export enum PurchaseViewType {
    "ADD" = "ADD",
    "EDIT" = "EDIT",
    "VIEW" = "VIEW",
}

export interface CreatePurchaseResponse {
    id: string
}

export enum PurchaseType {
    REQUEST = "REQUEST",
    ORDER = "ORDER",
}

export enum FulfillmentStatus {
    PENDING = "PENDING",
    IN_PREPARATION = "IN_PREPARATION",
    OUT_FOR_DELIVERY = "OUT_FOR_DELIVERY",
    DELIVERED = "DELIVERED",
    CANCELED = "CANCELED",
}

export const FulfillmentStatusOrder = [
    FulfillmentStatus.PENDING,
    FulfillmentStatus.IN_PREPARATION,
    FulfillmentStatus.OUT_FOR_DELIVERY,
    FulfillmentStatus.DELIVERED,
    FulfillmentStatus.CANCELED,
]

export enum FulfillmentType {
    BUYER = "BUYER",
    SUPPLIER = "SUPPLIER",
}
