import { Switch as MuiSwitch, styled } from "@mui/material"

export const Switch = styled(MuiSwitch)(({ theme, size }) => ({
    width: size === "small" ? "26px" : "40px",
    height: size === "small" ? "14px" : "20px",
    borderRadius: "10px",
    padding: 0,
    backgroundColor: theme.palette.primary.main,
    "& .MuiSwitch-track": {
        background: theme.palette.grey[900],
        opacity: 1.0,
    },
    "& .MuiSwitch-switchBase": {
        padding: "2px",
    },
    "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: size === "small" ? "10px" : "16px",
        height: size === "small" ? "10px" : "16px",
        backgroundColor: "var(--color-white)",
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
        transform: `translateX(${size === "small" ? "12px" : "16px"})`,
        "$ .MuiSwitch-thumb": {
            backgroundColor: theme.palette.primary.main,
        },
    },
    "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
        backgroundColor: theme.palette.primary.main,
        opacity: 1.0,
    },
    "& .MuiSwitch-switchBase.Mui-disabled": {
        "$ .MuiSwitch-thumb": {
            backgroundColor: theme.palette.primary.main,
        },
    },
    "& .MuiSwitch-switchBase.Mui-disabled+.MuiSwitch-track": {
        backgroundColor: "var(--color-light-grey)",
        opacity: 1.0,
    },
    "& .MuiSwitch-switchBase.Mui-checked.Mui-disabled+.MuiSwitch-track": {
        backgroundColor: "var(--color-white)",
        opacity: 0.4,
    },
}))
