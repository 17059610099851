import { useCallback } from "react"
import { organizationApi } from "~/api"
import { CountryCode, OrganizationRegistrationI } from "~/types"

export const useCreateOrganization = () => {
    return useCallback(
        async (
            name: string,
            countryCode: CountryCode,
            identifier: string,
            registration?: OrganizationRegistrationI
        ) => {
            const result = await organizationApi.createOrganization(name, countryCode, identifier)
            if (registration) {
                await organizationApi.provideRegistration(result.id, registration)
            }
            return result
        },
        []
    )
}
