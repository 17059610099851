import React, { useCallback, useState } from "react"
import { defineMessage, useIntl } from "react-intl"
import { LightButton } from "~/components/LightButton"
import { ModalContact } from "~/domains/transactions/book-of-relations/components/PartnerProfile/components/ModalContact"
import {
    useCreatePartnerProfile,
    useCreatePartnerProfileContact,
    useFetchPartnerProfile,
    useUpdatePartnerProfileContact,
} from "~/domains/transactions/book-of-relations/store/hooks"
import {
    CreatePartnerProfileContactI,
    CREATION_ID,
    OCR_ID,
    PartnerProfileContactI,
} from "~/domains/transactions/book-of-relations/types"
import { OrganizationId } from "~/types"
import { Loader } from "~/components"
import {
    computePartnerProfile,
    createPartnerProfileContactPayload,
    isPartnerProfileContact,
} from "~/domains/transactions/book-of-relations/core"

const saveContactMessage = defineMessage({
    id: "partners.bookofrelations.selectors.save",
    defaultMessage: "Save this contact",
})

interface Props {
    currentOrganizationId: OrganizationId
    partnerId: OrganizationId
    selectedContact: PartnerProfileContactI | null
}

export const AddContactBlock = ({ currentOrganizationId, partnerId, selectedContact }: Props) => {
    const [contactModalVisible, setContactModalVisible] = useState<boolean>(false)
    const { formatMessage } = useIntl()

    const isNewContact =
        !selectedContact ||
        !("id" in selectedContact) ||
        selectedContact.id === OCR_ID ||
        selectedContact.id === CREATION_ID

    const { partnerProfile, loading, error } = useFetchPartnerProfile(currentOrganizationId, partnerId)
    const { createPartnerProfile } = useCreatePartnerProfile()
    const { updatePartnerProfileContact } = useUpdatePartnerProfileContact()
    const { createPartnerProfileContact } = useCreatePartnerProfileContact()

    const handleSave = useCallback(
        (contact: CreatePartnerProfileContactI | PartnerProfileContactI) => {
            const contactPayload = createPartnerProfileContactPayload(contact)
            if (!contactPayload) return
            if (!partnerProfile) {
                const computedProfile = computePartnerProfile(
                    partnerProfile,
                    { contacts: [contactPayload] },
                    currentOrganizationId,
                    partnerId
                )
                const profileCreationResult = createPartnerProfile(computedProfile)
                return profileCreationResult
            }
            return isPartnerProfileContact(contactPayload)
                ? updatePartnerProfileContact(partnerProfile.initiatorId, partnerProfile.partnerId, contactPayload)
                : createPartnerProfileContact(partnerProfile.initiatorId, partnerProfile.partnerId, contactPayload)
        },
        [partnerProfile, currentOrganizationId, partnerId]
    )

    const handleOpenModal = useCallback(() => {
        setContactModalVisible(true)
    }, [setContactModalVisible])

    if (loading) {
        return <Loader small />
    }

    return (
        <>
            {!error && (
                <>
                    <LightButton type="button" onClick={handleOpenModal}>
                        {formatMessage(saveContactMessage)}
                    </LightButton>
                    <ModalContact
                        isNew={isNewContact}
                        contact={selectedContact}
                        save={handleSave}
                        open={contactModalVisible}
                        close={() => setContactModalVisible(false)}
                    />
                </>
            )}
        </>
    )
}
