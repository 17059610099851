import { Grid, Tooltip } from "@mui/material"
import { FC } from "react"
import { ChevronLeft, ChevronRight } from "react-feather"
import { defineMessages, useIntl } from "react-intl"
import { generatePath, useNavigate } from "react-router-dom"

import { Button } from "~/components"
import { INVOICE_ROUTE } from "~/domains/transactions/invoices-v1/routes"
import { selectSelectedDocuments } from "~/store/account/accountSlice"
import { useAppSelector } from "~/store/hooks"
import { InvoiceI } from "~/types"

const messages = defineMessages({
    previousInvoiceTooltip: {
        id: "supplier.invoice.page.button.previousInvoice.tooltip",
        defaultMessage: "Go to previous invoice",
    },
    nextInvoiceTooltip: {
        id: "supplier.invoice.page.button.nextInvoice.tooltip",
        defaultMessage: "Go to next invoice",
    },
})

interface InvoiceNavigationProps {
    invoice: InvoiceI
}

export const InvoiceNavigation: FC<InvoiceNavigationProps> = ({ invoice }) => {
    const { formatMessage } = useIntl()
    const navigate = useNavigate()
    const selectedDocuments = useAppSelector(selectSelectedDocuments)

    const isPreviousInvoiceButtonDisabled = () => {
        const currentIndex = selectedDocuments.findIndex((id: string) => id === invoice.id)
        return currentIndex === 0
    }

    const isNextInvoiceButtonDisabled = () => {
        const currentIndex = selectedDocuments.findIndex((id: string) => id === invoice.id)
        return currentIndex === selectedDocuments.length - 1
    }

    const goToPreviousInvoice = () => {
        const currentIndex = selectedDocuments.findIndex((id: string) => id === invoice.id)
        if (selectedDocuments[currentIndex - 1]) {
            navigate(generatePath(INVOICE_ROUTE, { invoiceId: selectedDocuments[currentIndex - 1] }))
        }
    }

    const goToNextInvoice = () => {
        const currentIndex = selectedDocuments.findIndex((id: string) => id === invoice.id)
        if (selectedDocuments[currentIndex + 1]) {
            navigate(generatePath(INVOICE_ROUTE, { invoiceId: selectedDocuments[currentIndex + 1] }))
        }
    }

    if (!selectedDocuments.length) return null

    return (
        <Grid container gap={1}>
            <Tooltip title={formatMessage(messages.previousInvoiceTooltip)} arrow>
                <div>
                    <Button type="neutral" disabled={isPreviousInvoiceButtonDisabled()} onClick={goToPreviousInvoice}>
                        <ChevronLeft size={16} />
                    </Button>
                </div>
            </Tooltip>
            <Tooltip title={formatMessage(messages.nextInvoiceTooltip)} arrow>
                <div>
                    <Button type="neutral" disabled={isNextInvoiceButtonDisabled()} onClick={goToNextInvoice}>
                        <ChevronRight size={16} />
                    </Button>
                </div>
            </Tooltip>
        </Grid>
    )
}
