import { XYPosition } from "@xyflow/react"

import { Opaque } from "~/utils"

export type FlowId = Opaque<string, { readonly T: unique symbol }>

export type Filter = {
    conditions: Array<Array<string>>
}

export type FlowNode =
    | EventTriggerNode
    | IfNode
    | SetPartnershipFieldNode
    | CheckNode
    | SendEmailNode
    | AddToBudgetNode
    | InvoiceToPurchaseOrderMatchNode
    | SetInvoiceLifecycleStatusNode
    | FitsToBudgetNode

export type Node = {
    name: string
    slug: string
    metadata: {
        position: XYPosition
        additionalInformation?: string
    }
}

type ConditionalNode = Node & {
    nextIfSuccess: string | null
    nextIfFailure: string | null
}

type RegularNode = Node & {
    nextNode: string | null
}

export enum UserType {
    USER = "User",
    TEAM = "Team",
}

export type UserReviewer = {
    userId: string
    type: UserType.USER
}

export type TeamReviewer = {
    teamId: string
    type: UserType.TEAM
}

export type Reviewer = UserReviewer | TeamReviewer

export type IfNode = ConditionalNode & {
    condition: string
    type: NodeType.IF_NODE
}

export type CheckNode = ConditionalNode & {
    objectId: string
    objectType: ObjectType
    reviewers: Reviewer[]
    passThreshold: number
    refuseThreshold: number
    type: NodeType.CHECK_NODE
}

export type EventTriggerNode = RegularNode & {
    objectType: ObjectType | null
    objectEvent: ObjectEvent | null
    filter: Filter
    type: NodeType.EVENT_TRIGGER_NODE
}

export type SetPartnershipFieldNode = RegularNode & {
    partnershipId: string
    fieldToUpdate: string
    valueToSet: string
    type: NodeType.SET_PARTNERSHIP_FIELD_NODE
}

export type SendEmailNode = RegularNode & {
    subject: string
    recipientAddresses: string[]
    body: string
    type: NodeType.SEND_EMAIL_NODE
}

export type AddToBudgetNode = RegularNode & {
    budgetId: string
    transactionId: string
    transactionType: TransactionType | null
    amount: string
    currency: string
    failIfOverbudget: boolean
    type: NodeType.ADD_TO_BUDGET_NODE
}

export type InvoiceToPurchaseOrderMatchNode = ConditionalNode & {
    invoiceId: string
    type: NodeType.INVOICE_TO_PURCHASE_ORDER_MATCH_NODE
}

export type SetInvoiceLifecycleStatusNode = RegularNode & {
    invoiceId: string
    statusToSet: string
    type: NodeType.SET_INVOICE_LIFECYCLE_STATUS_NODE
}

export type FitsToBudgetNode = ConditionalNode & {
    budgetId: string
    transactionId: string
    transactionType: string
    amount: string
    currency: string
    type: NodeType.FITS_TO_BUDGET_NODE
}

export type Flow = {
    id: FlowId
    version: number
    name: string
    enabled: boolean
    archived: boolean
    createdAt: string
    nodes: FlowNode[]
    author: string
}

export enum NodeType {
    EVENT_TRIGGER_NODE = "EventTriggerNode",
    HTTP_NODE = "HttpNode",
    SET_PARTNERSHIP_FIELD_NODE = "SetPartnershipFieldNode",
    IF_NODE = "IfNode",
    CHECK_NODE = "CheckNode",
    SEND_EMAIL_NODE = "SendEmailNode",
    ADD_TO_BUDGET_NODE = "AddToBudgetNode",
    INVOICE_TO_PURCHASE_ORDER_MATCH_NODE = "InvoiceToPurchaseOrderMatchNode",
    SET_INVOICE_LIFECYCLE_STATUS_NODE = "SetInvoiceLifecycleStatusNode",
    FITS_TO_BUDGET_NODE = "FitsToBudgetNode",
}

export enum ObjectEvent {
    CREATED = "Created",
    UPDATED = "Updated",
}

export enum FlowTrigger {
    INVOICE = "INVOICE",
    PURCHASE_REQUEST = "PURCHASE_REQUEST",
}

export enum ObjectType {
    ORGANIZATION_RELATIONSHIP = "OrganizationRelationship",
    PURCHASE_ORDER = "PurchaseOrder",
    PURCHASE_REQUEST = "PurchaseRequest",
    INVOICE = "Invoice",
    CONTACT = "Contact",
    PAYMENT = "Payment",
}

export enum TransactionType {
    PURCHASE_REQUEST = "PurchaseRequest",
    PURCHASE_ORDER = "PurchaseOrder",
    INVOICE = "Invoice",
    PAYMENT = "Payment",
    DEPOSIT = "Deposit",
}

export type Flows = {
    flows: FlowItem[]
}

export type FlowItem = {
    id: FlowId
    version: number
    name: string
    enabled: boolean
    archived: boolean
    createdAt: string
}

export type CreateFlowBody = {
    name: string
    enabled: boolean
    nodes: FlowNode[]
}

export type CreateFlowQuery = {
    body: CreateFlowBody
}

type GetFlowParams = {
    version: number
}

export type GetFlowQuery = {
    flowId: FlowId
    params: GetFlowParams
}

export type UpdateFlowMutation = {
    flowId: FlowId
    body: Flow
}

type ValidFlow = {
    isValid: true
}

type InvalidFlow = {
    isNotValid: true
    error: string
}

export type FlowValidation = ValidFlow | InvalidFlow

// type guard to check if node is an EventTriggerNode
export const isEventTriggerNode = (node: FlowNode): node is EventTriggerNode =>
    node.type === NodeType.EVENT_TRIGGER_NODE

// type guard to check if node is an IfNode
export const isIfNode = (node: FlowNode): node is IfNode => node.type === NodeType.IF_NODE

// type guard to check if node is an SetPartnershipFieldNode
export const isSetPartnershipFieldNode = (node: FlowNode): node is SetPartnershipFieldNode =>
    node.type === NodeType.SET_PARTNERSHIP_FIELD_NODE

// type guard to check if string is ObjectEvent
export const isObjectEvent = (event: string): event is ObjectEvent =>
    Object.values(ObjectEvent).includes(event as ObjectEvent)

// type guard to check if string is ObjectType
export const isObjectType = (type: string): type is ObjectType => Object.values(ObjectType).includes(type as ObjectType)

// type guard to check if string is TransactionType
export const isTransactionType = (type: string): type is TransactionType =>
    Object.values(TransactionType).includes(type as TransactionType)

// type guard to check if node is CheckNode
export const isCheckNode = (node: FlowNode): node is CheckNode => node.type === NodeType.CHECK_NODE

// Add type guard for SendEmailNode
export const isSendEmailNode = (node: FlowNode): node is SendEmailNode => node.type === NodeType.SEND_EMAIL_NODE

// Add type guard for AddToBudgetNode
export const isAddToBudgetNode = (node: FlowNode): node is AddToBudgetNode => node.type === NodeType.ADD_TO_BUDGET_NODE

// type guard to check if node is an InvoiceToPurchaseOrderMatchNode
export const isInvoiceToPurchaseOrderMatchNode = (node: FlowNode): node is InvoiceToPurchaseOrderMatchNode =>
    node.type === NodeType.INVOICE_TO_PURCHASE_ORDER_MATCH_NODE

// type guard to check if node is an SetInvoiceLifecycleStatusNode
export const isSetInvoiceLifecycleStatusNode = (node: FlowNode): node is SetInvoiceLifecycleStatusNode =>
    node.type === NodeType.SET_INVOICE_LIFECYCLE_STATUS_NODE

// type guard to check if node is an FitsToBudgetNode
export const isFitsToBudgetNode = (node: FlowNode): node is FitsToBudgetNode =>
    node.type === NodeType.FITS_TO_BUDGET_NODE

// type guard to check if node has nextNode
export const hasNextNode = (node: FlowNode): node is FlowNode & { nextNode: string } =>
    "nextNode" in node && node.nextNode !== null

// type guard to check if metadata position is position
export const isPosition = (position: unknown): position is XYPosition => {
    if (typeof position !== "object" || position === null) return false
    if (!("x" in position) || !("y" in position)) return false
    if (typeof (position as XYPosition).x !== "number" || typeof (position as XYPosition).y !== "number") return false
    return true
}

// type guard to check if reviewer is a UserReviewer
export const isUserReviewer = (reviewer: Reviewer): reviewer is UserReviewer => reviewer.type === UserType.USER

// type guard to check if reviewer is a TeamReviewer
export const isTeamReviewer = (reviewer: Reviewer): reviewer is TeamReviewer => reviewer.type === UserType.TEAM

// type guard to check if flow is invalid
export const isFlowInvalid = (flowValidation: FlowValidation): flowValidation is InvalidFlow =>
    "error" in flowValidation

// type guard to check if flow is valid
export const isFlowValid = (flowValidation: FlowValidation): flowValidation is ValidFlow => "isValid" in flowValidation
