import { RefObject, useCallback } from "react"

import { InvoiceLines as InvoiceLines_Deprecated } from "~/features/invoice/components/InvoiceLines/InvoiceLines"
import { useAppDispatch } from "~/store/hooks"
import { invoiceActions } from "~/store/invoice/invoiceSlice"
import { InvoiceI } from "~/types"

interface InvoiceItemsProps {
    invoice: InvoiceI
    invoiceLinesFormRef: RefObject<HTMLFormElement>
}

export const InvoiceItemsEdit = ({ invoice, invoiceLinesFormRef }: InvoiceItemsProps) => {
    const dispatch = useAppDispatch()

    const handleUpdateInvoice = useCallback(
        (data: Partial<InvoiceI>) => {
            dispatch(invoiceActions.updatePartialInvoice(data))
        },
        [dispatch]
    )

    return (
        <InvoiceLines_Deprecated
            ref={invoiceLinesFormRef}
            invoice={invoice}
            updateData={handleUpdateInvoice}
            dataLoaded
            countryCode={invoice.supplier.countryCode}
        />
    )
}
