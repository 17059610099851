import { InputAdornment, Paper, TextField, Tooltip } from "@mui/material"
import classnames from "classnames"
import React, { ChangeEventHandler, MouseEventHandler, ReactNode } from "react"
import { Search } from "react-feather"
import { defineMessages, useIntl } from "react-intl"

import { Button } from "~/components"
import { ActionsMenu } from "~/components/ActionsMenu"
import { permissionMessages } from "~/domains/identity/features/roles-permissions/utils/permissions"

import "./DatatableFilters.scss"

interface Props {
    onChangeFilter: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    valueFilter: string
    onClickButton?: MouseEventHandler<HTMLButtonElement> | undefined
    labelButton?: string
    iconButton?: React.ReactNode
    actionsMenu?: boolean
    actions?: Actions[]
    className?: string
    hasPermission?: boolean
}

const messages = defineMessages({
    filterLabel: {
        id: "dataTable.filters.SearchFieldLabel",
        defaultMessage: "Search",
    },
})

export interface Actions {
    label: ReactNode
    action: () => void
}

export const DatatableFilters: React.FC<Props> = ({
    onChangeFilter,
    valueFilter,
    onClickButton,
    labelButton,
    iconButton,
    actionsMenu,
    actions,
    className,
    hasPermission,
}) => {
    const { formatMessage } = useIntl()
    const classes = classnames("datatable-filters", className)

    const disabled = hasPermission === false
    const actionButton = (
        <Button type="primary" onClick={onClickButton} disabled={disabled}>
            {labelButton}
            {iconButton}
        </Button>
    )

    return (
        <Paper className={classes}>
            <TextField
                className="datatable-filters-field"
                name="datatable-filter"
                label={formatMessage(messages.filterLabel)}
                placeholder={formatMessage(messages.filterLabel)}
                onChange={onChangeFilter}
                value={valueFilter}
                InputLabelProps={!valueFilter ? { shrink: false, style: { paddingLeft: "2rem" } } : {}}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Search color="var(--color-light-grey)" />
                        </InputAdornment>
                    ),
                }}
            />
            <hr />
            {actionsMenu && actions ? (
                <ActionsMenu actions={actions} labelButton={labelButton} disabled={disabled} buttonType="primary" />
            ) : labelButton && onClickButton ? (
                <>
                    {hasPermission || typeof hasPermission === "undefined" ? (
                        actionButton
                    ) : (
                        <Tooltip title={formatMessage(permissionMessages.errorNoAccessAdministrator)}>
                            {actionButton}
                        </Tooltip>
                    )}
                </>
            ) : null}
        </Paper>
    )
}
