import { useCallback, useState } from "react"
import { OrganizationId } from "~/types"
import { toast } from "react-toastify"
import { defineMessages, useIntl } from "react-intl"
import { PurchaseOrderApprovalStatus } from "~/domains/transactions/purchase-orders/types/PurchaseOrders"
import { purchaseOrdersApi } from "~/domains/transactions/purchase-orders/api/purchaseOrdersApi"
import { useAppDispatch } from "~/store/hooks"

const messages = defineMessages({
    approved: {
        id: "purchase.orders.order.statusUpdate.approved",
        defaultMessage: "Purchase order approved",
    },
    rejected: {
        id: "purchase.orders.order.statusUpdate.rejected",
        defaultMessage: "Purchase order rejected",
    },
    error: {
        id: "purchase.orders.order.statusUpdate.error",
        defaultMessage: "Something went wrong. Please contact your administrator",
    },
})

export const useUpdatePurchaseOrderApprovalStatus = (purchaseOrderId: string) => {
    const { formatMessage } = useIntl()
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>()
    const dispatch = useAppDispatch()

    const updatePOApprovalStatus = useCallback(
        async (organizationId: OrganizationId, linesIds: string[], status: PurchaseOrderApprovalStatus) => {
            try {
                setLoading(true)
                await purchaseOrdersApi.updateApprovalStatus(organizationId, purchaseOrderId, linesIds, status)
                setLoading(false)

                return toast.success(formatMessage(messages[status.toLowerCase()]))
            } catch (e) {
                setError(`${e}`)
                setLoading(false)
                toast.error(formatMessage(messages.error))
                throw e
            }
        },
        [purchaseOrderId, dispatch]
    )

    return {
        updatePOApprovalStatus,
        error,
        loading,
    }
}
