import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { prepareHeadersWithAuthorizationAndOrganizationId } from "~/api/prepareHeaders"
import { adaptRunFromApi, adaptRunsFromApi } from "~/domains/orchestration/flows/api/adapters/fromApiAdapters"
import type { ApiRun, ApiRuns, GetRunQuery, Run, Runs } from "~/domains/orchestration/flows/types"

const BASE_URL = import.meta.env.VITE_API_FLOWS_URL

export const runsApi = createApi({
    reducerPath: "runsApi",
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL, prepareHeaders: prepareHeadersWithAuthorizationAndOrganizationId }),
    endpoints: (builder) => ({
        getRun: builder.query<Run, GetRunQuery>({
            query: ({ runId }: GetRunQuery) => ({
                url: `runs/${runId}`,
            }),
            transformResponse: (response: ApiRun) => adaptRunFromApi(response),
        }),

        getRuns: builder.query<Runs, void>({
            query: () => `runs`,
            transformResponse: (response: ApiRuns) => adaptRunsFromApi(response),
        }),
    }),
})

export const { useGetRunsQuery, useGetRunQuery } = runsApi
