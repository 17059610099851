import { Stack, TextField } from "@mui/material"
import { ChangeEvent, useCallback, useEffect, useState } from "react"
import { defineMessages, useIntl } from "react-intl"

import { InvoiceAddress } from "~/domains/transactions/invoices-v1/types/Invoice"
import { useSaveBuffer } from "~/hooks/useSaveBuffer"
import { useAppDispatch } from "~/store/hooks"

const messages = defineMessages({
    line1: {
        id: "company.address.line1",
        defaultMessage: "Line 1",
    },
    line2: {
        id: "company.address.line2",
        defaultMessage: "Line 2",
    },
    postalCode: {
        id: "company.address.postalCode",
        defaultMessage: "Postal Code",
    },
    city: {
        id: "company.address.city",
        defaultMessage: "City",
    },
    country: {
        id: "company.address.country",
        defaultMessage: "Country",
    },
})

interface AddressProps {
    address: InvoiceAddress
    onChange: (address: InvoiceAddress) => void
}

export function Address({ address, onChange }: AddressProps) {
    const { formatMessage } = useIntl()
    const [street, setStreet] = useState<string>(address?.street ?? "")
    const [street2, setStreet2] = useState<string>(address?.street2 ?? "")
    const [zipCode, setZipCode] = useState<string>(address?.zipCode ?? "")
    const [city, setCity] = useState<string>(address?.city ?? "")
    const [country, setCountry] = useState<string>(address?.country ?? "")
    const dispatch = useAppDispatch()

    useEffect(() => {
        setStreet(address?.street ?? "")
        setStreet2(address?.street2 ?? "")
        setZipCode(address?.zipCode ?? "")
        setCity(address?.city ?? "")
        setCountry(address?.country ?? "")
    }, [address])

    const save = useCallback(() => {
        onChange({
            street,
            street2,
            zipCode,
            city,
            country,
        })
        return
    }, [street, street2, zipCode, city, country, dispatch])

    const { setHasChanges } = useSaveBuffer(save)

    const onStreetChange = (e: ChangeEvent<HTMLInputElement>) => {
        setStreet(e.currentTarget.value)
        setHasChanges(true)
    }

    const onStreet2Change = (e: ChangeEvent<HTMLInputElement>) => {
        setStreet2(e.currentTarget.value)
        setHasChanges(true)
    }
    const onZipCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
        setZipCode(e.currentTarget.value)
        setHasChanges(true)
    }
    const onCityChange = (e: ChangeEvent<HTMLInputElement>) => {
        setCity(e.currentTarget.value)
        setHasChanges(true)
    }
    const onCountryChange = (e: ChangeEvent<HTMLInputElement>) => {
        setCountry(e.currentTarget.value)
        setHasChanges(true)
    }

    return (
        <Stack spacing={1}>
            <TextField
                id="address.street"
                name="address.street"
                label={formatMessage(messages.line1)}
                placeholder={formatMessage(messages.line1)}
                onChange={onStreetChange}
                value={street}
            />
            <TextField
                id="address.street2"
                name="address.street2"
                label={formatMessage(messages.line2)}
                placeholder={formatMessage(messages.line2)}
                onChange={onStreet2Change}
                value={street2}
            />
            <TextField
                id="address.zipCode"
                name="address.zipCode"
                label={formatMessage(messages.postalCode)}
                placeholder={formatMessage(messages.postalCode)}
                onChange={onZipCodeChange}
                value={zipCode}
            />
            <TextField
                id="address.city"
                name="address.city"
                label={formatMessage(messages.city)}
                placeholder={formatMessage(messages.city)}
                onChange={onCityChange}
                value={city}
            />
            <TextField
                id="address.country"
                name="address.country"
                label={formatMessage(messages.country)}
                placeholder={formatMessage(messages.country)}
                onChange={onCountryChange}
                value={country}
            />
        </Stack>
    )
}
