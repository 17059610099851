import { useCallback } from "react"

import { useAppDispatch } from "~/store/hooks"

import { customFieldsActions } from "../store"
import { CreateCustomFieldObjectPayload, CustomFieldValueI } from "../types/CustomFieldValue"

export const useSavePendingCustomFieldObject = (customField: CustomFieldValueI) => {
    const dispatch = useAppDispatch()
    return useCallback(
        (payload: CreateCustomFieldObjectPayload) => {
            dispatch(
                customFieldsActions.setPendingCustomFieldObject({
                    customField,
                    value: payload,
                })
            )
        },
        [dispatch, customField]
    )
}
