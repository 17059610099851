import { ListSubheader, MenuItem, TextField } from "@mui/material"
import { MessageDescriptor, useIntl } from "react-intl"

import { PaymentMethodDetails } from "~/domains/payment/payment-method-details/types"
import { buildMaskedCardNumber } from "~/domains/payment/payment-method-details/utils/getGridColumnsByActiveTab"
import { PaymentMethodType } from "~/domains/payment/payment-methods/types"

const paymentTypeMessage: Partial<Record<PaymentMethodType, MessageDescriptor>> = {
    [PaymentMethodType.CARD]: {
        id: "payment.paymentMethodTabs.card",
        defaultMessage: "Card",
    },
    [PaymentMethodType.BANK_TRANSFER]: {
        id: "payment.paymentMethodTabs.bankTransfer",
        defaultMessage: "Bank transfer",
    },
    [PaymentMethodType.DIRECT_DEBIT]: {
        id: "payment.paymentMethodTabs.directDebit",
        defaultMessage: "Direct debit",
    },
}

const subheaderStyles = {
    "&.MuiListSubheader-root": {
        fontSize: "var(--font-size-sm)",
        lineHeight: "var(--spacing-md)",
        color: "var(--color-light-grey)",
        marginBottom: "var(--spacing-sx)",
    },
}

interface PaymentMethodDetailsSelectorProps {
    label?: string
    value: string | null
    loading?: boolean
    items: PaymentMethodDetails[]
    onItemSelect: (id: string, paymentMethodId?: string | undefined) => void
}

export const PaymentMethodDetailsSelector = ({
    label,
    value,
    items,
    onItemSelect,
    loading = false,
}: PaymentMethodDetailsSelectorProps) => {
    const { formatMessage } = useIntl()
    const groupedData = items.reduce((acc, item) => {
        const key = item.payment_method_type as PaymentMethodType
        if (!acc[key]) {
            acc[key] = []
        }
        acc[key].push(item)
        return acc
    }, {})

    const groupedDataArray = Object.keys(groupedData).map((type) => ({
        type,
        items: groupedData[type],
    }))

    const handleChange = (event) => {
        const id = event.target.value
        const paymentMethodId = items.find((item) => item.id === id)?.payment_method_id

        if (paymentMethodId) {
            onItemSelect(id, paymentMethodId)
            return
        }
        onItemSelect(id)
    }

    const renderMenuItem = (item: PaymentMethodDetails) => {
        const { id, payment_method_type } = item
        let content = ""

        switch (payment_method_type) {
            case PaymentMethodType.CARD:
                content = buildMaskedCardNumber([item.card_first_6_digits, item.card_last_4_digits])
                break
            case PaymentMethodType.BANK_TRANSFER:
                content = item.iban || item.bank_account_number
                break
            case PaymentMethodType.DIRECT_DEBIT:
                content = item.bank_account_number
                break
            default:
                return null
        }

        return (
            <MenuItem key={id} value={id}>
                {content}
            </MenuItem>
        )
    }

    return (
        <TextField
            fullWidth
            label={label}
            select
            value={value || ""}
            disabled={loading}
            variant="outlined"
            autoComplete="off"
            onChange={handleChange}
        >
            {groupedDataArray.map(({ type, items }) => [
                <ListSubheader sx={subheaderStyles} key={type}>
                    {formatMessage(paymentTypeMessage[type])}
                </ListSubheader>,
                items.map(renderMenuItem),
            ])}
        </TextField>
    )
}
