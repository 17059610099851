import { useEffect, useState } from "react"

import { pipeResult } from "~/core/Result"
import { parseResponse } from "~/domains/common/apiClient/parseResponse"
import { OrganizationId } from "~/types"

import { useTagsApi } from "../tagsApi"
import { TagRecommandationsResultI, parseTagRecommandationsResult } from "../types/TagRecommandation"

const isFeatureEnabled = import.meta.env.VITE_ENV !== "production"

export const useTagsRecommandations = (organizationId?: OrganizationId, context?: any, readonly?: boolean) => {
    const [recommandations, setRecommandations] = useState<TagRecommandationsResultI | null>(null)
    const tagsApi = useTagsApi()
    useEffect(() => {
        if (!organizationId) return
        const fetchRecommandations = async () => {
            try {
                const response = await tagsApi.getTagsSuggestions(organizationId, context)
                if (response?.status >= 200 && response.status < 400 && !response.data) return
                const result = parseResponse(response, parseTagRecommandationsResult)
                pipeResult(result, setRecommandations)
            } catch (error) {
                console.error(error)
            }
        }
        if (isFeatureEnabled && !readonly) {
            fetchRecommandations()
        }
    }, [tagsApi, context, readonly])

    if (readonly || !isFeatureEnabled) {
        return null
    }
    return recommandations
}
