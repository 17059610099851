import { FormControl, FormControlOwnProps, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { defineMessages, useIntl } from "react-intl"

import { VatRateI, VatRateId } from "~/types/VatRate"

const messages = defineMessages({
    defaultLabel: {
        id: "invoice.vatRates.selector.defaultLabel",
        defaultMessage: "VAT rate",
    },
})

interface Props extends FormControlOwnProps {
    value: VatRateId | null
    onChange: (value: VatRateI | null) => void
    vatRates: VatRateI[]
    readonly?: boolean
    hideLabel?: boolean
}

export const getVatRateLabel = (vatRate: VatRateI, locale: string): string => {
    const localLabel = vatRate.label.translations[locale.split("-")[0].toUpperCase()]
    if (localLabel) {
        return localLabel
    }
    const language = vatRate.label.defaultLanguage
    const defaultLanguageLabel = vatRate.label.translations[language]
    if (defaultLanguageLabel) {
        return defaultLanguageLabel
    }
    return Object.values(vatRate.label.translations)[0]
}

export const SelectVatRate: React.FC<Props> = ({
    value,
    onChange,
    vatRates,
    readonly = false,
    hideLabel,
    ...formControlProps
}) => {
    const { formatMessage, locale } = useIntl()
    const [selectedVatRateId, setSelectedVatRateId] = useState<VatRateId>(value ? value : "")
    const fieldId = useMemo(() => `vat-rate-selector-${Math.round(Math.random() * 999999)}`, [])

    useEffect(() => {
        if (!selectedVatRateId && value) {
            setSelectedVatRateId(value)
        } else {
            const selectedVatRate = vatRates.find((vatRate) => vatRate.id === selectedVatRateId)
            if (!!selectedVatRate !== !!value || (selectedVatRate && selectedVatRate?.id !== value)) {
                onChange(selectedVatRate ?? null)
            }
        }
    }, [selectedVatRateId, value, vatRates, onChange])

    const onSelectChange = useCallback((event: SelectChangeEvent<VatRateId>) => {
        setSelectedVatRateId(event.target.value)
    }, [])

    const renderMenuItem = useCallback(
        (vatRate: VatRateI) => {
            const label = getVatRateLabel(vatRate, locale)
            return (
                <MenuItem value={vatRate.id} key={vatRate.id}>
                    {label}
                </MenuItem>
            )
        },
        [locale]
    )

    return (
        <FormControl {...formControlProps}>
            {hideLabel ? undefined : <InputLabel id={fieldId}>{formatMessage(messages.defaultLabel)}</InputLabel>}
            <Select
                labelId={fieldId}
                value={selectedVatRateId}
                label={hideLabel ? undefined : formatMessage(messages.defaultLabel)}
                onChange={onSelectChange}
                readOnly={readonly}
                aria-readonly={readonly}
            >
                {vatRates.map(renderMenuItem)}
            </Select>
        </FormControl>
    )
}
