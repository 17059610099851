import { styled } from "@mui/material"

import { Button } from "~/components"

export const BorderCard = styled("div")({
    display: "flex",
    flexDirection: "column",
    gap: "var(--spacing-md)",
    border: "1px solid var(--color-border-workflow)",
    padding: "var(--spacing-md)",
    borderRadius: "var(--border-radius-sm)",
})

export const Title = styled("div")({
    fontSize: "var(--font-size-md)",
    color: "var(--color-light-grey)",
    fontWeight: "var(--font-weight-lg)",
})

export const Container = styled("div")({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
})

export const AddButon = styled(Button)({
    padding: 0,
})

export const PaymentMethodContainer = styled("div")({
    display: "flex",
    flexWrap: "wrap",
    columnGap: "var(--spacing-xxl)",
    rowGap: "var(--spacing-md)",
})

export const InfoItem = styled("div")({
    display: "flex",
    flexDirection: "column",
    gap: "var(--spacing-sx)",
})

export const InfoItemLabel = styled("span")({
    color: "var(--color-light-grey)",
    fontSize: "var(--font-size-sm)",
})

export const InfoItemValue = styled("span")({
    color: "var(--color-grey)",
    fontSize: "var(--font-size-md)",
})
