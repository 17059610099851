import { Stack } from "@mui/material"
import React, { ElementType, useCallback, useEffect, useRef, useState } from "react"
import { ChevronDown, Edit3, Plus } from "react-feather"
import { FormattedMessage, defineMessages, useIntl } from "react-intl"
import { generatePath, useNavigate } from "react-router-dom"

import { Loader } from "~/components"
import { ORGANIZATION_DETAILS_ROUTE } from "~/features/account/routes"
import { selectUser } from "~/store/account/accountSlice"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { useCurrentOrganization, useFetchOrganization } from "~/store/organization/hooks"
import { organizationActions } from "~/store/organization/organizationSlice"
import { OrganizationI, OrganizationId, OrganizationItemI, organizationIsNotFake } from "~/types"

import "./SelectOrganization.scss"

const messages = defineMessages({
    createOrganization: {
        id: "account.organizations.createOrganization",
        defaultMessage: "Please select one of your organizations",
    },
})

interface OrganizationItemProps {
    organizationId: OrganizationId
    close: () => void
    withEditButton?: boolean
    onChange?: (organizationId: OrganizationId) => void
}

interface OrganizationProps {
    organization: OrganizationI
    withEditButton?: boolean
    withChevron?: boolean
}

export const getInitials = (name: string): string =>
    name
        .split(/\s+/)
        .map((word) => (word.length > 0 ? word[0] : ""))
        .join("")
        .toLocaleUpperCase()
        .slice(0, 2)

const SideOrganization: React.FC<OrganizationProps> = ({
    organization,
    withEditButton = false,
    withChevron = false,
}) => {
    const navigate = useNavigate()
    const editOrganization = useCallback(
        (event: React.MouseEvent<SVGElement>) => {
            event.stopPropagation()
            navigate(generatePath(ORGANIZATION_DETAILS_ROUTE, { organizationId: organization.id }))
        },
        [organization.id, navigate]
    )
    return (
        <Stack direction="row" gap={1} alignItems="center" justifyContent="space-between">
            <figure className="no-image">{getInitials(organization.name)}</figure>
            <span>{organization.name}</span>
            {withEditButton && organizationIsNotFake(organization) && (
                <Edit3
                    size={14}
                    onClick={editOrganization}
                    className="organization-edit-button"
                    color="var(--color-light-grey)"
                />
            )}
            {withChevron && <ChevronDown size={14} color="var(--color-light-grey)" />}
        </Stack>
    )
}

const SideOrganizationItem: React.FC<OrganizationItemProps> = ({
    organizationId,
    close,
    onChange,
    withEditButton = false,
}) => {
    const { organization, loading } = useFetchOrganization(organizationId)
    const dispatch = useAppDispatch()
    const selectOrganization = () => {
        dispatch(organizationActions.setCurrentOrganization(organizationId))
        onChange && onChange(organizationId)
        close()
    }
    if (loading) {
        return (
            <li className="organization-item">
                <Loader small />
                <span className="loading">
                    <FormattedMessage id="sidebar.organizations.loading" defaultMessage="loading..." />
                </span>
            </li>
        )
    }
    return (
        <li className="organization-item" onClick={selectOrganization}>
            <SideOrganization organization={organization} withEditButton={withEditButton} />
        </li>
    )
}

interface Props {
    as?: ElementType
    forceClose?: boolean
    withEditButton?: boolean
    className?: string
    onChange?: (organizationId: OrganizationId) => void
    onCreate?: () => void
    creationEnabled?: boolean
}

export const SelectOrganization: React.FC<Props> = ({
    as: Tag = "div",
    forceClose,
    withEditButton = false,
    className,
    onChange,
    onCreate,
    creationEnabled,
}) => {
    const { formatMessage } = useIntl()
    const user = useAppSelector(selectUser)
    const currentOrganization = useCurrentOrganization(user.organizations)
    const [open, setOpen] = useState<boolean>(false)
    const toggle = useCallback(() => setOpen((open) => !open), [setOpen])
    const close = useCallback(() => setOpen(false), [setOpen])
    const ignoreNextClick = useRef<boolean>(false)

    useEffect(() => {
        if (open) {
            const onClick = () => {
                if (ignoreNextClick.current) {
                    ignoreNextClick.current = false
                } else {
                    setOpen(false)
                }
            }
            window.addEventListener("click", onClick)
            return () => {
                window.removeEventListener("click", onClick)
            }
        }
    }, [open])

    const onRootClick = useCallback(() => {
        ignoreNextClick.current = true
    }, [ignoreNextClick])

    const isNotCurrentOrganization = useCallback(
        (organizationItem: OrganizationItemI) => organizationItem.id !== currentOrganization?.id,
        [currentOrganization]
    )

    const renderOrganizationItem = useCallback(
        (organizationItem: OrganizationItemI) => (
            <SideOrganizationItem
                key={organizationItem.id}
                organizationId={organizationItem.id}
                close={close}
                withEditButton={withEditButton}
                onChange={onChange}
            />
        ),
        [close, withEditButton, onChange]
    )

    const clickOnCreate = useCallback(() => {
        close()
        onCreate && onCreate()
    }, [onCreate])

    const isOpen = open && !forceClose
    if (user.organizations.length === 0) return null
    return (
        <Tag className={`organization-selector ${className ?? ""}`} onClick={onRootClick}>
            {currentOrganization && (
                <div className="organization-item" onClick={toggle}>
                    {creationEnabled ? (
                        <li className="organization-item">
                            <figure className="no-image">
                                <Plus />
                            </figure>
                            <span>{formatMessage(messages.createOrganization)}</span>
                        </li>
                    ) : (
                        <SideOrganization
                            organization={currentOrganization}
                            withEditButton={withEditButton}
                            withChevron
                        />
                    )}
                </div>
            )}
            {isOpen && (
                <ul className="organization-selector-list">
                    {user.organizations.filter(isNotCurrentOrganization).map(renderOrganizationItem)}
                    {onCreate && (
                        <li className="organization-item" onClick={clickOnCreate}>
                            <figure className="no-image">
                                <Plus />
                            </figure>
                            <span>{formatMessage(messages.createOrganization)}</span>
                        </li>
                    )}
                </ul>
            )}
        </Tag>
    )
}
