import { MessageDescriptor, defineMessages } from "react-intl"
import {
    DomainName,
    ScopeName,
    permissionErrorType,
    RoleWithEntityIds,
    CreateRoleI,
} from "~/domains/identity/features/roles-permissions/types/RolesPermissions"
import { AuthorizationName, AuthorizationNameExtra } from "~/types"

export const authorizationMessages: Record<AuthorizationName | AuthorizationNameExtra, MessageDescriptor> = {
    [AuthorizationName.READ]: {
        id: "rolesPermissions.permission.authorization.read",
        defaultMessage: "read",
    },
    [AuthorizationName.CREATE]: {
        id: "rolesPermissions.permission.authorization.create",
        defaultMessage: "create",
    },
    [AuthorizationName.UPDATE]: {
        id: "rolesPermissions.permission.authorization.update",
        defaultMessage: "update",
    },
    [AuthorizationName.DELETE]: {
        id: "rolesPermissions.permission.authorization.delete",
        defaultMessage: "delete",
    },
    [AuthorizationNameExtra.EDIT]: {
        id: "rolesPermissions.permission.authorization.update",
        defaultMessage: "update",
    },
    [AuthorizationNameExtra.APPROVE]: {
        id: "rolesPermissions.permission.authorization.approve",
        defaultMessage: "approve",
    },
    [AuthorizationNameExtra.CONVERT]: {
        id: "rolesPermissions.permission.authorization.convert",
        defaultMessage: "convert",
    },
    [AuthorizationNameExtra.CONVERT_TO_PO]: {
        id: "rolesPermissions.permission.authorization.convert",
        defaultMessage: "convert",
    },
    [AuthorizationNameExtra.CHECK]: {
        id: "rolesPermissions.permission.authorization.check",
        defaultMessage: "check",
    },
    [AuthorizationNameExtra.SUBMIT]: {
        id: "rolesPermissions.permission.authorization.submit",
        defaultMessage: "delete",
    },
}

export const scopeMessages: Record<ScopeName, MessageDescriptor> = {
    [ScopeName.ORGANIZATIONS]: {
        id: "rolesPermissions.permission.scope.organizations",
        defaultMessage: "Organizations",
    },
    [ScopeName.USERS]: {
        id: "rolesPermissions.permission.scope.users",
        defaultMessage: "Users",
    },
    [ScopeName.TEAMS]: {
        id: "rolesPermissions.permission.scope.teams",
        defaultMessage: "Teams",
    },
    [ScopeName.TAGS]: {
        id: "rolesPermissions.permission.scope.tags",
        defaultMessage: "Tags",
    },
    [ScopeName.ROLES_PERMISSIONS]: {
        id: "rolesPermissions.permission.scope.rolesPermissions",
        defaultMessage: "Roles & permissions",
    },
    [ScopeName.BOOK_OF_RELATIONS]: {
        id: "rolesPermissions.permission.scope.bookOfRelations",
        defaultMessage: "Partners network",
    },
    [ScopeName.PURCHASE_REQUESTS]: {
        id: "rolesPermissions.permission.scope.purchaseRequests",
        defaultMessage: "Purchase requests",
    },
    [ScopeName.INVOICES]: {
        id: "rolesPermissions.permission.scope.invoices",
        defaultMessage: "Invoices",
    },
    [ScopeName.WORKFLOWS]: {
        id: "rolesPermissions.permission.scope.workflows",
        defaultMessage: "Workflows",
    },
    [ScopeName.PURCHASE_ORDERS]: {
        id: "rolesPermissions.permission.scope.purchaseOrders",
        defaultMessage: "Purchase orders",
    },
    [ScopeName.BUDGETS]: {
        id: "rolesPermissions.permission.scope.budgets",
        defaultMessage: "Budgets",
    },
    [ScopeName.PAYMENTS]: {
        id: "rolesPermissions.permission.scope.payments",
        defaultMessage: "Payments",
    },
}

export const errorMessages: Record<permissionErrorType, MessageDescriptor> = {
    [permissionErrorType.PERMISSION_ID_ALREADY_USED]: {
        id: "rolesPermissions.permission.error.PERMISSION_ID_ALREADY_USED",
        defaultMessage: "This permission ID is already used",
    },
    [permissionErrorType.PERMISSION_ENTITY_NOT_VALID]: {
        id: "rolesPermissions.permission.error.PERMISSION_ENTITY_NOT_VALID",
        defaultMessage: "The permission entity provided is not valid",
    },
    [permissionErrorType.ORGANIZATION_ID_NOT_VALID]: {
        id: "rolesPermissions.permission.error.ORGANIZATION_ID_NOT_VALID",
        defaultMessage: "The organizationId provided is not valid",
    },
    [permissionErrorType.PERMISSION_ID_NOT_WELL_FORMATED]: {
        id: "rolesPermissions.permission.error.PERMISSION_ID_NOT_WELL_FORMATED",
        defaultMessage: "The ID is not well formated. It must be: <authorization>:<domain>:<scope>",
    },
    [permissionErrorType.AUTHORIZATION_NAME_UNKNOWN]: {
        id: "rolesPermissions.permission.error.AUTHORIZATION_NAME_UNKNOWN",
        defaultMessage: "The authorization in name is unknown.",
    },
    [permissionErrorType.DOMAIN_NAME_UNKNOWN]: {
        id: "rolesPermissions.permission.error.DOMAIN_NAME_UNKNOWN",
        defaultMessage: "The domain in name is unknown.",
    },
    [permissionErrorType.CANT_UPDATE_PREDEFINED_ROLE]: {
        id: "rolesPermissions.permission.error.CANT_UPDATE_PREDEFINED_ROLE",
        defaultMessage: "This role is predefined by Flowie. You can't update it.",
    },
    [permissionErrorType.CANT_DELETE_PREDEFINED_ROLE]: {
        id: "rolesPermissions.permission.error.CANT_DELETE_PREDEFINED_ROLE",
        defaultMessage: "This role is predefined by Flowie. You can't delete it.",
    },
    [permissionErrorType.UPDATE_ROLE_MAX_RETRY]: {
        id: "rolesPermissions.permission.error.UPDATE_ROLE_MAX_RETRY",
        defaultMessage: "Failed to update role after maximum retries.",
    },
    [permissionErrorType.CREATE_ROLE_MAX_RETRY]: {
        id: "rolesPermissions.permission.error.CREATE_ROLE_MAX_RETRY",
        defaultMessage: "Failed to add role after maximum retries.",
    },
    [permissionErrorType.ROLE_ENTITY_NOT_VALID]: {
        id: "rolesPermissions.permission.error.ROLE_ENTITY_NOT_VALID",
        defaultMessage: "The role entity provided is not valid",
    },
    [permissionErrorType.USER_ROLE_ASSOCIATION_NOT_FOUND]: {
        id: "rolesPermissions.permission.error.USER_ROLE_ASSOCIATION_NOT_FOUND",
        defaultMessage: "User/Role association not found",
    },
    [permissionErrorType.TEAM_ROLE_ASSOCIATION_NOT_FOUND]: {
        id: "rolesPermissions.permission.error.TEAM_ROLE_ASSOCIATION_NOT_FOUND",
        defaultMessage: "Team/Role association not found",
    },
    [permissionErrorType.ROLE_NOT_FOUND]: {
        id: "rolesPermissions.permission.error.ROLE_NOT_FOUND",
        defaultMessage: "Role not found",
    },
    [permissionErrorType.ADD_ROLE_PERMISSION_MAX_RETRY]: {
        id: "rolesPermissions.permission.error.ADD_ROLE_PERMISSION_MAX_RETRY",
        defaultMessage: "Failed to add permission to role after maximum retries.",
    },
    [permissionErrorType.PERMISSION_DATA_PROVIDED_NOT_VALID]: {
        id: "rolesPermissions.permission.error.PERMISSION_DATA_PROVIDED_NOT_VALID",
        defaultMessage: "The permission data provided is not valid",
    },
    [permissionErrorType.ROLE_DATA_PROVIDED_NOT_VALID]: {
        id: "rolesPermissions.permission.error.ROLE_DATA_PROVIDED_NOT_VALID",
        defaultMessage: "The role data provided is not valid",
    },
}

export const permissionMessages = defineMessages({
    permissionDescription: {
        id: "rolesPermissions.permission.scope.permissionDescription",
        defaultMessage: "Can {permissionString} {scope}",
    },
    errorPermission: {
        id: "rolesPermissions.permission.scope.errorPermission",
        defaultMessage: "You can't {permissionString} {scope}. Ask for the access to your administrator.",
    },
    errorNoAccess: {
        id: "rolesPermissions.permission.scope.errorNoAccess",
        defaultMessage: "You don't have access to this section.",
    },
    errorNoAccessAdministrator: {
        id: "rolesPermissions.permission.scope.errorNoAccessAdministrator",
        defaultMessage: "You don't have access to this section. Please contact your administrator and ask for access.",
    },
    plural: {
        id: "rolesPermissions.permission.scope.plural",
        defaultMessage: " ",
    },
})

export const getPermissionIdString = (
    permission: string,
    domainName: DomainName,
    scope: ScopeName,
    formatMessage
): string => {
    const regExp = new RegExp(
        `(^${Object.values(AuthorizationName).join("|")}|${Object.values(AuthorizationNameExtra).join(
            "|"
        )}):${domainName}:${scope}`,
        ""
    )
    const match = permission.match(regExp)
    return match && match[1] ? formatMessage(authorizationMessages[match[1]]) : ""
}

export const isRoleWithEntityIds = (role: RoleWithEntityIds | CreateRoleI): role is RoleWithEntityIds => {
    return "id" in role && "userIds" in role
}
