import { Stack, TextField } from "@mui/material"
import cls from "classnames"
import React, { ChangeEvent, FC, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"

import { adaptExpressionToText, adaptTextToExpression } from "~/domains/orchestration/flows/core"
import { messages } from "~/domains/orchestration/flows/messages"
import { SendEmailNode } from "~/domains/orchestration/flows/types"

import { ConfigurationNode } from "./ConfigurationNode"

interface Props {
    selectedNode: SendEmailNode
    unselectCallback: () => void
}

export const SendEmailConfiguration: FC<Props> = ({ selectedNode, unselectCallback }) => {
    const { formatMessage } = useIntl()
    const [currentNode, setCurrentNode] = useState(selectedNode)

    const handleChangeSubject = (e: ChangeEvent<HTMLInputElement>) => {
        setCurrentNode({
            ...currentNode,
            subject: e.target.value,
        })
    }

    const handleChangeBody = (e: ChangeEvent<HTMLInputElement>) => {
        setCurrentNode({
            ...currentNode,
            body: adaptTextToExpression(e.target.value),
        })
    }

    const handleChangeRecipients = (e: ChangeEvent<HTMLInputElement>) => {
        // Split by commas and remove whitespace
        const recipients = e.target.value.split(",").map((email) => email.trim())
        setCurrentNode({
            ...currentNode,
            recipientAddresses: recipients,
        })
    }

    const configurationNodeItemClassName = cls("flows-configurationNode-item", "flows-editor-sideBar-item")

    return (
        <ConfigurationNode configuredNode={currentNode} unselectCallback={unselectCallback}>
            <Stack gap={2} className={configurationNodeItemClassName}>
                <FormattedMessage tagName="h5" {...messages.sendEmailConfiguration.title} />

                <TextField
                    label={formatMessage(messages.sendEmailConfiguration.subjectLabel)}
                    value={currentNode.subject}
                    size="small"
                    onChange={handleChangeSubject}
                />

                <TextField
                    label={formatMessage(messages.sendEmailConfiguration.toLabel)}
                    value={currentNode.recipientAddresses.join(", ")}
                    size="small"
                    onChange={handleChangeRecipients}
                    helperText={formatMessage(messages.sendEmailConfiguration.toHelper)}
                />

                <TextField
                    label={formatMessage(messages.sendEmailConfiguration.bodyLabel)}
                    value={adaptExpressionToText(currentNode.body)}
                    multiline
                    rows={10}
                    onChange={handleChangeBody}
                />
            </Stack>
        </ConfigurationNode>
    )
}
