import axiosClient from "./axiosClient"

const BASE_URL = import.meta.env.VITE_API_USER_URL + "passwordless/"

export const passwordLessApi = {
    sendCode(email: string) {
        const url = BASE_URL + "send-code"
        return axiosClient.post(url, {
            email,
        })
    },

    checkCode(email: string, code: string): Promise<{ access_token: string; userId: string }> {
        const url = BASE_URL + "validate-code"
        return axiosClient.post(url, {
            email,
            code,
        })
    },
}
