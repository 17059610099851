import React, { Dispatch, SetStateAction, useCallback, useMemo } from "react"

import { SelectedTagI, SelectedTagWithObjectIdI, TagGroupI } from "~/domains/tags/types"
import { useTagsForLine } from "~/domains/transactions/components/Items/hooks"

import { TagsSelectorStateless } from "./TagsSelectorStateless"

interface Props {
    objectId: string | null
    tags: SelectedTagWithObjectIdI[]
    setTags: Dispatch<SetStateAction<SelectedTagI[]>>
    tagGroups: TagGroupI[]
    tagGroupId?: string
    usedTagGroups?: TagGroupI[]
    readonly?: boolean
}

export const TagsSelectorForLineCells: React.FC<Props> = ({
    objectId,
    tags,
    setTags,
    tagGroups,
    tagGroupId,
    usedTagGroups,
    readonly,
}) => {
    const tagGroupsForSelector = useMemo(
        () =>
            tagGroupId
                ? tagGroups?.filter((tagGroup) => tagGroup.tagGroupId === tagGroupId) ?? []
                : tagGroups?.filter((tagGroup) => !usedTagGroups?.includes(tagGroup)) ?? [],
        [tagGroups, usedTagGroups, tagGroupId]
    )
    const lineTags = useMemo(
        () => tags.filter((tag) => tag.objectId === objectId && tag.tagGroupId === tagGroupId),
        [tags, objectId, tagGroupId]
    )

    const setSelectedTags = useCallback(
        (selectedTags: SelectedTagI[] | ((selectedTags: SelectedTagI[]) => SelectedTagI[])) => {
            const newTags = Array.isArray(selectedTags) ? selectedTags : selectedTags(lineTags)
            return setTags([
                ...tags.filter(
                    (tag) => tag.objectId !== objectId || (tag.objectId === objectId && tag.tagGroupId !== tagGroupId)
                ),
                ...newTags.map((t) => ({ ...t, objectId })),
            ])
        },
        [tags, objectId, tagGroupId, lineTags, setTags]
    )

    return (
        <TagsSelectorStateless
            readonly={readonly}
            selectedTags={lineTags}
            setSelectedTags={setSelectedTags}
            tagsRecommandations={null}
            tagGroups={tagGroupsForSelector}
        />
    )
}

type PropsWithState = Omit<Omit<Omit<Props, "objectId">, "tags">, "setTags"> & {
    temporaryId: string
}

export const TagsSelectorWithStateForLineCells: React.FC<PropsWithState> = ({
    tagGroups,
    tagGroupId,
    usedTagGroups,
    temporaryId,
}) => {
    const [selectedTags, setSelectedTags] = useTagsForLine(temporaryId ?? "")

    const tagGroupsForSelector = useMemo(
        () =>
            tagGroupId
                ? tagGroups?.filter((tagGroup) => tagGroup.tagGroupId === tagGroupId) ?? []
                : tagGroups?.filter((tagGroup) => !usedTagGroups?.includes(tagGroup)) ?? [],
        [tagGroups, usedTagGroups, tagGroupId]
    )

    const tagsForSelector = useMemo(
        () => (tagGroupId ? selectedTags.filter((tag) => tag.tagGroupId === tagGroupId) : []),
        [selectedTags, tagGroupId]
    )

    return (
        <TagsSelectorStateless
            selectedTags={tagsForSelector}
            setSelectedTags={setSelectedTags}
            tagsRecommandations={null}
            tagGroups={tagGroupsForSelector}
        />
    )
}
