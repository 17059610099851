import { Grid, Stack, Tooltip } from "@mui/material"
import { FC } from "react"
import { CheckCircle, CheckSquare, CreditCard, DollarSign, Edit2, Filter, Link, Mail, Zap } from "react-feather"
import { FormattedMessage, useIntl } from "react-intl"

import { messages } from "~/domains/orchestration/flows/messages"
import { NodeType } from "~/domains/orchestration/flows/types"

interface Props {
    handleDragStart: (nodeType: NodeType) => (event: React.DragEvent<HTMLDivElement>) => void
    handleClick: (nodeType: NodeType) => void
    showLabels?: boolean
    direction?: "row" | "column"
}

const NODE_LIST = [
    { type: NodeType.EVENT_TRIGGER_NODE, Icon: Zap, messageKey: "eventTriggerNode" },
    { type: NodeType.IF_NODE, Icon: Filter, messageKey: "ifNode" },
    { type: NodeType.SET_PARTNERSHIP_FIELD_NODE, Icon: Link, messageKey: "setPartnershipFieldNode" },
    { type: NodeType.CHECK_NODE, Icon: CheckCircle, messageKey: "checkNode" },
    { type: NodeType.SEND_EMAIL_NODE, Icon: Mail, messageKey: "sendEmailNode" },
    { type: NodeType.ADD_TO_BUDGET_NODE, Icon: DollarSign, messageKey: "addToBudgetNode" },
    {
        type: NodeType.INVOICE_TO_PURCHASE_ORDER_MATCH_NODE,
        Icon: CheckSquare,
        messageKey: "invoiceToPurchaseOrderMatchNode",
    },
    {
        type: NodeType.SET_INVOICE_LIFECYCLE_STATUS_NODE,
        Icon: Edit2,
        messageKey: "setInvoiceLifecycleStatusNode",
    },
    {
        type: NodeType.FITS_TO_BUDGET_NODE,
        Icon: CreditCard,
        messageKey: "fitsToBudgetNode",
    },
]

export const NodeList: FC<Props> = ({ handleDragStart, handleClick, showLabels = false, direction = "row" }) => {
    const { formatMessage } = useIntl()

    const handleNodeClick = (nodeType: NodeType) => (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation()
        handleClick(nodeType)
    }

    return (
        <Stack gap={1} direction={direction}>
            {NODE_LIST.map(({ type, Icon, messageKey }) => (
                <div
                    className="flows-editor-sideBar-item"
                    key={type}
                    onDragStart={handleDragStart(type)}
                    onClick={handleNodeClick(type)}
                    draggable
                >
                    <Grid container justifyContent="space-between" className="flows-editor-sideBar-item-title">
                        {showLabels ? (
                            <Icon size={18} />
                        ) : (
                            <Tooltip placement="top" title={formatMessage(messages[messageKey].sideBarLabel)}>
                                <Icon size={18} />
                            </Tooltip>
                        )}
                        {showLabels && <FormattedMessage tagName="h5" {...messages[messageKey].sideBarLabel} />}
                    </Grid>
                </div>
            ))}
        </Stack>
    )
}
