import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { prepareHeadersWithAuthorizationAndOrganizationId as prepareHeaders } from "~/api/prepareHeaders"

import { ApprovalApiResponse, ToCheckObjectsResponse } from "../types/Approval"

const BASE_URL = import.meta.env.VITE_API_APPROVAL_URL

export enum FetchErrorType {
    HTTP_REQUEST_ERROR = "HTTP_REQUEST_ERROR",
    PARSING_ERROR = "PARSING_ERROR",
}

export const approvalApi = createApi({
    reducerPath: "approvalApi",
    baseQuery: fetchBaseQuery({ baseUrl: `${BASE_URL}${"v1"}`, prepareHeaders }),
    endpoints: (builder) => ({
        getObjectChecks: builder.query<ApprovalApiResponse, { objectId: string; objectType: string }>({
            query: ({ objectId, objectType }) => ({
                url: `/objects/${objectId}/checks-progress/`,
                params: { objectType },
            }),
        }),
        approveObject: builder.mutation({
            query: ({ objectId, objectType }: { objectId: string; objectType: string }) => ({
                url: `/objects/${objectId}/approve/`,
                method: "POST",
                params: { objectType },
                responseHandler: async (response) => {
                    return response
                },
            }),
        }),
        refuseObject: builder.mutation({
            query: ({ objectId, objectType }: { objectId: string; objectType: string }) => ({
                url: `/objects/${objectId}/refuse/`,
                method: "POST",
                params: { objectType },
                responseHandler: async (response) => {
                    return response
                },
            }),
        }),
        getToCheckObjects: builder.query<ToCheckObjectsResponse, { organizationId: string; objectType: string }>({
            query: ({ organizationId, objectType }) => ({
                url: `/organizations/${organizationId}/objects/to-check`,
                params: { objectType },
            }),
        }),
    }),
})

export const { useGetObjectChecksQuery, useApproveObjectMutation, useRefuseObjectMutation, useGetToCheckObjectsQuery } =
    approvalApi
