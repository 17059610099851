import { combineReducers, configureStore } from "@reduxjs/toolkit"
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistStore } from "redux-persist"
import createSagaMiddleware from "redux-saga"

import documentReducer from "~/components/UploadDocument/store/documentSlice"
import { communicationReducer } from "~/domains/communication/store/CommunicationSlice"
import { catalogApiV1 } from "~/domains/identity/features/catalog/catalogApiV1"
import { catalogReducer } from "~/domains/identity/features/catalog/store/catalogSlice"
import { documentsReducer } from "~/domains/identity/features/documents/store/documentsSlice"
import rolesPermissionsReducer from "~/domains/identity/store/rolesPermissionsSlice"
import { flowsApi } from "~/domains/orchestration/flows/api/flowsApi"
import { runsApi } from "~/domains/orchestration/flows/api/runsApi"
import { paymentMethodDetailsApi } from "~/domains/payment/payment-method-details/api/paymentMethodDetailsApi"
import paymentMethodsDetailsReducer from "~/domains/payment/payment-method-details/store/paymentMethodDetailsSlice"
import { paymentMethodsApi } from "~/domains/payment/payment-methods/api/paymentMethodsApi"
import { paymentTermsApi } from "~/domains/payment/payment-terms/api/paymentTermsApi"
import { paymentApi } from "~/domains/payment/payment/api/paymentApi"
import { tagsReducer } from "~/domains/tags/store/tagsSlice"
import bookOfRelationsReducer from "~/domains/transactions/book-of-relations/store/bookOfRelationsSlice"
import { customFieldsReducer } from "~/domains/transactions/custom-fields/store/customFieldsSlice"
import documentRelationsReducer from "~/domains/transactions/document-relations/store/documentRelationsSlice"
import { emailsReducer } from "~/domains/transactions/emails/store/emailsSlice"
import { invoiceApiV1 } from "~/domains/transactions/invoices-v1/api/invoiceApiV1"
import { invoiceLifeCycleApi } from "~/domains/transactions/invoices-v1/api/invoiceLifeCycleApi"
import purchaseOrdersReducer from "~/domains/transactions/purchase-orders/store/purchaseOrdersSlice"
import purchaseRequestsReducer from "~/domains/transactions/purchase-requests/store/purchaseRequestsSlice"
import { approvalApi } from "~/features/workflow/core/approvalApi"
import authReducer from "~/store/auth/authSlice"
import errorsReducer from "~/store/errors/errorsSlice"
import globalReducer from "~/store/global/globalSlice"
import invoiceReducer from "~/store/invoice/invoiceSlice"
import ocrReducer from "~/store/ocr/ocrSlice"
import paymentReducer from "~/store/payment/paymentSlice"

import accountReducer from "./account/accountSlice"
import budgetReducer from "./budget/budgetSlice"
import organizationReducer from "./organization/organizationSlice"
import rootSaga from "./rootSaga"
import usersReducer from "./users/usersSlice"
import workflowReducer from "./workflow/workflowSlice"

const rootReducer = combineReducers({
    errors: errorsReducer,
    global: globalReducer,
    auth: authReducer,
    invoice: invoiceReducer,
    ocr: ocrReducer,
    organization: organizationReducer,
    account: accountReducer,
    payment: paymentReducer,
    tags: tagsReducer,
    users: usersReducer,
    workflow: workflowReducer,
    purchaseRequests: purchaseRequestsReducer,
    purchaseOrders: purchaseOrdersReducer,
    rolesPermissions: rolesPermissionsReducer,
    budget: budgetReducer,
    document: documentReducer,
    customFields: customFieldsReducer,
    documentRelations: documentRelationsReducer,
    communication: communicationReducer,
    bookOfRelations: bookOfRelationsReducer,
    catalog: catalogReducer,
    emails: emailsReducer,
    documents: documentsReducer,
    paymentMethodDetails: paymentMethodsDetailsReducer,
    [flowsApi.reducerPath]: flowsApi.reducer,
    [paymentApi.reducerPath]: paymentApi.reducer,
    [paymentMethodsApi.reducerPath]: paymentMethodsApi.reducer,
    [paymentMethodDetailsApi.reducerPath]: paymentMethodDetailsApi.reducer,
    [paymentTermsApi.reducerPath]: paymentTermsApi.reducer,
    [runsApi.reducerPath]: runsApi.reducer,
    [invoiceApiV1.reducerPath]: invoiceApiV1.reducer,
    [invoiceLifeCycleApi.reducerPath]: invoiceLifeCycleApi.reducer,
    [approvalApi.reducerPath]: approvalApi.reducer,
    [catalogApiV1.reducerPath]: catalogApiV1.reducer,
})

const sagaMiddleware = createSagaMiddleware()

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    "ocr/uploadDocument",
                    "ocr/confirmInvoiceFailed",
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                ],
            },
        }).concat(
            sagaMiddleware,
            flowsApi.middleware,
            paymentApi.middleware,
            paymentMethodsApi.middleware,
            paymentMethodDetailsApi.middleware,
            paymentTermsApi.middleware,
            runsApi.middleware,
            invoiceApiV1.middleware,
            invoiceLifeCycleApi.middleware /* , routerMiddleware(history) */,
            approvalApi.middleware,
            catalogApiV1.middleware
        ),
})
export const persistor = persistStore(store)

sagaMiddleware.run(rootSaga)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
