import { styled } from "@mui/material"

import { InvoiceLineError } from "~/features/invoice/core"

export const IconContainer = styled("div")({
    position: "absolute",
    top: "12px",
    right: "8px",
    color: "var(--color-yellow)",
})

export const FieldContainer = styled("div", {
    shouldForwardProp: (prop) => prop !== "error" && prop !== "width",
})<{ error?: InvoiceLineError | null; flex?: string; minwidth?: string }>(({ error, flex, minwidth }) => ({
    position: "relative",
    minWidth: minwidth ? minwidth : "100px",
    display: "inline-flex",
    flex,
    "> .MuiFormControl-root": {
        backgroundColor: error ? "var(--color-yellow-100)" : "none",
        "> .MuiInputBase-root > input": {
            background: error ? "var(--color-yellow-100) !important" : "none",
            paddingRight: error ? "24px" : 0,
        },
    },
}))

export const TooltipContainer = styled("div")<{ flex?: string }>(({ flex }) => ({
    position: "relative",
    display: "inline-flex",
    flex,
}))
