import { Opaque } from "~/utils"

export type ApiFlowId = Opaque<string, { readonly T: unique symbol }>

type ApiFilter = {
    conditions: Array<Array<string>>
}

export type ApiNode = {
    name?: string
    slug: string
    metadata: Record<string, unknown>
}

export enum ApiUserType {
    USER = "User",
    TEAM = "Team",
}

export type ApiUserReviewer = {
    userId: string
    type: ApiUserType.USER
}

export type ApiTeamReviewer = {
    teamId: string
    type: ApiUserType.TEAM
}

export type ApiReviewer = ApiUserReviewer | ApiTeamReviewer

export type ApiEventTriggerNode = ApiNode & {
    objectType: ApiObjectType
    objectEvent: ApiObjectEvent
    filter?: ApiFilter
    type: ApiNodeType.EVENT_TRIGGER_NODE
    nextNode?: string
}

export type ApiSetPartnershipFieldNode = ApiNode & {
    partnershipId: string
    fieldToUpdate: string
    valueToSet: string
    type: ApiNodeType.SET_PARTNERSHIP_FIELD_NODE
    nextNode?: string
}

export type ApiIfNode = ApiNode & {
    slug: string
    condition: string
    nextIfTrue?: string
    nextIfFalse?: string
    type: ApiNodeType.IF_NODE
}

export type ApiCheckNode = ApiNode & {
    objectId: string
    objectType: ApiObjectType
    reviewers?: ApiReviewer[]
    passThreshold: number
    refuseThreshold: number
    nextIfPassed?: string
    nextIfRefused?: string
    type: ApiNodeType.CHECK_NODE
}

export type ApiSendEmailNode = ApiNode & {
    subject: string
    recipientAddresses: string[]
    body: string
    type: ApiNodeType.SEND_EMAIL_NODE
    nextNode?: string
}

export type ApiAddToBudgetNode = ApiNode & {
    budgetId: string
    transactionId: string
    transactionType: string
    amount: string
    failIfOverbudget: boolean
    type: ApiNodeType.ADD_TO_BUDGET_NODE
    currency?: string
    nextNode?: string
}

export type ApiInvoiceToPurchaseOrderMatchNode = ApiNode & {
    invoiceId: string
    type: ApiNodeType.INVOICE_TO_PURCHASE_ORDER_MATCH_NODE
    nextIfMatched?: string
    nextIfUnmatched?: string
}

export type ApiSetInvoiceLifecycleStatusNode = ApiNode & {
    invoiceId: string
    statusToSet: string
    type: ApiNodeType.SET_INVOICE_LIFECYCLE_STATUS_NODE
    nextNode?: string
}

export type ApiFitsToBudgetNode = ApiNode & {
    budgetId: string
    transactionId: string
    transactionType: string
    amount: string
    currency: string
    type: ApiNodeType.FITS_TO_BUDGET_NODE
    nextIfFits?: string
    nextIfDoesNotFit?: string
}

export type ApiFlows = {
    flows: ApiFlowItem[]
}

export type ApiFlowItem = {
    id: ApiFlowId
    version: number
    name: string
    enabled: boolean
    archived: boolean
    createdAt: string
}

export type ApiFlowNode =
    | ApiEventTriggerNode
    | ApiIfNode
    | ApiSetPartnershipFieldNode
    | ApiCheckNode
    | ApiSendEmailNode
    | ApiAddToBudgetNode
    | ApiInvoiceToPurchaseOrderMatchNode
    | ApiSetInvoiceLifecycleStatusNode
    | ApiFitsToBudgetNode

export type ApiFlow = ApiFlowItem & {
    nodes: ApiFlowNode[]
    author: string
}

export type ApiFlowUpdate = Omit<ApiFlow, "id" | "author">

export enum ApiNodeType {
    EVENT_TRIGGER_NODE = "EventTriggerNode",
    HTTP_NODE = "HttpNode",
    SET_PARTNERSHIP_FIELD_NODE = "SetPartnershipFieldNode",
    IF_NODE = "IfNode",
    CHECK_NODE = "CheckNode",
    SEND_EMAIL_NODE = "SendEmailNode",
    ADD_TO_BUDGET_NODE = "AddToBudgetNode",
    INVOICE_TO_PURCHASE_ORDER_MATCH_NODE = "InvoiceToPurchaseOrderMatchNode",
    SET_INVOICE_LIFECYCLE_STATUS_NODE = "SetInvoiceLifecycleStatusNode",
    FITS_TO_BUDGET_NODE = "FitsToBudgetNode",
}

export enum ApiObjectType {
    ORGANIZATION_RELATIONSHIP = "OrganizationRelationship",
    INVOICE = "Invoice",
    PURCHASE_ORDER = "PurchaseOrder",
    PURCHASE_REQUEST = "PurchaseRequest",
    CONTACT = "Contact",
    PAYMENT = "Payment",
}

export enum ApiObjectEvent {
    CREATED = "Created",
    UPDATED = "Updated",
}

// type guard to know if node is ApiFlowNode
export const isApiFlowNode = (node: unknown): node is ApiFlowNode => {
    return node !== null && typeof node === "object" && "slug" in node && "type" in node
}
