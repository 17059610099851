import { useCallback, useState } from "react"
import { useAppDispatch } from "~/store/hooks"
import { OrganizationId, UnifiedAuthorizationName } from "~/types"
import { toast } from "react-toastify"
import { defineMessages, useIntl } from "react-intl"
import { rolesPermissionsApi } from "~/api/rolesPermissionsApi"
import { purchaseRequestsActions } from "~/domains/transactions/purchase-requests/store/purchaseRequestsSlice"

const messages = defineMessages({
    error: {
        id: "purchase.requests.create.error",
        defaultMessage: "Something went wrong. Please contact your administrator",
    },
})

export const useCheckPurchaseRequestsPermission = () => {
    const dispatch = useAppDispatch()
    const { formatMessage } = useIntl()
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>("")

    const checkPurchaseRequestsPermission = useCallback(
        async (organizationId: OrganizationId, authorizations: UnifiedAuthorizationName[]): Promise<undefined> => {
            try {
                setLoading(true)

                const permissionsRecord = await rolesPermissionsApi.checkPurchaseRequestPermissions(
                    organizationId,
                    authorizations
                )

                authorizations.map((authorization) => {
                    dispatch(
                        purchaseRequestsActions.setPurchaseRequestsPermissions({
                            [authorization]: permissionsRecord[authorization],
                        })
                    )
                })
            } catch (e) {
                setError(`${e}`)
                toast.error(formatMessage(messages.error))
                throw e
            } finally {
                setLoading(false)
            }
        },
        [dispatch]
    )

    return {
        checkPurchaseRequestsPermission,
        error,
        loading,
    }
}
