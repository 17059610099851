import { TextField } from "@mui/material"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { enUS, frFR } from "@mui/x-date-pickers/locales"
import dayjs, { Dayjs } from "dayjs"
import "dayjs/locale/fr"
import React, { ChangeEvent, useCallback, useEffect, useState } from "react"
import { defineMessages, useIntl } from "react-intl"

import { Card } from "~/components"
import { SkeletonInput } from "~/components/Skeleton"
import { useHasInvoiceRelation } from "~/domains/transactions/document-relations/hooks"
import { OCRInputTypes } from "~/features/invoice/components/OcrExtract/extract"
import { useOcrExtract } from "~/features/invoice/components/OcrExtract/useOcrExtract"
import { getOcrInvoiceCurrency } from "~/features/invoice/core"
import { EditAmount } from "~/features/supplier/components/EditAmount"
import {
    DocumentWithDueDate,
    DocumentWithPrice,
    DocumentWithPurchaseOrderNumber,
    DocumentWithReference,
} from "~/types/"

const FR_LOCALE = "fr"

interface ExtractProps {
    ocrData: DocumentWithPurchaseOrderNumber & DocumentWithReference & DocumentWithDueDate & DocumentWithPrice
    updateData: (
        data: Partial<DocumentWithPurchaseOrderNumber & DocumentWithReference & DocumentWithDueDate & DocumentWithPrice>
    ) => void
    updateTotal?: (amount: number) => void
    updateTotalExcludedTaxes?: (amount: number) => void
    updateDueDate?: (date: string) => void
    dataLoaded: boolean
    disabled?: boolean
    invoiceId: string | undefined
}

const messages = defineMessages({
    title: {
        id: "supplier.extract.title",
        defaultMessage: "Informations",
    },
    reference: {
        id: "supplier.extract.reference",
        defaultMessage: "Document ref.",
    },
    purchaseOrderNumber: {
        id: "supplier.extract.purchaseOrderNumber",
        defaultMessage: "Purchase order number",
    },
    deadLine: {
        id: "supplier.extract.deadline",
        defaultMessage: "Payment deadline",
    },
    issueDate: {
        id: "buyer.invoice.page.issueDate",
        defaultMessage: "issue date (MM/DD/YYYY)",
    },
    dueDate: {
        id: "buyer.invoice.page.dueDate",
        defaultMessage: "due date (MM/DD/YYYY)",
    },
    totalNoTax: {
        id: "supplier.extract.totalNoTax",
        defaultMessage: "Total (taxes excl.)",
    },
    totalDiscount: {
        id: "supplier.extract.totalDiscount",
        defaultMessage: "Total discount",
    },
    total: {
        id: "supplier.extract.total",
        defaultMessage: "Total (taxes incl.)",
    },
    editLines: {
        id: "supplier.extract.editLine",
        defaultMessage: "View invoice items",
    },
})

export function Extract({ ocrData, updateData, dataLoaded, disabled, invoiceId }: ExtractProps) {
    const [issueDateOpen, setIssueDateOpen] = useState<boolean>(false)
    const [dueDateOpen, setDueDateOpen] = useState<boolean>(false)
    const { formatMessage, locale } = useIntl()
    const { toggleShowShapes } = useOcrExtract()
    const currency = getOcrInvoiceCurrency(ocrData)
    const textLocale =
        locale === FR_LOCALE
            ? frFR.components.MuiLocalizationProvider.defaultProps.localeText
            : enUS.components.MuiLocalizationProvider.defaultProps.localeText

    const hasInvoiceRelations = useHasInvoiceRelation(invoiceId)

    useEffect(() => {
        if (ocrData && !ocrData.total && ocrData.totalExcludedTaxes) {
            updateData({ total: ocrData.totalExcludedTaxes })
        }
    }, [ocrData])

    const onReferenceChange = useCallback(
        (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            updateData({
                reference: event.currentTarget.value,
            })
        },
        [updateData]
    )

    const onPurchaseOrderNumberChange = useCallback(
        (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            updateData({
                purchaseOrderNumber: event.currentTarget.value,
            })
        },
        [updateData]
    )

    const handleUpdateIssueDate = useCallback(
        (newDate: Dayjs | null) => {
            if (newDate) {
                updateData({ issueDate: newDate?.format("YYYY-MM-DD") ?? null })
            }
        },
        [updateData]
    )

    const handleUpdateDueDate = useCallback(
        (newDate: Dayjs | null) => {
            if (newDate) {
                updateData({ dueDate: newDate?.format("YYYY-MM-DD") ?? null })
            }
        },
        [updateData]
    )

    const handleUpdateAmountExcludingTaxes = useCallback(
        (newAmount: number | null) => {
            if (newAmount !== null) {
                updateData({ totalExcludedTaxes: newAmount })
            }
        },
        [updateData]
    )

    const handleUpdateAmountTotalDiscount = useCallback(
        (newAmount: number | null) => {
            if (newAmount !== null) {
                updateData({ totalDiscount: newAmount })
            }
        },
        [updateData]
    )

    const handleUpdateAmountIncludingTaxes = useCallback(
        (newAmount: number | null) => {
            if (newAmount !== null) {
                updateData({ total: newAmount })
            }
        },
        [updateData]
    )

    if (!ocrData) {
        return null
    }
    return (
        <Card title={formatMessage(messages.title)} expandable>
            {!dataLoaded ? (
                <div>
                    <SkeletonInput />
                    <SkeletonInput />
                    <SkeletonInput />
                    <SkeletonInput />
                    <SkeletonInput />
                    <SkeletonInput />
                    <SkeletonInput />
                </div>
            ) : (
                <div>
                    <div className="invoice-field mb-12">
                        <div className="invoice-field-container">
                            <TextField
                                required
                                id="reference"
                                name="reference"
                                label={formatMessage(messages.reference)}
                                placeholder={formatMessage(messages.reference)}
                                onChange={onReferenceChange}
                                value={ocrData?.reference ?? ""}
                                disabled={disabled}
                                onFocus={() => toggleShowShapes("reference", OCRInputTypes.TEXT)}
                            />
                        </div>
                        {/* <ToggleShowShapes inputName="reference" inputType="text" /> */}
                    </div>
                    {!hasInvoiceRelations && (
                        <div className="invoice-field mb-12">
                            <div className="invoice-field-container">
                                <TextField
                                    id="purchaseOrderNumber"
                                    name="purchaseOrderNumber"
                                    label={formatMessage(messages.purchaseOrderNumber)}
                                    placeholder={formatMessage(messages.purchaseOrderNumber)}
                                    onChange={onPurchaseOrderNumberChange}
                                    value={ocrData?.purchaseOrderNumber ?? ""}
                                    fullWidth
                                    disabled={disabled}
                                    onFocus={() => toggleShowShapes("purchaseOrderNumber", OCRInputTypes.TEXT)}
                                />
                            </div>
                            {/* <ToggleShowShapes inputName="purchaseOrderNumber" inputType="text" /> */}
                        </div>
                    )}
                    <div className="invoice-field mb-12">
                        <div className="invoice-field-container">
                            <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale={locale}
                                localeText={textLocale}
                            >
                                <DatePicker
                                    open={issueDateOpen}
                                    onOpen={() => setIssueDateOpen(true)}
                                    onClose={() => setIssueDateOpen(false)}
                                    label={formatMessage(messages.issueDate)}
                                    value={ocrData.issueDate ? dayjs(ocrData.issueDate) : null}
                                    onChange={(newValue) => {
                                        handleUpdateIssueDate(newValue)
                                    }}
                                    disabled={disabled}
                                    slotProps={{
                                        textField: {
                                            required: true,
                                            onClick: () => setIssueDateOpen(true),
                                        },
                                    }}
                                />
                            </LocalizationProvider>
                        </div>
                        {/* <ToggleShowShapes inputName="dueDate" inputType="date" /> */}
                    </div>
                    <div className="invoice-field mb-12">
                        <div className="invoice-field-container">
                            <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale={locale}
                                localeText={textLocale}
                            >
                                <DatePicker
                                    open={dueDateOpen}
                                    onOpen={() => setDueDateOpen(true)}
                                    onClose={() => setDueDateOpen(false)}
                                    label={formatMessage(messages.dueDate)}
                                    value={ocrData.dueDate ? dayjs(ocrData.dueDate) : null}
                                    onChange={(newValue) => {
                                        handleUpdateDueDate(newValue)
                                    }}
                                    disabled={disabled}
                                    slotProps={{
                                        textField: {
                                            required: true,
                                            onClick: () => setDueDateOpen(true),
                                        },
                                    }}
                                />
                            </LocalizationProvider>
                        </div>
                    </div>
                    <div className="invoice-field mb-12">
                        <div className="invoice-field-container">
                            <EditAmount
                                value={ocrData.totalExcludedTaxes ?? 0}
                                id="totalExcludedTaxes"
                                placeholder={formatMessage(messages.totalNoTax)}
                                handleUpdate={handleUpdateAmountExcludingTaxes}
                                disabled={disabled}
                                currency={currency}
                            />
                        </div>
                        {/* <ToggleShowShapes inputName="totalExcludedTaxes" inputType="amount" /> */}
                    </div>
                    <div className="invoice-field mb-12">
                        <div className="invoice-field-container">
                            <EditAmount
                                value={ocrData.totalDiscount ?? 0}
                                id="totalDiscount"
                                placeholder={formatMessage(messages.totalDiscount)}
                                handleUpdate={handleUpdateAmountTotalDiscount}
                                disabled={disabled}
                                currency={currency}
                            />
                        </div>
                        {/* <ToggleShowShapes inputName="totalDiscount" inputType="amount" /> */}
                    </div>
                    <div className="invoice-field mb-12">
                        <div className="invoice-field-container">
                            <EditAmount
                                value={ocrData.total ?? 0}
                                id="total"
                                placeholder={formatMessage(messages.total)}
                                handleUpdate={handleUpdateAmountIncludingTaxes}
                                disabled={disabled}
                                currency={currency}
                            />
                        </div>
                        {/* <ToggleShowShapes inputName="total" inputType="amount" /> */}
                    </div>
                </div>
            )}
        </Card>
    )
}
