import { Stack, TextField } from "@mui/material"
import cls from "classnames"
import React, { ChangeEvent, FC, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"

import { Card } from "~/components"
import { adaptExpressionToText, adaptTextToExpression } from "~/domains/orchestration/flows/core"
import { messages } from "~/domains/orchestration/flows/messages"
import { SetInvoiceLifecycleStatusNode } from "~/domains/orchestration/flows/types"

import { ConfigurationNode } from "./ConfigurationNode"

interface Props {
    selectedNode: SetInvoiceLifecycleStatusNode
    unselectCallback: () => void
}

export const SetInvoiceLifecycleStatusConfiguration: FC<Props> = ({ selectedNode, unselectCallback }) => {
    const { formatMessage } = useIntl()

    // Handle the current node
    const [currentNode, setCurrentNode] = useState(selectedNode)

    const handleChangeStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
        const statusToSet = adaptTextToExpression(e.target.value)
        setCurrentNode({ ...currentNode, statusToSet: statusToSet })
    }

    const handleChangeAdvanced = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        setCurrentNode({
            ...currentNode,
            invoiceId: value,
        })
    }

    const configurationNodeItemClassName = cls("flows-configurationNode-item", "flows-editor-sideBar-item")

    return (
        <ConfigurationNode configuredNode={currentNode} unselectCallback={unselectCallback}>
            <Stack gap={2} className={configurationNodeItemClassName}>
                <TextField
                    label={formatMessage(messages.setInvoiceLifecycleStatusConfiguration.statusLabel)}
                    value={adaptExpressionToText(currentNode.statusToSet)}
                    onChange={handleChangeStatus}
                />
            </Stack>
            <Stack gap={2} className={configurationNodeItemClassName}>
                <Card
                    expandable
                    header={<FormattedMessage {...messages.configurationNode.advanced} />}
                    isExpandedByDefault={false}
                >
                    <TextField
                        label={formatMessage(messages.setInvoiceLifecycleStatusConfiguration.invoiceIdLabel)}
                        value={currentNode.invoiceId}
                        onChange={handleChangeAdvanced}
                    />
                </Card>
            </Stack>
        </ConfigurationNode>
    )
}
