import { useEffect } from "react"

import { isResultSuccess } from "~/core/Result"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { OrganizationId } from "~/types"

import { useDocumentRelationsApi } from "../documentRelationsApi"
import { documentRelationsActions, selectInvoiceRelationsState } from "../store/documentRelationsSlice"

export const useInvoiceRelations = (organizationId: OrganizationId, invoiceId: string) => {
    const { loading, error, invoiceRelations } = useAppSelector(selectInvoiceRelationsState)

    const documentRelationsApi = useDocumentRelationsApi()
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(documentRelationsActions.fetchInvoiceRelations({ invoiceId }))

        documentRelationsApi.getInvoiceRelations(organizationId, invoiceId).then((result) => {
            if (isResultSuccess(result)) {
                dispatch(
                    documentRelationsActions.fetchInvoiceRelationsSuccess({
                        invoiceId,
                        invoiceRelations: result.result,
                    })
                )
            } else {
                if (result.error.partialResults) {
                    dispatch(
                        documentRelationsActions.fetchInvoiceRelationsSuccess({
                            invoiceId,
                            invoiceRelations: result.error.partialResults,
                        })
                    )
                }
                dispatch(documentRelationsActions.fetchInvoiceRelationsFailure({ invoiceId, error: result.error }))
            }
        })
    }, [organizationId, invoiceId, documentRelationsApi])

    return { invoiceRelations, loading, error }
}
