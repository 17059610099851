import React from "react"
import { useIntl } from "react-intl"

import { tagGroupsAndTagsMessages } from "~/domains/tags/core/messages"
import { isSelectedTagI } from "~/domains/tags/core/tagGroupsAndTags"
import { selectTagGroups } from "~/domains/tags/store/tagsSlice"
import { SelectedTagI, TagAndTagGroupNameI } from "~/domains/tags/types"
import { useAppSelector } from "~/store/hooks"
import { stopPropagation } from "~/utils"

import "./TagTooltip.scss"
import { TagTooltipPath } from "./TagTooltipPath"

interface TagTooltipProps {
    tag: TagAndTagGroupNameI | SelectedTagI | undefined
}

const getInfoContainer = (label: string, value: JSX.Element | string | null | undefined) => {
    value = value || "-"
    return (
        <div className="tag-tooltip-label-container">
            <div className="tag-tooltip-label">{label}</div>
            <div>{value}</div>
        </div>
    )
}

const MAX_DESCRIPTION_LENGTH = 150
export const TagTooltip: React.FC<TagTooltipProps> = ({ tag }) => {
    const { formatMessage, formatNumber } = useIntl()
    const tagGroups = useAppSelector(selectTagGroups)

    if (!tag) return null

    const hasRatio = isSelectedTagI(tag) && typeof tag.ratio === "number"
    const hasValue = Boolean(tag.value)
    const hasExternalId = Boolean(tag.externalId)
    const hasParent = Boolean(tag.parentId)
    const description =
        tag.description && tag.description.length > MAX_DESCRIPTION_LENGTH
            ? `${tag.description.slice(0, MAX_DESCRIPTION_LENGTH)}...`
            : tag.description

    return (
        <div className="tag-tooltip" onClick={stopPropagation}>
            {getInfoContainer(formatMessage(tagGroupsAndTagsMessages.name), tag.name)}
            {hasParent
                ? getInfoContainer(
                      formatMessage(tagGroupsAndTagsMessages.path),
                      <TagTooltipPath tag={tag} tagGroups={tagGroups} hasParent={hasParent} />
                  )
                : getInfoContainer(formatMessage(tagGroupsAndTagsMessages.groupName), tag.tagGroupName)}
            {hasRatio &&
                getInfoContainer(
                    formatMessage(tagGroupsAndTagsMessages.ratio),
                    formatNumber(tag.ratio as number, { style: "percent", maximumFractionDigits: 0 })
                )}
            {hasValue && getInfoContainer(formatMessage(tagGroupsAndTagsMessages.value), tag.value)}
            {hasExternalId && getInfoContainer(formatMessage(tagGroupsAndTagsMessages.externalId), tag.externalId)}
            {getInfoContainer(formatMessage(tagGroupsAndTagsMessages.description), description)}
        </div>
    )
}
