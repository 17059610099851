import { Avatar } from "@mui/material"

interface UserAvatarProps {
    email?: string | null
    size?: number
}

export const UserAvatar = ({ email, size = 22 }: UserAvatarProps) => {
    let hash = 0
    if (email) {
        for (let i = 0; i < email.length; i++) {
            hash = email.charCodeAt(i) + ((hash << 5) - hash)
        }
    }

    const rgb = [hash & 0xff, (hash >> 8) & 0xff, (hash >> 16) & 0xff]
    const bgcolor = email ? `rgb(${rgb.join(",")})` : "var(--color-primary)"

    return <Avatar sx={{ bgcolor, width: size, height: size, padding: 2 }}>{email?.charAt(0).toUpperCase()}</Avatar>
}

export default UserAvatar
