import { useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"

import { UserI, UserPartialI } from "~/types"

import { useAppDispatch } from "../hooks"
import { selectUsers, selectUsersStore, usersActions } from "./usersSlice"

const userIsDefined = (user: UserPartialI | undefined) => !!user

export const useGetAllUsersQuery = (userIds: string[]) => {
    const dispatch = useAppDispatch()
    const { users, loading, error } = useSelector(selectUsersStore)
    const selectedUsers = useMemo(() => userIds.map((userId) => users[userId]).filter(userIsDefined), [userIds, users])
    const [idsToFetch, setIdsToFetch] = useState<string[]>([])

    useEffect(() => {
        setIdsToFetch(userIds.filter((userId) => !users[userId]))
    }, [userIds, users])

    useEffect(() => {
        if (idsToFetch?.length > 0) {
            dispatch(usersActions.fetchUsers(idsToFetch))
        }
    }, [dispatch, idsToFetch])

    return {
        users: selectedUsers,
        loading,
        error,
    }
}

export const useSelectUserById = (userId: string): UserI | null => {
    const users = useSelector(selectUsers)
    return users[userId] ?? null
}
