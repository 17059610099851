import { FormControlLabel, styled } from "@mui/material"

export const ModalContainer = styled("form")({
    display: "flex",
    flexWrap: "wrap",
    alignItems: "stretch",
    margin: "0 -25px 0 -24px",
    padding: "12px 0",
})

export const ModalBlock = styled("div")({
    flex: "1 1 10%",
    minWidth: 220,
    padding: "8px 24px",
    borderRight: "1px solid var(--separator-color)",
    "&:last-child": {
        borderRight: 0,
    },
})

export const ModalMainBlock = styled(ModalBlock)({
    flex: "1 1 15%",
    minWidth: 330,
})

export const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    marginTop: "16px",
    marginLeft: 0,
    color: theme.palette.grey[600],
    "& .MuiTypography-root": {
        marginLeft: "8px",
    },
}))
