import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { CompanyI, EstablishmentI } from "~/types"

export const useEstablishmentState = (
    company: CompanyI | undefined,
    currentSiret?: string
): [EstablishmentI | undefined, Dispatch<SetStateAction<EstablishmentI | undefined>>] => {
    const [selectedEstablishment, setSelectedEstablishment] = useState<EstablishmentI>()

    useEffect(() => {
        if (currentSiret && company?.establishments && company.establishments.length > 0) {
            const establishment = company.establishments.find((est) => est.id === currentSiret)
            if (establishment) {
                setSelectedEstablishment(establishment)
            }
        } else if (company?.establishments?.length === 1) {
            const establishment = company.establishments[0]
            setSelectedEstablishment(establishment)
        }
    }, [company, setSelectedEstablishment, currentSiret])

    return [selectedEstablishment, setSelectedEstablishment]
}
