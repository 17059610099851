import { FC } from "react"

import { Card, InvoiceViewer } from "~/components"
import { usePatchUpdateInvoiceMutation } from "~/domains/transactions/invoices-v1/api/invoiceApiV1"
import { InvoiceParty } from "~/domains/transactions/invoices-v1/types/Invoice"
import { ImportInvoiceCompanyInfoI, InvoicePaymentDetailsI, PaymentDetailsI } from "~/types"
import { InvoiceI } from "~/types/InvoiceTypes"

interface InvoicePreviewEditProps {
    invoice: InvoiceI
}

export const InvoicePreviewEdit: FC<InvoicePreviewEditProps> = ({ invoice }) => {
    const [patchUpdateInvoice] = usePatchUpdateInvoiceMutation()

    const updateData = (data: Partial<InvoiceI>) => patchUpdateInvoice({ ...data, id: invoice.id } as InvoiceI)

    const updateBuyer = (data: Partial<ImportInvoiceCompanyInfoI | InvoiceParty>) =>
        patchUpdateInvoice({
            parties: data.organizationId ? { [data.organizationId]: data } : undefined,
            id: invoice.id,
        })

    const updateSupplier = (data: Partial<ImportInvoiceCompanyInfoI>) =>
        patchUpdateInvoice({
            parties: data.organizationId ? { [data.organizationId]: data } : undefined,
            id: invoice.id,
        })

    const updatePaymentData = (data: Partial<InvoicePaymentDetailsI>) =>
        patchUpdateInvoice({ paymentDetails: data as PaymentDetailsI, id: invoice.id })

    if (!invoice.fileLinks.originalImageLinks?.length)
        return (
            <Card>
                <p>No preview available</p>
            </Card>
        )

    return (
        <InvoiceViewer
            updateAction={updateData}
            updateBuyer={updateBuyer}
            updatePaymentData={updatePaymentData}
            updateSupplier={updateSupplier}
            imageUrls={invoice.fileLinks.originalImageLinks}
        />
    )
}
