import { styled } from "@mui/material"

export const LightButton = styled("button")(({ theme }) => ({
    border: 0,
    background: "none",
    fontFamily: "var(--font-family)",
    color: theme.palette.primary.main,
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    cursor: "pointer",
    "&:hover": {
        textDecoration: "underline",
    },
}))
