import { styled } from "@mui/material"
import classNames from "classnames"
import { MessageDescriptor, useIntl } from "react-intl"

import { PaymentMethodDetailsStatus } from "~/domains/payment/payment-method-details/types"

const messages: Record<PaymentMethodDetailsStatus, MessageDescriptor> = {
    [PaymentMethodDetailsStatus.ACTIVE]: {
        id: "payment.paymentMethodDetails.status.active",
        defaultMessage: "Active",
    },
    [PaymentMethodDetailsStatus.INACTIVE]: {
        id: "payment.paymentMethodDetails.status.inactive",
        defaultMessage: "Inactive",
    },
    [PaymentMethodDetailsStatus.EXPIRED]: {
        id: "payment.paymentMethodDetails.status.expired",
        defaultMessage: "Expired",
    },
    [PaymentMethodDetailsStatus.PENDING]: {
        id: "payment.paymentMethodDetails.status.pending",
        defaultMessage: "Pending",
    },
    [PaymentMethodDetailsStatus.PENDING_VERIFICATION]: {
        id: "payment.paymentMethodDetails.status.pendingVerification",
        defaultMessage: "Pending verification",
    },
    [PaymentMethodDetailsStatus.VERIFICATION_FAILED]: {
        id: "payment.paymentMethodDetails.status.verificationFailed",
        defaultMessage: "Verification failed",
    },
    [PaymentMethodDetailsStatus.VALID]: {
        id: "payment.paymentMethodDetails.status.valid",
        defaultMessage: "Valid",
    },
    [PaymentMethodDetailsStatus.INVALID]: {
        id: "payment.paymentMethodDetails.status.invalid",
        defaultMessage: "Invalid",
    },
    [PaymentMethodDetailsStatus.DELETED]: {
        id: "payment.paymentMethodDetails.status.deleted",
        defaultMessage: "Deleted",
    },
    [PaymentMethodDetailsStatus.SUSPENDED]: {
        id: "payment.paymentMethodDetails.status.suspended",
        defaultMessage: "Suspended",
    },
    [PaymentMethodDetailsStatus.UNKNOWN]: {
        id: "payment.paymentMethodDetails.status.unknown",
        defaultMessage: "Unknown",
    },
}

const Badge = styled("span")({
    borderRadius: "var(--border-radius-xxl)",
    padding: "3px var(--spacing-sx)",
    fontSize: "var(--font-size-md)",
    fontWeight: "var(--h4-font-weight)",
    "&.default": {
        backgroundColor: "var(--color-lighter-grey)",
        color: "var(--color-grey)",
    },
    "&.active": {
        backgroundColor: "var(--color-lighter-green)",
        color: "var(--color-green)",
    },
    "&.expired": {
        backgroundColor: "var(--color-lighter-red)",
        color: "var(--color-red)",
    },
    "&.suspended": {
        backgroundColor: "var(--color-lighter-grey)",
        color: "var(--color-grey)",
    },
    "&.inactive": {
        backgroundColor: "var(--color-slate)",
        color: "var(--color-light-grey)",
    },
    "&.pendingVerification": {
        backgroundColor: "var(--primary-color-light)",
        color: "var(--primary-color)",
    },
})

interface StatusBadgeProps {
    status: PaymentMethodDetailsStatus
}

export const StatusBadge = ({ status }: StatusBadgeProps) => {
    const { formatMessage } = useIntl()

    const badgeClasses = classNames("default", {
        active: status === PaymentMethodDetailsStatus.ACTIVE,
        inactive: status === PaymentMethodDetailsStatus.INACTIVE,
        expired: status === PaymentMethodDetailsStatus.EXPIRED,
        pendingVerification: status === PaymentMethodDetailsStatus.PENDING_VERIFICATION,
        suspended: status === PaymentMethodDetailsStatus.SUSPENDED,
    })

    return <Badge className={badgeClasses}>{formatMessage(messages[status] ?? messages.unknown)}</Badge>
}
