type GetValueType<T> = (o: T) => string | number | null

export const PAGE_SIZE_OPTIONS = [10, 20, 30, 40, 50]

export function tableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) {
            return order
        }
        return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
}

type OrderType = "asc" | "desc"

function getValue<T>(obj: T, key: keyof T | GetValueType<T>) {
    if (typeof key === "function") {
        return key(obj)
    } else {
        return typeof obj[key] === "string" ? (obj[key] as string).toLowerCase() : obj[key]
    }
}

export function sortComparator<T>(a: T, b: T, orderBy: keyof T | GetValueType<T>, sort: OrderType) {
    const aValue = getValue(a, orderBy)
    const bValue = getValue(b, orderBy)

    if (aValue === bValue) return 0
    if (bValue === null || bValue === undefined) return -1
    if (aValue === null || aValue === undefined) return 1

    if (bValue < aValue) {
        return sort === "desc" ? -1 : 1
    }
    if (bValue > aValue) {
        return sort === "desc" ? 1 : -1
    }
    return 0
}

export function noOrderingCompanator() {
    return 0
}

export function getComparator<T>(
    order: OrderType,
    orderBy: keyof T | GetValueType<T> | undefined
): (a: T, b: T) => number {
    return orderBy ? (a, b) => sortComparator(a, b, orderBy, order) : noOrderingCompanator
}
