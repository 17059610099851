import { MessageDescriptor, defineMessages } from "react-intl"

export enum EiffageCustomFields {
    ORGANISATION_NUMBER = "organisationNumber",
    ACCOUNT_NUMBER = "accountNumber",
    ID_LODACE_SPEND = "idLodaceSpend",
    WEB_SITE = "webSite",
    COVERED_REGION = "coveredRegion",
    INTERNATIONAL_PRESENCE = "internationalPresence",
    EIFFAGE_OPERATING_BRANCHES = "eiffageOperatingBranches",
}

export const eiffageCustomFieldsMessages: Record<EiffageCustomFields, MessageDescriptor> = defineMessages({
    [EiffageCustomFields.ORGANISATION_NUMBER]: {
        id: "customfields.company.eiffage.organisationNumber",
        defaultMessage: "Organisation Number",
    },
    [EiffageCustomFields.ACCOUNT_NUMBER]: {
        id: "customfields.company.eiffage.accountNumber",
        defaultMessage: "Account Number",
    },
    [EiffageCustomFields.ID_LODACE_SPEND]: {
        id: "customfields.company.eiffage.idLodaceSpend",
        defaultMessage: "ID Lodace Spend",
    },
    [EiffageCustomFields.WEB_SITE]: {
        id: "customfields.company.eiffage.webSite",
        defaultMessage: "Web Site",
    },
    [EiffageCustomFields.COVERED_REGION]: {
        id: "customfields.company.eiffage.coveredRegion",
        defaultMessage: "Covered Region",
    },
    [EiffageCustomFields.INTERNATIONAL_PRESENCE]: {
        id: "customfields.company.eiffage.internationalPresence",
        defaultMessage: "International Presence",
    },
    [EiffageCustomFields.EIFFAGE_OPERATING_BRANCHES]: {
        id: "customfields.company.eiffage.eiffageOperatingBranches",
        defaultMessage: "Eiffage Operating Branches",
    },
})

export enum VeoliaCustomFields {
    RECURRING_MODE = "recurringMode",
    PAYMENT_TERMS_REQUIRED = "paymentTermsRequired",
    WARRANTY_PERIOD_REQUIRED = "warrantyPeriodRequired",
    COMPETITIVE_BIDDING_TRACKER = "competitiveBiddingTracker",
    APPROVED_BID_COMPARISON = "approvedBidComparison",
    PO_REGULARISATION = "poRegularisation",
    INCOTERMS = "incoterms",
    SUPPLIER_CHARTER_SIGNED = "supplierCharterSigned",
    SUPPLIER_STATUS = "supplierStatus",
}

export const VeoliaCustomFieldsMessages: Record<VeoliaCustomFields, MessageDescriptor> = defineMessages({
    [VeoliaCustomFields.RECURRING_MODE]: {
        id: "customfields.company.recurringMode",
        defaultMessage: "Recurrent or One-off Spend",
    },
    [VeoliaCustomFields.PAYMENT_TERMS_REQUIRED]: {
        id: "customfields.company.paymentTerms",
        defaultMessage: "Payment Terms Required",
    },
    [VeoliaCustomFields.WARRANTY_PERIOD_REQUIRED]: {
        id: "customfields.company.warrantyPeriod",
        defaultMessage: "Warranty Period Required",
    },
    [VeoliaCustomFields.COMPETITIVE_BIDDING_TRACKER]: {
        id: "customfields.company.competitiveBiddingTracker",
        defaultMessage: "Competitive Bidding Tracker",
    },
    [VeoliaCustomFields.APPROVED_BID_COMPARISON]: {
        id: "customfields.company.approvedBidComparison",
        defaultMessage: "Approved Bid Comparison",
    },
    [VeoliaCustomFields.PO_REGULARISATION]: {
        id: "customfields.company.poRegularisation",
        defaultMessage: "PO Regularisation",
    },
    [VeoliaCustomFields.INCOTERMS]: {
        id: "customfields.company.incoterms",
        defaultMessage: "Incoterms",
    },
    [VeoliaCustomFields.SUPPLIER_CHARTER_SIGNED]: {
        id: "customfields.company.supplierCharterSigned",
        defaultMessage: "Supplier Charter Signed",
    },
    [VeoliaCustomFields.SUPPLIER_STATUS]: {
        id: "customfields.company.supplierStatus",
        defaultMessage: "Supplier Status",
    },
})

export enum VeoliaRecurrenceMode {
    RECURRENT = "recurrent",
    ONEOFF = "oneOff",
}

export const recurrenceModeEnumMessages: Record<VeoliaRecurrenceMode, MessageDescriptor> = defineMessages({
    [VeoliaRecurrenceMode.RECURRENT]: {
        id: "purchase.requests.request.infos.enum.recurrent",
        defaultMessage: "Recurrent",
    },
    [VeoliaRecurrenceMode.ONEOFF]: {
        id: "purchase.requests.request.infos.enum.oneoff",
        defaultMessage: "One-off",
    },
})

export enum ObjectStrictDecision {
    YES = "Yes",
    NO = "No",
}

export const strictDecisionEnumMessages: Record<ObjectStrictDecision, MessageDescriptor> = defineMessages({
    [ObjectStrictDecision.YES]: {
        id: "customfields.company.enum.yes",
        defaultMessage: "Yes",
    },
    [ObjectStrictDecision.NO]: {
        id: "customfields.company.enum.no",
        defaultMessage: "No",
    },
})

export enum ObjectJustifiableDecision {
    YES = "Yes",
    NO = "No",
    JUSTIFIABLE = "Justifiable",
}

export const justifiableDecisionEnumMessages: Record<ObjectJustifiableDecision, MessageDescriptor> = defineMessages({
    [ObjectJustifiableDecision.YES]: {
        id: "customfields.company.enum.yes",
        defaultMessage: "Yes",
    },
    [ObjectJustifiableDecision.NO]: {
        id: "customfields.company.enum.no",
        defaultMessage: "No",
    },
    [ObjectJustifiableDecision.JUSTIFIABLE]: {
        id: "customfields.company.enum.justifiable",
        defaultMessage: "Justifiable",
    },
})

export enum incoTerms {
    EXW = "EXW",
    FCA = "FCA",
    FAS = "FAS",
    FOB = "FOB",
    CFR = "CFR",
    CIF = "CIF",
    CPT = "CPT",
    CIP = "CIP",
    DPU = "DPU",
    DAP = "DAP",
    DDP = "DDP",
}

export const incoTermsEnumMessages: Record<incoTerms, MessageDescriptor> = defineMessages({
    [incoTerms.EXW]: {
        id: "customfields.company.enum.incoterms.EXW",
        defaultMessage: "Ex works - Agreed place",
    },
    [incoTerms.FCA]: {
        id: "customfields.company.enum.incoterms.FCA",
        defaultMessage: "Free carrier - Agreed place",
    },
    [incoTerms.FAS]: {
        id: "customfields.company.enum.incoterms.FAS",
        defaultMessage: "Free alongside ship - Port of loading",
    },
    [incoTerms.FOB]: {
        id: "customfields.company.enum.incoterms.FOB",
        defaultMessage: "Free on board - Port of loading",
    },
    [incoTerms.CFR]: {
        id: "customfields.company.enum.incoterms.CFR",
        defaultMessage: "Cost and Freight - Port of destination",
    },
    [incoTerms.CIF]: {
        id: "customfields.company.enum.incoterms.CIF",
        defaultMessage: "Cost, Insurance and Freight - Port of destination",
    },
    [incoTerms.CPT]: {
        id: "customfields.company.enum.incoterms.CPT",
        defaultMessage: "Cost Paid To - Place of destination",
    },
    [incoTerms.CIP]: {
        id: "customfields.company.enum.incoterms.CIP",
        defaultMessage: "Cost and Insurance Paid To - Place of destination",
    },
    [incoTerms.DPU]: {
        id: "customfields.company.enum.incoterms.DPU",
        defaultMessage: "Delivered at Place Unloaded - Place of destination",
    },
    [incoTerms.DAP]: {
        id: "customfields.company.enum.incoterms.DAP",
        defaultMessage: "Delivered at Place - Place of destination",
    },
    [incoTerms.DDP]: {
        id: "customfields.company.enum.incoterms.DDP",
        defaultMessage: "Delivered Duty Paid - Place of destination",
    },
})

export enum SupplierStatus {
    ACTIVE = "active",
    INACTIVE = "inactive",
    BLOCKLISTED = "blocklisted",
}

export const supplierStatusEnumMessages: Record<SupplierStatus, MessageDescriptor> = defineMessages({
    [SupplierStatus.ACTIVE]: {
        id: "customfields.company.enum.supplierStatus.active",
        defaultMessage: "Active",
    },
    [SupplierStatus.INACTIVE]: {
        id: "customfields.company.enum.supplierStatus.inactive",
        defaultMessage: "Inactive",
    },
    [SupplierStatus.BLOCKLISTED]: {
        id: "customfields.company.enum.supplierStatus.blocklisted",
        defaultMessage: "Blocklisted",
    },
})
