import { AxiosInstance } from "axios"
import { useContext } from "react"

import { getResultSuccessValue, isResultSuccess } from "~/core/Result"
import { ApiContext } from "~/domains/common/apiClient"
import { genericParser } from "~/utils"

import { CreateCustomFieldPayload } from "./types/CreateCustomFieldPayload"
import { CustomFieldI, CustomFieldType, CustomFieldsIO } from "./types/CustomField"
import { CustomFieldObjectType } from "./types/CustomFieldObjectType"
import {
    AggregatedCustomFieldValueI,
    AggregatedCustomFieldValueIO,
    CreateCustomFieldObjectPayload,
    CustomFieldValueI,
    CustomFieldValueIO,
    parseCustomFieldBatch,
    parseCustomFieldValue,
} from "./types/CustomFieldValue"

const BASE_URL = import.meta.env.VITE_API_CUSTOM_FIELDS_URL

class CustomFieldsApi {
    private static instance: CustomFieldsApi
    private constructor(private axiosClient: AxiosInstance) {}

    static getInstance(axiosClient: AxiosInstance) {
        if (!CustomFieldsApi.instance) {
            CustomFieldsApi.instance = new CustomFieldsApi(axiosClient)
        }
        return CustomFieldsApi.instance
    }

    async getESG(): Promise<CustomFieldI[]> {
        const url = `${BASE_URL}/esg`
        const response = await this.axiosClient.get(url)
        if (response.status >= 200 && response.status < 300) {
            const result = genericParser(response.data, CustomFieldsIO)
            if (isResultSuccess(result)) {
                return Object.keys(result.result).map(
                    (id): CustomFieldI => ({
                        id,
                        name: result.result[id].name,
                        description: result.result[id].description,
                        unit: result.result[id].unit,
                        translations: result.result[id].translations ?? null,
                        type:
                            result.result[id].type === CustomFieldType.NUMBER
                                ? CustomFieldType.NUMBER
                                : CustomFieldType.STRING,
                    })
                )
            }
        }
        return []
    }

    async createCustomField(payload: CreateCustomFieldPayload | CreateCustomFieldObjectPayload, allowCustom?: boolean) {
        const url = `${BASE_URL}${allowCustom ? "?allowCustom=true" : ""}`
        const response = await this.axiosClient.post(url, payload)
        return parseCustomFieldValue(response)
    }

    async batchCreateCustomFields(payload: CreateCustomFieldObjectPayload[], allowCustom?: boolean) {
        const url = `${BASE_URL}/batch${allowCustom ? "?allowCustom=true" : ""}`
        const response = await this.axiosClient.post(url, payload)
        return parseCustomFieldBatch(response)

    }

    async batchUpdateCustomFields(payload: CreateCustomFieldPayload[], allowCustom?: boolean) {
        const url = `${BASE_URL}/batch${allowCustom ? "?allowCustom=true" : ""}`
        const response = await this.axiosClient.put(url, payload)
        return parseCustomFieldBatch(response)
    }

    async getValues(objectId: string): Promise<CustomFieldValueI[]> {
        const url = `${BASE_URL}/object/${objectId}`
        const response = await this.axiosClient.get(url)
        if (response.status >= 200 && response.status < 300 && Array.isArray(response.data)) {
            return response.data
                .map((customFieldValueData) =>
                    genericParser<CustomFieldValueI>(customFieldValueData, CustomFieldValueIO)
                )
                .filter(isResultSuccess)
                .map(getResultSuccessValue)
        }
        return []
    }

    async fetchCustomFieldsByContextId(contextId: string): Promise<AggregatedCustomFieldValueI> {
        const url = `${BASE_URL}/context/${contextId}/aggregate`
        const response = await this.axiosClient.get(url)
        if (response.status !== 204 && response.status >= 200 && response.status < 300) {
            const result = genericParser(response.data, AggregatedCustomFieldValueIO)
            if (isResultSuccess(result)) {
                return result.result
            }
        }
        return {}
    }

    async updateCustomFieldValue(customFieldId: string, value: string) {
        const url = `${BASE_URL}/${customFieldId}`
        const response = await this.axiosClient.put(url, { value })
        return parseCustomFieldValue(response)
    }

    async duplicateCustomFields(
        sourceObjectId: string,
        destinationObjectId: string,
        contextId: string,
        contextType: CustomFieldObjectType
    ) {
        const url = `${BASE_URL}/object/duplicate`
        const response = await this.axiosClient.post(url, {
            sourceObjectId,
            targetObjectId: destinationObjectId,
            contextId,
            contextType,
        })
        return parseCustomFieldValue(response)
    }
}

export const useCustomFieldsApi = () => {
    const { axiosClient } = useContext(ApiContext)
    return CustomFieldsApi.getInstance(axiosClient)
}
