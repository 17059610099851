import { Grid, Tooltip } from "@mui/material"
import { useCallback, useState } from "react"
import { Edit, Eye } from "react-feather"
import { FormattedMessage, defineMessage } from "react-intl"

import { Chip } from "~/domains/common/components/Chip"
import { DocumentObjectType } from "~/domains/identity/features/documents/types"
import { SavePayloadType } from "~/domains/identity/features/organizations/components/ModalOrganizationSelectorDetails/types"
import { ModalSelectOrganization } from "~/domains/transactions/components/ModalSelectOrganization/ModalSelectOrganization"
import { Address } from "~/domains/transactions/purchase-orders/types/PurchaseOrders"
import { OrganizationAddressI, OrganizationId } from "~/types"

import { OrganizationDetailsModal } from "./OrganizationDetailsModal"

const currentUserSideLabel = defineMessage({
    id: "purchase.organization.currentUserSideLabel",
    defaultMessage: "Me",
})

interface Props {
    title: string
    name: string | undefined
    id: OrganizationId | null
    isCurrentUserSide?: boolean
    billingAddress?: Address | null
    shippingAddress?: Address | null
    onSave?: (payload: SavePayloadType) => void
    withOrganizationAddress?: boolean
    withSendingAddress?: boolean
    showOrganizationSelector?: boolean
    selectOrganizationLabel?: string
    readOnly?: boolean
    objectType: DocumentObjectType
}

const addressToOrganizationAddress = (address: Address): OrganizationAddressI => ({
    addressLine: address.street,
    secondaryAddressLine: address.street2 ?? "",
    zipCode: address.zipCode,
    city: address.city,
    country: address.country,
})
export function Organization({
    title,
    name,
    id,
    isCurrentUserSide,
    billingAddress,
    shippingAddress,
    onSave,
    withOrganizationAddress = true,
    withSendingAddress = false,
    showOrganizationSelector,
    selectOrganizationLabel,
    readOnly,
    objectType,
}: Props) {
    const [displayDetailsModal, setDisplayDetailsModal] = useState<boolean>(false)
    const [displaySelectOrganizationModal, setDisplaySelectOrganizationModal] = useState<boolean>(false)

    const showDetailsModal = useCallback(() => setDisplayDetailsModal(true), [])
    const hideDetailsModal = useCallback(() => setDisplayDetailsModal(false), [])

    const showSelectOrganizationModal = useCallback(() => setDisplaySelectOrganizationModal(true), [])
    const hideSelectOrganizationModal = useCallback(() => setDisplaySelectOrganizationModal(false), [])

    return (
        <>
            <Grid container alignItems="center" gap={1} flexGrow={1}>
                <h4>{title}</h4>
                {isCurrentUserSide && (
                    <Chip variant="primary" size="medium" borderLess>
                        <FormattedMessage {...currentUserSideLabel} />
                    </Chip>
                )}
            </Grid>
            <div className="organization-body">
                {showOrganizationSelector ? (
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        onClick={showSelectOrganizationModal}
                    >
                        <>
                            {!id && selectOrganizationLabel ? (
                                <span className="select-organization-label">{selectOrganizationLabel}</span>
                            ) : (
                                <Tooltip title={name} placement="bottom">
                                    <span className="truncate-text">{name}</span>
                                </Tooltip>
                            )}
                            <span>
                                <Edit size={20} />
                            </span>
                        </>
                    </Grid>
                ) : (
                    <Grid container alignItems="center" justifyContent="space-between" onClick={showDetailsModal}>
                        <Tooltip title={name} placement="bottom">
                            <span className="truncate-text">{name}</span>
                        </Tooltip>
                        <span>{onSave ? <Edit size={20} /> : <Eye size={20} />}</span>
                    </Grid>
                )}
            </div>
            {displayDetailsModal && id && (
                <OrganizationDetailsModal
                    organizationId={id}
                    open={displayDetailsModal}
                    onClose={hideDetailsModal}
                    onSave={onSave}
                    withOrganizationAddress={withOrganizationAddress}
                    withSendingAddress={withSendingAddress}
                    shippingAddress={shippingAddress ? addressToOrganizationAddress(shippingAddress) : undefined}
                    billingAddress={billingAddress ? addressToOrganizationAddress(billingAddress) : undefined}
                    readOnly={readOnly}
                />
            )}
            {displaySelectOrganizationModal ? (
                <ModalSelectOrganization
                    objectType={objectType}
                    organizationId={id}
                    organizationName={name}
                    open={displaySelectOrganizationModal}
                    close={hideSelectOrganizationModal}
                />
            ) : null}
        </>
    )
}
