import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { prepareHeadersWithAuthorizationAndOrganizationId } from "~/api/prepareHeaders"
import { Lifecycle } from "~/domains/transactions/invoices-v1/types/Lifecycle"

const BASE_URL = import.meta.env.VITE_API_TRANSACTION_V1_INVOICES_URL + "invoices/"

export const invoiceLifeCycleApi = createApi({
    reducerPath: "invoiceLifeCycleApi",
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL, prepareHeaders: prepareHeadersWithAuthorizationAndOrganizationId }),
    endpoints: (builder) => ({
        getInvoiceLifecycle: builder.query({
            query: (invoiceId: string) => ({ url: `${invoiceId}/lifecycle` }),
        }),
        createOrReplaceInvoiceLifecycle: builder.mutation({
            query: ({ invoiceId, status }: Lifecycle) => ({
                url: `${invoiceId}/lifecycle`,
                method: "POST",
                body: { status },
            }),
        }),
    }),
})

export const { useGetInvoiceLifecycleQuery, useCreateOrReplaceInvoiceLifecycleMutation } = invoiceLifeCycleApi
