interface Message {
    createdAt: string
    id: string
    isInbound: boolean
    message: string
    threadId: string
    updatedAt: string
}
interface Thread {
    createdAt: string
    id: string
    updatedAt: string
    userId: string
    name: string | null
    organizationId: string
}
interface Answer {
    createdAt: string
    id: string
    isInbound: boolean
    message: string
    threadId: string
    updatedAt: string
}
export interface ThreadResult {
    messages: Message[]
    thread: Thread
}

export enum From {
    USER = "user",
    COPILOT = "copilot",
}

export interface Conversation {
    from: From
    message: string
}

export interface MessageResult {
    message: Message
    answer: Answer
    completion: {
        type: string
        item: string
        quantity: string
        price: string
        currency: string
        tax: string
        message: string
    }
}
