import * as Sentry from "@sentry/browser"
import React, { FC, useEffect, useMemo } from "react"
import { useIntl } from "react-intl"
import { generatePath, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

import { Loader } from "~/components"
import { DataTable } from "~/components/DataTable"
import { useGetRunsQuery } from "~/domains/orchestration/flows/api/runsApi"
import { useOrganizationId } from "~/domains/orchestration/flows/hooks/"
import { messages } from "~/domains/orchestration/flows/messages"
import { RUN } from "~/domains/orchestration/flows/routes"
import { RunItem } from "~/domains/orchestration/flows/types"
import { OrderDirection } from "~/types"

interface Props {
    filter: string
}

export const RunsTable: FC<Props> = ({ filter }) => {
    const { formatMessage } = useIntl()
    const navigate = useNavigate()

    const organizationId = useOrganizationId()

    const { data, error, isLoading, isError, refetch } = useGetRunsQuery()

    const filteredRuns = useMemo(
        () => data?.runs.filter((flow) => flow.id.toLowerCase().includes(filter.toLowerCase())) || [],
        [data, filter]
    )

    const handleClickRow = (run: RunItem) => {
        const path = generatePath(RUN, {
            runId: run.id,
        })
        navigate(path)
    }

    const columns = useMemo(
        () => [
            {
                id: "runName",
                getValue: (run: RunItem) => run.id,
                label: formatMessage(messages.runsTable.id),
                sorter: true,
            },
            {
                id: "status",
                getValue: (run: RunItem) => run.status,
                label: formatMessage(messages.runsTable.status),
                sorter: true,
            },
            {
                id: "startedAt",
                getValue: (run: RunItem) => run.startedAt,
                label: formatMessage(messages.runsTable.startedAt),
                sorter: true,
            },
            {
                id: "finishedAt",
                getValue: (run: RunItem) => run.finishedAt,
                label: formatMessage(messages.runsTable.finishedAt),
                sorter: true,
            },
        ],
        [formatMessage, handleClickRow]
    )

    useEffect(() => {
        if (organizationId) {
            refetch()
        }
    }, [organizationId, refetch])

    if (isLoading) {
        return <Loader fullscreen />
    }
    if (isError) {
        toast.error(formatMessage(messages.error.loadingRuns))
        Sentry.captureException(error)
        return null
    }
    if (!data) {
        return null
    }

    return (
        <DataTable
            data={filteredRuns}
            defaultOrderBy="id"
            defaultOrderDirection={OrderDirection.ASC}
            columns={columns}
            handleClickRow={handleClickRow}
        />
    )
}
