import { styled } from "@mui/material"
import React, { useCallback, useMemo } from "react"
import { MessageDescriptor, useIntl } from "react-intl"
import { getInitialFromName } from "~/components/Avatar/getInitialFromName"
import {
    sharedObjectPermissionsMessages,
    sharedObjectPermissionsModalMessages,
    SpiceDBAuthorizationName,
    SpiceDBObjectType,
    SpiceDBPermissionsLiteI,
} from "~/domains/identity/features/roles-permissions/types/SpiceDB"
import { PermissionsSelect } from "./PermissionsSelect"
import { rolesPermissionsActions } from "~/domains/identity/store/rolesPermissionsSlice"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import {
    ManageObjectPermissionsArgs,
    useManageObjectPermissions,
} from "~/domains/identity/store/hooks/useManageObjectPermissions"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { toast } from "react-toastify"
import { getCurrentUserPermissions } from "~/domains/identity/features/roles-permissions/utils/spiceDB"
import { selectUser } from "~/store/account/accountSlice"
import { useFetchObjectPermissions } from "~/domains/identity/store/hooks/useFetchObjectPermissions"
import { selectPurchaseRequestsPermissions } from "~/domains/transactions/purchase-requests/store/purchaseRequestsSlice"

const Container = styled("div")({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "2px 8px",
    width: "100%",
})
export const UserTeamContainer = styled("div")({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "8px",
})
const NameContainer = styled("div")({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
})
export const Name = styled("div")({
    fontSize: "14px",
    lineHeight: "20px",
})
const Description = styled("div")({
    fontSize: "12px",
    lineHeight: "16px",
    color: "var(--color-light-grey)",
})
const PermissionsContainer = styled("div")({
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
})

interface Props {
    objectId: string
    objectType: SpiceDBObjectType
    objectPermission: SpiceDBPermissionsLiteI
    disabled?: boolean
}

export const ObjectPermissions: React.FC<Props> = ({ objectId, objectType, objectPermission, disabled = false }) => {
    const { formatMessage } = useIntl()
    const dispatch = useAppDispatch()
    const currentOrganizationId = useAppSelector(selectCurrentOrganizationId)
    const currentUser = useAppSelector(selectUser)
    const purchaseRequestsPermissions = useAppSelector(selectPurchaseRequestsPermissions)

    const { permissions, objectType: selectedObjectType, name, email, membersNumber } = objectPermission
    const { manageObjectPermissionsSuccess } = useManageObjectPermissions()
    const { sharedPermissions } = useFetchObjectPermissions(objectId, objectType)

    const currentUserPermissions = useMemo(() => {
        return sharedPermissions
            ? getCurrentUserPermissions(currentUser.id, purchaseRequestsPermissions, sharedPermissions)
            : []
    }, [sharedPermissions, currentUser.id, purchaseRequestsPermissions])

    const handlePermissionChangeSuccess = useCallback(
        async (newPermissions: SpiceDBAuthorizationName[]): Promise<boolean> => {
            if (!currentOrganizationId) {
                return true
            }

            const { permissions, objectId: selectedObjectId, objectType: selectedObjectType } = objectPermission
            const permissionsToAdd = newPermissions.filter((permission) => !permissions.includes(permission))
            const permissionsToRemove = permissions.filter((permission) => !newPermissions.includes(permission))

            let success: boolean = true
            const args: ManageObjectPermissionsArgs = {
                objectId,
                objectType,
                objects: [{ objectId: selectedObjectId, objectType: selectedObjectType }],
                permissions: newPermissions,
                isFirstTimeShare: false,
                creatorOrganizationId: currentOrganizationId,
                recipientOrganizationId: currentOrganizationId,
                isDelete: false,
            }
            if (permissionsToAdd.length) {
                success = await addPermissions(args, permissionsToAdd, selectedObjectType)
            } else if (permissionsToRemove.length) {
                success = await removePermissions(args, permissionsToRemove, selectedObjectType)
            }

            if (success) {
                dispatch(
                    rolesPermissionsActions.updateObjectPermissions({
                        ...objectPermission,
                        permissions: newPermissions,
                    })
                )
            }
            return success
        },
        [currentOrganizationId, objectPermission, objectId, objectType, manageObjectPermissionsSuccess, dispatch]
    )

    const addPermissions = useCallback(
        async (
            args: ManageObjectPermissionsArgs,
            permissionsToAdd: SpiceDBAuthorizationName[],
            selectedObjectType: SpiceDBObjectType
        ): Promise<boolean> => {
            const success = await manageObjectPermissionsSuccess(args)
            if (success) {
                toast.success(
                    formatMessage(sharedObjectPermissionsModalMessages.sharePermissionsSuccess, {
                        s: permissionsToAdd.length === 1 ? "" : "s",
                        objectType: formatMessage(
                            sharedObjectPermissionsMessages[selectedObjectType] as MessageDescriptor
                        ),
                    })
                )
            }
            return success
        },
        [formatMessage, manageObjectPermissionsSuccess]
    )

    const removePermissions = useCallback(
        async (
            args: ManageObjectPermissionsArgs,
            permissionsToRemove: SpiceDBAuthorizationName[],
            selectedObjectType: SpiceDBObjectType
        ): Promise<boolean> => {
            const success = await manageObjectPermissionsSuccess({
                ...args,
                permissions: permissionsToRemove,
                isDelete: true,
            })
            if (success) {
                toast.success(
                    formatMessage(sharedObjectPermissionsModalMessages.removePermissionsSuccess, {
                        s: permissionsToRemove.length === 1 ? "" : "s",
                        objectType: formatMessage(
                            sharedObjectPermissionsMessages[selectedObjectType] as MessageDescriptor
                        ),
                    })
                )
            }
            return success
        },
        [formatMessage, manageObjectPermissionsSuccess]
    )

    return (
        <Container>
            <UserTeamContainer>
                <span className="company-logo">
                    <figure className="no-image noto-semi-bold">{getInitialFromName(name as string)}</figure>
                </span>
                <NameContainer>
                    <Name>{name}</Name>
                    <Description>
                        {selectedObjectType === SpiceDBObjectType.USER
                            ? email
                            : `${formatMessage(sharedObjectPermissionsModalMessages.membersCount, {
                                  membersCount: membersNumber,
                              })}`}
                    </Description>
                </NameContainer>
            </UserTeamContainer>
            <PermissionsContainer>
                <PermissionsSelect
                    currentUserPermissions={currentUserPermissions}
                    objectPermissions={permissions}
                    objectType={objectType}
                    handleChangePermission={handlePermissionChangeSuccess}
                    canSelect={!disabled}
                />
            </PermissionsContainer>
        </Container>
    )
}
