import { useCallback } from "react"

import { invoiceApi } from "~/api"
import { getImportCompanyIdentifierOrEmptyString } from "~/store/invoice/core"
import { useCreateOrganization } from "~/store/organization/hooks"
import {
    ImportInvoiceCompanyInfoI,
    ImportingInvoiceI,
    InvoiceI,
    InvoiceUserType,
    NO_ORGANIZATION_ID,
    UpdateDraftInvoicePayload,
    UserI,
} from "~/types"

const constructOrganizationPayload = (companyInfo: ImportInvoiceCompanyInfoI, isInitiator: boolean, user: UserI) => ({
    organizationId:
        companyInfo.organizationId && companyInfo.organizationId !== NO_ORGANIZATION_ID
            ? companyInfo.organizationId
            : null,
    involvedUserIds: isInitiator && user.id ? [user.id] : [],
    name: companyInfo.name,
    contactName: isInitiator ? user.fullName : companyInfo.contactName,
    registrations: {
        registrationNumber: companyInfo.registrationNumber ?? null,
        vatNumber: companyInfo.taxId ?? null,
    },
})

export const usePatchDraftInvoice = (
    invoice: ImportingInvoiceI | InvoiceI | null,
    initiator: InvoiceUserType,
    user: UserI
) => {
    const createOrganization = useCreateOrganization()

    return useCallback(
        async (isCreation?: boolean, newInvoice?: Partial<InvoiceI>) => {
            if (!invoice) return

            const isBuyerInitiator = initiator === InvoiceUserType.BUYER
            const buyer = constructOrganizationPayload(invoice.buyer, isBuyerInitiator, user)
            const supplier = constructOrganizationPayload(invoice.supplier, !isBuyerInitiator, user)

            if (isCreation && !buyer.organizationId) {
                const result = await createOrganization(
                    buyer.name,
                    invoice.buyer.countryCode,
                    getImportCompanyIdentifierOrEmptyString(invoice.buyer)
                )
                buyer.organizationId = result.id
            }
            if (isCreation && !supplier.organizationId) {
                const result = await createOrganization(
                    supplier.name,
                    invoice.supplier.countryCode,
                    getImportCompanyIdentifierOrEmptyString(invoice.supplier)
                )
                supplier.organizationId = result.id
            }

            if (newInvoice) {
                invoice = { ...invoice, ...newInvoice }
            }

            await invoiceApi.saveDraft(invoice.id, {
                ...invoice,
                initiator,
                buyer,
                supplier,
            } as UpdateDraftInvoicePayload)
        },
        [invoice, initiator, user]
    )
}
