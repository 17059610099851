import React, { FC, useState, ReactNode } from "react"
import classNames from "classnames"
import { styled } from "@mui/material"

const NavigableTabsContainer = styled("div")({
    display: "flex",
    gap: "8px",
    width: "100%",
    borderBottom: "1px solid var(--separator-color)",
})

const TabItem = styled("a")({
    textDecoration: "none",
    padding: "0 12px 8px 12px",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    color: "var(--color-light-grey)",
    "&:hover, &:visited, &:active": {
        color: "var(--color-light-grey)",
    },
    "&.active": {
        color: "var(--color-grey)",
        borderBottom: "2px solid var(--color-grey)",
    },
})

interface NavigableTabsProps {
    tabs: string[]
    initialActiveTab?: number
}

export const NavigableTabs: FC<NavigableTabsProps> = ({ tabs, initialActiveTab = 0 }) => {
    const [activeTabIndex, setActiveTabIndex] = useState(initialActiveTab)

    const handleSetActiveTab = (index: number) => () => {
        if (activeTabIndex === index) return
        setActiveTabIndex(index)
    }

    const renderTabContent = (tab: string, index: number): ReactNode => {
        const id = tab.toLocaleLowerCase()
        const tabClasses = classNames({
            active: activeTabIndex === index,
        })

        return (
            <TabItem className={tabClasses} key={id} href={`#${id}`} onClick={handleSetActiveTab(index)}>
                {tab}
            </TabItem>
        )
    }

    return <NavigableTabsContainer>{tabs.map((tab, index) => renderTabContent(tab, index))}</NavigableTabsContainer>
}
