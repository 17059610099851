import { styled } from "@mui/material"
import classNames from "classnames"
import { FormattedMessage, MessageDescriptor } from "react-intl"

import { Button } from "~/components"
import { PaymentMethodType } from "~/domains/payment/payment-methods/types"

const TabsContaner = styled("div")({
    display: "flex",
    marginRight: "auto",
})

const tabs = [PaymentMethodType.BANK_TRANSFER, PaymentMethodType.DIRECT_DEBIT, PaymentMethodType.CARD]

const messages: Partial<Record<PaymentMethodType, MessageDescriptor>> = {
    [PaymentMethodType.CARD]: {
        id: "payment.paymentMethodTabs.card",
        defaultMessage: "Card",
    },
    [PaymentMethodType.BANK_TRANSFER]: {
        id: "payment.paymentMethodTabs.bankTransfer",
        defaultMessage: "Bank transfer",
    },
    [PaymentMethodType.DIRECT_DEBIT]: {
        id: "payment.paymentMethodTabs.directDebit",
        defaultMessage: "Direct debit",
    },
}

interface PaymentMethodTabsProps {
    currentTab: PaymentMethodType
    onTabChange: (tab: PaymentMethodType) => void
}

export const PaymentMethodTabs = ({ currentTab, onTabChange }: PaymentMethodTabsProps) => {
    const handleTabClick = (tab: PaymentMethodType) => () => {
        onTabChange(tab)
    }

    return (
        <TabsContaner>
            {tabs.map((tab) => (
                <Button
                    key={tab}
                    type="tab"
                    className={classNames({
                        selected: currentTab === tab,
                    })}
                    onClick={handleTabClick(tab)}
                >
                    <FormattedMessage {...messages[tab]} />
                </Button>
            ))}
        </TabsContaner>
    )
}
