import { Stack } from "@mui/material"
import { Link, generatePath } from "react-router-dom"

import { Card } from "~/components"
import { Chip } from "~/domains/common/components/Chip"
import { PURCHASE_ORDER_ROUTE } from "~/domains/transactions/purchase-orders/routes"
import { PurchaseRequestDetailsPurchaseOrder } from "~/domains/transactions/purchase-requests/types/PurchaseRequests"

interface Props {
    purchaseOrder?: PurchaseRequestDetailsPurchaseOrder
    label: string
}

export function PurchaseOrderLink({ purchaseOrder, label }: Props) {
    return (
        <Card title={label} expandable>
            <Stack direction="row" spacing={1}>
                <Chip variant="primary">
                    {!purchaseOrder ? (
                        "-"
                    ) : (
                        <Link to={generatePath(PURCHASE_ORDER_ROUTE, { purchaseOrderId: purchaseOrder.id })}>
                            #{purchaseOrder.shortId}
                        </Link>
                    )}
                </Chip>
            </Stack>
        </Card>
    )
}
