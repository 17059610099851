import { MessageDescriptor } from "react-intl"

import { BulkActionButton } from "~/components"
import {
    CreatePurchaseRequestDTOV2,
    PurchaseRequestDetails,
    PurchaseRequestDetailsPurchaseOrder,
    PurchaseRequestLineSummary,
    PurchaseRequestStatus,
    PurchaseRequestSummary,
    PurchaseRequestV2,
    PurchaseRequestV2SummaryAPI,
    PurchaseRequestsTab,
    UpdatePurchaseRequestDTOV2,
} from "~/domains/transactions/purchase-requests/types/PurchaseRequests"
import { CurrencyCodes } from "~/types"
import { isDefined } from "~/utils/isDefined"

export const purchaseRequestsTabMessages: Record<PurchaseRequestsTab, MessageDescriptor> = {
    [PurchaseRequestsTab.ALL]: {
        id: "purchase.requests.tabs.all",
        defaultMessage: "All",
    },
    [PurchaseRequestsTab.DRAFT]: {
        id: "purchase.requests.tabs.draft",
        defaultMessage: "Drafts",
    },
    [PurchaseRequestsTab.SUBMITTED]: {
        id: "purchase.requests.tabs.submitted",
        defaultMessage: "Submitted",
    },
    [PurchaseRequestsTab.APPROVED]: {
        id: "purchase.requests.tabs.approved",
        defaultMessage: "Approved",
    },
    [PurchaseRequestsTab.REJECTED]: {
        id: "purchase.requests.tabs.rejected",
        defaultMessage: "Rejected",
    },
    [PurchaseRequestsTab.CONVERTED]: {
        id: "purchase.requests.tabs.converted",
        defaultMessage: "Converted",
    },
}

export type PRStatusCounts = Record<PurchaseRequestStatus, number>
export const statusCounts = (
    purchaseRequests: { status: string; purchaseOrder?: PurchaseRequestDetailsPurchaseOrder | null }[]
) => {
    const countsInitial: PRStatusCounts = {
        DRAFT: 0,
        SUBMITTED: 0,
        APPROVED: 0,
        REJECTED: 0,
        CONVERTED: 0,
    }

    return purchaseRequests.reduce((count, purchaseRequest) => {
        if (purchaseRequest.status === PurchaseRequestStatus.APPROVED) {
            if (purchaseRequest.purchaseOrder) {
                count[PurchaseRequestStatus.CONVERTED] = count[PurchaseRequestStatus.CONVERTED]
                    ? count[PurchaseRequestStatus.CONVERTED] + 1
                    : 1
            } else {
                count[purchaseRequest.status] = count[purchaseRequest.status] ? count[purchaseRequest.status] + 1 : 1
            }
        } else {
            count[purchaseRequest.status] = count[purchaseRequest.status] ? count[purchaseRequest.status] + 1 : 1
        }
        return count
    }, countsInitial)
}

export const getNonConvertedToPOForDeletion = (
    purchaseRequestIds: string[],
    purchaseRequests: PurchaseRequestSummary[]
): string[] => {
    return purchaseRequestIds.reduce<string[]>((result, id) => {
        const purchaseRequest = purchaseRequests.find((pr) => pr.id === id)

        if (isDefined(purchaseRequest) && purchaseRequest.permissions?.delete && !purchaseRequest.purchaseOrder) {
            result.push(id)
        }

        return result
    }, [])
}

export const getApprovable = (purchaseRequestIds: string[], purchaseRequests: PurchaseRequestSummary[]): string[] => {
    return purchaseRequestIds.reduce<string[]>((result, id) => {
        const purchaseRequest = purchaseRequests.find((pr) => pr.id === id)

        if (
            isDefined(purchaseRequest) &&
            purchaseRequest.permissions?.approve &&
            !purchaseRequest.purchaseOrder &&
            purchaseRequest.status !== PurchaseRequestStatus.DRAFT &&
            purchaseRequest.status !== PurchaseRequestStatus.APPROVED
        ) {
            result.push(id)
        }

        return result
    }, [])
}

export const getRefusable = (purchaseRequestIds: string[], purchaseRequests: PurchaseRequestSummary[]): string[] => {
    return purchaseRequestIds.reduce<string[]>((result, id) => {
        const purchaseRequest = purchaseRequests.find((pr) => pr.id === id)

        if (
            isDefined(purchaseRequest) &&
            purchaseRequest.permissions?.approve &&
            !purchaseRequest.purchaseOrder &&
            purchaseRequest.status !== PurchaseRequestStatus.DRAFT &&
            purchaseRequest.status !== PurchaseRequestStatus.REJECTED
        ) {
            result.push(id)
        }

        return result
    }, [])
}

export const getConvertable = (purchaseRequestIds: string[], purchaseRequests: PurchaseRequestSummary[]): string[] => {
    return purchaseRequestIds.reduce<string[]>((result, id) => {
        const purchaseRequest = purchaseRequests.find((pr) => pr.id === id)

        if (
            isDefined(purchaseRequest) &&
            purchaseRequest.permissions?.convertToPurchaseOrder &&
            !purchaseRequest.purchaseOrder &&
            purchaseRequest.status === PurchaseRequestStatus.APPROVED &&
            purchaseRequest.expectedDeliveryDate &&
            purchaseRequest.supplierId
        ) {
            result.push(id)
        }

        return result
    }, [])
}

export const getPRBulkActionButtons = (
    currentTab: PurchaseRequestsTab,
    handleApprove: (currentTab: PurchaseRequestsTab) => BulkActionButton,
    handleReject: (currentTab: PurchaseRequestsTab) => BulkActionButton,
    handleDelete: (currentTab: PurchaseRequestsTab) => BulkActionButton,
    handleConvert: (currentTab: PurchaseRequestsTab) => BulkActionButton
): BulkActionButton[] => {
    switch (currentTab) {
        case PurchaseRequestsTab.ALL:
            return [
                handleApprove(currentTab),
                handleReject(currentTab),
                handleConvert(currentTab),
                handleDelete(currentTab),
            ]
        case PurchaseRequestsTab.SUBMITTED:
            return [handleApprove(currentTab), handleReject(currentTab), handleDelete(currentTab)]
        case PurchaseRequestsTab.DRAFT:
            return [handleDelete(currentTab)]
        case PurchaseRequestsTab.APPROVED:
            return [handleReject(currentTab), handleConvert(currentTab), handleDelete(currentTab)]
        case PurchaseRequestsTab.REJECTED:
            return [handleApprove(currentTab), handleDelete(currentTab)]
        case PurchaseRequestsTab.CONVERTED:
            return []
    }
}

const convertPRV2Common = (purchaseRequest: PurchaseRequestV2SummaryAPI | PurchaseRequestV2) => {
    const currencyKey = Object.keys(purchaseRequest.totals)[0]
    const amountsForCurrency = purchaseRequest.totals[currencyKey]

    return {
        description: purchaseRequest.description,
        creationDate: purchaseRequest.creationDate,
        expectedDeliveryDate: purchaseRequest.expectedDeliveryDate,
        totalAmount: amountsForCurrency ? parseFloat(amountsForCurrency?.amount.amount) : 0,
        totalAmountExcludingTax: amountsForCurrency ? parseFloat(amountsForCurrency?.amountExcludingTax.amount) : 0,
        id: purchaseRequest.id,
        organizationId: purchaseRequest.organizationId,
        requesterUserId: purchaseRequest.requesterUserId,
        status: purchaseRequest.status,
        supplierId: purchaseRequest.supplierId,
        purchaseOrder: purchaseRequest.purchaseOrder,
        submittedDate: purchaseRequest.submittedDate,
        number: purchaseRequest.number,
        lines: purchaseRequest.lines.map((line: PurchaseRequestLineSummary) => ({
            id: line.id,
            approvalStatus: line.approvalStatus,
        })),
        currency: currencyKey as CurrencyCodes,
        permissions: purchaseRequest.permissions ?? {
            approve: true,
            convertToPurchaseOrder: true,
            delete: true,
            edit: true,
            submit: true,
        },
    }
}

export const convertPRV2toPRSummary = (purchaseRequest: PurchaseRequestV2SummaryAPI): PurchaseRequestSummary => {
    const common = convertPRV2Common(purchaseRequest)
    return {
        ...common,
        lines: common.lines.map((line) => ({ id: line.id, approvalStatus: line.approvalStatus })),
    }
}

export const convertPRV2toPR = (purchaseRequest: PurchaseRequestV2): PurchaseRequestDetails => {
    const common = convertPRV2Common(purchaseRequest)
    return {
        ...common,
        documents: purchaseRequest.documents,
        shippingAddress: purchaseRequest.shippingAddress,
        lines: purchaseRequest.lines.map((line) => ({
            ...line,
            unitPrice: parseFloat(line.unitPrice.amount),
            unitPriceExcludingTax: parseFloat(line.unitPriceExcludingTax.amount),
            totalAmount: parseFloat(line.totals.amount.amount),
            totalAmountExcludingTax: parseFloat(line.totals.amountExcludingTax.amount),
            temporaryId: line.temporaryId,
            itemId: line.itemId,
            units: line.units ?? "",
        })),
    }
}

const convertPRtoPRV2Common = (purchaseRequest: PurchaseRequestDetails) => ({
    supplierId: purchaseRequest.supplierId && purchaseRequest.supplierId.length ? purchaseRequest.supplierId : null,
    description: purchaseRequest.description,
    shippingAddress: purchaseRequest.shippingAddress,
    lines: purchaseRequest.lines.map((line) => ({
        id: line.id,
        description: line.description,
        quantity: line.quantity,
        unitPrice: { amount: line.unitPrice?.toFixed(8), currency: purchaseRequest.currency },
        unitPriceExcludingTax: {
            amount: line.unitPriceExcludingTax?.toString(),
            currency: purchaseRequest.currency,
        },
        taxRate: line.taxRate.toFixed(8),
        temporaryId: line.temporaryId,
        itemId: line.itemId,
        units: line.units ?? "",
    })),
    ...(purchaseRequest.expectedDeliveryDate && { expectedDeliveryDate: purchaseRequest.expectedDeliveryDate }),
})

export const convertPRtoPRV2 = (purchaseRequest: PurchaseRequestDetails): CreatePurchaseRequestDTOV2 => {
    return convertPRtoPRV2Common(purchaseRequest) as CreatePurchaseRequestDTOV2
}

export const convertPRtoPRV2ForUpdate = (purchaseRequest: PurchaseRequestDetails): UpdatePurchaseRequestDTOV2 => {
    return convertPRtoPRV2Common(purchaseRequest) as UpdatePurchaseRequestDTOV2
}
