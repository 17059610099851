import { useMemo } from "react"

import { OrganizationI, OrganizationItemI } from "~/types"

import { useFetchOrganizations } from "./useFetchOrganization"

export const useOrganizations = (
    organizationItems: OrganizationItemI[]
): { organizations: OrganizationI[]; loading: boolean } => {
    const organizationsToFetch = useMemo(
        () => organizationItems.map((organizationItem) => organizationItem.id),
        [organizationItems]
    )
    const { organizations, loadings } = useFetchOrganizations(organizationsToFetch)
    const loading = organizationItems.some((organizationItem) => !!loadings[organizationItem.id])

    return {
        organizations,
        loading,
    }
}
