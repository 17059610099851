import { Autocomplete, FormControlLabel, MenuItem, Stack, Switch, TextField } from "@mui/material"
import cls from "classnames"
import React, { ChangeEvent, FC, useEffect, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"

import { Card } from "~/components"
import { TRANSACTION_TYPES } from "~/domains/orchestration/flows/const"
import { messages } from "~/domains/orchestration/flows/messages"
import { AddToBudgetNode, isTransactionType } from "~/domains/orchestration/flows/types"
import { BudgetDataI } from "~/features/budget/types"
import { useFetchBudgetsData } from "~/store/budget/hooks"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganization } from "~/store/organization/organizationSlice"

import { ConfigurationNode } from "./ConfigurationNode"

interface Props {
    selectedNode: AddToBudgetNode
    unselectCallback: () => void
}

export const AddToBudgetConfiguration: FC<Props> = ({ selectedNode, unselectCallback }) => {
    const { formatMessage } = useIntl()
    const organization = useAppSelector(selectCurrentOrganization)
    const { budgetsData } = useFetchBudgetsData(organization?.id, false)

    const [currentNode, setCurrentNode] = useState(selectedNode)

    const [budget, setBudget] = useState<BudgetDataI | null>(null)

    const handleChange = (field: keyof AddToBudgetNode) => (e: ChangeEvent<HTMLInputElement>) => {
        setCurrentNode({
            ...currentNode,
            [field]: e.target.value,
        })
    }

    const handleToggleFailIfOverbudget = (e: ChangeEvent<HTMLInputElement>) => {
        setCurrentNode({
            ...currentNode,
            failIfOverbudget: e.target.checked,
        })
    }

    const handleChangeBudget = (_, value: BudgetDataI | null) => {
        if (!value) return

        setBudget(value)
        setCurrentNode({
            ...currentNode,
            budgetId: value.id,
            metadata: {
                ...currentNode.metadata,
                additionalInformation: value.name,
            },
        })
    }

    const handleChangeTransactionType = (e: ChangeEvent<HTMLInputElement>): void => {
        const transactionType = e.target.value
        if (!isTransactionType(transactionType)) return

        setCurrentNode({
            ...currentNode,
            transactionType,
        })
    }

    useEffect(() => {
        const currentBudget = budgetsData.find((budget) => budget.id === currentNode.budgetId)

        if (currentBudget) {
            setBudget(currentBudget)
        }
    }, [budgetsData])

    const configurationNodeItemClassName = cls("flows-configurationNode-item", "flows-editor-sideBar-item")

    return (
        <ConfigurationNode configuredNode={currentNode} unselectCallback={unselectCallback}>
            <Stack gap={2} className={configurationNodeItemClassName}>
                <FormattedMessage tagName="h5" {...messages.addToBudgetConfiguration.title} />

                <Autocomplete
                    options={budgetsData}
                    value={budget}
                    onChange={handleChangeBudget}
                    getOptionLabel={(budget: BudgetDataI) => budget.name}
                    isOptionEqualToValue={(option: BudgetDataI, value: BudgetDataI) => option.id === value.id}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={formatMessage(messages.addToBudgetConfiguration.selectBudgetsLabel)}
                        />
                    )}
                />

                <TextField
                    select
                    required
                    label={formatMessage(messages.addToBudgetConfiguration.transactionTypeLabel)}
                    value={currentNode.transactionType ?? ""}
                    onChange={handleChangeTransactionType}
                    fullWidth
                >
                    {TRANSACTION_TYPES.map((type) => (
                        <MenuItem key={type} value={type}>
                            {formatMessage(messages.transactionType[type])}
                        </MenuItem>
                    ))}
                </TextField>

                <FormControlLabel
                    control={<Switch checked={currentNode.failIfOverbudget} onChange={handleToggleFailIfOverbudget} />}
                    label={formatMessage(messages.addToBudgetConfiguration.failIfOverbudgetLabel)}
                />
            </Stack>
            <Stack gap={2} className={configurationNodeItemClassName}>
                <Card
                    expandable
                    header={<FormattedMessage {...messages.configurationNode.advanced} />}
                    isExpandedByDefault={false}
                >
                    <Stack gap={2}>
                        <TextField
                            label={formatMessage(messages.addToBudgetConfiguration.transactionIdLabel)}
                            value={currentNode.transactionId}
                            onChange={handleChange("transactionId")}
                        />

                        <TextField
                            label={formatMessage(messages.addToBudgetConfiguration.amountLabel)}
                            value={currentNode.amount}
                            onChange={handleChange("amount")}
                        />

                        <TextField
                            label={formatMessage(messages.addToBudgetConfiguration.currencyLabel)}
                            value={currentNode.currency}
                            onChange={handleChange("currency")}
                        />
                    </Stack>
                </Card>
            </Stack>
        </ConfigurationNode>
    )
}
