import React, { ReactNode } from "react"
import { Modal, Size } from "~/components"

import "./ModalConfirm.scss"

interface Props {
    message: ReactNode
    title?: ReactNode
    icon?: ReactNode
    button1?: ReactNode
    button2?: ReactNode
    open: boolean
    size?: Size
    position?: "start" | "center" | "end"
}

export function ModalConfirm({
    message,
    title,
    icon,
    button1,
    button2,
    open,
    size = Size.SM,
    position = "center",
}: Props) {
    return (
        <Modal open={open} size={size} className={`modal-confirm ${position}`}>
            {title && <Modal.Header>{title}</Modal.Header>}
            {icon && <Modal.Header>{icon}</Modal.Header>}
            <Modal.Content>{message}</Modal.Content>
            <Modal.Footer>
                {button1 ? button1 : null}
                {button2 ? button2 : null}
            </Modal.Footer>
        </Modal>
    )
}
