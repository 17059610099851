import { Grid, Stack } from "@mui/material"
import { Navigate, useParams } from "react-router-dom"

import { CenterViewport, Loader } from "~/components"
import { HeaderH1 } from "~/components/Header/HeaderH1"
import { Features, isFeatureEnabled } from "~/core/featureFlag"
import { HOME_ROUTE } from "~/core/routes"
import "~/domains/transactions/assets/css/Purchases.scss"
import {
    InvoiceActions,
    InvoiceBudgets,
    InvoiceCommunication,
    InvoiceCopilot,
    InvoiceCustomFields,
    InvoiceDescription,
    InvoiceDocuments,
    InvoiceDuplicateWarning,
    InvoiceGeneralInfo,
    InvoiceItems,
    InvoiceLifeCycle,
    InvoiceOrganizations,
    InvoicePeopleInvolved,
    InvoicePreview,
    InvoicePurchaseOrders,
    InvoiceTags,
    InvoiceWorkflow,
} from "~/domains/transactions/invoices-v1/components"
import { InvoiceNavigation } from "~/domains/transactions/invoices-v1/components/InvoiceNavigation"
import { InvoicePayment } from "~/domains/transactions/invoices-v1/components/InvoicePayment"
import { InvoiceTotalAmount } from "~/domains/transactions/invoices-v1/components/InvoiceTotalAmount"
import { MY_DOCUMENTS_ROUTE } from "~/features/account/routes"
import { useAppSelector } from "~/store/hooks"
import { useFetchInvoice } from "~/store/invoice/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { ViewTypeI } from "~/types"

const PREFIX = "#INV"

export const Invoice = () => {
    const { invoiceId } = useParams<{ invoiceId: string }>()
    const currentOrganizationId = useAppSelector(selectCurrentOrganizationId)
    const { invoice } = useFetchInvoice(invoiceId, ViewTypeI.buyer, 0, false)

    if (!invoice) {
        return (
            <CenterViewport>
                <Loader />
            </CenterViewport>
        )
    }

    if (currentOrganizationId && !isFeatureEnabled(Features.InvoiceV1, currentOrganizationId)) {
        return <Navigate to={HOME_ROUTE} />
    }

    return (
        <div className="purchase-page">
            <div className="purchase-order">
                <Grid container>
                    <HeaderH1 title={`${PREFIX}${invoice.number}`} backLink={MY_DOCUMENTS_ROUTE}>
                        <InvoiceActions invoice={invoice} />
                    </HeaderH1>
                    <InvoiceNavigation invoice={invoice} />
                    <InvoiceDuplicateWarning invoice={invoice} />
                </Grid>
                <Grid container className="main-box">
                    <InvoiceLifeCycle invoice={invoice} />
                </Grid>
                <Grid container className="grid">
                    <Grid item xs className="column">
                        <InvoiceTotalAmount invoice={invoice} />
                        <InvoiceGeneralInfo invoice={invoice} />
                        <InvoiceCustomFields invoice={invoice} />
                        <InvoicePurchaseOrders invoice={invoice} />
                        <InvoiceWorkflow invoice={invoice} />
                        <InvoicePeopleInvolved invoice={invoice} />
                    </Grid>
                    <Grid item md={6} sm={8} xs={12} gap={3}>
                        <InvoiceOrganizations invoice={invoice} />
                        <InvoiceItems invoice={invoice} />
                        <InvoiceBudgets invoice={invoice} />
                        <InvoicePayment invoice={invoice} />
                        <InvoicePreview invoice={invoice} />
                    </Grid>
                    <Grid item xs>
                        <Stack gap={2}>
                            <InvoiceCopilot invoice={invoice} />
                            <InvoiceDescription invoice={invoice} />
                            <InvoiceTags invoice={invoice} />
                            <InvoiceDocuments invoiceId={invoice.id} />
                            <InvoiceCommunication invoice={invoice} />
                        </Stack>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}
