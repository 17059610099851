import { Grid, Typography } from "@mui/material"
import React from "react"

import Header from "."
import { BackLink } from "./BackLink"

interface Props extends React.PropsWithChildren {
    title: string
    icon?: React.ReactNode
    backLink?: string | (() => void)
    className?: string
    isShared?: boolean
}

export const HeaderH1 = ({ title, icon, backLink, children, className, isShared }: Props) => {
    return (
        <>
            <Header noHeaderSpacerLeft={true} className={className}>
                <Grid container justifyContent="space-between" alignItems="flex-end" className="header-top">
                    <Grid item className={"title " + (backLink ? "back-link" : "wrap-icon")}>
                        {backLink && !isShared ? (
                            <BackLink urlOrFunction={backLink} />
                        ) : icon ? (
                            <figure className="icon">{icon}</figure>
                        ) : null}
                        <Typography className="wrap-title" variant="h1">
                            {title}
                        </Typography>
                    </Grid>
                    <Grid item>{children}</Grid>
                </Grid>
            </Header>
        </>
    )
}
