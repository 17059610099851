import { HandleProps, Position } from "@xyflow/react"
import type { NodeProps, Node as NodeType } from "@xyflow/react"
import React, { FC } from "react"
import { Edit2 } from "react-feather"
import { useIntl } from "react-intl"

import { adaptExpressionToText } from "~/domains/orchestration/flows/core"
import { messages } from "~/domains/orchestration/flows/messages"
import { SetInvoiceLifecycleStatusNode as SetInvoiceLifecycleStatusNodeType } from "~/domains/orchestration/flows/types"

import { Node } from "./Node"

export const SetInvoiceLifecycleStatusNode: FC<NodeProps<NodeType<SetInvoiceLifecycleStatusNodeType>>> = ({
    data,
    selected,
}) => {
    const { formatMessage } = useIntl()

    const handles: HandleProps[] = [
        {
            type: "target",
            isConnectableEnd: true,
            position: Position.Top,
        },
        {
            type: "source",
            position: Position.Bottom,
            isConnectableStart: true,
        },
    ]

    const error = !data.invoiceId || !data.statusToSet

    return (
        <Node
            title={formatMessage(messages.setInvoiceLifecycleStatusNode.nodeTitle)}
            name={data.name}
            headerInformation={adaptExpressionToText(data.statusToSet)}
            icon={Edit2}
            handles={handles}
            selected={selected}
            slug={data.slug}
            error={error}
        ></Node>
    )
}
