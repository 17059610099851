import { useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { defineMessages, useIntl } from "react-intl"
import { useAppSelector } from "~/store/hooks"
import { rolesPermissionsActions, selectSpiceDBObjectPermissions } from "~/domains/identity/store/rolesPermissionsSlice"
import { rolesPermissionsApi } from "~/api/rolesPermissionsApi"
import { SpiceDBStorePermissions } from "~/domains/identity/store/rolesPermissionsState"
import { SpiceDBObjectType } from "~/domains/identity/features/roles-permissions/types/SpiceDB"
import { selectCurrentOrganization } from "~/store/organization/organizationSlice"
import { useFetchOrganizationMembers, useFetchOrganizationTeams } from "~/store/organization/hooks"

const messages = defineMessages({
    error: {
        id: "share.object.permissions.errorFetch",
        defaultMessage: "We can't retrieve the permissions. Please contact your administrator",
    },
})

interface SpiceDBStorePermissionsReturn {
    loading: boolean
    sharedPermissions: SpiceDBStorePermissions
}

export const useFetchObjectPermissions = (
    objectId: string,
    objectType: SpiceDBObjectType
): SpiceDBStorePermissionsReturn => {
    const dispatch = useDispatch()
    const { formatMessage } = useIntl()

    const [loading, setLoading] = useState<boolean>(true)
    const sharedPermissions = useAppSelector(selectSpiceDBObjectPermissions)
    const currentOrganization = useAppSelector(selectCurrentOrganization)
    const [members] = useFetchOrganizationMembers(currentOrganization)
    const { teams: organizationTeams, loading: teamsLoading } = useFetchOrganizationTeams(currentOrganization?.id, true)

    const fetchObjectPermissions = useCallback(async () => {
        try {
            if (currentOrganization?.id && !sharedPermissions.loading && !sharedPermissions.data) {
                dispatch(rolesPermissionsActions.fetchObjectPermissions())
                const sharedPermissions = await rolesPermissionsApi.fetchAllObjectPermissions(objectId, objectType)

                const userMap = new Map(members.map((user) => [user.userId, user.user]))
                const teamMap = new Map(organizationTeams.map((team) => [team.teamId, team]))

                const sharedPermissionsWithNames = sharedPermissions.map((sharedObject) => {
                    const { objectId } = sharedObject
                    const user = userMap.get(objectId)
                    const team = teamMap.get(objectId)

                    return {
                        ...sharedObject,
                        name: user ? user.fullName : team ? team.name : "",
                        email: user?.email ?? undefined,
                        membersNumber: team?.members.length ?? undefined,
                    }
                })

                dispatch(rolesPermissionsActions.fetchObjectPermissionsSuccess(sharedPermissionsWithNames))
                return sharedPermissionsWithNames
            } else {
                return sharedPermissions
            }
        } catch (error) {
            dispatch(rolesPermissionsActions.fetchObjectPermissionsFailed(formatMessage(messages.error)))
        } finally {
            setLoading(false)
        }
    }, [currentOrganization, sharedPermissions, members, organizationTeams])

    useEffect(() => {
        if (!teamsLoading && members.length) {
            fetchObjectPermissions()
        }
    }, [fetchObjectPermissions, teamsLoading, members])

    return { sharedPermissions, loading }
}
