import { useCallback, useContext, useMemo } from "react"

import { useAppDispatch, useAppSelector } from "~/store/hooks"
import {
    ocrActions,
    selectHasOcr,
    selectOcrCurrentAutocompleteText,
    selectOcrCurrentInput,
    selectOcrCurrentInputType,
    selectOcrDetails,
    selectOcrImages,
    selectOcrShowShapes,
} from "~/store/ocr/ocrSlice"

import { OcrExtractContext } from "./OcrExtractContext"
import { OCRInputTypes } from "./extract"

export function useOcrExtract(imagesUrls?: string[], details?: string) {
    const dispatch = useAppDispatch()

    const showShapes = useAppSelector(selectOcrShowShapes)
    const currentInput = useAppSelector(selectOcrCurrentInput)
    const currentInputType = useAppSelector(selectOcrCurrentInputType)
    const ocrImages = useAppSelector(selectOcrImages)
    const reduxOcrDetails = useAppSelector(selectOcrDetails)
    const images = imagesUrls ?? ocrImages
    const ocrDetails = details ?? reduxOcrDetails
    const hasOcr = useAppSelector(selectHasOcr)
    const currentAutocompleteText = useAppSelector(selectOcrCurrentAutocompleteText)
    const { setCallback } = useContext(OcrExtractContext)

    const setShowShapes = useCallback(
        (show: boolean) => {
            dispatch(ocrActions.updateOcrShowShapes(show))
        },
        [dispatch]
    )

    const resetShowShapes = useCallback(() => {
        dispatch(ocrActions.updateOcrShowShapes(false))
        dispatch(ocrActions.updateOcrCurrentInput(""))
        dispatch(ocrActions.updateOcrCurrentInputType(null))
        setCallback(null)
    }, [dispatch, setCallback])

    const toggleShowShapes = useCallback(
        (inputName: string, inputType: OCRInputTypes) => {
            if (inputName === currentInput) {
                resetShowShapes()
            } else {
                dispatch(ocrActions.updateOcrShowShapes(true))
                dispatch(ocrActions.updateOcrCurrentInput(inputName))
                dispatch(ocrActions.updateOcrCurrentInputType(inputType))
                setCallback(null)
            }
        },
        [dispatch, resetShowShapes, setCallback]
    )

    const setHasOcr = useCallback(
        (hasOcr: boolean) => {
            dispatch(ocrActions.updateHasOcr(hasOcr))
        },
        [dispatch]
    )

    const setOcrCurrentAutocompleteText = useCallback(
        (text: string) => {
            dispatch(ocrActions.updateOcrCurrentAutocompleteText(text))
        },
        [dispatch]
    )

    const resetOcrDetails = useCallback(() => {
        dispatch(ocrActions.resetOcrDetails())
    }, [dispatch])

    return useMemo(
        () => ({
            showShapes,
            currentInput,
            currentInputType,
            images,
            ocrDetails,
            toggleShowShapes,
            resetShowShapes,
            setShowShapes,
            setHasOcr,
            hasOcr,
            currentAutocompleteText,
            setOcrCurrentAutocompleteText,
            resetOcrDetails,
        }),
        [showShapes, currentInput, currentInputType, images, ocrDetails, hasOcr]
    )
}
