import { Autocomplete, Skeleton, TextField, TextFieldProps, TextFieldVariants } from "@mui/material"

import { PaymentMethod } from "~/domains/payment/payment-methods/types/PaymentMethod"

interface PaymentMethodsSelectorProps {
    value: string | null
    label: string
    items: PaymentMethod[]
    variant?: TextFieldVariants
    required?: boolean
    disabled?: boolean
    loading?: boolean
    onChange: (value: PaymentMethod | null) => void
}

export const PaymentMethodsSelector = ({
    value,
    label,
    onChange,
    items,
    variant = "filled",
    required = false,
    disabled = false,
    loading = false,
}: PaymentMethodsSelectorProps) => {
    const inputValue = items?.find((item) => item.id === value)

    const renderInput = (params: TextFieldProps) => (
        <TextField {...params} required={required} label={label} variant={variant} fullWidth />
    )

    const handleChange = (_: React.SyntheticEvent<Element, Event>, newValue: PaymentMethod | null): void => {
        onChange(newValue)
    }

    if (loading) {
        return <Skeleton variant="rectangular" width="100%" height={53} />
    }

    return (
        <Autocomplete
            disableClearable={true}
            value={inputValue}
            disabled={disabled}
            options={items ?? []}
            getOptionLabel={(option) => option.name}
            onChange={handleChange}
            renderInput={renderInput}
        />
    )
}
