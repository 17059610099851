import { List, ListItem, Stack } from "@mui/material"
import { FC, Fragment, useState } from "react"
import { Plus, Trash2 } from "react-feather"
import { FormattedMessage } from "react-intl"
import { v4 as uuid } from "uuid"

import { Button } from "~/components"
import { messages } from "~/domains/orchestration/flows/messages"

import type { ConditionGroup } from "./EventTriggerConfiguration"

interface Props {
    group: ConditionGroup
    handleChangeConditions: (group: ConditionGroup) => void
    handleNewCondition: (group: ConditionGroup) => void
}

export const AndCondition: FC<Props> = ({ group, handleChangeConditions, handleNewCondition }) => {
    const initialAndConditions = group.conditions.map((condition) => ({
        id: uuid(),
        condition: condition,
    }))

    const [andConditions, setAndConditions] = useState(initialAndConditions)

    const handleDelete = (deletedId: string) => () => {
        const newConditions = andConditions.filter((g) => g.id !== deletedId)
        setAndConditions(newConditions)
        handleChangeConditions({
            id: group.id,
            conditions: newConditions.map((g) => g.condition),
        })
    }

    const handleNew = (group: ConditionGroup) => () => handleNewCondition(group)

    const conditionsList = andConditions.map((group, index) => {
        return (
            <Fragment key={group.id}>
                <ListItem>
                    {index !== 0 ? (
                        <FormattedMessage tagName="h5" {...messages.eventTriggerConfiguration.andConditionOtherLabel} />
                    ) : null}
                </ListItem>
                <ListItem>
                    <p>{group.condition}</p>
                    <Trash2 size={18} color="var(--color-grey)" onClick={handleDelete(group.id)} />
                </ListItem>
            </Fragment>
        )
    })

    return (
        <Stack gap={1} className="flows-eventTriggerConfiguration-andConditions">
            <List>{conditionsList}</List>
            <Stack direction="column" gap={1} className="flows-eventTriggerConfiguration-newConditionSuggestions">
                <Button
                    type="grey-light"
                    onClick={handleNew(group)}
                    className="flows-eventTriggerConfiguration-newCondition"
                >
                    <Plus size={18} />
                    <FormattedMessage {...messages.eventTriggerConfiguration.newConditionLabel} />
                </Button>
            </Stack>
        </Stack>
    )
}
