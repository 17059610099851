import { Stack, TextField } from "@mui/material"
import cls from "classnames"
import React, { ChangeEvent, FC, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"

import { Card } from "~/components"
import { messages } from "~/domains/orchestration/flows/messages"
import { FitsToBudgetNode } from "~/domains/orchestration/flows/types"

import { ConfigurationNode } from "./ConfigurationNode"

interface Props {
    selectedNode: FitsToBudgetNode
    unselectCallback: () => void
}

export const FitsToBudgetConfiguration: FC<Props> = ({ selectedNode, unselectCallback }) => {
    const { formatMessage } = useIntl()

    // Handle the current node
    const [currentNode, setCurrentNode] = useState(selectedNode)

    const handleChangeAdvanced =
        (key: keyof FitsToBudgetNode) =>
        (e: ChangeEvent<HTMLInputElement>): void => {
            const valueToSet = e.target.value
            setCurrentNode({
                ...currentNode,
                [key]: valueToSet,
            })
        }

    const configurationNodeItemClassName = cls("flows-configurationNode-item", "flows-editor-sideBar-item")

    return (
        <ConfigurationNode configuredNode={currentNode} unselectCallback={unselectCallback}>
            <Stack gap={2} className={configurationNodeItemClassName}>
                <FormattedMessage tagName="h5" {...messages.fitsToBudgetConfiguration.title} />

                <Card
                    expandable
                    header={<FormattedMessage {...messages.configurationNode.advanced} />}
                    isExpandedByDefault={false}
                >
                    <Stack gap={2}>
                        <TextField
                            label={formatMessage(messages.fitsToBudgetConfiguration.budgetIdLabel)}
                            value={currentNode.budgetId}
                            onChange={handleChangeAdvanced("budgetId")}
                        />

                        <TextField
                            label={formatMessage(messages.fitsToBudgetConfiguration.transactionIdLabel)}
                            value={currentNode.transactionId}
                            onChange={handleChangeAdvanced("transactionId")}
                        />

                        <TextField
                            label={formatMessage(messages.fitsToBudgetConfiguration.transactionTypeLabel)}
                            value={currentNode.transactionType}
                            onChange={handleChangeAdvanced("transactionType")}
                        />

                        <TextField
                            label={formatMessage(messages.fitsToBudgetConfiguration.amountLabel)}
                            value={currentNode.amount}
                            onChange={handleChangeAdvanced("amount")}
                        />

                        <TextField
                            label={formatMessage(messages.fitsToBudgetConfiguration.currencyLabel)}
                            value={currentNode.currency}
                            onChange={handleChangeAdvanced("currency")}
                        />
                    </Stack>
                </Card>
            </Stack>
        </ConfigurationNode>
    )
}
