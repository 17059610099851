import * as Sentry from "@sentry/browser"
import { isLeft } from "fp-ts/Either"
import { TypeOf } from "io-ts"
import { useEffect, useMemo } from "react"

import { organizationApi } from "~/api"
import { store } from "~/store"
import {
    CountryCode,
    NO_ORGANIZATION,
    NO_ORGANIZATION_ID,
    OrganizationI,
    OrganizationIO,
    OrganizationId,
    OrganizationInvitationI,
    OrganizationInvitationIO,
    OrganizationMemberI,
    OrganizationMemberIO,
    OrganizationRegistrationI,
    OrganizationRegistrationIO,
    OrganizationRole,
    validateCountryCodeOrSetUnknown,
} from "~/types"
import { WHITE_SPACE_REGEXP } from "~/utils/string"

import { useAppDispatch, useAppSelector } from "../../hooks"
import { organizationActions, selectOrganizationState, selectOrganizationsFilters } from "../organizationSlice"

type LoadingOrError =
    | {
          loading: true
          error: undefined
      }
    | {
          loading: true
          error: string
      }

type FetchOrganization =
    | {
          organization: OrganizationI
          loading: false
          error: undefined
      }
    | ({
          organization: undefined
      } & LoadingOrError)

const checkOrganizationRole = (roleStr: string): OrganizationRole => {
    const role = roleStr as OrganizationRole
    if (Object.values(OrganizationRole).includes(role)) return role
    Sentry.captureMessage(`Unknown role in organization: ${roleStr}`, "warning")
    return OrganizationRole.UNKNOWN
}

const parseOrganizationInvitation = (
    invitationData: TypeOf<typeof OrganizationInvitationIO>
): OrganizationInvitationI => ({
    ...invitationData,
    role: checkOrganizationRole(invitationData.role),
})

const parseOrganizationMember = (memberData: TypeOf<typeof OrganizationMemberIO>): OrganizationMemberI => ({
    ...memberData,
    role: checkOrganizationRole(memberData.role),
})

const parseOrganizationRegistration = (
    registrationData: TypeOf<typeof OrganizationRegistrationIO> | null | undefined
): OrganizationRegistrationI => {
    return {
        ...registrationData,
        legalName: registrationData?.legalName ?? "",
        // TODO: remove when backend deployed
        preferredRegistrationNumber: registrationData?.preferredRegistrationNumber || {
            registrationNumber: "",
            registrationType: "",
        },
        countryCode: validateCountryCodeOrSetUnknown(registrationData?.countryCode ?? CountryCode.UNKNOWN),
    }
}

const parseOrganizationData = (organizationData: TypeOf<typeof OrganizationIO>): OrganizationI => ({
    ...organizationData,
    identifier:
        organizationData.registration?.dunsNumber ??
        organizationData.registration?.preferredRegistrationNumber?.registrationNumber ??
        "",
    invitations: organizationData.invitations.map(parseOrganizationInvitation),
    members: organizationData.members.map(parseOrganizationMember),
    membershipRequests: organizationData.membershipRequests.map(parseOrganizationMember),
    registration: parseOrganizationRegistration(organizationData.registration),
})

export const decodeOrganization = (organizationData: unknown): OrganizationI => {
    const decoded = OrganizationIO.decode(organizationData)
    if (isLeft(decoded)) {
        console.error({
            data: organizationData,
            error: decoded.left,
        })
        const error = new Error(`Invalid data return by the API`)
        Sentry.captureException(error, {
            extra: {
                data: organizationData,
                error: decoded.left,
            },
        })
        throw error
    } else {
        return parseOrganizationData(decoded.right)
    }
}

export const fetchAndDecodeOrganization = async (organizationId: OrganizationId) => {
    const organizationData = await organizationApi.getOrganizationById(organizationId)
    return decodeOrganization(organizationData)
}

export const fetchOrganization = async (
    organizationId: OrganizationId,
    dispatch: ReturnType<typeof useAppDispatch>
): Promise<OrganizationI> => {
    try {
        dispatch(organizationActions.fetchOrganization(organizationId))
        const organization =
            organizationId === NO_ORGANIZATION_ID ? NO_ORGANIZATION : await fetchAndDecodeOrganization(organizationId)
        dispatch(organizationActions.fetchOrganizationSuccess(organization))
        return organization
    } catch (error) {
        dispatch(
            organizationActions.fetchOrganizationFailed({
                organizationId,
                error: `${error}`,
            })
        )
        throw error
    }
}

export const useFetchOrganization = (
    organizationId?: OrganizationId | null,
    forceFetch?: boolean
): FetchOrganization => {
    const { organizations, loadings, errors } = useAppSelector(selectOrganizationState)
    const dispatch = useAppDispatch()
    const loading = organizationId ? loadings[organizationId] : false
    const error = organizationId ? errors[organizationId] : undefined
    useEffect(() => {
        const { organizations, loadings, errors } = selectOrganizationState(store.getState())
        if (
            organizationId &&
            !loadings[organizationId] &&
            !errors[organizationId] &&
            (forceFetch || !organizations[organizationId])
        ) {
            fetchOrganization(organizationId, dispatch)
        }
    }, [organizationId, dispatch])
    return useMemo(() => {
        const org = organizationId ? organizations[organizationId] : undefined
        if (loading) return { loading }
        if (error) {
            return {
                loading: true,
                error,
            }
        }
        if (!org) return { loading: true }
        return {
            loading: false,
            organization: org,
        }
    }, [organizations, organizationId, loading])
}

const noFilter = () => true

const getOrganizationsFilter = (filter: string) => {
    if (!filter) {
        return noFilter
    }
    const filterWords = filter.toLocaleLowerCase().split(WHITE_SPACE_REGEXP)
    return (organization: OrganizationI) => {
        const organizationWords = `${organization.name}`.toLocaleLowerCase().split(WHITE_SPACE_REGEXP)
        return filterWords.every((word) =>
            organizationWords.some((organizationWord) => organizationWord.indexOf(word) >= 0)
        )
    }
}

type FetchOrganizationsResult = {
    organizations: OrganizationI[]
    loadings: Record<OrganizationId, boolean | undefined>
    errors: Record<OrganizationId, string | undefined>
}

export const useFetchOrganizations = (
    organizationsIds: OrganizationId[],
    forceFetch?: boolean
): FetchOrganizationsResult => {
    const { organizations, loadings, errors } = useAppSelector(selectOrganizationState)
    const dispatch = useAppDispatch()
    const filters = useAppSelector(selectOrganizationsFilters)

    useEffect(() => {
        const { loadings, errors, organizations } = selectOrganizationState(store.getState())
        const organizationsIdsToFetch = organizationsIds.filter(
            (organizationId) =>
                organizationId !== NO_ORGANIZATION_ID &&
                !loadings[organizationId] &&
                !errors[organizationId] &&
                (forceFetch || !organizations[organizationId])
        )
        if (organizationsIdsToFetch.length > 0) {
            organizationsIdsToFetch.forEach((organizationId) =>
                dispatch(organizationActions.fetchOrganization(organizationId))
            )
            organizationApi
                .fetchOrganizationsByIds(organizationsIdsToFetch)
                .then((organizations: OrganizationI[]) => {
                    organizations.forEach((organizationData: OrganizationI) => {
                        try {
                            const organization = decodeOrganization(organizationData)
                            dispatch(organizationActions.fetchOrganizationSuccess(organization))
                        } catch (error) {
                            dispatch(
                                organizationActions.fetchOrganizationFailed({
                                    organizationId: organizationData.id,
                                    error: `${error}`,
                                })
                            )
                        }
                    })
                })
                .catch((error) => {
                    organizationsIdsToFetch.forEach((organizationId) =>
                        dispatch(organizationActions.fetchOrganizationFailed({ organizationId, error: `${error}` }))
                    )
                })
            if (
                organizationsIds.indexOf(NO_ORGANIZATION_ID) >= 0 &&
                !loadings[NO_ORGANIZATION_ID] &&
                !errors[NO_ORGANIZATION_ID]
            ) {
                dispatch(organizationActions.fetchOrganizationSuccess(NO_ORGANIZATION))
            }
        }
    }, [organizationsIds, dispatch, filters])

    return useMemo(() => {
        const organizationsResult = organizationsIds
            .filter((organizationId) => !!organizations[organizationId])
            .map((organizationId) => organizations[organizationId])
            .filter(getOrganizationsFilter(filters.organizations))
        return { organizations: organizationsResult, loadings, errors }
    }, [organizations, organizationsIds, loadings, filters])
}
