import { Box } from "@mui/material"
import { defineMessages, useIntl } from "react-intl"

import { PurchaseViewType } from "~/domains/transactions/types/Purchases"

import { Date } from "./Date"
import "./Dates.scss"

const messages = defineMessages({
    creationDate: {
        id: "purchase.requests.request.creationDate",
        defaultMessage: "Creation date",
    },
    deliveryDate: {
        id: "purchase.requests.request.deliveryDate",
        defaultMessage: "Delivery date",
    },
})

interface Props {
    creationDate: string
    expectedDeliveryDate?: string
    pageMode: PurchaseViewType
    setDeliveryDate: (date) => void
    required?: boolean
}

export function Dates({ creationDate, expectedDeliveryDate, pageMode, setDeliveryDate, required = false }: Props) {
    const { formatMessage } = useIntl()
    return (
        <Box component={"div"} className="dates">
            {creationDate ? <Date date={creationDate} label={formatMessage(messages.creationDate)} /> : null}

            <Date
                date={expectedDeliveryDate}
                label={formatMessage(messages.deliveryDate)}
                edit={pageMode === PurchaseViewType.EDIT || pageMode === PurchaseViewType.ADD}
                setDeliveryDate={setDeliveryDate}
                required={required}
            />
        </Box>
    )
}
