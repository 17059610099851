import { useMemo } from "react"
import { useCallback } from "react"
import { useSelector } from "react-redux"

import { CustomFieldValueI } from "~/domains/transactions/custom-fields/types/CustomFieldValue"
import { InvoiceLineCustomFieldsI } from "~/features/invoice/core"
import { RootState } from "~/store"
import { useAppDispatch } from "~/store/hooks"
import { invoiceActions } from "~/store/invoice/invoiceSlice"

export type UseInvoiceLineCustomFieldsReturnType = [
    InvoiceLineCustomFieldsI,
    (key: keyof InvoiceLineCustomFieldsI, value: CustomFieldValueI) => void
]

export const useInvoiceLineCustomFields = (lineId?: string): UseInvoiceLineCustomFieldsReturnType => {
    const dispatch = useAppDispatch()
    const invoiceLineCustomFields = useSelector((state: RootState) => state.invoice.invoiceLineCustomFields)

    const fields = useMemo(() => {
        return lineId && invoiceLineCustomFields[lineId]
            ? invoiceLineCustomFields[lineId]
            : ({} as InvoiceLineCustomFieldsI)
    }, [invoiceLineCustomFields, lineId])

    const setFieldValue = useCallback(
        (key: keyof InvoiceLineCustomFieldsI, customField: CustomFieldValueI) => {
            lineId && dispatch(invoiceActions.setOneInvoiceLineCustomFields({ lineId, key, customField }))
        },
        [dispatch, lineId]
    )

    return [fields, setFieldValue]
}
