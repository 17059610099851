import { styled } from "@mui/material"
import * as Sentry from "@sentry/browser"
import React, { FC, useEffect, useMemo } from "react"
import { useIntl } from "react-intl"
import { toast } from "react-toastify"

import { Loader } from "~/components"
import { useGetFlowsQuery } from "~/domains/orchestration/flows/api/flowsApi"
import { useOrganizationId } from "~/domains/orchestration/flows/hooks"
import { messages } from "~/domains/orchestration/flows/messages"

import { FlowCard } from "./FlowCard"

interface Props {
    filter: string
}

const StyledCardContainer = styled("div")({
    display: "grid",
    gap: "var(--spacing-md)",
    height: "350px",
    gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
})

export const List: FC<Props> = ({ filter }) => {
    // TODO: query params should be dynamic
    const organizationId = useOrganizationId()
    const { data, error, isLoading, refetch } = useGetFlowsQuery(false)
    const { formatMessage } = useIntl()
    const filteredFlows = useMemo(
        () => data?.flows.filter((flow) => flow.name.toLowerCase().includes(filter.toLowerCase())) || [],
        [data, filter]
    )

    useEffect(() => {
        refetch()
    }, [organizationId])

    if (isLoading) {
        return <Loader fullscreen />
    }

    if (error) {
        Sentry.captureException(error)
        toast.error(formatMessage(messages.error.fetchingFlows))
        return null
    }

    if (!data) {
        return null
    }

    return (
        <StyledCardContainer>
            {filteredFlows.map((flow) => (
                <FlowCard key={flow.id} flow={flow} />
            ))}
        </StyledCardContainer>
    )
}
