import dayjs from "dayjs"
import React, { useEffect, useMemo, useState } from "react"

import { ValidationSchema } from "~/domains/payment/payment-method-details/types"

type Errors<T extends object> = {
    [K in keyof T]?: React.ReactNode
}

export const useFormValidation = <T extends object>(values: T, validationSchema: ValidationSchema<T>) => {
    const [errors, setErrors] = useState<Errors<T>>({})

    const stableValidationSchema = useMemo(() => validationSchema, [validationSchema])

    const validate = () => {
        const newErrors: Errors<T> = {}

        Object.keys(stableValidationSchema).forEach((key) => {
            const fieldKey = key as keyof T
            const value = values[fieldKey]
            const rules = stableValidationSchema[fieldKey]

            if (!rules) return

            if (rules.required?.value && !value) {
                newErrors[fieldKey] = rules.required.message ?? `${String(fieldKey)} is required`
            } else if (rules.pattern?.value && typeof value === "string" && !rules.pattern.value.test(value)) {
                newErrors[fieldKey] = rules.pattern.message ?? `${String(fieldKey)} is invalid`
            } else if (rules.minLength?.value && typeof value === "string" && value.length < rules.minLength.value) {
                newErrors[fieldKey] =
                    rules.minLength.message ??
                    `${String(fieldKey)} should be at least ${rules.minLength.value} characters`
            } else if (rules.maxLength?.value && typeof value === "string" && value.length > rules.maxLength.value) {
                newErrors[fieldKey] =
                    rules.maxLength.message ??
                    `${String(fieldKey)} should be at most ${rules.maxLength.value} characters`
            } else if (rules.date?.value && dayjs.isDayjs(value) && !value.isValid()) {
                newErrors[fieldKey] = rules.date.message ?? `${String(fieldKey)} is invalid`
            } else if (rules.custom) {
                const customError = rules.custom(value, values)

                if (customError.value) {
                    newErrors[fieldKey] = customError.message
                }
            }
        })

        setErrors(newErrors)

        return Object.keys(newErrors).length === 0
    }

    useEffect(() => {
        setErrors((prevErrors) => {
            const updatedErrors: Errors<T> = {}
            Object.keys(prevErrors).forEach((key) => {
                const fieldKey = key as keyof T
                if (!(prevErrors[fieldKey] && values[fieldKey])) {
                    updatedErrors[fieldKey] = prevErrors[fieldKey]
                }
            })
            return updatedErrors
        })
    }, [values])

    return { errors, validate }
}
