import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { FormState } from "~/domains/payment/payment-method-details/types"
import { PaymentMethod, PaymentMethodType } from "~/domains/payment/payment-methods/types"
import { RootState } from "~/store"

import { paymentMethodDetailsState } from "./paymentMethodDetailsState"

const initialState = paymentMethodDetailsState

const paymentMethodDetailsSlice = createSlice({
    name: "paymentMethodDetails",
    initialState,
    reducers: {
        setSelectedItem(state, action: PayloadAction<FormState | null>) {
            state.selectedItem = action.payload
        },
        setSelectedPaymentMethod(state, action: PayloadAction<PaymentMethod | null>) {
            state.selectedPaymentMethod = action.payload
        },
        setActiveTab(state, action: PayloadAction<PaymentMethodType>) {
            state.activeTab = action.payload
        },
        reset() {
            return initialState
        },
    },
})

export const paymentMethodDetailsActions = paymentMethodDetailsSlice.actions

export const selectSelectedItem = (state: RootState) => state.paymentMethodDetails.selectedItem
export const selectSelectedPaymentMethod = (state: RootState) => state.paymentMethodDetails.selectedPaymentMethod
export const selectActiveTab = (state: RootState) => state.paymentMethodDetails.activeTab

const paymentMethodDetails = paymentMethodDetailsSlice.reducer
export default paymentMethodDetails
