import { FetchBaseQueryError, createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { v4 as uuidv4 } from "uuid"

import { prepareHeadersWithAuthorizationAndOrganizationId as prepareHeaders } from "~/api/prepareHeaders"
import {
    catalogFromApiAdapters,
    priceFromApiAdapters,
    productFromApiAdapters,
    productVariantFromApiAdapters,
} from "~/domains/identity/features/catalog/adapters/catalogFromApiAdapters"
import {
    Catalog,
    CatalogProduct,
    CatalogProductPrice,
    CatalogProductVariant,
} from "~/domains/identity/features/catalog/types/Catalog"

const BASE_URL = import.meta.env.VITE_API_CATALOG_URL

const catalogMock = (): Catalog => ({
    id: uuidv4(),
    organizationId: "1",
    supplierId: "1",
    name: "Catalog 1",
    nameTranslations: {},
    description: "Description 1",
    descriptionTranslations: {},
    catalogType: "FOR_PURCHASE",
    isActive: true,
    accessType: "PUBLIC",
    sharedWith: [],
    createdAt: "2021-01-01",
    updatedAt: "2021-01-01",
})

const productMock = (): CatalogProduct => ({
    id: uuidv4(),
    defaultVariantId: "1",
    organizationId: "1",
    sku: "1",
    label: "Product 1",
    labelTranslations: {},
    description: "Description 1",
    descriptionTranslations: {},
    status: "DRAFT",
    unitQuantity: 1,
    brand: "1",
    defaultPriceId: "1",
    createdAt: "2021-01-01",
    updatedAt: "2021-01-01",
})

export const catalogApiV1 = createApi({
    reducerPath: "catalogApi",
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL, prepareHeaders }),
    endpoints: (builder) => ({
        getCatalogs: builder.query<Catalog[], { organizationId: string }>({
            query: ({ organizationId }) => ({
                url: `/${organizationId}/catalogs`,
            }),
            transformResponse: (response: Catalog[]) => {
                return response.map(catalogFromApiAdapters)
            },
            transformErrorResponse: (error: FetchBaseQueryError) => {
                console.log("error", error)
                return [catalogMock(), catalogMock()].map(catalogFromApiAdapters)
            },
        }),

        getCatalogById: builder.query<Catalog, { organizationId: string; catalogId: string }>({
            query: ({ organizationId, catalogId }) => ({
                url: `/${organizationId}/catalogs/${catalogId}`,
            }),
            transformResponse: (response: Catalog) => catalogFromApiAdapters(response),
        }),

        getCatalogProducts: builder.query<CatalogProduct[], { organizationId: string; catalogId: string }>({
            query: ({ organizationId, catalogId }) => ({
                url: `/${organizationId}/catalogs/${catalogId}/products`,
            }),
            transformResponse: (response: CatalogProduct[]) => response.map(productFromApiAdapters),
            transformErrorResponse: (error: FetchBaseQueryError) => {
                console.log("error", error)
                return [productMock(), productMock()].map(productFromApiAdapters)
            },
        }),

        getProducts: builder.query<CatalogProduct[], { organizationId: string }>({
            query: ({ organizationId }) => ({
                url: `/${organizationId}/products`,
            }),
            transformResponse: (response: CatalogProduct[]) => response.map(productFromApiAdapters),
        }),

        getProductById: builder.query<CatalogProduct, { organizationId: string; productId: string }>({
            query: ({ organizationId, productId }) => ({
                url: `/${organizationId}/products/${productId}`,
            }),
            transformResponse: (response: CatalogProduct) => productFromApiAdapters(response),
        }),

        getProductVariants: builder.query<CatalogProductVariant[], { organizationId: string; productId: string }>({
            query: ({ organizationId, productId }) => ({
                url: `/${organizationId}/products/${productId}/variants`,
            }),
            transformResponse: (response: CatalogProductVariant[]) => response.map(productVariantFromApiAdapters),
        }),

        getProductVariantById: builder.query<
            CatalogProductVariant,
            { organizationId: string; productId: string; variantId: string }
        >({
            query: ({ organizationId, productId, variantId }) => ({
                url: `/${organizationId}/products/${productId}/variants/${variantId}`,
            }),
            transformResponse: (response: CatalogProductVariant) => productVariantFromApiAdapters(response),
        }),

        getPrices: builder.query<
            CatalogProductPrice[],
            { organizationId: string; productId: string; variantId: string }
        >({
            query: ({ organizationId, productId, variantId }) => ({
                url: `/${organizationId}/products/${productId}/variants/${variantId}/prices`,
            }),
            transformResponse: (response: CatalogProductPrice[]) => response.map(priceFromApiAdapters),
        }),

        getPriceById: builder.query<
            CatalogProductPrice,
            { organizationId: string; productId: string; variantId: string; priceId: string }
        >({
            query: ({ organizationId, productId, variantId, priceId }) => ({
                url: `/${organizationId}/products/${productId}/variants/${variantId}/prices/${priceId}`,
            }),
            transformResponse: (response: CatalogProductPrice) => priceFromApiAdapters(response),
        }),
    }),
})

export const {
    useGetCatalogsQuery,
    useGetCatalogByIdQuery,
    useGetCatalogProductsQuery,
    useGetProductsQuery,
    useGetProductByIdQuery,
    useGetProductVariantsQuery,
    useGetProductVariantByIdQuery,
} = catalogApiV1
