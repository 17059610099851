import React, { lazy, Suspense } from "react"
import { Route } from "react-router-dom"

import { ProtectedRoutes } from "~/features/auth/ProtectedRoute"
import { DOCUMENT_PAGE_ROUTE, DOCUMENTS_ROUTE } from "./routes"
import { Loader } from "~/components"

const OrganizationDocuments = lazy(() =>
    import("./pages/OrganizationDocuments").then(({ OrganizationDocuments }) => ({
        default: OrganizationDocuments,
    }))
)
const DocumentPage = lazy(() =>
    import("./pages/DocumentPage").then(({ DocumentPage }) => ({
        default: DocumentPage,
    }))
)

export default {
    routes: [
        <Route element={<ProtectedRoutes />} key="prs_route">
            <Route
                path={DOCUMENTS_ROUTE}
                element={
                    <Suspense fallback={<Loader />}>
                        <OrganizationDocuments />
                    </Suspense>
                }
            />
            <Route
                path={DOCUMENT_PAGE_ROUTE}
                element={
                    <Suspense fallback={<Loader />}>
                        <DocumentPage />
                    </Suspense>
                }
            />
        </Route>,
    ],
}
