import * as t from "io-ts"

import { Opaque } from "~/utils"

import { CreateTagI, CreateTagIO } from "./CreateTag"
import { TagGroupI } from "./TagGroup"

export type TagId = Opaque<string, { readonly T: unique symbol }>

export interface TagI extends CreateTagI {
    tagId: TagId
    children?: TagI[]
}

// Use recursion for self-referencing types
export const TagIO: t.Type<TagI> = t.recursion("TagIO", () =>
    t.intersection([
        t.type({
            tagId: t.string,
        }),
        t.partial({
            children: t.array(TagIO), // Recursive reference to TagIO itself
        }),
        CreateTagIO,
    ])
)

export type TagAndTagGroupNameI = TagI & {
    tagGroupName: string
    tagGroupShortName?: string | null
    id?: string
}

export enum FromRecommandationValues {
    MANUAL = "MANUAL",
    AUTO = "AUTO",
}

export type SelectedTagI = TagI & {
    ratio?: number
    tagGroup?: TagGroupI
    fromRecommandation?: FromRecommandationValues
    tagGroupName?: string
}

export type SelectedTagWithObjectIdI = SelectedTagI & {
    objectId?: string
}
