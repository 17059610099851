import React, { ReactNode, useCallback, useState } from "react"
import { Menu, MenuItem, Tooltip, IconButton } from "@mui/material"
import { ChevronDown, MoreHorizontal } from "react-feather"
import { Button, ButtonType } from "~/components"

import { permissionMessages } from "~/domains/identity/features/roles-permissions/utils/permissions"
import { useIntl } from "react-intl"

import "./ActionsMenu.scss"

export type Actions<T> = {
    label?: ReactNode
    action?: ((dataType: T) => void) | (() => void)
    icon?: React.ReactNode
    render?: React.ReactNode
}

type Props<T> = {
    actions: Actions<T>[]
    dataType?: T
    labelButton?: string
    disabled?: boolean
    buttonType?: ButtonType
    icon?: React.ReactNode
    isActionIcon?: boolean
}

const renderButton = (
    handleClick: (event: React.MouseEvent<HTMLButtonElement & MouseEvent>) => void,
    disabled?: boolean,
    labelButton?: string,
    buttonType?: ButtonType,
    icon?: React.ReactNode,
    isActionIcon?: boolean
) => {
    const defaultIcon = icon ?? <MoreHorizontal size={18} className="svg-grey" />

    if (labelButton) {
        return (
            <Button type={buttonType} onClick={handleClick} className="menu-actions-icon" disabled={disabled}>
                {labelButton}
                <ChevronDown size={18} className="svg-white" />
            </Button>
        )
    }

    return isActionIcon ? (
        <IconButton className="menu-actions-icon" disabled={disabled} onClick={handleClick}>
            {defaultIcon}
        </IconButton>
    ) : (
        <Button type={buttonType} onClick={handleClick} className="menu-actions-icon" disabled={disabled}>
            {defaultIcon}
        </Button>
    )
}

export const ActionsMenu = <T,>({
    dataType,
    actions,
    labelButton,
    disabled,
    icon,
    buttonType = "grey",
    isActionIcon = true,
}: Props<T>) => {
    const { formatMessage } = useIntl()
    const [anchorEl, setAnchorEl] = useState<EventTarget & HTMLButtonElement & MouseEvent>()
    const open = Boolean(anchorEl)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement & MouseEvent>) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget)
    }

    const handleClose = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        event.stopPropagation()
        setAnchorEl(undefined)
    }

    const handleDataAction = (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        actionExec: (dataType: T) => void,
        dataType: T
    ) => {
        event.stopPropagation()
        actionExec(dataType)
        handleClose(event)
    }

    const handleNoDataAction = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, actionExec: () => void) => {
        event.stopPropagation()
        actionExec()
        handleClose(event)
    }

    const handleMenuItemClick = useCallback(
        (event: React.MouseEvent<HTMLLIElement, MouseEvent>, item: Actions<T>) => {
            return dataType
                ? handleDataAction(event, item?.action as () => void, dataType)
                : handleNoDataAction(event, item?.action as () => void)
        },
        [dataType]
    )

    const button = renderButton(handleClick, disabled, labelButton, buttonType, icon, isActionIcon)

    if (!actions.length) return null

    return (
        <>
            {disabled ? (
                <Tooltip title={formatMessage(permissionMessages.errorNoAccessAdministrator)}>
                    <div>{button}</div>
                </Tooltip>
            ) : (
                button
            )}
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className="menu-actions"
                style={{
                    marginTop: "4px",
                }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                {actions.map((item, key) =>
                    item.render ? (
                        <MenuItem key={key}>{item.render}</MenuItem>
                    ) : (
                        <MenuItem key={key} onClick={(event) => handleMenuItemClick(event, item)}>
                            {item.icon}
                            <span className={labelButton ? "menu-items-primary" : "menu-items-grey"}>{item.label}</span>
                        </MenuItem>
                    )
                )}
            </Menu>
        </>
    )
}
