import { useCallback, useEffect, useMemo } from "react"
import { InvoiceWorkflowCheckWithUsers, ReviewerType } from "~/features/workflow/types/ApiWorkflow"
import { useGetAllUsersQuery } from "~/store/users/hooks"
import { OrganizationId, OrganizationTeamI, TeamMemberI, UserId } from "~/types"
import { isDefined } from "~/utils/isDefined"
import { useFetchInvoiceWorkflows } from "./useFetchInvoiceWorkflows"
import { useFetchOrganizationTeams } from "~/store/organization/hooks"

type ReviewersResult =
    | {
          loading: true
          error: undefined
          workflowCheck: undefined
      }
    | {
          loading: false
          error: string
          workflowCheck: undefined
      }
    | {
          loading: false
          error: undefined
          workflowCheck: InvoiceWorkflowCheckWithUsers
      }

const loadingState: ReviewersResult = {
    loading: true,
    error: undefined,
    workflowCheck: undefined,
}

const getTeamMembers = (team: OrganizationTeamI): TeamMemberI[] => team.members
const getTeamMemberUserId = (member: TeamMemberI): UserId => member.userId

export const useInvoiceWorkflowReviewers = (
    organizationId: OrganizationId | null | undefined,
    invoiceId: string | undefined
): ReviewersResult => {
    const { invoiceChecks, fetchInvoiceChecks, loading, error } = useFetchInvoiceWorkflows(organizationId, invoiceId)

    const { teams } = useFetchOrganizationTeams(organizationId ?? "", true)

    const teamReviewersIds = useMemo(
        () =>
            invoiceChecks
                ? invoiceChecks.checkStatus
                      .flatMap((check) => check.reviewers)
                      .filter((reviewer) => reviewer.type === "TEAM")
                      .map((reviewer) => reviewer.id)
                : [],
        [invoiceChecks]
    )

    const reviewersTeams = useMemo(
        () =>
            teams
                .filter((team) => teamReviewersIds.includes(team.teamId))
                .flatMap(getTeamMembers)
                .map(getTeamMemberUserId),
        [teams, teamReviewersIds]
    )

    const userReviewersIds = useMemo(() => {
        if (invoiceChecks) {
            return [
                ...reviewersTeams,
                ...invoiceChecks.checkStatus
                    .flatMap((check) => check.reviewers)
                    .filter((reviewer) => reviewer.type === "USER")
                    .map((reviewer): UserId => reviewer.id),
            ]
        }
        return []
    }, [invoiceChecks, reviewersTeams])

    const { users } = useGetAllUsersQuery(userReviewersIds)

    const getUserForId = useCallback((userId: string) => users.find((user) => user.id === userId), [users])

    useEffect(() => {
        if (invoiceId) {
            fetchInvoiceChecks()
        }
    }, [fetchInvoiceChecks, invoiceId])

    return useMemo(() => {
        if (loading || !invoiceChecks) {
            return loadingState
        }
        if (error) {
            return {
                loading: false,
                error,
                workflowCheck: undefined,
            }
        }
        const workflowCheckWithUsers: InvoiceWorkflowCheckWithUsers = {
            checkStatus: invoiceChecks.checkStatus.map((checkStatus) => ({
                ...checkStatus,
                reviewers: users.map((user) => ({
                    type: ReviewerType.USER,
                    value: user,
                })),
            })),
            approvers: invoiceChecks.approvers.map(getUserForId).filter(isDefined),
            refusers: invoiceChecks.refusers.map(getUserForId).filter(isDefined),
        }
        return {
            loading: false,
            error: undefined,
            workflowCheck: workflowCheckWithUsers,
        }
    }, [invoiceChecks, users, getUserForId, loading, error])
}
