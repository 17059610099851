import { Grid } from "@mui/material"
import { ReactNode } from "react"

interface CenterViewportProps {
    children: ReactNode
}

export const CenterViewport = ({ children }: CenterViewportProps) => {
    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: "100vh" }}
        >
            <Grid item>{children}</Grid>
        </Grid>
    )
}
