import { FC, useCallback } from "react"
import { useDispatch } from "react-redux"

import { Extract } from "~/features/supplier/components"
import { selectUser } from "~/store/account/accountSlice"
import { useAppSelector } from "~/store/hooks"
import { useFetchInvoice } from "~/store/invoice/hooks"
import { usePatchDraftInvoice } from "~/store/invoice/hooks/usePatchDraftInvoice"
import { invoiceActions } from "~/store/invoice/invoiceSlice"
import { InvoiceI, InvoiceUserType, ViewTypeI } from "~/types"
import { DocumentWithReference } from "~/types"

interface InvoiceGeneralInfoEditProps {
    invoiceId: string
}

export const InvoiceGeneralInfoEdit: FC<InvoiceGeneralInfoEditProps> = ({ invoiceId }) => {
    const dispatch = useDispatch()
    const user = useAppSelector(selectUser)
    const { invoice } = useFetchInvoice(invoiceId, ViewTypeI.buyer)
    const saveAsDraftAction = usePatchDraftInvoice(invoice, InvoiceUserType.BUYER, user)

    const handleUpdateInvoice = useCallback(
        (data: Partial<InvoiceI | DocumentWithReference>) => {
            dispatch(invoiceActions.updatePartialInvoice(data))
            saveAsDraftAction(false, data)
        },
        [dispatch, saveAsDraftAction]
    )

    return !invoice ? null : (
        <Extract invoiceId={invoice.id} dataLoaded={true} ocrData={invoice} updateData={handleUpdateInvoice} />
    )
}
