import { Typography } from "@mui/material"
import React from "react"
import { defineMessages, useIntl } from "react-intl"
import { Button, Modal } from "~/components"
import { InvoiceLineI } from "~/types"
import { VatRateI } from "~/types/VatRate"
import { getVatRateLabel } from "../SelectVatRate"

export type ModalSuggestVatRateForLines =
    | {
          open: false
          vatRate?: VatRateI | null
          lines?: InvoiceLineI[] | null
          simplyFill?: boolean
      }
    | {
          open: true
          vatRate: VatRateI
          lines: InvoiceLineI[]
          simplyFill?: boolean
      }

type Props = {
    onClose: () => void
    onConfirm: () => void
} & ModalSuggestVatRateForLines

const messages = defineMessages({
    modalTitle: {
        id: "invoice.invoiceLines.modalSuggestVatRate.title",
        defaultMessage: "Apply this VAT rate to other lines?",
    },
    modalContentMessage: {
        id: "invoice.invoiceLines.modalSuggestVatRate.modalContentMessage",
        defaultMessage:
            "The tax rate {vatRateLabel} seems to correspond to {numberOfLines, plural, =0 {no other lines} one {another line} other {# other lines}}. Do you want to apply this rate to all these lines?",
    },
    simplyFillMessage: {
        id: "invoice.invoiceLines.modalSuggestVatRate.simplyFillMessage",
        defaultMessage: "Do you want to apply this rate to all lines without any tax rate already selected?",
    },
    confirmChangeAllLines: {
        id: "invoice.invoiceLines.modalSuggestVatRate.confirmChangeAllLines",
        defaultMessage: "Apply to all lines",
    },
    keepCurrentSettings: {
        id: "invoice.invoiceLines.modalSuggestVatRate.keepCurrentSettings",
        defaultMessage: "Keep Current Settings",
    },
})

export const ModalSuggestVatRateForLines: React.FC<Props> = ({
    open,
    onClose,
    onConfirm,
    vatRate,
    lines,
    simplyFill = false,
}) => {
    const { formatMessage, locale } = useIntl()
    return (
        <Modal open={open} onClose={onClose}>
            <Modal.Header>
                <Typography variant="h2" gutterBottom>
                    {formatMessage(messages.modalTitle)}
                </Typography>
            </Modal.Header>
            <Modal.Content>
                {vatRate && lines && (
                    <Typography>
                        {simplyFill
                            ? formatMessage(messages.simplyFillMessage)
                            : formatMessage(messages.modalContentMessage, {
                                  vatRateLabel: <b>{getVatRateLabel(vatRate, locale)}</b>,
                                  numberOfLines: lines.length,
                              })}
                    </Typography>
                )}
            </Modal.Content>
            <Modal.Footer className="vr-footer">
                <Button buttonType="button" onClick={onClose} rightIcon="close" type="neutral">
                    {formatMessage(messages.keepCurrentSettings)}
                </Button>
                <Button buttonType="submit" onClick={onConfirm}>
                    {formatMessage(messages.confirmChangeAllLines)}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
