import { Box, Grid, IconButton } from "@mui/material"
import React, { Fragment, useEffect, useState } from "react"
import { Trash2 } from "react-feather"
import { defineMessages, useIntl } from "react-intl"

import { ItemLabel } from "~/components/ItemLabel/ItemLabel"
import { ProgressBar } from "~/components/ProgressBar/ProgressBar"
import { SubscriptionType } from "~/domains/transactions/common/subscriptions"
import {
    useDeleteInvoiceRelation,
    useInvoiceRelationsDifferences,
    useSubscribeToRelation,
} from "~/domains/transactions/document-relations/hooks"
import { InvoiceRelationI } from "~/domains/transactions/document-relations/types/InvoiceRelation"
import { purchaseOrdersApi } from "~/domains/transactions/purchase-orders/api"
import { PurchaseOrders } from "~/domains/transactions/purchase-orders/types/PurchaseOrders"
import { OrganizationId } from "~/types"

import { PurchaseOrderLink } from "./PurchaseOrderLink"

const messages = defineMessages({
    filled: {
        id: "common.kpis.filled",
        defaultMessage: "Filled",
    },
    filledAndRemaining: {
        id: "common.kpis.filledAndRemaining",
        defaultMessage: "Filled: {fillRate}% - Remaining: {remaining}",
    },
})

interface Props {
    organizationId: OrganizationId
    invoiceId: string
    invoiceRelation: InvoiceRelationI
}

export const InvoiceRelation: React.FC<Props> = ({ organizationId, invoiceId, invoiceRelation }) => {
    const { formatNumber, formatMessage } = useIntl()
    const deleteDocumentRelation = useDeleteInvoiceRelation(organizationId, invoiceRelation.relationId)
    const { invoiceRelationsDifferences } = useInvoiceRelationsDifferences(organizationId, invoiceId)
    useSubscribeToRelation(invoiceRelation.relationId, SubscriptionType.INVOICE)

    const [purchaseOrder, setPurchaseOrder] = useState<PurchaseOrders | null>(null)

    useEffect(() => {
        ;(async () => {
            setPurchaseOrder(null)
            const PO = await purchaseOrdersApi.findById(organizationId, invoiceRelation.purchaseOrderId)
            setPurchaseOrder(PO)
        })()
    }, [organizationId, invoiceRelation.purchaseOrderId])

    const differences = invoiceRelationsDifferences?.relations.find(
        ({ relationId }) => relationId === invoiceRelation.relationId
    )

    const hasKPIs = Object.keys(differences?.invoiceKpis?.totalAmount || {}).length > 0

    return (
        <>
            <Grid container alignItems="center" justifyContent="space-between" gap={1}>
                <PurchaseOrderLink invoiceRelation={invoiceRelation} purchaseOrder={purchaseOrder} />
                <div>
                    <IconButton onClick={deleteDocumentRelation}>
                        <Trash2 color="var(--color-light-grey)" size={16} />
                    </IconButton>
                </div>
            </Grid>
            {hasKPIs && (
                <Box>
                    {Object.entries(differences?.invoiceKpis?.totalAmount || {}).map(
                        ([currency, { fillRate, remaining }]) => (
                            <Fragment key={currency}>
                                <ProgressBar
                                    values={[
                                        {
                                            value: fillRate?.toString(),
                                            percentage: +fillRate / 100,
                                            label: formatMessage(messages.filled),
                                            color: "var(--color-lighter-primary)",
                                        },
                                    ]}
                                />
                                <ItemLabel>
                                    {`${formatMessage(messages.filledAndRemaining, {
                                        fillRate: fillRate || 0,
                                        remaining:
                                            formatNumber(+remaining, {
                                                style: "currency",
                                                currency,
                                            }) ?? "-",
                                    })}`}
                                </ItemLabel>
                            </Fragment>
                        )
                    )}
                </Box>
            )}
        </>
    )
}
