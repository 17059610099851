import { InvoiceI, ImportInvoiceCompanyInfoI, OrganizationOptions } from "~/types"

export const setInvoiceOrganizations = (invoice: InvoiceI, options: OrganizationOptions): InvoiceI => {
    const buyer: ImportInvoiceCompanyInfoI = {
        ...invoice.buyer,
    }
    if (options.buyerOrganization) {
        buyer.name = options.buyerOrganization.name
        buyer.dunsNumber = options.buyerOrganization.registration.dunsNumber
        buyer.countryCode = options.buyerOrganization.registration.countryCode
        buyer.taxId = options.buyerOrganization.registration.vatNumber
        buyer.registrationNumber =
            options.buyerOrganization.registration.preferredRegistrationNumber?.registrationNumber
    }
    const supplier: ImportInvoiceCompanyInfoI = {
        ...invoice.supplier,
    }
    if (options.supplierOrganization) {
        supplier.name = options.supplierOrganization.name
        supplier.dunsNumber = options.supplierOrganization.registration.dunsNumber
        supplier.countryCode = options.supplierOrganization.registration.countryCode
        supplier.taxId = options.supplierOrganization.registration.vatNumber
        supplier.registrationNumber =
            options.supplierOrganization.registration.preferredRegistrationNumber?.registrationNumber
    }
    return {
        ...invoice,
        buyer,
        supplier,
    }
}
