import { Box, Grid, Stack } from "@mui/material"
import { FC } from "react"
import { useIntl } from "react-intl"

import { ProgressBar } from "~/components"
import { ItemLabel } from "~/components/ItemLabel/ItemLabel"
import { ProgressBarLegend } from "~/components/ProgressBar/ProgressBarLegend"
import { useLineItemsByStatusComparedToBudget } from "~/domains/transactions/invoices-v1/hooks/useLineItemsByStatusComparedToBudget"
import { InvoiceI } from "~/types"

interface InvoiceBudgetGaugeProps {
    invoice: InvoiceI
}

export const InvoiceBudgetGauge: FC<InvoiceBudgetGaugeProps> = ({ invoice }) => {
    const { formatNumber } = useIntl()
    const { lineItemsByStatusAndCurrencyValues, lineItemsByStatusAndCurrencyValuesWithoutRejected } =
        useLineItemsByStatusComparedToBudget(invoice)

    if (!invoice?.budgets?.length) return null

    const totalAmountsFormatted = Object.entries(invoice?.totalAmountDue || {})?.reduce(
        (acc, [currency, amount]) => ({
            ...acc,
            [currency as string]: formatNumber(+amount || 0, {
                style: "currency",
                currency,
            }),
        }),
        {}
    )

    const budgetAmountsFormatted = invoice?.budgets?.reduce(
        (acc, budget) => ({
            ...acc,
            [budget?.budget?.currency]: formatNumber(budget?.budget?.currentAmount ?? 0, {
                style: "currency",
                currency: budget?.budget?.currency ?? "",
            }),
        }),
        {}
    )

    return (
        <Stack gap={2} className="mt-12">
            {Object.entries(lineItemsByStatusAndCurrencyValues).map(([currency, currencyValues]) => (
                <>
                    <hr className="m-0" />
                    {!budgetAmountsFormatted[currency] ? (
                        <ItemLabel>No budget for currency: {currency}</ItemLabel>
                    ) : (
                        <Stack key={currency} gap={1}>
                            <Grid container justifyContent="space-between" gap={3}>
                                <Box>
                                    <ItemLabel>Budget</ItemLabel>
                                    <h4>{totalAmountsFormatted[currency] || "0"}</h4>
                                </Box>
                                <Box textAlign="right">
                                    <ItemLabel>Budget total</ItemLabel>
                                    <h4>{budgetAmountsFormatted[currency] || "0"}</h4>
                                </Box>
                            </Grid>
                            <ProgressBar values={lineItemsByStatusAndCurrencyValuesWithoutRejected} />
                            <ProgressBarLegend values={currencyValues} />
                        </Stack>
                    )}
                </>
            ))}
        </Stack>
    )
}
