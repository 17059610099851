import { RootState } from "~/store"
import { getJWT } from "~/utils"

const prepareHeadersWithAuthorization = async (headers: Headers) => {
    const jwt = await getJWT()

    if (jwt) {
        headers.set("Authorization", `Bearer ${jwt}`)
    }

    return headers
}

const prepareHeadersWithAuthorizationAndOrganizationId = async (headers: Headers, { getState }) => {
    const organizationId = (getState() as RootState).organization.currentOrganizationId

    await prepareHeadersWithAuthorization(headers)

    if (organizationId) {
        headers.set("X-Flowie-Organization-Id", `${organizationId}`)
    }

    return headers
}

export { prepareHeadersWithAuthorizationAndOrganizationId, prepareHeadersWithAuthorization }
