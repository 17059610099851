import { Grid, IconButton } from "@mui/material"
import React, { Fragment, useEffect, useState } from "react"
import { Box, Trash2 } from "react-feather"
import { defineMessages, useIntl } from "react-intl"

import { invoiceApi } from "~/api"
import { ProgressBar } from "~/components"
import { ItemLabel } from "~/components/ItemLabel/ItemLabel"
import { SubscriptionType } from "~/domains/transactions/common/subscriptions"
import {
    useDeletePurchaseOrderRelation,
    useInvoiceRelationsDifferences,
    useSubscribeToRelation,
} from "~/domains/transactions/document-relations/hooks"
import { PurchaseOrderRelationI } from "~/domains/transactions/document-relations/types"
import { InvoiceI, OrganizationId, ViewTypeI } from "~/types"

import { InvoiceLink } from "./InvoiceLink"

const messages = defineMessages({
    filled: {
        id: "common.kpis.filled",
        defaultMessage: "Filled",
    },
    filledAndRemaining: {
        id: "common.kpis.filledAndRemaining",
        defaultMessage: "Filled: {fillRate}% - Remaining: {remaining}",
    },
})

interface Props {
    purchaseOrderRelation: PurchaseOrderRelationI
    organizationId: OrganizationId
    purchaseOrderId: string
    viewType: ViewTypeI
}

export const PurchaseOrderRelation: React.FC<Props> = ({
    organizationId,
    purchaseOrderRelation,
    purchaseOrderId,
    viewType,
}) => {
    const { formatNumber, formatMessage } = useIntl()
    const deleteRelation = useDeletePurchaseOrderRelation(organizationId, purchaseOrderRelation.relationId)
    const { invoiceRelationsDifferences } = useInvoiceRelationsDifferences(organizationId, purchaseOrderId)
    useSubscribeToRelation(purchaseOrderRelation.relationId, SubscriptionType.PURCHASE_ORDER)
    const [invoice, setInvoice] = useState<InvoiceI | null>(null)

    useEffect(() => {
        invoiceApi.getById(purchaseOrderRelation.invoiceId).then(setInvoice)
    }, [purchaseOrderRelation.invoiceId])

    const differences = invoiceRelationsDifferences?.relations.find(
        ({ relationId }) => relationId === purchaseOrderRelation.relationId
    )

    const hasKPIs = Object.keys(differences?.invoiceKpis?.totalAmount || {}).length > 0

    return (
        <>
            <Grid container alignItems="center" justifyContent="space-between">
                <InvoiceLink purchaseOrderRelation={purchaseOrderRelation} invoice={invoice} viewType={viewType} />
                <IconButton onClick={deleteRelation}>
                    <Trash2 color="#909399" size={16} />
                </IconButton>
            </Grid>
            {hasKPIs && (
                <Box>
                    {Object.entries(differences?.invoiceKpis?.totalAmount || {}).map(
                        ([currency, { fillRate, remaining }]) => (
                            <Fragment key={currency}>
                                <ProgressBar
                                    values={[
                                        {
                                            value: fillRate?.toString(),
                                            percentage: +fillRate / 100,
                                            label: formatMessage(messages.filled),
                                            color: "var(--color-lighter-primary)",
                                        },
                                    ]}
                                />
                                <ItemLabel>
                                    {`${formatMessage(messages.filledAndRemaining, {
                                        fillRate: fillRate || 0,
                                        remaining:
                                            formatNumber(+remaining, {
                                                style: "currency",
                                                currency,
                                            }) ?? "-",
                                    })}`}
                                </ItemLabel>
                            </Fragment>
                        )
                    )}
                </Box>
            )}
        </>
    )
}
