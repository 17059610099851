import { useEffect, useState } from "react"

import { organizationApi } from "~/api"
import { DocumentObjectType } from "~/domains/identity/features/documents/types"
import { Organizations } from "~/domains/transactions/components/Organizations/Organizations"
import { useAppSelector } from "~/store/hooks"
import { selectOrganizationById } from "~/store/organization/organizationSlice"
import { InvoiceI } from "~/types"
import { OrganizationI, ViewTypeI } from "~/types"

interface InvoiceOrganizationsProps {
    invoice: InvoiceI
}

export const InvoiceOrganizations = ({ invoice }: InvoiceOrganizationsProps) => {
    const payerOrganization = useAppSelector((state) => selectOrganizationById(state, invoice.payerId))
    const sellerOrganizationFromStore = useAppSelector((state) => selectOrganizationById(state, invoice.sellerId))
    const [sellerOrganization, setSellerOrganization] = useState<OrganizationI>(sellerOrganizationFromStore)

    useEffect(() => {
        (async () => {
            if (sellerOrganization) return

            try {
                const organization = await organizationApi.getOrganizationById(invoice.sellerId)
                setSellerOrganization(organization)
            } catch (error) {
                console.error(error)
            }
        })()
    }, [sellerOrganization?.id])

    const buyerOrganization = {
        ...payerOrganization,
        type: ViewTypeI.buyer,
    }

    const supplierOrganization = {
        ...sellerOrganization,
        type: ViewTypeI.supplier,
    }

    return (
        <Organizations
            to={supplierOrganization}
            from={buyerOrganization}
            readOnly
            objectType={DocumentObjectType.INVOICE}
        />
    )
}
