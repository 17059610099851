import { isLeft } from "fp-ts/lib/Either"
import { Decoder, Validation } from "io-ts"

import { Result, ResultError, ResultSuccess } from "~/core/Result"

const getValidationErrorsKey = <A>(v: Validation<A>): Array<string> | undefined => {
    if (isLeft(v)) {
        return v.left.map((error) => error.context.map(({ key }) => key).join("."))
    }
    return []
}

export interface ParsingErrorType extends Record<string, unknown> {
    data: unknown
    errorsKey: string[] | undefined
}

export function genericParser<T>(data: unknown, decoder: Decoder<unknown, T>): Result<T, ParsingErrorType> {
    const result = decoder.decode(data)
    if (isLeft(result)) {
        const errorsKey = getValidationErrorsKey(result)
        console.error(`Unable to parse ${decoder.name} data`, {
            data,
            errorsKey,
            parsingError: result.left,
        })
        return ResultError({
            data,
            errorsKey,
        })
    }
    return ResultSuccess(result.right)
}
