import { useCallback, useEffect, useMemo, useState } from "react"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { selectUsers, usersActions } from "~/store/users/usersSlice"
import { OrganizationI, OrganizationMemberI, OrganizationMemberUserI } from "~/types"
import { fetchOrganization } from "./useFetchOrganization"
import { selectOrganizationsFilters } from "../organizationSlice"
import { WHITE_SPACE_REGEXP } from "~/utils/string"

const noFilter = () => true

const getMembersFilter = (filter: string) => {
    if (!filter) {
        return noFilter
    }

    const filterWords = filter.toLocaleLowerCase().split(WHITE_SPACE_REGEXP)
    return (member: OrganizationMemberUserI) => {
        const memberWords = `${member.user?.fullName}`.toLocaleLowerCase().split(WHITE_SPACE_REGEXP)
        return filterWords.every((word) => memberWords.some((memberWord) => memberWord.indexOf(word) >= 0))
    }
}

export const useFetchOrganizationMembers = (
    organization?: OrganizationI
): [
    OrganizationMemberUserI[],
    (shouldFetchOrganization?: boolean) => Promise<OrganizationMemberI[]>,
    OrganizationMemberUserI[]
] => {
    const [members, setMembers] = useState<OrganizationMemberI[]>([])
    const [pendingMembers, setPendingMembers] = useState<OrganizationMemberI[]>([])
    const users = useAppSelector(selectUsers)
    const dispatch = useAppDispatch()
    const filters = useAppSelector(selectOrganizationsFilters)

    const userMembers = useMemo<OrganizationMemberUserI[]>(
        () =>
            members
                .map((member) => ({
                    ...member,
                    user: users[member.userId],
                }))
                .filter((member) => !!member.user)
                .filter(getMembersFilter(filters.members)),
        [members, users, filters]
    )

    const userPendingMembers = useMemo<OrganizationMemberUserI[]>(
        () =>
            pendingMembers
                .map((member) => ({
                    ...member,
                    user: users[member.userId],
                }))
                .filter((member) => !!member.user),
        [members, users]
    )

    const fetchMembers = useCallback(
        async (shouldFetchOrganization = false) => {
            if (!organization) {
                return []
            }
            let org = organization
            if (shouldFetchOrganization) {
                org = await fetchOrganization(organization.id, dispatch)
            }
            const organizationMembers = [...org.members, ...org.invitations]
            const membersIds = [...organizationMembers, ...org.membershipRequests].map((member) => member.userId)
            dispatch(usersActions.fetchUsers(membersIds))
            setMembers(organizationMembers)
            setPendingMembers(org.membershipRequests)
            return organizationMembers
        },
        [organization, dispatch]
    )

    useEffect(() => {
        fetchMembers()
    }, [fetchMembers])

    return [userMembers, fetchMembers, userPendingMembers]
}
