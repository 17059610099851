import { HandleProps, Position } from "@xyflow/react"
import type { NodeProps, Node as NodeType } from "@xyflow/react"
import React, { FC } from "react"
import { CreditCard } from "react-feather"
import { FormattedMessage, useIntl } from "react-intl"

import { messages } from "~/domains/orchestration/flows/messages"
import { FitsToBudgetNode as FitsToBudgetNodeType } from "~/domains/orchestration/flows/types"

import { Node } from "./Node"

export const FitsToBudgetNode: FC<NodeProps<NodeType<FitsToBudgetNodeType>>> = ({ data, selected }) => {
    const { formatMessage } = useIntl()

    const handles: HandleProps[] = [
        {
            type: "source",
            isConnectableStart: true,
            id: `${data.slug}-success`,
            position: Position.Left,
            className: "flows-node-handle-left",
            children: <FormattedMessage tagName="span" {...messages.fitsToBudgetNode.fitsLabel} />,
        },
        {
            type: "source",
            isConnectableStart: true,
            id: `${data.slug}-failure`,
            position: Position.Right,
            className: "flows-node-handle-right",
            children: <FormattedMessage tagName="span" {...messages.fitsToBudgetNode.doesNotFitLabel} />,
        },
        {
            type: "target",
            position: Position.Top,
        },
    ]

    const error =
        !data.budgetId?.trim() ||
        !data.transactionId?.trim() ||
        !data.transactionType?.trim() ||
        !data.amount?.trim() ||
        !data.currency?.trim()

    return (
        <Node
            title={formatMessage(messages.fitsToBudgetNode.nodeTitle)}
            name={data.name}
            headerInformation={`${data.amount} ${data.currency}`}
            icon={CreditCard}
            handles={handles}
            selected={selected}
            error={error}
            slug={data.slug}
        />
    )
}
