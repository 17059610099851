import { defineMessages, useIntl } from "react-intl"

import { AmountInList } from "~/domains/transactions/components/AmountInList"
import { CurrencyCodes } from "~/types"

import "./TotalAmount.scss"

interface TotalAmountProps {
    amount: number
    currency: CurrencyCodes
    taxLabel?: string
}

const messages = defineMessages({
    taxLabel: { id: "common.taxLabel", defaultMessage: "Tax excl." },
})

export function TotalAmount({ amount, currency, taxLabel }: TotalAmountProps) {
    const { formatMessage } = useIntl()

    return (
        <div className="total-amount">
            <AmountInList amount={amount} currency={currency} />
            <small>({taxLabel || formatMessage(messages.taxLabel)})</small>
        </div>
    )
}
