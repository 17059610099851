import { MandateStatus } from "~/domains/payment/payment-method-details/types"
import { PaymentMethodType } from "~/domains/payment/payment-methods/types"
import { CountryCode, CurrencyCodes } from "~/types"

export enum PaymentMethodDetailsStatus {
    ACTIVE = "active",
    INACTIVE = "inactive",
    SUSPENDED = "suspended",
    EXPIRED = "expired",
    PENDING = "pending",
    PENDING_VERIFICATION = "pending_verification",
    VERIFICATION_FAILED = "verification_failed",
    VALID = "valid",
    INVALID = "invalid",
    DELETED = "deleted",
    UNKNOWN = "unknown",
}

export enum PaymentMethodDetailsLink {
    PARTNER = "partner",
    TRANSACTION = "transaction",
}

export interface PaymentMethodDetailsBase {
    id: string
    owner: string
    payment_method_id: string | null
    payment_method_type?: PaymentMethodType
    country: CountryCode | null
    currency: CurrencyCodes | null
    status: PaymentMethodDetailsStatus
    kid?: string
}

export enum CardBrand {
    VISA = "Visa",
    MASTERCARD = "Mastercard",
    AMEX = "Amex",
    DINERS = "Diners",
    DISCOVER = "Discover",
    JCB = "Jcb",
    UNIONPAY = "Unionpay",
    MAESTRO = "Maestro",
    UNKNOWN = "Unknown",
}

export enum CardCategory {
    CREDIT = "credit",
    DEBIT = "debit",
    PREPAID = "prepaid",
}

export const CardBrands = {
    [CardBrand.UNKNOWN]: "Unknown",
    [CardBrand.VISA]: "Visa",
    [CardBrand.MASTERCARD]: "Mastercard",
    [CardBrand.AMEX]: "American Express",
    [CardBrand.DINERS]: "Diners Club",
    [CardBrand.DISCOVER]: "Discover",
    [CardBrand.JCB]: "JCB",
    [CardBrand.UNIONPAY]: "UnionPay",
    [CardBrand.MAESTRO]: "Maestro",
}

export interface CardPaymentMethod extends PaymentMethodDetailsBase {
    payment_method_type: PaymentMethodType.CARD
    card_brand?: CardBrand | null
    card_category: CardCategory | null
    card_expiration_month: number
    card_expiration_year: number
    card_first_6_digits: string
    card_last_4_digits: string
    card_nickname: string
    card_token?: string
}

export interface BankTransferPaymentMethod extends PaymentMethodDetailsBase {
    payment_method_type: PaymentMethodType.BANK_TRANSFER
    bank_account_number: string
    bank_name: string
    iban: string
    bic_swift: string
    holder_name: string
}

export interface DirectDebitPaymentMethod extends PaymentMethodDetailsBase {
    payment_method_type: PaymentMethodType.DIRECT_DEBIT
    mandate_reference: string
    mandate_date: string
    mandate_status: MandateStatus
    bank_account_number: string
    bank_name: string
    holder_name: string
}

export type PaymentMethodDetails = CardPaymentMethod | BankTransferPaymentMethod | DirectDebitPaymentMethod

export type CreatePaymentMethodDetails = Omit<PaymentMethodDetails, "id"> & { id?: string }
