import * as t from "io-ts"

export enum PennylaneSyncOutcome {
    SUCCESS = "SUCCESS",
    FAILURE = "FAILURE",
}
export const InvoicePennylaneSynchronizationStatusIO = t.type({
    pennylaneSyncOutcome: t.union([t.string, t.null]),
    pennylaneSyncOutcomeReason: t.union([t.string, t.null]),
    pennylaneLastSyncDate: t.string,
})
export type InvoicePennylaneSynchronizationStatusI = t.TypeOf<typeof InvoicePennylaneSynchronizationStatusIO> & {
    pennylaneSyncOutcome: PennylaneSyncOutcome | string | null
}

const InvoicePennylaneSynchronizationStatusExtendedIO = t.intersection([
    InvoicePennylaneSynchronizationStatusIO,
    t.type({
        invoiceId: t.string,
    }),
])
export type InvoicePennylaneSynchronizationStatusExtendedI = t.TypeOf<
    typeof InvoicePennylaneSynchronizationStatusExtendedIO
>
export const InvoicePennylaneSynchronizationStatusListIO = t.array(InvoicePennylaneSynchronizationStatusExtendedIO)
export type InvoicePennylaneSynchronizationStatusListI = t.TypeOf<typeof InvoicePennylaneSynchronizationStatusListIO>
