import { TRANSACTION_TYPES } from "~/domains/orchestration/flows/const"
import {
    ApiEventTriggerNode,
    ApiFlowNode,
    ApiFlowUpdate,
    ApiNode,
    ApiNodeType,
    ApiObjectEvent,
    ApiObjectType,
    ApiReviewer,
    ApiTeamReviewer,
    ApiUserReviewer,
    ApiUserType,
    Flow,
    FlowNode,
    NodeType,
    Reviewer,
    TransactionType,
    isApiFlowNode,
} from "~/domains/orchestration/flows/types"

const adaptReviewerToApi = (reviewer: Reviewer): ApiReviewer => {
    if ("userId" in reviewer) {
        return {
            userId: reviewer.userId,
            type: ApiUserType.USER,
        } as ApiUserReviewer
    }
    return {
        teamId: reviewer.teamId,
        type: ApiUserType.TEAM,
    } as ApiTeamReviewer
}

const adaptCommonNodeProperties = (node: FlowNode): ApiNode => {
    return {
        slug: node.slug,
        name: node.name.trim() ?? null,
        metadata: node.metadata,
    }
}

const adaptTransactionTypeToApi = (value: TransactionType | null) => {
    if (!value) return ""
    return adaptExpressionToApi(TRANSACTION_TYPES.find((type) => type === value) ?? "")
}

const adaptExpressionToApi = (value: string) => `'${value}'`

const adaptNodeToApi = (node: FlowNode): ApiFlowNode | null => {
    switch (node.type) {
        case NodeType.EVENT_TRIGGER_NODE: {
            const nodeToAdapt: ApiEventTriggerNode = {
                ...adaptCommonNodeProperties(node),
                objectType: node.objectType as unknown as ApiObjectType,
                objectEvent: node.objectEvent as unknown as ApiObjectEvent,
                type: node.type as unknown as ApiNodeType.EVENT_TRIGGER_NODE,
                nextNode: node.nextNode || undefined,
            }
            if (node.filter.conditions.length > 0 && node.filter.conditions[0].length > 0) {
                nodeToAdapt.filter = node.filter
            }

            return nodeToAdapt
        }

        case NodeType.IF_NODE:
            return {
                ...adaptCommonNodeProperties(node),
                type: node.type as unknown as ApiNodeType.IF_NODE,
                condition: node.condition,
                nextIfTrue: node.nextIfSuccess || undefined,
                nextIfFalse: node.nextIfFailure || undefined,
            }
        case NodeType.SET_PARTNERSHIP_FIELD_NODE:
            return {
                ...adaptCommonNodeProperties(node),
                type: node.type as unknown as ApiNodeType.SET_PARTNERSHIP_FIELD_NODE,
                partnershipId: node.partnershipId,
                fieldToUpdate: node.fieldToUpdate,
                valueToSet: node.valueToSet,
                nextNode: node.nextNode || undefined,
            }
        case NodeType.CHECK_NODE:
            return {
                ...adaptCommonNodeProperties(node),
                type: node.type as unknown as ApiNodeType.CHECK_NODE,
                objectId: node.objectId,
                objectType: node.objectType as unknown as ApiObjectType,
                reviewers: node.reviewers.map(adaptReviewerToApi),
                passThreshold: node.passThreshold,
                refuseThreshold: node.refuseThreshold,
                nextIfPassed: node.nextIfSuccess || undefined,
                nextIfRefused: node.nextIfFailure || undefined,
            }
        case NodeType.SEND_EMAIL_NODE:
            return {
                ...adaptCommonNodeProperties(node),
                type: node.type as unknown as ApiNodeType.SEND_EMAIL_NODE,
                subject: node.subject,
                recipientAddresses: node.recipientAddresses,
                body: node.body,
                nextNode: node.nextNode || undefined,
            }
        case NodeType.ADD_TO_BUDGET_NODE:
            return {
                ...adaptCommonNodeProperties(node),
                type: node.type as unknown as ApiNodeType.ADD_TO_BUDGET_NODE,
                amount: node.amount,
                budgetId: adaptExpressionToApi(node.budgetId),
                transactionId: node.transactionId,
                transactionType: adaptTransactionTypeToApi(node.transactionType),
                failIfOverbudget: node.failIfOverbudget,
                currency: node.currency,
                nextNode: node.nextNode || undefined,
            }
        case NodeType.INVOICE_TO_PURCHASE_ORDER_MATCH_NODE:
            return {
                ...adaptCommonNodeProperties(node),
                type: node.type as unknown as ApiNodeType.INVOICE_TO_PURCHASE_ORDER_MATCH_NODE,
                invoiceId: node.invoiceId,
                nextIfMatched: node.nextIfSuccess || undefined,
                nextIfUnmatched: node.nextIfFailure || undefined,
                metadata: node.metadata,
            }
        case NodeType.SET_INVOICE_LIFECYCLE_STATUS_NODE:
            return {
                ...adaptCommonNodeProperties(node),
                type: node.type as unknown as ApiNodeType.SET_INVOICE_LIFECYCLE_STATUS_NODE,
                invoiceId: node.invoiceId,
                statusToSet: node.statusToSet,
                nextNode: node.nextNode || undefined,
            }
        case NodeType.FITS_TO_BUDGET_NODE:
            return {
                ...adaptCommonNodeProperties(node),
                type: node.type as unknown as ApiNodeType.FITS_TO_BUDGET_NODE,
                budgetId: node.budgetId,
                transactionId: node.transactionId,
                transactionType: node.transactionType,
                amount: node.amount,
                currency: node.currency,
                nextIfFits: node.nextIfSuccess || undefined,
                nextIfDoesNotFit: node.nextIfFailure || undefined,
            }
        default:
            return null
    }
}

export const adaptFlowToApi = (flow: Flow): ApiFlowUpdate => {
    return {
        version: flow.version,
        name: flow.name,
        enabled: flow.enabled,
        archived: flow.archived,
        createdAt: flow.createdAt,
        nodes: flow.nodes.map(adaptNodeToApi).filter(isApiFlowNode),
    }
}
