import { useContext } from "react"
import * as Sentry from "@sentry/browser"
import { ApiContext } from "../common/apiClient"
import { AxiosInstance } from "axios"
import { OrganizationId } from "~/types"
import { CreateTagGroupI, CreateTagI, TagId, TagObjectI, TagObjectType } from "./types"
import { Result, getResultSuccessValue, isResultError, isResultSuccess } from "~/core/Result"
import { TagGroupI, TagGroupId } from "./types/TagGroup"
import { ParsingErrorType } from "~/utils"
import { parseTag, parseTagGroup, parseTagObject } from "./parsers"
import { CreateTagObjectI } from "./types/CreateTagObject"
import { ImportBatchResponseI } from "~/types/ImportBatch"

const BASE_URL = import.meta.env.VITE_API_TAGS_URL
const IMPORT_BASE_URL = import.meta.env.VITE_API_IMPORT_FROM_FILES_URL

class TagsApi {
    private static instance: TagsApi
    private constructor(private axiosClient: AxiosInstance) {}

    static getInstance(axiosClient: AxiosInstance) {
        if (!TagsApi.instance) {
            TagsApi.instance = new TagsApi(axiosClient)
        }
        return TagsApi.instance
    }

    private buildUrl(organizationId: OrganizationId, path: string): string {
        return `${BASE_URL}organizations/${organizationId}${path}`
    }

    async getTagGroups(organizationId: OrganizationId): Promise<TagGroupI[]> {
        try {
            const response = await this.axiosClient.get<unknown[]>(
                this.buildUrl(organizationId, `/tagGroups?includeTags=true`)
            )
            return response.data.map(parseTagGroup).filter(isResultSuccess).map(getResultSuccessValue)
        } catch (e) {
            console.error(e)
            throw e
        }
    }

    async createTagGroup(
        organizationId: OrganizationId,
        payload: CreateTagGroupI
    ): Promise<Result<TagGroupI, ParsingErrorType>> {
        const response = await this.axiosClient.post(this.buildUrl(organizationId, `/tagGroups`), payload)
        return parseTagGroup(response.data)
    }

    async updateTagGroup(
        organizationId: OrganizationId,
        tagGroupId: TagGroupId,
        payload: Partial<CreateTagGroupI>
    ): Promise<Result<TagGroupI, ParsingErrorType>> {
        const response = await this.axiosClient.put(this.buildUrl(organizationId, `/tagGroups/${tagGroupId}`), payload)
        return parseTagGroup(response.data)
    }

    async deleteTagGroup(
        organizationId: OrganizationId,
        tagGroupId: TagGroupId,
        force: boolean = true
    ): Promise<boolean> {
        const response = await this.axiosClient.delete(
            this.buildUrl(organizationId, `/tagGroups/${tagGroupId}${force ? "?force=true" : ""}`)
        )
        return response.status >= 200 && response.status < 300
    }

    async createTag(organizationId: OrganizationId, payload: CreateTagI) {
        const response = await this.axiosClient.post(this.buildUrl(organizationId, `/tags`), payload)
        return parseTag(response.data)
    }

    async getTag(organizationId: OrganizationId, tagId: TagId) {
        const response = await this.axiosClient.get(this.buildUrl(organizationId, `/tags/${tagId}`))
        return parseTag(response.data)
    }

    async updateTag(organizationId: OrganizationId, tagId: TagId, payload: Partial<CreateTagI>) {
        const response = await this.axiosClient.put(this.buildUrl(organizationId, `/tags/${tagId}`), payload)
        return parseTag(response.data)
    }

    async deleteTag(organizationId: OrganizationId, tagId: TagId, force: boolean = true): Promise<boolean> {
        const response = await this.axiosClient.delete(
            this.buildUrl(organizationId, `/tags/${tagId}${force ? "?force=true" : ""}`)
        )
        return response.status >= 200 && response.status < 300
    }

    async createTagObject(organizationId: OrganizationId, payload: CreateTagObjectI) {
        const response = await this.axiosClient.post(this.buildUrl(organizationId, `/objects/tags`), payload)
        return parseTagObject(response.data)
    }

    async updateObjectTag(organizationId: OrganizationId, payload: CreateTagObjectI) {
        const response = await this.axiosClient.put(
            this.buildUrl(organizationId, `/objects/${payload.objectId}/tags/${payload.tagId}`),
            {
                contextId: payload.contextId,
                objectContext: payload.objectContext,
                ratio: payload.ratio,
            }
        )
        return response.status >= 200 && response.status < 300
    }

    async getTagObjects(
        organizationId: OrganizationId,
        objectId: string,
        addNames: boolean = false
    ): Promise<Result<TagObjectI, ParsingErrorType>[]> {
        const response = await this.axiosClient.get(
            this.buildUrl(organizationId, `/objects/${objectId}${addNames ? "?addNames=true" : ""}`)
        )
        if (response.status !== 200 || !Array.isArray(response.data)) {
            if (response.status !== 404) {
                Sentry.captureMessage(
                    `getTagObjects(${organizationId}, ${objectId}) returned unexpected response with status: ${
                        response.status
                    }: ${JSON.stringify(response.data)}`
                )
            }
            return []
        }
        return (
            response.data
                .map(parseTagObject)
                // TODO: to remove when API will be fixed
                .filter((result) => isResultError(result) || result.result.organizationId === organizationId)
        )
    }

    async deleteTagObject(organizationId: OrganizationId, objectId: string, tagId: TagId): Promise<boolean> {
        const response = await this.axiosClient.delete(
            this.buildUrl(organizationId, `/objects/${objectId}/tags/${tagId}`)
        )
        return response.status >= 200 && response.status < 300
    }

    async getBulkObjectsTags(organizationId: OrganizationId, objectIds: string[], addNames: boolean = false) {
        return this.axiosClient.post(this.buildUrl(organizationId, `/objects/tags/fetch-bulk`), {
            objectIds,
            addNames,
        })
    }

    async duplicateObjectTags(
        organizationId: OrganizationId,
        sourceObjectId: string,
        destinationObjectId: string,
        objectType: TagObjectType
    ): Promise<boolean> {
        const url = this.buildUrl(organizationId, `/objects/tags/duplicate`)
        const response = await this.axiosClient.post(url, { sourceObjectId, destinationObjectId, objectType })
        return response.status >= 200 && response.status < 300
    }

    async createObjectTags(organizationId: OrganizationId, objectTags: CreateTagObjectI[]): Promise<boolean | undefined>  {
        if (objectTags.length) {
            const url = this.buildUrl(organizationId, `/objects/tags/bulk`)
            const response = await this.axiosClient.post(url, objectTags)
            return response.status >= 200 && response.status < 300
        }
    }

    async importBatch(organizationId: string, attachedFile: File): Promise<Result<ImportBatchResponseI>> {
        const formData = new FormData()
        formData.append("file", attachedFile)
        formData.append("organizationId", organizationId)

        return this.axiosClient.post(`${IMPORT_BASE_URL}tags/upload-file`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
    }

    async getTagsSuggestions(organizationId: OrganizationId, objectContext: unknown) {
        return this.axiosClient.post(this.buildUrl(organizationId, `/objects/tags/auto`), {
            objectContext,
        })
    }
}

export const useTagsApi = () => {
    const { axiosClient } = useContext(ApiContext)
    return TagsApi.getInstance(axiosClient)
}
