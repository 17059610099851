import { CurrencyCodes } from "~/types"

export enum PaymentStatus {
    DRAFT = "DRAFT",
    EXECUTED = "EXECUTED",
    PENDING = "PENDING",
    CANCELED = "CANCELED",
    SCHEDULED = "SCHEDULED",
    INITIATION = "INITIATION",
    AUTHORIZED = "AUTHORIZED",
    PROCESSING = "PROCESSING",
    CLEARING = "CLEARING",
}

export enum DocumentPaymentStatus {
    PAID = "PAID",
    UNPAID = "UNPAID",
    SCHEDULED = "SCHEDULED",
}

export interface DocumentData {
    number: string
    total: PaymentAmount
}

export interface PaymentAmount {
    amount: number
    currency: CurrencyCodes | undefined
}

export interface PaymentMetadata {
    invoice_number: string
    customer_id?: string
}

export interface Payment {
    id?: string
    amount_sent_data: PaymentAmount
    amount_received_data?: PaymentAmount
    payer_id: string
    payee_id: string
    payment_method_id?: string
    origin_cash_id?: string | null
    date_validated?: string | null
    date_scheduled?: string | null
    date_created?: string | null
    value_date_expected?: string | null
    destination_cash_id?: string | null
    reference?: string
    comment?: string
    status: PaymentStatus
    executed_by_id?: string
    authorized_by_id?: string
    payment_metadata?: PaymentMetadata
}

export interface SelectedPaymentMethods {
    payerPaymentMethodId: string | null
    payeePaymentMethodId: string | null
    paymentMethodId: string | null
}
