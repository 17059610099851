import {
    OrganizationPermission,
    PermissionId,
    RoleI,
    RoleWithEntityIds,
} from "~/domains/identity/features/roles-permissions/types/RolesPermissions"
import { UserI } from "~/types"
import { SharedObjectWithName } from "~/types/SharedObjects"
import { SpiceDBPermissionsLiteI } from "~/domains/identity/features/roles-permissions/types/SpiceDB"

export interface PendingInvitation {
    email: string
    name: string
}

export interface SpiceDBStorePermissions {
    loading: boolean
    error: string | null
    data: SpiceDBPermissionsLiteI[] | null
}

export interface RolesPermissionsState {
    loading: boolean
    error: string | null
    usersLoading: boolean
    usersError: string | null
    organizationId: string | null
    users: UserI[]
    roleWithEntityIds: RoleWithEntityIds[]
    permissions: PermissionId[]
    data: RoleI | RoleWithEntityIds
    jwtPermissions: OrganizationPermission[]
    shares: SharedObjectWithName[]
    sharesLoading: boolean
    sharesError: string | null
    pendingTeams: string[]
    pendingMembers: string[]
    pendingInvitations: PendingInvitation[]
    spiceDBObjectPermissions: SpiceDBStorePermissions
}

export const rolesPermissionsState: RolesPermissionsState = {
    loading: false,
    error: null,
    usersLoading: false,
    usersError: null,
    organizationId: null,
    roleWithEntityIds: [],
    permissions: [],
    users: [],
    jwtPermissions: [],
    shares: [],
    sharesLoading: false,
    sharesError: null,
    pendingTeams: [],
    pendingMembers: [],
    pendingInvitations: [],
    spiceDBObjectPermissions: {
        loading: false,
        error: null,
        data: null,
    },
    data: {
        id: "",
        organizationId: "",
        creator: "",
        description: "",
        name: "",
        isPredefined: false,
        createdAt: "",
        permissions: [],
        userIds: [],
        teamIds: [],
    },
}
