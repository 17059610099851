/**
 * @description Suggestion component for the configuration panel, this component will display a modal with a list of suggestions
 * on click a suggestion, the suggestion will be added to the free text field.
 * It will allow the user to write conditions or fill properties more easily.
 */
import { Chip, Grid, Stack, TextField } from "@mui/material"
import groupBy from "lodash/groupBy"
import { ChangeEvent, FC, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"

import { Button, Card } from "~/components"
import { ConditionGroup } from "~/domains/orchestration/flows/components/configuration/EventTriggerConfiguration"
import { messages } from "~/domains/orchestration/flows/messages"
import type { Suggestion } from "~/domains/orchestration/flows/types"

import "./ConditionBuilder.scss"

interface Props {
    suggestions: Suggestion[]
    group: ConditionGroup | null
    handleSave: (c: ConditionGroup) => void
    handleCancel: () => void
}

export const ConditionBuilder: FC<Props> = ({ suggestions, handleSave, handleCancel, group }) => {
    const { formatMessage } = useIntl()
    const [advanced, setAdvanced] = useState("")

    const [leftHand, setLeftHand] = useState<Suggestion | null>(null)
    const [rightHand, setRightHand] = useState<Suggestion | null>(null)

    const [showLeftSuggestions, setShowLeftSuggestions] = useState(false)
    const [showRightSuggestions, setShowRightSuggestions] = useState(false)
    // Group suggestions by type
    const groupedLeftSuggestions = groupBy(
        suggestions.filter((s) => s.position === "leftHand"),
        "type"
    )
    const groupedRightSuggestions = groupBy(
        suggestions.filter((s) => s.position === "rightHand"),
        "type"
    )

    if (!group) return null

    const handleClickLeftSuggestion = (s: Suggestion) => () => {
        setLeftHand(s)
        setAdvanced("")
    }
    const handleClickRightSuggestion = (s: Suggestion) => () => {
        setRightHand(s)
        setAdvanced("")
    }

    const isConditionEmpty = Boolean((leftHand && rightHand) || advanced)

    const handleSaveNewCondition = () => {
        // TODO: handle other operators, for now we only support "="
        const value = advanced || `${leftHand?.value} = ${rightHand?.value}`

        const newConditions = {
            id: group.id,
            conditions: [...group.conditions, value],
        }

        handleSave(newConditions)
    }

    const handleChangeAdvanced = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        setLeftHand(null)
        setRightHand(null)
        setAdvanced(value)
    }

    const handleLeftHandClick = () => {
        setShowLeftSuggestions(true)
        setShowRightSuggestions(false)
    }

    const handleRightHandClick = () => {
        setShowLeftSuggestions(false)
        setShowRightSuggestions(true)
    }

    return (
        <Stack gap={1} className="flows-conditionBuilder">
            <Grid
                container
                gap={1}
                gridColumn={3}
                alignItems="center"
                justifyContent="center"
                className="flows-conditionBuilder"
            >
                <Grid item xs={4} sx={{ display: "flex", justifyContent: "center", height: "100%" }}>
                    <Button type="neutral" onClick={handleLeftHandClick} className="flows-conditionBuilder-ifButton">
                        {leftHand
                            ? formatMessage(messages.suggestions[leftHand.labelId])
                            : formatMessage(messages.conditionBuilder.leftHand)}
                    </Button>
                </Grid>
                <Grid item xs={3} sx={{ display: "flex", justifyContent: "center", height: "100%" }}>
                    <Button type="primary" disabled>
                        {formatMessage(messages.conditionBuilder.operator)}
                    </Button>
                </Grid>
                <Grid item xs={4} sx={{ display: "flex", justifyContent: "center", height: "100%" }}>
                    <Button type="neutral" onClick={handleRightHandClick} className="flows-conditionBuilder-ifButton">
                        {rightHand
                            ? formatMessage(messages.suggestions[rightHand.labelId])
                            : formatMessage(messages.conditionBuilder.rightHand)}
                    </Button>
                </Grid>
            </Grid>

            {showLeftSuggestions &&
                Object.entries(groupedLeftSuggestions).map(([type, suggestions]) => (
                    <div key={type}>
                        <FormattedMessage tagName="h5" {...messages.suggestionTypes[type]} />
                        <Stack direction="row" gap={1}>
                            {suggestions.map((suggestion) => (
                                <Chip
                                    key={suggestion.value}
                                    label={formatMessage(messages.suggestions[suggestion.labelId])}
                                    variant="outlined"
                                    onClick={handleClickLeftSuggestion(suggestion)}
                                />
                            ))}
                        </Stack>
                    </div>
                ))}

            {showRightSuggestions &&
                Object.entries(groupedRightSuggestions).map(([type, suggestions]) => (
                    <div key={type}>
                        <FormattedMessage tagName="h5" {...messages.suggestionTypes[type]} />
                        <Stack direction="row" gap={1}>
                            {suggestions.map((suggestion) => (
                                <Chip
                                    key={suggestion.value}
                                    label={formatMessage(messages.suggestions[suggestion.labelId])}
                                    variant="outlined"
                                    onClick={handleClickRightSuggestion(suggestion)}
                                />
                            ))}
                        </Stack>
                    </div>
                ))}

            <Card
                expandable
                header={<FormattedMessage {...messages.configurationNode.advanced} />}
                isExpandedByDefault={false}
            >
                <TextField
                    label={formatMessage(messages.conditionBuilder.advancedLabel)}
                    value={advanced}
                    onChange={handleChangeAdvanced}
                />
            </Card>

            <Stack direction="row" gap={1} justifyContent="flex-end" className="flows-conditionBuilder-actions">
                <Button onClick={handleCancel} type="neutral">
                    {formatMessage(messages.conditionBuilder.cancelLabel)}
                </Button>

                <Button onClick={handleSaveNewCondition} type="primary-light" disabled={!isConditionEmpty}>
                    {formatMessage(messages.conditionBuilder.saveLabel)}
                </Button>
            </Stack>
        </Stack>
    )
}
