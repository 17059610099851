import { CurrencyCode } from "~/domains/identity/features/catalog/types"
import { LineStatus } from "~/domains/transactions/purchase-requests/types/PurchaseRequests"
import { CurrencyCodes, InvoiceFileLinksI, InvoiceLineI, InvoiceStatus, MonetaryInfo } from "~/types"

export interface InvoiceAddress {
    city: string
    country: string
    street: string
    street2?: string | null
    zipCode: string
}

export enum InvoiceFieldLevel {
    Line = "Line",
    Document = "Document",
}

export type InvoiceFieldType = "Address" | "Date" | "DecimalNumber" | "IntegerNumber" | "MonetaryAmount" | "String"

export interface InvoiceTemplateCustomField {
    id: string
    type: InvoiceFieldType
    name: string
    description: string
    level: InvoiceFieldLevel
}

export type InvoiceCustomFieldValue = string | MonetaryInfo | InvoiceAddress
export type InvoiceCustomFields = Record<string, InvoiceCustomFieldValue>

export interface InvoiceLifeCycleStatus {
    status: string
    description: string
}

export interface InvoiceLifeCycle {
    id: string
    organizationId: string
    default: InvoiceLifeCycleStatus
    other: InvoiceLifeCycleStatus[]
}

export interface InvoiceTemplate {
    id: string
    name: string
    description: string
    lifecycle: InvoiceLifeCycle
    customFields: InvoiceTemplateCustomField[]
}

export interface InvoiceParty {
    organizationId: string
    initiator: boolean
    involvement: string
    customFields?: InvoiceCustomFields
    referenceNumber?: string
    contacts: string[]
    billingAddress: InvoiceAddress
    currentLifecycle?: string
    template?: InvoiceTemplate
}

export interface InvoiceLineItemParty {
    id: string
    customFields?: InvoiceCustomFields | null
}

export interface InvoiceLineItemCustomField {
    label: string | undefined
    description: string | undefined
    value: string
}

export type InvoiceLineItemAPI = {
    id: string
    description: string
    productReference?: string
    currency: CurrencyCodes
    quantity: number
    unitPrice: number
    unitPriceWithoutTax: number
    priceWithoutTax: string
    taxId: string
    tax: string
    shippingAddress?: InvoiceAddress | null
    parties?: Record<string, InvoiceLineItemParty>

    // Temporary field to be removed
    approvalStatus?: LineStatus
    total?: number

    // Build in InvoiceItems component
    customFields?: Record<string, InvoiceLineItemCustomField>
}

export type InvoiceAPI = {
    version?: "V0" | "V1"
    status?: InvoiceStatus
    lifecycleStatus?: string
    number: string
    id: string
    invoiceId: string
    issuedAt: string
    dueDate?: string
    payerId: string
    sellerId: string
    parties: Record<string, InvoiceParty>
    lineItems: InvoiceLineI[]
    totalPriceWithoutTax: Record<CurrencyCode, string>
    tax: Record<CurrencyCode, { id: string; value: string }>
    totalAmountDue: Record<CurrencyCode, string>
    paymentTerms?: string | null
    paymentInstructions?: string | null
    notes?: string | null
    fileLinks: InvoiceFileLinksI | null
}

export const invoiceStatuses = ["DRAFT", "REFUSED", "CONFIRMED", "VALIDATED", "PAID", "CANCELED"] as const
export type InvoiceStatuses = (typeof invoiceStatuses)[number]

export const EditableInvoiceStatuses = ["DRAFT"] as const
export type EditableInvoiceStatusesEnum = (typeof EditableInvoiceStatuses)[number]

export const InvoicesTab = ["ALL", "DRAFT", "REFUSED", "CONFIRMED", "VALIDATED", "PAID", "CANCELED"] as const
export type InvoicesTabEnum = (typeof InvoicesTab)[number]
