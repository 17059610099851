import React, { FormEvent, useCallback, useEffect, useState } from "react"
import { OrganizationAddressI, OrganizationId, OrganizationRegistrationI } from "~/types"
import { OrganizationRegistrationForm } from "./OrganizationRegistrationForm"
import { AddressForm } from "./AddressForm"
import { defineMessages, useIntl } from "react-intl"
import { ModalBlock, ModalContainer, ModalMainBlock, StyledFormControlLabel } from "./ModalContainer"
import { Switch } from "~/components/Switch"
import { SavePayloadType } from "./types"
import { createEmptyAddress } from "./createEmptyAddress"

const messages = defineMessages({
    billingAddress: {
        id: "identity.organizations.modalOrganizationDetails.billingAddress",
        defaultMessage: "Billing address",
    },
    deliveryAddress: {
        id: "identity.organizations.modalOrganizationDetails.deliveryAddress",
        defaultMessage: "Delivery address",
    },
    copyAddress: {
        id: "identity.organizations.modalOrganizationDetails.copyAddress",
        defaultMessage: "Use for delivery address",
    },
})

interface Props {
    id?: string
    onSave: (payload: SavePayloadType) => void
    registrationInfo: OrganizationRegistrationI
    companyAddress?: OrganizationAddressI | null
    sendingAddress?: OrganizationAddressI | null
    withOrganizationAddress?: boolean
    withSendingAddress?: boolean
    registrationInfoReadOnly?: boolean
    adressesReadOnly?: boolean
    readOnly?: boolean
    organizationId?: OrganizationId
}

export const ModalOrganizationDetails: React.FC<Props> = ({
    id,
    onSave,
    registrationInfo,
    companyAddress,
    sendingAddress: initialSendingAddress,
    withOrganizationAddress = false,
    withSendingAddress = false,
    registrationInfoReadOnly = false,
    adressesReadOnly = false,
    readOnly,
    organizationId,
}) => {
    const { formatMessage } = useIntl()
    const [organizationRegistration, setOrganizationRegistration] =
        useState<OrganizationRegistrationI>(registrationInfo)
    const [organizationAddress, setOrganizationAddress] = useState<OrganizationAddressI>(
        companyAddress ?? createEmptyAddress()
    )
    const [sendingAddress, setSendingAddress] = useState<OrganizationAddressI>(
        initialSendingAddress ?? createEmptyAddress()
    )

    const [useSameAddress, setUseSameAddress] = useState<boolean>(false)
    const shouldUseSameAddress = useSameAddress && withOrganizationAddress && withSendingAddress
    const toggleUseSameAddress = useCallback(() => setUseSameAddress((currentValue) => !currentValue), [])

    useEffect(() => {
        if (initialSendingAddress) {
            setSendingAddress(initialSendingAddress)
        }
    }, [initialSendingAddress])

    const onRegistrationChange = useCallback(
        (payload: Partial<OrganizationRegistrationI>) => {
            setOrganizationRegistration((currentRegistration) => ({
                ...currentRegistration,
                ...payload,
            }))
        },
        [setOrganizationRegistration]
    )

    const onOrganizationAddressChange = useCallback(
        (payload: Partial<OrganizationAddressI>) => {
            setOrganizationAddress((currentAddress) => ({
                ...currentAddress,
                ...payload,
            }))
        },
        [setOrganizationRegistration]
    )

    const onSendingAddressChange = useCallback(
        (payload: Partial<OrganizationAddressI>) => {
            setSendingAddress((currentAddress) => ({
                ...currentAddress,
                ...payload,
            }))
        },
        [setSendingAddress]
    )

    const onSubmit = useCallback(
        (event: FormEvent) => {
            event.preventDefault()
            event.stopPropagation()
            onSave({
                organizationRegistration,
                organizationAddress: withOrganizationAddress ? organizationAddress : undefined,
                sendingAddress: withSendingAddress
                    ? useSameAddress
                        ? organizationAddress
                        : sendingAddress
                    : undefined,
            })
        },
        [
            useSameAddress,
            organizationRegistration,
            organizationAddress,
            sendingAddress,
            withOrganizationAddress,
            withSendingAddress,
            onSave,
        ]
    )

    return (
        <ModalContainer id={id} onSubmit={onSubmit}>
            <ModalMainBlock>
                <OrganizationRegistrationForm
                    registration={organizationRegistration}
                    onChange={onRegistrationChange}
                    readOnly={registrationInfoReadOnly}
                    organizationId={organizationId}
                />
            </ModalMainBlock>
            {withOrganizationAddress && (
                <ModalBlock>
                    <AddressForm
                        address={organizationAddress}
                        onChange={adressesReadOnly ? undefined : onOrganizationAddressChange}
                        label={formatMessage(messages.billingAddress)}
                    />

                    {!readOnly && withSendingAddress && (
                        <StyledFormControlLabel
                            control={<Switch checked={useSameAddress} onChange={toggleUseSameAddress} />}
                            label={formatMessage(messages.copyAddress)}
                        />
                    )}
                </ModalBlock>
            )}
            {withSendingAddress && (
                <ModalBlock>
                    <AddressForm
                        address={shouldUseSameAddress ? organizationAddress : sendingAddress}
                        onChange={shouldUseSameAddress || adressesReadOnly ? undefined : onSendingAddressChange}
                        label={formatMessage(messages.deliveryAddress)}
                    />
                </ModalBlock>
            )}
        </ModalContainer>
    )
}
