import { Stack, TextField } from "@mui/material"
import { DatePicker, LocalizationProvider, frFR } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs from "dayjs"
import { Fragment, useCallback } from "react"
import { useEffect, useState } from "react"
import { defineMessages, useIntl } from "react-intl"

import { Card } from "~/components"
import { Address } from "~/components/Form/Fields/Address"
import { ItemLabel } from "~/components/ItemLabel/ItemLabel"
import { usePatchUpdateInvoiceMutation } from "~/domains/transactions/invoices-v1/api/invoiceApiV1"
import { formatCustomFields } from "~/domains/transactions/invoices-v1/helpers/formatCustomFields"
import {
    InvoiceAddress,
    InvoiceCustomFieldValue,
    InvoiceCustomFields,
    InvoiceFieldLevel,
} from "~/domains/transactions/invoices-v1/types/Invoice"
import { EditAmount } from "~/features/supplier/components/EditAmount"
import { useSaveBuffer } from "~/hooks/useSaveBuffer"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { CurrencyCodes, InvoiceI, MonetaryInfo } from "~/types"

const messages = defineMessages({
    title: { id: "invoice.additionalFields.title", defaultMessage: "Additional fields" },
})

interface InvoiceCustomFieldsEditProps {
    invoice: InvoiceI
}

export function InvoiceCustomFieldsEdit({ invoice }: InvoiceCustomFieldsEditProps) {
    const { formatMessage, locale } = useIntl()
    const textLocale = frFR.components.MuiLocalizationProvider.defaultProps.localeText
    const organizationId = useAppSelector(selectCurrentOrganizationId)
    const [patchUpdateInvoice] = usePatchUpdateInvoiceMutation()
    const [customFields, setCustomFields] = useState<InvoiceCustomFields>({})
    const [fieldDateOpen, setFieldDateOpen] = useState(false)
    const [updatedField, setUpdatedField] = useState<InvoiceCustomFields>({})

    if (!organizationId) return null

    const party = invoice.parties?.[organizationId]

    if (!party) return null

    useEffect(() => {
        /* Customfields with values have already been formatted */
        if (Object.keys(customFields).length) return

        const customFieldsFormatted = formatCustomFields(party, InvoiceFieldLevel.Document)
        setCustomFields(customFieldsFormatted as unknown as InvoiceCustomFields)
    }, [])

    const save = useCallback(() => {
        patchUpdateInvoice({ id: invoice.id, parties: { [organizationId]: { customFields: updatedField } } })
    }, [updatedField])

    const { setHasChanges } = useSaveBuffer(save)

    const handleChange = useCallback(
        (id: string, value: InvoiceCustomFieldValue) => {
            setUpdatedField({ [id]: value })
            setHasChanges(true)

            setCustomFields({ ...customFields, [id]: value })
        },
        [customFields, invoice.parties, organizationId]
    )

    const templateDocumentCustomFields = party?.template?.customFields.filter(
        ({ level }) => level === InvoiceFieldLevel.Document
    )

    return (
        <Card title={formatMessage(messages.title)} expandable>
            <Stack spacing={1}>
                {(templateDocumentCustomFields || []).map(({ id, name, type }) => (
                    <Fragment key={id}>
                        {type === "Date" ? (
                            <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale={locale}
                                localeText={textLocale}
                            >
                                <DatePicker
                                    open={fieldDateOpen}
                                    onOpen={() => setFieldDateOpen(true)}
                                    onClose={() => setFieldDateOpen(false)}
                                    label={name}
                                    value={customFields?.[id] ? dayjs(customFields?.[id] as string) : null}
                                    onChange={(newValue) => {
                                        handleChange(id, newValue?.toISOString() ?? "")
                                    }}
                                />
                            </LocalizationProvider>
                        ) : type === "MonetaryAmount" ? (
                            <>
                                <EditAmount
                                    value={+(customFields?.[id] as MonetaryInfo)?.amount}
                                    currency={(customFields?.[id] as MonetaryInfo)?.currency as CurrencyCodes}
                                    placeholder={name}
                                    handleUpdate={(newValue) => {
                                        handleChange(id, {
                                            amount: newValue?.toString() ?? "0",
                                            currency: (customFields?.[id] as MonetaryInfo)?.currency ?? "EUR",
                                        } as MonetaryInfo)
                                    }}
                                />
                            </>
                        ) : type === "Address" ? (
                            <Stack spacing={1} paddingBlock={2}>
                                <ItemLabel>{name}</ItemLabel>
                                <Address
                                    address={party.customFields?.[id] as InvoiceAddress}
                                    onChange={(address) => {
                                        handleChange(id, JSON.stringify(address))
                                    }}
                                />
                            </Stack>
                        ) : type === "DecimalNumber" ? (
                            <TextField
                                type="number"
                                label={name}
                                placeholder="0"
                                value={customFields?.[id] ?? ""}
                                onChange={(event) => handleChange(id, event.currentTarget.value)}
                                name={id}
                            />
                        ) : type === "IntegerNumber" ? (
                            <TextField
                                type="number"
                                label={name}
                                placeholder="0"
                                value={customFields?.[id] ?? ""}
                                onChange={(event) => handleChange(id, event.currentTarget.value)}
                                name={id}
                            />
                        ) : type === "String" ? (
                            <TextField
                                name={id}
                                label={name}
                                placeholder={name}
                                value={customFields?.[id] ?? ""}
                                onChange={(event) => handleChange(id, event.currentTarget.value)}
                            />
                        ) : null}
                    </Fragment>
                ))}
            </Stack>
        </Card>
    )
}
