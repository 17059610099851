import * as Sentry from "@sentry/browser"
import { useCallback } from "react"
import { defineMessages, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"

import { partnerApi } from "~/api"
import { CreatePartnershipPayload, PartnershipTypeOption } from "~/domains/transactions/book-of-relations/types"
import { OrganizationId } from "~/types"

import { bookOfRelationsActions } from "../bookOfRelationsSlice"

const messages = defineMessages({
    createError: {
        id: "partners.bookofrelations.modal.create.createError",
        defaultMessage: "We encountered an error creating this partnership.",
    },
})

export const useCreatePartnership = (organizationId: OrganizationId) => {
    const dispatch = useDispatch()
    const { formatMessage } = useIntl()

    const createPartnership = useCallback(
        async (payload: CreatePartnershipPayload, attempt = 1) => {
            try {
                const partnership = await partnerApi.createPartnership(organizationId, payload)
                if (partnership) {
                    dispatch(bookOfRelationsActions.fetchPartnershipDataSuccess([partnership]))
                }
                return partnership
            } catch (error) {
                if (attempt < 3) {
                    setTimeout(() => createPartnership(payload, attempt + 1), 1000 * attempt)
                } else {
                    Sentry.captureException(error)
                    toast.error(formatMessage(messages.createError))
                }
            }
        },
        [organizationId, dispatch]
    )

    const handleCreatePartnership = useCallback(
        async (payload: CreatePartnershipPayload) => {
            if (payload.partnershipType === PartnershipTypeOption.BOTH) {
                await createPartnership({ ...payload, partnershipType: PartnershipTypeOption.BUYER })
                await createPartnership({ ...payload, partnershipType: PartnershipTypeOption.SUPPLIER })
            } else {
                await createPartnership(payload)
            }
        },
        [createPartnership]
    )

    return handleCreatePartnership
}
