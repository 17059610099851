import { Stack } from "@mui/material"
import { useCallback } from "react"
import { defineMessages, useIntl } from "react-intl"

import { Card } from "~/components"
import { ItemLabel } from "~/components/ItemLabel/ItemLabel"
import { AddPeople } from "~/features/buyer/components/InvolvedPeople/AddPeople"
import { People } from "~/features/buyer/components/InvolvedPeople/People"
import { useSegment } from "~/hooks/useSegment"
import { useAddInvolvedPeopleAndJoinOrganization } from "~/store/invoice/hooks"
import { useRemoveInvolvedPeople } from "~/store/invoice/hooks/useRemoveInvolvedPeople"
import { CountryCode, InvoiceI, OrganizationIdentifier, UserPartialI } from "~/types/"

export const messagesInvolvedPeople = defineMessages({
    title: {
        id: "buyer.involvedPeople.title",
        defaultMessage: "Involved people",
    },
    description: {
        id: "buyer.involvedPeople.description",
        defaultMessage: "Add peoples that should be added in the validation workflow",
    },
    errorAlreadyInvolved: {
        id: "buyer.involvedPeople.error.alreadyInvolved",
        defaultMessage: "is already involved",
    },
    errorCreateUser: {
        id: "buyer.involvedPeople.error.createUser",
        defaultMessage: "There was an error adding ",
    },
    peopleAdded: {
        id: "buyer.involvedPeople.success.peopleAdded",
        defaultMessage: "has been added",
    },
    firstInvolved: {
        id: "buyer.involvedPeople.status.firstInvolved",
        defaultMessage: "First receiver",
    },
    sent: {
        id: "buyer.involvedPeople.status.sent",
        defaultMessage: "Sent",
    },
    seen: {
        id: "buyer.involvedPeople.status.seen",
        defaultMessage: "Seen",
    },
    error: {
        id: "buyer.involvedPeople.status.error",
        defaultMessage: "Error",
    },
})

interface InvolvedPeopleProps {
    invoice: InvoiceI
    involvedPeople: UserPartialI[]
    identifier: OrganizationIdentifier
    countryCode: CountryCode
    invoiceVersion?: "V0" | "V1"
}

export function InvolvedPeople({ invoice, involvedPeople, identifier, countryCode }: InvolvedPeopleProps) {
    const { formatMessage } = useIntl()
    const { segmentTrack } = useSegment()

    const addInvolvedPeopleAndJoinOrganization = useAddInvolvedPeopleAndJoinOrganization(invoice.id, invoice.version)
    const removeInvolvedPeople = useRemoveInvolvedPeople(invoice.id)

    const handleAddInvolvedPeople = useCallback(
        async (email: string) => {
            segmentTrack("Add Involved People", {
                invoice_id: invoice.id,
                email_to: email,
            })
            addInvolvedPeopleAndJoinOrganization(countryCode, identifier, [email])
        },
        [invoice.id, identifier, countryCode]
    )

    const deletePeople = useCallback(
        (user: UserPartialI) => {
            segmentTrack("Remove Involved People", {
                invoice_id: invoice.id,
                userId: user.id,
            })

            removeInvolvedPeople([user.id])
        },
        [invoice.id]
    )

    return (
        <Card title={formatMessage(messagesInvolvedPeople.title)} expandable>
            <ItemLabel>{formatMessage(messagesInvolvedPeople.description)}</ItemLabel>
            <AddPeople addInvolvedPeople={handleAddInvolvedPeople} />
            <Stack spacing={1} marginTop={1}>
                {involvedPeople.map((person, index) => (
                    <People person={person} deletePeople={deletePeople} key={index} />
                ))}
            </Stack>
        </Card>
    )
}
