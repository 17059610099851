import React, { useCallback, useEffect, useMemo, useState } from "react"
import { OrganizationId } from "~/types"
import {
    sharedObjectPermissionsMessages,
    sharedObjectPermissionsModalMessages,
    SpiceDBAuthorizationName,
    SpiceDBObjectType,
    SpiceDBPermissionsLiteI,
} from "~/domains/identity/features/roles-permissions/types/SpiceDB"
import { OrganizationSummary } from "~/domains/transactions/components/Organizations/Organizations"
import { Button, Loader, Modal, Size } from "~/components"
import { useIntl } from "react-intl"
import { useFetchObjectPermissions } from "~/domains/identity/store/hooks/useFetchObjectPermissions"
import { SearchObjects } from "./SearchObjects"
import "~/components/ShareObjectModal/ShareObjectModal.scss"
import { PermissionsSelect } from "./PermissionsSelect"
import { useManageObjectPermissions } from "~/domains/identity/store/hooks/useManageObjectPermissions"
import { toast } from "react-toastify"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { rolesPermissionsActions } from "~/domains/identity/store/rolesPermissionsSlice"
import { selectUser } from "~/store/account/accountSlice"
import { getCurrentUserPermissions } from "~/domains/identity/features/roles-permissions/utils/spiceDB"
import { useCheckPurchaseRequestsPermission } from "~/domains/transactions/purchase-requests/store/hooks/useCheckPurchaseRequestsPermission"
import { selectPurchaseRequestsPermissions } from "~/domains/transactions/purchase-requests/store/purchaseRequestsSlice"
import { ObjectsList } from "./ObjectsList"
import { OnlyListView } from "./OnlyListView"

interface Props {
    open: boolean
    close: () => void
    organizationId: OrganizationId
    objectType: SpiceDBObjectType
    objectId: string
    buyerOrganization: OrganizationSummary | undefined
    supplierOrganization: OrganizationSummary | undefined
}

const addPermissionsToObjects = (objects: SpiceDBPermissionsLiteI[], permissions: SpiceDBAuthorizationName[]) => {
    return objects.map((object) => ({
        ...object,
        permissions: [...new Set([...object.permissions, ...permissions])],
    }))
}

export const ShareObjectPermissionsModal: React.FC<Props> = ({ open, close, objectType, objectId, organizationId }) => {
    const { formatMessage } = useIntl()
    const dispatch = useAppDispatch()
    const [searchPermissions, setSearchPermissions] = useState<SpiceDBAuthorizationName[]>([])
    const [selectedObjects, setSelectedObjects] = useState<SpiceDBPermissionsLiteI[]>([])
    const [showOnlyList, setShowOnlyList] = useState<boolean>(false)

    const currentUser = useAppSelector(selectUser)
    const purchaseRequestsPermissions = useAppSelector(selectPurchaseRequestsPermissions)
    const { sharedPermissions, loading } = useFetchObjectPermissions(objectId, objectType)
    const { manageObjectPermissionsSuccess, loading: manageObjectPermissionsLoading } = useManageObjectPermissions()
    const { checkPurchaseRequestsPermission, loading: loadingPermissions } = useCheckPurchaseRequestsPermission()

    const objectTypeTranslation = useMemo(() => sharedObjectPermissionsMessages[objectType], [objectType])

    const currentUserPermissions = useMemo(() => {
        return sharedPermissions
            ? getCurrentUserPermissions(currentUser.id, purchaseRequestsPermissions, sharedPermissions)
            : []
    }, [sharedPermissions, currentUser.id, purchaseRequestsPermissions])

    useEffect(() => {
        checkPurchaseRequestsPermission(organizationId, [
            SpiceDBAuthorizationName.VIEW,
            SpiceDBAuthorizationName.EDIT,
            SpiceDBAuthorizationName.DELETE,
            SpiceDBAuthorizationName.CONVERT,
            SpiceDBAuthorizationName.APPROVE,
        ])
    }, [organizationId, checkPurchaseRequestsPermission])

    const handleChangePermission = async (permissions: SpiceDBAuthorizationName[]): Promise<boolean> => {
        setSearchPermissions(permissions)
        return true
    }

    const handleInvite = useCallback(async () => {
        if (!selectedObjects.length || !searchPermissions.length) return

        const success = await manageObjectPermissionsSuccess({
            objectId,
            objectType,
            objects: selectedObjects,
            permissions: searchPermissions,
            isFirstTimeShare: true,
            creatorOrganizationId: organizationId,
            recipientOrganizationId: organizationId,
            isDelete: false,
        })

        if (success) {
            toast.success(
                formatMessage(sharedObjectPermissionsModalMessages.shareMultiPermissionsSuccess, {
                    s: searchPermissions.length === 1 ? "" : "s",
                })
            )
            dispatch(
                rolesPermissionsActions.setObjectsPermissions(
                    addPermissionsToObjects(selectedObjects, searchPermissions)
                )
            )
            setSelectedObjects([])
            setSearchPermissions([])
        }
    }, [
        selectedObjects,
        sharedPermissions,
        searchPermissions,
        manageObjectPermissionsSuccess,
        organizationId,
        objectId,
        objectType,
        dispatch,
    ])

    return (
        <>
            <Modal open={open} onClose={close} size={Size.MD} className="modal-shared-object-permissions">
                {!showOnlyList && (
                    <Modal.Header>
                        <h4>
                            {formatMessage(sharedObjectPermissionsModalMessages.title, {
                                objectType: objectTypeTranslation ? formatMessage(objectTypeTranslation) : "",
                            })}
                        </h4>
                    </Modal.Header>
                )}
                <Modal.Content>
                    {loading ? (
                        <Loader />
                    ) : (
                        <div className="modal-shared-body">
                            {showOnlyList ? (
                                <OnlyListView
                                    objectType={objectType}
                                    objectId={objectId}
                                    setShowOnlyList={setShowOnlyList}
                                    sharedPermissions={sharedPermissions.data}
                                />
                            ) : (
                                <>
                                    <div className="shared-objects-search">
                                        <div className="shared-objects-search-container">
                                            <SearchObjects
                                                objectType={objectType}
                                                objectId={objectId}
                                                selectedObjects={selectedObjects}
                                                setSelectedObjects={setSelectedObjects}
                                            />
                                            <div className="shared-objects-search-select-container">
                                                <PermissionsSelect
                                                    currentUserPermissions={currentUserPermissions}
                                                    objectPermissions={searchPermissions}
                                                    objectType={objectType}
                                                    handleChangePermission={handleChangePermission}
                                                    showRemoveAll={false}
                                                />
                                            </div>
                                        </div>
                                        <Button
                                            type="primary"
                                            buttonType="button"
                                            onClick={handleInvite}
                                            disabled={manageObjectPermissionsLoading || loadingPermissions}
                                        >
                                            <span>{formatMessage(sharedObjectPermissionsModalMessages.share)}</span>
                                        </Button>
                                    </div>
                                    <div className="shared-objects-text">
                                        {formatMessage(sharedObjectPermissionsModalMessages.sharedObjectsText, {
                                            objectType: objectTypeTranslation
                                                ? formatMessage(objectTypeTranslation)
                                                : "",
                                        })}
                                    </div>
                                    <div className="shared-objects">
                                        <ObjectsList
                                            objectId={objectId}
                                            objectType={objectType}
                                            sharedPermissions={sharedPermissions.data}
                                            setShowOnlyList={setShowOnlyList}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                </Modal.Content>
                <Modal.Footer>
                    <Button type="neutral" buttonType="button" onClick={close}>
                        {formatMessage(sharedObjectPermissionsModalMessages.close)}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
