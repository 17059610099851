import * as t from "io-ts"

import { OrganizationId } from "~/types"
import { Opaque } from "~/utils"

import { CreateTagGroupIO } from "./CreateTagGroup"
import { TagAndTagGroupNameI, TagI, TagIO } from "./Tag"
import { TagAssignmentRule } from "./TagAssignmentRule"

export type TagGroupId = Opaque<string, { readonly T: unique symbol }>

export const TagGroupIO = t.intersection([
    t.type({
        tagGroupId: t.string,
        organizationId: t.string,
    }),
    t.partial({
        tags: t.array(TagIO),
        id: t.string,
    }),
    CreateTagGroupIO,
])

export type TagGroupI = t.TypeOf<typeof TagGroupIO> & {
    tagGroupId: TagGroupId
    organizationId: OrganizationId
    assignmentRule: TagAssignmentRule
    tags: (TagI | TagAndTagGroupNameI)[]
}
