import { Popover, PopoverOrigin } from "@mui/material"
import React, { useCallback, useRef, useState } from "react"
import { Clock } from "react-feather"

import { EventI } from "~/types"

import Messages from "../CommunicationsTabs/Messages"
import "./InvoiceEvents.scss"

interface Props {
    events: EventI[]
}

const ANCHOR_ORIGIN: PopoverOrigin = {
    vertical: "bottom",
    horizontal: "right",
}

const TRANSFORM_ORIGIN: PopoverOrigin = {
    vertical: "top",
    horizontal: "right",
}

export const InvoiceEvents: React.FC<Props> = ({ events }) => {
    const [displayEventHistory, setDisplayEventHistory] = useState<boolean>(false)
    const toggleEventHistory = useCallback(() => {
        setDisplayEventHistory((value) => !value)
    }, [setDisplayEventHistory])
    const invoiceHistoryButton = useRef<HTMLDivElement>()

    const setInvoiceHistoryButton = useCallback((ref: HTMLDivElement) => {
        invoiceHistoryButton.current = ref
    }, [])

    if (events.length === 0) return null
    return (
        <>
            <div className="button-invoice-history" ref={setInvoiceHistoryButton} onClick={toggleEventHistory}>
                <Clock color="#606266" size={22} />
                <span className="button-invoice-history-counter">{events.length}</span>
            </div>
            <Popover
                open={displayEventHistory}
                anchorEl={invoiceHistoryButton.current}
                onClose={toggleEventHistory}
                className="invoice-history-popover"
                anchorOrigin={ANCHOR_ORIGIN}
                transformOrigin={TRANSFORM_ORIGIN}
                container={invoiceHistoryButton.current ? invoiceHistoryButton.current.parentElement : undefined}
            >
                <Messages messages={events} type="events" />
            </Popover>
        </>
    )
}
