import React from "react"
import { EventI, MessageI } from "~/types"
import Message from "./Message"
import "./Communication.scss"
import NewMessage from "~/components/CommunicationsTabs/NewMessage"

export type MessagesType = "communications" | "events"
export type Persona = "supplier" | "buyer"

interface Props {
    messages: MessageI[] | EventI[]
    type: MessagesType
    persona?: Persona
}

// TODO: display loader if is loading
// const eventsLoaded = useAppSelector(selectInvoiceEventsLoaded)
// const communicationsLoaded = useAppSelector(selectInvoiceCommunicationsLoaded)

export default function Messages({ messages, type }: Props) {
    return (
        <>
            {type === "communications" ? (
                <div className="messages-wrapper new-message">
                    <div className="messages">
                        <NewMessage />
                    </div>
                </div>
            ) : null}
            <div className={"messages-wrapper " + type}>
                <div className="messages">
                    {messages.map((message: MessageI | EventI, key: number) => (
                        <Message message={message} type={type} key={key} />
                    ))}
                </div>
            </div>
        </>
    )
}
