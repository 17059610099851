import React from "react"
import { AlertTriangle } from "react-feather"
import { defineMessages, FormattedMessage, useIntl } from "react-intl"
import { InvoiceStatus, InvoiceUserType } from "~/types"
import "./InvoiceDuplicateWarning.scss"
import { Alert, AlertTitle } from "@mui/material"
import { InvoiceDuplicateWarningInvoice } from "./InvoiceDuplicateWarningInvoice"
import { useInvoicesByIds } from "~/store/invoice/hooks/useInvoicesByIds"
import { isDefined } from "~/utils/isDefined"

const messages = defineMessages({
    warning: {
        id: "invoice.duplicate.warning",
        defaultMessage: "This invoice has already been imported",
    },
    issuedOn: {
        id: "invoice.duplicate.issuedOn",
        defaultMessage: "Issued on",
    },
})

interface Props {
    duplicates: string[]
    initiator: InvoiceUserType
}

export const InvoiceDuplicateWarning: React.FC<Props> = ({ duplicates, initiator }) => {
    const { formatMessage } = useIntl()
    const { invoices } = useInvoicesByIds(duplicates)

    const filteredInvoices = Object.keys(invoices)
        .map((invoiceId) => invoices[invoiceId]?.invoice)
        .filter(isDefined)
        .filter((invoice) => invoice.status !== InvoiceStatus.REJECTED)

    if (filteredInvoices.length === 0) {
        return null
    }

    return (
        <Alert className="invoice-warning-container" severity="error" icon={<AlertTriangle />}>
            <AlertTitle>
                <FormattedMessage {...messages.warning} />
            </AlertTitle>
            <div className="invoice-warning-duplicates">
                {filteredInvoices.map((invoice, index) => (
                    <InvoiceDuplicateWarningInvoice
                        key={invoice.id}
                        invoice={invoice}
                        index={index}
                        initiator={initiator}
                        issuedOnText={formatMessage(messages.issuedOn)}
                    />
                ))}
            </div>
        </Alert>
    )
}
