//import * as Sentry from "@sentry/browser"
import * as Sentry from "@sentry/browser"
import { useEffect, useMemo, useRef, useState } from "react"
import { defineMessage, useIntl } from "react-intl"
import { generatePath, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { Socket, io } from "socket.io-client"

import { isResultSuccess } from "~/core/Result"
import { invoiceFromApiAdapter } from "~/domains/transactions/invoices-v1/api/adapters/invoiceFromApiAdapter"
import { invoiceApiV1 } from "~/domains/transactions/invoices-v1/api/invoiceApiV1"
import { INVOICE_EDIT_ROUTE } from "~/domains/transactions/invoices-v1/routes"
import { store } from "~/store"
import { useAppDispatch } from "~/store/hooks"
import { invoiceActions } from "~/store/invoice/invoiceSlice"
import { ocrActions } from "~/store/ocr/ocrSlice"
import { parseCreateInvoiceResponse, parseInvoice, parsePartialInvoice } from "~/types/InvoiceParsers"

import {
    WebSocketInvoiceFileEvent,
    WebSocketInvoiceImagesStoredEvent,
    WebsocketEventType,
    WebsocketInvoiceProcessedEvent,
} from "../types/WebsocketEvents"

const API_OCR_URL = new URL(import.meta.env.VITE_API_OCR_URL)
const WS_ORIGIN = `ws${API_OCR_URL.protocol.endsWith("s:") ? "s" : ""}://${API_OCR_URL.hostname}:${API_OCR_URL.port}`
const WS_PATH = "/ocr/ws"

const errorMessage = defineMessage({
    id: "invoice.errorWithCurrentData",
    defaultMessage: "An error occured with this current data {data}",
})

export const useOcrWebsocket = (invoiceId: string) => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const didUnmount = useRef(false)
    const [isConnected, setIsConnected] = useState(false)
    const [subscribeToInvoiceAknowledge, setSubscribeToInvoiceAknowledge] = useState(false)
    const { formatMessage } = useIntl()

    const ioSocket = useMemo<Socket>(() => {
        const socket = io(WS_ORIGIN, {
            path: WS_PATH,
            transports: ["websocket"],
        })
        socket.on("connect", () => setIsConnected(true))
        socket.on("disconnect", () => setIsConnected(false))
        return socket
    }, [])

    useEffect(() => {
        return () => {
            didUnmount.current = true
        }
    }, [didUnmount])

    useEffect(() => {
        dispatch(invoiceActions.creatingInvoiceStarted(invoiceId))
        setSubscribeToInvoiceAknowledge(false)
        ioSocket.on(WebsocketEventType.InvoiceImport, (data: boolean) => {
            setSubscribeToInvoiceAknowledge(data === true)
        })
        ioSocket.on(WebsocketEventType.InvoiceRawDocumentUrl, async (data: string) => {
            dispatch(ocrActions.ocrResultSuccess({ rawOcrResult: data }))
        })
        ioSocket.on(WebsocketEventType.InvoicePartialUpdate, (data: unknown) => {
            const partialInvoiceResult = parsePartialInvoice(data)
            if (isResultSuccess(partialInvoiceResult)) {
                dispatch(invoiceActions.updatePartialImportingInvoice(partialInvoiceResult.result))
            }
        })
        ioSocket.on(WebsocketEventType.InvoiceProcessed, (data: WebsocketInvoiceProcessedEvent) => {
            try {
                const creatingInvoice = parseCreateInvoiceResponse(data)
                const currentCreatingInvoice = store.getState().invoice.creatingInvoice
                if (currentCreatingInvoice?.invoice.fileLinks) {
                    creatingInvoice.invoice.fileLinks = currentCreatingInvoice.invoice.fileLinks
                }
                dispatch(invoiceActions.setCreatingInvoice(creatingInvoice))
            } catch (e) {
                Sentry.captureException(e, { extra: { data, invoiceId, on: "WebsocketEventType.InvoiceProcessed" } })
            }
        })
        ioSocket.on(WebsocketEventType.InvoiceFileStored, (data: WebSocketInvoiceFileEvent) => {
            dispatch(invoiceActions.updatePartialFileLinks({ pdfLink: data }))
        })
        ioSocket.on(WebsocketEventType.InvoicePartialImageStored, (data: { pageIndex: number; url: string }) => {
            dispatch(invoiceActions.addOriginalImageLink(data))
        })
        ioSocket.on(WebsocketEventType.InvoicePaginationResult, (data: unknown) => {
            if (typeof data === "number") {
                dispatch(invoiceActions.setInvoicePagination(data))
            }
        })
        ioSocket.on(WebsocketEventType.InvoiceImagesStored, (data: WebSocketInvoiceImagesStoredEvent) => {
            dispatch(invoiceActions.updatePartialFileLinks({ originalImageLinks: data }))
        })
        ioSocket.on(WebsocketEventType.InvoiceErrorProcessing, (data: unknown) => {
            if (typeof data === "string") {
                toast.error(formatMessage(errorMessage, { data }))
                dispatch(invoiceActions.setCreatingInvoiceError(data))
            }
        })
        ioSocket.on(WebsocketEventType.InvoiceRetrieve, (data: unknown) => {
            const invoice = parseInvoice(data)
            dispatch(
                invoiceActions.setCreatingInvoice({
                    invoice: {
                        ...invoice,
                        loaded: invoice.reference !== null,
                    },
                    ocrBuyer: invoice.buyer,
                    ocrSupplier: invoice.supplier,
                    error: null,
                    otherError: null,
                })
            )
        })
        ioSocket.on(WebsocketEventType.InvoiceV2CreationSuccess, async (invoiceId: string) => {
            const { data } = await store.dispatch(invoiceApiV1.endpoints.getInvoice.initiate(invoiceId))

            if (!data) return

            const invoice = invoiceFromApiAdapter(data)
            dispatch(
                invoiceActions.setCreatingInvoice({
                    invoice: {
                        ...invoice,
                        loaded: invoice.reference !== null,
                    },
                    ocrBuyer: invoice.buyer,
                    ocrSupplier: invoice.supplier,
                    error: null,
                    otherError: null,
                })
            )
            navigate(generatePath(INVOICE_EDIT_ROUTE, { invoiceId }))
        })
        ioSocket.emitWithAck(WebsocketEventType.InvoiceImport, invoiceId).then(() => {
            setSubscribeToInvoiceAknowledge(true)
        })
    }, [invoiceId])

    return { isConnected, subscribeToInvoiceAknowledge }
}
