import React from "react"
import { ExternalLink, FileText } from "react-feather"
import { Loader } from "~/components"
import { InvoiceI, InvoiceStatus, InvoiceUserType, ViewTypeI } from "~/types"
import { StyledLink } from "~/domains/transactions/emails/components/EmailInboxForOrganization/StyledLink"
import { DateComponent } from "~/components/Date"
import { InvoiceStatusChip } from "~/components/DataTable/components"

interface Props {
    invoice: InvoiceI
    index: number
    initiator: InvoiceUserType
    issuedOnText: string
}

export const InvoiceDuplicateWarningInvoice: React.FC<Props> = ({ invoice, index, initiator, issuedOnText }) => {
    const getInvoiceLink = (invoice: InvoiceI, initiator: InvoiceUserType) => {
        const base = `/${initiator.toLowerCase()}/invoice/${invoice.id}`
        const isEditable =
            invoice.status === InvoiceStatus.DRAFT ||
            (initiator.toLowerCase() === ViewTypeI.supplier && invoice.status === InvoiceStatus.INFO_REQUESTED)
        const edit = isEditable ? "/edit" : ""
        return `${base}${edit}`
    }

    if (!invoice) {
        return <Loader key={index} small />
    }
    return (
        <div className="invoice-warning-duplicates-invoice" key={invoice.id}>
            <StyledLink to={getInvoiceLink(invoice, initiator)} target="_blank">
                <FileText size={20} color={"var(--color-light-grey)"} />
                <div className="invoice-warning-date">
                    {issuedOnText} <DateComponent value={invoice.issueDate} />
                </div>
                <div className="invoice-warning-status">
                    <InvoiceStatusChip status={invoice.status} view={initiator.toLowerCase() as ViewTypeI} />
                </div>
                <ExternalLink size={16} color={"var(--color-light-grey)"} />
            </StyledLink>
        </div>
    )
}
