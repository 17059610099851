import { Box, Stack, Tooltip } from "@mui/material"
import React, { useCallback, useMemo } from "react"
import { useIntl } from "react-intl"

import { commonMessages } from "~/common-messages"
import { ProgressBar, ProgressBarItem, ProgressBarItemDisplay, TitlePlacement } from "~/components"
import { ErrorMessage } from "~/components/ErrorMessage"
import { ProgressBarLegend } from "~/components/ProgressBar/ProgressBarLegend"
import {
    BudgetGaugeAmountLabel,
    BudgetGaugeTotalLabel,
    budgetGaugeMessages,
} from "~/features/budget/components/BudgetGauge"
import {
    amountLabels,
    budgetMetricsToProgressBarItems,
    getTotalForGaugeTitle,
} from "~/features/budget/core/budgetGauge"
import { AmountType, BudgetDataI, BudgetSimplifiedMetricsI } from "~/features/budget/types"

interface Props {
    budgetDetails: BudgetDataI
    transactionItems: ProgressBarItem[]
    showLegend?: boolean
}

const translatedLabels = Object.keys(amountLabels).reduce((acc, key) => {
    acc[key] = true
    return acc
}, {} as Record<string, boolean>)

export const TransactionInBudgetGaugeWithDetails: React.FC<Props> = ({
    budgetDetails,
    transactionItems,
    showLegend = true,
}) => {
    const { formatMessage, formatNumber } = useIntl()

    const metricsWithCurrentTransaction = useMemo(() => {
        const totalTransaction = transactionItems.reduce((acc, item) => acc + item.value, 0)
        const metrics: BudgetSimplifiedMetricsI | undefined = budgetDetails.metrics
            ? {
                  ...budgetDetails.metrics,
                  engaged: {
                      sumAmount: budgetDetails.metrics.engaged.sumAmount + totalTransaction,
                      sumAmountWithoutTax: budgetDetails.metrics.engaged.sumAmountWithoutTax + totalTransaction,
                  },
                  available: budgetDetails.metrics?.available ? budgetDetails.metrics?.available - totalTransaction : 0,
              }
            : undefined
        return metrics
    }, [transactionItems, budgetDetails.metrics])

    const totalAmountForGaugeTitle = useMemo(
        () => getTotalForGaugeTitle(metricsWithCurrentTransaction),
        [metricsWithCurrentTransaction]
    )
    const progressBarItems = useMemo(() => {
        const totalTransaction = transactionItems.reduce((acc, item) => acc + item.value, 0)
        const metrics = metricsWithCurrentTransaction
        const budgetMetrics = budgetMetricsToProgressBarItems(metrics, budgetDetails.currentAmount)
        const totalsMetrics = budgetMetrics.reduce((acc, metric) => acc + metric.value, 0)
        const progressBarItems = [
            ...transactionItems.map((item) => ({ ...item, percentage: item.value / totalsMetrics })),
            ...budgetMetricsToProgressBarItems(metrics, budgetDetails.currentAmount).map((item) => {
                const percentage = item.value / totalsMetrics
                return item.label === AmountType.ENGAGED
                    ? { ...item, percentage, value: item.value - totalTransaction }
                    : { ...item, percentage }
            }),
        ]

        return progressBarItems
    }, [transactionItems, metricsWithCurrentTransaction, budgetDetails.metrics, budgetDetails.currentAmount])

    const overbudgetAmount = useMemo(
        () => progressBarItems.find((item) => item.label === AmountType.OVERBUDGET && item.value > 0)?.value ?? 0,
        [progressBarItems]
    )

    const isOverbudget = overbudgetAmount > 0

    const progressBarItemsDisplay = useMemo(
        () =>
            progressBarItems.reduce((acc, item) => {
                if (item.label !== AmountType.OVERBUDGET || item.value > 0) {
                    const value = formatNumber(item.value, {
                        style: "currency",
                        currency: budgetDetails.currency,
                        currencyDisplay: "narrowSymbol",
                    })
                    const percentage = item.percentage ? formatNumber(item.percentage, { style: "percent" }) : null
                    acc.push({
                        ...item,
                        value: `${value}${percentage ? ` / ${percentage}` : ""}`,
                        label: item.label
                            ? translatedLabels[item.label]
                                ? formatMessage(amountLabels[item.label])
                                : item.label
                            : "",
                    })
                }
                return acc
            }, [] as ProgressBarItemDisplay[]),
        [
            progressBarItems,
            budgetDetails.currency,
            budgetDetails.currentAmount,
            isOverbudget,
            overbudgetAmount,
            formatMessage,
        ]
    )

    const renderProgressBarTitle = useCallback(
        () => (
            <Box>
                <Tooltip title={formatMessage(budgetGaugeMessages.progressBarTitleTooltip)} placement="top" arrow>
                    <BudgetGaugeAmountLabel isOverbudget={isOverbudget}>
                        {formatNumber(totalAmountForGaugeTitle, {
                            style: "currency",
                            currency: budgetDetails.currency,
                            currencyDisplay: "narrowSymbol",
                        })}
                    </BudgetGaugeAmountLabel>
                </Tooltip>
                <BudgetGaugeTotalLabel>{formatMessage(budgetGaugeMessages.used)}</BudgetGaugeTotalLabel>
                <BudgetGaugeAmountLabel isOverbudget={isOverbudget}>
                    {formatNumber(budgetDetails.currentAmount, {
                        style: "currency",
                        currency: budgetDetails.currency,
                        currencyDisplay: "narrowSymbol",
                    })}
                </BudgetGaugeAmountLabel>
            </Box>
        ),
        [totalAmountForGaugeTitle, budgetDetails.currentAmount, budgetDetails.currency, isOverbudget, formatMessage]
    )

    if (budgetDetails.currentAmount == null || budgetDetails.currency == null) {
        return <ErrorMessage>{formatMessage(commonMessages.error)}</ErrorMessage>
    }
    if (!showLegend) {
        return (
            <ProgressBar
                values={progressBarItemsDisplay}
                title={renderProgressBarTitle()}
                titlePlacement={TitlePlacement.BOTTOM}
                tooltip={<ProgressBarLegend values={progressBarItemsDisplay} />}
            />
        )
    }

    return (
        <Stack gap={1}>
            <ProgressBar values={progressBarItemsDisplay} title={renderProgressBarTitle()} />
            <ProgressBarLegend values={progressBarItemsDisplay} />
        </Stack>
    )
}
