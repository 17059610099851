import * as Sentry from "@sentry/react"
import { useCallback } from "react"

import { selectUser } from "~/store/account/accountSlice"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { OrganizationId } from "~/types"

import { PurchaseOrders } from "../../purchase-orders/types/PurchaseOrders"
import { convertToPayloadArray } from "../components/CompanyCustomFields/CustomFieldObjectHelpers"
import { useCustomFieldsApi } from "../customFieldsApi"
import { customFieldsActions, selectPendingCustomFieldObjects } from "../store"
import { CustomFieldObjectType } from "../types/CustomFieldObjectType"

export const useSavePOPendingCustomFieldObjects = (organizationId: OrganizationId) => {
    const user = useAppSelector(selectUser)
    const dispatch = useAppDispatch()
    const pendingCustomFieldObjects = useAppSelector(selectPendingCustomFieldObjects)
    const customFieldsApi = useCustomFieldsApi()

    return useCallback(
        async (purchaseOrder: PurchaseOrders) => {
            try {
                const customFieldsObjectsToCreate = convertToPayloadArray(
                    pendingCustomFieldObjects,
                    purchaseOrder.id,
                    CustomFieldObjectType.PURCHASE_ORDER
                )
                if (customFieldsObjectsToCreate?.length) {
                    await customFieldsApi.batchCreateCustomFields(customFieldsObjectsToCreate, true)
                }
                dispatch(customFieldsActions.resetPendingCustomFieldObjects())
            } catch (error) {
                Sentry.captureException(error, {
                    tags: { organizationId },
                    extra: { purchaseOrder },
                })
            }
        },
        [pendingCustomFieldObjects, customFieldsApi, user, organizationId]
    )
}
