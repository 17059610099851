import { withSocketIOProvider } from "~/domains/transactions/common/subscriptions/components/SocketIOContext"
import { InvoiceDocumentRelations } from "~/domains/transactions/document-relations/components/InvoiceDocumentRelations"
import { InvoiceI } from "~/types"
import { InvoiceUserType } from "~/types"

interface InvoicePurchaseOrdersProps extends JSX.IntrinsicAttributes {
    invoice: InvoiceI
}

export const InvoicePurchaseOrders = withSocketIOProvider(({ invoice }: InvoicePurchaseOrdersProps) => {
    if (!invoice.payerId) return null

    return (
        <>
            <InvoiceDocumentRelations
                organizationId={invoice.payerId}
                invoiceId={invoice.id}
                initiator={InvoiceUserType.BUYER}
            />
        </>
    )
})
