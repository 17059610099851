import { Stack, TextField } from "@mui/material"
import cls from "classnames"
import React, { ChangeEvent, FC, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"

import { Card } from "~/components"
import { messages } from "~/domains/orchestration/flows/messages"
import { InvoiceToPurchaseOrderMatchNode } from "~/domains/orchestration/flows/types"

import { ConfigurationNode } from "./ConfigurationNode"

interface Props {
    selectedNode: InvoiceToPurchaseOrderMatchNode
    unselectCallback: () => void
}

export const InvoiceToPurchaseOrderMatchConfiguration: FC<Props> = ({ selectedNode, unselectCallback }) => {
    const { formatMessage } = useIntl()

    // Handle the current node
    const [currentNode, setCurrentNode] = useState(selectedNode)

    const handleChangeAdvanced = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        setCurrentNode({
            ...currentNode,
            invoiceId: value,
        })
    }

    const configurationNodeItemClassName = cls("flows-configurationNode-item", "flows-editor-sideBar-item")

    return (
        <ConfigurationNode configuredNode={currentNode} unselectCallback={unselectCallback}>
            <Stack gap={2} className={configurationNodeItemClassName}>
                <Card
                    expandable
                    header={<FormattedMessage {...messages.configurationNode.advanced} />}
                    isExpandedByDefault={false}
                >
                    <TextField
                        label={formatMessage(messages.InvoiceToPurchaseOrderMatchNodeConfiguration.invoiceIdLabel)}
                        value={currentNode.invoiceId}
                        onChange={handleChangeAdvanced}
                    />
                </Card>
            </Stack>
        </ConfigurationNode>
    )
}
