import { defineMessages } from "react-intl"

export const tagGroupsAndTagsMessages = defineMessages({
    name: { id: "account.tagGroups.table.name", defaultMessage: "Name" },
    description: { id: "account.tagGroups.table.description", defaultMessage: "Description" },
    groupName: { id: "account.tagGroups.table.groupName", defaultMessage: "Group name" },
    nbTags: { id: "account.tagGroups.table.nbTags", defaultMessage: "Number of tags" },
    value: { id: "account.tagGroups.table.value", defaultMessage: "Value" },
    externalId: { id: "account.tagGroups.table.externalId", defaultMessage: "External ID" },
    selected: { id: "account.tagGroups.table.selected", defaultMessage: "selected" },
    delete: { id: "account.tagGroups.table.delete", defaultMessage: "Delete" },
    actions: { id: "account.tagGroups.table.actions", defaultMessage: "Actions" },
    tags: { id: "account.tagGroups.table.tags", defaultMessage: "Associated tags" },
    newTagGroup: { id: "account.tagGroups.newGroup", defaultMessage: "New group" },
    newTag: { id: "account.tagGroups.newTag", defaultMessage: "New tag" },
    new: { id: "account.tagGroups.new", defaultMessage: "New" },
    batchImportTags: { id: "account.tagGroups.batchImportTags", defaultMessage: "Batch import tags" },
    modalUploadTitle: { id: "account.tagGroups.modalImportBatch.title", defaultMessage: "Import Tags in batch" },
    modalUploadTip: {
        id: "account.tagGroups.modalImportBatch.tip",
        defaultMessage: "Supported format : csv, xlsx. No more than 2MB",
    },
    errorWrongFileType: {
        id: "account.tagGroups.modalImportBatch.errorWrongFileType",
        defaultMessage: "Wrong file format: we only accept CSV and XLSX files.",
    },
    canSelectOnlyOneType: {
        id: "account.tagGroups.modalImportBatch.canSelectOnlyOneType",
        defaultMessage: "You can select only one option at a time: either groups or tags.",
    },
    ratio: { id: "account.tagGroups.ratio", defaultMessage: "Ratio" },
    path: { id: "account.tagGroups.path", defaultMessage: "Path" },
})
