import { ThemeProvider } from "@mui/material"
import React from "react"
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { IntercomProvider } from "react-use-intercom"
import { PersistGate } from "redux-persist/integration/react"

// import Segment from 'react-segment-analytics';
// import { ConnectedRouter } from 'connected-react-router';
import { InactivityDetection, Loader, Theme } from "~/core"
import RouterLayout from "~/core/RouterLayout"
import prompt from "~/domains/copilot/prompt"
import rolesPermissions from "~/domains/identity/features/roles-permissions"
import flows from "~/domains/orchestration/flows"
import paymentMethods from "~/domains/payment/payment-methods"
import partners from "~/domains/transactions/book-of-relations"
import invoicesV1 from "~/domains/transactions/invoices-v1"
import purchaseOrders from "~/domains/transactions/purchase-orders"
import purchaseRequests from "~/domains/transactions/purchase-requests"
import account from "~/features/account"
import budget from "~/features/budget"
import buyer from "~/features/buyer"
import ocr from "~/features/ocr"
import supplier from "~/features/supplier"
import workflow from "~/features/workflow"
import { LocalesProvider } from "~/i18n/LocalesProvider"
import messages_ar from "~/lang/ar.json"
import messages_ch from "~/lang/ch.json"
import messages_de from "~/lang/de.json"
import messages_no from "~/lang/no.json"
import { persistor, store } from "~/store"

import "./assets/css/App.scss"
import { WithApiContext } from "./domains/common/apiClient"
import documents from "./domains/identity/features/documents"
import emails from "./domains/transactions/emails"
import dashboard from "./features/dashboard"
import metrics from "./features/metrics"
import messages_en from "./lang/en.json"
import messages_fr from "./lang/fr.json"
import { AppCrashFallback } from "./utils/AppCrashFallback"
import ErrorBoundary from "./utils/ErrorBoundary"

const messages = {
    fr: messages_fr,
    en: messages_en,
    ar: messages_ar,
    ch: messages_ch,
    de: messages_de,
    no: messages_no,
}

const modules = [
    buyer,
    supplier,
    account,
    flows,
    workflow,
    partners,
    ocr,
    purchaseRequests,
    purchaseOrders,
    invoicesV1,
    rolesPermissions,
    budget,
    prompt,
    emails,
    documents,
    dashboard,
    paymentMethods,
    metrics,
]
const modulesRoutes = modules.reduce(
    (routes: React.ReactElement[], module) => routes.concat(module.routes as React.ReactElement[]),
    []
)

const ENV = import.meta.env.VITE_ENV
const INTERCOM_APP_ID = import.meta.env.VITE_INTERCOM_APP_ID

export default function App() {
    return (
        <ErrorBoundary FallbackComponent={AppCrashFallback}>
            <ThemeProvider theme={Theme}>
                <Provider store={store}>
                    <PersistGate loading={<Loader />} persistor={persistor}>
                        <LocalesProvider messages={messages} locale={"en"}>
                            {/* <Segment writeKey={SEGMENT_KEY}> */}
                            <IntercomProvider appId={INTERCOM_APP_ID} autoBoot={ENV !== "development"}>
                                <WithApiContext>
                                    <div className="App">
                                        <InactivityDetection />
                                        <BrowserRouter>
                                            <RouterLayout modulesRoutes={modulesRoutes} modulesNavItems={[]} />
                                        </BrowserRouter>
                                        <ToastContainer
                                            position="top-right"
                                            autoClose={5000}
                                            hideProgressBar={false}
                                            newestOnTop={false}
                                            closeOnClick
                                            rtl={false}
                                            pauseOnFocusLoss
                                            draggable
                                            pauseOnHover
                                        />
                                        {ENV === "production" ? null : <div id="environment">{ENV}</div>}
                                    </div>
                                </WithApiContext>
                            </IntercomProvider>
                            {/* </Segment> */}
                        </LocalesProvider>
                    </PersistGate>
                </Provider>
            </ThemeProvider>
        </ErrorBoundary>
    )
}
