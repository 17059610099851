import { useCallback, useState } from "react"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { OrganizationId } from "~/types"
import { toast } from "react-toastify"
import { defineMessages, useIntl } from "react-intl"
import { purchaseRequestApi } from "~/domains/transactions/purchase-requests/api/purchaseRequestsApi"
import { purchaseRequestsActions, selectPurchaseRequest } from "../purchaseRequestsSlice"
import { useFetchPurchaseRequest } from "~/domains/transactions/purchase-requests/store/hooks/useFetchPurchaseRequest"
import { useSavePurchaseRequestPendingLineTags } from "./useSavePurchaseRequestPendingLineTags"
import { useSavePRPendingCustomFieldValues } from "~/domains/transactions/custom-fields/hooks"
import { selectPendingLineTags, tagsActions } from "~/domains/tags/store/tagsSlice"
import { addLineIdToTags, hasPendingLineTags } from "~/domains/transactions/common/purchases/purchasesUtils"
import * as Sentry from "@sentry/react"

const messages = defineMessages({
    success: {
        id: "purchase.requests.update.success",
        defaultMessage: "Purchase request updated",
    },
    error: {
        id: "purchase.requests.update.error",
        defaultMessage: "Something went wrong. Please contact your administrator",
    },
})

export const useUpdatePurchaseRequest = (organizationId: OrganizationId, PRid: string) => {
    const dispatch = useAppDispatch()
    const { formatMessage } = useIntl()
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>()
    const { fetchPurchaseRequest } = useFetchPurchaseRequest(organizationId)
    const PR = useAppSelector(selectPurchaseRequest)
    const pendingLineTags = useAppSelector(selectPendingLineTags)
    const savePurchaseRequestPendingLineTags = useSavePurchaseRequestPendingLineTags(organizationId)
    const savePurchaseRequestPendingCustomFieldValues = useSavePRPendingCustomFieldValues(organizationId)

    const updatePR = useCallback(
        async (showToast: boolean = true) => {
            try {
                setLoading(true)

                await purchaseRequestApi.update(organizationId, PRid, PR)

                const purchaseRequest = await purchaseRequestApi.findById(organizationId, PRid)

                if (purchaseRequest) {
                    const hasPendingTags = hasPendingLineTags(pendingLineTags, PR)
                    await Promise.allSettled([
                        hasPendingTags && savePurchaseRequestPendingLineTags(PR, purchaseRequest),
                        savePurchaseRequestPendingCustomFieldValues(PR, purchaseRequest),
                    ])
                    if (hasPendingTags) {
                        const tags = purchaseRequest.lines.flatMap((line, lineIndex) => {
                            const temporaryId = PR.lines[lineIndex]?.temporaryId
                            const tags = temporaryId ? pendingLineTags[temporaryId] : null
                            return tags && line.id ? addLineIdToTags(tags, line.id) : []
                        })
                        dispatch(tagsActions.addNewLinesTags(tags))
                        dispatch(tagsActions.resetPendingTags())
                    }
                }
                await fetchPurchaseRequest(PRid)

                dispatch(purchaseRequestsActions.updatePRSuccess())
                setLoading(false)
                if (showToast) {
                    toast.success(formatMessage(messages.success))
                }
                return PR
            } catch (e) {
                setError(`${e}`)
                setLoading(false)
                toast.error(formatMessage(messages.error))
                console.error(`Error updating Purchase Request: ${PRid}`, error)
                Sentry.captureException(error, {
                    tags: { organizationId },
                    extra: { PRid },
                })
                throw e
            }
        },
        [
            PR,
            organizationId,
            pendingLineTags,
            dispatch,
            savePurchaseRequestPendingLineTags,
            savePurchaseRequestPendingCustomFieldValues,
        ]
    )

    return {
        updatePR,
        error,
        loading,
    }
}
