import { defineMessages, useIntl } from "react-intl"
import { toast } from "react-toastify"
import { useAppDispatch } from "~/store/hooks"
import { OrganizationId } from "~/types"
import { isDefined } from "~/utils/isDefined"
import { purchaseOrdersApi } from "~/domains/transactions/purchase-orders/api"
import { purchaseOrdersActions } from "~/domains/transactions/purchase-orders/store/purchaseOrdersSlice"
import { getPurchaseOrderId } from "~/domains/transactions/purchase-orders/core/purchaseOrder"
import { sleep } from "~/utils"
import { ListPurchaseOrders } from "~/domains/transactions/purchase-orders/types/PurchaseOrders"
import { FulfillmentStatus } from "~/domains/transactions/types"

const messages = defineMessages({
    purchaseOrderReceived: {
        id: "purchase.orders.purchaseOrderReceived",
        defaultMessage:
            "Your purchase {count, plural, =0 {} one {order} other {orders}} has been successfully marked as received.",
    },
    unableToMarkAsReceivedPurchaseOrder: {
        id: "purchase.orders.unableToMarkAsReceivedPurchaseOrder",
        defaultMessage:
            "An error occured: your purchase {count, plural, =0 {} one {order} other {orders}} could not be marked as received.",
    },
    unableToMarkAsReceivedSomePurchaseOrder: {
        id: "purchase.orders.unableToMarkAsReceivedSomePurchaseOrder",
        defaultMessage:
            "An error occured: {count} purchase {count, plural, =0 {} one {order} other {orders}} could not be marked as received.",
    },
})

export const useMarkAsReceivedPurchaseOrders = () => {
    const dispatch = useAppDispatch()
    const { formatMessage } = useIntl()
    return async (purchaseOrders: ListPurchaseOrders, organizationId: OrganizationId) => {
        const results = await Promise.allSettled(
            purchaseOrders.map((purchaseOrder) => {
                const listIds = purchaseOrder.lines.map((pol) => pol.id ?? "")

                purchaseOrdersApi.updateFulfillmentStatus(
                    organizationId,
                    purchaseOrder.id,
                    listIds,
                    FulfillmentStatus.DELIVERED
                )
            })
        )

        // We have to wait for the PO updates to be processed, cause we fetch them right after to get updated statuses
        await sleep(200)

        const resultPOs = await Promise.allSettled(
            results.map((r, index) => {
                const purchaseOrderIds = purchaseOrders.map((po) => po.id)
                const purchaseOrderId = getPurchaseOrderId(r, index, purchaseOrderIds)
                if (purchaseOrderId) {
                    return purchaseOrdersApi.findById(organizationId, purchaseOrderId)
                }
                return undefined
            })
        )

        const successReceivedPOs = resultPOs
            .map((r) => (r.status === "fulfilled" && r.value ? r.value : null))
            .filter(isDefined)

        const failReceivedLength = results.length - successReceivedPOs.length
        dispatch(purchaseOrdersActions.approvePurchaseOrders(successReceivedPOs))
        if (failReceivedLength > 0) {
            toast.error(
                formatMessage(
                    failReceivedLength === results.length
                        ? messages.unableToMarkAsReceivedPurchaseOrder
                        : messages.unableToMarkAsReceivedSomePurchaseOrder,
                    { count: failReceivedLength }
                )
            )
        } else {
            toast.success(formatMessage(messages.purchaseOrderReceived, { count: purchaseOrders.length }))
        }
        return true
    }
}
