import { SxProps, Theme, styled } from "@mui/material"
import React, { useCallback } from "react"

import { CheckIcon } from "~/components/Icons"

type OptionsWithoutRatioProps = {
    withRatio?: false
    ratioKey?: never
}

type MultipleOptionsWithRatioProps<T> = {
    withRatio: true
    ratioKey: keyof T
}

export type MultipleOptionsProps<T> = {
    multiple: true
    onChange: (values: T[]) => void
    value: T[]
} & (OptionsWithoutRatioProps | MultipleOptionsWithRatioProps<T>)

export type SingleOptionProps<T> = {
    multiple?: false
    onChange: (value: T | null) => void
    value: T | null | undefined
} & OptionsWithoutRatioProps

type Props<T> = {
    items: T[]
    labelKeys: (keyof T)[]
    idKey: keyof T
    sx?: SxProps<Theme>
    highlightItem?: T | null
    level?: number
} & (MultipleOptionsProps<T> | SingleOptionProps<T>)

const Container = styled("ul")({
    width: "100%",
})

const ListItem = styled("li")<{ highlighted: boolean; level: number }>(({ theme, highlighted, level }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "8px",
    minHeight: "34px",
    cursor: "pointer",
    stroke: "transparent",
    padding: `0 0 0 ${(level + 1) * 20}px`,
    ...(highlighted && {
        backgroundColor: theme.palette.primary.light,
        stroke: theme.palette.primary.light,
    }),
    "&:hover": {
        backgroundColor: theme.palette.primary.light,
        stroke: theme.palette.primary.light,
    },
}))
const ListItemLabel = styled("div")(({ theme }) => ({
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    color: theme.palette.grey[600],
}))

const ListItemLabelsGroup = styled("div")({
    display: "flex",
    flexWrap: "wrap",
    gap: "4px",
})

interface CheckBoxProps {
    checked: boolean
}

const SquareCheckboxContainer = styled("i")<CheckBoxProps>(({ theme, checked }) => ({
    width: "14px",
    height: "14px",
    margin: "4px 0",
    border: `1px solid ${theme.palette.grey.A700}`,
    borderRadius: "1px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
    ...(checked && {
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.main,
        stroke: "white",
    }),
}))

const RoundCheckboxContainer = styled(SquareCheckboxContainer)<CheckBoxProps>({
    borderRadius: "100%",
})

export function TagsList<T>({
    items,
    labelKeys,
    idKey,
    multiple,
    value,
    onChange,
    highlightItem,
    withRatio,
    ratioKey,
    level = 0,
}: Props<T>) {
    const CheckboxContainer = multiple ? SquareCheckboxContainer : RoundCheckboxContainer

    const onItemClick = useCallback(
        (item: T) => {
            if (multiple) {
                const isSelected = value.some((selectedItem) => selectedItem[idKey] === item[idKey])
                if (isSelected) {
                    const newSelection = value.filter((selectedItem) => selectedItem[idKey] !== item[idKey])
                    if (withRatio) {
                        const ratioRatio = value.length / newSelection.length
                        onChange(
                            newSelection.map((item) => ({
                                ...item,
                                [ratioKey]:
                                    typeof item[ratioKey] === "number"
                                        ? (item[ratioKey] as number) * ratioRatio
                                        : 1 / newSelection.length,
                            }))
                        )
                    } else {
                        onChange(newSelection)
                    }
                } else {
                    if (withRatio) {
                        const itemsInOptionGroup = value.length + 1
                        const ratioRatio = value.length / itemsInOptionGroup
                        onChange([
                            ...value.map((item) => ({
                                ...item,
                                [ratioKey]:
                                    typeof item[ratioKey] === "number"
                                        ? (item[ratioKey] as number) * ratioRatio
                                        : 1 / itemsInOptionGroup,
                            })),
                            { ...item, [ratioKey]: 1 / (value.length + 1) },
                        ])
                    } else {
                        onChange([...value, item])
                    }
                }
            } else {
                const isSelected = !!value && item[idKey] === value[idKey]
                if (isSelected) {
                    onChange(null)
                } else {
                    onChange(item)
                }
            }
        },
        [onChange, value]
    )

    const renderItem = useCallback(
        (item: T) => {
            // Remove nested children that are at root level
            if (level === 0 && item["parentId" as keyof T]) return null

            const children = item["children" as keyof T] ?? null
            const checked = multiple
                ? value.some((selectedItem) => selectedItem[idKey] === item[idKey])
                : !!value && item[idKey] === value[idKey]
            return (
                <React.Fragment key={`${item[idKey]}`}>
                    <ListItem
                        highlighted={highlightItem ? highlightItem[idKey] === item[idKey] : false}
                        data-itemid={item[idKey]}
                        level={level}
                        onClick={() => onItemClick(item)}
                    >
                        <CheckboxContainer checked={checked}>
                            <CheckIcon color="current" />
                        </CheckboxContainer>
                        <ListItemLabelsGroup>
                            {labelKeys.map((labelKey, index) => {
                                if (item[labelKey]) {
                                    return (
                                        <ListItemLabel key={index}>
                                            {index > 0 ? `(${item[labelKey]})` : `${item[labelKey]}`}
                                        </ListItemLabel>
                                    )
                                }
                                return null
                            })}
                        </ListItemLabelsGroup>
                    </ListItem>
                    {children &&
                        (multiple ? (
                            <TagsList
                                items={children as T[]}
                                highlightItem={highlightItem}
                                labelKeys={["name", "value"] as (keyof T)[]}
                                idKey={"tagId" as keyof T}
                                multiple={true}
                                value={value as T[]}
                                level={level + 1}
                                onChange={onChange as (values: T[]) => void}
                                {...(withRatio === true ? { withRatio: true, ratioKey } : { withRatio: false })}
                            />
                        ) : (
                            <TagsList
                                items={children as T[]}
                                highlightItem={highlightItem}
                                labelKeys={["name", "value"] as (keyof T)[]}
                                idKey={"tagId" as keyof T}
                                multiple={false}
                                value={value as T | null}
                                level={level + 1}
                                onChange={onChange as (value: T | null) => void}
                            />
                        ))}
                </React.Fragment>
            )
        },
        [labelKeys, idKey, onItemClick, value, highlightItem]
    )

    return <Container>{items.map(renderItem)}</Container>
}
