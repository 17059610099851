import { useState } from "react"
import { defineMessages, useIntl } from "react-intl"
import { toast } from "react-toastify"

import { Loader } from "~/components"
import {
    useCreatePaymentMethodDetailsObjectMutation,
    useGetPartnerPaymentMethodDetailsQuery,
} from "~/domains/payment/payment-method-details/api/paymentMethodDetailsApi"
import { AddButon, BorderCard, Container, Title } from "~/domains/payment/payment-method-details/components/"
import { PaymentMethodDetailsManagement } from "~/domains/payment/payment-method-details/components/"
import { PaymentMethodDetailsSelector } from "~/domains/payment/payment-method-details/components/DocumentPaymentSection/PaymentMethodDetailsSelector"
import { PaymentMethodDetails } from "~/domains/payment/payment-method-details/types"
import { PaymentMethodType } from "~/domains/payment/payment-methods/types"
import { ObjectType } from "~/domains/payment/types"

const messages = defineMessages({
    payee: {
        id: "payment.payeePaymentMethodDetails.payee",
        defaultMessage: "Payee",
    },
    paymentMethod: {
        id: "payment.payeePaymentMethodDetails.paymentMethod",
        defaultMessage: "Payment method",
    },
    addPaymentMethod: {
        id: "payment.payeePaymentMethodDetails.addPaymentMethod",
        defaultMessage: "+ Add a payment method",
    },
    linkCreated: {
        id: "payment.payeePaymentMethodDetails.linkCreated",
        defaultMessage: "The payment method has been linked to your organization",
    },
})

interface PayeePaymentMethodDetailsProps {
    payerId: string
    payeeId: string
    loading?: boolean
    label?: string
    selectedPaymentMethodDetailsId: string | null
    onPaymentMethodChanged: (paymentMethodDetailsId: string) => void
}

export const PayeePaymentMethodDetails = ({
    payerId,
    payeeId,
    label,
    loading = false,
    selectedPaymentMethodDetailsId,
    onPaymentMethodChanged,
}: PayeePaymentMethodDetailsProps) => {
    const { formatMessage } = useIntl()
    const [createPaymentMethodDetailsObject, { isLoading: isCreating }] = useCreatePaymentMethodDetailsObjectMutation()
    const { data, isLoading, isFetching } = useGetPartnerPaymentMethodDetailsQuery({
        ownerId: payeeId,
        partnerId: payerId,
    })
    const [open, setOpen] = useState(false)
    const items = data?.items ?? []

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handlePaymentMethdDetailsChanged = (id: string) => {
        onPaymentMethodChanged(id)
    }

    const handlePaymentMethodDetailsCreated = async ({ id }: PaymentMethodDetails) => {
        if (payeeId && payeeId && payeeId !== payerId) {
            await createPaymentMethodDetailsObject({
                payment_method_details_id: id,
                object_id: payerId,
                object_type: ObjectType.ORGANIZATION,
                link_created_by_id: "",
                side: "sender",
                default: true,
            })
            toast.success(formatMessage(messages.linkCreated))
        }
    }

    if (isLoading || isFetching || isCreating) {
        return <Loader small />
    }

    return (
        <BorderCard>
            <Container>
                <Title>{label ?? formatMessage(messages.payee)}</Title>
                <AddButon type="title" disabled={loading} onClick={handleOpen}>
                    {formatMessage(messages.addPaymentMethod)}
                </AddButon>
            </Container>
            {items.length > 0 && (
                <PaymentMethodDetailsSelector
                    label={formatMessage(messages.paymentMethod)}
                    value={selectedPaymentMethodDetailsId}
                    items={items}
                    loading={loading}
                    onItemSelect={handlePaymentMethdDetailsChanged}
                />
            )}
            {open && (
                <PaymentMethodDetailsManagement
                    activeTab={PaymentMethodType.CARD}
                    open
                    organizationId={payeeId}
                    onClose={handleClose}
                    onOpen={handleOpen}
                    onPaymentMethodDetailsCreated={handlePaymentMethodDetailsCreated}
                />
            )}
        </BorderCard>
    )
}
