import { FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Stack, capitalize } from "@mui/material"
import * as Sentry from "@sentry/browser"
import React, { useCallback, useEffect, useState } from "react"
import { FormattedMessage, MessageDescriptor, defineMessages, useIntl } from "react-intl"
import { generatePath, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

import { organizationApi } from "~/api"
import { Button, Loader, Modal } from "~/components"
import { Switch } from "~/components/Switch"
import { CreateOrganizationContainer } from "~/domains/identity/features/organizations/components/CreateOrganizationContainer/CreateOrganizationContainer"
import { ManualCompanyInputTrigger } from "~/domains/identity/features/organizations/components/CreateOrganizationContainer/ManualInputContainer"
import { OrganizationSelector } from "~/domains/identity/features/organizations/components/OrganizationSelector"
import { PARTNER_DETAILS_ROUTE } from "~/domains/transactions/book-of-relations/routes"
import { useCreatePartnership } from "~/domains/transactions/book-of-relations/store/hooks"
import { CreatePartnershipPayload, PartnershipTypeOption } from "~/domains/transactions/book-of-relations/types"
import { useCompanyResultFromCompanyDetails } from "~/features/invoice/components/InvoiceSupplierInfo"
import { CompanyResult } from "~/features/organization/components/CompanyAutocomplete"
import { CompanyAutocompleteType } from "~/features/organization/components/CompanyAutocomplete/CompanyAutocompleteField"
import { getImportCompanyIdentifierOrEmptyString } from "~/store/invoice/core"
import {
    CompanyI,
    CountryCode,
    CreateOrganizationResponseI,
    ImportInvoiceCompanyInfoI,
    OrganizationI,
    OrganizationId,
} from "~/types"

const NO_ORGANIZATIONS: OrganizationI[] = []
const NO_SUGGESTED_COMPANIES: CompanyI[] = []

const messages = defineMessages({
    modalTitle: {
        id: "partners.bookofrelations.modal.create.title",
        defaultMessage: "Add a new partner",
    },
    modalCancel: {
        id: "partners.bookofrelations.modal.create.cancel",
        defaultMessage: "Cancel",
    },
    modalCreateAnother: {
        id: "partners.bookofrelations.modal.create.another",
        defaultMessage: "Create & add another",
    },
    modalConfirm: {
        id: "partners.bookofrelations.modal.create.confirm",
        defaultMessage: "Create",
    },
    organization: {
        id: "partners.bookofrelations.modal.create.organization",
        defaultMessage: "Organization",
    },
    searchPartner: {
        id: "partners.bookofrelations.modal.create.searchPartner",
        defaultMessage: "Search for the partner's company",
    },
    preferredStatus: {
        id: "partners.bookofrelations.modal.create.preferredStatus",
        defaultMessage: "Mark as preferred",
    },
    verifiedStatus: {
        id: "partners.bookofrelations.modal.create.verifiedStatus",
        defaultMessage: "Mark as verified",
    },
    createSuccess: {
        id: "partners.bookofrelations.modal.create.success",
        defaultMessage: "The new partner has been added to your Partners network",
    },
    createPartnershipError: {
        id: "partners.bookofrelations.modal.create.partnershipError",
        defaultMessage: "We encountered an error creating the partnership. Please try again.",
    },
    createOrganizationError: {
        id: "partners.bookofrelations.modal.create.organizationError",
        defaultMessage: "We encountered an error creating the organization. Please try again.",
    },
})

export const partnershipTypeMessages: Record<PartnershipTypeOption, MessageDescriptor> = defineMessages({
    [PartnershipTypeOption.BUYER]: {
        id: "partners.bookofrelations.createPartnershipType.buyer",
        defaultMessage: "client",
    },
    [PartnershipTypeOption.SUPPLIER]: {
        id: "partners.bookofrelations.createPartnershipType.supplier",
        defaultMessage: "vendor",
    },
    [PartnershipTypeOption.BOTH]: {
        id: "partners.bookofrelations.createPartnershipType.both",
        defaultMessage: "both Vendor & Client",
    },
})

const createEmptyPartner = (countryCode = CountryCode.FR): ImportInvoiceCompanyInfoI => ({
    countryCode,
    name: "",
    contactName: "",
    organizationId: null,
})

const getCountryCodeFromCompanyResult = (type: CompanyAutocompleteType, value: any): CountryCode | null => {
    switch (type) {
        case CompanyAutocompleteType.WhitePagesResult:
            return value?.countryCode || null
        case CompanyAutocompleteType.Organization:
            return value?.registration?.countryCode || null
        default:
            return null
    }
}

interface CreatePartnershipModalProps {
    organizationId: OrganizationId
    open: boolean
    close: () => void
    fetchPartners: () => void
}

export const ModalCreatePartnership: React.FC<CreatePartnershipModalProps> = ({
    organizationId,
    open,
    close,
    fetchPartners,
}) => {
    const { formatMessage } = useIntl()
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [createPartnershipType, setPartnershipType] = useState(PartnershipTypeOption.SUPPLIER)
    const [verifiedStatus, setVerifiedStatus] = useState(false)
    const [preferredStatus, setPreferredStatus] = useState(false)
    const [countryCode, setCountryCode] = useState(CountryCode.FR)
    const [manualImport, setManualImport] = useState(false)
    const [companyResult, setCompanyResult] = useState<CompanyResult | undefined>()

    const onCountryCodeChange = useCallback((countryCode: CountryCode) => setCountryCode(countryCode), [setCountryCode])
    const [partner, setPartner] = useState(createEmptyPartner())

    const initialCompanyResult = useCompanyResultFromCompanyDetails(partner, NO_SUGGESTED_COMPANIES)
    const createPartnership = useCreatePartnership(organizationId)

    useEffect(() => {
        if (!initialCompanyResult) return

        setCompanyResult(initialCompanyResult)

        const { type, value } = initialCompanyResult

        const countryCode = getCountryCodeFromCompanyResult(type, value)

        if (countryCode) {
            setCountryCode(countryCode)
        }
    }, [initialCompanyResult])

    const createOrganization = async (
        partner: ImportInvoiceCompanyInfoI
    ): Promise<CreateOrganizationResponseI | null> => {
        try {
            const organizationItem = await organizationApi.createOrganization(
                partner.name,
                partner.countryCode,
                getImportCompanyIdentifierOrEmptyString(partner)
            )
            return organizationItem
        } catch (error) {
            toast.error(formatMessage(messages.createOrganizationError))
            Sentry.captureException(error, {
                extra: {
                    companyResult,
                    partner,
                    organizationId,
                },
            })
            return null
        }
    }

    const handleCreatePartnership = async (partnerId: OrganizationId | undefined, createAnother = false) => {
        if (!partnerId) {
            toast.error(formatMessage(messages.createOrganizationError))
            return
        }
        const payload: CreatePartnershipPayload = {
            partnerId,
            partnershipType: createPartnershipType,
            verifiedStatus,
            preferredStatus,
        }

        try {
            await createPartnership(payload)
            toast.success(formatMessage(messages.createSuccess))

            if (!createAnother) {
                handleClose()
                navigate(generatePath(PARTNER_DETAILS_ROUTE, { organizationId: payload.partnerId }))
            } else {
                clearComponent()
                fetchPartners()
            }
        } catch (error) {
            toast.error(formatMessage(messages.createPartnershipError))
            Sentry.captureException(error, {
                extra: {
                    partner,
                    organizationId,
                },
            })
        }
    }

    const handleOrganizationAndPartnership = useCallback(
        async (createAnother = false) => {
            setLoading(true)
            if (!partner.organizationId) {
                const organizationItem = await createOrganization(partner)
                await handleCreatePartnership(organizationItem?.id, createAnother)
            } else {
                await handleCreatePartnership(partner.organizationId, createAnother)
            }
            setLoading(false)
        },
        [partner, createPartnershipType, verifiedStatus, preferredStatus, createPartnership]
    )

    const clearComponent = () => {
        setPartner(createEmptyPartner())
        setPartnershipType(PartnershipTypeOption.SUPPLIER)
        setVerifiedStatus(false)
        setPreferredStatus(false)
    }

    const onSubmit = useCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            handleOrganizationAndPartnership()
        },
        [handleOrganizationAndPartnership]
    )

    const handleClose = useCallback(() => {
        clearComponent()
        close()
    }, [close])

    const updateData = useCallback(
        (data: Partial<ImportInvoiceCompanyInfoI>) => {
            setPartner((currentState) => ({ ...currentState, ...data }))
        },
        [setPartner]
    )

    const onSave = useCallback(() => true, [])

    const handlePartnershipTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPartnershipType((event.target as HTMLInputElement).value as PartnershipTypeOption)
    }

    const handleCreateAnother = () => {
        handleOrganizationAndPartnership(true)
    }

    const onPreferredStatusChange = () => setPreferredStatus((prev) => !prev)

    const onVerifiedStatusChange = () => setVerifiedStatus((prev) => !prev)

    const onManualImportClick = useCallback(() => setManualImport(true), [])
    const cancelManualImport = useCallback(() => setManualImport(false), [])

    const handleManualOrganizationCreated = useCallback((newOrganization: OrganizationI) => {
        const newCompanyResult: CompanyResult = {
            type: CompanyAutocompleteType.Organization,
            value: newOrganization,
        }
        setCompanyResult(newCompanyResult)
        const newPartner = {
            organizationId: newOrganization.id,
            countryCode: newOrganization.registration?.countryCode,
            name: newOrganization.name,
            registrationNumber: newOrganization.registration?.preferredRegistrationNumber?.registrationNumber,
        }
        updateData(newPartner)
        setManualImport(false)
    }, [])

    const saveEnabled = partner.registrationNumber ? false : true

    return (
        <>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-request" className="modal-request">
                <Modal.Header>
                    <h4>
                        <FormattedMessage {...messages.modalTitle} />
                    </h4>
                </Modal.Header>
                {!manualImport ? (
                    <form onSubmit={onSubmit}>
                        {loading ? (
                            <Loader />
                        ) : (
                            <>
                                <Modal.Content>
                                    <Stack gap={1}>
                                        <OrganizationSelector
                                            organizations={NO_ORGANIZATIONS}
                                            label=""
                                            company={companyResult}
                                            suggestedCompanies={NO_SUGGESTED_COMPANIES}
                                            countryCode={countryCode}
                                            onCountryCodeChange={onCountryCodeChange}
                                            updateData={updateData}
                                            onSave={onSave}
                                        />
                                        <ManualCompanyInputTrigger onClick={onManualImportClick} />
                                        <hr />
                                        <FormLabel id="radio-label">
                                            <RadioGroup
                                                aria-labelledby="partnershipType-radio-group"
                                                name="partnership.partnershipType"
                                                value={createPartnershipType}
                                                onChange={handlePartnershipTypeChange}
                                            >
                                                <Grid container alignItems="center" gap={1}>
                                                    <FormControlLabel
                                                        value={PartnershipTypeOption.SUPPLIER}
                                                        control={<Radio />}
                                                        label={capitalize(
                                                            formatMessage(
                                                                partnershipTypeMessages[PartnershipTypeOption.SUPPLIER]
                                                            )
                                                        )}
                                                    />
                                                    <FormControlLabel
                                                        value={PartnershipTypeOption.BUYER}
                                                        control={<Radio />}
                                                        label={capitalize(
                                                            formatMessage(
                                                                partnershipTypeMessages[PartnershipTypeOption.BUYER]
                                                            )
                                                        )}
                                                    />
                                                    <FormControlLabel
                                                        value={PartnershipTypeOption.BOTH}
                                                        control={<Radio />}
                                                        label={capitalize(
                                                            formatMessage(
                                                                partnershipTypeMessages[PartnershipTypeOption.BOTH]
                                                            )
                                                        )}
                                                    />
                                                </Grid>
                                            </RadioGroup>
                                        </FormLabel>
                                        <Grid container alignItems="center" gap={2}>
                                            <Grid container alignItems="center" gap={1}>
                                                <FormLabel>
                                                    {capitalize(formatMessage(messages.preferredStatus))}
                                                </FormLabel>
                                                <Switch
                                                    checked={preferredStatus}
                                                    onChange={onPreferredStatusChange}
                                                    color="primary"
                                                />
                                            </Grid>
                                            <Grid container alignItems="center" gap={1}>
                                                <FormLabel>
                                                    {capitalize(formatMessage(messages.verifiedStatus))}
                                                </FormLabel>
                                                <Switch
                                                    checked={verifiedStatus}
                                                    onChange={onVerifiedStatusChange}
                                                    color="primary"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Stack>
                                </Modal.Content>
                                <Modal.Footer>
                                    <Button buttonType="button" onClick={handleClose} rightIcon="close" type="neutral">
                                        <FormattedMessage {...messages.modalCancel} />
                                    </Button>
                                    <Button
                                        buttonType="button"
                                        onClick={handleCreateAnother}
                                        type="primary-light"
                                        disabled={saveEnabled}
                                    >
                                        <FormattedMessage {...messages.modalCreateAnother} />
                                    </Button>
                                    <Button buttonType="submit" disabled={saveEnabled}>
                                        <FormattedMessage {...messages.modalConfirm} />
                                    </Button>
                                </Modal.Footer>
                            </>
                        )}
                    </form>
                ) : (
                    <CreateOrganizationContainer
                        cancelManualImport={cancelManualImport}
                        companyResult={companyResult}
                        onOrganizationCreated={handleManualOrganizationCreated}
                    />
                )}
            </Modal>
        </>
    )
}
