import { invoiceApiV1 } from "~/domains/transactions/invoices-v1/api/invoiceApiV1"
import { store } from "~/store"
import { OrganizationId, ReceivedDocumentI, SentDocumentI } from "~/types"

import axiosClient from "./axiosClient"

const BASE_URL = import.meta.env.VITE_API_INVOICES_URL + "v1"
const SUPPLIERS_BASE_URL = `${BASE_URL}/suppliers`
const BUYERS_BASE_URL = `${BASE_URL}/buyers`

export const documentApi = {
    getSentDocuments: async (userId: string): Promise<SentDocumentI[]> => {
        const url = `${SUPPLIERS_BASE_URL}/${userId}/documents`
        const invoicesSent = (await axiosClient.get(url)) as SentDocumentI[]

        try {
            // Get invoice from v1 API
            const invoicesV1Result = await store.dispatch(invoiceApiV1.endpoints.getInvoices.initiate(userId))
            const invoicesV1Sent = (invoicesV1Result?.data || []).filter(
                ({ sellerId }) => sellerId === userId
            ) as unknown as SentDocumentI[]

            return [...invoicesSent, ...invoicesV1Sent]
        } catch (error) {
            console.error(error)
            return invoicesSent
        }
    },
    getReceivedDocuments: async (userId: string): Promise<ReceivedDocumentI[]> => {
        const url = `${BUYERS_BASE_URL}/${userId}/documents`
        const invoicesReceived = (await axiosClient.get(url)) as ReceivedDocumentI[]

        try {
            // Get invoice from v1 API
            const invoicesV1Result = await store.dispatch(invoiceApiV1.endpoints.getInvoices.initiate(userId))
            const invoicesV1Received = (invoicesV1Result?.data || []).filter(
                ({ payerId }) => payerId === userId
            ) as unknown as ReceivedDocumentI[]

            return [...invoicesReceived, ...invoicesV1Received]
        } catch (error) {
            console.error(error)
            return invoicesReceived
        }
    },
    async getSuppliersDocuments(organizationId: OrganizationId): Promise<SentDocumentI[]> {
        const url = `${SUPPLIERS_BASE_URL}/${organizationId}/organization-documents`
        const invoicesSupplier = (await axiosClient.get(url)) as SentDocumentI[]

        try {
            // Get invoice from v1 API
            const invoicesV1Result = await store.dispatch(invoiceApiV1.endpoints.getInvoices.initiate(organizationId))
            const invoicesV1Supplier = (invoicesV1Result?.data || []).filter(
                ({ sellerId }) => sellerId === organizationId
            ) as unknown as SentDocumentI[]

            return [...invoicesSupplier, ...invoicesV1Supplier]
        } catch (error) {
            console.error(error)
            return invoicesSupplier
        }
    },
    async getBuyersDocuments(organizationId: OrganizationId): Promise<ReceivedDocumentI[]> {
        const url = `${BUYERS_BASE_URL}/${organizationId}/organization-documents`
        const invoicesBuyer = (await axiosClient.get(url)) as ReceivedDocumentI[]

        try {
            // Get invoice from v1 API
            const invoicesV1Result = await store.dispatch(invoiceApiV1.endpoints.getInvoices.initiate(organizationId))
            const invoicesV1Buyer = (invoicesV1Result?.data || []).filter(
                ({ payerId }) => payerId === organizationId
            ) as unknown as ReceivedDocumentI[]

            return [...invoicesBuyer, ...invoicesV1Buyer]
        } catch (error) {
            console.error(error)
            return invoicesBuyer
        }
    },
}
