import React from "react"
import { CustomFieldObjectType } from "~/domains/transactions/custom-fields/types/CustomFieldObjectType"
import { PurchaseViewType } from "~/domains/transactions/types"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { CustomFieldObjectAsTextfield } from "../../../custom-fields/components/CompanyCustomFields/CustomFieldObjectAsTextfield"
import { useIntl } from "react-intl"
import {
    justifiableDecisionEnumMessages,
    strictDecisionEnumMessages,
    VeoliaCustomFields,
    VeoliaCustomFieldsMessages,
} from "../../../custom-fields/components/CompanyCustomFields/CompanyCustomFieldsConfig"
import { useFetchCustomFieldsByObjectId } from "~/domains/transactions/custom-fields/hooks"
import { CustomFieldValueI } from "~/domains/transactions/custom-fields/types/CustomFieldValue"
import { getOrCreateCustomField } from "~/domains/transactions/custom-fields/components/CompanyCustomFields/CustomFieldObjectHelpers"

interface Props {
    purchaseOrderId: string
    pageMode: PurchaseViewType
}

export const POInfosVeoliaCustomFields: React.FC<Props> = ({ purchaseOrderId, pageMode }) => {
    const { formatMessage } = useIntl()
    const currentOrganizationId = useAppSelector(selectCurrentOrganizationId)
    const customFields: CustomFieldValueI[] | null = useFetchCustomFieldsByObjectId(purchaseOrderId)
    const isReadOnly = pageMode === PurchaseViewType.VIEW
    const isTemporaryId = !purchaseOrderId

    const competitiveBiddingCustomField = getOrCreateCustomField(
        customFields,
        VeoliaCustomFields.COMPETITIVE_BIDDING_TRACKER,
        purchaseOrderId,
        CustomFieldObjectType.PURCHASE_ORDER,
        currentOrganizationId
    )

    const approvedBidComparisonCustomField = getOrCreateCustomField(
        customFields,
        VeoliaCustomFields.APPROVED_BID_COMPARISON,
        purchaseOrderId,
        CustomFieldObjectType.PURCHASE_ORDER,
        currentOrganizationId
    )

    const poRegularisationCustomField = getOrCreateCustomField(
        customFields,
        VeoliaCustomFields.PO_REGULARISATION,
        purchaseOrderId,
        CustomFieldObjectType.PURCHASE_ORDER,
        currentOrganizationId
    )

    const labels = {
        [VeoliaCustomFields.COMPETITIVE_BIDDING_TRACKER]: formatMessage(
            VeoliaCustomFieldsMessages[VeoliaCustomFields.COMPETITIVE_BIDDING_TRACKER]
        ),
        [VeoliaCustomFields.APPROVED_BID_COMPARISON]: formatMessage(
            VeoliaCustomFieldsMessages[VeoliaCustomFields.APPROVED_BID_COMPARISON]
        ),
        [VeoliaCustomFields.PO_REGULARISATION]: formatMessage(
            VeoliaCustomFieldsMessages[VeoliaCustomFields.PO_REGULARISATION]
        ),
    }

    return (
        <>
            <CustomFieldObjectAsTextfield
                customField={competitiveBiddingCustomField}
                label={labels[VeoliaCustomFields.COMPETITIVE_BIDDING_TRACKER]}
                isTemporaryId={isTemporaryId}
                enumMessages={justifiableDecisionEnumMessages}
                isReadOnly={isReadOnly}
            />
            <CustomFieldObjectAsTextfield
                customField={approvedBidComparisonCustomField}
                label={labels[VeoliaCustomFields.APPROVED_BID_COMPARISON]}
                isTemporaryId={isTemporaryId}
                enumMessages={justifiableDecisionEnumMessages}
                isReadOnly={isReadOnly}
            />
            <CustomFieldObjectAsTextfield
                customField={poRegularisationCustomField}
                label={labels[VeoliaCustomFields.PO_REGULARISATION]}
                isTemporaryId={isTemporaryId}
                enumMessages={strictDecisionEnumMessages}
                isReadOnly={isReadOnly}
            />
        </>
    )
}
