import { Typography, styled } from "@mui/material"

export const Title = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "0 8px",
})

export const TitleTypography = styled(Typography)(({ theme }) => ({
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "26px",
    margin: 0,
    color: theme.palette.grey[600],
}))
