import React, { PropsWithChildren } from "react"
import { Alert, styled } from "@mui/material"
import { AlertTriangle } from "react-feather"
import { FormattedMessage, MessageDescriptor } from "react-intl"

interface Props extends PropsWithChildren {
    autoCorrectionMessage?: MessageDescriptor
}

export const CustomAlert = styled(Alert, {
    shouldForwardProp: (prop) => prop !== "autoCorrectionMessage",
})<{ autoCorrectionMessage?: MessageDescriptor | undefined }>(({ autoCorrectionMessage }) => ({
    backgroundColor: "var(--color-yellow-100)",
    cursor: autoCorrectionMessage ? "pointer" : "default",
}))

const AutoCorrectDiv = styled("div")(() => ({
    color: "var(--primary-color)",
}))

export const WarningAlert: React.FC<Props> = ({ autoCorrectionMessage, children }) => {
    return (
        <CustomAlert severity="warning" icon={<AlertTriangle />} autoCorrectionMessage={autoCorrectionMessage}>
            <span>{children}</span>
            {autoCorrectionMessage ? (
                <AutoCorrectDiv>
                    <FormattedMessage {...autoCorrectionMessage} />
                </AutoCorrectDiv>
            ) : null}
        </CustomAlert>
    )
}
