import { CountryCode, OrganizationI, OrganizationId, UserI } from "~/types"
import { useAppDispatch } from "../../hooks"
import { organizationActions } from "../organizationSlice"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { organizationApi, userApi } from "~/api"
import { accountActions } from "../../account/accountSlice"

const usePoolUserForNewOrganization = (user: UserI, pollingFrequency = 1000, pollingTimeout = 10000) => {
    const [waitingOrganizationId, setWaitingOrganizationId] = useState<OrganizationId>()
    const finished = useRef<boolean>(false)
    const [newOrganizationId, setNewOrganizationId] = useState<OrganizationId>()
    const userOrganizationsIds = useMemo(
        () => user.organizations.map((organizationItem) => organizationItem.id),
        [user.organizations]
    )
    const timeout = useRef<number>()
    const newOrganizationCallback = useRef<(value: OrganizationId | PromiseLike<OrganizationId>) => void>()
    const dispatch = useAppDispatch()

    const waitForNewOrganization = useCallback(
        (organizationId: OrganizationId) => {
            const promise = new Promise<OrganizationId>((resolve, reject) => {
                const rejectTimeout = window.setTimeout(() => {
                    setWaitingOrganizationId(undefined)
                    finished.current = true
                    reject("Timeout")
                }, pollingTimeout)
                newOrganizationCallback.current = (value: OrganizationId | PromiseLike<OrganizationId>) => {
                    window.clearTimeout(rejectTimeout)
                    resolve(value)
                }
            })
            finished.current = false
            setWaitingOrganizationId(organizationId)
            return promise
        },
        [setWaitingOrganizationId, pollingTimeout]
    )

    useEffect(() => {
        if (waitingOrganizationId && !finished.current) {
            const fetchUser = async () => {
                const updatedUser = await userApi.getCurrentUser()
                const organizationItem = updatedUser.organizations.find(
                    (organizationId) => organizationId.id === waitingOrganizationId
                )
                if (organizationItem) {
                    setNewOrganizationId(organizationItem.id)
                    setWaitingOrganizationId(undefined)
                    finished.current = true
                    if (newOrganizationCallback.current) {
                        newOrganizationCallback.current(organizationItem.id)
                    }
                    dispatch(accountActions.fetchUserSuccess(updatedUser))
                } else if (!finished.current) {
                    if (timeout.current) {
                        window.clearTimeout(timeout.current)
                    }
                    timeout.current = window.setTimeout(fetchUser, pollingFrequency)
                }
            }
            if (timeout.current) {
                window.clearTimeout(timeout.current)
            }
            timeout.current = window.setTimeout(fetchUser, pollingFrequency)
        }
    }, [userOrganizationsIds, setNewOrganizationId, waitingOrganizationId, setWaitingOrganizationId, dispatch])

    return {
        newOrganizationId,
        waitForNewOrganization: waitForNewOrganization,
    }
}

export const useFoundOrganization = (user: UserI) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>()
    const { waitForNewOrganization } = usePoolUserForNewOrganization(user)
    const dispatch = useAppDispatch()

    const foundOrganization = useCallback(
        async (organizationName: string, countryCode: CountryCode, identifier: string) => {
            setLoading(true)
            try {
                const { id: organizationId, membershipRequested } = await organizationApi.foundOrganization(
                    organizationName,
                    countryCode,
                    identifier
                )
                let organization: OrganizationI
                if (!membershipRequested) {
                    await waitForNewOrganization(organizationId)
                    organization = await organizationApi.getOrganizationById(organizationId)
                    dispatch(organizationActions.foundOrganization(organization))
                } else {
                    organization = await organizationApi.getOrganizationById(organizationId)
                }
                setLoading(false)

                return {
                    organization,
                    membershipRequested,
                }
            } catch (e) {
                setLoading(false)
                setError(`${e}`)
                throw e
            }
        },
        [dispatch, setError, setLoading]
    )

    return {
        loading,
        error,
        foundOrganization,
    }
}
