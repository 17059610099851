import { Stack } from "@mui/material"
import React, { useCallback } from "react"
import { useIntl } from "react-intl"

import { ProgressBarItem } from "~/components"
import { TransactionBudgetGaugeByCurrency } from "~/domains/transactions/components/TransactionInBudgets/TransactionBudgetGaugeByCurrency"
import {
    sortProgressBarItemByColor,
    useLineItemsByStatusComparedToBudget,
} from "~/domains/transactions/purchase-orders/hooks"
import { PurchaseOrders } from "~/domains/transactions/purchase-orders/types/PurchaseOrders"
import { PurchaseRequestDetails } from "~/domains/transactions/purchase-requests/types/PurchaseRequests"
import { CurrencyCodes } from "~/types"

interface TransactionBudgetGaugeProps {
    transaction: PurchaseOrders | PurchaseRequestDetails
}

export const TransactionBudgetGauge: React.FC<TransactionBudgetGaugeProps> = ({ transaction }) => {
    const { formatNumber } = useIntl()
    const currency = transaction.currency

    const { groupedItemsByStatus, lineItemsByStatusAndCurrencyValuesWithoutRejected } =
        useLineItemsByStatusComparedToBudget(transaction)

    const totalAmountsFormatted = formatNumber(transaction.totalAmount ?? 0, {
        style: "currency",
        currency,
        currencyDisplay: "narrowSymbol",
    })

    if (!transaction.budgets || transaction.budgets?.length === 0) {
        return null
    }

    const renderBudgetByCurrency = useCallback(
        ([currency, currencyValues]: [string, Record<string, ProgressBarItem>]) => {
            const budget = transaction.budgets?.find((budget) => budget.budget.currency === currency)
            const values = Object.values(currencyValues).sort(sortProgressBarItemByColor)
            return (
                <TransactionBudgetGaugeByCurrency
                    totalAmountsFormatted={totalAmountsFormatted}
                    currency={currency as CurrencyCodes}
                    budget={budget}
                    transactionItems={values}
                    notRejectedTransactionItems={lineItemsByStatusAndCurrencyValuesWithoutRejected}
                    showDetails={false}
                />
            )
        },
        [transaction.budgets, lineItemsByStatusAndCurrencyValuesWithoutRejected, totalAmountsFormatted]
    )

    return (
        <Stack gap={2} className="mt-12">
            {Object.entries(groupedItemsByStatus).map(renderBudgetByCurrency)}
        </Stack>
    )
}
