import { withSocketIOProvider } from "~/domains/transactions/common/subscriptions/components/SocketIOContext"
import { InvoiceI } from "~/types"

interface InvoiceCommunicationProps extends JSX.IntrinsicAttributes {
    invoice?: InvoiceI
}
/* src/domains/communication/hooks/useRoomForObjectId.ts communicationApi.createRoom loops */
/* <CommunicationRoom
            organizationId={invoice.payerId}
            objectId={invoice.id}
            objectType={SharedObjectType.Invoice}
            otherOrganizations={[invoice.sellerId]}
        /> */
export const InvoiceCommunication = withSocketIOProvider<InvoiceCommunicationProps>(({ invoice }) => {
    return !invoice ? null : null
})
