import { TextField, TextFieldProps } from "@mui/material"
import React, { useCallback, useEffect, useRef, useState } from "react"

type Props = Omit<Omit<TextFieldProps, "onChange">, "value"> & {
    onChange: (value: number | null) => void
    value: number | null
}

const NUMBER_REGEX = /^[\d]*[,.]?[\d]*$/

export const FloatInput: React.FC<Props> = ({ onChange, value, ...props }) => {
    const [inputValue, setInputValue] = useState<string>("")
    const ignoreNextChange = useRef<boolean>(false)

    useEffect(() => {
        if (!ignoreNextChange.current && value !== null) {
            setInputValue(value.toLocaleString())
        }
        ignoreNextChange.current = false
    }, [value])

    const onInputChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            e.preventDefault()
            if (NUMBER_REGEX.test(e.target.value)) {
                setInputValue(e.target.value)
                const parsed = parseFloat(e.target.value.replaceAll(",", "."))
                if (!isNaN(parsed)) {
                    ignoreNextChange.current = true
                    onChange(parsed)
                }
            }
        },
        [onChange]
    )
    return <TextField inputMode="numeric" {...props} onChange={onInputChange} value={inputValue} />
}
