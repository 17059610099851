import React from "react"

import EdfSmall from "~/assets/images/edf-small.svg"
import Edf from "~/assets/images/edf.svg"
import CndgSmall from "~/assets/images/logo-CNDG-small.webp"
import Cndg from "~/assets/images/logo-CNDG.webp"
import CpfaSmall from "~/assets/images/logo-CPFA-small.webp"
import Cpfa from "~/assets/images/logo-CPFA.webp"
import GhdcSmall from "~/assets/images/logo-GHDC-small.webp"
import Ghdc from "~/assets/images/logo-GHDC.webp"
import HeloraSmall from "~/assets/images/logo-HELORA-small.webp"
import Helora from "~/assets/images/logo-HELORA.webp"
import StVithSmall from "~/assets/images/logo-ST-VITH-small.webp"
import StVith from "~/assets/images/logo-ST-VITH.webp"
import LogoArhm from "~/assets/images/logo-arhm.webp"
import logoBnpSmall from "~/assets/images/logo-bnp-small.svg"
import logoBnp from "~/assets/images/logo-bnp.svg"
import CoveaSmall from "~/assets/images/logo-covea-small.webp"
import Covea from "~/assets/images/logo-covea.webp"
import EiffageSmall from "~/assets/images/logo-eiffage-small.svg"
import Eiffage from "~/assets/images/logo-eiffage.svg"
import Seshe from "~/assets/images/logo-seche.webp"
import SesheSmall from "~/assets/images/logo-seshe-small.jpeg"
import logoStellantisSmall from "~/assets/images/logo-stellantis-small.png"
import logoStellantis from "~/assets/images/logo-stellantis.svg"
import MobilisSmall from "~/assets/images/mobilis-small.png"
import Mobilis from "~/assets/images/mobilis.webp"
import Ncc from "~/assets/images/ncc.svg"
import Ucpa from "~/assets/images/ucpa.png"
import VeoliaSmall from "~/assets/images/veolia-small.svg"
import Veolia from "~/assets/images/veolia.webp"
import VivisolSmall from "~/assets/images/vivisol-small.webp"
import Vivisol from "~/assets/images/vivisol.webp"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganization } from "~/store/organization/organizationSlice"

interface Props extends React.SVGProps<SVGSVGElement> {
    size?: number
    iconOnly?: boolean
}

const companyLogoConfig = {
    "664fb0bd62a6d65e3a13b511": { large: Edf, small: EdfSmall },
    "6668d1c72ba07a41e2467ff0": { large: Vivisol, small: VivisolSmall },
    "666b17a3ea11870c7b2d34b1": { large: Veolia, small: VeoliaSmall },
    "667378ce4d459a2ad69b16d8": { large: Eiffage, small: EiffageSmall },
    "66749bed4d459a2ad69b16ec": { large: Mobilis, small: MobilisSmall },
    "667d6850c09bac6e89487f32": { large: Seshe, small: SesheSmall },
    "668dde162195b44a5119c484": { large: Covea, small: CoveaSmall },
    "66d60836c64c6a1546dfc928": { large: Cndg, small: CndgSmall },
    "66d96aaac64c6a1546dfc938": { large: Cpfa, small: CpfaSmall },
    "66d96224c64c6a1546dfc936": { large: Ghdc, small: GhdcSmall },
    "65ca1bdac70e2c64a7bd73c8": { large: Helora, small: HeloraSmall },
    "66d96d74c64c6a1546dfc93a": { large: StVith, small: StVithSmall },
    "671a5f422c738165af24a906": { large: Ncc, small: Ncc },
    "67287572bdc6ff7a9a787e17": { large: LogoArhm, small: LogoArhm },
    "6673ee7e4d459a2ad69b16dc": { large: Eiffage, small: EiffageSmall },
    "672b824fbdc6ff7a9a787ebb": { large: logoStellantis, small: logoStellantisSmall },
    "672b86d9bdc6ff7a9a787ebf": { large: logoBnp, small: logoBnpSmall },
}

const UPCAId = "66433572ebfa732fb3f5a59d"

const LogoFlowie = ({ iconOnly, size = 105, ...props }: Props) => {
    return (
        <svg
            width={iconOnly ? (151 * size) / 523 : size}
            height={(151 * size) / 523}
            viewBox={iconOnly ? "0 0 151 151" : "0 0 523 151"}
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M89.7 40.3347C78.9 40.3347 69.5667 38.5347 61.7 34.9347L55.3 67.3347H93.9C93.9 74.4014 92.1 80.2014 88.5 84.7347C85.0333 89.1347 80.1 91.3347 73.7 91.3347C66.7667 91.3347 60.1 90.0681 53.7 87.5347C52.3667 87.0014 51.6333 86.6681 51.5 86.5347L40 145.935H0L21.9 14.3347H111.1C111.1 22.4681 109.167 28.868 105.3 33.5347C101.567 38.068 96.3667 40.3347 89.7 40.3347Z"
                fill="#7B61FF"
            />
            {!iconOnly && (
                <>
                    <path
                        d="M144.5 145.935H98.4C98.4 135.935 100.655 105.001 105.322 83.1347L119.922 14.3347L160.566 14.3347C152 52.4993 130.5 71.8535 130.5 109.999C130.5 117.599 132 134.935 144.5 145.935Z"
                        fill="#7B61FF"
                    />
                    <path
                        d="M182.566 150.335C153.232 150.335 138.566 136.535 138.566 108.935C138.566 89.3347 143.966 73.2014 154.766 60.5347C166.232 47.068 181.899 40.3347 201.766 40.3347C216.166 40.3347 227.032 43.6681 234.366 50.3347C241.699 57.0014 245.366 67.3347 245.366 81.3347C245.366 102.668 239.632 119.535 228.166 131.935C216.966 144.201 201.766 150.335 182.566 150.335ZM187.766 63.3347C186.166 66.9347 184.699 71.4681 183.366 76.9347C182.166 82.2681 180.766 89.2014 179.166 97.7347C177.566 106.268 176.766 115.801 176.766 126.335C176.766 129.801 177.299 132.668 178.366 134.935C179.566 137.201 181.699 138.335 184.766 138.335C187.832 138.335 190.299 137.601 192.166 136.135C194.166 134.668 195.899 132.201 197.366 128.735C200.032 122.601 202.432 113.868 204.566 102.535C206.699 91.0681 207.832 82.8681 207.966 77.9347C208.232 73.0014 208.366 68.7347 208.366 65.1347C208.366 61.4014 207.832 58.3347 206.766 55.9347C205.699 53.5347 203.632 52.3347 200.566 52.3347C197.632 52.3347 195.166 53.2681 193.166 55.1347C191.166 57.0014 189.366 59.7347 187.766 63.3347Z"
                        fill="#7B61FF"
                    />
                    <path
                        d="M345.962 140.735C338.004 147.946 328.963 150.335 320.163 150.335C301.746 150.335 310.562 116.76 310.562 103.735C302.962 124.001 300.363 138.068 286.763 145.935C281.829 148.868 277.029 150.335 272.362 150.335C229.1 150.335 275 73 237.5 44.5347C258.222 29.1136 276.056 46.5771 278.562 69.1347C279.096 73.6681 279.363 78.3347 279.363 83.1347C279.363 90.3347 278.562 106.468 276.962 131.535C296.88 118.739 305.562 84.8856 305.562 56.1347C305.562 53.8681 305.229 51.5347 304.562 49.1347C304.029 46.7347 303.429 45.2014 302.763 44.5347C320.969 33.6106 329.089 52.32 330.763 68.3347C331.829 79.6681 332.363 88.7347 332.363 95.5347C332.363 170.751 354.5 6 421.492 15.9993L424.892 19.4988C363 21.4993 387.036 103.522 345.962 140.735Z"
                        fill="#7B61FF"
                    />
                    <path
                        d="M495.731 112.535C499.065 114.801 500.731 118.468 500.731 123.535C500.731 128.468 499.465 132.535 496.931 135.735C494.398 138.935 491.065 141.601 486.931 143.735C478.398 148.135 469.531 150.335 460.331 150.335C451.131 150.335 443.798 149.335 438.331 147.335C432.998 145.335 428.531 142.468 424.931 138.735C417.865 131.668 414.331 121.668 414.331 108.735C414.331 88.6014 419.798 72.4014 430.731 60.1347C442.465 46.9347 458.531 40.3347 478.931 40.3347C491.598 40.3347 501.065 43.0014 507.331 48.3347C511.998 52.3347 514.331 57.6014 514.331 64.1347C514.331 87.6014 494.065 99.3347 453.531 99.3347C452.998 102.801 452.731 106.001 452.731 108.935C452.731 115.068 454.065 119.335 456.731 121.735C459.531 124.001 463.465 125.135 468.531 125.135C473.598 125.135 478.798 124.001 484.131 121.735C489.598 119.335 493.465 116.268 495.731 112.535ZM454.931 90.7347C464.398 90.7347 471.865 87.8014 477.331 81.9347C482.798 76.3347 485.531 69.0681 485.531 60.1347C485.531 57.0681 484.931 54.7347 483.731 53.1347C482.665 51.4014 480.998 50.5347 478.731 50.5347C476.465 50.5347 474.331 51.0014 472.331 51.9347C470.465 52.7347 468.531 54.6014 466.531 57.5347C461.598 64.2014 457.731 75.2681 454.931 90.7347Z"
                        fill="#7B61FF"
                    />
                    <path
                        d="M422.656 146.272H376.156C376.156 108.063 378.5 73.5 397.856 40.3347L438.5 40.3347C414.5 53.1347 389.665 117.24 422.656 146.272Z"
                        fill="#7B61FF"
                    />
                    <path
                        d="M407.149 11.0903C396.925 5.4292 404.781 2.18308 408.432 0.862652C420.035 -1.92334 440.378 14.0014 440.471 19.0002C440.62 26.9988 413.845 37.1574 409.216 34.7428C405.513 32.8117 409.968 26.3825 413.459 24.0036C416.95 21.6247 422.085 17.0103 422.085 17.0103L407.149 11.0903Z"
                        fill="#7B61FF"
                    />
                </>
            )}
        </svg>
    )
}

const LogoUcpa = ({ iconOnly }: { iconOnly?: boolean }) => {
    return !iconOnly ? (
        <img src={Ucpa} alt="UCPA" height={30} />
    ) : (
        <svg width="29" height="30" viewBox="0 0 117 123" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M85.5003 0V69.7C85.5003 90 79.9003 103.2 58.1003 103.2C36.3003 103.2 30.4003 90 30.4003 69.7V0H0.000326201V77C-0.0996738 110.1 22.8003 122.1 57.6003 122.1C91.4003 122.1 116.3 110.2 116.3 76.9L116.2 0H85.5003Z"
                fill="#E81D00"
            />
        </svg>
    )
}

const Logo = ({ orgId, iconOnly }: { orgId: string; iconOnly?: boolean }) => {
    const logo = companyLogoConfig[orgId]
    const logoSrc = iconOnly ? logo.small : logo.large

    return <img src={logoSrc} alt={orgId} />
}

export const SideBarCompanyLogo: React.FC<Props> = ({ size = 105, iconOnly }) => {
    const currentOrganization = useAppSelector(selectCurrentOrganization)
    const currentOrganizationId = currentOrganization?.id ?? ""

    return currentOrganizationId in companyLogoConfig ? (
        <Logo orgId={currentOrganizationId} iconOnly={iconOnly} />
    ) : currentOrganizationId === UPCAId ? (
        <LogoUcpa iconOnly={iconOnly} />
    ) : (
        <LogoFlowie size={size} iconOnly={iconOnly} />
    )
}
