import React from "react"

import { CustomTooltip, CustomTooltipTitle } from "~/features/invoice/components/InvoiceLines/CustomTooltip"
import { RenderQuantity } from "~/features/invoice/components/InvoiceLines/RenderQuantity"
import { TooltipContainer } from "~/features/invoice/components/InvoiceLines/StyledDiv"
import { InvoiceLineError } from "~/features/invoice/core"

interface Props {
    quantity: number
    onQuantityChange: (quantity: number | null) => void
    onErrorClick: (error: InvoiceLineError) => void
    quantityLabel: string
    error: InvoiceLineError | undefined
    readonly?: boolean
    fieldClassName?: string
    minwidth?: string
}

export const QuantityField: React.FC<Props> = ({
    quantity,
    onQuantityChange,
    onErrorClick,
    quantityLabel,
    error,
    readonly,
    fieldClassName,
    minwidth,
}) => {
    return (
        <>
            {error?.fields.includes("quantity") ? (
                <CustomTooltip
                    title={<CustomTooltipTitle error={error} onErrorClick={onErrorClick} />}
                    arrow
                    placement="top"
                >
                    <TooltipContainer>
                        <RenderQuantity
                            quantity={quantity}
                            onQuantityChange={onQuantityChange}
                            quantityLabel={quantityLabel}
                            error={error}
                            readonly={readonly}
                            fieldClassName={fieldClassName}
                            minwidth={minwidth}
                        />
                    </TooltipContainer>
                </CustomTooltip>
            ) : (
                <RenderQuantity
                    quantity={quantity}
                    onQuantityChange={onQuantityChange}
                    quantityLabel={quantityLabel}
                    error={null}
                    readonly={readonly}
                    fieldClassName={fieldClassName}
                    minwidth={minwidth}
                />
            )}
        </>
    )
}
