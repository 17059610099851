import { Box, Typography } from "@mui/material"
import React, { useCallback, useEffect } from "react"
import { defineMessages, FormattedMessage } from "react-intl"
import { Button, Modal } from "~/components"
import { authActions, selectIsConnected } from "~/store/auth/authSlice"
import { useAppDispatch, useAppSelector } from "~/store/hooks"

const messages = defineMessages({
    modalTitle: {
        id: "invoice.modal.signInToCreateOrganization",
        defaultMessage: "Sign in to create a company",
    },
    modalText: {
        id: "invoice.modal.signInToCreateOrganizationMessage",
        defaultMessage: "You must be signed in to create a new company",
    },
    signIn: {
        id: "invoice.modal.signIn",
        defaultMessage: "Sign in",
    },
    cancel: {
        id: "invoice.modal.cancel",
        defaultMessage: "Cancel",
    },
})

interface Props {
    open: boolean
    onClose: (isSignedIn: boolean) => void
}

export const SignInToCreateOrganizationModal: React.FC<Props> = ({ open, onClose }) => {
    const dispatch = useAppDispatch()
    const userIsConnected = useAppSelector(selectIsConnected)
    const onSignIn = useCallback(() => {
        dispatch(authActions.openPopupSignIn())
    }, [dispatch])

    const cancel = useCallback(() => onClose(false), [onClose])

    useEffect(() => {
        if (open && userIsConnected) {
            onClose(true)
        }
    }, [userIsConnected, onClose, open])

    return (
        <Modal open={open} aria-labelledby="modal-connect-organization" className="" disableEscapeKeyDown={true}>
            <Modal.Header>
                <Typography variant="h2" gutterBottom>
                    <FormattedMessage {...messages.modalTitle} />
                </Typography>
            </Modal.Header>
            <Modal.Content>
                <Box>
                    <Typography>
                        <FormattedMessage {...messages.modalText} />
                    </Typography>
                </Box>
            </Modal.Content>
            <Modal.Footer>
                <Button buttonType="button" onClick={cancel} type="neutral">
                    <FormattedMessage {...messages.cancel} />
                </Button>
                <Button buttonType="submit" onClick={onSignIn}>
                    <FormattedMessage {...messages.signIn} />
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
