import styled from "@emotion/styled"
import React, { useState } from "react"
import { Play } from "react-feather"
import { FormattedMessage, useIntl } from "react-intl"
import { generatePath, useNavigate } from "react-router-dom"

import { HeaderH1 } from "~/components/Header/HeaderH1"
import { Button, useTitle } from "~/core"
import { useCreateFlowMutation } from "~/domains/orchestration/flows/api/flowsApi"
import { Filter } from "~/domains/orchestration/flows/components/Filter"
import { List } from "~/domains/orchestration/flows/components/List"
import { usePermission } from "~/domains/orchestration/flows/hooks/usePermission"
import { messages } from "~/domains/orchestration/flows/messages"
import { RUNS_LIST } from "~/domains/orchestration/flows/routes"
import type { CreateFlowQuery } from "~/domains/orchestration/flows/types"

const StyledViewSwitcher = styled.div`
    display: flex;
    margin-top: 100px;
`

const StyledContent = styled.div`
    margin-top: var(--spacing-lg);
`

export const FlowsList: React.FC = () => {
    const navigate = useNavigate()
    const { formatMessage } = useIntl()
    const pageName = formatMessage(messages.page.title)
    const [filter, setFilter] = useState("")

    const [trigger] = useCreateFlowMutation()
    const { hasWorkflowCreatePermission, permissionError } = usePermission()

    useTitle(pageName)

    const createFlowCallback = (query: CreateFlowQuery) => {
        trigger(query)
    }

    const filterChangeCallback = (filter: string) => {
        setFilter(filter)
    }

    const handleViewChange = () => {
        const path = generatePath(RUNS_LIST)
        navigate(path)
    }

    return (
        <>
            <HeaderH1 title={pageName} icon={<Play />} />
            <StyledViewSwitcher className="view-switcher">
                <Button type="tab" className={"selected"}>
                    {formatMessage(messages.page.flowsTab)}
                </Button>
                <Button type="tab" onClick={handleViewChange}>
                    {formatMessage(messages.page.runsTab)}
                </Button>
            </StyledViewSwitcher>

            <StyledContent>
                {!permissionError ? (
                    <>
                        <Filter
                            hasCreatePermission={hasWorkflowCreatePermission}
                            filterChangeCallback={filterChangeCallback}
                            filter={filter}
                            createCallback={createFlowCallback}
                        />
                        <List filter={filter} />
                    </>
                ) : (
                    <FormattedMessage tagName="p" {...messages.page.noOrganization} />
                )}
            </StyledContent>
        </>
    )
}
