import TextField, { TextFieldProps } from "@mui/material/TextField"
import React from "react"

import { StyledTextField } from "~/domains/identity/features/organizations/components/ModalOrganizationSelectorDetails/StyledTextField"

export enum ComponentType {
    STYLED = "styled",
    DEFAULT = "default",
}

type TrimmedTextFieldProps = TextFieldProps & {
    componentType?: ComponentType
    size?: "small" | "medium"
}

const TrimmedTextField: React.FC<TrimmedTextFieldProps> = ({ componentType = ComponentType.STYLED, ...props }) => {
    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const { value } = event.target
        const trimmedValue = value.trim()

        const newBlurEvent = {
            ...event,
            target: { ...event.target, value: trimmedValue },
        }

        props.onBlur?.(newBlurEvent)

        const newChangeEvent = {
            ...event,
            target: { ...event.target, value: trimmedValue },
        }

        props.onChange?.(newChangeEvent)
    }

    const Component = componentType === ComponentType.STYLED ? StyledTextField : TextField

    return <Component {...props} onBlur={handleBlur} />
}

export default TrimmedTextField
