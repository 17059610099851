import React, { ChangeEvent, FormEvent, useCallback, useEffect, useState } from "react"
import { defineMessages, useIntl } from "react-intl"

import { Button, Modal, Size } from "~/components"
import TrimmedTextField from "~/components/Form/TrimmedTextField"
import { StyledTextField } from "~/domains/identity/features/organizations/components/ModalOrganizationSelectorDetails/StyledTextField"
import { CreatePartnerProfileContactI, PartnerProfileContactI } from "~/domains/transactions/book-of-relations/types"

const messages = defineMessages({
    titleNew: { id: "partners.bookofrelations.modalContact.titleNew", defaultMessage: "New contact" },
    titleUpdate: { id: "partners.bookofrelations.modalContact.titleUpdate", defaultMessage: "Update contact" },
    lastName: { id: "partners.bookofrelations.contact.lastName", defaultMessage: "Last name" },
    firstName: { id: "partners.bookofrelations.contact.firstName", defaultMessage: "First name" },
    email: { id: "partners.bookofrelations.contact.email", defaultMessage: "Email" },
    phoneNumber: { id: "partners.bookofrelations.contact.phoneNumber", defaultMessage: "Phone number" },
    position: { id: "partners.bookofrelations.contact.position", defaultMessage: "Position" },
    language: { id: "partners.bookofrelations.contact.language", defaultMessage: "Language" },
    timezone: { id: "partners.bookofrelations.contact.timezone", defaultMessage: "Timezone" },
    save: { id: "partners.bookofrelations.modalContact.save", defaultMessage: "Save" },
    cancel: { id: "partners.bookofrelations.modalContact.cancel", defaultMessage: "Cancel" },
})

interface Props {
    isNew: boolean
    contact: CreatePartnerProfileContactI | PartnerProfileContactI | null
    save: (payload: CreatePartnerProfileContactI | PartnerProfileContactI) => void
    open: boolean
    close: () => void
}

export const ModalContact: React.FC<Props> = ({ isNew, contact, save, open, close }) => {
    const { formatMessage } = useIntl()
    const [lastName, setLastName] = useState<string | null>(null)
    const [firstName, setFirstName] = useState<string | null>(null)
    const [email, setEmail] = useState<string | null>(null)
    const [phoneNumber, setPhoneNumber] = useState<string | null>(null)
    const [position, setPosition] = useState<string | null>(null)
    const [language, setLanguage] = useState<string | null>(null)
    const [timezone, setTimezone] = useState<string | null>(null)

    useEffect(() => {
        setLastName(contact?.lastName ?? null)
        setFirstName(contact?.firstName ?? null)
        setEmail(contact?.email ?? null)
        setPhoneNumber(contact?.phoneNumber ?? null)
        setPosition(contact?.position ?? null)
        setLanguage(contact?.language ?? null)
        setTimezone(contact?.timezone ?? null)
    }, [contact])

    const onLastNameChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            setLastName(e.currentTarget.value)
        },
        [setLastName]
    )

    const onFirstNameChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            setFirstName(e.currentTarget.value)
        },
        [setFirstName]
    )

    const onEmailChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            setEmail(e.currentTarget.value)
        },
        [setEmail]
    )

    const onPhoneNumberChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            setPhoneNumber(e.currentTarget.value)
        },
        [setPhoneNumber]
    )

    const onPositionChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            setPosition(e.currentTarget.value)
        },
        [setPosition]
    )

    const onLanguageChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            setLanguage(e.currentTarget.value)
        },
        [setLanguage]
    )

    const onTimezoneChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            setTimezone(e.currentTarget.value)
        },
        [setTimezone]
    )

    const onSubmit = useCallback(
        (e: FormEvent<HTMLFormElement>) => {
            e.preventDefault()
            e.stopPropagation()
            const contactToSave = { lastName, firstName, email, phoneNumber, position, language, timezone }
            if (isNew) {
                save(contactToSave)
            } else {
                save({ ...contact, ...contactToSave })
            }
            close()
        },
        [lastName, firstName, email, phoneNumber, position, language, timezone]
    )

    return (
        <Modal open={open} className={"modal-add-items"} size={Size.MD}>
            <Modal.Header>
                <h4>{formatMessage(isNew ? messages.titleNew : messages.titleUpdate)}</h4>
            </Modal.Header>
            <form onSubmit={onSubmit}>
                <Modal.Content>
                    <StyledTextField
                        name="contact.lastName"
                        className="mb-12"
                        label={formatMessage(messages.lastName)}
                        placeholder={formatMessage(messages.lastName)}
                        onChange={onLastNameChange}
                        value={lastName ?? ""}
                        fullWidth
                    />
                    <StyledTextField
                        name="contact.firstName"
                        className="mb-12"
                        label={formatMessage(messages.firstName)}
                        placeholder={formatMessage(messages.firstName)}
                        onChange={onFirstNameChange}
                        value={firstName ?? ""}
                        fullWidth
                    />
                    <TrimmedTextField
                        id="contact.email"
                        name="contact.email"
                        className="mb-12"
                        label={formatMessage(messages.email)}
                        placeholder={formatMessage(messages.email)}
                        onChange={onEmailChange}
                        value={email ?? ""}
                        fullWidth
                        type="email"
                    />
                    <StyledTextField
                        id="contact.phoneNumber"
                        name="contact.phoneNumber"
                        className="mb-12"
                        label={formatMessage(messages.phoneNumber)}
                        placeholder={formatMessage(messages.phoneNumber)}
                        onChange={onPhoneNumberChange}
                        value={phoneNumber ?? ""}
                        fullWidth
                    />
                    <StyledTextField
                        name="contact.position"
                        className="mb-12"
                        label={formatMessage(messages.position)}
                        placeholder={formatMessage(messages.position)}
                        onChange={onPositionChange}
                        value={position ?? ""}
                        fullWidth
                    />
                    <StyledTextField
                        name="contact.language"
                        className="mb-12"
                        label={formatMessage(messages.language)}
                        placeholder={formatMessage(messages.language)}
                        onChange={onLanguageChange}
                        value={language ?? ""}
                        fullWidth
                    />
                    <StyledTextField
                        name="contact.timezone"
                        className="mb-12"
                        label={formatMessage(messages.timezone)}
                        placeholder={formatMessage(messages.timezone)}
                        onChange={onTimezoneChange}
                        value={timezone ?? ""}
                        fullWidth
                    />
                </Modal.Content>
                <Modal.Footer>
                    <Button type="neutral" buttonType="button" onClick={close}>
                        {formatMessage(messages.cancel)}
                    </Button>
                    <Button type="primary" buttonType="submit">
                        {formatMessage(messages.save)}
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}
