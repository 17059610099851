import dayjs from "dayjs"
import React from "react"
import { FormattedDate, FormattedTime } from "react-intl"

interface Props {
    value: string | null | undefined
    timeLocale?: string
    className?: string
}

export const toISOStringWithFixedOffset = (date: string) => {
    return dayjs(date).add(dayjs(date).utcOffset(), "minute").toISOString()
}

const isValidDate = (date: string): boolean => {
    const parsedDate = Date.parse(date)
    return !isNaN(parsedDate)
}

export const DateComponent: React.FC<Props> = ({ value: date, timeLocale, className }) => {
    if (!date || !isValidDate(date)) {
        return <span>-</span>
    }
    if (timeLocale) {
        return (
            <span className={className}>
                <FormattedDate value={date} day="2-digit" month="short" year="numeric" />
                {" - "}
                <FormattedTime value={date} hourCycle={timeLocale === "fr" ? "h24" : "h12"} />
            </span>
        )
    }
    return (
        <span className={className}>
            <FormattedDate value={date} day="2-digit" month="short" year="numeric" />
        </span>
    )
}
