import Grid from "@mui/material/Grid"
import React, { useCallback, useMemo, useState } from "react"
import { defineMessages, useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"

import { Card } from "~/components"
import { Documents, DraftDocumentI } from "~/components/UploadDocument/Documents"
import { DocumentObjectType } from "~/domains/identity/features/documents/types/UploadDocumentDto"
import { TagsSelector } from "~/domains/tags/components/TagsSelector"
import { TagObjectType } from "~/domains/tags/types"
import "~/domains/transactions/assets/css/Purchases.scss"
import { ModalAddToBudget } from "~/domains/transactions/components/ModalAddToBudget/ModalAddToBudget"
import { TotalAmount } from "~/domains/transactions/components/TotalAmount/TotalAmount"
import { TransactionInBudgets } from "~/domains/transactions/components/TransactionInBudgets"
import { PURCHASE_REQUESTS_ROUTE } from "~/domains/transactions/purchase-requests//routes"
import { useFetchPurchaseRequest } from "~/domains/transactions/purchase-requests//store/hooks"
import { purchaseRequestApi } from "~/domains/transactions/purchase-requests/api"
import { Delete } from "~/domains/transactions/purchase-requests/components/Actions/Delete"
import { PurchaseOrderLink } from "~/domains/transactions/purchase-requests/components/PurchaseOrderLink/PurchaseOrderLink"
import { PurchaseRequestInfos } from "~/domains/transactions/purchase-requests/components/PurchaseRequestInfos/PurchaseRequestInfos"
import { TagsForCreatingPurchaseRequest } from "~/domains/transactions/purchase-requests/components/TagsForCreatingPurchaseRequest"
import { PurchaseRequestDetails } from "~/domains/transactions/purchase-requests/types/PurchaseRequests"
import { PurchaseViewType } from "~/domains/transactions/types"
import { AddTransactionPayloadI, TransactionType } from "~/features/budget/types"
import { useFetchBudgetsData } from "~/store/budget/hooks"
import { useAppDispatch } from "~/store/hooks"

import { ActionsColumn } from "../Actions/ActionsColumn"

const messages = defineMessages({
    taxLabel: { id: "purchase.requests.request.taxLabel", defaultMessage: "Tax excl." },
    purchaseOrder: { id: "purchase.requests.request.purchaseOrder", defaultMessage: "Purchase order" },
    tagsTitle: { id: "purchase.requests.request.tagsTitle", defaultMessage: "Tags" },
})

interface Props {
    PR: PurchaseRequestDetails
    newPR: boolean | undefined
    pageMode: PurchaseViewType
    organization: { id: string }
    user: { id: string }
    draftDocuments: DraftDocumentI[]
    setDraftDocuments: React.Dispatch<React.SetStateAction<DraftDocumentI[]>>
    updatePR: (showToast?: boolean) => Promise<PurchaseRequestDetails>
}

export const PurchaseRequestLeftColumn: React.FC<Props> = ({
    PR,
    newPR,
    pageMode,
    organization,
    user,
    draftDocuments,
    setDraftDocuments,
    updatePR,
}) => {
    const [modalAddToBudgetVisible, setModalAddToBudgetVisible] = useState<boolean>(false)

    const { formatMessage } = useIntl()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const { fetchPurchaseRequest } = useFetchPurchaseRequest(organization.id)

    const { budgetsData } = useFetchBudgetsData(organization.id, false)
    const transactionPayload: AddTransactionPayloadI = {
        transactionRefId: PR.id,
        transactionStatus: PR.status,
        transactionType: TransactionType.PURCHASE_REQUEST,
        supplierOrgId: PR.supplierId,
        buyerOrgId: organization.id,
        description: PR.description,
        amount: PR.totalAmount ?? 0,
        amountWithoutTaxes: PR.totalAmountExcludingTax,
        amountRemainingToPay: 0,
        expectedDeliveryDate: PR.expectedDeliveryDate,
        partialAmount: 0,
        partialRate: 0,
    }

    const showModalAddToBudget = useCallback(() => setModalAddToBudgetVisible(true), [])
    const hideModalAddToBudget = useCallback(() => setModalAddToBudgetVisible(false), [])

    const handleSuccess = useCallback(async () => {
        fetchPurchaseRequest(PR.id)
    }, [dispatch, PR])

    const tagSelectorContext = useMemo(
        () => ({
            page: "purchase-request",
            purchaseRequest: PR,
            organization,
            user,
        }),
        [PR, organization, user]
    )

    const canDelete = useMemo(() => {
        return !PR.purchaseOrder
    }, [PR.purchaseOrder])
    const handleDelete = useCallback(async () => {
        try {
            await purchaseRequestApi.delete(PR.organizationId, PR.id)
            navigate(PURCHASE_REQUESTS_ROUTE)
        } catch (e) {
            console.error(e)
        }
    }, [purchaseRequestApi.delete, PR.organizationId, PR.id, navigate])

    return (
        <>
            <Grid item xs className="column pr-column pr-column-left">
                <TotalAmount
                    amount={PR.totalAmountExcludingTax}
                    currency={PR.currency}
                    taxLabel={formatMessage(messages.taxLabel)}
                />
                <PurchaseRequestInfos
                    PR={PR}
                    newPR={newPR}
                    updatePR={updatePR}
                    pageMode={pageMode}
                    organizationId={PR.organizationId}
                />
                {PR.purchaseOrder && (
                    <PurchaseOrderLink purchaseOrder={PR.purchaseOrder} label={formatMessage(messages.purchaseOrder)} />
                )}
                {PR.id && <TransactionInBudgets transaction={PR} showModalAddToBudget={showModalAddToBudget} withGauge />}
                {!newPR ? (
                    <>
                        <Card title={formatMessage(messages.tagsTitle)} expandable>
                            <TagsSelector
                                organizationId={organization.id}
                                objectId={PR.id}
                                userId={user.id}
                                objectType={TagObjectType.PURCHASE_REQUEST}
                                context={tagSelectorContext}
                            />
                        </Card>
                        <Documents
                            objectId={PR.id}
                            objectType={DocumentObjectType.PURCHASE_REQUEST}
                            organizationId={organization.id}
                        />
                    </>
                ) : (
                    <>
                        <Card title={formatMessage(messages.tagsTitle)} expandable>
                            <TagsForCreatingPurchaseRequest organizationId={organization.id} />
                        </Card>
                        <Documents
                            organizationId={organization.id}
                            objectType={DocumentObjectType.PURCHASE_REQUEST}
                            draftDocuments={draftDocuments}
                            setDraftDocuments={setDraftDocuments}
                        />
                    </>
                )}
                {pageMode === PurchaseViewType.VIEW && !PR.purchaseOrder ? <ActionsColumn PR={PR} /> : null}
                {canDelete ? <Delete hasPermission={PR.permissions?.delete} handleDelete={handleDelete} /> : null}
            </Grid>
            <ModalAddToBudget
                open={modalAddToBudgetVisible}
                close={hideModalAddToBudget}
                organizationId={organization.id}
                budgets={budgetsData}
                transaction={PR}
                transactionPayload={transactionPayload}
                handleSuccess={handleSuccess}
            />
        </>
    )
}
