import { styled } from "@mui/material"
import { Link } from "react-router-dom"

export const StyledLink = styled(Link)({
    display: "block",
    color: "var(--primary-color)",
    "&:visited": {
        color: "var(--primary-color)",
    },
    "&:hover": {
        textDecoration: "underline",
        color: "var(--primary-color)",
    },
})
