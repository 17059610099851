import { InvoiceLineI } from "~/types"
import { VatRateI } from "~/types/VatRate"
import { roundMoney } from "./computeInvoiceLinesTotals"

export const tryComputeInvoiceLinesTaxes = (
    invoiceLines: InvoiceLineI[],
    vatRates: VatRateI[],
    invoiceTotal: number
): VatRateI | null => {
    let matchingRate: VatRateI | null = null,
        indexRates: number = 0
    while (matchingRate === null && vatRates[indexRates]) {
        const vatRate = vatRates[indexRates]
        const sumLines = invoiceLines.reduce((acc, line) => {
            return acc + roundMoney((line.totalExcludedTaxes * (100 + vatRate.rate)) / 100)
        }, 0)

        if (sumLines === invoiceTotal) {
            matchingRate = vatRate
        }

        indexRates++
    }

    return matchingRate
}
