import { XYPosition } from "@xyflow/react"

import { Flow, FlowNode, Node, NodeType, ObjectType } from "../types"

/**
 *
 * @param flow
 * @returns Flow
 * @description bump the version of the flow
 */
const bumpFlow = (flow: Flow): Flow => ({ ...flow, version: flow.version + 1 })

/**
 *
 * @param nodeType
 * @param currentNodes
 * @returns string
 * @description generate a readable id for a node
 */
const generateReadableId = (nodeType: NodeType, currentNodes: FlowNode[]): string => {
    const nodeTypeMapping: Record<NodeType, string> = {
        [NodeType.EVENT_TRIGGER_NODE]: "event_trigger",
        [NodeType.IF_NODE]: "if",
        [NodeType.SET_PARTNERSHIP_FIELD_NODE]: "set_partnership_field",
        [NodeType.CHECK_NODE]: "check",
        [NodeType.HTTP_NODE]: "http",
        [NodeType.SEND_EMAIL_NODE]: "send_email",
        [NodeType.ADD_TO_BUDGET_NODE]: "add_to_budget",
        [NodeType.INVOICE_TO_PURCHASE_ORDER_MATCH_NODE]: "invoice_to_purchase_order_match",
        [NodeType.SET_INVOICE_LIFECYCLE_STATUS_NODE]: "set_invoice_lifecycle_status",
        [NodeType.FITS_TO_BUDGET_NODE]: "fits_to_budget",
    }

    const baseId = nodeTypeMapping[nodeType]
    if (!baseId) {
        throw new Error(`Unknown node type: ${nodeType}`)
    }

    const existingNodes = currentNodes.filter((node) => node.type === nodeType)

    const baseIdFilter = (id: string) => (node: FlowNode) => node.slug.startsWith(id)
    const isSameBaseId = baseIdFilter(baseId)

    const getIdCountFromNode = (node: FlowNode) => {
        const match = node.slug.match(new RegExp(`${baseId}_(\\d+)$`))
        return match ? parseInt(match[1], 10) : 0
    }

    const existingIds = existingNodes.filter(isSameBaseId).map(getIdCountFromNode)

    const maxNumber = Math.max(0, ...existingIds)
    return `${baseId}_${maxNumber + 1}`
}

const createEventTriggerNode = () => ({
    objectType: null,
    objectEvent: null,
    nextNode: null,
    filter: {
        conditions: [],
    },
})

const createIfNode = () => ({
    condition: ``,
})

const createSetPartnershipFieldNode = () => ({
    partnershipId: `{{ trigger_event.id }}`,
    fieldToUpdate: ``,
    valueToSet: ``,
})

const createCheckNode = () => ({
    objectId: `{{ trigger_event.id }}`,
    objectType: ObjectType.ORGANIZATION_RELATIONSHIP,
    reviewers: [],
    passThreshold: 1,
    refuseThreshold: 1,
})

const createSendEmailNode = () => ({
    subject: ``,
    recipientAddresses: [],
    body: ``,
})

const createAddToBudgetNode = () => ({
    amount: ` `,
    budgetId: ` `,
    transactionId: ` `,
    failIfOverbudget: false,
    transactionType: null,
    nextNode: null,
    currency: ``,
})

const createInvoiceToPurchaseOrderMatchNode = () => ({
    invoiceId: `{{ trigger_event.id }}`,
})

const createSetInvoiceLifecycleStatusNode = () => ({
    invoiceId: `{{ trigger_event.id }}`,
    statusToSet: ``,
})

const createFitsToBudgetNode = () => ({
    budgetId: `{{ trigger_event.id }}`,
    transactionId: ``,
    transactionType: ``,
    amount: ``,
    currency: ``,
})

const createCommonNodeProperties = (slug: string, position: XYPosition): Node => ({
    name: "",
    slug,
    metadata: {
        position,
    },
})

const createNode = (type: NodeType, slug: string, position: XYPosition): FlowNode | null => {
    switch (type) {
        case NodeType.EVENT_TRIGGER_NODE:
            return {
                ...createEventTriggerNode(),
                ...createCommonNodeProperties(slug, position),
                type,
            }

        case NodeType.IF_NODE:
            return {
                ...createIfNode(),
                ...createCommonNodeProperties(slug, position),
                nextIfSuccess: null,
                nextIfFailure: null,
                type,
            }

        case NodeType.SET_PARTNERSHIP_FIELD_NODE:
            return {
                ...createSetPartnershipFieldNode(),
                ...createCommonNodeProperties(slug, position),
                nextNode: null,
                type,
            }
        case NodeType.CHECK_NODE:
            return {
                ...createCheckNode(),
                ...createCommonNodeProperties(slug, position),
                nextIfSuccess: null,
                nextIfFailure: null,
                type,
            }

        case NodeType.SEND_EMAIL_NODE:
            return {
                ...createSendEmailNode(),
                ...createCommonNodeProperties(slug, position),
                nextNode: null,
                type,
            }

        case NodeType.ADD_TO_BUDGET_NODE:
            return {
                ...createAddToBudgetNode(),
                ...createCommonNodeProperties(slug, position),
                nextNode: null,
                type,
            }

        case NodeType.INVOICE_TO_PURCHASE_ORDER_MATCH_NODE:
            return {
                ...createInvoiceToPurchaseOrderMatchNode(),
                ...createCommonNodeProperties(slug, position),
                nextIfSuccess: null,
                nextIfFailure: null,
                type,
            }

        case NodeType.SET_INVOICE_LIFECYCLE_STATUS_NODE:
            return {
                ...createSetInvoiceLifecycleStatusNode(),
                ...createCommonNodeProperties(slug, position),
                nextNode: null,
                type,
            }

        case NodeType.FITS_TO_BUDGET_NODE:
            return {
                ...createFitsToBudgetNode(),
                ...createCommonNodeProperties(slug, position),
                nextIfSuccess: null,
                nextIfFailure: null,
                type,
            }

        default:
            return null
    }
}

export { bumpFlow, createNode, generateReadableId }
