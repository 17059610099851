import { Stack, TextField } from "@mui/material"
import cls from "classnames"
import React, { ChangeEvent, FC, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"

import { messages } from "~/domains/orchestration/flows/messages"
import { IfNode } from "~/domains/orchestration/flows/types"

import { ConfigurationNode } from "./ConfigurationNode"

interface Props {
    selectedNode: IfNode
    unselectCallback: () => void
}

export const IfConfiguration: FC<Props> = ({ selectedNode, unselectCallback }) => {
    const { formatMessage } = useIntl()

    // Handle the current node
    const [currentNode, setCurrentNode] = useState(selectedNode)

    const handleChangeIfCondition = (e: ChangeEvent<HTMLInputElement>): void => {
        const condition = e.target.value
        setCurrentNode({
            ...currentNode,
            condition,
        })
    }

    const configurationNodeItemClassName = cls("flows-configurationNode-item", "flows-editor-sideBar-item")

    return (
        <ConfigurationNode configuredNode={currentNode} unselectCallback={unselectCallback}>
            <Stack gap={2} className={configurationNodeItemClassName}>
                <FormattedMessage tagName="h5" {...messages.ifConfiguration.title} />

                <TextField
                    label={formatMessage(messages.ifConfiguration.label)}
                    value={currentNode.condition}
                    size="small"
                    onChange={handleChangeIfCondition}
                />
            </Stack>
        </ConfigurationNode>
    )
}
