import { styled } from "@mui/material"
import * as Sentry from "@sentry/browser"
import { useEffect } from "react"
import { AlertTriangle, X } from "react-feather"
import { FormattedMessage, defineMessages, useIntl } from "react-intl"
import { batch } from "react-redux"
import { toast } from "react-toastify"

import { Button, Modal, Size } from "~/components"
import {
    useCreatePaymentMethodDetailsMutation,
    useUpdatePaymentMethodDetailsMutation,
} from "~/domains/payment/payment-method-details/api/paymentMethodDetailsApi"
import {
    BankTransferPaymentMethodForm,
    CardPaymentMethodForm,
} from "~/domains/payment/payment-method-details/components"
import {
    selectSelectedItem,
    selectSelectedPaymentMethod,
} from "~/domains/payment/payment-method-details/store/paymentMethodDetailsSlice"
import { paymentMethodDetailsActions } from "~/domains/payment/payment-method-details/store/paymentMethodDetailsSlice"
import {
    BankTransferFormState,
    CardFormState,
    CreatePaymentMethodDetails,
    DirectDebitFormState,
    FormState,
    PaymentMethodDetails,
} from "~/domains/payment/payment-method-details/types"
import { mapToSave } from "~/domains/payment/payment-method-details/utils/mapPaymentMethodDetails"
import { useGetPaymentMethodsQuery } from "~/domains/payment/payment-methods/api/paymentMethodsApi"
import { PaymentMethodsSelector } from "~/domains/payment/payment-methods/components"
import { PaymentMethod, PaymentMethodType } from "~/domains/payment/payment-methods/types"
import { useAppDispatch, useAppSelector } from "~/store/hooks"

import { DirectDebitPaymentMethodForm } from "./DirectDebitPaymentMethodForm"

const messages = defineMessages({
    addTitle: {
        id: "payment.paymentMethodDetailsManagement.addTitle",
        defaultMessage: "Add payment method",
    },
    editTitle: {
        id: "payment.paymentMethodDetailsManagement.editTitle",
        defaultMessage: "Edit payment method",
    },
    selectMessage: {
        id: "payment.paymentMethodDetailsManagement.selectMessage",
        defaultMessage: "Please select a payment method",
    },
    notSupportedMessage: {
        id: "payment.paymentMethodDetailsManagement.notSupportedMessage",
        defaultMessage: "This payment method is not supported yet",
    },
    successMessage: {
        id: "payment.paymentMethodDetailsManagement.successMessage",
        defaultMessage: "Payment method saved successfully",
    },
    errorMessage: {
        id: "payment.paymentMethodDetailsManagement.errorMessage",
        defaultMessage: "An error occurred while saving the payment method",
    },
    paymentMethod: {
        id: "payment.paymentMethodDetailsManagement.paymentMethod",
        defaultMessage: "Payment method",
    },
})

const ModalTitle = styled("div")({
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "30px",
    color: "var(--color-grey)",
})

const ModalContentWrapper = styled("div")({
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    ".MuiFormControl-root .MuiInputBase-root": {
        backgroundColor: "var(--color-bg-input)",
        "&:hover, &.Mui-focused": {
            backgroundColor: "var(--color-bg-input)",
        },
        ".MuiInputBase-input": {
            backgroundColor: "var(--color-bg-input)",
        },
    },
    ".MuiFormLabel-root.Mui-error": {
        color: "initial",
    },
})

const ModalContent = styled(Modal.Content)({
    "&.flowie-modal-content": {
        paddingTop: 0,
    },
})

const ModalMessage = styled("div")({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    gap: "30px",
    fontSize: "18px",
    lineHeight: "26px",
    color: "var(--color-light-grey)",
    textAlign: "center",
    padding: "50px 20px",
})

const supportedPaymentMethods = [
    PaymentMethodType.CARD,
    PaymentMethodType.BANK_TRANSFER,
    PaymentMethodType.DIRECT_DEBIT,
]

interface PaymentMethodDetailsManagementProps {
    open: boolean
    activeTab: PaymentMethodType
    organizationId: string
    onPaymentMethodDetailsCreated?: (data: PaymentMethodDetails) => void
    onClose: () => void
    onOpen?: () => void
}

export const PaymentMethodDetailsManagement = ({
    open,
    organizationId,
    activeTab,
    onPaymentMethodDetailsCreated,
    onClose,
    onOpen,
}: PaymentMethodDetailsManagementProps) => {
    const [createPaymentMethodDetails, { isLoading: createLoading }] = useCreatePaymentMethodDetailsMutation()
    const [updatePaymentMethodDetails, { isLoading: updateLoading }] = useUpdatePaymentMethodDetailsMutation()

    const { data: paymentMethods, isLoading: paymentMethodsLoading } = useGetPaymentMethodsQuery(null)
    const selectedPaymentMethodDetails = useAppSelector(selectSelectedItem)
    const selectedPaymentMethod = useAppSelector(selectSelectedPaymentMethod)
    const dispatch = useAppDispatch()
    const { formatMessage } = useIntl()
    const paymentMethodType = selectedPaymentMethod?.payment_method_type ?? null
    const paymentMethodBasedOnTab = paymentMethods?.items.find((item) => item.payment_method_type === activeTab)

    const handleClose = (_: React.SyntheticEvent, reason: "backdropClick" | "escapeKeyDown") => {
        if (reason && reason === "backdropClick") return
        clearStates()
    }

    const clearStates = () => {
        batch(() => {
            dispatch(paymentMethodDetailsActions.setSelectedItem(null))
            dispatch(paymentMethodDetailsActions.setSelectedPaymentMethod(null))
        })
        onClose()
    }

    const handleSave = async (values: FormState, resetForm: () => void) => {
        const paymentMethodDetailsId = selectedPaymentMethodDetails?.id
        const payload = mapToSave(
            {
                ...values,
                paymentMethodId: (selectedPaymentMethod?.id || paymentMethodBasedOnTab?.id) ?? null,
                paymentMethodType: selectedPaymentMethod?.payment_method_type ?? activeTab,
            },
            organizationId,
            paymentMethodDetailsId
        )

        try {
            if (paymentMethodDetailsId) {
                await updatePaymentMethodDetails(payload as PaymentMethodDetails).unwrap()
            } else {
                const newPaymentMethodDetails = await createPaymentMethodDetails(
                    payload as CreatePaymentMethodDetails
                ).unwrap()

                if (onPaymentMethodDetailsCreated) {
                    onPaymentMethodDetailsCreated(newPaymentMethodDetails)
                }
            }

            clearStates()
            resetForm()
            toast.success(formatMessage(messages.successMessage))
        } catch (error) {
            toast.error(formatMessage(messages.errorMessage))
            Sentry.captureException(error, {
                extra: {
                    organizationId,
                    payload,
                },
            })
        }
    }

    const handlePaymentMethodChange = (paymentMethod: PaymentMethod | null) => {
        dispatch(paymentMethodDetailsActions.setSelectedPaymentMethod(paymentMethod))
    }

    const handleCancel = (resetForm: () => void) => {
        clearStates()
        resetForm()
    }

    useEffect(() => {
        if (selectedPaymentMethodDetails && !open) {
            const paymentMethod =
                paymentMethods?.items.find((item) => item.id === selectedPaymentMethodDetails.paymentMethodId) ?? null

            dispatch(paymentMethodDetailsActions.setSelectedPaymentMethod(paymentMethod))
            if (onOpen) {
                onOpen()
            }
        }
    }, [selectedPaymentMethodDetails])

    return (
        <Modal size={Size.SM} open={open} onClose={handleClose}>
            <Modal.Header>
                <ModalTitle>
                    <FormattedMessage {...(selectedPaymentMethodDetails ? messages.editTitle : messages.addTitle)} />
                    <Button type="title" onClick={clearStates}>
                        <X size={24} color="var(--color-light-grey)" />
                    </Button>
                </ModalTitle>
            </Modal.Header>
            <ModalContent>
                <ModalContentWrapper>
                    <PaymentMethodsSelector
                        loading={paymentMethodsLoading}
                        items={paymentMethods?.items ?? []}
                        value={
                            selectedPaymentMethod?.id ||
                            selectedPaymentMethodDetails?.paymentMethodId ||
                            paymentMethodBasedOnTab?.id ||
                            null
                        }
                        disabled={updateLoading || createLoading || !!selectedPaymentMethodDetails}
                        label={formatMessage(messages.paymentMethod)}
                        onChange={handlePaymentMethodChange}
                    />
                    {(paymentMethodType || activeTab) === PaymentMethodType.CARD && (
                        <CardPaymentMethodForm
                            loading={updateLoading || createLoading}
                            selectedItem={selectedPaymentMethodDetails as CardFormState}
                            onSubmit={handleSave}
                            onCancel={handleCancel}
                        />
                    )}
                    {(paymentMethodType || activeTab) === PaymentMethodType.BANK_TRANSFER && (
                        <BankTransferPaymentMethodForm
                            loading={updateLoading || createLoading}
                            selectedItem={selectedPaymentMethodDetails as BankTransferFormState}
                            onSubmit={handleSave}
                            onCancel={handleCancel}
                        />
                    )}
                    {(paymentMethodType || activeTab) === PaymentMethodType.DIRECT_DEBIT && (
                        <DirectDebitPaymentMethodForm
                            loading={updateLoading || createLoading}
                            selectedItem={selectedPaymentMethodDetails as DirectDebitFormState}
                            onSubmit={handleSave}
                            onCancel={handleCancel}
                        />
                    )}
                    {!!selectedPaymentMethod &&
                        !supportedPaymentMethods.includes(selectedPaymentMethod.payment_method_type) && (
                            <ModalMessage>
                                <AlertTriangle size={48} color="var(--color-lighter-grey)" />
                                <FormattedMessage {...messages.notSupportedMessage} />
                            </ModalMessage>
                        )}
                </ModalContentWrapper>
            </ModalContent>
        </Modal>
    )
}
