import { Stack, TextField } from "@mui/material"
import cls from "classnames"
import React, { ChangeEvent, FC, PropsWithChildren, useRef, useState } from "react"
import { Check, Trash2 } from "react-feather"
import { FormattedMessage } from "react-intl"

import { Button } from "~/components"
import { useEditorDispatch } from "~/domains/orchestration/flows/context/editorContext"
import { messages } from "~/domains/orchestration/flows/messages"
import { FlowNode, Node } from "~/domains/orchestration/flows/types"

import "./ConfigurationNode.scss"

interface Props {
    configuredNode: FlowNode
    unselectCallback: () => void
}

const adaptCommonNodeProperties = (node: FlowNode): Pick<Node, "name"> => {
    return {
        name: node.name,
    }
}

export const ConfigurationNode: FC<PropsWithChildren<Props>> = ({ configuredNode, children, unselectCallback }) => {
    const dispatch = useEditorDispatch()
    const [commonNodeProperties, setCommonNodeProperties] = useState(adaptCommonNodeProperties(configuredNode))

    const initialConfigurationRef = useRef(configuredNode)

    const handleDeleteNode = () => {
        dispatch({
            type: "DELETE_NODE",
            payload: configuredNode.slug,
        })
        dispatch({
            type: "SET_FLOW_PUBLISHABLE",
            payload: true,
        })
        unselectCallback()
    }

    const handleCancelNode = () => {
        // TODO: This will close the side bar view and revert to the initial node configuration
        dispatch({
            type: "UPDATE_NODE",
            payload: initialConfigurationRef.current,
        })
        unselectCallback()
    }

    const handleSaveNode = () => {
        dispatch({
            type: "UPDATE_NODE",
            payload: { ...configuredNode, ...commonNodeProperties },
        })
        dispatch({
            type: "SET_FLOW_PUBLISHABLE",
            payload: true,
        })
        unselectCallback()
    }

    const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
        setCommonNodeProperties({ ...commonNodeProperties, name: e.target.value })
    }

    const configurationNodeItemClassName = cls("flows-configurationNode-item", "flows-editor-sideBar-item")

    return (
        <Stack className="flows-configurationNode" gap={2}>
            <Stack gap={1} className={configurationNodeItemClassName}>
                <TextField
                    label={<FormattedMessage {...messages.configurationNode.nameLabel} />}
                    value={commonNodeProperties.name}
                    onChange={handleChangeName}
                    size="small"
                />
            </Stack>
            {children}
            <Stack direction="row" justifyContent="flex-end" gap={1} className="flows-configurationNode-actions">
                <Button
                    type="grey-light"
                    onClick={handleCancelNode}
                    className="flows-configurationNode-cancelConfiguration"
                >
                    <FormattedMessage {...messages.configurationNode.cancelConfigurationLabel} />
                </Button>
                <Button type="success" onClick={handleSaveNode} className="flows-configurationNode-saveConfiguration">
                    <Check size={18} />
                    <FormattedMessage {...messages.configurationNode.saveConfigurationLabel} />
                </Button>

                <Button type="error" onClick={handleDeleteNode} className="flows-configurationNode-deleteNode">
                    <Trash2 size={18} />
                </Button>
            </Stack>
        </Stack>
    )
}
