import { Card as CardMUI, Grid, IconButton, SxProps, styled } from "@mui/material"
import classnames from "classnames"
import React, { FC, PropsWithChildren, ReactNode, useState } from "react"
import { defineMessages, useIntl } from "react-intl"

import { DownChevronIcon } from "~/components/Icons"

import "./Card.scss"

const messages = defineMessages({
    showMore: {
        id: "common.showMore",
        defaultMessage: "Show more...",
    },
    showLess: {
        id: "common.showLess",
        defaultMessage: "Show less",
    },
})
const StyledCard = styled(CardMUI)`
    border-radius: var(--border-radius-sm);
    overflow: inherit;
`

interface CardProps {
    id?: string
    title?: string
    header?: ReactNode
    status?: string
    className?: string
    expandable?: boolean
    isExpandedByDefault?: boolean
    onClick?: React.MouseEventHandler<HTMLDivElement>
    sx?: SxProps
}

export const Card: FC<PropsWithChildren<CardProps>> = ({
    id,
    title,
    header,
    status = "default",
    children,
    className = "",
    onClick,
    expandable = false,
    isExpandedByDefault = true,
    sx,
}) => {
    const { formatMessage } = useIntl()
    const [isExpanded, setIsExpanded] = useState(isExpandedByDefault)

    const handleExpandClick = () => {
        setIsExpanded(!isExpanded)
    }

    const cls = classnames(className, "flowie-card", `status-${status}`)

    const hasHeader = header || title || expandable

    return (
        <StyledCard id={id} onClick={onClick} className={cls} sx={sx}>
            {hasHeader && (
                <Grid container justifyContent="space-between" alignItems="center" className="flowie-card__header">
                    {header || (title && <h4>{title}</h4>)}
                    {expandable && (
                        <StyledExpandButton
                            $isExpanded={isExpanded}
                            onClick={handleExpandClick}
                            aria-expanded={isExpanded}
                            aria-label={
                                isExpanded ? formatMessage(messages.showLess) : formatMessage(messages.showMore)
                            }
                            $size={30}
                        >
                            <DownChevronIcon />
                        </StyledExpandButton>
                    )}
                </Grid>
            )}
            {isExpanded && children}
        </StyledCard>
    )
}

const StyledExpandButton = styled(IconButton)<{ $isExpanded: boolean; $size: number }>(
    ({ theme, $isExpanded, $size }) => ({
        marginLeft: "auto",
        height: $size,
        width: $size,
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest,
        }),
        transform: $isExpanded ? "rotate(180deg)" : "rotate(0deg)",
    })
)
