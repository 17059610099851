import { Grid, Skeleton, Stack } from "@mui/material"
import dayjs from "dayjs"
import React, { useCallback, useEffect, useState } from "react"
import { DollarSign, FileText, Home, Send, ShoppingBag, Users } from "react-feather"
import { FormattedMessage, MessageDescriptor, defineMessages, useIntl } from "react-intl"
import { generatePath, useNavigate } from "react-router-dom"
import { JSX } from "react/jsx-runtime"

import { Button, Card, Tabs } from "~/components"
import { DateComponent } from "~/components/Date"
import { HeaderH1 } from "~/components/Header/HeaderH1"
import { ItemLabel } from "~/components/ItemLabel/ItemLabel"
import { StatusChip } from "~/components/StatusChip/StatusChip"
import { DocumentType } from "~/domains/identity/features/documents/types"
import { DomainName, ScopeName } from "~/domains/identity/features/roles-permissions/types/RolesPermissions"
import { useHasPermissions } from "~/domains/identity/store/hooks/useHasPermissions"
import { ModalCreatePartnership } from "~/domains/transactions/book-of-relations/components/ModalCreatePartnership/ModalCreatePartnership"
import { useFetchPartnersData } from "~/domains/transactions/book-of-relations/store/hooks"
import { PURCHASE_ORDER_NEW_ROUTE, PURCHASE_ORDER_ROUTE } from "~/domains/transactions/purchase-orders/routes"
import { useFetchPurchaseOrders } from "~/domains/transactions/purchase-orders/store/hooks"
import {
    PurchaseOrderProgressStatus,
    PurchaseOrderStatus,
    PurchaseOrders,
} from "~/domains/transactions/purchase-orders/types/PurchaseOrders"
import { PURCHASE_REQUEST_NEW_ROUTE, PURCHASE_REQUEST_ROUTE } from "~/domains/transactions/purchase-requests/routes"
import { useFetchPurchaseRequests } from "~/domains/transactions/purchase-requests/store/hooks"
import {
    PurchaseRequestStatus,
    PurchaseRequestSummary,
} from "~/domains/transactions/purchase-requests/types/PurchaseRequests"
import { MY_DOCUMENTS_ROUTE } from "~/features/account/routes"
import { INVOICE_BUYER_ROUTE } from "~/features/buyer/routes"
import { LEGACY_UPLOAD } from "~/features/ocr/routes"
import { accountActions, selectUser, selectUserLoading } from "~/store/account/accountSlice"
import { useGetReceivedDocuments } from "~/store/account/hooks"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { AuthorizationName, InvoiceStatus, InvoicesTab, ReceivedDocumentI, ViewTypeI } from "~/types"

import "../assets/Dashboard.scss"
import { DashboardMetricsCard } from "../components/DashboardMetricsCard"

const messages = defineMessages({
    title: {
        id: "dashboard.header.title",
        defaultMessage: "Dashboard",
    },
    headerWelcome: {
        id: "dashboard.header.welcome",
        defaultMessage: "Welcome {name}, what do you need help with today?",
    },
    buttonRequestPurchase: {
        id: "dashboard.button.requestPurchase",
        defaultMessage: "Request a purchase",
    },
    buttonCreatePO: {
        id: "dashboard.button.importPO",
        defaultMessage: "Create a purchase order",
    },
    buttonAddPartner: {
        id: "dashboard.button.addPartner",
        defaultMessage: "Add a new partner",
    },
    importInvoice: {
        id: "dashboard.button.importInvoice",
        defaultMessage: "Import an invoice",
    },
    payInvoice: {
        id: "dashboard.button.payInvoice",
        defaultMessage: "Pay an invoice",
    },
    prCardHeading: {
        id: "dashboard.card.pr.heading",
        defaultMessage: "Purchase requests created",
    },
    prCardPercentage: {
        id: "dashboard.card.pr.percentage",
        defaultMessage: "from previous month",
    },
    prCardValue: {
        id: "dashboard.card.pr.value",
        defaultMessage: "PRs this month",
    },
    poCardHeading: {
        id: "dashboard.card.po.heading",
        defaultMessage: "Purchase orders created",
    },
    poCardPercentage: {
        id: "dashboard.card.po.percentage",
        defaultMessage: "from previous month",
    },
    poCardValue: {
        id: "dashboard.card.po.value",
        defaultMessage: "POs this month",
    },
    invoicesCardHeading: {
        id: "dashboard.card.invoices.heading",
        defaultMessage: "Invoices validated",
    },
    invoicesCardPercentage: {
        id: "dashboard.card.invoices.percentage",
        defaultMessage: "from previous month",
    },
    invoicesCardValue: {
        id: "dashboard.card.invoices.value",
        defaultMessage: "invoices this month",
    },
    myPurchaseRequests: {
        id: "dashboard.list.heading.myPurchaseRequests",
        defaultMessage: "My purchase requests",
    },
    myApprovals: {
        id: "dashboard.list.heading.myApprovals",
        defaultMessage: "Documents pending approval",
    },
    purchaseRequest: {
        id: "purchase.requests.request.htmlTitle",
        defaultMessage: "Purchase request",
    },
    purchaseOrder: {
        id: "purchase.orders.order.htmlTitle",
        defaultMessage: "Purchase order",
    },
    invoice: {
        id: "documents.documentTypes.invoice",
        defaultMessage: "Invoice",
    },
    approvalsAll: {
        id: "dashboard.approvals.tab.all",
        defaultMessage: "All",
    },
    approvalsPRs: {
        id: "dashboard.approvals.tab.prs",
        defaultMessage: "Purchase requests",
    },
    approvalsPOs: {
        id: "dashboard.approvals.tab.pos",
        defaultMessage: "Purchase orders",
    },
    approvalsInvoices: {
        id: "dashboard.approvals.tab.invoices",
        defaultMessage: "Invoices",
    },
    errorWrongFileType: {
        id: "purchase.list.modalImportBatch.errorWrongFileType",
        defaultMessage: "Wrong file format: we only accept CSV and XLSX files.",
    },
    modalUploadTitle: {
        id: "purchase.list.modalImportBatch.title",
        defaultMessage: "Import Purchase Orders in batch",
    },
    modalUploadTip: {
        id: "purchase.list.modalImportBatch.uploadBox.tip",
        defaultMessage: "Supported format : csv, xlsx. No more than 2mb",
    },
    emptyList: {
        id: "dashboard.list.empty",
        defaultMessage: "No items to display",
    },
})

const prStatusLabels: Record<PurchaseRequestStatus, MessageDescriptor> = {
    DRAFT: {
        id: "purchase.requests.status.draft",
        defaultMessage: "Draft",
    },
    SUBMITTED: {
        id: "purchase.requests.status.submitted",
        defaultMessage: "Validation pending",
    },
    APPROVED: {
        id: "purchase.requests.status.approved",
        defaultMessage: "Approved",
    },
    REJECTED: {
        id: "purchase.requests.status.rejected",
        defaultMessage: "Rejected",
    },
    CONVERTED: {
        id: "purchase.requests.status.converted",
        defaultMessage: "Converted",
    },
}
export type DashboardApprovalsTab = "All" | "Purchase requests" | "Purchase orders" | "Invoices"

/**
 * Calculates monthly and change for an array of items
 * @param items - an array of items
 * @param statusProperty - property used for filtering the items
 * @param expectedStatus - status used for filtering
 * @param dateProperty - which property holds the date
 * @param setThisMonth - state function to call to set monthly value
 * @param setPercentage - state function to call to set percentage value
 */
const calculateMonthlyChange = <T extends Record<string, any>>(
    items: T[],
    statusProperty: keyof T,
    expectedStatus: any,
    userIdProperty: keyof T,
    userId: string,
    dateProperty: keyof T
) => {
    let currentMonthCount = 0
    let previousMonthCount = 0

    const startOfCurrentMonth = dayjs().startOf("month")
    const startOfPreviousMonth = dayjs().subtract(1, "month").startOf("month")

    items.forEach((item) => {
        if (item[statusProperty] === expectedStatus && item[userIdProperty] === userId) {
            const itemDate = dayjs(item[dateProperty])
            if (itemDate.isAfter(startOfCurrentMonth)) {
                currentMonthCount++
            } else if (itemDate.isAfter(startOfPreviousMonth)) {
                previousMonthCount++
            }
        }
    })

    const percentage =
        previousMonthCount !== 0 ? ((currentMonthCount - previousMonthCount) / previousMonthCount) * 100 : 0
    return { currentMonthCount, percentage }
}

// Number of items to render
const LIST_ITEMS_LIMIT = 20

const ListLoadingState = () => (
    <div className="dashboard-list-loading">
        <Skeleton variant="rectangular" className="dashboard-list-loading-skeleton" />
        <Skeleton variant="rectangular" className="dashboard-list-loading-skeleton" />
        <Skeleton variant="rectangular" className="dashboard-list-loading-skeleton" />
    </div>
)

const TabCount: React.FC<{ count: number }> = ({ count }) => (
    <div className={`tab-count${count < 1 ? " tab-count--grey" : ""}`}>{count}</div>
)

export const Dashboard = () => {
    const { formatMessage } = useIntl()
    const user = useAppSelector(selectUser)
    const isUserLoading = useAppSelector(selectUserLoading)
    const currentOrganizationId = useAppSelector(selectCurrentOrganizationId)
    const { purchaseRequests, loading: purchaseRequestsLoading } = useFetchPurchaseRequests(currentOrganizationId)
    const { purchaseOrders, loading: loadingPurchaseOrders } = useFetchPurchaseOrders("", currentOrganizationId)
    const { receivedDocuments, loading: receivedDocumentsLoading } = useGetReceivedDocuments(
        {
            organizationId: currentOrganizationId,
            userId: user.id,
        },
        true
    )
    const { fetchPartners } = useFetchPartnersData(currentOrganizationId, true)
    const [metrics, setMetrics] = useState({
        pr: { currentMonthCount: 0, percentage: 0 },
        po: { currentMonthCount: 0, percentage: 0 },
        invoices: { currentMonthCount: 0, percentage: 0 },
    })
    const [approvals, setApprovals] = useState<Array<PurchaseRequestSummary | PurchaseOrders | ReceivedDocumentI>>([])
    const [currentPrTab, setCurrentPrTab] = useState<PurchaseRequestStatus>(PurchaseRequestStatus.SUBMITTED)
    const [currentApprovalsTab, setCurrentApprovalsTab] = useState<DashboardApprovalsTab>("All")
    const [showCreatePartnershipModal, setShowCreatePartnershipModal] = useState<boolean>(false)

    const dispatch = useAppDispatch()

    const navigate = useNavigate()

    const { hasPermission: hasInvoicesPermission } = useHasPermissions(
        currentOrganizationId ?? "",
        AuthorizationName.READ,
        DomainName.TRANSACTION,
        ScopeName.INVOICES
    )
    const handlePurchaseRequest = () => {
        navigate(PURCHASE_REQUEST_NEW_ROUTE)
    }

    const handlePurchaseOrder = () => {
        navigate(PURCHASE_ORDER_NEW_ROUTE)
    }

    const handleVendorManagement = () => {
        setShowCreatePartnershipModal(true)
    }

    const handleImportInvoice = () => {
        navigate(LEGACY_UPLOAD)
    }
    const handlePayInvoice = () => {
        dispatch(accountActions.setCurrentInvoicesTab(InvoicesTab.PENDING_PAYMENT))
        navigate(generatePath(MY_DOCUMENTS_ROUTE, { view: ViewTypeI.buyer }))
    }

    const onPrTabChange = (tab: string | number) => {
        setCurrentPrTab(tab as PurchaseRequestStatus)
    }

    const onApprovalsTabChange = (tab: string | number) => {
        setCurrentApprovalsTab(tab as DashboardApprovalsTab)
    }
    const hideCreatePartnershipModal = () => setShowCreatePartnershipModal(false)

    useEffect(() => {
        const prMetrics = calculateMonthlyChange(
            purchaseRequests,
            "status",
            PurchaseRequestStatus.APPROVED,
            "requesterUserId",
            user.id,
            "creationDate"
        )
        const poMetrics = calculateMonthlyChange(
            purchaseOrders,
            "progress",
            PurchaseOrderProgressStatus.INTERNALLY_APPROVED,
            "requesterUserId",
            user.id,
            "creationDate"
        )
        const invoicesMetrics = calculateMonthlyChange(
            receivedDocuments,
            "invoiceType",
            DocumentType.INVOICE,
            "userId",
            user.id,
            "receptionDate"
        )

        setMetrics({ pr: prMetrics, po: poMetrics, invoices: invoicesMetrics })

        const approvalsList = [
            ...purchaseRequests.filter((pr) => pr.status === PurchaseRequestStatus.SUBMITTED),
            ...purchaseOrders.filter((po) => po.status === PurchaseOrderStatus.OPEN),
            ...receivedDocuments.filter(
                (doc) => doc.invoiceType === DocumentType.INVOICE && doc.status === InvoiceStatus.CONFIRMED
            ),
        ].sort((a, b) => {
            const compareDateA = "creationDate" in a ? dayjs(a.creationDate as string) : dayjs(a.receptionDate)
            const compareDateB = "creationDate" in b ? dayjs(b.creationDate as string) : dayjs(b.receptionDate)
            return compareDateA.isAfter(compareDateB) ? -1 : 1
        })

        setApprovals(approvalsList)

        return () => {
            setMetrics({
                pr: { currentMonthCount: 0, percentage: 0 },
                po: { currentMonthCount: 0, percentage: 0 },
                invoices: { currentMonthCount: 0, percentage: 0 },
            })
            setApprovals([])
        }
    }, [purchaseRequests, purchaseOrders, receivedDocuments])

    const EmptyState = () => (
        <Grid container justifyContent="center" alignItems="center" padding={5}>
            <FormattedMessage {...messages.emptyList} />
        </Grid>
    )

    /**
     * Renders the list item for both lists
     * @param key
     * @param url
     * @param firstLine
     * @param secondLine
     * @param status
     * @returns
     */
    const dashboardListItem = useCallback(
        (
            key: string,
            onClick: () => void,
            firstLine: string | JSX.Element,
            secondLine: string | JSX.Element,
            status: string | JSX.Element
        ) => (
            <Stack
                key={key}
                direction="row"
                alignItems="center"
                gap={1}
                onClick={onClick}
                className="dashboard-list-item"
            >
                <div className="dashboard-list-item-description truncate-text">
                    <a>{firstLine}</a>
                    <ItemLabel>{secondLine}</ItemLabel>
                </div>
                <div>{status}</div>
            </Stack>
        ),
        []
    )

    /**
     * Renders the list item for approvals
     * @param approval
     * @returns
     */
    const renderApprovalListItem = useCallback((approval) => {
        let approvalSupplementary
        let approvalLink

        const createSupplementary = (message, date) => (
            <>
                <FormattedMessage {...message} /> -
                <DateComponent className="dashboard-list-item-date" value={date} />
            </>
        )

        if ("organizationId" in approval) {
            approvalSupplementary = createSupplementary(messages.purchaseRequest, approval.submittedDate)
            approvalLink = generatePath(PURCHASE_REQUEST_ROUTE, { purchaseRequestId: approval.id })
        } else if ("progress" in approval) {
            approvalSupplementary = createSupplementary(messages.purchaseOrder, approval.creationDate)
            approvalLink = generatePath(PURCHASE_ORDER_ROUTE, { purchaseOrderId: approval.id })
        } else if ("invoiceType" in approval) {
            approvalSupplementary = createSupplementary(messages.invoice, approval.updateDate)
            approvalLink = generatePath(INVOICE_BUYER_ROUTE, { invoiceId: approval.invoiceId })
        }

        const description = approval.description
            ? `${approval.description}${approval.supplierName ? " - " : ""}${approval.supplierName || ""}`
            : approval.supplierName || ""

        const itemOnClick = () => {
            navigate(approvalLink)
        }

        return dashboardListItem(
            approval.id ?? approval.invoiceId,
            itemOnClick,
            description,
            approvalSupplementary,
            <>
                <StatusChip status={approval.status}>{approval.status}</StatusChip>
            </>
        )
    }, [])

    /**
     * Renders purchase requests based on status
     * @param status
     * @returns
     */
    const renderPurchaseRequests = (filteredPurchaseRequests: PurchaseRequestSummary[]) => {
        if (!filteredPurchaseRequests.length) {
            return <EmptyState />
        }

        return (
            <>
                {filteredPurchaseRequests.map(
                    ({ id, description, supplierName, totalAmount, currency, creationDate, status }) => {
                        const formattedDescription = `${description} ${supplierName || ""} - ${totalAmount} ${currency}`
                        const approvalDate = (
                            <>
                                <FormattedMessage {...messages.purchaseRequest} /> -
                                <DateComponent className="dashboard-list-item-date" value={creationDate} />
                            </>
                        )
                        const statusBadge = (
                            <span className={`pr-status status-${status}`}>
                                <FormattedMessage {...prStatusLabels[status]} />
                            </span>
                        )

                        const itemOnClick = () =>
                            navigate(generatePath(PURCHASE_REQUEST_ROUTE, { purchaseRequestId: id }))

                        return dashboardListItem(id, itemOnClick, formattedDescription, approvalDate, statusBadge)
                    }
                )}
            </>
        )
    }

    const approvalTabs = useCallback(() => {
        const allApprovals = approvals.slice(0, LIST_ITEMS_LIMIT)
        // filter the approvals based on their type
        const approvalsPRs = approvals.filter((a) => "organizationId" in a).slice(0, LIST_ITEMS_LIMIT)
        const approvalsPOs = approvals.filter((a) => "progress" in a).slice(0, LIST_ITEMS_LIMIT)
        const approvalsInvoices = approvals.filter((a) => "invoiceType" in a).slice(0, LIST_ITEMS_LIMIT)

        return [
            {
                label: (
                    <div>
                        <FormattedMessage {...messages.approvalsAll} />
                        <TabCount count={allApprovals.length} />
                    </div>
                ),
                value: "All",
                component: <>{allApprovals.map(renderApprovalListItem)}</>,
            },
            {
                label: (
                    <div>
                        <FormattedMessage {...messages.approvalsPRs} />
                        <TabCount count={approvalsPRs.length} />
                    </div>
                ),
                value: "Purchase requests",
                component: approvalsPRs.length ? <>{approvalsPRs.map(renderApprovalListItem)}</> : <EmptyState />,
            },
            {
                label: (
                    <div>
                        <FormattedMessage {...messages.approvalsPOs} />
                        <TabCount count={approvalsPOs.length} />
                    </div>
                ),
                value: "Purchase orders",
                component: approvalsPOs.length ? <>{approvalsPOs.map(renderApprovalListItem)}</> : <EmptyState />,
            },
            {
                label: (
                    <div>
                        <FormattedMessage {...messages.approvalsInvoices} />
                        <TabCount count={approvalsInvoices.length} />
                    </div>
                ),
                value: "Invoices",
                component: approvalsInvoices.length ? (
                    <>{approvalsInvoices.map(renderApprovalListItem)}</>
                ) : (
                    <EmptyState />
                ),
            },
        ]
    }, [approvals, renderApprovalListItem])

    const prStatusTabs = useCallback(() => {
        // filter and sort PRs based on status
        const filterPurchaseRequests = (
            requests: PurchaseRequestSummary[],
            userId: string,
            status: PurchaseRequestStatus
        ) => {
            return requests
                .filter(
                    ({ requesterUserId, status: requestStatus }) =>
                        requesterUserId === userId && requestStatus === status
                )
                .sort((a, b) => dayjs(b.creationDate).diff(dayjs(a.creationDate)))
                .slice(0, LIST_ITEMS_LIMIT)
        }

        // filter the purchase requests based on their status
        const submittedPurchaseRequests = filterPurchaseRequests(
            purchaseRequests,
            user.id,
            PurchaseRequestStatus.SUBMITTED
        )
        const approvedPurchaseRequests = filterPurchaseRequests(
            purchaseRequests,
            user.id,
            PurchaseRequestStatus.APPROVED
        )
        const draftPurchaseRequests = filterPurchaseRequests(purchaseRequests, user.id, PurchaseRequestStatus.DRAFT)

        return [
            {
                label: (
                    <div>
                        <FormattedMessage {...prStatusLabels[PurchaseRequestStatus.SUBMITTED]} />
                        <TabCount count={submittedPurchaseRequests.length} />
                    </div>
                ),
                value: PurchaseRequestStatus.SUBMITTED,
                component: renderPurchaseRequests(submittedPurchaseRequests),
            },
            {
                label: (
                    <div>
                        <FormattedMessage {...prStatusLabels[PurchaseRequestStatus.APPROVED]} />
                        <TabCount count={approvedPurchaseRequests.length} />
                    </div>
                ),
                value: PurchaseRequestStatus.APPROVED,
                component: renderPurchaseRequests(approvedPurchaseRequests),
            },
            {
                label: (
                    <div>
                        <FormattedMessage {...prStatusLabels[PurchaseRequestStatus.DRAFT]} />
                        <TabCount count={draftPurchaseRequests.length} />
                    </div>
                ),
                value: PurchaseRequestStatus.DRAFT,
                component: renderPurchaseRequests(draftPurchaseRequests),
            },
        ]
    }, [purchaseRequests])

    return (
        <>
            <HeaderH1 title={formatMessage(messages.title)} icon={<Home />} />

            <div className="dashboard">
                {!isUserLoading && (
                    <Stack paddingBlock={3}>
                        <h4>
                            <b>
                                <FormattedMessage
                                    {...messages.headerWelcome}
                                    values={{
                                        name: user.fullName,
                                    }}
                                />
                            </b>
                        </h4>
                    </Stack>
                )}

                <Grid container gap={1} flexDirection={{ xs: "column", sm: "row" }}>
                    <Button type="primary-light" onClick={handlePurchaseRequest}>
                        <Send size={18} />
                        <FormattedMessage {...messages.buttonRequestPurchase} />
                    </Button>
                    <Button type="primary-light" onClick={handlePurchaseOrder}>
                        <ShoppingBag size={18} />
                        <FormattedMessage {...messages.buttonCreatePO} />
                    </Button>
                    <Button type="primary-light" onClick={handleVendorManagement}>
                        <Users size={18} />
                        <FormattedMessage {...messages.buttonAddPartner} />
                    </Button>
                    <Button type="primary-light" onClick={handleImportInvoice}>
                        <FileText size={18} />
                        <FormattedMessage {...messages.importInvoice} />
                    </Button>
                    {hasInvoicesPermission && (
                        <Button type="primary-light" onClick={handlePayInvoice}>
                            <DollarSign size={18} />
                            <FormattedMessage {...messages.payInvoice} />
                        </Button>
                    )}
                </Grid>
                <Grid
                    container
                    spacing={3}
                    justifyContent="stretch"
                    flexDirection={{ xs: "column", sm: "row" }}
                    className="dashboard-metrics"
                >
                    <Grid item xs={12} sm={4}>
                        <DashboardMetricsCard
                            isLoading={purchaseRequestsLoading}
                            metricValue={metrics.pr.currentMonthCount}
                            percentage={metrics.pr.percentage}
                            heading={formatMessage(messages.prCardHeading)}
                            percentageLabel={<FormattedMessage {...messages.prCardPercentage} />}
                            valueLabel={<FormattedMessage {...messages.prCardValue} />}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <DashboardMetricsCard
                            isLoading={loadingPurchaseOrders}
                            metricValue={metrics.po.currentMonthCount}
                            percentage={metrics.po.percentage}
                            heading={formatMessage(messages.poCardHeading)}
                            percentageLabel={<FormattedMessage {...messages.poCardPercentage} />}
                            valueLabel={<FormattedMessage {...messages.poCardValue} />}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <DashboardMetricsCard
                            isLoading={receivedDocumentsLoading}
                            metricValue={metrics.invoices.currentMonthCount}
                            percentage={metrics.invoices.percentage}
                            heading={formatMessage(messages.invoicesCardHeading)}
                            percentageLabel={<FormattedMessage {...messages.invoicesCardPercentage} />}
                            valueLabel={<FormattedMessage {...messages.invoicesCardValue} />}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3} justifyContent="stretch" className="dashboard-metrics">
                    <Grid item xs={12} sm={6}>
                        <Card title={formatMessage(messages.myPurchaseRequests)} className="dashboard-list">
                            {purchaseRequestsLoading ? (
                                <ListLoadingState />
                            ) : (
                                <Tabs
                                    className="dashboard-metrics-tabs"
                                    defaultValue={currentPrTab}
                                    setChange={onPrTabChange}
                                    tabs={prStatusTabs()}
                                />
                            )}
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Card title={formatMessage(messages.myApprovals)} className="dashboard-list">
                            {purchaseRequestsLoading || loadingPurchaseOrders || receivedDocumentsLoading ? (
                                <ListLoadingState />
                            ) : (
                                <Tabs
                                    className="dashboard-metrics-tabs"
                                    defaultValue={currentApprovalsTab}
                                    setChange={onApprovalsTabChange}
                                    tabs={approvalTabs()}
                                />
                            )}
                        </Card>
                    </Grid>
                </Grid>
                {currentOrganizationId && (
                    <ModalCreatePartnership
                        open={showCreatePartnershipModal}
                        close={hideCreatePartnershipModal}
                        organizationId={currentOrganizationId}
                        fetchPartners={fetchPartners}
                    />
                )}
            </div>
        </>
    )
}
