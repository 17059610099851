import { styled } from "@mui/material"
import React, { useCallback, useState } from "react"

interface Props {
    name: string
    domain?: string | null
}
const initialRegexp = /(\p{L}{1})\p{L}+/gu

const Figure = styled("figure")({
    width: "34px",
    height: "34px",
    flex: "0 0 34px",
    margin: 0,
    padding: "2px",
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& > img": {
        maxWidth: "100%",
        maxHeight: "100%",
    },
})

export const FigureForInitials = styled(Figure)({
    backgroundColor: "white",
    borderRadius: "17px",
    overflow: "hidden",
    fontWeight: 500,
    fontSize: "22px",
})

export const OrganizationLogo: React.FC<Props> = ({ name, domain }) => {
    const [errorLoadingImage, setErrorLoadingImage] = useState<boolean>(false)
    const onError = useCallback(() => {
        setErrorLoadingImage(true)
    }, [])
    if (!domain || errorLoadingImage) {
        const initialsResul = [...name.matchAll(initialRegexp)]
        const initials = ((initialsResul.shift()?.[1] || "") + (initialsResul.pop()?.[1] || "")).toUpperCase()
        return (
            <FigureForInitials>
                <span>{initials}</span>
            </FigureForInitials>
        )
    }
    return (
        <Figure>
            <img src={`https://logo.clearbit.com/${domain}`} onError={onError} />
        </Figure>
    )
}
