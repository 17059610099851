import { InvoiceFieldLevel } from "~/domains/transactions/invoices-v1/types/Invoice"
import { InvoiceParty } from "~/domains/transactions/invoices-v1/types/Invoice"

export const formatCustomFields = (party: InvoiceParty, level: InvoiceFieldLevel) => {
    const customFieldsTemplate = (party?.template?.customFields || []).filter(({ level: l }) => l === level)

    const customFields = {}
    customFieldsTemplate?.forEach((customField) => {
        const customFieldValue = party?.customFields?.[customField.id]
        const valueFormatted = customFieldValue || ""

        customFields[customField.id] = valueFormatted
    })

    return customFields
}
