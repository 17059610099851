import { Grid } from "@mui/material"
import React from "react"
import { FormattedMessage, defineMessages } from "react-intl"

import { Card } from "~/components"
import { ItemLabel } from "~/components/ItemLabel/ItemLabel"
import { InvoiceReviewerWithUsersI, ReviewerType } from "~/features/workflow/types/ApiWorkflow"
import { InvoiceStatus, UserI, UserId } from "~/types"

import { ReviewStep, reviewState } from "./ReviewStep"

const messages = defineMessages({
    title: {
        id: "buyer.workflowValidation.title",
        defaultMessage: "Workflow - {checkDone} / {totalChecks}",
    },
    description: {
        id: "buyer.workflowValidation.description",
        defaultMessage: "Awaiting approvals from:",
    },
    notReady: {
        id: "buyer.workflowValidation.notReady",
        defaultMessage: "This invoice is not ready for approval yet",
    },
    noApprovals: {
        id: "buyer.workflowValidation.noApproval",
        defaultMessage: "There is currently no expected approval for this invoice",
    },
    allApprovals: {
        id: "buyer.workflowValidation.allApprovals",
        defaultMessage: "All approvals have been given",
    },
})

interface Props {
    reviewers: InvoiceReviewerWithUsersI[]
    approvers: UserI[]
    refusers: UserI[]
    totalChecks: number
    checkDone: number
    invoiceStatus: InvoiceStatus
}

export const WorkflowValidation: React.FC<Props> = ({
    reviewers,
    approvers,
    refusers,
    totalChecks,
    checkDone,
    invoiceStatus,
}) => {
    const validationStatuses = [InvoiceStatus.CONFIRMED, InvoiceStatus.INFO_REQUESTED]
    const isInValidationStatus = validationStatuses.includes(invoiceStatus)
    const allChecksDone = checkDone / totalChecks === 1

    const getUserState = (userId: UserId) => {
        if (approvers.some((user) => user.id === userId)) return reviewState.APPROVED
        if (refusers.some((user) => user.id === userId)) return reviewState.REFUSED

        return reviewState.WAITING
    }

    return (
        <Card
            header={
                <h4>
                    <FormattedMessage {...messages.title} values={{ checkDone, totalChecks }} />
                </h4>
            }
            expandable
        >
            {!isInValidationStatus ? (
                <ItemLabel>
                    <FormattedMessage {...messages.notReady} />
                </ItemLabel>
            ) : reviewers.length ? (
                <>
                    {allChecksDone && (
                        <ItemLabel>
                            <FormattedMessage {...messages.allApprovals} />
                        </ItemLabel>
                    )}
                    <Grid container className="auto-check">
                        <Grid item md={12} className="checklist">
                            <ul>
                                {reviewers.map(
                                    ({ type, value }) =>
                                        type === ReviewerType.USER && (
                                            <ReviewStep key={value.id} user={value} state={getUserState(value.id)} />
                                        )
                                )}
                            </ul>
                        </Grid>
                    </Grid>
                </>
            ) : (
                <Grid container className="auto-check">
                    <ItemLabel>
                        <FormattedMessage {...messages.noApprovals} />
                    </ItemLabel>
                </Grid>
            )}
        </Card>
    )
}
