import { useEffect, useRef, useState, useCallback } from "react"
import throttle from "lodash.throttle"

const ONE_SECOND = 1000

const THROTTLE_DELAY = 300

const events = ["mousemove", "keydown", "scroll", "click"]

interface InactivityDetection {
    isWarningVisible: boolean
    countdown: number
}

export function useInactivityDetection(
    timeout: number,
    countdownBeforeRefresh: number,
    onTimeout: () => void
): InactivityDetection {
    const initialCountdown = countdownBeforeRefresh / ONE_SECOND
    const [isWarningVisible, setWarningVisible] = useState(false)
    const [countdown, setCountdown] = useState(initialCountdown)
    const timeoutRef = useRef<number | null>(null)
    const countdownRef = useRef<number | null>(null)

    const resetTimer = useCallback(() => {
        if (timeoutRef.current) clearTimeout(timeoutRef.current)
        if (countdownRef.current) clearInterval(countdownRef.current)

        setWarningVisible(false)
        setCountdown(initialCountdown)

        timeoutRef.current = window.setTimeout(() => {
            setWarningVisible(true)
            startCountdown()
        }, timeout - countdownBeforeRefresh)
    }, [timeout, countdownBeforeRefresh])

    const startCountdown = useCallback(() => {
        countdownRef.current = window.setInterval(() => {
            setCountdown((prev) => {
                if (prev <= 1) {
                    clearInterval(countdownRef.current!)
                    onTimeout()
                    return 0
                }
                return prev - 1
            })
        }, ONE_SECOND)
    }, [onTimeout])

    useEffect(() => {
        const handleActivity = throttle(() => {
            resetTimer()
        }, THROTTLE_DELAY)

        events.forEach((event) => window.addEventListener(event, handleActivity))

        resetTimer()

        return () => {
            events.forEach((event) => window.removeEventListener(event, handleActivity))

            handleActivity.cancel()

            if (timeoutRef.current) clearTimeout(timeoutRef.current)
            if (countdownRef.current) clearInterval(countdownRef.current)
        }
    }, [resetTimer])

    return { isWarningVisible, countdown }
}
