import { Grid, Stack } from "@mui/material"
import { FC } from "react"
import { Trash2 } from "react-feather"

import UserAvatar from "~/components/Avatar/UserAvatar"
import { UserPartialI } from "~/types"

interface PeopleProps {
    person: UserPartialI
    deletePeople: (user: UserPartialI) => void
}

export const People: FC<PeopleProps> = ({ person, deletePeople }) => {
    const handleDelete = () => {
        deletePeople(person)
    }

    return (
        <Grid container alignItems="center" justifyContent="space-between">
            <Stack direction="row" alignItems="center" spacing={1}>
                <UserAvatar email={person.email} />
                <p>{person.email}</p>
            </Stack>
            <Trash2 size={16} onClick={handleDelete} color="var(--color-light-grey)" />
        </Grid>
    )
}
