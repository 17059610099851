import { Stack, Tooltip, styled } from "@mui/material"
import React, { useCallback, useMemo } from "react"
import { FormattedMessage, defineMessages, useIntl } from "react-intl"

import { commonMessages } from "~/common-messages"
import { ProgressBar, ProgressBarItemDisplay, TitlePlacement } from "~/components"
import { ErrorMessage } from "~/components/ErrorMessage"
import { ProgressBarLegend } from "~/components/ProgressBar/ProgressBarLegend"
import {
    amountLabels,
    budgetMetricsToProgressBarItems,
    getTotalForGaugeTitle,
} from "~/features/budget/core/budgetGauge"
import { AmountType, BudgetDataI } from "~/features/budget/types"

export const budgetGaugeMessages = defineMessages({
    used: {
        id: "budgets.table.usedOutOf",
        defaultMessage: "used out of",
    },
    exclTaxes: {
        id: "budgets.table.exclTaxes",
        defaultMessage: "(excl. taxes)",
    },
    error: {
        id: "budgets.table.error",
        defaultMessage: "error: missing budget's data",
    },
    progressBarTitleTooltip: {
        id: "budget.budgetDetails.gauge.progressBarTitleTooltip",
        defaultMessage: "Total excl. taxes",
    },
})

export const BudgetGaugeAmountLabel = styled("div", {
    shouldForwardProp: (prop) => prop !== "isOverbudget",
})<{ isOverbudget?: boolean }>(({ isOverbudget }) => ({
    color: isOverbudget ? "var(--color-red)" : undefined,
    fontWeight: isOverbudget ? "bold" : undefined,
}))

export const BudgetGaugeTotalLabel = styled("div")({
    color: "var(--color-light-grey)",
})

interface Props {
    budget: BudgetDataI
    showLegend?: boolean
}

export const BudgetGauge: React.FC<Props> = ({ budget, showLegend = false }) => {
    const { formatNumber, formatMessage } = useIntl()

    const totalAmountForGaugeTitle = useMemo(() => getTotalForGaugeTitle(budget.metrics), [budget.metrics])

    const progressBarItems = useMemo(
        () => budgetMetricsToProgressBarItems(budget.metrics, budget.currentAmount),
        [budget.metrics, budget.currentAmount]
    )
    const overbudgetAmount = useMemo(
        () => progressBarItems.find((item) => item.label === AmountType.OVERBUDGET && item.value > 0)?.value ?? 0,
        [progressBarItems]
    )
    const isOverbudget = overbudgetAmount > 0

    const progressBarItemsDisplay = useMemo(
        () =>
            progressBarItems.reduce((acc, item) => {
                if (item.label !== AmountType.OVERBUDGET || item.value > 0) {
                    const value = formatNumber(item.value, {
                        style: "currency",
                        currency: budget.currency,
                    })
                    const percentage = item.percentage ? formatNumber(item.percentage, { style: "percent" }) : null
                    acc.push({
                        ...item,
                        value: `${value}${percentage ? ` / ${percentage}` : ""}`,
                        label: item.label ? formatMessage(amountLabels[item.label]) : "",
                    })
                }
                return acc
            }, [] as ProgressBarItemDisplay[]),
        [progressBarItems, budget.currency, budget.currentAmount, isOverbudget, overbudgetAmount, formatMessage]
    )

    const renderProgressBarTitle = useCallback(
        () => (
            <Stack direction="row" gap={1}>
                <Tooltip title={formatMessage(budgetGaugeMessages.progressBarTitleTooltip)} placement="top" arrow>
                    <BudgetGaugeAmountLabel isOverbudget={isOverbudget}>
                        {formatNumber(totalAmountForGaugeTitle, {
                            style: "currency",
                            currency: budget.currency,
                        })}
                    </BudgetGaugeAmountLabel>
                </Tooltip>
                <BudgetGaugeTotalLabel>{formatMessage(budgetGaugeMessages.used)}</BudgetGaugeTotalLabel>
                <BudgetGaugeAmountLabel isOverbudget={isOverbudget}>
                    {formatNumber(budget.currentAmount, {
                        style: "currency",
                        currency: budget.currency,
                    })}
                </BudgetGaugeAmountLabel>
            </Stack>
        ),
        [totalAmountForGaugeTitle, budget.currentAmount, budget.currency, isOverbudget, formatMessage]
    )

    if (budget.currentAmount == null || budget.currency == null) {
        return (
            <ErrorMessage>
                <FormattedMessage {...commonMessages.error} />
            </ErrorMessage>
        )
    }

    if (!showLegend) {
        return (
            <ProgressBar
                values={progressBarItemsDisplay}
                title={renderProgressBarTitle()}
                titlePlacement={TitlePlacement.BOTTOM}
                tooltip={<ProgressBarLegend values={progressBarItemsDisplay} />}
            />
        )
    }

    return (
        <Stack spacing={2}>
            <ProgressBar values={progressBarItemsDisplay} title={renderProgressBarTitle()} />
            <ProgressBarLegend values={progressBarItemsDisplay} />
        </Stack>
    )
}
