import { useCallback, useState } from "react"
import { OrganizationId } from "~/types"
import { toast } from "react-toastify"
import { defineMessages, useIntl } from "react-intl"
import { purchaseOrdersApi } from "~/domains/transactions/purchase-orders/api/purchaseOrdersApi"
import { useAppDispatch } from "~/store/hooks"
import { FulfillmentStatus } from "~/domains/transactions/types"

const messages = defineMessages({
    statusUpdated: {
        id: "purchase.orders.order.statusUpdate",
        defaultMessage: "Purchase order fulfillment status updated",
    },

    error: {
        id: "purchase.orders.order.statusUpdate.error",
        defaultMessage: "Something went wrong. Please contact your administrator",
    },
})

export const useUpdatePurchaseOrderFulfillmentStatus = (purchaseOrderId: string) => {
    const { formatMessage } = useIntl()
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>()
    const dispatch = useAppDispatch()

    const updatePOFulfillmentStatus = useCallback(
        async (organizationId: OrganizationId, linesIds: string[], status: FulfillmentStatus) => {
            try {
                setLoading(true)
                await purchaseOrdersApi.updateFulfillmentStatus(organizationId, purchaseOrderId, linesIds, status)
                setLoading(false)

                return toast.success(formatMessage(messages.statusUpdated))
            } catch (e) {
                setError(`${e}`)
                setLoading(false)
                toast.error(formatMessage(messages.error))
                throw e
            }
        },
        [purchaseOrderId, dispatch]
    )

    return {
        updatePOFulfillmentStatus,
        error,
        loading,
    }
}
