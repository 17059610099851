import React from "react"

import { CustomTooltip, CustomTooltipTitle } from "~/features/invoice/components/InvoiceLines/CustomTooltip"
import { RenderPrice } from "~/features/invoice/components/InvoiceLines/RenderPrice"
import { TooltipContainer } from "~/features/invoice/components/InvoiceLines/StyledDiv"
import { InvoiceLineError } from "~/features/invoice/core"
import { CurrencyCodes, InvoiceLineI } from "~/types"

interface Props {
    price: number
    fieldName: keyof InvoiceLineI
    onPriceChange: (price: number | null) => void
    onErrorClick: (error: InvoiceLineError) => void
    priceLabel: string
    error: InvoiceLineError | undefined
    readonly?: boolean
    fieldClassName?: string
    currency: CurrencyCodes
    hideLabel?: boolean
}

export const PriceField: React.FC<Props> = ({
    price,
    fieldName,
    onPriceChange,
    onErrorClick,
    priceLabel,
    error,
    readonly,
    fieldClassName,
    currency,
    hideLabel,
}) => {
    return (
        <>
            {error?.fields.includes(fieldName) ? (
                <CustomTooltip
                    title={<CustomTooltipTitle error={error} onErrorClick={onErrorClick} currency={currency} />}
                    arrow
                    placement="top"
                >
                    <TooltipContainer flex="3 1 3%">
                        <RenderPrice
                            price={price}
                            currency={currency}
                            onPriceChange={onPriceChange}
                            priceLabel={priceLabel}
                            error={error}
                            readonly={readonly}
                            fieldClassName={fieldClassName}
                            hideLabel={hideLabel}
                        />
                    </TooltipContainer>
                </CustomTooltip>
            ) : (
                <RenderPrice
                    price={price}
                    currency={currency}
                    onPriceChange={onPriceChange}
                    priceLabel={priceLabel}
                    error={null}
                    readonly={readonly}
                    fieldClassName={fieldClassName}
                    hideLabel={hideLabel}
                />
            )}
        </>
    )
}
