import styled from "@emotion/styled"
import {
    Background,
    BackgroundVariant,
    type Connection,
    Controls,
    type Node,
    NodeMouseHandler,
    Panel,
    ReactFlow,
    XYPosition,
    addEdge,
    useEdgesState,
    useNodesState,
    useReactFlow,
} from "@xyflow/react"
import "@xyflow/react/dist/style.css"
import React, { FC, useEffect, useMemo, useRef, useState } from "react"
import { Menu } from "react-feather"

import { Button } from "~/components"
import { SideBar } from "~/domains/orchestration/flows/components/SideBar"
import { useEditor, useEditorDispatch } from "~/domains/orchestration/flows/context/editorContext"
import { adaptFlowToEdges, adaptFlowToNodes, createNode, generateReadableId } from "~/domains/orchestration/flows/core"
import { useFlowValidator } from "~/domains/orchestration/flows/hooks"
import {
    type Flow,
    FlowNode,
    NodeType,
    isAddToBudgetNode,
    isEventTriggerNode,
    isSendEmailNode,
    isSetInvoiceLifecycleStatusNode,
    isSetPartnershipFieldNode,
} from "~/domains/orchestration/flows/types"

import { ButtonEdge } from "./ButtonEdge"
import { NodeList } from "./NodeList"
import {
    AddToBudgetNode,
    CheckNode,
    EventTriggerNode,
    FitsToBudgetNode,
    IfNode,
    InvoiceToPurchaseOrderMatchNode,
    SendEmailNode,
    SetInvoiceLifecycleStatusNode,
    SetPartnershipFieldNode,
} from "./nodes"

interface Props {
    flow: Flow
    handlePublish: (flow: Flow) => void
    hasWorkflowUpdatePermission: boolean
}

const NODE_WIDTH = 218

const Wrapper = styled.div`
    width: 80vw;
    height: calc(100vh - 100px);
`

const StyledControls = styled(Controls)`
    position: absolute;
    top: 10px;
    box-shadow: none;
`

const StyledPanel = styled(Panel)`
    padding: var(--spacing-md);
    margin-bottom: 40px;
    background-color: var(--color-white);
    border-radius: var(--border-radius-sm);
`

const StyledError = styled(Panel)`
    color: var(--color-red);
    padding: var(--spacing-md);
    background-color: var(--color-white);
    border-radius: var(--border-radius-sm);
`

export const EditorPanel: FC<Props> = ({ flow, hasWorkflowUpdatePermission }) => {
    const reactFlowWrapper = useRef(null)
    const state = useEditor()
    const currentFlow = state.flow || flow
    const { error } = state

    const [nodes, setNodes, onNodesChange] = useNodesState(adaptFlowToNodes(currentFlow))
    const [edges, setEdges, onEdgesChange] = useEdgesState(adaptFlowToEdges(currentFlow))
    const [selectedNode, setSelectedNode] = useState<FlowNode>()
    const { screenToFlowPosition } = useReactFlow()
    const dispatch = useEditorDispatch()

    const [currentNodeType, setCurrentNodeType] = useState<NodeType | null>(null)
    const [showBottomPanel, setShowBottomPanel] = useState(false)

    const nodeTypes = useMemo(
        () => ({
            [NodeType.EVENT_TRIGGER_NODE]: EventTriggerNode,
            [NodeType.IF_NODE]: IfNode,
            [NodeType.SET_PARTNERSHIP_FIELD_NODE]: SetPartnershipFieldNode,
            [NodeType.CHECK_NODE]: CheckNode,
            [NodeType.SEND_EMAIL_NODE]: SendEmailNode,
            [NodeType.ADD_TO_BUDGET_NODE]: AddToBudgetNode,
            [NodeType.INVOICE_TO_PURCHASE_ORDER_MATCH_NODE]: InvoiceToPurchaseOrderMatchNode,
            [NodeType.SET_INVOICE_LIFECYCLE_STATUS_NODE]: SetInvoiceLifecycleStatusNode,
            [NodeType.FITS_TO_BUDGET_NODE]: FitsToBudgetNode,
        }),
        []
    )

    const edgeTypes = useMemo(
        () => ({
            button: ButtonEdge,
        }),
        []
    )

    const updateFlow = (flow: Flow) => {
        dispatch({
            type: "SET_FLOW",
            payload: flow,
        })

        dispatch({
            type: "SET_FLOW_PUBLISHABLE",
            payload: true,
        })
    }

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>): void => {
        event.preventDefault()
        event.dataTransfer.dropEffect = "move"
    }

    const handleDragStop = (event: React.MouseEvent, node: Node): void => {
        event.preventDefault()

        const { nodes: flowNodes } = currentFlow
        const currentFlowNode = flowNodes.find((fn) => fn.slug === node.id)
        if (!currentFlowNode) return

        const position = {
            x: Math.floor(node.position.x),
            y: Math.floor(node.position.y),
        }

        dispatch({
            type: "UPDATE_NODE",
            payload: {
                ...currentFlowNode,
                metadata: { ...currentFlowNode.metadata, position },
            },
        })

        dispatch({
            type: "SET_FLOW_PUBLISHABLE",
            payload: true,
        })
    }

    const selectCallback = (nodeType: NodeType) => {
        setCurrentNodeType(nodeType)
    }

    const onConnect = (params: Connection) => {
        const nodes = [...currentFlow.nodes]
        const sourceNodeIndex = nodes.findIndex((node) => node.slug === params.source)
        if (sourceNodeIndex === -1) return

        // TODO: Create a function to handle this

        if (
            isEventTriggerNode(nodes[sourceNodeIndex]) ||
            isSetPartnershipFieldNode(nodes[sourceNodeIndex]) ||
            isSendEmailNode(nodes[sourceNodeIndex]) ||
            isAddToBudgetNode(nodes[sourceNodeIndex]) ||
            isSetInvoiceLifecycleStatusNode(nodes[sourceNodeIndex])
        ) {
            nodes[sourceNodeIndex] = { ...nodes[sourceNodeIndex], nextNode: params.target }
        } else {
            nodes[sourceNodeIndex] = params.sourceHandle?.includes("success")
                ? { ...nodes[sourceNodeIndex], nextIfSuccess: params.target }
                : { ...nodes[sourceNodeIndex], nextIfFailure: params.target }
        }

        updateFlow({ ...currentFlow, nodes })

        setEdges((eds) => addEdge(params, eds))
    }

    const createAndAddNode = (nodeType: NodeType, position: XYPosition) => {
        const id = generateReadableId(nodeType, currentFlow.nodes)

        const node = createNode(nodeType, id, position)

        // TODO: Handle the case when node is null
        if (!node) return

        updateFlow({ ...currentFlow, nodes: [...currentFlow.nodes, node] })

        const newNode = {
            id,
            type: nodeType,
            position,
            data: node,
        }

        setNodes((nds) => nds.concat(newNode))
    }

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault()

        if (!currentNodeType) return

        // TODO: For now node width is hardcoded, it will be dynamic if needed
        const position = screenToFlowPosition({
            x: Math.floor(event.clientX - NODE_WIDTH),
            y: Math.floor(event.clientY),
        })

        createAndAddNode(currentNodeType, position)
    }

    const handleNodeClick = (nodeType: NodeType) => {
        setCurrentNodeType(nodeType)

        const centerX = window.innerWidth / 2
        const centerY = window.innerHeight / 2

        // TODO: For now node width is hardcoded, it will be dynamic if needed
        const position = screenToFlowPosition({
            x: Math.floor(centerX - NODE_WIDTH / 2),
            y: Math.floor(centerY),
        })

        createAndAddNode(nodeType, position)
    }

    const handlSelectNode: NodeMouseHandler<Node> = (_, node) => {
        const selected = currentFlow.nodes.find((n) => n.slug === node.data.slug)
        setSelectedNode(selected)
        dispatch({
            type: "SET_FLOW",
            payload: currentFlow,
        })
        setShowBottomPanel(false)
    }

    const handlUnelectNode = () => {
        setSelectedNode(undefined)
    }

    const handleDragStart =
        (nodeType: NodeType) =>
        (event: React.DragEvent<HTMLDivElement>): void => {
            selectCallback(nodeType)
            event.dataTransfer.effectAllowed = "move"
        }

    const handleClose = () => {
        setShowBottomPanel(true)
    }

    const handleOpen = () => {
        setShowBottomPanel(false)
    }

    useEffect(() => {
        setNodes(adaptFlowToNodes(currentFlow, selectedNode))
        setEdges(adaptFlowToEdges(currentFlow))
    }, [currentFlow])

    useFlowValidator(currentFlow)

    useEffect(() => {
        dispatch({ type: "SET_FLOW", payload: flow })
    }, [])

    const hasError = error !== null && error.trim().length > 0

    return (
        <>
            {hasWorkflowUpdatePermission && (
                <>
                    {showBottomPanel ? (
                        <div>
                            <Button type="primary" className="flows-editor-sideBar-open" onClick={handleOpen}>
                                <Menu size={18} />
                            </Button>
                        </div>
                    ) : (
                        <SideBar
                            handleDragStart={handleDragStart}
                            selectedNode={selectedNode}
                            unselectCallback={handlUnelectNode}
                            handleNodeClick={handleNodeClick}
                            handleClose={handleClose}
                        />
                    )}
                </>
            )}

            <Wrapper ref={reactFlowWrapper}>
                <ReactFlow
                    key={flow.id}
                    nodes={nodes}
                    edges={edges}
                    nodeTypes={nodeTypes}
                    edgeTypes={edgeTypes}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onConnect={onConnect}
                    onDragOver={handleDragOver}
                    onNodeDragStop={handleDragStop}
                    onDrop={handleDrop}
                    onNodeClick={handlSelectNode}
                    onPaneClick={handlUnelectNode}
                    snapToGrid
                    snapGrid={[50, 50]}
                    fitView
                >
                    <StyledControls />
                    {hasError && <StyledError position="top-center">{error}</StyledError>}
                    {showBottomPanel && (
                        <StyledPanel position="bottom-center">
                            <NodeList handleDragStart={handleDragStart} handleClick={handleNodeClick} />
                        </StyledPanel>
                    )}
                    <Background color="var(--primary-color)" variant={BackgroundVariant.Dots} />
                </ReactFlow>
            </Wrapper>
        </>
    )
}
