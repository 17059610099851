import { useEffect } from "react"
import { MessageDescriptor, useIntl } from "react-intl"

import { useEditorDispatch } from "~/domains/orchestration/flows/context/editorContext"
import { messages } from "~/domains/orchestration/flows/messages"
import { Flow, FlowValidation, NodeType, hasNextNode, isFlowInvalid } from "~/domains/orchestration/flows/types"

const eventTriggerNodeValidation = (
    flow: Flow,
    formatMessage: (message: MessageDescriptor) => string
): FlowValidation => {
    const eventTriggerNode = flow.nodes.filter((node) => node.type === NodeType.EVENT_TRIGGER_NODE)

    // Check if flow has one event trigger node
    if (eventTriggerNode.length < 1) {
        return { isNotValid: true, error: formatMessage({ ...messages.error.noEventTrigger }) }
    }

    // Check if flow has only one event trigger node
    if (eventTriggerNode.length > 1) {
        return { isNotValid: true, error: formatMessage({ ...messages.error.multipleEventTriggers }) }
    }

    // Check if event trigger node is connected to any other node
    if (eventTriggerNode.length === 1 && eventTriggerNode[0].nextNode === null) {
        return { isNotValid: true, error: formatMessage({ ...messages.error.triggerNodeNotConnected }) }
    }

    return { isValid: true }
}

const noCyclesValidation = ({ nodes }: Flow, formatMessage: (message: MessageDescriptor) => string): FlowValidation => {
    // Will check if two node have the same next node id
    const nextNodeIdSet = new Set<string>()

    for (const node of nodes) {
        if (!hasNextNode(node)) continue

        if (nextNodeIdSet.has(node.nextNode)) {
            return { isNotValid: true, error: formatMessage({ ...messages.error.cycleDetected }) }
        }

        nextNodeIdSet.add(node.nextNode)
    }

    return { isValid: true }
}

const validateFlow = (flow: Flow, formatMessage: (message: MessageDescriptor) => string): FlowValidation => {
    // Validate event trigger node
    const eventTriggerNodeValidationResult = eventTriggerNodeValidation(flow, formatMessage)

    if (isFlowInvalid(eventTriggerNodeValidationResult)) {
        return eventTriggerNodeValidationResult
    }

    // Validate no cycles
    const noCyclesValidationResult = noCyclesValidation(flow, formatMessage)

    if (isFlowInvalid(noCyclesValidationResult)) {
        return noCyclesValidationResult
    }

    return { isValid: true }
}

export const useFlowValidator = (flow: Flow) => {
    const { formatMessage } = useIntl()
    const dispatch = useEditorDispatch()

    useEffect(() => {
        const flowValidation = validateFlow(flow, formatMessage)

        dispatch({
            type: "SET_FLOW_ERROR",
            payload: isFlowInvalid(flowValidation) ? flowValidation.error : null,
        })
    }, [flow])
}
