import React from "react"
import { Route } from "react-router-dom"
import { HOME_DASHBOARD } from "./routes"
import { Dashboard } from "./pages/Dashboard"

export default {
    routes: [
        <Route key="invoice_route">
            <Route path={HOME_DASHBOARD} element={<Dashboard />} />
        </Route>,
    ],
}
