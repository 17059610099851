import { Autocomplete, AutocompleteRenderInputParams, Box, TextField, createFilterOptions } from "@mui/material"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { FormattedMessage, defineMessage } from "react-intl"

import { OrganizationId, ViewTypeI } from "~/types"

import { useFetchOrganizationPurchaseOrders } from "../../store/hooks"
import { PurchaseOrders } from "../../types/PurchaseOrders"

const defaultLabel = defineMessage({
    id: "transactions.invoice.documentRelations.purchaseOrderPicker.label",
    defaultMessage: "Link a purchase order",
})

const getOptionId = (option: PurchaseOrders | string) => {
    if (typeof option === "string") return option
    return option.id
}

const getOptionLabel = (option: PurchaseOrders | string) => {
    if (typeof option === "string") return option
    return `PO#${option.shortId}: ${option.description}`
}

const filterOptions = createFilterOptions({
    stringify: (option: PurchaseOrders) =>
        `${option.shortId} ${option.description} ${option.supplierName} ${option.requesterName}`,
})

const renderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: PurchaseOrders) => {
    return (
        <Box component="li" {...props}>
            {getOptionLabel(option)}
        </Box>
    )
}

const sortPurchaseOrderByDate = (po1: PurchaseOrders, po2: PurchaseOrders) => {
    if (po1.creationDate < po2.creationDate) return 1
    if (po1.creationDate > po2.creationDate) return -1
    return 0
}

interface PurchaseOrderPickerProps {
    organizationId: OrganizationId
    viewType: ViewTypeI
    value: PurchaseOrders | null
    onChange: (purchaseOrderId: string) => void
    blackListIds?: string[]
}

export const PurchaseOrderPicker: React.FC<PurchaseOrderPickerProps> = ({
    organizationId,
    viewType,
    value,
    onChange,
    blackListIds,
}) => {
    const [inputValue, setInputValue] = useState<string>("")
    // TODO change this
    const { purchaseOrders, loading, fetchPurchaseOrders } = useFetchOrganizationPurchaseOrders(
        viewType,
        organizationId
    )

    useEffect(() => {
        fetchPurchaseOrders(false)
    }, [])

    const filteredPurchaseOrders = useMemo(
        () =>
            purchaseOrders.filter((PO) => !blackListIds || !blackListIds.includes(PO.id)).sort(sortPurchaseOrderByDate),
        [purchaseOrders, blackListIds]
    )

    const onInputChange = useCallback((_event: React.SyntheticEvent<Element, Event>, value: string) => {
        setInputValue(value)
    }, [])

    const onAucompleteChange = useCallback(
        (_event: React.SyntheticEvent<Element, Event>, value: string | PurchaseOrders | null) => {
            if (value && typeof value === "object") {
                onChange(value.id)
                setInputValue("")
            }
        },
        [onChange]
    )

    const renderInput = useCallback((params: AutocompleteRenderInputParams) => {
        return <TextField {...params} label={<FormattedMessage {...defaultLabel} />} />
    }, [])

    return (
        <Autocomplete
            inputValue={inputValue}
            onInputChange={onInputChange}
            filterOptions={filterOptions}
            autoHighlight
            loading={loading}
            options={filteredPurchaseOrders}
            getOptionLabel={getOptionId}
            value={value}
            onChange={onAucompleteChange}
            fullWidth
            renderInput={renderInput}
            renderOption={renderOption}
        />
    )
}
