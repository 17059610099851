import * as t from "io-ts"
import { optional } from "~/types/utils"
import { FolderId } from "./FolderId"
import { genericParser } from "~/utils"
import { isResultSuccess, ResultSuccess } from "~/core/Result"

export const FolderIO = t.intersection([
    t.type({
        id: t.string,
        name: t.string,
        createdAt: t.string,
        updatedAt: t.string,
    }),
    t.partial({
        parentId: optional(t.string),
    }),
])

export type FolderI = t.TypeOf<typeof FolderIO> & {
    id: FolderId
    parentId?: FolderId | null
}

export const parseFolder = (data: unknown) => {
    const result = genericParser(data, FolderIO)
    if (isResultSuccess(result)) {
        return ResultSuccess<FolderI>({
            ...result.result,
            id: result.result.id as FolderId,
            parentId: result.result.parentId as FolderId | null,
        })
    }
    return result
}

export const FolderSummaryIO = t.type({
    id: t.string,
    name: t.string,
    created_at: t.string,
})

export type FolderSummaryI = {
    id: FolderId
    name: string
    created_at: string
}

export const parseFolderSummary = (data: unknown) => {
    const result = genericParser(data, FolderSummaryIO)
    if (isResultSuccess(result)) {
        return ResultSuccess<FolderSummaryI>({
            ...result.result,
            id: result.result.id as FolderId,
        })
    }
    return result
}
