import React, { useCallback, useEffect, useState } from "react"
import { useIntl } from "react-intl"

import { EditAmount } from "~/features/supplier/components"
import { OrganizationId } from "~/types"

import {
    useCustomFieldValue,
    usePendingFieldValue,
    useSavePendingCustomFieldValue,
    useUpdateCustomFieldValue,
} from "../../hooks"
import { useCreateCustomField } from "../../hooks/useCreateCustomField"
import { CustomFieldI } from "../../types/CustomField"
import { CustomFieldObjectType } from "../../types/CustomFieldObjectType"

interface Props {
    objectId: string
    isTemporaryId: boolean
    quantity: number
    customField: CustomFieldI
    contextType: CustomFieldObjectType
    contextId?: string
    organizationId?: OrganizationId
}

export const ESGCustomFieldNumberCell: React.FC<Props> = ({
    objectId,
    isTemporaryId,
    quantity,
    customField,
    contextId,
    contextType,
    organizationId,
}) => {
    const { formatNumber } = useIntl()
    const [value, setValue] = useState<number>(0)
    const savedValue = useCustomFieldValue(customField, objectId)
    const pendingValue = usePendingFieldValue(customField, objectId)
    const currentValue = savedValue ?? pendingValue
    const createCustomField = useCreateCustomField()
    const updateCustomFieldValue = useUpdateCustomFieldValue(objectId, customField)
    const savePendingCustomFieldValue = useSavePendingCustomFieldValue(customField)

    useEffect(() => {
        if (currentValue) {
            const actualValue = parseFloat(currentValue.value)
            if (!isNaN(actualValue)) {
                setValue(actualValue)
            }
        }
    }, [currentValue])

    const onChange = useCallback(
        (newValue: number | null) => {
            if (typeof newValue === "number") {
                setValue(newValue)
                if (contextId && !isTemporaryId) {
                    if (!savedValue) {
                        createCustomField({
                            name: customField.name,
                            unit: customField.unit[0],
                            value: newValue.toString(),
                            quantity,
                            objectId,
                            contextType,
                            contextId,
                            organizationId,
                        })
                    } else {
                        updateCustomFieldValue(savedValue.id, newValue.toString())
                    }
                } else {
                    // if no contextId save as pending value
                    savePendingCustomFieldValue({
                        name: customField.name,
                        unit: customField.unit[0],
                        value: newValue.toString(),
                        quantity,
                        objectId,
                        contextType,
                        contextId,
                        organizationId,
                    })
                }
            }
        },
        [
            isTemporaryId,
            customField,
            savedValue,
            quantity,
            createCustomField,
            objectId,
            contextId,
            contextType,
            updateCustomFieldValue,
            savePendingCustomFieldValue,
        ]
    )

    return (
        <EditAmount
            value={value}
            handleUpdate={onChange}
            disableHelpers
            renderer={(value) =>
                `${formatNumber(value, {
                    style: "decimal",
                    maximumSignificantDigits: 2,
                })} ${customField.unit[0]}`
            }
        />
    )
}
