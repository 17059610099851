import { styled } from "@mui/material"
import { DataGridPremium } from "@mui/x-data-grid-premium"
import { useMemo } from "react"
import { FormattedMessage, defineMessages } from "react-intl"

import { selectActiveTab } from "~/domains/payment/payment-method-details/store/paymentMethodDetailsSlice"
import { PaymentMethodDetails } from "~/domains/payment/payment-method-details/types"
import { getGridColumnsByActiveTab } from "~/domains/payment/payment-method-details/utils/getGridColumnsByActiveTab"
import { useAppSelector } from "~/store/hooks"

const messages = defineMessages({
    noRows: {
        id: "payment.paymentMethodDetailsList.noRows",
        defaultMessage: "No payment methods",
    },
})

const NoRowsOverlay = styled("div")({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    padding: "20px",
})

const gridStyles = {
    border: 0,
    maxWidth: "100%",
    "& .MuiDataGrid-cell:focus": {
        outline: "none",
    },
    "& .MuiDataGrid-cell:focus-within": {
        outline: "none",
    },
}

interface PaymentMethodDetailsListProps {
    items: PaymentMethodDetails[]
    loading?: boolean
}

export const PaymentMethodDetailsList = ({ items, loading = false }: PaymentMethodDetailsListProps) => {
    const activeTab = useAppSelector(selectActiveTab)
    const columns = useMemo(() => getGridColumnsByActiveTab(activeTab), [activeTab])
    const rows = useMemo(() => items.filter((item) => item.payment_method_type === activeTab), [items, activeTab])

    const renderNoRows = () => {
        return (
            <NoRowsOverlay>
                <FormattedMessage {...messages.noRows} />
            </NoRowsOverlay>
        )
    }

    return (
        <DataGridPremium
            sx={gridStyles}
            columns={columns}
            rows={rows}
            getRowId={(row) => row.id}
            rowCount={rows.length}
            loading={loading}
            rowSelection={false}
            autoHeight
            slots={{ noResultsOverlay: renderNoRows, noRowsOverlay: renderNoRows }}
            hideFooter
        />
    )
}
