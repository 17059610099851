import * as t from "io-ts"

import { genericParser } from "~/utils"

import { ContractDataIO } from "./ContractData"
import { ContractDurationUnit } from "./ContractUnit"

const ContractResultTermIO = t.type({
    startDate: t.string,
    duration: t.union([t.string, t.number]),
    endDate: t.string,
    unit: t.string,
})

const AddressIO = t.type({
    city: t.string,
    country: t.string,
    line1: t.string,
    line2: t.string,
    postalCode: t.string,
})

const ContractResultPartyIO = t.type({
    address: AddressIO,
    countryCode: t.string,
    involvement: t.string,
    name: t.string,
    registrationNumber: t.string,
    taxId: t.string,
})

const LatePaymentIO = t.type({
    interval: t.string,
    rate: t.union([t.number, t.string]),
})

const PaymentPenaltiesIO = t.type({
    latePayment: LatePaymentIO,
})

const ContractPaymentDetailsIO = t.type({
    IBAN: t.string,
    SWIFT: t.string,
    accountNumber: t.string,
    bank: t.string,
})

const ContractPaymentConditionsIO = t.type({
    currency: t.string,
    dueDate: t.string,
    paymentDetails: t.union([t.string, ContractPaymentDetailsIO]),
    paymentMethods: t.string,
    penalties: PaymentPenaltiesIO,
})

const ContractPricingRenewalIO = t.type({
    applyAfterRenewals: t.string,
    type: t.string,
    value: t.string,
})

const ContractPricingAdjustmentsIO = t.type({
    renewal: ContractPricingRenewalIO,
})

const ContractPricingIO = t.type({
    currency: t.string,
    initialPrice: t.union([t.string, t.number]),
    adjustments: ContractPricingAdjustmentsIO,
})

const ContractRenewalNoticePeriodIO = t.type({
    duration: t.union([t.string, t.number]),
    requiredForTermination: t.union([t.string, t.boolean]),
    unit: t.string,
})

const ContractRenewalTermIO = t.type({
    duration: t.union([t.string, t.number]),
    unit: t.string,
})

const ContractRenewalOptionsIO = t.type({
    autoRenewal: t.string,
    maximumRenewals: t.union([t.string, t.number]),
    noticePeriod: ContractRenewalNoticePeriodIO,
    otherInformation: t.string,
    renewalTerm: ContractRenewalTermIO,
})

const ContractOcrDataIO = t.type({
    isContract: t.boolean,
    initialTerm: ContractResultTermIO,
    parties: t.array(ContractResultPartyIO),
    paymentConditions: ContractPaymentConditionsIO,
    pricing: ContractPricingIO,
    renewalOptions: ContractRenewalOptionsIO,
})

const ContractResultIO = t.intersection([
    t.type({
        is_safe: t.boolean,
        timestamp: t.string,
        contractData: ContractOcrDataIO,
    }),
    t.partial({
        data: ContractDataIO,
    }),
])

export type ContractResultTermI = t.TypeOf<typeof ContractResultTermIO>
export type ContractResultPartyI = t.TypeOf<typeof ContractResultPartyIO>
export type ContractResultI = t.TypeOf<typeof ContractResultIO>
export type ContractOcrDataI = t.TypeOf<typeof ContractOcrDataIO>

export const parseContractResult = (data: unknown) => {
    return genericParser<ContractResultI>(data, ContractResultIO)
}

export const parseContractDuration = (duration: string): ContractDurationUnit | null => {
    return Object.values(ContractDurationUnit).includes(duration as ContractDurationUnit)
        ? (duration as ContractDurationUnit)
        : null
}
