import { FC } from "react"

import { Steps } from "~/components"
import { usePatchUpdateInvoiceMutation } from "~/domains/transactions/invoices-v1/api/invoiceApiV1"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { InvoiceI } from "~/types"

interface InvoiceLifeCycleProps {
    invoice: InvoiceI
}

export const InvoiceLifeCycle: FC<InvoiceLifeCycleProps> = ({ invoice }) => {
    const organizationId = useAppSelector(selectCurrentOrganizationId)
    const [patchUpdateInvoice] = usePatchUpdateInvoiceMutation()

    const party = invoice.parties[organizationId || ""]
    const currentLifecycle = party?.currentLifecycle

    if (!currentLifecycle || !party?.template?.lifecycle?.default) return null

    const lifecycle = [party?.template?.lifecycle?.default, ...(party?.template?.lifecycle?.other || [])]
    const currentLifecycleIndex = lifecycle.findIndex(({ status }) => status === currentLifecycle)

    if (currentLifecycleIndex === -1) return null

    const handleUpdateLifecycle = (status: string) => {
        if (!organizationId) return

        patchUpdateInvoice({ id: invoice.id, parties: { [organizationId]: { currentLifecycle: status } } })
    }

    const steps = lifecycle.map(({ status, description }) => ({
        key: status,
        name: status,
        description: description,
        isCurrentLifecycle: status === currentLifecycle,
        onClick: handleUpdateLifecycle,
    }))

    return <Steps steps={steps} />
}
