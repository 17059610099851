import React from "react"
import { defineMessages, useIntl } from "react-intl"
import { styled } from "@mui/material"
import { AlertTriangle } from "react-feather"
import { ModalConfirm } from "~/components"
import { useInactivityDetection } from "~/hooks"

const messages = defineMessages({
    title: { id: "modal.inactivity.title", defaultMessage: "Warning! You've been inactive for a while." },
    message: {
        id: "modal.inactivity.message",
        defaultMessage: "To maintain optimal performance, the application will refresh in {countdown} seconds.",
    },
    prevention: {
        id: "modal.inactivity.prevention",
        defaultMessage: "To prevent this refresh, simply click anywhere, press any key, or move the mouse.",
    },
    cancel: { id: "modal.inactivity.cancel", defaultMessage: "Cancel" },
})

const INACTIVITY_TIMEOUT = 15 * 60 * 1000
const WARNING_TIME = 60 * 1000

const reloadApp = () => location.reload()

const Title = styled("h2")`
    text-align: center;
    padding-bottom: 10px;
`

const Text = styled("div")`
    margin-bottom: 20px;
`

export const InactivityDetection = () => {
    const { formatMessage } = useIntl()
    const { countdown, isWarningVisible } = useInactivityDetection(INACTIVITY_TIMEOUT, WARNING_TIME, reloadApp)

    return (
        <ModalConfirm
            open={isWarningVisible}
            icon={<AlertTriangle size={48} color="var(--color-yellow)" />}
            title={<Title>{formatMessage(messages.title)}</Title>}
            message={
                <>
                    <Text>{formatMessage(messages.message, { countdown })}</Text>
                    <Text>{formatMessage(messages.prevention)}</Text>
                </>
            }
        />
    )
}
