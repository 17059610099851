import { Grid } from "@mui/material"
import { FC, useMemo } from "react"
import { defineMessages, useIntl } from "react-intl"

import { Card } from "~/components"
import { ItemLabel } from "~/components/ItemLabel/ItemLabel"
import { InvoiceI } from "~/types"

const messages = defineMessages({
    title: {
        id: "supplier.extract.title",
        defaultMessage: "Informations",
    },
    initialAmount: { id: "buyer.invoice.details.initialAmount", defaultMessage: "Initial amount" },
    tax: { id: "buyer.invoice.details.tax", defaultMessage: "Tax" },
    currency: { id: "buyer.invoice.details.currency", defaultMessage: "Currency" },
    paymentTerms: { id: "buyer.invoice.details.paymentTerms", defaultMessage: "Payment terms" },
    paymentInstructions: { id: "buyer.invoice.details.paymentInstructions", defaultMessage: "Payment instructions" },
    notes: { id: "buyer.invoice.details.notes", defaultMessage: "Notes" },
    startDate: { id: "buyer.invoice.details.startDate", defaultMessage: "Issued date" },
    endDate: { id: "buyer.invoice.details.endDate", defaultMessage: "Due date" },
    referenceNumber: { id: "buyer.invoice.details.referenceNumber", defaultMessage: "Reference number" },
})

interface InvoiceGeneralInfoProps {
    invoice: InvoiceI
}

export const InvoiceGeneralInfo: FC<InvoiceGeneralInfoProps> = ({ invoice }) => {
    const { formatMessage, formatDate } = useIntl()

    const dataItems = useMemo(
        () => [
            {
                label: formatMessage(messages.startDate),
                value: formatDate(invoice.issuedAt),
            },
            {
                label: formatMessage(messages.endDate),
                value: formatDate(invoice.dueDate),
            },
            {
                label: formatMessage(messages.currency),
                value: Object.keys(invoice.totalPriceWithoutTax).join(", "),
            },
            {
                label: formatMessage(messages.referenceNumber),
                value: invoice.number,
            },
        ],
        [invoice]
    )

    return (
        <Card title={formatMessage(messages.title)} expandable>
            <Grid container spacing={2}>
                {dataItems.map(({ label, value }) => (
                    <Grid key={label} item xs={12} sm={6}>
                        <ItemLabel>{label}</ItemLabel>
                        <p>{value || "-"}</p>
                    </Grid>
                ))}
            </Grid>
        </Card>
    )
}
