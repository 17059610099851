import { Tooltip } from "@mui/material"
import { FC } from "react"
import { useIntl } from "react-intl"
import { generatePath, useNavigate } from "react-router-dom"

import { Card } from "~/components"
import { messages } from "~/domains/orchestration/flows/messages"
import { EDITOR } from "~/domains/orchestration/flows/routes"
import { FlowItem } from "~/domains/orchestration/flows/types"

import "./FlowCard.scss"

interface Props {
    flow: FlowItem
}

export const FlowCard: FC<Props> = ({ flow }) => {
    const { name, version, enabled } = flow
    const { formatMessage } = useIntl()
    const navigate = useNavigate()

    const handleClick = () => {
        const path = generatePath(EDITOR, {
            flowId: flow.id,
        })
        navigate(path, { state: { version: flow.version } })
    }

    return (
        <Card title={name} onClick={handleClick} className="flows-list-flowCard">
            <Tooltip title={formatMessage(enabled ? messages.flowCard.enabled : messages.flowCard.disabled)}>
                <span className={`flows-list-flowCard-dot-${enabled ? "enabled" : "disabled"}`} />
            </Tooltip>
            <p className="flows-list-flowCard-version">version : {version}</p>
        </Card>
    )
}
