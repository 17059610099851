import { Tooltip, TooltipProps, styled, tooltipClasses } from "@mui/material"
import React from "react"
import { FormatNumberOptions, useIntl } from "react-intl"

import { WarningAlert } from "~/components/Alerts/WarningAlert"
import { errorMessages } from "~/features/invoice/components/InvoiceLines/errorMessages"
import { InvoiceLineError } from "~/features/invoice/core"
import { invoiceDiscountErrorMessages } from "~/features/invoice/core/invoiceDiscount"
import { CurrencyCodes } from "~/types"

export const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: "none",
        backgroundColor: "var(--color-white)",
        padding: 0,
        border: "1px solid var(--color-light-silver)",
    },
    [`& .${tooltipClasses.tooltip} .MuiAlert-root`]: {
        backgroundColor: "var(--color-white)",
    },
})

interface Props {
    error: InvoiceLineError
    onErrorClick: (error: InvoiceLineError) => void
    currency?: CurrencyCodes
}

export const CustomTooltipTitle: React.FC<Props> = ({
    error,
    onErrorClick,
    currency: currencyCode = CurrencyCodes.EUR,
}) => {
    const { formatNumber, formatMessage } = useIntl()
    const currency: FormatNumberOptions = {
        style: "currency",
        currency: currencyCode,
        currencyDisplay: "narrowSymbol",
    }

    return (
        <div onClick={() => onErrorClick(error)}>
            <WarningAlert autoCorrectionMessage={invoiceDiscountErrorMessages.automaticCorrection}>
                {formatMessage(errorMessages[error.error], {
                    computedValueError: (
                        <span className="invoice-line-error-value">
                            {formatNumber(error.computedValueError, currency)}
                        </span>
                    ),
                    referenceValue: formatNumber(error.referenceValue, currency),
                })}
            </WarningAlert>
        </div>
    )
}
