import { useCallback } from "react"
import { useIntl, defineMessages } from "react-intl"
import { toast } from "react-toastify"
import { invoiceApi } from "~/api"
import { useAppDispatch } from "~/store/hooks"
import { UserId } from "~/types"
import { invoiceActions } from "../invoiceSlice"

export const removeInvolvedPeopleMessages = defineMessages({
    success: {
        id: "invoice.removeInvolvedPeople.success",
        defaultMessage: "Involved person{s} removed",
    },
    error: {
        id: "invoice.removeInvolvedPeople.error",
        defaultMessage: "Error removing involved person: {error}",
    },
})

export const useRemoveInvolvedPeople = (invoiceId: string | null | undefined) => {
    const { formatMessage } = useIntl()
    const dispatch = useAppDispatch()

    return useCallback(
        async (userIds: UserId[]) => {
            if (invoiceId) {
                try {
                    dispatch(invoiceActions.removeInvolvedPeople())

                    await Promise.all(userIds?.map((userId) => invoiceApi.removeInvolvedPerson(invoiceId, userId)))

                    dispatch(invoiceActions.removeInvolvedPeopleSuccess(userIds))
                    toast.success(
                        formatMessage(removeInvolvedPeopleMessages.success, {
                            s: userIds.length > 1 ? "s" : "",
                        })
                    )
                } catch (error) {
                    dispatch(invoiceActions.removeInvolvedPeopleFailed())
                    toast.error(formatMessage(removeInvolvedPeopleMessages.error, { error: `${error}` }))
                }
            }
        },
        [invoiceId]
    )
}
