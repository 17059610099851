import { defineMessages, useIntl } from "react-intl"
import { toast } from "react-toastify"
import { invoiceApi } from "~/api"
import { accountActions } from "~/store/account/accountSlice"
import { useAppDispatch } from "~/store/hooks"
import { InvoiceId, OrganizationId, ViewTypeI } from "~/types"
import { isDefined } from "~/utils/isDefined"

const messages = defineMessages({
    invoiceRejected: {
        id: "invoice.ocrInvoice.invoiceRejected",
        defaultMessage: "Your invoice has been successfully moved to trash.",
    },
    invoicesRejected: {
        id: "invoice.ocrInvoice.invoicesRejected",
        defaultMessage: "Your invoices has been successfully moved to trash.",
    },
    unableToRejectInvoice: {
        id: "invoice.ocrInvoice.unableToRejectInvoice",
        defaultMessage: "An error occured: your invoice could not be moved to trash.",
    },
    unableToRejectInvoices: {
        id: "invoice.ocrInvoice.unableToRejectInvoices",
        defaultMessage: "An error occured: your invoices could not be moved to trash.",
    },
    unableToRejectSomeInvoices: {
        id: "invoice.ocrInvoice.unableToRejectSomeInvoices",
        defaultMessage: "An error occured: {count} invoice{s} could not be moved to trash.",
    },
})

export const useRejectInvoice = () => {
    const dispatch = useAppDispatch()
    const { formatMessage } = useIntl()
    return async (view: ViewTypeI, invoiceId: InvoiceId, organizationId: OrganizationId) => {
        try {
            await invoiceApi.reject(invoiceId, organizationId)
            dispatch(accountActions.rejectInvoice({ invoiceId, view }))
            toast.success(formatMessage(messages.invoiceRejected))
            return true
        } catch {
            toast.error(formatMessage(messages.unableToRejectInvoice))
        }
        return false
    }
}

const getInvoiceId = (r: PromiseSettledResult<unknown>, index: number, invoiceIds: InvoiceId[]): string | undefined =>
    r.status === "rejected" ? undefined : invoiceIds[index]

export const useRejectInvoices = () => {
    const dispatch = useAppDispatch()
    const { formatMessage } = useIntl()
    return async (view: ViewTypeI, invoiceIds: InvoiceId[], organizationId: OrganizationId) => {
        const results = await Promise.allSettled(
            invoiceIds.map((invoiceId) => invoiceApi.reject(invoiceId, organizationId))
        )
        const successRejected = results.map((r, index) => getInvoiceId(r, index, invoiceIds)).filter(isDefined)
        const failRejectedLength = results.length - successRejected.length
        dispatch(accountActions.rejectInvoices({ invoiceIds: successRejected, view }))
        if (failRejectedLength > 0) {
            toast.error(
                formatMessage(
                    failRejectedLength === results.length
                        ? messages.unableToRejectInvoices
                        : messages.unableToRejectSomeInvoices,
                    { count: failRejectedLength, s: failRejectedLength > 1 ? "s" : "" }
                )
            )
        } else {
            toast.success(formatMessage(invoiceIds.length === 1 ? messages.invoiceRejected : messages.invoicesRejected))
        }
        return true
    }
}
