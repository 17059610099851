import React from "react"
import {
    sharedObjectPermissionsModalMessages,
    SpiceDBObjectType,
    SpiceDBPermissionsLiteI,
} from "~/domains/identity/features/roles-permissions/types/SpiceDB"
import { ObjectPermissions } from "./ObjectPermissions"
import { ChevronLeft } from "react-feather"
import { useIntl } from "react-intl"

interface Props {
    objectId: string
    objectType: SpiceDBObjectType
    sharedPermissions: SpiceDBPermissionsLiteI[] | null
    setShowOnlyList: (showOnlyList: boolean) => void
}

export const OnlyListView: React.FC<Props> = ({ objectId, objectType, sharedPermissions, setShowOnlyList }) => {
    const { formatMessage } = useIntl()
    const hideList = () => setShowOnlyList(false)

    return (
        <>
            <div className="shared-objects-header" onClick={hideList}>
                <ChevronLeft size={24} />
                <span>
                    {formatMessage(sharedObjectPermissionsModalMessages.collaborators, {
                        count: sharedPermissions?.length ?? 0,
                    })}
                </span>
            </div>
            <div className="shared-objects shared-objects-big">
                {sharedPermissions?.map((object) => (
                    <ObjectPermissions
                        key={object.objectId}
                        objectId={objectId}
                        objectType={objectType}
                        objectPermission={object}
                    />
                ))}
            </div>
        </>
    )
}
