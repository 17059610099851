import React, { FC, PropsWithChildren, createContext, useContext, useReducer } from "react"
import { Dispatch } from "react"

import type { Flow, FlowNode } from "~/domains/orchestration/flows/types"

type EditorState = {
    flow: Flow | null
    isFlowPublishable: boolean
    error: string | null
}

type EditorAction =
    | { type: "SET_FLOW"; payload: Flow }
    | { type: "UPDATE_NODE"; payload: FlowNode }
    | { type: "DELETE_NODE"; payload: string }
    | { type: "SET_FLOW_PUBLISHABLE"; payload: boolean }
    | { type: "SET_FLOW_ERROR"; payload: string | null }

const initialState: EditorState = {
    flow: null,
    isFlowPublishable: false,
    error: null,
}

const editorReducer = (state: EditorState, action: EditorAction): EditorState => {
    switch (action.type) {
        case "SET_FLOW":
            return { ...state, flow: action.payload }

        case "UPDATE_NODE": {
            const { flow } = state
            const { payload } = action

            if (!flow) return state
            const updatedFlow = {
                ...flow,
                nodes: flow.nodes.map((node) => (node.slug === payload.slug ? payload : node)),
            }
            return { ...state, flow: updatedFlow }
        }
        case "DELETE_NODE": {
            const { flow } = state
            const { payload } = action

            if (!flow) return state
            const updatedFlow = {
                ...flow,
                nodes: flow.nodes.filter((node) => node.slug !== payload),
            }
            return { ...state, flow: updatedFlow }
        }

        case "SET_FLOW_PUBLISHABLE":
            return { ...state, isFlowPublishable: action.payload }

        case "SET_FLOW_ERROR":
            return { ...state, error: action.payload }

        default:
            return state
    }
}

export const EditorContext = createContext<EditorState>({
    flow: null,
    isFlowPublishable: false,
    error: null,
})

const EditorDispatchContext = createContext<Dispatch<EditorAction>>(() => {
    throw new Error("EditorDispatchContext not provided")
})

export const EditorProvider: FC<PropsWithChildren> = ({ children }) => {
    const [flow, dispatch] = useReducer(editorReducer, initialState)

    return (
        <EditorContext.Provider value={flow}>
            <EditorDispatchContext.Provider value={dispatch}>{children}</EditorDispatchContext.Provider>
        </EditorContext.Provider>
    )
}

export function useEditor() {
    return useContext(EditorContext)
}

export function useEditorDispatch() {
    return useContext(EditorDispatchContext)
}
