import { Auth0Client, GenericError, PopupTimeoutError, AuthenticationError } from "@auth0/auth0-spa-js"
import { PopupCancelledError } from "@auth0/auth0-spa-js/src/errors"
import { clearCookies } from "~/utils/cookies"

const config = {
    domain: import.meta.env.VITE_API_AUTH0_DOMAIN,
    clientId: import.meta.env.VITE_API_AUTH0_CLIENT_ID,
    returnTo: window.location.origin,
    authorizationParams: {
        audience: import.meta.env.VITE_API_AUTH0_AUDIENCE,
    },
}

const client = new Auth0Client({
    domain: config.domain,
    clientId: config.clientId,
    cacheLocation: "localstorage",
    authorizationParams: config.authorizationParams,
    useRefreshTokens: true,
})

export const enum Auth0CacheMode {
    "ON" = "on",
    "OFF" = "off",
    "CACHE_ONLY" = "cache-only",
}

export type LoginPageType = "signin" | "signup"

export const auth0 = (locale = "en-EN") => {
    return {
        login: async function (page: LoginPageType = "signin") {
            try {
                await client.loginWithPopup({
                    authorizationParams: {
                        display: "touch",
                        ui_locales: locale,
                        screen_hint: page,
                    },
                })
            } catch (error) {
                if (error instanceof GenericError && error.error === "cancelled") {
                    return error.error
                } else {
                    if (error instanceof PopupCancelledError || error instanceof PopupTimeoutError) {
                        error.popup.close()
                    }
                    throw error
                }
            }
        },
        getToken: async function (cacheMode: Auth0CacheMode = Auth0CacheMode.ON) {
            try {
                const token = await client.getTokenSilently({ cacheMode })

                // Decode the token to get the payload
                const payload = JSON.parse(atob(token.split(".")[1]))
                const permissions = payload._permissions

                return { token, permissions }
            } catch (error) {
                const authError = error as AuthenticationError
                if (authError.error === "login_required" || authError.error === "invalid_grant") {
                    await client.logout({
                        logoutParams: {
                            returnTo: window.location.origin,
                        },
                    })
                } else {
                    console.error(authError)
                    throw authError
                }

                return { token: null, permissions: null }
            }
        },
        isAuthenticated: async function () {
            return client.isAuthenticated()
        },
        logout: async function (redirect = true) {
            if (localStorage) {
                const lang = localStorage.getItem("lang")
                localStorage.clear()
                localStorage.setItem("lang", lang as string)
            }
            clearCookies()
            if (redirect) {
                return client.logout({
                    logoutParams: {
                        returnTo: window.location.origin,
                    },
                })
            }
        },
    }
}
