import { useMemo } from "react"

import { useFetchPartnersData } from "~/domains/transactions/book-of-relations/store/hooks/useFetchPartnersData"
import {
    CollaborationStatus,
    PartnerAddressI,
    PartnerOrganizationI,
    PartnersDataI,
    PartnershipType,
} from "~/domains/transactions/book-of-relations/types"
import { OrganizationAddressI, OrganizationId } from "~/types"

interface ReturnType {
    organizations: PartnerOrganizationI[]
    loading: boolean
}

const getPartialPartnerAddress = (partnerAddress: PartnerAddressI): OrganizationAddressI => ({
    addressLine: "",
    secondaryAddressLine: "",
    city: partnerAddress.city,
    zipCode: "",
    country: "",
})

const getCollaborationStatus = (
    partner: PartnersDataI,
    partnershipType: PartnershipType
): CollaborationStatus | undefined | null => {
    return partnershipType === PartnershipType.SUPPLIER
        ? partner.supplier?.collaborationStatus
        : partner.buyer?.collaborationStatus
}

export const usePartnersAsOrganizations = (
    organizationId: OrganizationId | undefined,
    partnershipType: PartnershipType
): ReturnType => {
    if (!organizationId) {
        return { organizations: [], loading: false }
    }

    const { partnersData, loading } = useFetchPartnersData(organizationId, true)

    const partnersOrganizations = useMemo<PartnerOrganizationI[]>(() => {
        return partnersData
            .reduce<PartnerOrganizationI[]>((acc, partner) => {
                const hasRelevantPartnership =
                    (partnershipType === PartnershipType.SUPPLIER && partner.supplier) ||
                    (partnershipType === PartnershipType.BUYER && partner.buyer)

                if (hasRelevantPartnership) {
                    acc.push({
                        id: partner.organizationId,
                        name: partner.organizationName,
                        registration: partner.registration,
                        address: partner.address ? getPartialPartnerAddress(partner.address) : undefined,
                        collaborationStatus: getCollaborationStatus(partner, partnershipType) ?? undefined,
                    })
                }

                return acc
            }, [])
            .sort((a, b) => a.name.localeCompare(b.name))
    }, [partnersData, partnershipType])

    return { organizations: partnersOrganizations, loading: loading }
}
