import { Box, Grid } from "@mui/material"
import React from "react"
import { FormattedMessage, defineMessages } from "react-intl"

import UserAvatar from "~/components/Avatar/UserAvatar"
import { StatusChip } from "~/components/StatusChip/StatusChip"
import { UserI } from "~/types"

const messages = defineMessages({
    team: {
        id: "buyer.workflowValidation.prefix.team",
        defaultMessage: "{name} team",
    },
    role: {
        id: "buyer.workflowValidation.prefix.role",
        defaultMessage: "{name} role",
    },
    person: {
        id: "buyer.workflowValidation.prefix.person",
        defaultMessage: "{name}",
    },
    APPROVED: {
        id: "workflow.check.approved",
        defaultMessage: "Approved",
    },
    REFUSED: {
        id: "workflow.check.refused",
        defaultMessage: "Refused",
    },
    WAITING: {
        id: "workflow.check.waiting",
        defaultMessage: "Waiting",
    },
})

export enum reviewState {
    APPROVED = "APPROVED",
    REFUSED = "REFUSED",
    WAITING = "WAITING",
}

interface Props {
    user: UserI
    state: reviewState
}

export const ReviewStep: React.FC<Props> = ({ user, state }) => {
    return (
        <li>
            <Grid container flexDirection="column" gap={1}>
                <Grid container alignItems="center">
                    <UserAvatar email={user?.email} />
                    <small>
                        <FormattedMessage
                            {...messages.person}
                            values={{
                                name: user.fullName === "" ? user.email : user.fullName,
                            }}
                        />
                    </small>
                </Grid>
                <Box>
                    <StatusChip
                        status={
                            state === reviewState.APPROVED
                                ? "validated"
                                : state === reviewState.REFUSED
                                ? "refused"
                                : "waiting"
                        }
                    >
                        <FormattedMessage {...messages[state]} />
                    </StatusChip>
                </Box>
            </Grid>
        </li>
    )
}
