import { useCallback, useEffect, useState } from "react"

import { invoiceApi } from "~/api"
import { budgetApi } from "~/api/budgetApi"
import { TransactionType } from "~/features/budget/types"
import { getIsConnected } from "~/store/auth/authSlice"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { useFetchOrganization } from "~/store/organization/hooks"
import { paymentActions } from "~/store/payment/paymentSlice"
import { InvoiceI, PaymentStatus, ViewTypeI } from "~/types"
import { parseInvoice } from "~/types/InvoiceParsers"

import { invoiceActions, selectInvoice } from "../invoiceSlice"
import { setInvoiceOrganizations } from "../utils/setInvoiceOrganizations"

export const useFetchInvoice = (
    invoiceId: string | undefined,
    viewType: ViewTypeI,
    shouldReload?: number,
    forceRefetch?: boolean | null
) => {
    const [tmpInvoice, setTmpInvoice] = useState<InvoiceI | null>(null)
    const dispatch = useAppDispatch()
    const invoice = useAppSelector(selectInvoice)

    const isConnected = useAppSelector(getIsConnected)

    const { organization: buyerOrganization } = useFetchOrganization(tmpInvoice?.buyer.organizationId)
    const { organization: supplierOrganization } = useFetchOrganization(tmpInvoice?.supplier.organizationId)

    const fetchInvoice = useCallback(async () => {
        if (!invoiceId) return
        try {
            const invoiceResponse = await invoiceApi.getById(invoiceId)
            const invoice = parseInvoice(invoiceResponse)
            setTmpInvoice(invoice)
        } catch (error) {
            console.error(`Failed to fetch the invoice`, error)
            dispatch(invoiceActions.fetchInvoiceFailed(`${error}`))
        }
    }, [dispatch, invoiceId])

    useEffect(() => {
        if (forceRefetch) {
            fetchInvoice()
        } else if (forceRefetch !== null) {
            fetchInvoice()
        }
    }, [fetchInvoice, shouldReload, forceRefetch])

    useEffect(() => {
        if (tmpInvoice) {
            const invoice = setInvoiceOrganizations(tmpInvoice, { buyerOrganization, supplierOrganization })
            const allInvolvedPeople = new Set(
                [...invoice.involvedPeople, ...invoice.supplierInvolvedPeople].map((person) => person.userId)
            )

            /* Invoice in Budgets */
            if (
                invoice &&
                ((viewType === ViewTypeI.buyer && buyerOrganization) ||
                    (viewType === ViewTypeI.supplier && supplierOrganization))
            ) {
                if (isConnected) {
                    const organizationId =
                        viewType === ViewTypeI.buyer ? buyerOrganization?.id : supplierOrganization?.id
                    budgetApi
                        .getBudgetByTransaction(organizationId as string, invoice.id, TransactionType.INVOICE)
                        .then((result) => {
                            dispatch(invoiceActions.setInvoice({ ...invoice, budgets: result }))
                        })
                } else {
                    dispatch(invoiceActions.setInvoice(invoice))
                }
                dispatch(invoiceActions.addPeople([...allInvolvedPeople]))
            } else {
                dispatch(invoiceActions.setInvoice(invoice))
            }
        }
    }, [buyerOrganization, supplierOrganization, tmpInvoice])

    useEffect(() => {
        if (invoice?.id && isConnected) {
            dispatch(invoiceActions.fetchCommunication(invoice.id))
            dispatch(invoiceActions.fetchEvents(invoice.id))
        }
    }, [invoice?.id, isConnected])

    useEffect(() => {
        if (invoice && invoice.payments?.length) {
            const lastEvent = invoice.payments.slice(-1)[0]
            if (
                lastEvent.status === PaymentStatus.EXECUTED ||
                lastEvent.status === PaymentStatus.VALIDATED ||
                lastEvent.status === PaymentStatus.SCHEDULED
            ) {
                dispatch(paymentActions.setStatus(lastEvent.status))
                dispatch(paymentActions.setUpdateTimestamp(lastEvent.updateTimestamp))
            }
        }
    }, [invoice?.payments])

    return { invoice, buyerOrganization, supplierOrganization, refetchInvoice: fetchInvoice }
}
