import { Badge, Grid, IconButton, InputBase, Paper } from "@mui/material"
import classNames from "classnames"
import { FC } from "react"
import { Send } from "react-feather"
import { defineMessages, useIntl } from "react-intl"

import { Conversation } from "~/domains/copilot/prompt/types/Copilot"

import { CopilotUploadDocument } from "./CopilotUploadDocument"

const messages = defineMessages({
    askQuestion: {
        id: "copilot.askQuestion",
        defaultMessage: "Ask a question or get some help",
    },
})

interface CopilotPromptBarProps {
    displayMenu: boolean
    documentContent: string
    conversation: Conversation[]
    message: string
    handleChangeMessage: (event: React.ChangeEvent<HTMLInputElement>) => void
    handleUploadSuccess: (documentContent: string) => void
    handlePressEnter: (event: React.KeyboardEvent<HTMLInputElement>) => void
    handleSendMessage: () => void
}

export const CopilotPromptBar: FC<CopilotPromptBarProps> = ({
    displayMenu,
    documentContent,
    conversation,
    message,
    handleChangeMessage,
    handleUploadSuccess,
    handlePressEnter,
    handleSendMessage,
}) => {
    const { formatMessage } = useIntl()

    return (
        <Paper
            component="form"
            className={classNames("prompt-container", {
                "sidebar-opened": displayMenu,
                centered: !conversation.length,
            })}
        >
            <Grid container gap={2}>
                <Badge color="secondary" badgeContent={documentContent ? 1 : 0}>
                    <CopilotUploadDocument onUploadSuccess={handleUploadSuccess} />
                </Badge>
                <InputBase
                    required
                    id="message"
                    name="message"
                    placeholder={formatMessage(messages.askQuestion)}
                    onChange={handleChangeMessage}
                    value={message}
                    onKeyDown={handlePressEnter}
                    sx={{ flexGrow: 1 }}
                />

                <IconButton color="primary" onClick={handleSendMessage}>
                    <Send />
                </IconButton>
            </Grid>
        </Paper>
    )
}
