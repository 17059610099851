import React, { Dispatch, SetStateAction, useState } from "react"
import { Button, Loader } from "~/components"
import { MuiOtpInput } from "mui-one-time-password-input"
import { passwordLessApi, userApi } from "~/api"
import { defineMessages, FormattedMessage } from "react-intl"
import "./OneTimePassword.scss"
import { useCookies } from "react-cookie"
import { authActions } from "~/store/auth/authSlice"
import { useAppDispatch } from "~/store/hooks"
import { accountActions } from "~/store/account/accountSlice"

const messages = defineMessages({
    resend: {
        id: "supplier.modalValidation.resend",
        defaultMessage: "A secret code has been sent to you.{br}Didn't get it? Feel free to {resend} it",
    },
    buttonVerify: {
        id: "supplier.modalValidation.button.verify",
        defaultMessage: "Verify my email",
    },
    buttonSignup: {
        id: "supplier.modalValidation.button.signup",
        defaultMessage: "Sign In/Up",
    },
    or: {
        id: "supplier.modalValidation.or",
        defaultMessage: "OR",
    },
})

interface Props {
    setIsAuthorized: Dispatch<SetStateAction<boolean>>
    senderEmail: string
}

export function OneTimePassword({ setIsAuthorized, senderEmail }: Props) {
    const [isSent, setIsSent] = useState(false)
    const [value, setValue] = useState("")
    const [, setCookie] = useCookies(["jwt"])
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useAppDispatch()
    const handleChangeOTP = async (newValue: string) => {
        setValue(newValue)
    }

    const handleVerify = async () => {
        try {
            setIsLoading(true)
            await passwordLessApi.sendCode(senderEmail)
            setIsSent(true)
        } catch (error) {
            console.error("handleVerify", error)
        } finally {
            setIsLoading(false)
        }
    }

    const handleCompleteOTP = async (value: string) => {
        try {
            setIsLoading(true)
            const tokens = await passwordLessApi.checkCode(senderEmail, value)
            setCookie("jwt", tokens.access_token, {
                maxAge: 600,
            })
            setIsAuthorized(true)
            const user = await userApi.getCurrentUser()
            if (user?.id) {
                dispatch(accountActions.setUserId(user.id))
            }
            setValue("")
        } catch (error) {
            console.error(error)
            setIsAuthorized(false)
        } finally {
            setValue("")
            setIsLoading(false)
        }
    }

    const handleSignUp = async () => {
        dispatch(authActions.openPopupSignIn())
    }

    if (!isSent) {
        return isLoading ? (
            <Loader small={true} />
        ) : (
            <div className="actions">
                <Button onClick={handleVerify} type="tertiary">
                    <FormattedMessage
                        {...messages.buttonVerify}
                        values={{
                            resend: <span onClick={handleVerify}>resend</span>,
                            email: senderEmail,
                        }}
                    />
                </Button>
                <strong>
                    <FormattedMessage {...messages.or} />
                </strong>
                <Button onClick={handleSignUp}>
                    <FormattedMessage {...messages.buttonSignup} />
                </Button>
            </div>
        )
    }

    return isLoading ? (
        <Loader small={true} />
    ) : (
        <>
            <MuiOtpInput
                sx={{ maxWidth: "80%" }}
                TextFieldsProps={{
                    placeholder: "-",
                    autoComplete: "false",
                }}
                length={6}
                value={value}
                onChange={handleChangeOTP}
                onComplete={handleCompleteOTP}
            />
            <p className="resend-verification-message">
                <FormattedMessage
                    {...messages.resend}
                    values={{
                        resend: <span onClick={handleVerify}>resend</span>,
                        email: senderEmail,
                        br: <br />,
                    }}
                />
            </p>
        </>
    )
}
