import { Box, Breadcrumbs, Button, Stack, Tooltip, capitalize } from "@mui/material"
import React from "react"
import { ChevronRight } from "react-feather"

import { InvoiceStatus } from "~/types"

export interface StepI {
    key: string
    name: string
    value?: string
    isCurrentLifecycle?: boolean
    done?: boolean
    status?: InvoiceStatus
    rejected?: boolean
    description?: string
    onClick?: (status: string) => void
    icon?: React.ReactNode
    progress?: string
}

interface StepsProps {
    steps: StepI[]
}

export function Steps({ steps }: StepsProps) {
    return (
        <Breadcrumbs separator={<ChevronRight color="var(--color-grey)" size={14} />}>
            {steps.map(({ name, icon, isCurrentLifecycle, description, key, onClick }, index) => (
                <Tooltip key={`${key}-${index}`} title={description} arrow>
                    <Button
                        variant="text"
                        color={isCurrentLifecycle ? "success" : "inherit"}
                        onClick={() => onClick?.(name)}
                    >
                        <Stack direction="row" alignItems="center" gap={1}>
                            {icon}

                            <Box sx={{ display: { xs: "none", sm: "block" } }}>{capitalize(name)}</Box>
                        </Stack>
                    </Button>
                </Tooltip>
            ))}
        </Breadcrumbs>
    )
}
