import { FC } from "react"

import { Card, InvoiceViewer } from "~/components"
import { InvoiceI } from "~/types"

interface InvoicePreviewProps {
    invoice: InvoiceI
}

export const InvoicePreview: FC<InvoicePreviewProps> = ({ invoice }) => {
    const handleDownloadPDF = () => {
        if (invoice?.fileLinks?.pdfLink) {
            window.location.href = invoice.fileLinks.pdfLink
        }
    }

    if (!invoice.fileLinks.originalImageLinks?.length)
        return (
            <Card>
                <p>No preview available</p>
            </Card>
        )

    return (
        <InvoiceViewer
            imageUrls={invoice.fileLinks.originalImageLinks}
            imagesOnly={true}
            handleDownload={handleDownloadPDF}
        />
    )
}
