export const PROFILE_ROUTE = "/account/profile"
export const PROFILE_ROUTE_DEFAULT = "/account"
export const ORGANIZATIONS_ROUTE = "/account/organizations"
export const ORGANIZATION_DETAILS_ROUTE = "/account/organizations/:organizationId"
export const ORGANIZATION_INFO_ROUTE = "/account/organizations/:organizationId/infos"
export const ORGANIZATION_INTEGRATION_ROUTE = "/account/organizations/:organizationId/integrations"
export const ORGANIZATION_BILLING_ROUTE = "/account/organizations/:organizationId/billing"
export const ORGANIZATION_PAYMENT_METHODS_ROUTE = "/account/organizations/:organizationId/payment-method-details"
export const ORGANIZATION_CONTACTS_ROUTE = "/account/organizations/:organizationId/contacts"
export const ORGANIZATION_CATALOGS_ROUTE = "/account/organizations/:organizationId/catalogs"
export const ORGANIZATION_CATALOGS_V1_ROUTE = "/account/organizations/:organizationId/catalogs-v1"
export const ORGANIZATION_CREATE_PRODUCT_ROUTE = "/account/organizations/:organizationId/products/create"
export const ORGANIZATION_EDIT_PRODUCT_ROUTE = "/account/organizations/:organizationId/products/:productId"
export const ORGANIZATION_TEAMS_ROUTE = "/account/organizations/:organizationId/teams"
export const ORGANIZATION_TAGS_ROUTE = "/account/organizations/:organizationId/tags"
export const ORGANIZATION_INVOICE_EXPORTS_ROUTE = "/account/organizations/:organizationId/exports"
export const CONTACTS_ROUTE = "/account/contacts"
export const MY_DOCUMENTS_ROUTE = "/account/documents/:view"
export const MY_DOCUMENTS_ROUTE_DEFAULT = "/account/documents"
export const ORGANIZATION_KYB_ROUTE = "/account/organizations/:organizationId/kyb"
