import { HandleProps, Position } from "@xyflow/react"
import type { NodeProps, Node as NodeType } from "@xyflow/react"
import React, { FC } from "react"
import { Link } from "react-feather"
import { useIntl } from "react-intl"

import { messages } from "~/domains/orchestration/flows/messages"
import { SetPartnershipFieldNode as SetPartnershipFieldNodeType } from "~/domains/orchestration/flows/types"

import { Node } from "./Node"

export const SetPartnershipFieldNode: FC<NodeProps<NodeType<SetPartnershipFieldNodeType>>> = ({ data, selected }) => {
    const { formatMessage } = useIntl()

    const handles: HandleProps[] = [
        {
            type: "target",
            isConnectableEnd: true,
            position: Position.Top,
        },
        {
            type: "source",
            position: Position.Bottom,
            isConnectableStart: true,
        },
    ]

    const error = !data.fieldToUpdate || !data.valueToSet

    return (
        <Node
            title={formatMessage(messages.setPartnershipFieldNode.nodeTitle)}
            name={data.name}
            headerInformation={data.fieldToUpdate}
            icon={Link}
            handles={handles}
            selected={selected}
            slug={data.slug}
            error={error}
        ></Node>
    )
}
