import { DocumentPaymentSection } from "~/domains/payment"
import { InvoiceI } from "~/types"

interface InvoicePaymentProps {
    invoice: InvoiceI
}

export const InvoicePayment = ({ invoice }: InvoicePaymentProps) => {
    const { totalAmountDue, number, reference, payerId, sellerId, id } = invoice
    const documentPaymentData = {
        number: number ?? reference,
        total: {
            amount: +totalAmountDue[0]?.amount,
            currency: totalAmountDue[0]?.currency,
        },
    }

    if (!id) {
        return null
    }

    return (
        <DocumentPaymentSection
            documentId={id}
            payerId={payerId}
            payeeId={sellerId}
            documentData={documentPaymentData}
        />
    )
}
