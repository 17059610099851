import React from "react"

import "./StatusChip.scss"

interface StatusChipProps {
    status: string
    children: React.ReactNode
}

export const StatusChip: React.FC<StatusChipProps> = ({ status, children }) => {
    return <span className={`status-chip status-${status.toLowerCase()}`}>{children}</span>
}
