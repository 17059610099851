import { BaseTextFieldProps, TextField } from "@mui/material"
import React, { ChangeEvent, useCallback, useState } from "react"
import { defineMessages, useIntl } from "react-intl"

const messages = defineMessages({
    defaultLabel: {
        id: "invoice.invoiceLine.vatAmount.defaultLabel",
        defaultMessage: "VAT",
    },
    errorMustBeANumber: {
        id: "invoice.invoiceLine.vatAmount.errors.mustBeANumber",
        defaultMessage: "VAT amount must be a number",
    },
})

interface Props extends BaseTextFieldProps {
    value: number | null
    onChange: (value: number) => void
    hideLabel?: boolean
}

export const InvoiceLineTotalTaxField: React.FC<Props> = ({ value, onChange, hideLabel, ...textFieldProps }) => {
    const [fieldValue, setFieldValue] = useState<string>(value ? `${value}` : "")
    const [error, setError] = useState<string>()
    const { formatMessage } = useIntl()

    const onFieldChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const value = event.currentTarget.value
            setFieldValue(value)
            const numberValue = parseFloat(value)
            if (isNaN(numberValue)) {
                setError(formatMessage(messages.errorMustBeANumber))
            } else {
                setError(undefined)
                onChange(numberValue)
            }
        },
        [onChange]
    )
    return (
        <TextField
            {...textFieldProps}
            name="line-tax"
            label={hideLabel ? undefined : formatMessage(messages.defaultLabel)}
            placeholder={formatMessage(messages.defaultLabel)}
            onChange={onFieldChange}
            value={fieldValue}
            type="number"
            error={!!error}
            helperText={error}
        />
    )
}
