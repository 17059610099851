import { useState, useEffect } from "react"

interface Dimensions {
    width: number
    height: number
}

export function useResizeObserver(targetRef: React.RefObject<HTMLElement>): Dimensions {
    const [dimensions, setDimensions] = useState<Dimensions>({ width: 0, height: 0 })

    useEffect(() => {
        const target = targetRef.current

        if (!target) return

        const resizeObserver = new ResizeObserver((entries) => {
            if (!entries || entries.length === 0) return
            const { width, height } = entries[0].contentRect
            setDimensions({ width, height })
        })

        resizeObserver.observe(target)

        return () => {
            if (target) {
                resizeObserver.unobserve(target)
            }
        }
    }, [targetRef])

    return dimensions
}
