import React, { useCallback, useEffect, useState } from "react"
import {
    PurchaseRequestDetails,
    PurchaseRequestStatus,
} from "~/domains/transactions/purchase-requests/types/PurchaseRequests"
import { defineMessages, useIntl } from "react-intl"
import { generatePath, useNavigate } from "react-router-dom"
import { Button, Loader } from "~/components"
import { FileText } from "react-feather"
import "./Actions.scss"
import { useConvertToPurchaseOrder } from "~/domains/transactions/purchase-requests/store/hooks/useConvertToPurchaseOrder"
import { PURCHASE_ORDER_ROUTE } from "~/domains/transactions/purchase-orders/routes"
import { toast } from "react-toastify"
import { SubmittedPRActions } from "./SubmittedPRActions"
import { useDuplicateDocumentLinks } from "~/components/UploadDocument/hooks/useDuplicateDocumentLinks"
// import { ModalStatusConversion } from "../ModalStatusConversion/ModalStatusConversion"
import { PurchaseRequestConversionStatus } from "~/domains/transactions/purchase-requests/types/PurchaseRequests"
import { OrganizationId } from "~/types"
import { Tooltip } from "@mui/material"
import { permissionMessages } from "~/domains/identity/features/roles-permissions/utils/permissions"
import { useFetchPartnership } from "~/domains/transactions/book-of-relations/store/hooks"
import useSupplierAllowed from "~/domains/transactions/book-of-relations/store/hooks/useSupplierAllowed"

interface PropsPRActions {
    PR: PurchaseRequestDetails
}

const messages = defineMessages({
    toPO: {
        id: "purchase.requests.request.toPO",
        defaultMessage: "Convert to PO",
    },
    supplierNeeded: {
        id: "purchase.requests.request.supplierNeeded",
        defaultMessage: "You need to select a supplier before converting this PR to a PO",
    },
    deliveryDateNeeded: {
        id: "purchase.requests.request.deliveryDateNeeded",
        defaultMessage: "You need to select a delivery date before converting this PR to a PO",
    },
    title: {
        id: "purchase.requests.request.actions.title",
        defaultMessage: "Actions",
    },
    supplierNotAllowed: {
        id: "purchase.requests.request.supplierNotAllowed",
        defaultMessage: "This supplier is not allowed to receive POs",
    },
    overBudget: {
        id: "purchase.requests.request.overBudget",
        defaultMessage: "This PR is over budget",
    },
})

export function ActionsColumn({ PR }: PropsPRActions) {
    const { formatMessage } = useIntl()
    const navigate = useNavigate()
    // const [displayStatusModal, setDisplayStatusModal] = useState<boolean>(false)
    const { duplicateDocumentLinks } = useDuplicateDocumentLinks()
    const [supplierAllowed, setSupplierAllowed] = useState(false)
    const [overBudget, setOverBudget] = useState(false)

    const { convertToPO, convertLoading } = useConvertToPurchaseOrder(PR.organizationId)
    const convertPermission = PR.permissions?.convertToPurchaseOrder
    const { partnershipData, loading: partnershipDataLoading } = useFetchPartnership(PR.organizationId, PR.supplierId)
    const canConvert = (convertPermission && typeof convertPermission !== "undefined") ?? false

    const handleConvertToPO = () => {
        if (!PR.supplierId) {
            return toast.error(formatMessage(messages.supplierNeeded))
        }
        if (!PR.expectedDeliveryDate) {
            return toast.error(formatMessage(messages.deliveryDateNeeded))
        }
        convert(PR.expectedDeliveryDate, PurchaseRequestConversionStatus.SUBMITTED)
        // setDisplayStatusModal(true)
    }

    useEffect(() => {
        setSupplierAllowed(useSupplierAllowed(partnershipData, partnershipDataLoading))

        return () => {
            setSupplierAllowed(false)
        }
    }, [partnershipData, partnershipDataLoading])

    useEffect(() => {
        const budgetForCurrency = PR?.budgets?.find((budget) => budget?.budget?.currency === PR.currency)?.budget

        setOverBudget(
            !budgetForCurrency || (PR.totalAmount ? budgetForCurrency.currentAmount >= PR.totalAmount : false)
        )

        return () => {
            setOverBudget(false)
        }
    }, [PR])

    // const handleStatusAndConvert = (status: PurchaseRequestConversionStatus, sharedEmail?: string) => {
    //     convert(null, status, sharedEmail, sharedEmail ? (PR.supplierId as string) : undefined)
    // }

    const convert = (
        deliveryDate: string | null = null,
        status?: PurchaseRequestConversionStatus,
        sharedEmail?: string,
        supplierId?: OrganizationId
    ) => {
        convertToPO(PR, deliveryDate, status, sharedEmail, supplierId).then(async (purchaseOrder) => {
            if (!purchaseOrder) return
            await duplicateDocumentLinks({ sourceObjectId: PR.id, targetObjectId: purchaseOrder.id })
            navigate(generatePath(PURCHASE_ORDER_ROUTE, { purchaseOrderId: purchaseOrder.id }))
        })
    }

    /// Renders Convert to PO button
    const convertToPOButton = useCallback(() => {
        const disallowReason = () => {
            if (!canConvert) {
                return formatMessage(permissionMessages.errorNoAccessAdministrator)
            } else if (!supplierAllowed) {
                return formatMessage(messages.supplierNotAllowed)
            } else if (!overBudget) {
                return formatMessage(messages.overBudget)
            }
        }

        const isButtonDisabled = !supplierAllowed || !canConvert || !overBudget

        const handleButtonClick = (e: React.MouseEvent) => {
            e.preventDefault()
            if (!isButtonDisabled) {
                handleConvertToPO()
            }
        }

        return (
            <Tooltip arrow title={disallowReason()}>
                <span className="flex-1">
                    <Button type="tertiary" className="status" disabled={isButtonDisabled} onClick={handleButtonClick}>
                        {formatMessage(messages.toPO)}
                        <FileText size={14} />
                    </Button>
                </span>
            </Tooltip>
        )
    }, [supplierAllowed, canConvert, handleConvertToPO, formatMessage])

    if (PR.status === PurchaseRequestStatus.SUBMITTED) {
        return <SubmittedPRActions purchaseRequest={PR} />
    }
    if (PR.status === PurchaseRequestStatus.APPROVED) {
        return (
            <div className="actions">
                {convertLoading ? <Loader small={true} /> : convertToPOButton()}
                {/* <ModalStatusConversion
                    open={displayStatusModal}
                    close={() => setDisplayStatusModal(false)}
                    confirm={handleStatusAndConvert}
                    partnerId={PR.supplierId}
                /> */}
            </div>
        )
    }
    return <></>
}
