import { InvoiceLineI } from "~/types"

export enum InvoiceLineErrorType {
    UnitPriceAndQuantityDoesNotMatchTotalExcludedTaxes = "UnitPriceAndQuantityDoesNotMatchTotalExcludedTaxes",
    TotalDoesNotMatchComputedTotal = "TotalDoesNotMatchComputedTotal",
    TotalDoesNotTakeChargeOfDiscount = "TotalDoesNotTakeChargeOfDiscount",
}

export interface InvoiceLineError extends Record<string, number | string | string[]> {
    linePosition: number
    error: InvoiceLineErrorType
    fields: (keyof InvoiceLineI)[]
    computedValueError: number
    referenceValue: number
}

export interface InvoiceLinesTotals extends Record<string, any> {
    totalExcludedTaxes: number
    total: number
    discountTotal: number
    totalTax: number
}

export interface InvoiceLinesTotalsWithErrors extends InvoiceLinesTotals {
    errors: InvoiceLineError[]
}

const sumInvoiceLines = (
    acc: InvoiceLinesTotalsWithErrors,
    invoiceLine: InvoiceLineI
): InvoiceLinesTotalsWithErrors => {
    acc.totalExcludedTaxes += invoiceLine.totalExcludedTaxes
    acc.discountTotal += invoiceLine.discountTotal ?? 0
    acc.totalTax += invoiceLine.totalTax
    acc.total += invoiceLine.totalExcludedTaxes + invoiceLine.totalTax

    const computedTotalExcludedTaxes = roundMoney(
        invoiceLine.unitPrice * invoiceLine.quantity - (invoiceLine.discountTotal ?? 0)
    )
    if (computedTotalExcludedTaxes !== invoiceLine.totalExcludedTaxes) {
        const error =
            invoiceLine.totalExcludedTaxes - (invoiceLine.discountTotal ?? 0) === computedTotalExcludedTaxes
                ? InvoiceLineErrorType.TotalDoesNotTakeChargeOfDiscount
                : InvoiceLineErrorType.UnitPriceAndQuantityDoesNotMatchTotalExcludedTaxes
        acc.errors.push({
            linePosition: invoiceLine.linePosition,
            error: error,
            fields: ["unitPrice", "quantity", "totalExcludedTaxes"],
            computedValueError: computedTotalExcludedTaxes,
            referenceValue: invoiceLine.totalExcludedTaxes,
        })
    }

    const computedTotal = roundMoney(
        invoiceLine.totalExcludedTaxes + invoiceLine.totalTax - (invoiceLine.discountTotal ?? 0)
    )
    if (computedTotal !== roundMoney(invoiceLine.total)) {
        acc.errors.push({
            linePosition: invoiceLine.linePosition,
            error: InvoiceLineErrorType.TotalDoesNotMatchComputedTotal,
            fields: ["totalTax", "discountTotal", "total", "totalExcludedTaxes"],
            computedValueError: computedTotal,
            referenceValue: invoiceLine.total,
        })
    }
    return acc
}

export const roundMoney = (amount: number): number => Math.round(amount * 100 + Number.EPSILON) / 100

export const computeInvoiceLinesTotals = (invoiceLines: InvoiceLineI[]): InvoiceLinesTotalsWithErrors => {
    const totals = invoiceLines.reduce(sumInvoiceLines, {
        totalExcludedTaxes: 0,
        total: 0,
        discountTotal: 0,
        totalTax: 0,
        errors: [],
    })
    return {
        ...totals,
        totalExcludedTaxes: roundMoney(totals.totalExcludedTaxes),
        discountTotal: roundMoney(totals.discountTotal),
        total: roundMoney(totals.total),
    }
}
