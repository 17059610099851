import React from "react"
import { EventI, MessageI } from "~/types"
import { MessagesType } from "~/components/CommunicationsTabs/Messages"
import UserAvatar from "~/components/Avatar/UserAvatar"
import { defineMessages, FormattedMessage, useIntl } from "react-intl"
import dayjs from "dayjs"
import { DateComponent } from "../Date"

interface Props {
    message: MessageI | EventI
    type: MessagesType
}

const messages = defineMessages({
    eventsInvoiceOpened: {
        id: "events.type.invoice_opened",
        defaultMessage: "Invoice seen",
    },
    eventsInvoiceConfirmed: {
        id: "events.type.invoice_confirmed",
        defaultMessage: "Invoice sent",
    },
    eventsInvoiceRequestInfo: {
        id: "events.type.info_requested",
        defaultMessage: "Information requested",
    },
    eventsInvoiceUpdated: {
        id: "events.type.invoice_updated",
        defaultMessage: "Invoice updated",
    },
    eventsInvoiceValidated: {
        id: "events.type.invoice_validated",
        defaultMessage: "Invoice validated",
    },
    eventsInvoiceInvalidated: {
        id: "events.type.invoice_invalidated",
        defaultMessage: "Invoice invalidated",
    },
    eventsInvoicePaymentScheduled: {
        id: "events.type.invoice_scheduled",
        defaultMessage: "Payment scheduled",
    },
    eventsInvoiceInvoicePaid: {
        id: "events.type.invoice_paid",
        defaultMessage: "Invoice paid",
    },
    eventsMarkedAsPaid: {
        id: "events.type.marked_as_paid",
        defaultMessage: "Marked as paid",
    },
    eventsConfirmedAsPaid: {
        id: "events.type.confirmed_as_paid",
        defaultMessage: "Confirmed as paid",
    },
    eventsUnmarkedAsPaid: {
        id: "events.type.unmarked_as_paid",
        defaultMessage: "Unmarked as paid",
    },
})

const events = {
    invoice_opened: "eventsInvoiceOpened",
    invoice_confirmed: "eventsInvoiceConfirmed",
    info_requested: "eventsInvoiceRequestInfo",
    invoice_updated: "eventsInvoiceUpdated",
    invoice_validated: "eventsInvoiceValidated",
    invoice_invalidated: "eventsInvoiceInvalidated",
    invoice_scheduled: "eventsInvoicePaymentScheduled",
    invoice_paid: "eventsInvoiceInvoicePaid",
    invoice_marked_as_paid: "eventsMarkedAsPaid",
    invoice_confirmed_as_paid: "eventsConfirmedAsPaid",
    invoice_unmarked_as_paid: "eventsUnmarkedAsPaid",
}

function MessageContent({ type, message }: Props) {
    if (type === "events") {
        const content = message as EventI
        const label = content.type
        return (
            <p className={"event " + label}>
                {events[label] ? <FormattedMessage {...messages[events[label]]} /> : label}
            </p>
        )
    }

    if (type === "communications") {
        const content = message as MessageI
        return <p>{content.message.replace(/<br>/g, " ")}</p>
    }
    return null
}

export default function Message({ message, type }: Props) {
    const { locale } = useIntl()

    return (
        <div className="message">
            <div className="message-avatar">
                <UserAvatar email={message.user?.email} />
            </div>
            <h5>
                <span>
                    <DateComponent value={dayjs(message.timestamp).toISOString()} timeLocale={locale} />
                </span>
                {message.user?.fullName} {message.user?.email}
            </h5>
            <MessageContent type={type} message={message} />
        </div>
    )
}
