import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { FetchError } from "~/domains/common/apiClient/errors"
import { RootState } from "~/store"
import { OrganizationId } from "~/types"

import {
    ContractDataI,
    DocumentDetailsI,
    DocumentI,
    DocumentVersionI,
    FolderId,
    PaginatedDocumentsResultI,
} from "../types"
import { DocumentsStore } from "./documentsStore"

const initialState: DocumentsStore = {
    currentOrganizationId: null,
    loading: false,
    query: "",
    perPage: 24,
    page: 0,
    parentId: null,
    result: null,
    selectedDocument: null,
    loadingSingleDocument: false,

    currentContractId: null,
    currentContractData: null,
}

const documentsSlice = createSlice({
    name: "documents",
    initialState,
    reducers: {
        fetchDocuments(
            state,
            {
                payload: { organizationId, query, page, perPage, parentId },
            }: PayloadAction<{
                organizationId: OrganizationId
                query: string
                page: number
                perPage: number
                parentId: FolderId | null
            }>
        ) {
            state.currentOrganizationId = organizationId
            state.loading = true
            state.fetchingError = null
            state.query = query
            state.page = page
            state.perPage = perPage
            state.parentId = parentId
        },
        fetchDocumentsSuccess(
            state,
            {
                payload: { organizationId, result, query, perPage, page, parentId },
            }: PayloadAction<{
                organizationId: OrganizationId
                result: PaginatedDocumentsResultI
                query: string
                page: number
                perPage: number
                parentId: FolderId | null
            }>
        ) {
            if (
                state.currentOrganizationId === organizationId &&
                state.query === query &&
                state.page === page &&
                state.perPage === perPage &&
                state.parentId === parentId
            ) {
                state.loading = false
                state.result = result
            }
        },
        fetchDocumentsFailure(
            state,
            {
                payload: { organizationId, error, query, perPage, page, parentId },
            }: PayloadAction<{
                organizationId: OrganizationId
                error: FetchError<PaginatedDocumentsResultI>
                query: string
                page: number
                perPage: number
                parentId: FolderId | null
            }>
        ) {
            if (
                state.currentOrganizationId === organizationId &&
                state.query === query &&
                state.page === page &&
                state.perPage === perPage &&
                state.parentId === parentId
            ) {
                state.loading = false
                state.fetchingError = error
            }
        },
        insertDocument(
            state,
            {
                payload: { organizationId, document },
            }: PayloadAction<{ organizationId: OrganizationId; document: DocumentI }>
        ) {
            if (state.currentOrganizationId === organizationId && state.result?.documents) {
                state.result.documents = [document, ...state.result.documents]
            }
        },
        setQuery(state, { payload }: PayloadAction<string>) {
            state.query = payload
        },
        fetchingSingleDocument(state) {
            state.loadingSingleDocument = true
            state.selectedDocument = null
            state.fetchingSingleDocumentError = null
        },
        setSelectedDocument(state, { payload }: PayloadAction<DocumentDetailsI | null>) {
            state.selectedDocument = payload
        },
        fetchingSingleDocumentFailure(state, { payload: { error } }: PayloadAction<{ error: FetchError<DocumentI> }>) {
            state.loadingSingleDocument = false
            state.fetchingSingleDocumentError = error
        },
        setCurrentContractData(state, { payload }: PayloadAction<{ contractId: string; contractData: ContractDataI }>) {
            state.currentContractId = payload.contractId
            state.currentContractData = payload.contractData
        },
        updateCurrentDocumentVersion(state, { payload }: PayloadAction<DocumentVersionI>) {
            if (state.selectedDocument && state.selectedDocument.id === payload.documentId) {
                state.selectedDocument.versions = state.selectedDocument.versions.map((version) => {
                    if (version.id === payload.id) {
                        version.scannedResults = payload.scannedResults
                        return version
                    }
                    return version
                })
            }
        },
    },
})

export const documentsActions = documentsSlice.actions
export const documentsReducer = documentsSlice.reducer

export const selectDocumentsState = (state: RootState) => state.documents
export const selectDocumentsQuery = (state: RootState) => state.documents.query
export const selectSelectedDocument = (state: RootState) => state.documents.selectedDocument
export const selectSelectedDocumentLoading = (state: RootState) => state.documents.loadingSingleDocument
export const selectSelectedDocumentFetchingError = (state: RootState) => state.documents.fetchingSingleDocumentError

export const selectCurrentContractData = (state: RootState) => state.documents.currentContractData
export const selectCurrentContractId = (state: RootState) => state.documents.currentContractId
