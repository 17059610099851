import { useState } from "react"
import { rolesPermissionsApi } from "~/api/rolesPermissionsApi"
import {
    SpiceDBAuthorizationName,
    SpiceDBObjectReferenceI,
    SpiceDBObjectType,
    SpiceDBWriteRelationshipsResponse,
} from "~/domains/identity/features/roles-permissions/types/SpiceDB"

export interface ManageObjectPermissionsArgs {
    objectId: string
    objectType: SpiceDBObjectType
    objects: SpiceDBObjectReferenceI[]
    permissions: SpiceDBAuthorizationName[]
    isFirstTimeShare: boolean
    creatorOrganizationId: string
    recipientOrganizationId: string
    isDelete: boolean
}

export const useManageObjectPermissions = () => {
    const [loading, setLoading] = useState<boolean>(false)

    const manageObjectPermissionsSuccess = async ({
        objectId,
        objectType,
        objects,
        permissions,
        isFirstTimeShare,
        creatorOrganizationId,
        recipientOrganizationId,
        isDelete,
    }: ManageObjectPermissionsArgs): Promise<boolean> => {
        try {
            setLoading(true)

            const response = await rolesPermissionsApi[isDelete ? "removeObjectPermissions" : "shareObjectPermissions"](
                objectId,
                objectType,
                {
                    objects,
                    authorizations: permissions,
                    isFirstTimeShare,
                    creatorOrganizationId,
                    recipientOrganizationId,
                }
            )

            setLoading(false)
            if (response && (response as SpiceDBWriteRelationshipsResponse).writtenAt) {
                return true
            }
        } catch (error) {
            console.error(error)
            setLoading(false)
        }
        return false
    }

    return {
        loading,
        manageObjectPermissionsSuccess,
    }
}
