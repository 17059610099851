import { Stack, Typography } from "@mui/material"
import { Handle, HandleProps } from "@xyflow/react"
import cls from "classnames"
import React, { FC, PropsWithChildren } from "react"
import { AlertCircle } from "react-feather"
import type { Icon } from "react-feather"
import { FormattedMessage } from "react-intl"

import { TooltipConditional } from "~/components/Tooltip/Tooltip"
import { messages } from "~/domains/orchestration/flows/messages"

import "./Node.scss"

interface Props {
    title: string
    name: string
    slug: string
    headerInformation?: string
    icon: Icon
    handles: Array<HandleProps>
    selected?: boolean
    error?: boolean
    errorMessage?: string
}
export const Node: FC<PropsWithChildren<Props>> = ({
    title,
    name,
    slug,
    headerInformation,
    icon: Icon,
    handles,
    children,
    selected = false,
    error = false,
    errorMessage,
}) => {
    const nodeClassName = cls("flows-node", {
        "flows-node-selected": selected,
    })

    const hasTooltip = Boolean(headerInformation && headerInformation?.length > 18)

    return (
        <div className={nodeClassName}>
            <Stack className="flows-node-header" direction="row" justifyContent="space-between" gap={1}>
                <Stack direction="row" alignItems="center" gap={1}>
                    <Icon size={18} />
                    <h5 className="flows-node-header-title">{name || title}</h5>
                </Stack>

                {headerInformation ? (
                    <TooltipConditional condition={hasTooltip} title={headerInformation} placement="right">
                        <Typography noWrap className="flows-node-header-information">
                            {headerInformation}
                        </Typography>
                    </TooltipConditional>
                ) : null}
            </Stack>
            <Stack className="flows-node-content" direction="column" gap={1}>
                {children}
            </Stack>
            {handles.map((handle) => (
                <Handle
                    key={`${handle.type}${handle.id || ""}`}
                    {...handle}
                    className={cls("flows-node-handle", handle.className)}
                />
            ))}
            <Stack
                className="flows-node-footer"
                direction="row-reverse"
                alignItems="center"
                justifyContent="space-between"
                gap={1}
            >
                <span className="flows-node-footer-information">{slug}</span>
                {error && (
                    <Stack className="flows-node-footer-error" direction="row" alignItems="center" gap={1}>
                        <AlertCircle size={18} color="var(--color-red)" />
                        {errorMessage ? errorMessage : <FormattedMessage {...messages.node.errorDefault} />}
                    </Stack>
                )}
            </Stack>
        </div>
    )
}
