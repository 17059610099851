import { Box } from "@mui/material"
import React from "react"
import { FormattedMessage } from "react-intl"

import { ItemLabel } from "~/components/ItemLabel/ItemLabel"

interface Props {
    requesterName?: string
}

export function RequesterName({ requesterName }: Props) {
    return (
        <Box component={"div"} className={"requester-name"}>
            <ItemLabel>
                <FormattedMessage id={"purchase.requesterName"} defaultMessage={"Creator"} />
            </ItemLabel>
            <span>{requesterName ?? ""}</span>
        </Box>
    )
}
