import { HandleProps, Position } from "@xyflow/react"
import type { NodeProps, Node as NodeType } from "@xyflow/react"
import React, { FC } from "react"
import { Mail } from "react-feather"
import { useIntl } from "react-intl"

import { messages } from "~/domains/orchestration/flows/messages"
import { SendEmailNode as SendEmailNodeType } from "~/domains/orchestration/flows/types"

import { Node } from "./Node"

export const SendEmailNode: FC<NodeProps<NodeType<SendEmailNodeType>>> = ({ data, selected }) => {
    const { formatMessage } = useIntl()

    const handles: HandleProps[] = [
        {
            type: "target",
            isConnectableEnd: true,
            position: Position.Top,
        },
        {
            type: "source",
            position: Position.Bottom,
            isConnectableStart: true,
        },
    ]

    const error = !data.recipientAddresses.length || !data.subject

    return (
        <Node
            title={formatMessage(messages.sendEmailNode.nodeTitle)}
            name={data.name}
            headerInformation={data.subject}
            icon={Mail}
            handles={handles}
            selected={selected}
            slug={data.slug}
            error={error}
        ></Node>
    )
}
