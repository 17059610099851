import { FC } from "react"
import { CheckCircle, Clock, Info, X, XCircle } from "react-feather"

import "./StatusIcon.scss"

interface StatusIconProps {
    type: "done" | "error" | "info" | "disabled"
}

export const StatusIcon: FC<StatusIconProps> = ({ type }) => {
    const getIcon = () => {
        switch (type) {
            case "done":
                return <CheckCircle />
            case "error":
                return <XCircle />
            case "info":
                return <Info />
            case "disabled":
                return <Clock />
            default:
                return <X />
        }
    }

    return <span className={`status status-${type}`}>{getIcon()}</span>
}
