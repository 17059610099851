import { selectUser } from "~/store/account/accountSlice"
import { useAppSelector } from "~/store/hooks"
import { useCurrentOrganization } from "~/store/organization/hooks"

export const useOrganizationId = () => {
    const user = useAppSelector(selectUser)
    const currentOrganization = useCurrentOrganization(user.organizations)
    const organizationId = currentOrganization?.id || ""

    return organizationId
}
