import * as Sentry from "@sentry/browser"
import { useMemo, useState } from "react"
import { Edit, Trash } from "react-feather"
import { FormattedMessage, defineMessages, useIntl } from "react-intl"
import { toast } from "react-toastify"

import { ActionsMenu, Loader } from "~/components"
import { ConfirmModal } from "~/components/ConfirmModal"
import { PaymentMethodDetails } from "~/domains/payment/payment-method-details/types"
import { useAppDispatch } from "~/store/hooks"

import { useDeletePaymentMethodDetailsMutation } from "../api/paymentMethodDetailsApi"
import { paymentMethodDetailsActions } from "../store/paymentMethodDetailsSlice"
import { mapToEdit } from "../utils/mapPaymentMethodDetails"

const messages = defineMessages({
    edit: {
        id: "payment.paymentMethodDetailsList.edit",
        defaultMessage: "Edit",
    },
    delete: {
        id: "payment.paymentMethodDetailsList.delete",
        defaultMessage: "Delete",
    },
    successMessage: {
        id: "payment.paymentMethodDetailsList.successMessage",
        defaultMessage: "Payment method deleted successfully",
    },
    errorMessage: {
        id: "payment.paymentMethodDetailsList.errorMessage",
        defaultMessage: "An error occurred while deleting the payment method",
    },
    deleteMessage: {
        id: "payment.paymentMethodDetailsList.deleteMessage",
        defaultMessage: "Are you sure you want to delete this payment method?",
    },
})

interface LineActionsProps {
    row: PaymentMethodDetails
}

export const LineActions = ({ row }: LineActionsProps) => {
    const [deletePaymentMethodDetails, { isLoading }] = useDeletePaymentMethodDetailsMutation()
    const [open, setOpen] = useState(false)
    const dispatch = useAppDispatch()
    const { formatMessage } = useIntl()

    const handleEdit = () => {
        const lineToEdit = mapToEdit(row)

        dispatch(paymentMethodDetailsActions.setSelectedItem(lineToEdit))
    }

    const handleDelete = async () => {
        try {
            await deletePaymentMethodDetails(row.id).unwrap()
            setOpen(false)
            toast.success(formatMessage(messages.successMessage))
            return true
        } catch (error) {
            toast.error(formatMessage(messages.errorMessage))
            Sentry.captureException(error, {
                extra: {
                    paymentMethodId: row.id,
                },
            })
            return false
        }
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleOpenDeleteConfirmation = () => {
        setOpen(true)
    }

    const actions = useMemo(
        () => [
            {
                label: <FormattedMessage {...messages.edit} />,
                icon: <Edit size={14} />,
                action: handleEdit,
            },
            {
                label: <FormattedMessage {...messages.delete} />,
                icon: <Trash size={14} />,
                action: handleOpenDeleteConfirmation,
            },
        ],
        [row]
    )

    return (
        <>
            <ActionsMenu actions={actions} />
            <ConfirmModal
                open={open}
                title={<FormattedMessage {...messages.deleteMessage} />}
                close={handleClose}
                onConfirm={handleDelete}
                {...(isLoading ? { confirmButtonText: <Loader small /> } : {})}
            />
        </>
    )
}
