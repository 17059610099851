import { Stack } from "@mui/material"
import React, { FC } from "react"
import { X } from "react-feather"
import { FormattedMessage } from "react-intl"

import { NodeList } from "~/domains/orchestration/flows/components/NodeList"
import {
    AddToBudgetConfiguration,
    CheckNodeConfiguration,
    EventTriggerConfiguration,
    FitsToBudgetConfiguration,
    IfConfiguration,
    InvoiceToPurchaseOrderMatchConfiguration,
    SendEmailConfiguration,
    SetInvoiceLifecycleStatusConfiguration,
    SetPartnershipConfiguration,
} from "~/domains/orchestration/flows/components/configuration"
import { messages } from "~/domains/orchestration/flows/messages"
import {
    type FlowNode,
    NodeType,
    isAddToBudgetNode,
    isCheckNode,
    isEventTriggerNode,
    isFitsToBudgetNode,
    isIfNode,
    isInvoiceToPurchaseOrderMatchNode,
    isSendEmailNode,
    isSetInvoiceLifecycleStatusNode,
    isSetPartnershipFieldNode,
} from "~/domains/orchestration/flows/types"

import { IconButton } from "./IconButton"
import "./SideBar.scss"

interface Props {
    handleDragStart: (nodeType: NodeType) => (event: React.DragEvent<HTMLDivElement>) => void
    selectedNode?: FlowNode
    unselectCallback: () => void
    handleNodeClick: (nodeType: NodeType) => void
    handleClose: () => void
}

const getConfigurationComponent = (selectedNode: FlowNode, unselectCallback: () => void) => {
    switch (true) {
        case isEventTriggerNode(selectedNode):
            return <EventTriggerConfiguration selectedNode={selectedNode} unselectCallback={unselectCallback} />
        case isIfNode(selectedNode):
            return <IfConfiguration selectedNode={selectedNode} unselectCallback={unselectCallback} />
        case isSetPartnershipFieldNode(selectedNode):
            return <SetPartnershipConfiguration selectedNode={selectedNode} unselectCallback={unselectCallback} />
        case isCheckNode(selectedNode):
            return <CheckNodeConfiguration selectedNode={selectedNode} unselectCallback={unselectCallback} />
        case isSendEmailNode(selectedNode):
            return <SendEmailConfiguration selectedNode={selectedNode} unselectCallback={unselectCallback} />
        case isAddToBudgetNode(selectedNode):
            return <AddToBudgetConfiguration selectedNode={selectedNode} unselectCallback={unselectCallback} />
        case isInvoiceToPurchaseOrderMatchNode(selectedNode):
            return (
                <InvoiceToPurchaseOrderMatchConfiguration
                    selectedNode={selectedNode}
                    unselectCallback={unselectCallback}
                />
            )
        case isSetInvoiceLifecycleStatusNode(selectedNode):
            return (
                <SetInvoiceLifecycleStatusConfiguration
                    selectedNode={selectedNode}
                    unselectCallback={unselectCallback}
                />
            )
        case isFitsToBudgetNode(selectedNode):
            return <FitsToBudgetConfiguration selectedNode={selectedNode} unselectCallback={unselectCallback} />
        default:
            return null
    }
}

export const SideBar: FC<Props> = ({
    handleDragStart,
    selectedNode,
    unselectCallback,
    handleNodeClick,
    handleClose,
}) => {
    return (
        <div className="flows-editor-sideBar">
            <Stack
                className="flows-editor-sideBar-header"
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <FormattedMessage
                    tagName="h4"
                    {...(selectedNode ? messages.sideBar.configurationTitle : messages.sideBar.listTitle)}
                />
                <IconButton type="close" size="medium" onClick={handleClose}>
                    <X size={14} />
                </IconButton>
            </Stack>

            {selectedNode ? (
                getConfigurationComponent(selectedNode, unselectCallback)
            ) : (
                <NodeList
                    handleDragStart={handleDragStart}
                    handleClick={handleNodeClick}
                    showLabels
                    direction="column"
                />
            )}
        </div>
    )
}
