import { useCallback, useEffect, useMemo } from "react"

import {
    PurchaseRequestApiWorkflowLineCheckStatusI,
    PurchaseRequestApiWorkflowLineCheckStatusWithUsersI,
    PurchaseRequestCheckReviewerI,
    PurchaseRequestCheckWorkflowI,
    PurchaseRequestCheckWorkflowWithUsers,
    PurchaseRequestWorkflowCheckWithUsersI,
} from "~/features/workflow/types/ApiWorkflow"
import { useFetchOrganizationTeams } from "~/store/organization/hooks"
import { useGetAllUsersQuery } from "~/store/users/hooks"
import { OrganizationId, OrganizationTeamI, TeamMemberI, UserId } from "~/types"
import { isDefined } from "~/utils/isDefined"

import { useFetchPurchaseRequestWorkflows } from "./useFetchPurchaseRequestWorkflows"

type ReviewersResult =
    | {
          loading: true
          error: undefined
          workflowCheck: undefined
      }
    | {
          loading: false
          error: string
          workflowCheck: undefined
      }
    | {
          loading: false
          error: undefined
          workflowCheck: PurchaseRequestWorkflowCheckWithUsersI
      }

const loadingState: ReviewersResult = {
    loading: true,
    error: undefined,
    workflowCheck: undefined,
}

const getReviewersHasApproved = (reviewers: string[]) => (userId: string) => reviewers.includes(userId)
const getTeamMembers = (team: OrganizationTeamI): TeamMemberI[] => team.members
const getTeamMemberUserId = (member: TeamMemberI): UserId => member.userId
const reviewerIsUserReviewer = (reviewer: PurchaseRequestCheckReviewerI) => reviewer.type === "USER"
const reviewerIsTeamReviewer = (reviewer: PurchaseRequestCheckReviewerI) => reviewer.type === "TEAM"
const getCheckReviewers = (check: PurchaseRequestCheckWorkflowI): PurchaseRequestCheckReviewerI[] => check.reviewers
const getReviewerId = (reviewer: PurchaseRequestCheckReviewerI) => reviewer.id

export const usePurchaseRequestWorkflowReviewers = (
    organizationId: OrganizationId,
    purchaseRequestId: string
): ReviewersResult => {
    const { purchaseRequestChecks, fetchPurchaseRequestChecks, loading, error } = useFetchPurchaseRequestWorkflows(
        organizationId,
        purchaseRequestId
    )

    const { teams, loading: loadingTeams } = useFetchOrganizationTeams(organizationId, true)

    const teamReviewersIds = useMemo(
        () =>
            purchaseRequestChecks
                ? [
                      ...purchaseRequestChecks.purchaseRequestCheckStatus.checks
                          .flatMap((check) => check.reviewers)
                          .filter(reviewerIsTeamReviewer)
                          .map(getReviewerId),
                      ...purchaseRequestChecks.lineCheckStatuses
                          .flatMap((lineCheckStatus) => lineCheckStatus.checks)
                          .flatMap((check) => check.reviewers)
                          .filter(reviewerIsTeamReviewer)
                          .map(getReviewerId),
                  ]
                : [],
        [purchaseRequestChecks]
    )

    const reviewersTeams = useMemo(
        () =>
            teams
                .filter((team) => teamReviewersIds.includes(team.teamId))
                .flatMap(getTeamMembers)
                .map(getTeamMemberUserId),
        [teams, teamReviewersIds]
    )

    const userReviewersIds = useMemo(() => {
        if (purchaseRequestChecks) {
            return [
                ...reviewersTeams,

                ...purchaseRequestChecks.purchaseRequestCheckStatus.checks
                    .flatMap(getCheckReviewers)
                    .filter(reviewerIsUserReviewer)
                    .map(getReviewerId),

                ...purchaseRequestChecks.lineCheckStatuses.flatMap((lineCheckStatus) =>
                    lineCheckStatus.checks.flatMap(getCheckReviewers).filter(reviewerIsUserReviewer).map(getReviewerId)
                ),
            ]
        }
        return []
    }, [purchaseRequestChecks, reviewersTeams])

    const { users, loading: loadingUsers } = useGetAllUsersQuery(userReviewersIds)

    useEffect(() => {
        if (purchaseRequestId) {
            fetchPurchaseRequestChecks()
        }
    }, [fetchPurchaseRequestChecks, purchaseRequestId])

    const getUserForId = useCallback((userId: string) => users.find((user) => user.id === userId), [users])

    const getReviewerIdOrTeamReviewerIds = useCallback(
        (reviewer: PurchaseRequestCheckReviewerI) => {
            if (reviewerIsTeamReviewer(reviewer)) {
                return reviewersTeams
            }
            return [reviewer.id]
        },
        [reviewersTeams]
    )

    return useMemo(() => {
        if (error) {
            return {
                loading: false,
                error,
                workflowCheck: undefined,
            }
        }
        if (loading || loadingUsers || loadingTeams || !purchaseRequestChecks) {
            return loadingState
        }
        const checkToCheckWithUsers = (check: PurchaseRequestCheckWorkflowI): PurchaseRequestCheckWorkflowWithUsers => {
            const approversIds = purchaseRequestChecks.purchaseRequestCheckStatus.approvers.filter(
                getReviewersHasApproved(check.reviewers.flatMap(getReviewerIdOrTeamReviewerIds))
            )

            return {
                workflowId: check.workflowId,
                reviewers: [
                    ...check.reviewers
                        .filter(reviewerIsTeamReviewer)
                        .map(getReviewerId)
                        .flatMap((teamId) => teams.find((team) => team.teamId === teamId))
                        .filter(isDefined)
                        .flatMap(getTeamMembers)
                        .map(getTeamMemberUserId)
                        .map(getUserForId)
                        .filter(isDefined),

                    ...check.reviewers
                        .filter(reviewerIsUserReviewer)
                        .map(getReviewerId)
                        .map(getUserForId)
                        .filter(isDefined),
                ],
                checkThreshold: check.checkThreshold,
                approvers: approversIds.map(getUserForId).filter(isDefined),
                progress: approversIds.length / check.checkThreshold,
            }
        }
        const lineCheckStatusToLineCheckStatusWithUser = (
            lineCheckStatus: PurchaseRequestApiWorkflowLineCheckStatusI
        ): PurchaseRequestApiWorkflowLineCheckStatusWithUsersI => ({
            ...lineCheckStatus,
            checks: lineCheckStatus.checks.map(checkToCheckWithUsers),
        })
        const workflowCheckWithUsers: PurchaseRequestWorkflowCheckWithUsersI = {
            ...purchaseRequestChecks,
            purchaseRequestCheckStatus: {
                checks: purchaseRequestChecks.purchaseRequestCheckStatus.checks.map(checkToCheckWithUsers),
                approvers: purchaseRequestChecks.purchaseRequestCheckStatus.approvers,
                refusers: purchaseRequestChecks.purchaseRequestCheckStatus.refusers,
            },
            lineCheckStatuses: purchaseRequestChecks.lineCheckStatuses.map(lineCheckStatusToLineCheckStatusWithUser),
        }
        return {
            loading: false,
            error: undefined,
            workflowCheck: workflowCheckWithUsers,
        }
    }, [purchaseRequestChecks, getUserForId, loading, loadingTeams, teams, loadingUsers, users, error])
}
