import React, { useState } from "react"
import { OrganizationId } from "~/types"
import { Share2 } from "react-feather"
import { useIntl } from "react-intl"
import { OrganizationSummary } from "~/domains/transactions/components/Organizations/Organizations"
import { ShareObjectPermissionsModal } from "./ShareObjectPermissionsModal"
import {
    sharedObjectPermissionsModalMessages,
    SpiceDBObjectType,
} from "~/domains/identity/features/roles-permissions/types/SpiceDB"
import { Button } from "~/components"

interface Props {
    organizationId: OrganizationId
    objectType: SpiceDBObjectType
    objectId: string
    buyerOrganization: OrganizationSummary | undefined
    supplierOrganization: OrganizationSummary | undefined
    showIcon?: boolean
}

export const ShareObjectPermissionsButton: React.FC<Props> = ({
    organizationId,
    objectType,
    objectId,
    buyerOrganization,
    supplierOrganization,
    showIcon = false,
}) => {
    const { formatMessage } = useIntl()
    const [shareObjectModalOpen, setShareObjectModalOpen] = useState<boolean>(false)

    const showShareObjectModal = () => setShareObjectModalOpen(true)
    const hideShareObjectModal = () => setShareObjectModalOpen(false)

    return (
        <>
            <Button type="tertiary" buttonType="button" onClick={showShareObjectModal}>
                {showIcon ? <Share2 size={16} /> : null}
                <span>{formatMessage(sharedObjectPermissionsModalMessages.share)}</span>
            </Button>
            {shareObjectModalOpen && (
                <ShareObjectPermissionsModal
                    open={shareObjectModalOpen}
                    close={hideShareObjectModal}
                    organizationId={organizationId}
                    objectType={objectType}
                    objectId={objectId}
                    buyerOrganization={buyerOrganization}
                    supplierOrganization={supplierOrganization}
                />
            )}
        </>
    )
}
