import { TextField, styled } from "@mui/material"
import { useState } from "react"
import { FormattedMessage, defineMessages, useIntl } from "react-intl"

import { Button, CountrySelector, CurrencySelector, Loader } from "~/components"
import { useFormValidation } from "~/domains/payment/payment-method-details/hooks"
import { BankTransferFormState } from "~/domains/payment/payment-method-details/types"
import { bankTransferFormValidationSchema } from "~/domains/payment/payment-method-details/utils/formsValidationSchemas"
import { CountryCode, CurrencyCodes, CurrencyI } from "~/types"

const messages = defineMessages({
    iban: {
        id: "payment.bankTransferPaymentMethodForm.iban",
        defaultMessage: "IBAN",
    },
    bicSwift: {
        id: "payment.bankTransferPaymentMethodForm.bicSwift",
        defaultMessage: "BIC / SWIFT",
    },
    bankName: {
        id: "payment.bankTransferPaymentMethodForm.bankName",
        defaultMessage: "Bank name",
    },
    accountHolderName: {
        id: "payment.bankTransferPaymentMethodForm.accountHolderName",
        defaultMessage: "Account holder name",
    },
    bankAccountNumber: {
        id: "payment.bankTransferPaymentMethodForm.bankAccountNumber",
        defaultMessage: "Bank account number",
    },
    currency: {
        id: "payment.bankTransferPaymentMethodForm.currency",
        defaultMessage: "Currency",
    },
    country: {
        id: "payment.bankTransferPaymentMethodForm.country",
        defaultMessage: "Country",
    },
    saveButton: {
        id: "payment.bankTransferPaymentMethodForm.saveButton",
        defaultMessage: "Save",
    },
    cancelButton: {
        id: "payment.bankTransferPaymentMethodForm.cancelButton",
        defaultMessage: "Cancel",
    },
    kid: {
        id: "payment.paymentMethodDetailsList.kid",
        defaultMessage: "KID",
    },
})

const Form = styled("form")({
    display: "flex",
    flexDirection: "column",
    gap: "16px",
})

const FormActions = styled("div")({
    display: "flex",
    justifyContent: "flex-end",
    gap: "12px",
})

const initialState: BankTransferFormState = {
    bankAccountNumber: "",
    bankName: "",
    iban: "",
    bicSwift: "",
    holderName: "",
    currency: CurrencyCodes.EUR,
    country: null,
    kid: "",
}

interface BankTransferPaymentMethodFormProps {
    loading?: boolean
    selectedItem: BankTransferFormState | null
    onCancel: (resetForm: () => void) => void
    onSubmit: (formData: BankTransferFormState, resetForm: () => void) => void
}

export const BankTransferPaymentMethodForm = ({
    selectedItem,
    onSubmit,
    onCancel,
    loading = false,
}: BankTransferPaymentMethodFormProps) => {
    const [formData, setFormData] = useState<BankTransferFormState>(selectedItem ?? initialState)
    const { errors, validate } = useFormValidation<BankTransferFormState>(formData, bankTransferFormValidationSchema)
    const { formatMessage } = useIntl()

    const resetForm = () => {
        setFormData(initialState)
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        e.stopPropagation()

        const isValid = validate()

        if (onSubmit && isValid) {
            onSubmit(formData, resetForm)
        }
    }

    const handleCurrencyChange = (currency: CurrencyI | null) => {
        setFormData({
            ...formData,
            currency: currency?.code ?? CurrencyCodes.EUR,
        })
    }

    const handleCountryChange = (countryCode: CountryCode | null) => {
        setFormData({
            ...formData,
            country: countryCode,
        })
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        })
    }

    const handleCancel = () => {
        onCancel(resetForm)
    }

    return (
        <Form noValidate onSubmit={handleSubmit}>
            <TextField
                label={formatMessage(messages.accountHolderName)}
                name="holderName"
                value={formData.holderName}
                disabled={loading}
                variant="filled"
                fullWidth
                autoComplete="off"
                onChange={handleChange}
            />
            <TextField
                label={formatMessage(messages.iban)}
                name="iban"
                value={formData.iban}
                disabled={loading}
                variant="filled"
                error={!!errors.iban}
                helperText={errors.iban}
                fullWidth
                autoComplete="off"
                onChange={handleChange}
            />
            <TextField
                label={formatMessage(messages.bankAccountNumber)}
                name="bankAccountNumber"
                value={formData.bankAccountNumber}
                disabled={loading}
                variant="filled"
                error={!!errors.bankAccountNumber}
                helperText={errors.bankAccountNumber}
                fullWidth
                autoComplete="off"
                onChange={handleChange}
            />
            <TextField
                label={formatMessage(messages.bicSwift)}
                name="bicSwift"
                value={formData.bicSwift}
                disabled={loading}
                variant="filled"
                error={!!errors.bicSwift}
                helperText={errors.bicSwift}
                fullWidth
                autoComplete="off"
                onChange={handleChange}
            />
            <TextField
                label={formatMessage(messages.bankName)}
                name="bankName"
                value={formData.bankName}
                disabled={loading}
                variant="filled"
                fullWidth
                autoComplete="off"
                onChange={handleChange}
            />
            <CountrySelector
                label={formatMessage(messages.country)}
                value={formData.country}
                disabled={loading}
                onChange={handleCountryChange}
                variant="filled"
            />
            {formData.country === CountryCode.NO && (
                <TextField
                    label={formatMessage(messages.kid)}
                    name="kid"
                    value={formData.kid}
                    disabled={loading}
                    variant="filled"
                    error={!!errors.kid}
                    helperText={errors.kid}
                    fullWidth
                    autoComplete="off"
                    onChange={handleChange}
                />
            )}
            <CurrencySelector
                editMode
                label={formatMessage(messages.currency)}
                value={formData.currency}
                variant="filled"
                disabled={loading}
                onChange={handleCurrencyChange}
            />
            <FormActions>
                <Button type="neutral" onClick={handleCancel}>
                    <FormattedMessage {...messages.cancelButton} />
                </Button>
                <Button type="primary" buttonType="submit">
                    {loading ? <Loader small /> : <FormattedMessage {...messages.saveButton} />}
                </Button>
            </FormActions>
        </Form>
    )
}
