import styled from "@emotion/styled"
import React, { useState } from "react"
import { Play } from "react-feather"
import { useIntl } from "react-intl"
import { generatePath, useNavigate } from "react-router-dom"

import { HeaderH1 } from "~/components/Header/HeaderH1"
import { Button, useTitle } from "~/core"
import { Filter } from "~/domains/orchestration/flows/components/Filter"
import { RunsTable } from "~/domains/orchestration/flows/components/RunsTable"
import { messages } from "~/domains/orchestration/flows/messages"
import { FLOWS_LIST } from "~/domains/orchestration/flows/routes"

const StyledViewSwitcher = styled.div`
    display: flex;
    margin-top: 100px;
`

const StyledContent = styled.div`
    margin-top: var(--spacing-lg);
`

export const RunsList: React.FC = () => {
    const { formatMessage } = useIntl()
    const navigate = useNavigate()
    const pageName = formatMessage(messages.page.title)
    const [filter, setFilter] = useState("")

    useTitle(pageName)

    const filterChangeCallback = (filter: string) => {
        setFilter(filter)
    }

    const handleViewChange = () => {
        const path = generatePath(FLOWS_LIST)
        navigate(path)
    }

    return (
        <>
            <HeaderH1 title={pageName} icon={<Play />} />
            <StyledViewSwitcher className="view-switcher">
                <Button type="tab" onClick={handleViewChange}>
                    {formatMessage(messages.page.flowsTab)}
                </Button>
                <Button type="tab" className={"selected"}>
                    {formatMessage(messages.page.runsTab)}
                </Button>
            </StyledViewSwitcher>
            <StyledContent>
                <Filter
                    filterChangeCallback={filterChangeCallback}
                    filter={filter}
                    hasCreatePermission={false}
                    createCallback={() => {}}
                />
                <RunsTable filter={filter} />
            </StyledContent>
        </>
    )
}
