import { InvoiceReviewerWithUsersI } from "~/features/workflow/types/ApiWorkflow"

export const workflowMock = {
    loading: false,
    withinWorkflow: true,
    checkDone: 0,
    totalChecks: 1,
    approvers: [],
    refusers: [],
    reviewers: [
        {
            type: "USER",
            value: {
                id: "6537880532b88901a70f8132",
                email: "nicolas@flowie.fr",
                emailVerified: false,
                organizations: [
                    {
                        id: "64ec6c29dd74306a85f11c19",
                    },
                    {
                        id: "6703e91850789d0f5f3e5b9f",
                    },
                    {
                        id: "67065bd350789d0f5f3e5bab",
                    },
                    {
                        id: "670ff49144455e7c26f25aa1",
                    },
                ],
                invitations: [],
                membershipRequests: [],
                fullName: "Nicolas SANDRON",
                phoneNumber: "",
                phoneVerified: false,
                picture: null,
            },
        },
    ] as unknown as InvoiceReviewerWithUsersI[],
}
