import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { OCRInputTypes } from "~/features/invoice/components/OcrExtract/extract"
import { RootState } from "~/store"
import {
    AlreadyPaidPaymentI,
    CompanyDetailsI,
    CompanyRegistrationI,
    CountryCode,
    InvoiceUserType,
    NotificationI,
    OcrImagesI,
    OcrInvoiceI,
    OcrResultI,
    PaymentDetailsI,
    UserId,
} from "~/types"
import { deleteCookie, mergeDeep } from "~/utils"

import { OcrDocumentId } from "../../features/ocr/types/OcrDocumentId"
import { ocrState } from "./ocrState"

const initialState = ocrState

const cleanInvoice = <T extends object = OcrInvoiceI | CompanyDetailsI | PaymentDetailsI | NotificationI>(
    invoice: T
): T => {
    const data = {}
    for (const [k, v] of Object.entries(invoice)) {
        if (!v) {
            // data["dueDate"] = (k === "dueDate") ? dayjs().format("YYYY-MM-DD") : ""
            data["dueDate"] = ""
            continue
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        data[k] = typeof v === "object" ? cleanInvoice(v) : v
    }
    return data as T
}

const checkInvoiceData = (invoice: OcrInvoiceI) => {
    if (!invoice.totalExcludedTaxes) {
        invoice.totalExcludedTaxes = invoice.total ?? 0
    }
    if (!invoice.dueDate || invoice.dueDate === "") {
        invoice.dueDate = ""
        // invoice.dueDate = dayjs().format("YYYY-MM-DD")
    }
    return invoice
}

const ocrSlice = createSlice({
    name: "ocr",
    initialState: initialState,
    reducers: {
        newUpload(state) {
            state.loading = true
        },
        newUploadSuccess(state, action: PayloadAction<OcrDocumentId>) {
            state.loading = false
            state.ocrDocumentId = action.payload
        },
        newUploadFailed(state) {
            state.loading = false
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        uploadDocument(state, _action: PayloadAction<{ file: File; userType: InvoiceUserType }>) {
            state.loading = true
        },
        uploadDocumentSuccess(state, action: PayloadAction<OcrResultI>) {
            state.loading = false
            state.invoiceId = action.payload.invoiceId
        },
        uploadDocumentFailed(state, action: PayloadAction<string>) {
            state.loading = false
            state.error = action.payload
        },
        setOcrData(state, action: PayloadAction<OcrInvoiceI>) {
            const cleaned = cleanInvoice(action.payload) as OcrInvoiceI
            const invoice = checkInvoiceData(cleaned)
            const mergedInvoice = mergeDeep(state.data, invoice)
            if (!mergedInvoice.buyer) {
                mergedInvoice.buyer = {
                    name: "",
                    userId: "",
                    contactName: "",
                    registrations: {
                        countryCode: CountryCode.UNKNOWN,
                        dunsNumber: "",
                        registrationNumber: {
                            registrationNumber: "",
                            registrationType: "UNKNOWN",
                        },
                        vatNumber: "",
                    },
                }
            }
            if (!mergedInvoice.supplier) {
                mergedInvoice.supplier = {
                    name: "",
                    userId: "",
                    contactName: "",
                    registrations: {
                        countryCode: CountryCode.UNKNOWN,
                        dunsNumber: "",
                        registrationNumber: {
                            registrationNumber: "",
                            registrationType: "UNKNOWN",
                        },
                        vatNumber: "",
                    },
                }
            }
            state.data = mergedInvoice
            state.dataLoaded = true
        },
        setImageLinks(state, action: PayloadAction<OcrImagesI>) {
            state.imageLinks = action.payload.imageLinks
        },
        setOcrDetails(state, action: PayloadAction<string>) {
            state.details = action.payload
        },
        clearPaymentDetails(state) {
            state.data.paymentDetails = {}
        },
        confirmInvoice(state) {
            state.loading = true
            state.addingInvoice = true
        },
        confirmInvoiceSuccess(state) {
            state.loading = false
            state.invoiceSent = true
            state.error = null
            deleteCookie("jwt")
        },
        confirmInvoiceFailed(state, action: PayloadAction<string>) {
            state.loading = false
            state.error = action.payload
            state.confirmInvoiceSuccess = false
            state.addingInvoice = false
            state.invoiceSent = false
        },
        resetState() {
            return initialState
        },
        addInvolvedPeople(state) {
            state.involvedPeople.push("")
        },
        updateInvolvedPeople(state, action: PayloadAction<{ index: number; value: UserId }>) {
            state.involvedPeople[action.payload.index] = action.payload.value
        },
        removeInvolvedPeople(state, action: PayloadAction<number>) {
            state.involvedPeople.splice(action.payload, 1)
        },
        setInitiator(state, action: PayloadAction<InvoiceUserType>) {
            state.initiator = action.payload
        },
        updatePartial(state, action: PayloadAction<Partial<OcrInvoiceI>>) {
            state.data = {
                ...state.data,
                ...action.payload,
            }
        },
        updatePartialBuyer(state, action: PayloadAction<Partial<CompanyDetailsI>>) {
            state.data.buyer = {
                ...state.data.buyer,
                ...action.payload,
            }
        },
        updatePartialBuyerRegistration(state, action: PayloadAction<Partial<CompanyRegistrationI>>) {
            state.data.buyer.registrations = {
                ...(state.data.buyer.registrations || {
                    countryCode: CountryCode.UNKNOWN,
                }),
                ...action.payload,
            }
        },
        updatePartialSupplier(state, action: PayloadAction<Partial<CompanyDetailsI>>) {
            state.data.supplier = {
                ...state.data.supplier,
                ...action.payload,
            }
        },
        updatePartialSupplierRegistration(state, action: PayloadAction<Partial<CompanyRegistrationI>>) {
            state.data.supplier.registrations = {
                ...(state.data.supplier.registrations || {
                    countryCode: CountryCode.UNKNOWN,
                }),
                ...action.payload,
            }
        },
        updatePaymentData(state, action: PayloadAction<Partial<PaymentDetailsI>>) {
            state.data.paymentDetails = {
                ...(state.data.paymentDetails || {}),
                ...action.payload,
            }
        },
        updateOcrShowShapes(state, action: PayloadAction<boolean>) {
            state.showShapes = action.payload
        },
        updateOcrCurrentInput(state, action: PayloadAction<string>) {
            state.currentInput = action.payload
        },
        updateOcrCurrentInputType(state, action: PayloadAction<OCRInputTypes | null>) {
            state.currentInputType = action.payload
        },
        updateOcrCurrentAutocompleteText(state, action: PayloadAction<string>) {
            state.currentAutocompleteText = action.payload
        },
        getOcrResult(state, action: PayloadAction<string>) {
            state.loading = true
            state.invoiceId = action.payload
        },
        ocrResultSuccess(state, action: PayloadAction<any>) {
            // TODO change PayloadAction type
            state.loading = false
            state.details = action.payload.rawOcrResult
        },
        ocrResultFailed(state, action: PayloadAction<string>) {
            state.loading = false
            state.error = action.payload
        },
        updateHasOcr(state, action: PayloadAction<boolean>) {
            state.hasOcr = action.payload
        },
        updateAlreadyPaidPaymentData(state, action: PayloadAction<Partial<AlreadyPaidPaymentI>>) {
            state.alreadyPaidPayment = {
                ...state.alreadyPaidPayment,
                ...action.payload,
            }
        },
        resetAlreadyPaidPaymentData(state) {
            state.alreadyPaidPayment = {
                alreadyPaid: false,
                executionDate: null,
                requestIBAN: false,
            }
        },
        resetOcrDetails(state) {
            state.details = ""
        },
    },
})

export const ocrActions = ocrSlice.actions

export const selectOcrLoading = (state: RootState) => state.ocr.loading
export const selectInvoiceSent = (state: RootState) => state.ocr.invoiceSent
export const selectOcrDataLoaded = (state: RootState) => state.ocr.dataLoaded
export const selectOcrData = (state: RootState) => state.ocr.data
export const selectOcrInvoiceId = (state: RootState) => state.ocr.invoiceId
export const selectOcrDocumentId = (state: RootState) => state.ocr.ocrDocumentId
export const selectOcrImages = (state: RootState) => state.ocr.imageLinks
export const selectAddingInvoice = (state: RootState) => state.ocr.addingInvoice
export const selectOcrInvolvedPeople = (state: RootState) => state.ocr.involvedPeople
export const selectOcrShowShapes = (state: RootState) => state.ocr.showShapes
export const selectOcrCurrentInput = (state: RootState) => state.ocr.currentInput
export const selectOcrCurrentInputType = (state: RootState) => state.ocr.currentInputType
export const selectOcrCurrentAutocompleteText = (state: RootState) => state.ocr.currentAutocompleteText
export const selectOcrDetails = (state: RootState) => state.ocr.details
export const selectInitiator = (state: RootState) => state.ocr.initiator
export const selectHasOcr = (state: RootState) => state.ocr.hasOcr
export const selectAlreadyPaidPayment = (state: RootState) => state.ocr.alreadyPaidPayment

const ocrReducer = ocrSlice.reducer
export default ocrReducer

/*
 * NOTES :
 * state.error is not used has we display only error from errorStore but it could be convenient to display special errors in pages/components
 * */
