import { defineMessages, useIntl } from "react-intl"
import { toast } from "react-toastify"
import { invoiceApi } from "~/api"
import { useAppDispatch } from "~/store/hooks"
import { InvoiceId, OrganizationId } from "~/types"
import { invoiceActions } from "../invoiceSlice"

const messages = defineMessages({
    invoiceApprovalRemoved: {
        id: "invoice.buyer.invoiceApprovalRemoved",
        defaultMessage: "The invoice approval has been successfully removed.",
    },
    unableToRemoveInvoiceApproval: {
        id: "invoice.buyer.unableToRemoveInvoiceApproval",
        defaultMessage: "An error occured: the invoice approval could not be removed.",
    },
})

export const useRemoveInvoiceApproval = () => {
    const dispatch = useAppDispatch()
    const { formatMessage } = useIntl()
    return async (invoiceId: InvoiceId, organizationId: OrganizationId) => {
        try {
            await invoiceApi.removeApproval(invoiceId, organizationId)
            dispatch(invoiceActions.removeInvoiceApproval())
            toast.success(formatMessage(messages.invoiceApprovalRemoved))
            return true
        } catch {
            toast.error(formatMessage(messages.unableToRemoveInvoiceApproval))
        }
        return false
    }
}
