import { TextField } from "@mui/material"
import React, { ChangeEvent, useCallback, useEffect, useState } from "react"

import { useSaveBuffer } from "~/hooks"
import { OrganizationId } from "~/types"

import { useCustomFieldValue, useSavePendingCustomFieldValue, useUpdateCustomFieldValue } from "../../hooks"
import { useCreateCustomField } from "../../hooks/useCreateCustomField"
import { CustomFieldI } from "../../types/CustomField"
import { CustomFieldObjectType } from "../../types/CustomFieldObjectType"

interface Props {
    objectId: string
    isTemporaryId: boolean
    quantity: number
    customField: CustomFieldI
    contextType: CustomFieldObjectType
    contextId?: string
    organizationId?: OrganizationId
}

export const ESGCustomFieldStringCell: React.FC<Props> = ({
    objectId,
    isTemporaryId,
    quantity,
    customField,
    contextId,
    contextType,
    organizationId,
}) => {
    const [value, setValue] = useState<string>("")
    const savedValue = useCustomFieldValue(customField, objectId)
    const createCustomField = useCreateCustomField()
    const updateCustomFieldValue = useUpdateCustomFieldValue(objectId, customField)
    const savePendingCustomFieldValue = useSavePendingCustomFieldValue(customField)

    useEffect(() => {
        if (savedValue) {
            setValue(savedValue.value)
        }
    }, [savedValue])

    const save = useCallback(() => {
        if (contextId && !isTemporaryId) {
            if (!savedValue) {
                createCustomField({
                    name: customField.name,
                    unit: customField.unit[0],
                    value,
                    quantity,
                    objectId,
                    contextType,
                    contextId,
                    organizationId,
                })
            } else {
                updateCustomFieldValue(savedValue.id, value)
            }
        } else {
            savePendingCustomFieldValue({
                name: customField.name,
                unit: customField.unit[0],
                value,
                quantity,
                objectId,
                contextType,
                contextId,
                organizationId,
            })
        }
    }, [
        createCustomField,
        updateCustomFieldValue,
        value,
        objectId,
        quantity,
        customField,
        contextId,
        contextType,
        isTemporaryId,
        savePendingCustomFieldValue,
    ])

    const { setHasChanges } = useSaveBuffer(
        save,
        contextId && !isTemporaryId ? undefined : { savingTime: 0, displaySavedTime: 0 }
    )

    const onChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const newValue = event.currentTarget.value
            setValue(newValue)
            setHasChanges(true)
        },
        [setHasChanges, setValue]
    )

    return <TextField value={value} placeholder={customField.unit[0]} onChange={onChange} type="text" size="small" />
}
