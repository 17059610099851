import { MenuItem, TextField, styled } from "@mui/material"
import React from "react"
import { defineMessages, useIntl } from "react-intl"

import { DocumentType } from "~/domains/identity/features/documents/types"

import { documentTypeMessages } from "./UploadDocument"

const messages = defineMessages({
    documentType: { id: "documents.modal.uploadFile.documentType", defaultMessage: "Type" },
})

interface Props {
    value: DocumentType | null
    showLabel?: boolean
    onChange: (value: DocumentType) => void
    size?: "small" | "medium"
}

const TextFieldContainer = styled(TextField)({
    "&.no-label > .MuiInputBase-root > .MuiSelect-select": {
        paddingTop: 12,
    },
})

export const SelectDocumentType: React.FC<Props> = ({ value, onChange, showLabel = true, size = "medium" }) => {
    const { formatMessage } = useIntl()

    return (
        <TextFieldContainer
            select
            required
            name="uploadDocument.type"
            label={showLabel ? formatMessage(messages.documentType) : undefined}
            value={value}
            onChange={(event: React.ChangeEvent<{ value: string }>) => {
                onChange(event.target.value as DocumentType)
            }}
            fullWidth
            className={`documents-fields${!showLabel ? " no-label" : ""}`}
            SelectProps={{
                MenuProps: {
                    id: "menu-document-types",
                },
            }}
            size={size}
        >
            {Object.values(DocumentType).map((type) => (
                <MenuItem key={DocumentType[type]} value={type}>
                    {formatMessage(documentTypeMessages[type])}
                </MenuItem>
            ))}
        </TextFieldContainer>
    )
}
