import { InputLabel as MuiInputLabel, styled } from "@mui/material"

export const InputLabel = styled(MuiInputLabel)(({ theme, shrink }) => ({
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "19px",
    color: theme.palette.grey[900],
    top: "16px",
    left: "16px",
    zIndex: 1,
    ...(shrink && {
        marginTop: "-12px",
        left: 0,
    }),
    '&[data-shrink="true"]': {
        marginTop: "-15px",
        left: 0,
        background: "var(--color-white)",
    },
}))
