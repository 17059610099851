import { toast } from "react-toastify"
import { commonMessages } from "~/common-messages"
import { useCallback } from "react"
import { useIntl } from "react-intl"
import { useTagsApi } from "../tagsApi"
import { OrganizationId } from "~/types"
import { TagObjectType } from "../types"

export const useDuplicateTags = () => {
    const { formatMessage } = useIntl()
    const tagsApi = useTagsApi()

    const duplicateTags = useCallback(
        async (
            organizationId: OrganizationId,
            sourceObjectId: string,
            destinationObjectId: string,
            objectType: TagObjectType
        ): Promise<boolean | undefined> => {
            try {
                return await tagsApi.duplicateObjectTags(
                    organizationId,
                    sourceObjectId,
                    destinationObjectId,
                    objectType
                )
            } catch (error) {
                console.error(error)
                toast.error(formatMessage(commonMessages.errorContact))
            }
        },
        []
    )

    return { duplicateTags }
}
