import { InvoiceV1Payload } from "~/domains/transactions/invoices-v1/types/InvoicePayload"
import { UpdateDraftInvoicePayload } from "~/types"

export const invoiceV0ToApiAdapter = (invoice: Partial<UpdateDraftInvoicePayload>): Partial<InvoiceV1Payload> => {
    const apiInvoice = {
        version: invoice.version,
        initiator: invoice.initiator,
        description: invoice.description ?? undefined,
        reference: invoice.reference ?? undefined,
        purchaseOrderNumber: invoice.purchaseOrderNumber ?? undefined,
        total: invoice.total ?? undefined,
        totalDiscount: invoice.totalDiscount ?? undefined,
        totalExcludedTaxes: invoice.totalExcludedTaxes ?? undefined,
        dueDate: invoice.dueDate ?? undefined,
        issueDate: invoice.issueDate ?? undefined,
        buyer: invoice.buyer ?? undefined,
        supplier: invoice.supplier ?? undefined,
        paymentDetails: invoice.paymentDetails ?? undefined,
        lines: invoice.lines ?? [],
    }

    return JSON.parse(JSON.stringify(apiInvoice))
}
