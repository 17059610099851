import { useCallback, useEffect, useState } from "react"
import { defineMessages, useIntl } from "react-intl"

import { budgetApi } from "~/api/budgetApi"
import { Card } from "~/components"
import { withSocketIOProvider } from "~/domains/transactions/common/subscriptions/components/SocketIOContext"
import { ModalAddToBudget } from "~/domains/transactions/components/ModalAddToBudget/ModalAddToBudget"
import { TransactionInBudgets } from "~/domains/transactions/components/TransactionInBudgets"
import { InvoiceBudgetGauge } from "~/domains/transactions/invoices-v1/components/InvoiceBudgetGauge"
import { AddTransactionPayloadI, TransactionType } from "~/features/budget/types"
import { useFetchBudgetsData } from "~/store/budget/hooks"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { useFetchInvoice, useSubscribeInvoiceUpdates } from "~/store/invoice/hooks"
import { invoiceActions } from "~/store/invoice/invoiceSlice"
import { selectCurrentOrganization } from "~/store/organization/organizationSlice"
import { InvoiceI, ViewTypeI } from "~/types"

const messages = defineMessages({
    budgetsTitle: { id: "purchase.orders.order.budgets", defaultMessage: "Budgets" },
})

interface InvoiceBudgetsProps extends JSX.IntrinsicAttributes {
    invoice: InvoiceI
}

export const InvoiceBudgets = withSocketIOProvider(({ invoice }: InvoiceBudgetsProps) => {
    const { formatMessage } = useIntl()
    const dispatch = useAppDispatch()
    const { buyerOrganization, refetchInvoice } = useFetchInvoice(invoice.id, ViewTypeI.buyer)
    useSubscribeInvoiceUpdates(buyerOrganization?.id, invoice.id)

    const organization = useAppSelector(selectCurrentOrganization)
    const { budgetsData, reFetchBudgetsData } = useFetchBudgetsData(organization?.id || "", false)

    const [modalAddToBudgetVisible, setModalAddToBudgetVisible] = useState(false)
    const [transactionPayload, setTransactionPayload] = useState<AddTransactionPayloadI | null>(null)

    const showModalAddToBudget = () => setModalAddToBudgetVisible(true)
    const hideModalAddToBudget = () => setModalAddToBudgetVisible(false)

    useEffect(() => {
        if (!organization?.id) return
        reFetchBudgetsData()
    }, [organization?.id, dispatch])

    useEffect(() => {
        if (!invoice?.payerId) return
        ;(async () => {
            // This comes from useFetchInvoice
            // only handle buyer view for now
            const budgets = await budgetApi.getBudgetByTransaction(invoice.payerId, invoice.id, TransactionType.INVOICE)
            dispatch(invoiceActions.updatePartialInvoice({ id: invoice.id, budgets }))
        })()
    }, [invoice?.sellerId, invoice?.id, dispatch])

    useEffect(() => {
        if (!invoice?.payerId) return

        setTransactionPayload({
            transactionRefId: invoice.id,
            transactionStatus: invoice.status,
            transactionType: TransactionType.INVOICE,
            supplierOrgId: invoice.supplier?.organizationId ?? "",
            buyerOrgId: invoice.payerId,
            description: invoice.description ?? "",
            amount: invoice.total ?? 0,
            amountWithoutTaxes: invoice.totalExcludedTaxes ?? 0,
            amountRemainingToPay: 0,
        })
    }, [invoice])

    if (!invoice) return null

    const handleSuccess = useCallback(async () => {
        dispatch(invoiceActions.updatePartialInvoice(invoice))
        await refetchInvoice()
    }, [dispatch, refetchInvoice])

    return (
        <Card title={formatMessage(messages.budgetsTitle)} expandable>
            <TransactionInBudgets transaction={invoice} showModalAddToBudget={showModalAddToBudget} noCard />
            <InvoiceBudgetGauge invoice={invoice} />

            {invoice.payerId && transactionPayload && (
                <ModalAddToBudget
                    open={modalAddToBudgetVisible}
                    close={hideModalAddToBudget}
                    organizationId={invoice.payerId}
                    budgets={budgetsData}
                    transaction={invoice}
                    transactionPayload={transactionPayload}
                    handleSuccess={handleSuccess}
                />
            )}
        </Card>
    )
})
