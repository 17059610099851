import React, { PropsWithChildren } from "react"
import { CountryCode, isValidCountryCode } from "~/types"
import { OrganizationLogo } from "../OrganizationLogo/OrganizationLogo"
import { styled } from "@mui/material"
import { defineMessages, useIntl } from "react-intl"
import { getRegistrationNumberLabel } from "~/features/organization/components/CompanyRegistrationFields"

interface Props extends PropsWithChildren {
    name: string
    countryCode: CountryCode
    registrationNumber?: string | null
    domain?: string | null
}

const Container = styled("div")({
    display: "flex",
    gap: "8px",
    flex: "1 1 auto",
    overflow: "hidden",
})

const OrganizationLabel = styled("div")({
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
})

const OrganizationName = styled("div")({
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    color: "var(--color-grey)",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    img: {
        verticalAlign: "middle",
        marginRight: "4px",
        borderRadius: "2px",
    },
})

const OrganizationRegistrationNumber = styled("div")({
    fontSize: "9px",
    fontWeight: 500,
    lineHeight: "18px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
})

const messages = defineMessages({
    registrationNumber: {
        id: "company.registrationNumber.registrationNumberWithlabel",
        defaultMessage: "{label}: {registrationNumber}",
    },
})

const getCountryFlag = (countryCode: CountryCode) =>
    isValidCountryCode(countryCode) ? (
        <img
            loading="lazy"
            width="14"
            src={`https://flagcdn.com/w20/${countryCode.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${countryCode.toLowerCase()}.png 2x`}
            alt=""
        />
    ) : null

export const OrganizationItem: React.FC<Props> = ({ name, countryCode, registrationNumber, domain, children }) => {
    const { formatMessage } = useIntl()

    return (
        <Container>
            <OrganizationLogo name={name} domain={domain} />
            <OrganizationLabel>
                <OrganizationName>
                    {getCountryFlag(countryCode)}
                    {name}
                </OrganizationName>
                {registrationNumber && (
                    <OrganizationRegistrationNumber>
                        {formatMessage(messages.registrationNumber, {
                            label: formatMessage(getRegistrationNumberLabel(countryCode)),
                            registrationNumber,
                        })}
                    </OrganizationRegistrationNumber>
                )}
            </OrganizationLabel>
            {children}
        </Container>
    )
}
