import { styled } from "@mui/material"

import { Button } from "~/components/Button"

export const IconButton = styled(Button)`
    height: 36px;
    width: 36px;
    border-radius: var(--border-radius-sm);
    border: 1px solid var(--color-border-flows);
    box-shadow: none !important;
    padding: 0 !important;
`
