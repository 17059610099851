import React from "react"
import { AlertTriangle } from "react-feather"

import { FloatInput } from "~/components/Form"
import { FieldContainer, IconContainer } from "~/features/invoice/components/InvoiceLines/StyledDiv"
import { InvoiceLineError } from "~/features/invoice/core"

interface RenderQuantityProps {
    quantity: number
    onQuantityChange: (quantity: number | null) => void
    quantityLabel: string
    error: InvoiceLineError | null
    readonly?: boolean
    fieldClassName?: string
    minwidth?: string
}

export const RenderQuantity: React.FC<RenderQuantityProps> = ({
    quantity,
    onQuantityChange,
    quantityLabel,
    error,
    readonly,
    fieldClassName,
    minwidth,
}) => (
    <FieldContainer error={error} minwidth={minwidth}>
        <FloatInput
            className={`invoice-line-field invoice-line-quantity ${fieldClassName}`}
            name="line-quantity"
            label={quantityLabel}
            placeholder={quantityLabel}
            onChange={onQuantityChange}
            value={quantity}
            required
            inputProps={{
                readOnly: readonly,
            }}
            aria-readonly={readonly}
        />
        {error ? (
            <IconContainer>
                <AlertTriangle />
            </IconContainer>
        ) : null}
    </FieldContainer>
)
