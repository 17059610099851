import { MenuItem, Select, SelectChangeEvent, Stack, Switch } from "@mui/material"
import cls from "classnames"
import React, { FC, Fragment, useState } from "react"
import { Plus, Trash2 } from "react-feather"
import { FormattedMessage, MessageDescriptor, useIntl } from "react-intl"
import { v4 as uuid } from "uuid"

import { Button } from "~/components"
import { OBJECT_TYPES } from "~/domains/orchestration/flows/const"
import { messages } from "~/domains/orchestration/flows/messages"
import {
    EventTriggerNode,
    ObjectEvent,
    ObjectType,
    Suggestion,
    isObjectEvent,
    isObjectType,
} from "~/domains/orchestration/flows/types"

import { AndCondition } from "./AndCondition"
import { ConditionBuilder } from "./ConditionBuilder"
import { ConfigurationNode } from "./ConfigurationNode"
import "./EventTriggerConfiguration.scss"

interface Props {
    selectedNode: EventTriggerNode
    unselectCallback: () => void
}

/*
This is the structure of the conditions array:
each group is an "or" condition, and each "or" condition is an array of "and" conditions
const conditionGroups = [
	[
		"first conditions", // and 
		"second condition", // and
		"third condition"
	], // or
	[
		"other first conditions", // and 
		"other second condition", // and 
		"other third condition"
	]
]

to simplify the state, we will store the conditions add id to each group
const conditionGroups = [
    {
        id: "1",
        group: [
            "first conditions", // and 
            "second condition", // and
            "third condition"
        ]
    },
]


groups, and each group as an array of conditions
*/

const suggestions: Suggestion[] = [
    { labelId: "equals", value: "=", type: "operator", position: "operator" },
    { labelId: "partnershipTypeSupplier", value: "'SUPPLIER'", type: "property", position: "rightHand" },
    { labelId: "partnershipTypeBuyer", value: "'BUYER'", type: "property", position: "rightHand" },
    { labelId: "true", value: "true", type: "value", position: "rightHand" },
    { labelId: "false", value: "false", type: "value", position: "rightHand" },
    {
        labelId: "eventTriggerNewPartnership",
        value: "{{trigger_event.partnershipType}}",
        type: "trigger",
        position: "leftHand",
    },
]

function adaptValueToText(
    value: string,
    formatMessage: (descriptor: MessageDescriptor) => string,
    suggestions: Suggestion[]
): string {
    const parts = value.split(" ")
    const translatedParts = parts.map((part) => {
        const suggestion = suggestions.find((s) => s.value === part)
        return suggestion ? formatMessage(messages.suggestions[suggestion.labelId]) : part
    })
    return translatedParts.join(" ")
}

const OBJECT_EVENTS = Object.values(ObjectEvent)

export type ConditionGroup = {
    id: string
    conditions: string[]
}

export const EventTriggerConfiguration: FC<Props> = ({ selectedNode, unselectCallback }) => {
    const { formatMessage } = useIntl()

    const initialConditionGroups: ConditionGroup[] = selectedNode.filter.conditions.map((andConditions) => ({
        id: uuid(),
        conditions: andConditions,
    }))

    const hasConditions = initialConditionGroups.length > 0
    const [enableConditions, setEnableConditions] = useState(hasConditions)

    // Handle the conditions
    const [conditions, setConditions] = useState(initialConditionGroups)

    // Handle the current node
    const [currentNode, setCurrentNode] = useState(selectedNode)

    const [showConditionBuilder, setShowConditionBuilder] = useState(false)

    const [currentGroup, setCurrentGroup] = useState<ConditionGroup | null>(null)

    const updateConditions = (conditions: ConditionGroup[]) => {
        setConditions(conditions)
        setCurrentNode({
            ...currentNode,
            filter: {
                ...currentNode.filter,
                conditions: conditions.map((g) => g.conditions),
            },
        })
    }

    const handleChangeCondition = (conditionGroup: ConditionGroup) => {
        const newConditions = conditions.map((g) =>
            g.id === conditionGroup.id
                ? {
                      id: uuid(),
                      conditions: conditionGroup.conditions,
                  }
                : g
        )

        updateConditions(newConditions)
    }

    const handleAddCondition = () => {
        const newConditions = [...conditions]
        newConditions.push({
            id: uuid(),
            conditions: [],
        })
        updateConditions(newConditions)
    }

    const handleDelete = (conditionGroup: ConditionGroup) => () => {
        const newConditions = conditions.filter((g) => g.id !== conditionGroup.id)
        updateConditions(newConditions)
    }

    const handleChangeObjectEvent = (e: SelectChangeEvent<ObjectEvent>): void => {
        const objectEvent = e.target.value
        if (!isObjectEvent(objectEvent)) return

        setCurrentNode({
            ...currentNode,
            objectEvent,
        })
    }

    const handleChangeObjectType = (e: SelectChangeEvent<ObjectType>): void => {
        const objectType = e.target.value
        if (!isObjectType(objectType)) return

        setCurrentNode({
            ...currentNode,
            objectType,
        })
    }

    const handleNewCondition = (group: ConditionGroup) => {
        setShowConditionBuilder(true)
        setCurrentGroup(group)
    }
    const handleCancelNewCondition = () => setShowConditionBuilder(false)

    const handleSaveNewCondition = (c: ConditionGroup) => {
        setShowConditionBuilder(false)
        handleChangeCondition(c)
    }

    const handleEnableConditions = () => {
        if (enableConditions) {
            // reset the conditions
            updateConditions([])
        }
        setEnableConditions(!enableConditions)
    }

    const configurationNodeItemClassName = cls("flows-configurationNode-item", "flows-editor-sideBar-item")

    if (showConditionBuilder) {
        return (
            <ConditionBuilder
                group={currentGroup}
                suggestions={suggestions}
                handleCancel={handleCancelNewCondition}
                handleSave={handleSaveNewCondition}
            />
        )
    }

    const adaptedConditions = conditions.map((g) => ({
        ...g,
        conditions: g.conditions.map((c) => adaptValueToText(c, formatMessage, suggestions)),
    }))

    return (
        <ConfigurationNode configuredNode={currentNode} unselectCallback={unselectCallback}>
            <Stack gap={1} className={configurationNodeItemClassName}>
                <FormattedMessage tagName="h5" {...messages.eventTriggerConfiguration.eventTitle} />
                <Select value={currentNode.objectType ?? ""} onChange={handleChangeObjectType}>
                    {OBJECT_TYPES.map((type) => (
                        <MenuItem key={type} value={type}>
                            {formatMessage(messages.objectType[type])}
                        </MenuItem>
                    ))}
                </Select>
                <Select
                    value={currentNode.objectEvent ?? ""}
                    onChange={handleChangeObjectEvent}
                    className="flows-configurationNode-select"
                >
                    {OBJECT_EVENTS.map((event) => (
                        <MenuItem key={event} value={event}>
                            {formatMessage(messages.objectEvent[event])}
                        </MenuItem>
                    ))}
                </Select>
            </Stack>
            <Stack gap={1} className={configurationNodeItemClassName}>
                <FormattedMessage tagName="span" {...messages.eventTriggerConfiguration.enableConditionsLabel} />
                <Switch checked={enableConditions} onChange={handleEnableConditions} />
            </Stack>
            {enableConditions && (
                <>
                    <Stack gap={1} className={configurationNodeItemClassName}>
                        <FormattedMessage tagName="h5" {...messages.eventTriggerConfiguration.conditionsTitle} />

                        {adaptedConditions.map((g, index) => (
                            <Fragment key={g.id}>
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    className="flows-eventTriggerConfiguration-orTitle"
                                >
                                    <FormattedMessage
                                        tagName="span"
                                        {...(index === 0
                                            ? messages.eventTriggerConfiguration.orConditionFirstLabel
                                            : messages.eventTriggerConfiguration.orConditionOtherLabel)}
                                    />

                                    <Trash2 size={18} color="var(--color-grey)" onClick={handleDelete(g)} />
                                </Stack>
                                <AndCondition
                                    key={g.id}
                                    group={g}
                                    handleChangeConditions={handleChangeCondition}
                                    handleNewCondition={handleNewCondition}
                                />
                            </Fragment>
                        ))}
                    </Stack>
                    <Button
                        type="primary-light"
                        onClick={handleAddCondition}
                        className="flows-eventTriggerConfiguration-newConditionGroup"
                    >
                        <Plus size={18} />
                        <FormattedMessage {...messages.eventTriggerConfiguration.newConditionGroupLabel} />
                    </Button>
                </>
            )}
        </ConfigurationNode>
    )
}
