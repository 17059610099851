import axiosClient from "~/api/axiosClient"
import { ContractCheckI } from "~/types/UnderstandingTypes"

const BASE_URL_UNDERSTANDING = import.meta.env.VITE_API_UNDERSTANDING_URL

export const understandingApi = {
    contractCheck(contractUrl: string, receivedAt: string, dueDate: string): Promise<ContractCheckI> {
        const url = BASE_URL_UNDERSTANDING + "contract/check"
        const formData = new FormData()
        formData.append("contractUrl", contractUrl)
        formData.append("dueDate", dueDate)
        formData.append("receivedAt", receivedAt)
        return axiosClient.post(url, formData)
    },
}
