import { styled } from "@mui/material"
import { FormattedMessage, defineMessages } from "react-intl"

import { PaymentMethodDetails, PaymentMethodDetailsStatus } from "~/domains/payment/payment-method-details/types"

const messages = defineMessages({
    activeLabel: {
        id: "payment.paymentMethodDetails.infoCards.activeLabel",
        defaultMessage: "Active payment method(s)",
    },
    expiredLabel: {
        id: "payment.paymentMethodDetails.infoCards.expiredLabel",
        defaultMessage: "Expired payment method(s)",
    },
    totallabel: {
        id: "payment.paymentMethodDetails.infoCards.totalLabel",
        defaultMessage: "Total payment method(s)",
    },
    typesLabel: {
        id: "payment.paymentMethodDetails.infoCards.typesLabel",
        defaultMessage: "Types of payment method(s)",
    },
})

const InfoCardsContainer = styled("div")({
    display: "flex",
    gap: "var(--spacing-md)",
    flexWrap: "wrap",
    justifyContent: "center",
})

const InfoCard = styled("div")({
    flex: 1,
    display: "flex",
    minWidth: "150px",
    flexDirection: "column",
    backgroundColor: "var(--color-white)",
    padding: "var(--spacing-sm) var(--spacing-md)",
    borderRadius: "var(--border-radius-sm)",
    fontSize: "var(--font-size-sm)",
    color: "var(--color-light-grey)",
})

const InfoCardValue = styled("div")({
    fontSize: "var(--h1-font-size)",
    lineHeight: "38px",
    color: "var(--color-grey)",
    marginTop: "auto",
})

interface InfoCardsProps {
    items: PaymentMethodDetails[]
}

export const InfoCards = ({ items }: InfoCardsProps) => {
    const totalTypes = items.reduce((acc, item) => {
        if (item.payment_method_id) {
            acc.add(item.payment_method_id)
        }
        return acc
    }, new Set()).size

    const expiredItems = items.filter((item) => item.status === PaymentMethodDetailsStatus.EXPIRED).length
    const activeItems = items.filter(({ status }) => status === PaymentMethodDetailsStatus.ACTIVE).length

    return (
        <InfoCardsContainer>
            <InfoCard>
                <FormattedMessage {...messages.totallabel} />
                <InfoCardValue>{items.length}</InfoCardValue>
            </InfoCard>
            <InfoCard>
                <FormattedMessage {...messages.activeLabel} />
                <InfoCardValue>{activeItems}</InfoCardValue>
            </InfoCard>
            <InfoCard>
                <FormattedMessage {...messages.expiredLabel} />
                <InfoCardValue>{expiredItems}</InfoCardValue>
            </InfoCard>
            <InfoCard>
                <FormattedMessage {...messages.typesLabel} />
                <InfoCardValue>{totalTypes}</InfoCardValue>
            </InfoCard>
        </InfoCardsContainer>
    )
}
