import { useCallback } from "react"
import { invoiceApi } from "~/api"
import {
    SubscriptionEventI,
    SubscriptionEventType,
    SubscriptionType,
} from "~/domains/transactions/common/subscriptions"
import { useSubscribeToUpdate } from "~/domains/transactions/common/subscriptions/hooks"
import { useAppDispatch } from "~/store/hooks"
import { InvoiceId, OrganizationId } from "~/types"
import { parseInvoice } from "~/types/InvoiceParsers"
import { invoiceActions } from "../invoiceSlice"
import { useFetchInvoiceWorkflows } from "~/store/workflow/hooks/useFetchInvoiceWorkflows"
import { useDocumentRelationsApi } from "~/domains/transactions/document-relations/documentRelationsApi"
import { isResultSuccess } from "~/core/Result"
import { documentRelationsActions } from "~/domains/transactions/document-relations/store/documentRelationsSlice"

export const useSubscribeInvoiceUpdates = (
    organizationId: OrganizationId | null | undefined,
    invoiceId: InvoiceId | undefined
) => {
    const dispatch = useAppDispatch()
    const { fetchInvoiceChecks } = useFetchInvoiceWorkflows(organizationId, invoiceId)
    const documentRelationsApi = useDocumentRelationsApi()

    const fetchInvoiceRelations = useCallback(
        async (invoiceId: string) => {
            if (organizationId) {
                const result = await documentRelationsApi.getInvoiceRelations(organizationId, invoiceId)

                if (isResultSuccess(result)) {
                    dispatch(
                        documentRelationsActions.fetchInvoiceRelationsSuccess({
                            invoiceId,
                            invoiceRelations: result.result,
                        })
                    )
                }
            }
        },
        [dispatch, organizationId, documentRelationsApi]
    )

    const doFetch = useCallback(() => {
        if (invoiceId) {
            invoiceApi.getById(invoiceId).then((invoiceData) => {
                const invoice = parseInvoice(invoiceData)
                dispatch(invoiceActions.setInvoice(invoice))
            })
        }
    }, [invoiceId, dispatch])

    const onInvoiceEvent = useCallback(
        (event: SubscriptionEventI) => {
            if (event.type === SubscriptionEventType.SELF) {
                doFetch()
            }
            if (event.type === SubscriptionEventType.WORKFLOW) {
                fetchInvoiceChecks()
            }
            if (event.type === SubscriptionEventType.DOCUMENT_RELATION) {
                fetchInvoiceRelations(event.objectId)
            }
        },
        [doFetch, fetchInvoiceChecks, fetchInvoiceRelations]
    )
    useSubscribeToUpdate(SubscriptionType.INVOICE, invoiceId, onInvoiceEvent)
}
