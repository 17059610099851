import React, { useEffect, useRef } from "react"

const PADDING = 10

export const Metrics = () => {
    const elementRef = useRef<HTMLIFrameElement>(null)

    const handleResize = () => {
        if (elementRef.current) {
            elementRef.current.style.height = `${window.innerHeight - PADDING}px`
        }
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize)
        handleResize()
        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [])

    return (
        <div>
            <iframe
                ref={elementRef}
                src="https://metabase.flowie.me/public/dashboard/3f44df2d-14b4-4d3d-b1a9-7bdb5fa59d95"
                frameBorder="0"
                width="100%"
            ></iframe>
        </div>
    )
}
