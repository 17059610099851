import { FormControl, Grid, InputLabel, Switch, TextField, Typography } from "@mui/material"
import React, { ChangeEvent, FC, useRef, useState } from "react"
import { FormattedMessage } from "react-intl"
import { generatePath } from "react-router-dom"
import { Link } from "react-router-dom"

import { Button } from "~/components"
import { LeftChevronIcon } from "~/components/Icons"
import { useEditor, useEditorDispatch } from "~/domains/orchestration/flows/context/editorContext"
import { messages } from "~/domains/orchestration/flows/messages"
import { FLOWS_LIST } from "~/domains/orchestration/flows/routes"
import { Flow } from "~/domains/orchestration/flows/types"

import "./ActionBar.scss"
import { DeleteAction } from "./DeleteAction"

interface Props {
    hasWorkflowDeletePermission: boolean
    hasWorkflowUpdatePermission: boolean
    publishFlow: (flow: Flow) => void
    flow: Flow
}

export const ActionBar: FC<Props> = ({
    flow,
    publishFlow,
    hasWorkflowUpdatePermission,
    hasWorkflowDeletePermission,
}) => {
    const dispatch = useEditorDispatch()
    const state = useEditor()

    const currentFlow = state.flow || flow

    const { isFlowPublishable, error } = state
    const initialFlow = useRef(currentFlow)

    const [enabled, setEnabled] = useState(flow.enabled)

    const updatePublishableValue = (isPublishable: boolean) => {
        dispatch({
            type: "SET_FLOW_PUBLISHABLE",
            payload: isPublishable,
        })
    }

    const updateFlow = (flow: Flow) => {
        dispatch({
            type: "SET_FLOW",
            payload: flow,
        })
    }

    const handleToggleEnabledFlow = () => {
        const toggleEnabled = !enabled
        setEnabled(toggleEnabled)

        updateFlow({ ...currentFlow, enabled: toggleEnabled })

        const isEnabledChanged = initialFlow.current.enabled !== toggleEnabled

        updatePublishableValue(isEnabledChanged)
    }

    const handleEditFlowName = (event: ChangeEvent<HTMLInputElement>) => {
        const name = event.target.value
        updateFlow({ ...currentFlow, name })

        const isNameChanged = initialFlow.current.name !== name

        updatePublishableValue(isNameChanged)
    }

    const handleDeleteFlow = () => {
        const archivedFlow = { ...currentFlow, archived: true, enabled: false }
        updateFlow(archivedFlow)
        publishFlow(archivedFlow)
    }

    const handlePublish = () => {
        updateFlow(currentFlow)
        publishFlow(currentFlow)
    }

    // TODO: Implement view change for explorer view

    // const handleViewChange = () => {
    //     // TODO: Implement view change
    //     console.log("handleViewChange")
    // }

    const disablePublish = !isFlowPublishable || error !== null

    const canEdit = hasWorkflowUpdatePermission && !flow.archived
    const canDelete = hasWorkflowDeletePermission && !flow.archived

    return (
        <Grid container className="flows-editor-actionBar" alignItems="center">
            <Grid item xs={5} display="flex" alignItems="center" gap={1}>
                <Link to={generatePath(FLOWS_LIST)} className="flows-editor-actionBar-left-backLink">
                    <LeftChevronIcon size={14} color="var(--primary-color)" strokeWidth="3" />
                </Link>
                <FormControl fullWidth>
                    <TextField
                        className="flows-editor-actionBar-left-title"
                        value={currentFlow.name}
                        disabled={!canEdit}
                        variant="standard"
                        InputProps={{
                            disableUnderline: true,
                        }}
                        onChange={handleEditFlowName}
                    />
                </FormControl>
                <Typography className="flows-editor-actionBar-left-version" variant="caption">
                    <FormattedMessage {...messages.editor.version} values={{ version: currentFlow.version }} />
                </Typography>
            </Grid>
            <Grid item xs={2} display="flex" justifyContent="center">
                {/*
                // TODO: Explorer view 

                <div className="view-switcher">
                    <Button type="tab" className={"selected"}>
                        {formatMessage(messages.page.flowsTab)}
                    </Button>
                    <Button type="tab" onClick={handleViewChange}>
                        {formatMessage(messages.page.runsTab)}
                    </Button>
                </div>
                */}
            </Grid>
            <Grid item xs={5} display="flex" justifyContent="flex-end" alignItems="center" gap={1}>
                <Switch checked={enabled} onChange={handleToggleEnabledFlow} disabled={!canEdit} />
                <InputLabel>
                    <FormattedMessage {...(currentFlow.enabled ? messages.editor.enabled : messages.editor.disabled)} />
                </InputLabel>
                <Button
                    className="flows-editor-actionBar-right-publish"
                    disabled={disablePublish}
                    onClick={handlePublish}
                >
                    <FormattedMessage {...messages.editor.publish} />
                </Button>
                <DeleteAction flow={flow} deleteCallback={handleDeleteFlow} hasFlowDeletePermission={canDelete} />
            </Grid>
        </Grid>
    )
}
