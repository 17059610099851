export type Suggestion = {
    labelId: string
    value: string
    description?: string
    enabled?: boolean
    type: string
    position: "leftHand" | "rightHand" | "operator"
}

export enum PartnershipFieldToUpdate {
    VERIFIED_STATUS = "verifiedStatus",
    PREFERRED_STATUS = "preferredStatus",
    ALLOW_TO_SEND_PO = "allowToSendPO",
    ALLOW_TO_PAY_INVOICE = "allowToPayInvoice",
    COLLABORATION_STATUS = "collaborationStatus",
}

export enum CollaborationStatus {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE",
    REFUSED = "REFUSED",
    IN_REVIEW = "IN_REVIEW",
    CLOTURED = "CLOTURED",
    EXCLUDED = "EXCLUDED",
}
