import React, { useState } from "react"
import { useIntl } from "react-intl"

import { DatatableFilters } from "~/components/DataTable/components/DatatableFilters"
import { messages } from "~/domains/orchestration/flows/messages"
import { CreateFlowQuery } from "~/domains/orchestration/flows/types"

import { ModalCreateFlow } from "./ModalCreateFlow"

interface Props {
    hasCreatePermission: boolean
    filter: string
    createCallback: (p: CreateFlowQuery) => void
    filterChangeCallback: (filter: string) => void
}

export const Filter: React.FC<Props> = ({ createCallback, filterChangeCallback, filter, hasCreatePermission }) => {
    const { formatMessage } = useIntl()

    const [showCreateFlowModal, setShowCreateFlowModal] = useState(false)

    const displayCreateFlowModal = () => setShowCreateFlowModal(true)
    const hideCreateFlowModal = () => setShowCreateFlowModal(false)

    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        filterChangeCallback(event.target.value)
    }

    const createFlowProps = hasCreatePermission
        ? {
              onClickButton: displayCreateFlowModal,
              labelButton: formatMessage(messages.filter.newFlow),
          }
        : {}

    return (
        <>
            <DatatableFilters {...createFlowProps} onChangeFilter={handleFilterChange} valueFilter={filter} />
            <ModalCreateFlow
                visible={showCreateFlowModal}
                close={hideCreateFlowModal}
                createFlowCallback={createCallback}
            />
        </>
    )
}
