import { FC } from "react"
import { defineMessages, useIntl } from "react-intl"

import { Card } from "~/components"
import { ItemLabel } from "~/components/ItemLabel/ItemLabel"
import { InvoiceI } from "~/types"

const messages = defineMessages({
    description: {
        id: "purchase.requests.request.description",
        defaultMessage: "Description",
    },
})

interface InvoiceDescriptionProps {
    invoice: InvoiceI
}

export const InvoiceDescription: FC<InvoiceDescriptionProps> = ({ invoice }) => {
    const { formatMessage } = useIntl()

    return (
        <Card title={formatMessage(messages.description)} expandable>
            {invoice.description ? <p>{invoice.description}</p> : <ItemLabel>No description</ItemLabel>}
        </Card>
    )
}
