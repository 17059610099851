import { Chip } from "@mui/material"
import { styled } from "@mui/system"
import * as Sentry from "@sentry/browser"
import { ReactFlowProvider } from "@xyflow/react"
import React, { FC, memo, useCallback, useEffect, useRef, useState } from "react"
import { useIntl } from "react-intl"
import { generatePath, useLocation, useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"

import { Loader, useTitle } from "~/core"
import { useGetFlowQuery, useUpdateFlowMutation } from "~/domains/orchestration/flows/api/flowsApi"
import { ActionBar } from "~/domains/orchestration/flows/components/ActionBar"
import { useEditorDispatch } from "~/domains/orchestration/flows/context/editorContext"
import { bumpFlow } from "~/domains/orchestration/flows/core"
import { useOrganizationId, usePermission } from "~/domains/orchestration/flows/hooks/"
import { messages } from "~/domains/orchestration/flows/messages"
import { FLOWS_LIST } from "~/domains/orchestration/flows/routes"
import { type Flow, type FlowId } from "~/domains/orchestration/flows/types"

import { EditorPanel } from "../components/EditorPanel"

const StyledLoader = styled("div")({
    height: "calc(100vh - 24px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
})

// TODO: improve this component
const StyleChipContainer = styled("div")({
    width: "100%",
    display: "flex",
    marrginTop: "var(--spacing-sx)",
    justifyContent: "center",
    padding: "var(--spacing-sx)",
})

const MemoizedActionBar = memo(ActionBar)

export const Editor: FC = () => {
    const { formatMessage } = useIntl()
    const location = useLocation()
    const navigate = useNavigate()
    const { flowId = "" as FlowId } = useParams<{ flowId: FlowId }>()

    const organizationId = useOrganizationId()
    const dispatch = useEditorDispatch()

    const organizationIdRef = useRef(organizationId)

    const { state } = location
    const { version = 0 } = state || {}

    const [flowVersion, setFlowVersion] = useState(version)

    const pageName = formatMessage(messages.editor.title)
    useTitle(pageName)

    const {
        data: flow,
        isError,
        isLoading,
        error,
    } = useGetFlowQuery(
        { flowId, params: { version: flowVersion } },
        {
            refetchOnMountOrArgChange: true,
        }
    )
    const [updateFlow] = useUpdateFlowMutation()
    const { hasWorkflowDeletePermission, hasWorkflowUpdatePermission, permissionError } = usePermission()

    useEffect(() => {
        if (!organizationIdRef.current) {
            organizationIdRef.current = organizationId
        }

        if (organizationIdRef.current !== organizationId) {
            navigate(generatePath(FLOWS_LIST))
        }
    }, [organizationId])

    const handlePublish = useCallback(
        async (flow: Flow) => {
            try {
                const bumpedFlow = bumpFlow(flow)

                await updateFlow({ flowId: flow.id, body: bumpedFlow }).unwrap()

                dispatch({ type: "SET_FLOW", payload: bumpedFlow })
                setFlowVersion(bumpedFlow.version)

                toast.success(formatMessage(messages.editor.flowUpdated))
                dispatch({
                    type: "SET_FLOW_PUBLISHABLE",
                    payload: false,
                })

                // navigate to the same page with the new version
                // if the user refreshes the page, the new version will be loaded

                if (bumpedFlow.archived) {
                    navigate(generatePath(FLOWS_LIST))
                } else {
                    navigate(".", { state: { version: bumpedFlow.version } })
                }
            } catch (error) {
                Sentry.captureException(error)
                toast.error(formatMessage(messages.error.updatingFlow))
            }
        },
        [updateFlow, dispatch]
    )

    if (isLoading) {
        return (
            <StyledLoader>
                <Loader />
            </StyledLoader>
        )
    }

    if (isError) {
        toast.error(formatMessage(messages.error.loadingFlow))
        Sentry.captureException(error)
        return null
    }

    if (permissionError) {
        toast.error(formatMessage(messages.error.permission))
        Sentry.captureException("Flow editor permission error")
        return null
    }

    if (!flow) {
        return null
    }

    return (
        <div className="flows-editor">
            <MemoizedActionBar
                key={flow.version}
                flow={flow}
                publishFlow={handlePublish}
                hasWorkflowDeletePermission={hasWorkflowDeletePermission}
                hasWorkflowUpdatePermission={hasWorkflowUpdatePermission}
            />

            {flow.archived ? (
                <StyleChipContainer>
                    <Chip label={formatMessage(messages.editor.archived)} variant="outlined" />
                </StyleChipContainer>
            ) : null}

            <ReactFlowProvider>
                <EditorPanel
                    flow={flow}
                    hasWorkflowUpdatePermission={hasWorkflowUpdatePermission}
                    handlePublish={handlePublish}
                />
            </ReactFlowProvider>
        </div>
    )
}
