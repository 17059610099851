import React from "react"
import { Route } from "react-router-dom"
import { METRICS_ROUTE } from "./routes"
import { Metrics } from "./pages/Metrics"

export default {
    routes: [
        <Route key="invoice_route">
            <Route path={METRICS_ROUTE} element={<Metrics />} />
        </Route>,
    ],
}
