import {
    Catalog,
    CatalogProduct,
    CatalogProductPrice,
    CatalogProductVariant,
} from "~/domains/identity/features/catalog/types/Catalog"

export const catalogFromApiAdapters = (catalog: Catalog) => {
    return {
        id: catalog.id,
        organizationId: catalog.organizationId,
        supplierId: catalog.supplierId,
        name: catalog.name,
        nameTranslations: catalog.nameTranslations,
        description: catalog.description,
        descriptionTranslations: catalog.descriptionTranslations,
        catalogType: catalog.catalogType,
        isActive: catalog.isActive,
        accessType: catalog.accessType,
        sharedWith: catalog.sharedWith,
        createdAt: catalog.createdAt,
        updatedAt: catalog.updatedAt,
    }
}

export const productFromApiAdapters = (product: CatalogProduct) => {
    return {
        id: product.id,
        defaultVariantId: product.defaultVariantId,
        organizationId: product.organizationId,
        sku: product.sku,
        label: product.label,
        labelTranslations: product.labelTranslations,
        description: product.description,
        descriptionTranslations: product.descriptionTranslations,
        status: product.status,
        unitQuantity: product.unitQuantity,
        brand: product.brand,
        defaultPriceId: product.defaultPriceId,
        createdAt: product.createdAt,
        updatedAt: product.updatedAt,
    }
}

export const productVariantFromApiAdapters = (productVariant: CatalogProductVariant) => {
    return {
        id: productVariant.id,
        productId: productVariant.productId,
        sku: productVariant.sku,
        label: productVariant.label,
        labelTranslations: productVariant.labelTranslations,
        description: productVariant.description,
        descriptionTranslations: productVariant.descriptionTranslations,
        defaultPriceId: productVariant.defaultPriceId,
        features: productVariant.features,
        createdAt: productVariant.createdAt,
        updatedAt: productVariant.updatedAt,
    }
}

export const priceFromApiAdapters = (price: CatalogProductPrice) => {
    return {
        id: price.id,
        variantId: price.variantId,
        amount: price.amount,
        currency: price.currency,
        countryCode: price.countryCode,
        minOrderThreshold: price.minOrderThreshold,
        maxOrderThreshold: price.maxOrderThreshold,
        incotermType: price.incotermType,
        effectiveDate: price.effectiveDate,
        expirationDate: price.expirationDate,
        eligibleOrganizations: price.eligibleOrganizations,
        createdAt: price.createdAt,
        updatedAt: price.updatedAt,
    }
}
