import { Box } from "@mui/material"
import React from "react"
import { DollarSign } from "react-feather"
import { defineMessages, useIntl } from "react-intl"

import { Button, Card } from "~/components"
import { TransactionBudgetGauge } from "~/domains/transactions/components/TransactionInBudgets/TransactionBudgetGauge"
import { InvoiceI, ReceivedDocumentI } from "~/types"

import { PurchaseOrders } from "../../purchase-orders/types/PurchaseOrders"
import { PurchaseRequestDetails } from "../../purchase-requests/types/PurchaseRequests"
import { BudgetLink } from "../BudgetLink/BudgetLink"

const messages = defineMessages({
    budgets: { id: "purchase.orders.order.budgets", defaultMessage: "Budgets" },
    addToBudget: {
        id: "transactions.in.addToBudget",
        defaultMessage: "Add to budget",
    },
})

type TransactionInBudgetsProps =
    | {
          transaction: ReceivedDocumentI | InvoiceI
          showModalAddToBudget: () => void
          noCard?: boolean
          withGauge?: false
      }
    | {
          transaction: PurchaseOrders | PurchaseRequestDetails
          showModalAddToBudget: () => void
          noCard?: boolean
          withGauge?: boolean
      }

export const TransactionInBudgets: React.FC<TransactionInBudgetsProps> = ({
    transaction,
    showModalAddToBudget,
    noCard = false,
    withGauge = false,
}) => {
    const { formatMessage } = useIntl()

    const Container = noCard ? Box : Card

    return (
        <Container title={formatMessage(messages.budgets)} expandable>
            {transaction.budgets && transaction.budgets.length ? (
                <>
                    <BudgetLink budgets={transaction.budgets} handleEdit={showModalAddToBudget} />
                    {withGauge && (
                        <TransactionBudgetGauge transaction={transaction as PurchaseOrders | PurchaseRequestDetails} />
                    )}
                </>
            ) : (
                <Box>
                    <Button type="tertiary" onClick={showModalAddToBudget}>
                        <DollarSign size={16} />
                        {formatMessage(messages.addToBudget)}
                    </Button>
                </Box>
            )}
        </Container>
    )
}
