import * as Sentry from "@sentry/react"
import { useCallback } from "react"

import { useCustomFieldsApi } from "~/domains/transactions/custom-fields/customFieldsApi"
import { CustomFieldValueI } from "~/domains/transactions/custom-fields/types/CustomFieldValue"
import { useAppDispatch } from "~/store/hooks"
import { invoiceActions } from "~/store/invoice/invoiceSlice"

export const useUpdateInvoiceLineCustomField = (customField: CustomFieldValueI | null) => {
    const customFieldsApi = useCustomFieldsApi()
    const dispatch = useAppDispatch()

    return useCallback(
        async (lineId: string, customFieldId: string | undefined, value: string) => {
            try {
                if (!customField || !customFieldId) return
                await customFieldsApi.updateCustomFieldValue(customFieldId, value)
                dispatch(invoiceActions.setOneInvoiceLineCustomFields({ lineId, key: customFieldId, customField }))
            } catch (error) {
                Sentry.captureException(error, {
                    extra: { customField },
                })
            }
        },
        [dispatch, customFieldsApi, customField]
    )
}
