import { CreateInvoiceResponseI } from "~/types"
import { Opaque } from "~/utils"

export enum WebsocketEventType {
    InvoiceImport = "import-invoice",
    InvoiceRetrieve = "InvoiceRetrieve",
    InvoiceFileStored = "InvoiceFileStored",
    InvoicePartialImageStored = "InvoicePartialImageStored",
    InvoiceProcessed = "InvoiceProcessed",
    InvoiceRawDocumentUrl = "InvoiceRawDocumentUrl",
    InvoiceImagesStored = "InvoiceImagesStored",
    InvoicePaginationResult = "InvoicePaginationResult",
    InvoicePartialUpdate = "InvoicePartialUpdate",
    InvoiceErrorProcessing = "InvoiceErrorProcessing",
    InvoiceV2CreationSuccess = "InvoiceV2CreationSuccess",
    InvoiceV2CreationFailed = "InvoiceV2CreationFailed",
}

export type WebSocketInvoiceFileEvent = Opaque<string, { readonly T: unique symbol }>
export type WebSocketInvoiceImagesStoredEvent = string[]
export type WebSocketInvoiceRawDocumentEvent = unknown
export type WebsocketInvoiceProcessedEvent = CreateInvoiceResponseI
