import { CurrencyCode } from "~/domains/identity/features/catalog/types"
import {
    DocumentRelationDifferencesI,
    DocumentRelationKpiAmount,
    DocumentRelationKpiAmounts,
} from "~/domains/transactions/document-relations/types/DocumentRelation"
import { InvoiceAPI, InvoiceLineItemAPI, InvoiceParty } from "~/domains/transactions/invoices-v1/types/Invoice"
import { LineStatus } from "~/domains/transactions/purchase-requests/types/PurchaseRequests"
import {
    CountryCode,
    CurrencyCodes,
    ImportInvoiceCompanyInfoI,
    InvoiceI,
    InvoiceLineI,
    InvoicePaymentDetailsI,
} from "~/types"

const mockOrganization = {
    countryCode: CountryCode.FR,
    organizationId: "",
    name: "",
    contactName: "",
}

export const invoiceFromApiAdapter = (invoice: InvoiceAPI): InvoiceI => {
    const payer = invoice.parties?.[invoice.payerId]
    const supplier = invoice.parties?.[invoice.sellerId]

    return {
        version: "V1",
        invoiceId: invoice.id,
        id: invoice.id,

        number: invoice.number,
        reference: invoice.number,

        issuedAt: invoice.issuedAt,
        issueDate: invoice.issuedAt,
        dueDate: invoice.dueDate,

        notes: invoice.notes || "",
        description: invoice.notes || "",

        status: "DRAFT" as any,

        parties: invoice.parties,

        payerId: invoice.payerId,
        buyer: organizationFromApiAdapter(payer),

        sellerId: invoice.sellerId,
        supplier: organizationFromApiAdapter(supplier),

        lines: (invoice.lineItems || []).map(lineItemFromApiAdapter),
        lineItems: (invoice.lineItems || []).map(lineItemFromApiAdapter),

        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        totalAmountDue: invoice.totalAmountDue as any,
        total: Object.values(invoice.totalAmountDue).reduce((sum, amount) => sum + +amount, 0),

        tax: invoice.tax,
        totalPriceWithoutTax: invoice.totalPriceWithoutTax,
        totalExcludedTaxes: Object.values(invoice.totalPriceWithoutTax).reduce((sum, amount) => sum + +amount, 0),

        buyerTaxes: [],
        supplierTaxes: [],

        paidAt: "0",
        fileLinks: invoice.fileLinks || {
            pdfLink: "",
            bboxLink: "",
            imageLinks: [],
            originalImageLinks: [],
        },

        involvedPeople: (payer?.contacts || []).map((contact) => ({ userId: contact })),
        supplierInvolvedPeople: (supplier?.contacts || []).map((contact) => ({ userId: contact })),

        signed: true,
        totalDiscount: 0,
        paymentInstructions: invoice.paymentInstructions,
        paymentDetails: { iban: invoice.paymentInstructions || "" } as InvoicePaymentDetailsI,
    }
}

export const lineItemFromApiAdapter = (lineItem: Partial<InvoiceLineItemAPI>, i: number): InvoiceLineI => {
    const lineStatuses = Object.values(LineStatus)
    const randomApprovalStatus = () => lineStatuses[Math.floor(Math.random() * lineStatuses.length)]

    return {
        ...lineItem,
        id: lineItem.id || "",
        description: lineItem.description || "",
        productReference: lineItem.productReference || `REF-00${i}`,
        currency: lineItem.currency || CurrencyCodes.EUR,
        quantity: lineItem.quantity || 0,
        unitPrice: +(lineItem.unitPrice || 0),
        approvalStatus: randomApprovalStatus(),
        linePosition: i,
        priceWithoutTax: lineItem.priceWithoutTax || "0",
        totalExcludedTaxes: +(lineItem.priceWithoutTax || "0") * (lineItem.quantity || 1),
        total: +(lineItem.priceWithoutTax || "0") * (lineItem.quantity || 1),
        taxId: lineItem.taxId || "",
        tax: lineItem.tax || "0",
        totalTax: 0,
        unitPriceWithoutTax: lineItem.unitPriceWithoutTax || 0,
    }
}

export const organizationFromApiAdapter = (organization?: InvoiceParty): ImportInvoiceCompanyInfoI => {
    return !organization
        ? mockOrganization
        : {
              countryCode: CountryCode.FR,
              organizationId: organization.organizationId,
              name: organization.organizationId,
              contactName: organization.organizationId,
          }
}

export const documentsDifferencesFromApiAdapter = (
    documentsDifferences: DocumentRelationDifferencesI
): DocumentRelationDifferencesI => {
    return {
        relations: documentsDifferences.relations.map((relation) => ({
            relationId: relation.relationId,
            invoiceId: relation.invoiceId,
            purchaseOrderId: relation.purchaseOrderId,
            purchaseOrderKpis: documentsDifferencesKPIsFromApiAdapter(relation.purchaseOrderKpis),
            invoiceKpis: documentsDifferencesKPIsFromApiAdapter(relation.invoiceKpis),
        })),
    }
}

export const documentsDifferencesKPIFromApiAdapter = (kpi: DocumentRelationKpiAmount): DocumentRelationKpiAmount => {
    return {
        amount: kpi.amount,
        filled: kpi.filled,
        remaining: kpi.remaining,
        fillRate: kpi.fillRate,
    }
}
export const documentsDifferencesKPIsFromApiAdapter = (
    kpis: DocumentRelationKpiAmounts
): DocumentRelationKpiAmounts => {
    return {
        totalAmount: Object.entries(kpis.totalAmount).reduce((acc, [currency, kpi]) => {
            acc[currency] = documentsDifferencesKPIFromApiAdapter(kpi)
            return acc
        }, {} as Record<CurrencyCode, DocumentRelationKpiAmount>),
        amountExcludingTax: Object.entries(kpis.amountExcludingTax).reduce((acc, [currency, kpi]) => {
            acc[currency] = documentsDifferencesKPIFromApiAdapter(kpi)
            return acc
        }, {} as Record<CurrencyCode, DocumentRelationKpiAmount>),
        tax: Object.entries(kpis.tax).reduce((acc, [currency, kpi]) => {
            acc[currency] = documentsDifferencesKPIFromApiAdapter(kpi)
            return acc
        }, {} as Record<CurrencyCode, DocumentRelationKpiAmount>),
    }
}
