import { FormControl, MenuItem, Select, SelectChangeEvent, SxProps, Theme } from "@mui/material"
import React, { useCallback } from "react"
import { FormattedMessage } from "react-intl"

import { InputLabel } from "~/components/Form"
import { CrossIcon } from "~/components/Icons"
import { Chip } from "~/domains/common/components/Chip"
import {
    chipStyles,
    companyStatusMessages,
} from "~/features/organization/components/CompanyAutocomplete/CompanyAutocompleteField"
import { EstablishmentI } from "~/types"

import "./EstablishmentSelect.scss"

interface Props {
    options: EstablishmentI[]
    value: EstablishmentI | undefined
    onChange: (value: EstablishmentI | undefined) => void
    className?: string
    disabled?: boolean
    sx?: SxProps<Theme>
}

export const EstablishmentSelect: React.FC<Props> = ({ options, value, onChange, className, disabled, sx }) => {
    const onValueChange = useCallback(
        (event: SelectChangeEvent) => {
            const selectedOption = options.find((option) => option.id === event.target.value)
            onChange(selectedOption)
        },
        [options, onChange]
    )

    const clearSelection = useCallback(() => {
        onChange(undefined)
    }, [onChange])

    return (
        <FormControl fullWidth className={`establishment-selector ${className ?? ""}`} required sx={sx}>
            <InputLabel className="establishment-selector-label">
                <FormattedMessage id="supplier.buyerInfo.siret" defaultMessage="SIRET" />
            </InputLabel>
            <Select
                className="mb-12"
                value={value ? value.id : ""}
                endAdornment={
                    value ? (
                        <CrossIcon
                            size={20}
                            onClick={clearSelection}
                            color="var(--color-light-grey)"
                            className="establishment-selector-clearer"
                        />
                    ) : undefined
                }
                label={<FormattedMessage id="supplier.buyerInfo.siret" defaultMessage="SIRET" />}
                onChange={onValueChange}
                disabled={disabled}
            >
                {options.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                        {option.id} ({option.city})
                        {!option.active && (
                            <Chip size="small" variant="error" sx={chipStyles}>
                                <FormattedMessage {...companyStatusMessages.closed} />
                            </Chip>
                        )}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
