import { Grid, Tooltip } from "@mui/material"
import { Dispatch, SetStateAction } from "react"
import { Check, Download, Edit, Trash2, X } from "react-feather"
import { defineMessages, useIntl } from "react-intl"
import { generatePath, useNavigate } from "react-router-dom"

import { InvoiceEvents } from "~/components/InvoiceEvents"
import { Button } from "~/core"
import { DomainName, ScopeName } from "~/domains/identity/features/roles-permissions/types/RolesPermissions"
import { useHasPermissions } from "~/domains/identity/store/hooks/useHasPermissions"
import { INVOICE_EDIT_ROUTE } from "~/domains/transactions/invoices-v1/routes"
import { EDIT_INVOICE_BUYER_ROUTE } from "~/features/buyer/routes"
import { AuthorizationNameExtra, EventI, InvoiceI, InvoiceStatus, OrganizationId } from "~/types"

const messages = defineMessages({
    request: { id: "buyer.invoice.actions.button.request", defaultMessage: "Request update" },
    import: { id: "buyer.invoice.actions.button.import", defaultMessage: "Import document" },
    validate: { id: "buyer.invoice.actions.button.validate", defaultMessage: "Validate document" },
    approve: { id: "buyer.invoice.actions.button.approve", defaultMessage: "Approve" },
    refuse: { id: "buyer.invoice.actions.button.refuse", defaultMessage: "Refuse" },
    removeApproval: { id: "buyer.invoice.actions.button.removeApproval", defaultMessage: "Remove approval" },
    schedule: { id: "buyer.invoice.actions.button.schedule", defaultMessage: "Schedule payment" },
    paymentDone: { id: "buyer.invoice.actions.button.paymentDone", defaultMessage: "Payment's done" },
    requestButtonTooltip: {
        id: "buyer.invoice.actions.button.request.tooltip",
        defaultMessage: "You need more information about this invoice? Send a request to your supplier!",
    },
    downloadButtonTooltip: { id: "invoice.actions.button.download.tooltip", defaultMessage: "Download this invoice" },
    cannotDownloadFile: {
        id: "invoice.actions.button.cannotDownloadFile",
        defaultMessage: "This invoice has no PDF link",
    },
    validateButtonTooltip: {
        id: "buyer.invoice.actions.button.validate.tooltip",
        defaultMessage: "Are all the details on this invoice correct? Please validate it!",
    },
    refuseButtonTooltip: {
        id: "buyer.invoice.actions.button.refuse.tooltip",
        defaultMessage: "The invoice does not correspond to what you expected? Refuse it.",
    },
    importButtonTooltip: {
        id: "buyer.invoice.actions.button.import.tooltip",
        defaultMessage: "Confirm the import of this invoice !",
    },
    moveToTrash: { id: "invoice.ocrInvoice.moveToTrash", defaultMessage: "Move to trash" },
    unmarkAsPaid: { id: "invoice.ocrInvoice.unmarkAsPaid", defaultMessage: "Unmark as paid" },
    edit: { id: "invoice.actions.button.edit", defaultMessage: "Edit" },
})

interface ActionsProps {
    organizationId?: OrganizationId
    invoice?: InvoiceI
    status: InvoiceStatus
    handleDownloadPDF: () => void
    handleValidate: () => void
    setDisplayModal: Dispatch<SetStateAction<boolean>>
    handleMoveToTrash?: () => void
    handleUnmarkAsPaid?: () => void
    handleRemoveApproval: () => void
    className?: string
    events?: EventI[]
    isReviewable?: boolean
}

interface ActionWithoutApproveProps {
    handleApprove?: undefined
    handleReject?: undefined
}

interface ActionApproveProps {
    handleApprove: () => void
    handleReject: () => void
}

type Props = ActionsProps & (ActionWithoutApproveProps | ActionApproveProps)

export function Actions({
    organizationId,
    invoice,
    handleDownloadPDF,
    handleValidate,
    handleApprove,
    handleReject,
    handleMoveToTrash,
    handleRemoveApproval,
    handleUnmarkAsPaid,
    setDisplayModal,
    className = "",
    events,
    isReviewable = false,
}: Props) {
    const { formatMessage } = useIntl()
    const navigate = useNavigate()
    /* const { status: pennylaneStatus } = useInvoicePennylaneSynchronizationStatus(invoice) */
    const { hasPermission: hasInvoicesApprovePermission } = useHasPermissions(
        organizationId ?? "",
        AuthorizationNameExtra.APPROVE,
        DomainName.TRANSACTION,
        ScopeName.INVOICES
    )

    const status = invoice?.status
    const isInvoiceConfirmed = status === InvoiceStatus.CONFIRMED
    const isInvoiceInfoRequested = status === InvoiceStatus.INFO_REQUESTED
    const isInvoiceDraft = status === InvoiceStatus.DRAFT
    /* const isPennylaneSyncFailure = pennylaneStatus?.pennylaneSyncOutcome === PennylaneSyncOutcome.FAILURE */

    const handleEdit = () => {
        if (!invoice) return

        if (invoice.version === "V0") {
            navigate(generatePath(EDIT_INVOICE_BUYER_ROUTE, { invoiceId: invoice.id }))
        } else {
            navigate(generatePath(INVOICE_EDIT_ROUTE, { invoiceId: invoice.id }))
        }
    }

    return (
        <Grid container gap={1} className={className}>
            {/* TMP Removed */}
            {/* {isPennylaneSyncFailure && isInvoiceConfirmed && ( */}
            <Tooltip title={formatMessage(messages.edit)} placement="bottom" arrow>
                <div>
                    <Button onClick={handleEdit} type="neutral">
                        <Edit size={19} />
                    </Button>
                </div>
            </Tooltip>
            {/* )} */}
            {hasInvoicesApprovePermission && status === InvoiceStatus.VALIDATED && (
                <Tooltip title={formatMessage(messages.removeApproval)} placement="bottom" arrow>
                    <div>
                        <Button
                            onClick={handleRemoveApproval}
                            text={formatMessage(messages.removeApproval)}
                            type="neutral"
                        />
                    </div>
                </Tooltip>
            )}
            {isInvoiceDraft || isInvoiceConfirmed || isInvoiceInfoRequested ? (
                <Tooltip title={formatMessage(messages.moveToTrash)} placement="bottom" arrow>
                    <div>
                        <Button onClick={handleMoveToTrash} type="error-light">
                            <Trash2 size={19} />
                        </Button>
                    </div>
                </Tooltip>
            ) : null}
            {isInvoiceConfirmed || isInvoiceInfoRequested ? (
                <Tooltip title={formatMessage(messages.requestButtonTooltip)} arrow>
                    <div>
                        <Button
                            onClick={() => setDisplayModal(true)}
                            text={formatMessage(messages.request)}
                            type="secondary"
                        />
                    </div>
                </Tooltip>
            ) : null}
            {status === InvoiceStatus.MARKED_AS_PAID ? (
                <div>
                    <Button onClick={handleUnmarkAsPaid} text={formatMessage(messages.unmarkAsPaid)} type="secondary" />
                </div>
            ) : null}
            <Tooltip
                title={formatMessage(
                    messages[invoice?.fileLinks.pdfLink ? "downloadButtonTooltip" : "cannotDownloadFile"]
                )}
                arrow
            >
                <div>
                    <Button onClick={handleDownloadPDF} type="tertiary" disabled={!invoice?.fileLinks.pdfLink}>
                        <Download size={19} />
                    </Button>
                </div>
            </Tooltip>
            {isInvoiceConfirmed || isInvoiceInfoRequested ? (
                isReviewable ? (
                    <>
                        <Tooltip title={formatMessage(messages.validateButtonTooltip)} arrow>
                            <div>
                                <Button onClick={handleApprove}>
                                    {formatMessage(messages.approve)} <Check size={19} />
                                </Button>
                            </div>
                        </Tooltip>
                        <Tooltip title={formatMessage(messages.refuseButtonTooltip)} arrow>
                            <div>
                                <Button onClick={handleReject}>
                                    {formatMessage(messages.refuse)} <X size={19} />
                                </Button>
                            </div>
                        </Tooltip>
                    </>
                ) : hasInvoicesApprovePermission ? (
                    <Tooltip title={formatMessage(messages.validateButtonTooltip)} arrow>
                        <div>
                            <Button onClick={handleValidate}>
                                {formatMessage(messages.approve)} <Check size={19} />
                            </Button>
                        </div>
                    </Tooltip>
                ) : null
            ) : null}
            {isInvoiceDraft ? (
                <Tooltip title={formatMessage(messages.importButtonTooltip)} arrow>
                    <div>
                        <Button onClick={handleValidate}>
                            {formatMessage(messages.import)} <Check size={19} />
                        </Button>
                    </div>
                </Tooltip>
            ) : null}
            {events && <InvoiceEvents events={events} />}
        </Grid>
    )
}
