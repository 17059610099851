import { MessageDescriptor, defineMessages } from "react-intl"

import {
    CollaborationStatus,
    FlowTrigger,
    ObjectEvent,
    ObjectType,
    PartnershipFieldToUpdate,
    TransactionType,
} from "~/domains/orchestration/flows/types"

const page = defineMessages({
    title: {
        id: "flows.title",
        defaultMessage: "Flows (beta)",
    },
    flowsTab: {
        id: "flows.flowTab",
        defaultMessage: "Flows",
    },
    runsTab: {
        id: "flows.runsTab",
        defaultMessage: "Runs",
    },
    noOrganization: {
        id: "flows.noOrganization",
        defaultMessage: "No organization",
    },
})

const runsTable = defineMessages({
    id: {
        id: "runs.table.id",
        defaultMessage: "Id",
    },
    status: {
        id: "runs.table.status",
        defaultMessage: "Status",
    },
    startedAt: {
        id: "runs.table.startedAt",
        defaultMessage: "Started at",
    },
    finishedAt: {
        id: "runs.table.finishedAt",
        defaultMessage: "Finished at",
    },
})

const runInformation = defineMessages({
    id: {
        id: "runs.information.id",
        defaultMessage: "Id",
    },
    flowId: {
        id: "runs.information.flowId",
        defaultMessage: "Flow Id",
    },
    flowVersion: {
        id: "runs.information.flowVersion",
        defaultMessage: "Flow Version",
    },
    status: {
        id: "runs.information.status",
        defaultMessage: "Status",
    },
    state: {
        id: "runs.information.state",
        defaultMessage: "State",
    },
    error: {
        id: "runs.information.error",
        defaultMessage: "Error",
    },
    startedAt: {
        id: "runs.information.startedAt",
        defaultMessage: "Started at",
    },
    finishedAt: {
        id: "runs.information.finishedAt",
        defaultMessage: "Finished at",
    },
})

const flowTrigger: Record<FlowTrigger, MessageDescriptor> = defineMessages({
    [FlowTrigger.INVOICE]: {
        id: "flows.modalCreateFlow.trigger.INVOICE",
        defaultMessage: "Invoice",
    },
    [FlowTrigger.PURCHASE_REQUEST]: {
        id: "flows.modalCreateFlow.trigger.PURCHASE_REQUEST",
        defaultMessage: "Purchase Request",
    },
})

const filter = defineMessages({
    newFlow: {
        id: "flows.filters.newFlowButtonlabel",
        defaultMessage: "New flow +",
    },
})

const modalCreate = defineMessages({
    title: { id: "flow.modalCreateFlow.title", defaultMessage: "Create a new flow" },
    flowName: { id: "flow.modalCreateFlow.flowName", defaultMessage: "Name of your flow" },
    flowTrigger: { id: "flow.modalCreateFlow.flowTrigger", defaultMessage: "Trigger the flow" },
})

const editor = defineMessages({
    title: {
        id: "flows.editor.title",
        defaultMessage: "Flows",
    },
    flowUpdated: {
        id: "flows.editor.flowUpdated",
        defaultMessage: "Flow updated",
    },
    enabled: {
        id: "flows.editor.enabled",
        defaultMessage: "Active",
    },
    disabled: {
        id: "flows.editor.disabled",
        defaultMessage: "Inactive",
    },
    archived: {
        id: "flows.editor.archived",
        defaultMessage: "Flow has been archived",
    },
    publish: {
        id: "flows.editor.publish",
        defaultMessage: "publish",
    },
    editButtonTooltip: {
        id: "flows.editor.editButtonTooltip",
        defaultMessage: "Edit flow's name",
    },
    confirmArchivedTitle: {
        id: "flows.editor.confirmArchivedTitle",
        defaultMessage: "Archive a flow",
    },
    confirmArchivedMessage: {
        id: "flows.editor.confirmArchivedMessage",
        defaultMessage: "Are you sure to archive {name} flow? ",
    },
    version: {
        id: "flows.editor.version",
        defaultMessage: "v{version} (latest)",
    },
})

const eventTriggerNode = defineMessages({
    nodeTitle: {
        id: "flows.eventTriggerNode.title",
        defaultMessage: "Event Trigger",
    },
    noTriggerError: {
        id: "flows.eventTriggerNode.noTriggerError",
        defaultMessage: "Missing trigger conditions",
    },
    sideBarLabel: {
        id: "flows.eventTriggerNode.sideBarLabel",
        defaultMessage: "Event Trigger",
    },
    headerInformation: {
        id: "flows.eventTriggerNode.headerInformation",
        defaultMessage: "Run when {objectType} is {objectEvent}",
    },
})

const objectEvent: Record<ObjectEvent, MessageDescriptor> = defineMessages({
    [ObjectEvent.CREATED]: {
        id: "flows.objectEvent.created",
        defaultMessage: "Created",
    },
    [ObjectEvent.UPDATED]: {
        id: "flows.objectEvent.updated",
        defaultMessage: "Updated",
    },
})

const objectType: Record<ObjectType, MessageDescriptor> = defineMessages({
    [ObjectType.INVOICE]: {
        id: "flows.objectType.INVOICE",
        defaultMessage: "Invoice",
    },
    [ObjectType.PURCHASE_REQUEST]: {
        id: "flows.objectType.PURCHASE_REQUEST",
        defaultMessage: "Purchase Request",
    },
    [ObjectType.PURCHASE_ORDER]: {
        id: "flows.objectType.PURCHASE_ORDER",
        defaultMessage: "Purchase Order",
    },
    [ObjectType.CONTACT]: {
        id: "flows.objectType.CONTACT",
        defaultMessage: "Contact",
    },
    [ObjectType.PAYMENT]: {
        id: "flows.objectType.PAYMENT",
        defaultMessage: "Payment",
    },
    [ObjectType.ORGANIZATION_RELATIONSHIP]: {
        id: "flows.objectType.ORGANIZATION_RELATIONSHIP",
        defaultMessage: "Partnership",
    },
})

const transactionType: Record<TransactionType, MessageDescriptor> = defineMessages({
    [TransactionType.PURCHASE_REQUEST]: {
        id: "flows.transactionType.PURCHASE_REQUEST",
        defaultMessage: "Purchase Request",
    },
    [TransactionType.PURCHASE_ORDER]: {
        id: "flows.transactionType.PURCHASE_ORDER",
        defaultMessage: "Purchase Order",
    },
    [TransactionType.INVOICE]: {
        id: "flows.transactionType.INVOICE",
        defaultMessage: "Invoice",
    },
    [TransactionType.PAYMENT]: {
        id: "flows.transactionType.PAYMENT",
        defaultMessage: "Payment",
    },
    [TransactionType.DEPOSIT]: {
        id: "flows.transactionType.DEPOSIT",
        defaultMessage: "Deposit",
    },
})

const collaborationStatus: Record<CollaborationStatus, MessageDescriptor> = defineMessages({
    [CollaborationStatus.ACTIVE]: {
        id: "flows.collaborationStatus.ACTIVE",
        defaultMessage: "Active",
    },
    [CollaborationStatus.INACTIVE]: {
        id: "flows.collaborationStatus.INACTIVE",
        defaultMessage: "Inactive",
    },
    [CollaborationStatus.REFUSED]: {
        id: "flows.collaborationStatus.REFUSED",
        defaultMessage: "Refused",
    },
    [CollaborationStatus.IN_REVIEW]: {
        id: "flows.collaborationStatus.IN_REVIEW",
        defaultMessage: "In review",
    },
    [CollaborationStatus.CLOTURED]: {
        id: "flows.collaborationStatus.CLOTURED",
        defaultMessage: "Clotured",
    },
    [CollaborationStatus.EXCLUDED]: {
        id: "flows.collaborationStatus.EXCLUDED",
        defaultMessage: "Excluded",
    },
})

const ifNode = defineMessages({
    nodeTitle: {
        id: "flows.ifNode.title",
        defaultMessage: "If",
    },
    conditionLabel: {
        id: "flows.ifNode.conditionLabel",
        defaultMessage: "Conditions",
    },
    noConditionError: {
        id: "flows.ifNode.noConditionError",
        defaultMessage: "Missing if conditions",
    },
    trueLabel: {
        id: "flows.ifNode.trueLabel",
        defaultMessage: "True",
    },
    falseLabel: {
        id: "flows.ifNode.falseLabel",
        defaultMessage: "False",
    },
    sideBarLabel: {
        id: "flows.ifNode.sideBarLabel",
        defaultMessage: "If condition",
    },
})

const checkNode = defineMessages({
    nodeTitle: {
        id: "flows.checkNode.nodeTitle",
        defaultMessage: "Check Node",
    },
    passLabel: {
        id: "flows.checkNode.passLabel",
        defaultMessage: "Pass",
    },
    refused: {
        id: "flows.checkNode.refused",
        defaultMessage: "Refused",
    },
    objectTypeLabel: {
        id: "flows.checkNode.objectTypeLabel",
        defaultMessage: "Object Type",
    },
    reviewersLabel: {
        id: "flows.checkNode.reviewersLabel",
        defaultMessage: "Reviewers",
    },
    noReviewers: {
        id: "flows.checkNode.noReviewers",
        defaultMessage: "No reviewers",
    },
    teamIdLabel: {
        id: "flows.checkNode.teamIdLabel",
        defaultMessage: "Team ID",
    },
    typeLabel: {
        id: "flows.checkNode.typeLabel",
        defaultMessage: "Type",
    },
    passThresholdLabel: {
        id: "flows.checkNode.passThresholdLabel",
        defaultMessage: "Pass threshold",
    },
    refuseThresholdLabel: {
        id: "flows.checkNode.refuseThresholdLabel",
        defaultMessage: "Refuse threshold",
    },
    sideBarLabel: {
        id: "flows.checkNode.sideBarLabel",
        defaultMessage: "Check Node",
    },
})

const setPartnershipFieldNode = defineMessages({
    nodeTitle: {
        id: "flows.setPartnershipFieldNode.title",
        defaultMessage: "Set Partnership field",
    },
    label: {
        id: "flows.setPartnershipFieldNode.label",
        defaultMessage: "Partnership Field",
    },
    partnershipIdLabel: {
        id: "flows.setPartnershipFieldNode.partnershipIdLabel",
        defaultMessage: "Partnership ID",
    },
    fieldLabel: {
        id: "flows.setPartnershipFieldNode.fieldLabel",
        defaultMessage: "Field to update",
    },
    valueLabel: {
        id: "flows.setPartnershipFieldNode.valueLabel",
        defaultMessage: "Value to set",
    },
    sideBarLabel: {
        id: "flows.setPartnershipFieldNode.sideBarLabel",
        defaultMessage: "Set Partnership field",
    },
})

const sendEmailNode = defineMessages({
    nodeTitle: {
        id: "flows.sendEmailNode.title",
        defaultMessage: "Send Email",
    },
    subjectLabel: {
        id: "flows.sendEmailNode.subjectLabel",
        defaultMessage: "Subject",
    },
    noMessageError: {
        id: "flows.sendEmailNode.noMessageError",
        defaultMessage: "Missing message",
    },
    sideBarLabel: {
        id: "flows.sendEmailNode.sideBarLabel",
        defaultMessage: "Send Email",
    },
})

const addToBudgetNode = defineMessages({
    nodeTitle: {
        id: "flows.addToBudgetNode.title",
        defaultMessage: "Add to Budget",
    },
    amountLabel: {
        id: "flows.addToBudgetNode.amountLabel",
        defaultMessage: "Amount",
    },
    sideBarLabel: {
        id: "flows.addToBudgetNode.sideBarLabel",
        defaultMessage: "Add to Budget",
    },
})

const invoiceToPurchaseOrderMatchNode = defineMessages({
    nodeTitle: {
        id: "flows.InvoiceToPurchaseOrderMatchNode.title",
        defaultMessage: "Match invoice to purchase order",
    },
    sideBarLabel: {
        id: "flows.InvoiceToPurchaseOrderMatchNode.sideBarLabel",
        defaultMessage: "Match invoice to purchase order",
    },
    matched: {
        id: "flows.InvoiceToPurchaseOrderMatchNode.matched",
        defaultMessage: "Matched",
    },
    unmatched: {
        id: "flows.InvoiceToPurchaseOrderMatchNode.unmatched",
        defaultMessage: "Not matched",
    },
})

const setInvoiceLifecycleStatusNode = defineMessages({
    nodeTitle: {
        id: "flows.setInvoiceLifecycleStatusNode.title",
        defaultMessage: "Invoice lifecycle status",
    },
    sideBarLabel: {
        id: "flows.setInvoiceLifecycleStatusNode.sideBarLabel",
        defaultMessage: "Set invoice lifecycle status",
    },
})

const node = defineMessages({
    errorDefault: {
        id: "flows.node.errorDefault",
        defaultMessage: "Missing configuration",
    },
})

const flowCard = defineMessages({
    nodeTitle: {
        id: "flows.flowCard.version",
        defaultMessage: "version",
    },
    enabled: {
        id: "flows.editor.enabled",
        defaultMessage: "Enabled",
    },
    disabled: {
        id: "flows.editor.disabled",
        defaultMessage: "Disabled",
    },
})

const fitsToBudgetNode = defineMessages({
    nodeTitle: {
        id: "flows.fitsToBudgetNode.title",
        defaultMessage: "Fits to Budget",
    },
    sideBarLabel: {
        id: "flows.fitsToBudgetNode.sideBarLabel",
        defaultMessage: "Fits to Budget",
    },
    fitsLabel: {
        id: "flows.fitsToBudgetNode.fitsLabel",
        defaultMessage: "Fits",
    },
    doesNotFitLabel: {
        id: "flows.fitsToBudgetNode.doesNotFitLabel",
        defaultMessage: "Does not fit",
    },
})

const sideBar = defineMessages({
    listTitle: {
        id: "flows.sideBar.listTitle",
        defaultMessage: "Flow nodes",
    },
    configurationTitle: {
        id: "flows.sideBar.configurationTitle",
        defaultMessage: "Configuration",
    },
})

const eventTriggerConfiguration = defineMessages({
    eventTitle: {
        id: "flows.eventTriggerConfiguration.eventTitle",
        defaultMessage: "Event Trigger",
    },
    eventSelectLabel: {
        id: "flows.eventTriggerConfiguration.eventSelectLabel",
        defaultMessage: "Event type",
    },
    conditionsTitle: {
        id: "flows.eventTriggerConfiguration.conditionsTitle",
        defaultMessage: "Conditions",
    },
    edit: {
        id: "flows.eventTriggerConfiguration.edit",
        defaultMessage: "Configuration",
    },
    enableConditionsLabel: {
        id: "flows.eventTriggerConfiguration.enableConditionsLabel",
        defaultMessage: "Filter events",
    },
    andConditionsTitle: {
        id: "flows.eventTriggerConfiguration.andConditionsTitle",
        defaultMessage: "And conditions",
    },
    andConditionFirstLabel: {
        id: "flows.eventTriggerConfiguration.andConditionFirstLabel",
        defaultMessage: "This condition",
    },
    andConditionOtherLabel: {
        id: "flows.eventTriggerConfiguration.andConditionOtherLabel",
        defaultMessage: "And",
    },
    orConditionFirstLabel: {
        id: "flows.eventTriggerConfiguration.orConditionFirstLabel",
        defaultMessage: "if",
    },
    orConditionOtherLabel: {
        id: "flows.eventTriggerConfiguration.orConditionOtherLabel",
        defaultMessage: "or",
    },
    newConditionLabel: {
        id: "flows.eventTriggerConfiguration.newConditionLabel",
        defaultMessage: "Add condition",
    },
    newConditionGroupLabel: {
        id: "flows.eventTriggerConfiguration.newConditionGroupLabel",
        defaultMessage: "Add new condition",
    },

    [ObjectEvent.CREATED]: {
        id: "flows.eventTriggerConfiguration.objectEvent.created",
        defaultMessage: "Create event",
    },
    [ObjectEvent.UPDATED]: {
        id: "flows.eventTriggerConfiguration.objectEvent.updated",
        defaultMessage: "Update event",
    },
})

const configurationNode = defineMessages({
    nameLabel: {
        id: "flows.configurationNode.nameLabel",
        defaultMessage: "Name",
    },
    saveConfigurationLabel: {
        id: "flows.configurationNode.saveConfigurationLabel",
        defaultMessage: "Save",
    },
    cancelConfigurationLabel: {
        id: "flows.configurationNode.cancelConfigurationLabel",
        defaultMessage: "Cancel",
    },
    deleteNodeLabel: {
        id: "flows.configurationNode.deleteNodeLabel",
        defaultMessage: "Delete",
    },
    advanced: {
        id: "flows.configurationNode.advanced",
        defaultMessage: "Advanced",
    },
})

const ifConfiguration = defineMessages({
    title: {
        id: "flows.ifConfiguration.title",
        defaultMessage: "If condition",
    },
    label: {
        id: "flows.ifConfiguration.label",
        defaultMessage: "Condition",
    },
})
const printLineConfiguration = defineMessages({
    title: {
        id: "flows.printLineConfiguration.title",
        defaultMessage: "Message to print",
    },
    label: {
        id: "flows.printLineConfiguration.label",
        defaultMessage: "Message",
    },
})

const partnershipFieldToUpdate: Record<PartnershipFieldToUpdate, MessageDescriptor> = defineMessages({
    [PartnershipFieldToUpdate.PREFERRED_STATUS]: {
        id: "flows.partnershipFieldToUpdate.PREFERRED_STATUS",
        defaultMessage: "Preferred status",
    },
    [PartnershipFieldToUpdate.VERIFIED_STATUS]: {
        id: "flows.partnershipFieldToUpdate.VERIFIED_STATUS",
        defaultMessage: "Verified status",
    },
    [PartnershipFieldToUpdate.ALLOW_TO_SEND_PO]: {
        id: "flows.partnershipFieldToUpdate.ALLOW_TO_SEND_PO",
        defaultMessage: "Allow to send PO",
    },
    [PartnershipFieldToUpdate.ALLOW_TO_PAY_INVOICE]: {
        id: "flows.partnershipFieldToUpdate.ALLOW_TO_PAY_INVOICE",
        defaultMessage: "Allow to pay invoice",
    },
    [PartnershipFieldToUpdate.COLLABORATION_STATUS]: {
        id: "flows.partnershipFieldToUpdate.COLLABORATION_STATUS",
        defaultMessage: "Collaboration status",
    },
})

const setPartnershipFieldConfiguration = defineMessages({
    fieldTitle: {
        id: "flows.setPartnershipFieldConfiguration.fieldTitle",
        defaultMessage: "Partnership field to update",
    },
    valueLabel: {
        id: "flows.setPartnershipFieldConfiguration.valueLabel",
        defaultMessage: "Value to set",
    },
    fieldLabel: {
        id: "flows.setPartnershipFieldConfiguration.fieldLabel",
        defaultMessage: "Field to update",
    },
    editPartnershipIdLabel: {
        id: "flows.setPartnershipFieldConfiguration.editPartnershipIdLabel",
        defaultMessage: "Partnership Id",
    },
    verifiedStatus: {
        id: "flows.setPartnershipFieldConfiguration.verifiedStatusLabel",
        defaultMessage: "Mark as verified",
    },
    preferredStatus: {
        id: "flows.setPartnershipFieldConfiguration.preferredStatusLabel",
        defaultMessage: "Mark as preferred",
    },
    allowToSendPO: {
        id: "flows.setPartnershipFieldConfiguration.allowToSendPOLabel",
        defaultMessage: "Enable sending PO",
    },
    allowToPayInvoice: {
        id: "flows.setPartnershipFieldConfiguration.allowToPayInvoiceLabel",
        defaultMessage: "Enable payment of invoices",
    },
})

const checkNodeConfiguration = defineMessages({
    usersTitle: {
        id: "flows.checkNodeConfiguration.usersTitle",
        defaultMessage: "Users to review",
    },
    teamsTitle: {
        id: "flows.checkNodeConfiguration.teamsTitle",
        defaultMessage: "Teams to review",
    },
    objectType: {
        id: "flows.checkNodeConfiguration.objectType",
        defaultMessage: "Object Type",
    },
    objectId: {
        id: "flows.checkNodeConfiguration.objectId",
        defaultMessage: "Object ID",
    },
    user: {
        id: "flows.checkNodeConfiguration.user",
        defaultMessage: "Users",
    },
    team: {
        id: "flows.checkNodeConfiguration.team",
        defaultMessage: "Teams",
    },
    type: {
        id: "flows.checkNodeConfiguration.type",
        defaultMessage: "Type",
    },
    userId: {
        id: "flows.checkNodeConfiguration.userId",
        defaultMessage: "User ID",
    },
    passThreshold: {
        id: "flows.checkNodeConfiguration.passThreshold",
        defaultMessage: "Pass threshold",
    },
    refuseThreshold: {
        id: "flows.checkNodeConfiguration.refuseThreshold",
        defaultMessage: "Refuse threshold",
    },
})

const sendEmailConfiguration = defineMessages({
    title: {
        id: "flows.configuration.sendEmail.title",
        defaultMessage: "Send Email Configuration",
    },
    subjectLabel: {
        id: "flows.configuration.sendEmail.subjecrLabel",
        defaultMessage: "Subject",
    },
    toLabel: {
        id: "flows.configuration.sendEmail.toLabel",
        defaultMessage: "To",
    },
    toHelper: {
        id: "flows.configuration.sendEmail.toHelper",
        defaultMessage: "Separate multiple email addresses with commas",
    },
    bodyLabel: {
        id: "flows.configuration.sendEmail.bodyLabel",
        defaultMessage: "Message",
    },
})

const addToBudgetConfiguration = defineMessages({
    title: {
        id: "flows.configuration.addToBudget.title",
        defaultMessage: "Add to Budget Configuration",
    },
    budgetIdLabel: {
        id: "flows.configuration.addToBudget.budgetIdLabel",
        defaultMessage: "Budget ID",
    },
    transactionIdLabel: {
        id: "flows.configuration.addToBudget.transactionIdLabel",
        defaultMessage: "Transaction ID",
    },
    transactionTypeLabel: {
        id: "flows.configuration.addToBudget.transactionTypeLabel",
        defaultMessage: "Transaction Type",
    },
    amountLabel: {
        id: "flows.configuration.addToBudget.amountLabel",
        defaultMessage: "Amount",
    },
    failIfOverbudgetLabel: {
        id: "flows.configuration.addToBudget.failIfOverbudgetLabel",
        defaultMessage: "Fail if over budget",
    },
    selectBudgetsLabel: {
        id: "flows.configuration.addToBudget.selectBudgetsLabel",
        defaultMessage: "Select budget",
    },
    currencyLabel: {
        id: "flows.configuration.addToBudget.currencyLabel",
        defaultMessage: "Currency",
    },
})

const InvoiceToPurchaseOrderMatchNodeConfiguration = defineMessages({
    title: {
        id: "flows.invoiceToPurchaseOrderMatchConfigration.title",
        defaultMessage: "Match invoice to purchase order configuration",
    },
    invoiceIdLabel: {
        id: "flows.invoiceToPurchaseOrderMatchConfigration.invoiceIdLabel",
        defaultMessage: "Invoice ID",
    },
})

const setInvoiceLifecycleStatusConfiguration = defineMessages({
    title: {
        id: "flows.configuration.setInvoiceLifecycleStatus.title",
        defaultMessage: "Set invoice lifecycle status configuration",
    },
    invoiceIdLabel: {
        id: "flows.configuration.setInvoiceLifecycleStatus.invoiceIdLabel",
        defaultMessage: "Invoice ID",
    },
    statusLabel: {
        id: "flows.configuration.setInvoiceLifecycleStatus.statusLabel",
        defaultMessage: "Status to set",
    },
})

const fitsToBudgetConfiguration = defineMessages({
    title: {
        id: "flows.fitsToBudgetConfiguration.title",
        defaultMessage: "Fits to Budget configuration",
    },
    budgetIdLabel: {
        id: "flows.fitsToBudgetConfiguration.budgetIdLabel",
        defaultMessage: "Budget ID",
    },
    transactionIdLabel: {
        id: "flows.fitsToBudgetConfiguration.transactionIdLabel",
        defaultMessage: "Transaction ID",
    },
    transactionTypeLabel: {
        id: "flows.fitsToBudgetConfiguration.transactionTypeLabel",
        defaultMessage: "Transaction Type",
    },
    amountLabel: {
        id: "flows.fitsToBudgetConfiguration.amountLabel",
        defaultMessage: "Amount",
    },
    currencyLabel: {
        id: "flows.fitsToBudgetConfiguration.currencyLabel",
        defaultMessage: "Currency",
    },
})

const suggestions = defineMessages({
    equals: {
        id: "flows.suggestions.equals",
        defaultMessage: "equals",
    },
    true: {
        id: "flows.suggestions.true",
        defaultMessage: "true",
    },
    false: {
        id: "flows.suggestions.false",
        defaultMessage: "false",
    },
    partnershipTypeSupplier: {
        id: "flows.suggestions.partnershipTypeSupplier",
        defaultMessage: "Supplier",
    },
    partnershipTypeBuyer: {
        id: "flows.suggestions.partnershipTypeBuyer",
        defaultMessage: "Buyer",
    },
    eventTriggerNewPartnership: {
        id: "flows.suggestions.eventTriggerNewPartnership",
        defaultMessage: "Partnership",
    },
})

const suggestionTypes = defineMessages({
    operator: {
        id: "flows.suggestions.operator",
        defaultMessage: "Operators",
    },
    property: {
        id: "flows.suggestions.property",
        defaultMessage: "Properties",
    },
    value: {
        id: "flows.suggestions.value",
        defaultMessage: "Values",
    },
    trigger: {
        id: "flows.suggestions.trigger",
        defaultMessage: "Triggers",
    },
})

const conditionBuilder = defineMessages({
    operator: {
        id: "flows.conditionBuilder.operator",
        defaultMessage: "=",
    },
    leftHand: {
        id: "flows.conditionBuilder.leftHand",
        defaultMessage: "Select a property",
    },
    rightHand: {
        id: "flows.conditionBuilder.rightHand",
        defaultMessage: "Select a value",
    },
    saveLabel: {
        id: "flows.conditionBuilder.saveLabel",
        defaultMessage: "Save",
    },
    cancelLabel: {
        id: "flows.conditionBuilder.cancelLabel",
        defaultMessage: "Cancel",
    },
    advancedLabel: {
        id: "flows.conditionBuilder.advancedLabel",
        defaultMessage: "Condition",
    },
})

const error = defineMessages({
    noEventTrigger: {
        id: "flows.error.noEventTrigger",
        defaultMessage: "Flow must have one event trigger node",
    },
    multipleEventTriggers: {
        id: "flows.error.multipleEventTriggers",
        defaultMessage: "Flow must have only one event trigger node",
    },
    triggerNodeNotConnected: {
        id: "flows.error.triggerNodeNotConnected",
        defaultMessage: "Event trigger node is not connected to any other node",
    },
    cycleDetected: {
        id: "flows.error.cycleDetected",
        defaultMessage: "Nodes cannot have the same next node",
    },
    loadingFlow: {
        id: "flows.error.loadingFlow",
        defaultMessage: "Unable to load this flow",
    },
    updatingFlow: {
        id: "flows.error.updatingFlow",
        defaultMessage: "Unable to update this flow",
    },
    fetchingFlows: {
        id: "flows.error.fetchingFlows",
        defaultMessage: "Unable to fetch flows",
    },
    permission: {
        id: "flows.error.permission",
        defaultMessage: "Permission error",
    },
    loadingRunInformation: {
        id: "flows.error.loadingRunInformation",
        defaultMessage: "Unable to load this run",
    },
    loadingRuns: {
        id: "flows.error.loadingRuns",
        defaultMessage: "Unable to load runs",
    },
})

export const messages = {
    conditionBuilder,
    runsTable,
    runInformation,
    configurationNode,
    eventTriggerConfiguration,
    ifConfiguration,
    printLineConfiguration,
    setPartnershipFieldConfiguration,
    checkNodeConfiguration,
    sendEmailConfiguration,
    addToBudgetConfiguration,
    InvoiceToPurchaseOrderMatchNodeConfiguration,
    setInvoiceLifecycleStatusConfiguration,
    fitsToBudgetConfiguration,
    objectType,
    objectEvent,
    transactionType,
    collaborationStatus,
    partnershipFieldToUpdate,
    page,
    flowTrigger,
    flowCard,
    filter,
    modalCreate,
    editor,
    sideBar,
    eventTriggerNode,
    sendEmailNode,
    addToBudgetNode,
    ifNode,
    setPartnershipFieldNode,
    checkNode,
    node,
    suggestions,
    suggestionTypes,
    invoiceToPurchaseOrderMatchNode,
    setInvoiceLifecycleStatusNode,
    fitsToBudgetNode,
    error,
}
