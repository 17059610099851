import { Box, Grid, styled } from "@mui/material"
import { FC } from "react"

import { ItemLabel } from "~/components/ItemLabel/ItemLabel"
import { ProgressBarItemDisplay } from "~/components/ProgressBar/ProgressBar"

const Dot = styled("div")<{ $color: string }>(({ $color }) => ({
    height: "12px",
    width: "12px",
    backgroundColor: $color,
    borderRadius: "var(--border-radius-md)",
    marginBottom: "4px",
}))

const LegendLabel = styled(ItemLabel)({
    fontSize: "var(--font-size-md)",
    lineHeight: "22px",
})

interface Props {
    values: ProgressBarItemDisplay[]
}

export const ProgressBarLegend: FC<Props> = ({ values }) => {
    return (
        <Grid container gap={5} rowGap={2}>
            {values.map((p) => (
                <Box key={p.label}>
                    <Box display="flex" alignItems="center" gap={1}>
                        <Dot $color={p.color} />
                        <LegendLabel>{p.label}</LegendLabel>
                    </Box>
                    {p.value ?? null}
                </Box>
            ))}
        </Grid>
    )
}
