import { Avatar, Grid, Stack, capitalize } from "@mui/material"
import { Check } from "react-feather"
import { defineMessages, useIntl } from "react-intl"

import { Card } from "~/components/Card/Card"
import { ItemLabel } from "~/components/ItemLabel/ItemLabel"
import { MessageResult } from "~/domains/copilot/prompt/types/Copilot"

const messages = defineMessages({
    preparingPurchaseRequest: {
        id: "copilot.prompt.preparingPurchaseRequest",
        defaultMessage: "Preparing your purchase request",
    },
    currency: {
        id: "invoice.invoiceLines.currency",
        defaultMessage: "Currency",
    },
    item: {
        id: "invoice.invoiceLines.item",
        defaultMessage: "Item",
    },
    message: {
        id: "invoice.invoiceLines.message",
        defaultMessage: "Message",
    },
    price: {
        id: "invoice.invoiceLines.price",
        defaultMessage: "Price",
    },
    quantity: {
        id: "invoice.invoiceLines.quantity",
        defaultMessage: "Quantity",
    },
    tax: {
        id: "invoice.invoiceLines.tax",
        defaultMessage: "Tax",
    },
    type: {
        id: "invoice.invoiceLines.type",
        defaultMessage: "Type",
    },
})
interface CopilotCompletionProps {
    completion?: MessageResult["completion"]
}

export const CopilotCompletion = ({ completion }: CopilotCompletionProps) => {
    const { formatMessage } = useIntl()
    return Object.keys(completion || {}).length === 0 ? null : (
        <Grid className="completion-container">
            <Card title={formatMessage(messages.preparingPurchaseRequest)}>
                <Stack gap={1}>
                    {Object.entries(completion || {}).map(([key, value]) => (
                        <Grid container alignItems="center" gap={1} key={key}>
                            <Avatar
                                sx={{
                                    bgcolor: `var(--color-${value ? "primary" : "lighter-grey"})`,
                                    width: 20,
                                    height: 20,
                                }}
                            >
                                <Check size={16} />
                            </Avatar>
                            <Grid flexGrow={1}>
                                <Card
                                    sx={{ bgcolor: "var(--color-lighter-grey-2)", paddingBlock: 1 }}
                                    status={value ? "filled" : "default"}
                                >
                                    <ItemLabel>
                                        {messages[key] ? formatMessage(messages[key]) : capitalize(key)}
                                    </ItemLabel>
                                    <ItemLabel>
                                        <b>{value ?? "..."}</b>
                                    </ItemLabel>
                                </Card>
                            </Grid>
                        </Grid>
                    ))}
                </Stack>
            </Card>
        </Grid>
    )
}
