import { TextField, Typography, styled } from "@mui/material"
import React, { ChangeEvent, useRef, useState } from "react"
import { useIntl } from "react-intl"

import { commonMessages } from "~/common-messages"
import { Button, Modal } from "~/components"
import { messages } from "~/domains/orchestration/flows/messages"
import { CreateFlowQuery } from "~/domains/orchestration/flows/types"

interface Props {
    visible: boolean
    close: () => void
    createFlowCallback: (p: CreateFlowQuery) => void
}

const StyledContent = styled(Modal.Content)({
    minHeight: "70px",
})

export const ModalCreateFlow: React.FC<Props> = ({ visible, close, createFlowCallback }) => {
    const { formatMessage } = useIntl()
    const [name, setName] = useState("")
    const formRef = useRef<HTMLFormElement>(null)

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        createFlowCallback({
            body: {
                name,
                enabled: false,
                nodes: [],
            },
        })
        close()
    }

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value)
    }

    return (
        <Modal open={visible} onClose={close}>
            <Modal.Header>
                <Typography variant="h2" gutterBottom>
                    {formatMessage(messages.modalCreate.title)}
                </Typography>
            </Modal.Header>
            <form onSubmit={handleSubmit} ref={formRef}>
                <StyledContent>
                    <TextField
                        required
                        name="flow.name"
                        label={formatMessage(messages.modalCreate.flowName)}
                        placeholder={formatMessage(messages.modalCreate.flowName)}
                        onChange={handleInputChange}
                        value={name}
                        fullWidth
                    />
                </StyledContent>
                <Modal.Footer>
                    <Button type="neutral" buttonType="button" onClick={close}>
                        {formatMessage(commonMessages.cancel)}
                    </Button>
                    <Button type="primary" buttonType="submit">
                        {formatMessage(commonMessages.confirm)}
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}
