import { FC } from "react"

export const formatText = (input: string) => {
    const boldFormatted = input.replace(/\*\*(.*?)\*\*/g, "<b>$1</b>")
    const italicFormatted = boldFormatted.replace(/__(.*?)__/g, "<i>$1</i>")
    const strikethroughFormatted = italicFormatted.replace(/~~(.*?)~~/g, "<s>$1</s>")
    const codeFormatted = strikethroughFormatted.replace(/`(.*?)`/g, "<code>$1</code>")

    return codeFormatted
}

interface FormattedTextProps {
    text: string
}

const FormattedText: FC<FormattedTextProps> = ({ text }) => {
    return <span dangerouslySetInnerHTML={{ __html: formatText(text) }} />
}

export default FormattedText
