import { RootState } from "~/store"

export const selectFetchingESG = (state: RootState) => state.customFields.fetchingESG
export const selectESGFields = (state: RootState) => state.customFields.esgFields

export const selectFetchingCustomFieldValues = (state: RootState) => state.customFields.fetchingCustomFieldValues
export const selectCustomFieldValues = (state: RootState) => state.customFields.customFieldValues
export const selectPendingCustomFieldValues = (state: RootState) => state.customFields.pendingCustomFieldValues

export const selectFetchingCustomFieldObjects = (state: RootState) => state.customFields.fetchingCustomFieldObjects
export const selectCustomFieldObjects = (state: RootState) => state.customFields.customFieldObjects
export const selectPendingCustomFieldObjects = (state: RootState) => state.customFields.pendingCustomFieldObjects
