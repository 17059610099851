import { Grid } from "@mui/material"
import { FC } from "react"

import { Loader } from "~/components"
import { workflowMock } from "~/domains/transactions/invoices-v1/api/mocks/workflowMock"
import { WorkflowValidation } from "~/features/buyer/components/WorkflowValidation"
import { InvoiceI } from "~/types"

interface InvoiceWorkflowProps {
    invoice: InvoiceI
}

export const InvoiceWorkflow: FC<InvoiceWorkflowProps> = ({ invoice }) => {
    const { loading: workflowLoading, checkDone, totalChecks, approvers, refusers, reviewers } = workflowMock //useInvoiceValidationActions(invoice.buyerId, invoice.id)

    return (
        <>
            {workflowLoading ? (
                <Grid container justifyContent="center">
                    <Loader small />
                </Grid>
            ) : (
                <WorkflowValidation
                    reviewers={reviewers}
                    approvers={approvers}
                    refusers={refusers}
                    checkDone={checkDone}
                    totalChecks={totalChecks}
                    invoiceStatus={invoice.status}
                />
            )}
        </>
    )
}
