import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { useCustomFieldsApi } from "../customFieldsApi"
import { CustomFieldValueI } from "../types/CustomFieldValue"
import { customFieldsActions, selectCustomFieldObjects, selectFetchingCustomFieldObjects } from "../store"
import { store } from "~/store"
import { useCallback, useEffect } from "react"
import * as Sentry from "@sentry/browser"

export const useFetchCustomFieldsByObjectId = (objectId: string): CustomFieldValueI[] | null => {
    const dispatch = useAppDispatch()
    const customFieldsApi = useCustomFieldsApi()
    const customFieldsObjects = useAppSelector(selectCustomFieldObjects)[objectId]
    const flattenCustomFieldsObjects = customFieldsObjects ? Object.values(customFieldsObjects).flat() : []

    const fetch = useCallback(async () => {
        const fetching = selectFetchingCustomFieldObjects(store.getState())[objectId] === true
        if (!fetching && objectId) {
            try {
                dispatch(customFieldsActions.fetchCustomFieldObjects(objectId))
                const values = await customFieldsApi.getValues(objectId)
                const flattenValues = Object.values(values).flat()
                dispatch(customFieldsActions.fetchCustomFieldObjectsSuccess({ objectId, values: flattenValues }))
            } catch (e) {
                Sentry.captureException(e, { extra: { objectId } })
                dispatch(customFieldsActions.fetchCustomFieldObjectsFailure(objectId))
            }
        }
    }, [customFieldsApi, objectId, dispatch])

    useEffect(() => {
        fetch()
    }, [fetch])

    return flattenCustomFieldsObjects
}
