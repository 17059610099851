import * as Sentry from "@sentry/browser"
import React, { FC, useEffect, useMemo, useRef } from "react"
import { useIntl } from "react-intl"
import { generatePath, useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"

import { DataTable, Loader, OrderDirection } from "~/components"
import { BackLink } from "~/components/Header/BackLink"
import { useGetRunQuery } from "~/domains/orchestration/flows/api/runsApi"
import { useOrganizationId } from "~/domains/orchestration/flows/hooks"
import { messages } from "~/domains/orchestration/flows/messages"
import { RUNS_LIST } from "~/domains/orchestration/flows/routes"
import { Run, RunId } from "~/domains/orchestration/flows/types"

export const RunInformation: FC = () => {
    const { runId = "" as RunId } = useParams<{ runId: RunId }>()
    const { formatMessage } = useIntl()

    const organizationId = useOrganizationId()
    const navigate = useNavigate()

    const organizationIdRef = useRef(organizationId)

    const {
        data: run,
        isError,
        isLoading,
        error,
    } = useGetRunQuery(
        { runId },
        {
            refetchOnMountOrArgChange: true,
        }
    )

    const columns = useMemo(
        () => [
            {
                id: "id",
                getValue: (run: Run) => run.id,
                label: formatMessage(messages.runInformation.id),
            },
            {
                id: "flowId",
                getValue: (run: Run) => run.flowId,
                label: formatMessage(messages.runInformation.flowId),
            },
            {
                id: "flowVersion",
                getValue: (run: Run) => run.flowVersion,
                label: formatMessage(messages.runInformation.flowVersion),
            },
            {
                id: "status",
                getValue: (run: Run) => run.status,
                label: formatMessage(messages.runInformation.status),
            },
            {
                id: "state",
                getValue: (run: Run) => run.state.pathTaken.join(" / "),
                label: formatMessage(messages.runInformation.state),
            },
            {
                id: "error",
                getValue: (run: Run) => run.error || "",
                label: formatMessage(messages.runInformation.error),
            },
            {
                id: "startedAt",
                getValue: (run: Run) => run.startedAt,
                label: formatMessage(messages.runInformation.startedAt),
            },
            {
                id: "finishedAt",
                getValue: (run: Run) => run.finishedAt,
                label: formatMessage(messages.runInformation.finishedAt),
            },
        ],
        [formatMessage]
    )

    useEffect(() => {
        if (!organizationIdRef.current) {
            organizationIdRef.current = organizationId
        }

        if (organizationIdRef.current !== organizationId) {
            navigate(generatePath(RUNS_LIST))
        }
    }, [organizationId])

    if (isLoading) {
        return <Loader fullscreen />
    }
    if (isError) {
        toast.error(formatMessage(messages.error.loadingRunInformation))
        Sentry.captureException(error)
        return null
    }

    if (!run) {
        return null
    }

    return (
        <>
            <BackLink urlOrFunction={RUNS_LIST} />
            <DataTable data={[run]} defaultOrderDirection={OrderDirection.ASC} columns={columns} />
        </>
    )
}
