import { Box, Grid } from "@mui/material"
import React, { ChangeEvent, ReactNode, useCallback } from "react"
import { defineMessages, useIntl } from "react-intl"

import { OrganizationAddressI } from "~/types"

import { TitleTypography } from "../OrganizationSelector/Title"
import { StyledTextField } from "./StyledTextField"

const messages = defineMessages({
    title: {
        id: "company.address.title",
        defaultMessage: "Address",
    },
    line1: {
        id: "company.address.line1",
        defaultMessage: "Line 1",
    },
    line2: {
        id: "company.address.line2",
        defaultMessage: "Line 2",
    },
    postalCode: {
        id: "company.address.postalCode",
        defaultMessage: "Postal Code",
    },
    city: {
        id: "company.address.city",
        defaultMessage: "City",
    },
    country: {
        id: "company.address.country",
        defaultMessage: "Country",
    },
})

interface Props {
    label: ReactNode
    address: OrganizationAddressI
    onChange?: (payload: Partial<OrganizationAddressI>) => void
}

export const AddressForm: React.FC<Props> = ({ label, address, onChange }) => {
    const { formatMessage } = useIntl()
    const onAddressLineChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            onChange && onChange({ addressLine: e.currentTarget.value })
        },
        [onChange]
    )

    const onSecondaryAddressLineChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            onChange && onChange({ secondaryAddressLine: e.currentTarget.value })
        },
        [onChange]
    )

    const onZipCodeChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            onChange && onChange({ zipCode: e.currentTarget.value })
        },
        [onChange]
    )

    const onCityChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            onChange && onChange({ city: e.currentTarget.value })
        },
        [onChange]
    )

    const onCountryChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            onChange && onChange({ country: e.currentTarget.value })
        },
        [onChange]
    )

    return (
        <Box>
            <TitleTypography sx={{ marginBottom: 2 }}>{label}</TitleTypography>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <StyledTextField
                        required={!!onChange}
                        id="companyDetails.address.line1"
                        name="companyDetails.address.line1"
                        label={formatMessage(messages.line1)}
                        placeholder={formatMessage(messages.line1)}
                        onChange={onAddressLineChange}
                        value={address.addressLine}
                        fullWidth
                        disabled={!onChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <StyledTextField
                        id="companyDetails.address.line2"
                        name="companyDetails.address.line2"
                        label={formatMessage(messages.line2)}
                        placeholder={formatMessage(messages.line2)}
                        onChange={onSecondaryAddressLineChange}
                        value={address.secondaryAddressLine ?? ""}
                        fullWidth
                        disabled={!onChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <StyledTextField
                        required={!!onChange}
                        id="companyDetails.address.postalCode"
                        name="companyDetails.address.postalCode"
                        label={formatMessage(messages.postalCode)}
                        placeholder={formatMessage(messages.postalCode)}
                        onChange={onZipCodeChange}
                        value={address.zipCode}
                        fullWidth
                        disabled={!onChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <StyledTextField
                        required={!!onChange}
                        id="companyDetails.address.city"
                        name="companyDetails.address.city"
                        label={formatMessage(messages.city)}
                        placeholder={formatMessage(messages.city)}
                        onChange={onCityChange}
                        value={address.city}
                        fullWidth
                        disabled={!onChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <StyledTextField
                        required={!!onChange}
                        name="companyDetails.address.country"
                        label={formatMessage(messages.country)}
                        placeholder={formatMessage(messages.country)}
                        onChange={onCountryChange}
                        value={address.country}
                        fullWidth
                        disabled={!onChange}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}
