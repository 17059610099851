export interface ContractCheckI {
    lateFees: string
    matchWithPaymentCondition: boolean
    reasonExplained: string
    reasonExtractArgument: string[]
}

export const createEmptyContractCheck = (): ContractCheckI => ({
    lateFees: "",
    matchWithPaymentCondition: false,
    reasonExplained: "",
    reasonExtractArgument: [],
})
