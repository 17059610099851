import { HandleProps, Position } from "@xyflow/react"
import { NodeProps, Node as NodeType } from "@xyflow/react"
import React, { FC } from "react"
import { Zap } from "react-feather"
import { useIntl } from "react-intl"

import { messages } from "~/domains/orchestration/flows/messages"
import {
    EventTriggerNode as EventTriggerNodeType,
    ObjectEvent,
    ObjectType,
    isObjectEvent,
    isObjectType,
} from "~/domains/orchestration/flows/types"

import { Node } from "./Node"

const handles: HandleProps[] = [
    {
        type: "source",
        position: Position.Bottom,
    },
]

export const EventTriggerNode: FC<NodeProps<NodeType<EventTriggerNodeType>>> = ({ data, selected }) => {
    const { formatMessage } = useIntl()

    const error = !isObjectEvent(data.objectEvent ?? "") || !isObjectType(data.objectType ?? "")

    // Run when an invoice is created

    const headerInformation = !error
        ? formatMessage(messages.eventTriggerNode.headerInformation, {
              objectType: formatMessage(messages.objectType[data.objectType as ObjectType]).toLowerCase(),
              objectEvent: formatMessage(messages.objectEvent[data.objectEvent as ObjectEvent]).toLowerCase(),
          })
        : ""

    return (
        <Node
            title={formatMessage(messages.eventTriggerNode.nodeTitle)}
            name={data.name}
            headerInformation={headerInformation}
            icon={Zap}
            handles={handles}
            selected={selected}
            error={error}
            slug={data.slug}
        ></Node>
    )
}
