import { useCallback, useEffect } from "react"
import { useDispatch } from "react-redux"
import { defineMessages, useIntl } from "react-intl"
import { useAppSelector } from "~/store/hooks"
import { RoleWithEntityIds } from "~/domains/identity/features/roles-permissions/types/RolesPermissions"
import { rolesPermissionsActions, selectRoleUsers } from "../rolesPermissionsSlice"
import { userApi } from "~/api"
import { UserI } from "~/types"

const messages = defineMessages({
    error: {
        id: "roles.users.errorFetch",
        defaultMessage: "We can't retrieve the users. Please contact your administrator",
    },
})

export const useFetchUsers = (role: RoleWithEntityIds): UserI[] => {
    const dispatch = useDispatch()
    const { formatMessage } = useIntl()
    const users = useAppSelector(selectRoleUsers)

    const fetchUsers = useCallback(async () => {
        try {
            dispatch(rolesPermissionsActions.fetchRoleUsers())
            const users = await userApi.findUsersByIds(role.userIds ?? [])

            dispatch(rolesPermissionsActions.fetchRoleUsersSuccess(users))
            return users
        } catch (error) {
            dispatch(rolesPermissionsActions.fetchRoleUsersFailed(formatMessage(messages.error)))
        }
    }, [role])

    useEffect(() => {
        fetchUsers()
    }, [fetchUsers])

    return users
}
