import { Autocomplete, Chip, Stack, TextField, styled } from "@mui/material"
import cls from "classnames"
import React, { ChangeEvent, FC, useEffect, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"

import { messages } from "~/domains/orchestration/flows/messages"
import {
    CheckNode,
    TeamReviewer,
    UserReviewer,
    UserType,
    isTeamReviewer,
    isUserReviewer,
} from "~/domains/orchestration/flows/types"
import { useAppSelector } from "~/store/hooks"
import { useFetchOrganizationMembers, useFetchOrganizationTeams } from "~/store/organization/hooks"
import { selectCurrentOrganization } from "~/store/organization/organizationSlice"
import { OrganizationMemberUserI, OrganizationTeamI } from "~/types"

import { ConfigurationNode } from "./ConfigurationNode"

interface Props {
    selectedNode: CheckNode
    unselectCallback: () => void
}

const StyledChip = styled(Chip)`
    margin: var(--spacing-xxs);
`

export const CheckNodeConfiguration: FC<Props> = ({ selectedNode, unselectCallback }) => {
    const { formatMessage } = useIntl()

    const [users, setUsers] = useState<OrganizationMemberUserI[]>([])
    const [teams, setTeams] = useState<OrganizationTeamI[]>([])

    const organization = useAppSelector(selectCurrentOrganization)
    const [members] = useFetchOrganizationMembers(organization!)
    const { teams: allTeams, loading: loadingAllTeams } = useFetchOrganizationTeams(organization!.id, true)

    // Handle the current node
    const [currentNode, setCurrentNode] = useState(selectedNode)

    const handleSelectUser = (_: any, values: OrganizationMemberUserI[] = []) => {
        setUsers(values)
    }

    const handleSelectTeam = (_: any, values: OrganizationTeamI[]) => {
        setTeams(values)
    }

    useEffect(() => {
        if (members.length) {
            const reviewerIds = selectedNode.reviewers.filter(isUserReviewer).map((r) => r.userId)
            setUsers(members.filter((member) => reviewerIds.includes(member.userId)))
        }

        if (allTeams.length) {
            const teamsIds = selectedNode.reviewers.filter(isTeamReviewer).map((r) => r.teamId)
            setTeams(allTeams.filter((team) => teamsIds.includes(team.teamId)))
        }
    }, [members, allTeams])

    useEffect(() => {
        const usersReviewers: UserReviewer[] = users.map((user) => ({ userId: user.userId, type: UserType.USER }))
        const teamsReviewers: TeamReviewer[] = teams.map((team) => ({ teamId: team.teamId, type: UserType.TEAM }))

        setCurrentNode({
            ...currentNode,
            reviewers: [...usersReviewers, ...teamsReviewers],
        })
    }, [users, teams])

    const handleChangeThreshold =
        (field: "passThreshold" | "refuseThreshold") => (e: ChangeEvent<HTMLInputElement>) => {
            const value = parseInt(e.target.value || "1", 10)
            if (Number.isInteger(value)) {
                setCurrentNode({ ...currentNode, [field]: value })
            }
        }

    const configurationNodeItemClassName = cls("flows-configurationNode-item", "flows-editor-sideBar-item")

    return (
        <ConfigurationNode configuredNode={currentNode} unselectCallback={unselectCallback}>
            <Stack gap={2} className={configurationNodeItemClassName}>
                <FormattedMessage tagName="h5" {...messages.checkNodeConfiguration.usersTitle} />
                <Autocomplete
                    multiple
                    autoComplete
                    options={members}
                    value={users}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => {
                            const { onDelete } = getTagProps({ index })
                            const name = option?.user?.fullName ?? ""
                            return (
                                <StyledChip key={option.userId} label={name} variant="outlined" onDelete={onDelete} />
                            )
                        })
                    }
                    getOptionKey={(option) => option.userId}
                    getOptionLabel={(option) => option?.user?.fullName ?? ""}
                    filterSelectedOptions
                    isOptionEqualToValue={(option, value) => option.userId === value.userId}
                    onChange={handleSelectUser}
                    renderInput={(params) => (
                        <TextField {...params} label={formatMessage(messages.checkNodeConfiguration.user)} />
                    )}
                />
                <FormattedMessage tagName="h5" {...messages.checkNodeConfiguration.teamsTitle} />

                <Autocomplete
                    multiple
                    autoComplete
                    loading={loadingAllTeams}
                    options={allTeams}
                    value={teams}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => {
                            const { onDelete } = getTagProps({ index })
                            const name = option?.name ?? ""
                            return (
                                <StyledChip key={option.teamId} label={name} variant="outlined" onDelete={onDelete} />
                            )
                        })
                    }
                    getOptionKey={(option) => option.teamId}
                    getOptionLabel={(option) => option?.name ?? ""}
                    filterSelectedOptions
                    isOptionEqualToValue={(option, value) => option.teamId === value.teamId}
                    onChange={handleSelectTeam}
                    renderInput={(params) => (
                        <TextField {...params} label={formatMessage(messages.checkNodeConfiguration.team)} />
                    )}
                />
            </Stack>

            <Stack gap={2} className={configurationNodeItemClassName}>
                <TextField
                    label={formatMessage(messages.checkNodeConfiguration.passThreshold)}
                    value={currentNode.passThreshold}
                    size="small"
                    onChange={handleChangeThreshold("passThreshold")}
                />

                <TextField
                    label={formatMessage(messages.checkNodeConfiguration.refuseThreshold)}
                    value={currentNode.refuseThreshold}
                    size="small"
                    onChange={handleChangeThreshold("refuseThreshold")}
                />
            </Stack>
        </ConfigurationNode>
    )
}
