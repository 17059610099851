import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "~/store"
import { rolesPermissionsState } from "./rolesPermissionsState"
import {
    OrganizationPermission,
    PermissionId,
    RoleI,
    RoleWithEntityIds,
} from "../features/roles-permissions/types/RolesPermissions"
import { OrganizationId, TeamId, UserI, UserId } from "~/types"
import { SharedObjectWithName } from "~/types/SharedObjects"
import { SpiceDBPermissionsLiteI } from "~/domains/identity/features/roles-permissions/types/SpiceDB"

const initialState = rolesPermissionsState

const rolesPermissionsSlice = createSlice({
    name: "roles-permissions",
    initialState: initialState,
    reducers: {
        fetchRoles(state) {
            state.loading = true
        },
        fetchRolesSuccess(
            state,
            action: PayloadAction<{ organizationId: OrganizationId; roles: RoleWithEntityIds[] }>
        ) {
            state.loading = false
            state.organizationId = action.payload.organizationId
            state.roleWithEntityIds = action.payload.roles
        },
        fetchRolesFailed(state, action: PayloadAction<string>) {
            state.loading = false
            state.error = action.payload
        },
        fetchRoleUsers(state) {
            state.usersLoading = true
            state.usersError = null
        },
        fetchRoleUsersSuccess(state, action: PayloadAction<UserI[]>) {
            state.usersLoading = false
            state.users = action.payload
        },
        fetchRoleUsersFailed(state, action: PayloadAction<string>) {
            state.usersLoading = false
            state.usersError = action.payload
        },
        fetchPermissions(state) {
            state.loading = true
        },
        fetchPermissionsSuccess(state, action: PayloadAction<PermissionId[]>) {
            state.loading = false
            state.permissions = action.payload
        },
        fetchPermissionsFailed(state, action: PayloadAction<string>) {
            state.loading = false
            state.error = action.payload
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        fetchRole(state, action: PayloadAction<string>) {
            state.loading = true
        },
        fetchRoleSuccess(state, action: PayloadAction<RoleI>) {
            state.loading = false
            state.data = action.payload
        },
        fetchRoleFailed(state, action: PayloadAction<string>) {
            state.loading = false
            state.error = action.payload
        },
        createRoleSuccess(state, action: PayloadAction<RoleWithEntityIds>) {
            state.roleWithEntityIds = [...state.roleWithEntityIds, action.payload]
        },
        createRoleError(state, action: PayloadAction<string>) {
            state.error = action.payload
        },
        addRolesToUser(state, action: PayloadAction<{ userId: UserId; roles: RoleWithEntityIds[] }>) {
            const newRolesWithUsers = state.roleWithEntityIds.map((role) => {
                const newRoleIndex = action.payload.roles.findIndex((r) => r.id === role.id)
                if (newRoleIndex !== -1 && role.userIds) {
                    return { ...role, userIds: [...role.userIds, action.payload.userId] }
                }

                return role
            })
            state.roleWithEntityIds = newRolesWithUsers
        },
        removeRolesFromUser(state, action: PayloadAction<{ userId: UserId; roles: RoleWithEntityIds[] }>) {
            const newRolesWithUsers = state.roleWithEntityIds.map((role) => {
                const removedRoleIndex = action.payload.roles.findIndex((r) => r.id === role.id)
                if (removedRoleIndex !== -1 && role.userIds) {
                    return { ...role, userIds: role.userIds.filter((u) => u !== action.payload.userId) }
                }

                return role
            })
            state.roleWithEntityIds = newRolesWithUsers
        },
        addRolesToTeam(state, action: PayloadAction<{ teamId: TeamId; roles: RoleWithEntityIds[] }>) {
            const newRolesWithUsers = state.roleWithEntityIds.map((role) => {
                const newRoleIndex = action.payload.roles.findIndex((r) => r.id === role.id)
                if (newRoleIndex !== -1 && role.teamIds) {
                    return { ...role, teamIds: [...role.teamIds, action.payload.teamId] }
                }

                return role
            })
            state.roleWithEntityIds = newRolesWithUsers
        },
        removeRolesFromTeam(state, action: PayloadAction<{ teamId: TeamId; roles: RoleWithEntityIds[] }>) {
            const newRolesWithTeams = state.roleWithEntityIds.map((role) => {
                const removedRoleIndex = action.payload.roles.findIndex((r) => r.id === role.id)
                if (removedRoleIndex !== -1 && role.teamIds) {
                    return { ...role, teamIds: role.teamIds.filter((u) => u !== action.payload.teamId) }
                }

                return role
            })
            state.roleWithEntityIds = newRolesWithTeams
        },
        updateRolePermissions(state, action: PayloadAction<{ role: RoleWithEntityIds; permissions: PermissionId[] }>) {
            const roleIndex = state.roleWithEntityIds.findIndex((r) => r.id === action.payload.role.id)
            if (roleIndex !== -1) {
                state.roleWithEntityIds[roleIndex] = { ...action.payload.role, permissions: action.payload.permissions }
            }
        },
        deleteRoleSuccess(state, action: PayloadAction<string>) {
            const roleIndex = state.roleWithEntityIds.findIndex((r) => r.id === action.payload)
            if (roleIndex !== -1) {
                state.roleWithEntityIds.splice(roleIndex, 1)
            }
        },
        setPermissions(state, action: PayloadAction<OrganizationPermission[]>) {
            state.jwtPermissions = action.payload
        },
        reset() {
            return initialState
        },
        sharedObject(
            state,
            action: PayloadAction<{ organizationId: OrganizationId; sharedObject: SharedObjectWithName }>
        ) {
            const shares = state.shares.filter((share) => share.id !== action.payload.sharedObject.id)
            state.shares = [...shares, action.payload.sharedObject]
        },
        fetchSharedObjects(state) {
            state.sharesLoading = true
            state.sharesError = null
        },
        fetchSharedObjectsSuccess(state, action: PayloadAction<SharedObjectWithName[]>) {
            state.sharesLoading = false
            state.sharesError = null
            state.shares = action.payload
        },
        fetchSharedObjectsFailed(state, action: PayloadAction<string>) {
            state.sharesLoading = false
            state.sharesError = action.payload
        },
        deletedSharedObject(state, action: PayloadAction<string>) {
            state.shares = state.shares.filter((share) => share.id !== action.payload)
        },
        addPendingTeams(state, action: PayloadAction<string[]>) {
            state.pendingTeams = [...new Set([...state.pendingTeams, ...action.payload])]
        },
        resetPendingTeams(state) {
            state.pendingTeams = []
        },
        addPendingMembers(state, action: PayloadAction<string[]>) {
            state.pendingMembers = [...new Set([...state.pendingMembers, ...action.payload])]
        },
        resetPendingMembers(state) {
            state.pendingMembers = []
        },
        addPendingInvitation(state, action: PayloadAction<{ email: string; name: string }>) {
            const alreadyExists = state.pendingInvitations.some(
                (invitation) => invitation.email === action.payload.email
            )
            if (!alreadyExists) {
                state.pendingInvitations = [...state.pendingInvitations, action.payload]
            }
        },
        resetPendingInvitations(state) {
            state.pendingInvitations = []
        },
        fetchObjectPermissions(state) {
            state.spiceDBObjectPermissions.loading = true
            state.spiceDBObjectPermissions.error = null
            state.spiceDBObjectPermissions.data = null
        },
        fetchObjectPermissionsSuccess(state, action: PayloadAction<SpiceDBPermissionsLiteI[]>) {
            state.spiceDBObjectPermissions.loading = false
            state.spiceDBObjectPermissions.error = null
            state.spiceDBObjectPermissions.data = action.payload
        },
        fetchObjectPermissionsFailed(state, action: PayloadAction<string>) {
            state.spiceDBObjectPermissions.loading = false
            state.spiceDBObjectPermissions.error = action.payload
            state.spiceDBObjectPermissions.data = null
        },
        updateObjectPermissions(state, action: PayloadAction<SpiceDBPermissionsLiteI>) {
            const data = state.spiceDBObjectPermissions.data
            if (data) {
                const isObjectDeletable = action.payload.permissions.length === 0
                const index = data.findIndex(
                    (object) =>
                        object.objectId === action.payload.objectId && object.objectType === action.payload.objectType
                )
                if (index !== -1) {
                    if (isObjectDeletable) {
                        data.splice(index, 1)
                    } else {
                        data[index] = action.payload
                    }
                }
            }
        },
        setObjectsPermissions(state, action: PayloadAction<SpiceDBPermissionsLiteI[]>) {
            const data = state.spiceDBObjectPermissions.data ? state.spiceDBObjectPermissions.data : []

            action.payload.forEach((newObject) => {
                const index = data.findIndex(
                    (object) => object.objectId === newObject.objectId && object.objectType === newObject.objectType
                )
                if (index !== -1) {
                    data[index] = newObject
                } else {
                    data.push(newObject)
                }
            })
        },
    },
})

export const rolesPermissionsActions = rolesPermissionsSlice.actions

// // Selectors
export const selectPermissions = (state: RootState) => state.rolesPermissions.permissions
export const selectRolesOrganizationId = (state: RootState) => state.rolesPermissions.organizationId
export const selectRolesWithEntities = (state: RootState) => state.rolesPermissions.roleWithEntityIds
export const selectRoleUsers = (state: RootState) => state.rolesPermissions.users
export const selectRole = (state: RootState) => state.rolesPermissions.data
export const selectRoleLoading = (state: RootState) => state.rolesPermissions.loading
export const selectRoleError = (state: RootState) => state.rolesPermissions.error
export const selectRoleUsersLoading = (state: RootState) => state.rolesPermissions.usersLoading
export const selectRoleUsersError = (state: RootState) => state.rolesPermissions.usersError
export const selectJwtPermissions = (state: RootState) => state.rolesPermissions.jwtPermissions
export const selectSharedObjects = (state: RootState) => state.rolesPermissions.shares
export const selectSharedObjectsLoading = (state: RootState) => state.rolesPermissions.sharesLoading
export const selectSharedObjectsError = (state: RootState) => state.rolesPermissions.sharesError
export const selectPendingInvitations = (state: RootState) => state.rolesPermissions.pendingInvitations
export const selectPendingMembers = (state: RootState) => state.rolesPermissions.pendingMembers
export const selectPendingTeams = (state: RootState) => state.rolesPermissions.pendingTeams

export const selectSpiceDBObjectPermissionsData = (state: RootState) =>
    state.rolesPermissions.spiceDBObjectPermissions.data
export const selectSpiceDBObjectPermissionsLoading = (state: RootState) =>
    state.rolesPermissions.spiceDBObjectPermissions.loading
export const selectSpiceDBObjectPermissionsError = (state: RootState) =>
    state.rolesPermissions.spiceDBObjectPermissions.error
export const selectSpiceDBObjectPermissions = createSelector(
    [selectSpiceDBObjectPermissionsData, selectSpiceDBObjectPermissionsLoading, selectSpiceDBObjectPermissionsError],
    (data, loading, error) => ({
        data,
        loading,
        error,
    })
)

// // Reducer
const rolesPermissionsReducer = rolesPermissionsSlice.reducer
export default rolesPermissionsReducer
