import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { CreateCustomFieldPayload } from "../types/CreateCustomFieldPayload"
import { CustomFieldI } from "../types/CustomField"
import { CreateCustomFieldObjectPayload, CustomFieldValueI } from "../types/CustomFieldValue"
import { CustomFieldsStore } from "./customFieldsStore"

const initialState: CustomFieldsStore = {
    fetchingESG: false,
    esgFields: null,
    customFieldValues: {},
    fetchingCustomFieldValues: {},
    pendingCustomFieldValues: {},
    customFieldObjects: {},
    fetchingCustomFieldObjects: {},
    pendingCustomFieldObjects: {},
}

const customFieldsSlice = createSlice({
    name: "custom-fields",
    initialState: initialState,
    reducers: {
        fetchingESG(state) {
            state.fetchingESG = true
        },
        fetchingESGSuccess(state, { payload }: PayloadAction<CustomFieldI[]>) {
            state.esgFields = payload
            state.fetchingESG = false
        },
        fetchCustomFieldValues(state, { payload }: PayloadAction<string>) {
            state.fetchingCustomFieldValues[payload] = true
        },
        fetchCustomFieldValuesSuccess(
            state,
            { payload: { objectId, values } }: PayloadAction<{ objectId: string; values: CustomFieldValueI[] }>
        ) {
            state.fetchingCustomFieldValues[objectId] = false
            state.customFieldValues[objectId] = values.reduce((acc: Record<string, CustomFieldValueI>, value) => {
                acc[value.name] = value
                return acc
            }, {})
        },
        fetchCustomFieldValuesFailure(state, { payload }: PayloadAction<string>) {
            state.fetchingCustomFieldValues[payload] = false
        },
        updateCustomFieldValue(
            state,
            {
                payload: { objectId, customField, value },
            }: PayloadAction<{ objectId: string; customField: CustomFieldI; value: string }>
        ) {
            if (state.customFieldValues[objectId][customField.name]) {
                state.customFieldValues[objectId][customField.name].value = value
            }
        },

        setPendingCustomFieldValue(
            state,
            {
                payload: { objectId, customField, value },
            }: PayloadAction<{ objectId: string; customField: CustomFieldI; value: CreateCustomFieldPayload }>
        ) {
            if (!state.pendingCustomFieldValues[objectId]) {
                state.pendingCustomFieldValues[objectId] = {}
            }
            state.pendingCustomFieldValues[objectId][customField.name] = value
        },

        fetchCustomFieldObjects(state, { payload }: PayloadAction<string>) {
            state.fetchingCustomFieldObjects[payload] = true
        },
        fetchCustomFieldObjectsSuccess(
            state,
            { payload: { objectId, values } }: PayloadAction<{ objectId: string; values: CustomFieldValueI[] }>
        ) {
            state.fetchingCustomFieldObjects[objectId] = false
            state.customFieldObjects[objectId] = values.reduce((acc: Record<string, CustomFieldValueI>, value) => {
                acc[value.name] = value
                return acc
            }, {})
        },
        fetchCustomFieldObjectsFailure(state, { payload }: PayloadAction<string>) {
            state.fetchingCustomFieldObjects[payload] = false
        },
        updateCustomFieldObject(
            state,
            {
                payload: { objectId, customField, value },
            }: PayloadAction<{ objectId: string; customField: CustomFieldValueI; value: string }>
        ) {
            if (customField && state.customFieldObjects[objectId][customField.name]) {
                state.customFieldObjects[objectId][customField.name].value = value
            }
        },
        setPendingCustomFieldObject(
            state,
            {
                payload: { customField, value },
            }: PayloadAction<{
                customField: CustomFieldValueI
                value: CreateCustomFieldObjectPayload
            }>
        ) {
            state.pendingCustomFieldObjects[customField.name] = value
        },
        resetPendingCustomFieldObjects(state) {
            state.pendingCustomFieldObjects = {}
        },
    },
})

export const customFieldsActions = customFieldsSlice.actions
export const customFieldsReducer = customFieldsSlice.reducer
