import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { prepareHeadersWithAuthorization } from "~/api/prepareHeaders"
import {
    CreatePaymentMethodDetails,
    PaymentMethodDetails,
    PaymentMethodDetailsObject,
} from "~/domains/payment/payment-method-details/types"
import { PaginatedResponse } from "~/domains/payment/types"

const tags = ["PaymentMethodDetails"]

const BASE_URL = import.meta.env.VITE_API_PAYMENT_URL_V2

interface PartnerPaymentMethodDetails {
    ownerId: string
    partnerId: string
}

export const paymentMethodDetailsApi = createApi({
    reducerPath: "paymentMethodDetailsApi",
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL, prepareHeaders: prepareHeadersWithAuthorization }),
    tagTypes: tags,
    endpoints: (builder) => ({
        getPaymentMethodDetails: builder.query<PaginatedResponse<PaymentMethodDetails>, unknown>({
            query: ({ page, page_size, owner, iban }) => ({
                url: "payment_method_details",
                params: { page, page_size, owner, iban },
            }),
            providesTags: tags,
        }),
        getPaymentMethodDetailsItem: builder.query({
            query: (id: string) => ({
                url: `payment_method_details/${id}`,
            }),
            providesTags: tags,
        }),
        getPartnerPaymentMethodDetails: builder.query({
            query: ({ ownerId, partnerId }: PartnerPaymentMethodDetails) => ({
                url: `payment_method_details/by_owner_and_partner/${ownerId}/${partnerId}`,
            }),
            providesTags: tags,
        }),
        createPaymentMethodDetails: builder.mutation({
            query: (paymentMethodDetails: CreatePaymentMethodDetails) => ({
                url: "payment_method_details",
                method: "POST",
                body: paymentMethodDetails,
            }),
            invalidatesTags: tags,
        }),
        createPaymentMethodDetailsObject: builder.mutation({
            query: (paymentMethodDetailsObject: PaymentMethodDetailsObject) => ({
                url: "payment_method_details_object",
                method: "POST",
                body: paymentMethodDetailsObject,
            }),
            invalidatesTags: tags,
        }),
        updatePaymentMethodDetails: builder.mutation({
            query: (paymentMethodDetails: PaymentMethodDetails) => ({
                url: `payment_method_details/${paymentMethodDetails.id}`,
                method: "PUT",
                body: paymentMethodDetails,
            }),
            invalidatesTags: tags,
        }),
        deletePaymentMethodDetails: builder.mutation({
            query: (id: string) => ({
                url: `payment_method_details/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: tags,
        }),
    }),
})

export const {
    useGetPaymentMethodDetailsQuery,
    useGetPaymentMethodDetailsItemQuery,
    useGetPartnerPaymentMethodDetailsQuery,
    useCreatePaymentMethodDetailsMutation,
    useCreatePaymentMethodDetailsObjectMutation,
    useUpdatePaymentMethodDetailsMutation,
    useDeletePaymentMethodDetailsMutation,
} = paymentMethodDetailsApi
