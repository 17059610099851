import { styled } from "@mui/material"
import { useEffect, useState } from "react"
import { Plus } from "react-feather"
import { FormattedMessage, defineMessages } from "react-intl"

import { Button, Loader } from "~/components"
import {
    InfoCards,
    PaymentMethodDetailsList,
    PaymentMethodDetailsManagement,
    PaymentMethodTabs,
} from "~/domains/payment/payment-method-details/components"
import { paymentMethodDetailsActions } from "~/domains/payment/payment-method-details/store/paymentMethodDetailsSlice"
import { selectActiveTab } from "~/domains/payment/payment-method-details/store/paymentMethodDetailsSlice"
import { PaymentMethodDetails as PaymentMethodDetailsI } from "~/domains/payment/payment-method-details/types"
import { PaymentMethodType } from "~/domains/payment/payment-methods/types"
import { useAppDispatch, useAppSelector } from "~/store/hooks"

const messages = defineMessages({
    newMethod: {
        id: "payment.paymentMethodDetails.newMethod",
        defaultMessage: "New method",
    },
})

const PaymentActions = styled("div")({
    display: "flex",
    justifyContent: "flex-end",
    gap: "20px",
})

interface PaymentMethodDetailsProps {
    isLoading: boolean
    isFetching: boolean
    organizationId: string
    items: PaymentMethodDetailsI[]
    onPaymentMethodDetailsCreated?: (data: PaymentMethodDetailsI) => void
}

export const PaymentMethodDetails = ({
    isLoading,
    isFetching,
    organizationId,
    items,
    onPaymentMethodDetailsCreated,
}: PaymentMethodDetailsProps) => {
    const [open, setOpen] = useState(false)
    const activeTab = useAppSelector(selectActiveTab)
    const dispatch = useAppDispatch()

    const handleTabChange = (tab: PaymentMethodType) => {
        dispatch(paymentMethodDetailsActions.setActiveTab(tab))
    }

    const handleAddNew = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    useEffect(() => {
        dispatch(paymentMethodDetailsActions.reset())
        return () => {
            dispatch(paymentMethodDetailsActions.reset())
        }
    }, [organizationId, dispatch])

    if (isLoading) {
        return <Loader position="center" />
    }

    return (
        <>
            <InfoCards items={items} />
            <PaymentActions>
                <PaymentMethodTabs currentTab={activeTab} onTabChange={handleTabChange} />
                <Button type="primary" onClick={handleAddNew}>
                    <Plus size={16} />
                    <FormattedMessage {...messages.newMethod} />
                </Button>
            </PaymentActions>
            <PaymentMethodDetailsList loading={isFetching} items={items} />
            <PaymentMethodDetailsManagement
                open={open}
                activeTab={activeTab}
                organizationId={organizationId}
                onPaymentMethodDetailsCreated={onPaymentMethodDetailsCreated}
                onClose={handleClose}
                onOpen={handleAddNew}
            />
        </>
    )
}
