import { Box, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from "@mui/material"
import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useRef, useState } from "react"
import { FormattedMessage, defineMessages, useIntl } from "react-intl"

import { userApi } from "~/api"
import UserAvatar from "~/components/Avatar/UserAvatar"
import TrimmedTextField from "~/components/Form/TrimmedTextField"
import { Button, Modal, OneTimePassword } from "~/core"
import "~/features/buyer/components/ModalRequest/ModalRequest.scss"
import { selectUser } from "~/store/account/accountSlice"
import { authActions, selectIsConnected } from "~/store/auth/authSlice"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { AddInvolvedPersonI, InvoiceRequestI, UserPartialI } from "~/types"
import { getJWTCookie } from "~/utils"

const messages = defineMessages({
    modalTitle: {
        id: "buyer.modalRequest.title",
        defaultMessage: "Request more information",
    },
    modalCancel: {
        id: "buyer.modalRequest.button.cancel",
        defaultMessage: "Cancel",
    },
    modalConfirm: {
        id: "buyer.modalRequest.button.confirm",
        defaultMessage: "Send",
    },
    modalSubject: {
        id: "buyer.modalRequest.label.subject",
        defaultMessage: "Subject",
    },
    modalMessage: {
        id: "buyer.modalRequest.label.message",
        defaultMessage: "Message",
    },
    modalName: {
        id: "buyer.modalRequest.label.name",
        defaultMessage: "Your name",
    },
    modalEmail: {
        id: "buyer.modalRequest.label.email",
        defaultMessage: "Your email",
    },
    emailVerificationTitle: {
        id: "buyer.modalRequest.emailVerification.title",
        defaultMessage: "Email verification",
    },
    email: {
        id: "buyer.modalRequest.emailVerification.labelEmail",
        defaultMessage: "Or add your email",
    },
    emailAdd: {
        id: "buyer.modalRequest.emailVerification.addEmail",
        defaultMessage: "Add email",
    },
    step1Title: {
        id: "buyer.modalRequest.step1.title",
        defaultMessage: "Select who you are",
    },
    step1Content: {
        id: "buyer.modalRequest.step1.content",
        defaultMessage: "You can also just {login}",
    },
    step3Title: {
        id: "buyer.modalRequest.step3.title",
        defaultMessage: "Make your request",
    },
    step3Content: {
        id: "buyer.modalRequest.step3.content",
        defaultMessage: "",
    },
    step2Title: {
        id: "buyer.modalRequest.step2.title",
        defaultMessage: "Authentication method",
    },
    emailVerificationText: {
        id: "buyer.modalRequest.emailVerification.text",
        defaultMessage: "Before sending a request, we need to verify if the email address {email} belongs to you.",
    },
    reconnect: {
        id: "buyer.emailVerification.reconnect",
        defaultMessage: "Your session has expired, please sign up again or verify your email",
    },
})

interface Props {
    displayModal: boolean
    setDisplayModal: Dispatch<SetStateAction<boolean>>
    onConfirm: (request: InvoiceRequestI) => void
    invoiceId: string
    involvedPeople: UserPartialI[]
}

export function ModalRequest({ displayModal, setDisplayModal, onConfirm, invoiceId, involvedPeople }: Props) {
    const isConnected = useAppSelector(selectIsConnected)
    const formRef = useRef<HTMLFormElement>(null)
    const { formatMessage } = useIntl()
    const [userId, setUserId] = useState<string>("")
    const [userName, setUserName] = useState<string>("")
    const [email, setEmail] = useState<string>("")
    const [isAuthorized, setIsAuthorized] = useState(false)
    const user = useAppSelector(selectUser)

    const dispatch = useAppDispatch()
    const [newPerson, setNewPerson] = useState("")
    const [emailList, setEmailList] = useState<AddInvolvedPersonI[]>([])
    const [request, setRequest] = useState<InvoiceRequestI>({
        subject: "",
        body: "",
        invoiceId,
        userId: userId,
    })

    useEffect(() => {
        if (isConnected && user.id) {
            setUserId(user.id)
            setUserName(user.fullName || "")
        }
    }, [isConnected, user])

    useEffect(() => {
        setIsAuthorized(isConnected || !!getJWTCookie())
    }, [isConnected])

    const handleAddInvolvedPeople = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        formRef.current?.requestSubmit()
        if (formRef.current?.checkValidity()) {
            setEmailList(emailList.concat({ email: newPerson }))
            setEmail(newPerson)
            setNewPerson("")
        }
    }

    const handleClickLogin = () => {
        dispatch(authActions.openPopupSignIn())
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
    }

    const handleChangeInvolvedPeople = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setNewPerson(event.target.value)
    }

    const handleChangeBody = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setRequest((prev) => {
            return { ...prev, ...{ body: e.target.value } }
        })
    }

    const handleChangeSubject = (e: ChangeEvent<HTMLInputElement>) => {
        setRequest((prev) => {
            return { ...prev, ...{ subject: e.target.value } }
        })
    }

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail((event.target as HTMLInputElement).value)
    }

    const handleChangeUserName = (e: ChangeEvent<HTMLInputElement>) => {
        setUserName(e.target.value)
    }

    const handleConfirm = async () => {
        const requestTmp = { ...{}, ...request }
        // If is isAuthorized but not connected, we know this is a passwordLess auth, and we don't have his id stored
        if (!isConnected && isAuthorized) {
            try {
                const user = await userApi.getCurrentUser()
                if (user.id) {
                    requestTmp.userId = user.id
                    onConfirm(requestTmp)
                }
                handleClose()
            } catch (error) {
                console.error(error)
            }
        } else {
            if (!requestTmp.userId && user) {
                requestTmp.userId = user.id
            }
            onConfirm(requestTmp)
            handleClose()
        }
    }

    const handleClose = () => {
        setDisplayModal(false)
    }

    return (
        <Modal
            open={displayModal}
            onClose={handleClose}
            aria-labelledby="modal-request"
            className="modal-request modal-otp"
        >
            <Modal.Header>
                <Typography variant="h2" gutterBottom>
                    <FormattedMessage {...messages.modalTitle} />
                </Typography>
            </Modal.Header>
            <Modal.Content>
                {isAuthorized ? (
                    <>
                        <Grid className="OTP-step step1">
                            <div className="OTP-step-num">1</div>
                            <div className="OTP-step-content">
                                <h5>
                                    <FormattedMessage {...messages.step3Title} />
                                </h5>
                            </div>
                        </Grid>
                        {isConnected ? null : (
                            <TextField
                                required
                                id="name"
                                name="name"
                                className="bg-grey"
                                label={formatMessage(messages.modalName)}
                                placeholder={formatMessage(messages.modalName)}
                                value={userName}
                                onChange={handleChangeUserName}
                                fullWidth
                            />
                        )}
                        <TextField
                            required
                            id="subject"
                            name="subject"
                            className="bg-grey"
                            label={formatMessage(messages.modalSubject)}
                            placeholder={formatMessage(messages.modalSubject)}
                            value={request.subject ?? ""}
                            onChange={handleChangeSubject}
                            fullWidth
                        />
                        <TextField
                            required
                            id="body"
                            name="body"
                            className="bg-grey"
                            label={formatMessage(messages.modalMessage)}
                            placeholder={formatMessage(messages.modalMessage)}
                            value={request.body ?? ""}
                            onChange={handleChangeBody}
                            fullWidth
                            rows={3}
                            multiline
                        />
                    </>
                ) : (
                    <Grid className="validation-auth">
                        <Grid className="OTP-step">
                            <div className="OTP-step-num">1</div>
                            <div className="OTP-step-content">
                                <h5>
                                    <FormattedMessage {...messages.step1Title} />
                                </h5>
                                <p>
                                    <FormattedMessage
                                        {...messages.step1Content}
                                        values={{
                                            login: <span onClick={handleClickLogin}>login</span>,
                                        }}
                                    />
                                </p>
                            </div>
                        </Grid>
                        <Grid className="involved-people-list">
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                name="radio-buttons-group"
                                onChange={handleRadioChange}
                                value={email}
                            >
                                {[...involvedPeople, ...emailList].map((person, index) => (
                                    <FormControlLabel
                                        key={index}
                                        value={person.email}
                                        labelPlacement="start"
                                        control={<Radio />}
                                        label={
                                            <>
                                                <UserAvatar email={person.email} />
                                                {person.email}
                                            </>
                                        }
                                    />
                                ))}
                            </RadioGroup>
                        </Grid>
                        <form onSubmit={handleSubmit} ref={formRef}>
                            <Grid className="add-email">
                                <TrimmedTextField
                                    required
                                    id="supplier.email"
                                    name="supplier.email"
                                    type="email"
                                    label={formatMessage(messages.email)}
                                    placeholder={formatMessage(messages.email)}
                                    onChange={handleChangeInvolvedPeople}
                                    value={newPerson}
                                    fullWidth
                                />
                                <Button onClick={handleAddInvolvedPeople}>
                                    <FormattedMessage {...messages.emailAdd} />
                                </Button>
                            </Grid>
                        </form>
                        {email === "" ? null : (
                            <>
                                <Grid className="OTP-step step2">
                                    <div className="OTP-step-num">2</div>
                                    <div className="OTP-step-content">
                                        <h5>
                                            <FormattedMessage {...messages.step2Title} />
                                        </h5>
                                    </div>
                                </Grid>
                                <Box className="otp-container">
                                    <p>
                                        <FormattedMessage
                                            {...messages.emailVerificationText}
                                            values={{
                                                email: <strong>{email}</strong>,
                                            }}
                                        />
                                    </p>
                                    <OneTimePassword senderEmail={email} setIsAuthorized={setIsAuthorized} />
                                </Box>
                            </>
                        )}
                    </Grid>
                )}
            </Modal.Content>
            <Modal.Footer className="vr-footer">
                <Button buttonType="button" onClick={handleClose} rightIcon="close" type="neutral">
                    <FormattedMessage {...messages.modalCancel} />
                </Button>
                <Button buttonType="submit" onClick={handleConfirm} disabled={!isAuthorized}>
                    <FormattedMessage {...messages.modalConfirm} />
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
