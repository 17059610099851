import React, { useMemo } from "react"
import { SavePayloadType } from "./types"
import { CompanyI, OrganizationRegistrationI, WhitePagesIdTypes } from "~/types"
import { ModalOrganizationDetails } from "./ModalOrganizationDetails"

interface Props {
    id?: string
    onSave: (payload: SavePayloadType) => void
    company: CompanyI
    withOrganizationAddress?: boolean
    withSendingAddress?: boolean
}

export const ModalCompanyDetails: React.FC<Props> = ({
    id,
    company,
    onSave,
    withOrganizationAddress,
    withSendingAddress,
}) => {
    const companyRegistrationInfo = useMemo<OrganizationRegistrationI>(
        () => ({
            countryCode: company.countryCode,
            preferredRegistrationNumber: {
                registrationType: company.idType,
                registrationNumber: company.id,
            },
            legalName: company.name,
            vatNumber: company.taxId,
            dunsNumber: company.idType === WhitePagesIdTypes.DUNS ? company.id : null,
        }),
        [company]
    )

    return (
        <ModalOrganizationDetails
            id={id}
            registrationInfo={companyRegistrationInfo}
            withOrganizationAddress={withOrganizationAddress}
            withSendingAddress={withSendingAddress}
            onSave={onSave}
            organizationId={company.id}
        />
    )
}
