import { useCallback, useState } from "react"
import { useWorkflowsApi } from "~/features/workflow/core/workflowsApi"
import { selectUser } from "~/store/account/accountSlice"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { workflowActions } from "../workflowSlice"
import { InvoiceId, OrganizationId } from "~/types"
import { invoiceActions } from "~/store/invoice/invoiceSlice"

export const useInvoiceActions = (
    organizationId: OrganizationId | null | undefined,
    invoiceId: InvoiceId | undefined
) => {
    const workflowsApi = useWorkflowsApi()
    const user = useAppSelector(selectUser)
    const dispatch = useAppDispatch()
    const [loading, setLoading] = useState<boolean>(false)

    const approveInvoice = useCallback(async () => {
        if (!invoiceId || !organizationId) return false
        setLoading(true)
        const result = await workflowsApi.approveInvoice(organizationId, invoiceId)
        if (result) {
            dispatch(workflowActions.approveInvoice({ invoiceId, approver: user.id }))
            dispatch(invoiceActions.setValidated())
        }
        setLoading(false)
        return result
    }, [workflowsApi, organizationId, invoiceId, dispatch, user])

    const refuseInvoice = useCallback(async () => {
        if (!invoiceId || !organizationId) return false
        setLoading(true)
        const result = await workflowsApi.refuseInvoice(organizationId, invoiceId)
        if (result) {
            dispatch(workflowActions.refuseInvoice({ invoiceId, refuser: user.id }))
        }
        setLoading(false)
        return result
    }, [workflowsApi, organizationId, invoiceId, dispatch, user])

    return {
        loading,
        approveInvoice,
        refuseInvoice,
    }
}
