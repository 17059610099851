import { Tooltip } from "@mui/material"
import React, { ReactNode } from "react"
import { AlertTriangle } from "react-feather"
import { MessageDescriptor, useIntl } from "react-intl"

import { CurrencyCodes } from "~/types"

interface Props {
    total: number
    currency?: CurrencyCodes
    label: ReactNode
    error: MessageDescriptor | null
}

const renderTotal = (label: ReactNode, amount: string, error: MessageDescriptor | null) => {
    return (
        <>
            <div className="invoice-lines-total-left">
                <div className="invoice-lines-total-label">{label}</div>
                <div className="invoice-lines-total-amount">{amount}</div>
            </div>
            {error === null ? null : (
                <div className="invoice-lines-total-right">
                    <AlertTriangle size={18} color="var(--color-yellow)" />
                </div>
            )}
        </>
    )
}

export const InvoiceLinesTotal: React.FC<Props> = ({ total, currency = CurrencyCodes.EUR, label, error }) => {
    const { formatNumber, formatMessage } = useIntl()

    return (
        <div className={`invoice-lines-total ${error === null ? "" : "is-invalid"}`}>
            {error ? (
                <Tooltip title={formatMessage(error)} arrow placement="top">
                    <div className="invoice-lines-total-tooltip">
                        {renderTotal(
                            label,
                            formatNumber(total, {
                                style: "currency",
                                currency,
                                currencyDisplay: "narrowSymbol",
                            }),
                            error
                        )}
                    </div>
                </Tooltip>
            ) : (
                renderTotal(
                    label,
                    formatNumber(total, {
                        style: "currency",
                        currency,
                        currencyDisplay: "narrowSymbol",
                    }),
                    error
                )
            )}
        </div>
    )
}
