import * as t from "io-ts"

import { OrganizationId } from "~/types"
import { optional } from "~/types/utils"

import { ContractDurationUnit } from "./ContractUnit"

const ContractDataTermsIO = t.type({
    startDate: t.string,
    endDate: t.string,
})

const ContractDataPartyIO = t.type({
    organizationId: optional(t.string),
    involvement: t.string,
})
const ContractDurationUnitIO = t.keyof({
    // using "keyof" for better performance instead of "union"
    [ContractDurationUnit.DAY]: null,
    [ContractDurationUnit.WEEK]: null,
    [ContractDurationUnit.MONTH]: null,
    [ContractDurationUnit.YEAR]: null,
})

const ContractRenewalTermIO = t.type({
    duration: t.number,
    unit: ContractDurationUnitIO,
})

const ContractRenewalOptionsIO = t.partial({
    autoRenewal: t.boolean,
    renewalTerm: optional(ContractRenewalTermIO),
    maximumRenewals: t.union([t.number, t.null]),
})

export type ContractDataTermsI = t.TypeOf<typeof ContractDataTermsIO>
export type ContractRenewalTermI = t.TypeOf<typeof ContractRenewalTermIO>
export type ContractRenewalOptionsI = t.TypeOf<typeof ContractRenewalOptionsIO>

export interface ContractDataPartyI {
    organizationId: OrganizationId | null | undefined
    involvement: string
}

export const ContractDataIO = t.partial({
    initialTerms: optional(ContractDataTermsIO),
    parties: optional(t.array(ContractDataPartyIO)),
    renewalOptions: optional(ContractRenewalOptionsIO),
})

export interface ContractDataI {
    initialTerms: ContractDataTermsI
    parties: ContractDataPartyI[]
    renewalOptions: ContractRenewalOptionsI
}
