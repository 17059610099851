import { defineMessages, MessageDescriptor } from "react-intl"
import { InvoiceLineI } from "~/types"
import { VatRateI } from "~/types/VatRate"
import { InvoiceLinesTotals, roundMoney } from "./computeInvoiceLinesTotals"

export enum InvoiceDiscountErrorType {
    discountDoesNotMatchInvoiceTotals = "discountDoesNotMatchInvoiceTotals",
    missingVATRateOnLines = "missingVATRateOnLines",
}

export const errorDiscountMessages: Record<InvoiceDiscountErrorType, MessageDescriptor> = defineMessages({
    [InvoiceDiscountErrorType.discountDoesNotMatchInvoiceTotals]: {
        id: "invoice.discount.errors.discountDoesNotMatchInvoiceTotals",
        defaultMessage:
            "The totals seem to be wrong, as you have a discount on this invoice.{br}- Your total taxes excluded: {referenceTotalExcludedTaxes}, should be {computedTotalExcludedTaxes}.{br}- Your total taxes included: {referenceTotalIncludedTaxes}, should be {computedTotalIncludedTaxes}.",
    },
    [InvoiceDiscountErrorType.missingVATRateOnLines]: {
        id: "invoice.discount.errors.missingVATRateOnLines",
        defaultMessage: "You're missing some tax rates on some lines. Please fill all tax rates.",
    },
})

export const invoiceDiscountErrorMessages = defineMessages({
    automaticCorrection: {
        id: "invoice.discount.errors.automaticCorrection",
        defaultMessage: "Click here for an automatic correction",
    },
})

export type VerifyInvoiceDiscountResult = {
    error: InvoiceDiscountErrorType | null
    totalExcludedTaxes: number
    total: number
}
export const verifyInvoiceDiscount = (
    totalExcludedTaxes: number,
    total: number,
    discount: number,
    lines: InvoiceLineI[],
    totals: InvoiceLinesTotals,
    vatRates: VatRateI[]
): VerifyInvoiceDiscountResult => {
    /* Calculate the discount ratio */
    const discountRatio = discount / totals.totalExcludedTaxes

    /* Apply this ratio to each total exluded tax of line */
    const newLines = lines.map((line) => ({
        ...line,
        totalExcludedTaxes: line.totalExcludedTaxes - line.totalExcludedTaxes * discountRatio,
    }))
    const newTotalExcludedTaxes = newLines.reduce((total, line) => total + line.totalExcludedTaxes, 0)

    /* Sum lines per VAT rate */
    let vatRateError: boolean = false
    const sumLinesPerTaxRate = newLines.reduce((groupedLines, line) => {
        if (!line.taxRateId) {
            vatRateError = true
            return groupedLines
        }
        if (!groupedLines[line.taxRateId]) {
            groupedLines[line.taxRateId] = 0
        }
        groupedLines[line.taxRateId] += line.totalExcludedTaxes
        return groupedLines
    }, {})
    if (vatRateError) {
        return { error: InvoiceDiscountErrorType.missingVATRateOnLines, totalExcludedTaxes, total }
    }

    /* Apply VAT rate on each summed lines */
    const newTotalIncludedTaxes = Object.keys(sumLinesPerTaxRate).reduce((total, taxRateId) => {
        const rate = vatRates.find((rate) => rate.id === taxRateId)
        return total + sumLinesPerTaxRate[taxRateId] * ((100 + (rate ? rate.rate : 0)) / 100)
    }, 0)

    /* Verify total excluded taxes */
    if (
        roundMoney(newTotalExcludedTaxes) !== roundMoney(totalExcludedTaxes) ||
        roundMoney(newTotalIncludedTaxes) !== roundMoney(total)
    ) {
        return {
            error: InvoiceDiscountErrorType.discountDoesNotMatchInvoiceTotals,
            totalExcludedTaxes: roundMoney(newTotalExcludedTaxes),
            total: roundMoney(newTotalIncludedTaxes),
        }
    }

    return {
        error: null,
        totalExcludedTaxes: roundMoney(newTotalExcludedTaxes),
        total: roundMoney(newTotalIncludedTaxes),
    }
}
