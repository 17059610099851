import { Stack, TextField } from "@mui/material"
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react"
import { MessageDescriptor, defineMessages, useIntl } from "react-intl"

import { Loader } from "~/components"
import { PartnerOrganizationI } from "~/domains/transactions/book-of-relations/types"
import {
    CompanyData,
    OrganizationPickerFromOCR,
    OrganizationPickerFromOcrResult,
    OrganizationPickerFromOcrResultType,
} from "~/domains/transactions/invoices/ocr/components/OrganizationPickerFromOCR"
import { registrationNumberIsPrefered } from "~/features/account/components/Company/FoundOrganization"
import { EstablishmentSelect } from "~/features/supplier/components/EstablishmentSelect"
import { fetchAndDecodeOrganization, useFetchOrganization } from "~/store/organization/hooks"
import {
    CompanyI,
    CountryCode,
    EstablishmentI,
    ImportInvoiceCompanyInfoI,
    InvoiceUserType,
    NO_ORGANIZATION_ID,
    OrganizationI,
    RegistrationNumberType,
    RegistrationNumberTypePerCountryCode,
    ViewTypeI,
    WhitePagesIdTypes,
} from "~/types"

import { useEstablishmentState } from "../../hooks"
import { CompanyAutocomplete, CompanyResult } from "../CompanyAutocomplete"
import { CompanyAutocompleteType } from "../CompanyAutocomplete/CompanyAutocompleteField"
import { UserOrganizationsSelect } from "../UserOrganizationsSelect"
import "./CompanyRegistrationFields.scss"

interface Props {
    initiator: InvoiceUserType
    currentOrganization: OrganizationI | undefined
    organizations: (OrganizationI | PartnerOrganizationI)[]
    suggestedCompanies: CompanyI[] | undefined
    companyDetails: ImportInvoiceCompanyInfoI
    ocrCompanyDetails?: ImportInvoiceCompanyInfoI
    updateData: (data: Partial<ImportInvoiceCompanyInfoI>) => void
    disabled?: boolean
    blockType: organizationBlockType
    userIsConnected: boolean
    isReadOnly?: boolean
}

export enum organizationBlockType {
    SUPPLIER = "SUPPLIER",
    BUYER = "BUYER",
}

export type OtherOrganizationI = {
    id: string
    name: string
}

const messages = defineMessages({
    companyName: {
        id: "supplier.supplierInfo.companyName",
        defaultMessage: "Company Name",
    },
    siren: {
        id: "supplier.supplierInfo.siren",
        defaultMessage: "SIREN",
    },
    siret: {
        id: "supplier.supplierInfo.siret",
        defaultMessage: "SIRET",
    },
    taxNumber: {
        id: "supplier.supplierInfo.taxNumber",
        defaultMessage: "VAT NUMBER",
    },
    registrationNumber: {
        id: "company.registrationNumber",
        defaultMessage: "Registration number",
    },
    or: {
        id: "buyer.import.page.me.or",
        defaultMessage: "or",
    },
})

const countrySpecificRegistrationNumber: Record<RegistrationNumberType, MessageDescriptor> = defineMessages({
    [RegistrationNumberType.FRENCH_SIRET]: {
        id: `company.registrationNumber.FRENCH_SIRET`,
        defaultMessage: "SIRET",
    },
})

export const getRegistrationNumberLabel = (countryCode: CountryCode): MessageDescriptor => {
    const registrationNumberType = RegistrationNumberTypePerCountryCode[countryCode]
    if (registrationNumberType) return countrySpecificRegistrationNumber[registrationNumberType]
    return messages.registrationNumber
}

// Either an organization is selected, or a company, or the fields are filled freely

export const CompanyRegistrationFields: React.FC<Props> = ({
    initiator,
    currentOrganization,
    companyDetails,
    ocrCompanyDetails,
    suggestedCompanies,
    updateData,
    organizations,
    disabled,
    blockType,
    userIsConnected,
    isReadOnly,
}) => {
    const { formatMessage } = useIntl()
    const [company, setCompany] = useState<CompanyResult>()
    const [companyLoading, setCompanyLoading] = useState<boolean>(false)
    const [searchAnotherOrganization, setSearchAnotherOrganization] = useState<boolean>(false)
    const [selectedEstablishment, setSelectedEstablishment] = useEstablishmentState(
        company?.type === CompanyAutocompleteType.WhitePagesResult ? company.value : undefined,
        undefined
    )

    /* const isCurrentOrganizationSelected = companyDetails.organizationId === currentOrganization?.id */
    const { organization: companyOrganization, loading: loadingCompanyOrganization } = useFetchOrganization(
        companyDetails.organizationId
    )

    const [companyResult, setCompanyResult] = useState<OrganizationPickerFromOcrResult | null>(
        companyOrganization
            ? { type: OrganizationPickerFromOcrResultType.ORGANIZATION, value: companyOrganization }
            : null
    )

    const readOnly = disabled || !!company /* || isCurrentOrganizationSelected */

    const vatNumberIsReadOnly =
        readOnly &&
        !(company && company.type === CompanyAutocompleteType.WhitePagesResult && !company.value.taxId) &&
        !(
            (companyDetails.organizationId && companyOrganization && !companyOrganization.registration.vatNumber) ||
            loadingCompanyOrganization
        )

    const mustShowUserOrganizationsSelect =
        userIsConnected &&
        organizations.length &&
        ((blockType === organizationBlockType.BUYER && initiator === InvoiceUserType.BUYER) ||
            (blockType === organizationBlockType.SUPPLIER && initiator === InvoiceUserType.SUPPLIER))

    const mustShowCompanyAutocomplete =
        (suggestedCompanies && suggestedCompanies.length === 1) ||
        !userIsConnected ||
        !organizations.length ||
        searchAnotherOrganization ||
        (initiator === InvoiceUserType.BUYER && blockType === organizationBlockType.SUPPLIER) ||
        (initiator === InvoiceUserType.SUPPLIER && blockType === organizationBlockType.BUYER)

    useEffect(() => {
        // The current user has an organization selected but this organization is not selected
        if (
            currentOrganization &&
            currentOrganization.id !== NO_ORGANIZATION_ID &&
            companyDetails.organizationId !== currentOrganization.id
        ) {
            // let's check if we should select this organization as the current company (buyer or supplier depending of the context)
            if (
                (!suggestedCompanies || suggestedCompanies.length === 0) &&
                !companyDetails.taxId &&
                !companyDetails.registrationNumber &&
                updateData
            ) {
                updateData({
                    organizationId: currentOrganization.id,
                    countryCode: currentOrganization.registration.countryCode,
                    name: currentOrganization.name,
                    taxId: currentOrganization.registration.vatNumber,
                    registrationNumber:
                        currentOrganization.registration.preferredRegistrationNumber?.registrationNumber,
                    dunsNumber: currentOrganization.registration.dunsNumber,
                })
            }
        }
    }, [currentOrganization, companyDetails, suggestedCompanies])

    useEffect(() => {
        setCompanyResult(
            !companyOrganization
                ? null
                : { type: OrganizationPickerFromOcrResultType.ORGANIZATION, value: companyOrganization }
        )
    }, [companyOrganization])

    /* useEffect(() => {
        setSearchAnotherOrganization(currentOrganization?.id !== companyDetails.organizationId)
    }, [currentOrganization, companyDetails]) */

    useEffect(() => {
        if (!company) return

        if (company.type === CompanyAutocompleteType.Organization) {
            updateData({
                name: company.value.name,
                organizationId: company.value.id,
                countryCode: company.value.registration.countryCode,
                dunsNumber: company.value.registration.dunsNumber ?? undefined,
                taxId: company.value.registration.vatNumber,
                registrationNumber: company.value.registration.preferredRegistrationNumber?.registrationNumber,
            })
            return
        }
        updateData({
            name: company.value.name,
            organizationId: null,
            countryCode: company.value.countryCode,
            dunsNumber: company.value.idType === WhitePagesIdTypes.DUNS ? company.value.id : null,
            registrationNumber:
                company.value.idType === WhitePagesIdTypes.FRENCH_SIRET
                    ? company.value.id
                    : (company.value.registrationNumbers.find(registrationNumberIsPrefered)?.registrationNumber ??
                      null),
            taxId: company.value.taxId,
        })
    }, [company, updateData])

    /* 
    This is causing re-renders when the user changes the organization :
    See: https://flowie-groupe.slack.com/archives/C05QRERB23Z/p1730726643244729
    useEffect(() => {
        if (currentOrganization && mustShowUserOrganizationsSelect && !isCurrentOrganizationSelected) {
            handleSelectUserOrganization(currentOrganization)
        }
    }, [mustShowUserOrganizationsSelect, isCurrentOrganizationSelected, currentOrganization])
    */

    useEffect(() => {
        if (selectedEstablishment) {
            updateData({
                registrationNumber: selectedEstablishment.id,
            })
        }
    }, [selectedEstablishment, updateData])

    const registrationNumberLabel = getRegistrationNumberLabel(companyDetails.countryCode)

    const loader = {
        startAdornment: (
            <div
                style={{
                    position: "relative",
                    left: 18,
                    top: 7,
                }}
            >
                <Loader small />
            </div>
        ),
    }

    const onCompanyChange = useCallback(
        (value: CompanyResult | undefined, establishmentToSelect?: EstablishmentI | undefined) => {
            setCompany(value)
            setSelectedEstablishment(establishmentToSelect)
        },
        [setCompany, setSelectedEstablishment]
    )

    const onCountryCodeChange = useCallback(
        (countryCode: CountryCode) => {
            updateData && updateData({ countryCode })
        },
        [updateData]
    )

    const onNameChange = useCallback(
        (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            updateData && updateData({ name: event.currentTarget.value })
        },
        [updateData]
    )

    const onVatNumberChange = useCallback(
        (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            updateData && updateData({ taxId: event.currentTarget.value })
        },
        [updateData]
    )

    const onRegistrationNumberChange = useCallback(
        (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            updateData &&
                updateData({
                    registrationNumber: event.currentTarget.value,
                })
        },
        [updateData]
    )

    const handleSelectUserOrganization = useCallback(
        (organization: OrganizationI | OtherOrganizationI) => {
            if (organization.id === NO_ORGANIZATION_ID) {
                setSearchAnotherOrganization(true)
                setCompany(undefined)
                return
            }
            setSearchAnotherOrganization(false)
            setCompany({
                type: CompanyAutocompleteType.Organization,
                value: organization as OrganizationI,
            })
        },
        [currentOrganization, organizations]
    )

    const onOrganizationPickerChange = useCallback(
        async (result: OrganizationPickerFromOcrResult | null) => {
            setCompanyResult(result)
            if (!result) {
                updateData({
                    organizationId: null,
                    dunsNumber: null,
                    name: "",
                    registrationNumber: "",
                    taxId: "",
                })
            } else if (result.type === OrganizationPickerFromOcrResultType.ORGANIZATION) {
                if (
                    !result.value.registration.dunsNumber &&
                    !result.value.registration.preferredRegistrationNumber?.registrationNumber
                ) {
                    const fetchedOrganization = await fetchAndDecodeOrganization(result.value.id)
                    updateData({
                        organizationId: fetchedOrganization.id,
                        dunsNumber: fetchedOrganization.registration.dunsNumber,
                        name: fetchedOrganization.name,
                        registrationNumber:
                            fetchedOrganization.registration.preferredRegistrationNumber?.registrationNumber,
                        taxId: fetchedOrganization.registration.vatNumber,
                    })
                } else {
                    updateData({
                        organizationId: result.value.id,
                        dunsNumber: result.value.registration.dunsNumber,
                        name: result.value.name,
                        registrationNumber: result.value.registration.preferredRegistrationNumber?.registrationNumber,
                        taxId: result.value.registration.vatNumber,
                    })
                }
            } else {
                updateData({
                    organizationId: null,
                    name: result.value.name,
                    dunsNumber: result.value.idType === WhitePagesIdTypes.DUNS ? result.value.id : null,
                    registrationNumber:
                        result.value.registrationNumbers.find(registrationNumberIsPrefered)?.registrationNumber,
                    taxId: result.value.taxId,
                })
            }
        },
        [updateData]
    )

    const ocrData = useMemo<CompanyData>(
        () => ({
            countryCode: companyDetails.countryCode,
            name: ocrCompanyDetails?.name,
            registrationNumber: ocrCompanyDetails?.registrationNumber || undefined,
            taxNumber: ocrCompanyDetails?.taxId || undefined,
        }),
        [ocrCompanyDetails, companyDetails]
    )

    // TODO: this is ugly, sorry
    if (`${initiator}` !== `${blockType}`) {
        return (
            <OrganizationPickerFromOCR
                ocrData={ocrData}
                options={organizations}
                onChange={onOrganizationPickerChange}
                setCountryCode={onCountryCodeChange}
                value={companyResult}
                viewType={blockType === organizationBlockType.BUYER ? ViewTypeI.buyer : ViewTypeI.supplier}
                disabled={disabled}
            />
        )
    }

    return (
        <>
            {!disabled && (
                <Stack className="company-registration-fields" gap={1}>
                    {mustShowUserOrganizationsSelect ? (
                        <>
                            <UserOrganizationsSelect
                                selectedOrganizationId={companyDetails.organizationId as string}
                                organizations={organizations}
                                onSelect={handleSelectUserOrganization}
                                searchAnotherOrganization={searchAnotherOrganization}
                                readOnly={isReadOnly}
                            />
                            {searchAnotherOrganization ? (
                                <div className="or-divider">{formatMessage(messages.or)}</div>
                            ) : null}
                        </>
                    ) : null}
                    {mustShowCompanyAutocomplete ? (
                        <CompanyAutocomplete
                            company={company}
                            setCompany={onCompanyChange}
                            organizations={organizations}
                            suggestedCompanies={suggestedCompanies}
                            countryCode={companyDetails.countryCode}
                            setCountryCode={onCountryCodeChange}
                            setCompanyLoading={setCompanyLoading}
                        />
                    ) : null}
                    {companyLoading ? (
                        <TextField
                            required
                            id="supplier.name"
                            name="supplier.name"
                            aria-readonly={true}
                            className={!mustShowCompanyAutocomplete ? "input-hidden" : ""}
                            inputProps={{ readOnly: true }}
                            InputProps={loader}
                            value={""}
                            label={!mustShowCompanyAutocomplete ? null : formatMessage(messages.companyName)}
                            fullWidth
                            type={mustShowCompanyAutocomplete ? "hidden" : "text"}
                        />
                    ) : (
                        <TextField
                            required
                            id="supplier.name"
                            name="supplier.name"
                            className={!mustShowCompanyAutocomplete ? "input-hidden" : ""}
                            placeholder={formatMessage(messages.companyName)}
                            onChange={onNameChange}
                            aria-readonly={readOnly}
                            inputProps={{ readOnly }}
                            value={companyDetails?.name ?? ""}
                            label={!mustShowCompanyAutocomplete ? null : formatMessage(messages.companyName)}
                            fullWidth
                            type={!mustShowCompanyAutocomplete ? "hidden" : "text"}
                        />
                    )}
                    {companyLoading ? (
                        <TextField
                            id="supplier.registrations.vatNumber"
                            name="supplier.registrations.vatNumber"
                            aria-readonly={true}
                            className={!mustShowCompanyAutocomplete ? "input-hidden" : ""}
                            inputProps={{ readOnly: true }}
                            InputProps={loader}
                            value={""}
                            label={!mustShowCompanyAutocomplete ? null : formatMessage(messages.taxNumber)}
                            fullWidth
                            type={!mustShowCompanyAutocomplete ? "hidden" : "text"}
                        />
                    ) : (
                        <TextField
                            id="supplier.registrations.vatNumber"
                            name="supplier.registrations.vatNumber"
                            className={!mustShowCompanyAutocomplete ? "input-hidden" : ""}
                            label={!mustShowCompanyAutocomplete ? null : formatMessage(messages.taxNumber)}
                            placeholder={formatMessage(messages.taxNumber)}
                            onChange={onVatNumberChange}
                            aria-readonly={vatNumberIsReadOnly}
                            inputProps={{
                                readOnly: vatNumberIsReadOnly,
                            }}
                            value={companyDetails?.taxId ?? ""}
                            fullWidth
                            type={!mustShowCompanyAutocomplete ? "hidden" : "text"}
                        />
                    )}
                    {companyLoading ? (
                        <TextField
                            required
                            id="supplier.company.registrationNumber"
                            name="supplier.company.registrationNumber"
                            aria-readonly={true}
                            className={!mustShowCompanyAutocomplete ? "input-hidden" : ""}
                            inputProps={{ readOnly: true }}
                            InputProps={loader}
                            value={""}
                            label={!mustShowCompanyAutocomplete ? null : formatMessage(registrationNumberLabel)}
                            fullWidth
                            type={!mustShowCompanyAutocomplete ? "hidden" : "text"}
                        />
                    ) : company?.type === CompanyAutocompleteType.WhitePagesResult && company.value.establishments ? (
                        <EstablishmentSelect
                            options={company.value.establishments}
                            value={selectedEstablishment}
                            onChange={setSelectedEstablishment}
                            className={!mustShowCompanyAutocomplete ? "input-hidden" : ""}
                        />
                    ) : (
                        <TextField
                            required
                            id="supplier.company.registrationNumber"
                            name="supplier.company.registrationNumber"
                            className={!mustShowCompanyAutocomplete ? "input-hidden" : ""}
                            label={!mustShowCompanyAutocomplete ? null : formatMessage(registrationNumberLabel)}
                            placeholder={formatMessage(registrationNumberLabel)}
                            onChange={onRegistrationNumberChange}
                            aria-readonly={readOnly}
                            inputProps={{ readOnly }}
                            value={companyDetails?.registrationNumber ?? ""}
                            fullWidth
                            type={!mustShowCompanyAutocomplete ? "hidden" : "text"}
                        />
                    )}
                </Stack>
            )}
        </>
    )
}
