import { FormState } from "~/domains/payment/payment-method-details/types"
import { PaymentMethod, PaymentMethodType } from "~/domains/payment/payment-methods/types"

export interface PaymentMethodDetailsState {
    selectedItem: FormState | null
    selectedPaymentMethod: PaymentMethod | null
    activeTab: PaymentMethodType
}

export const paymentMethodDetailsState: PaymentMethodDetailsState = {
    selectedItem: null,
    selectedPaymentMethod: null,
    activeTab: PaymentMethodType.BANK_TRANSFER,
}
