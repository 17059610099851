import { Link } from "@mui/material"
import { FC } from "react"
import { generatePath } from "react-router-dom"

import { PurchaseOrderRelationI } from "~/domains/transactions/document-relations/types"
import { INVOICE_BUYER_ROUTE } from "~/features/buyer/routes"
import { EDIT_INVOICE_SUPPLIER_ROUTE } from "~/features/supplier/routes"
import { InvoiceI, ViewTypeI } from "~/types"

interface Props {
    purchaseOrderRelation: PurchaseOrderRelationI
    viewType: ViewTypeI
    invoice?: InvoiceI | null
}

export const InvoiceLink: FC<Props> = ({ purchaseOrderRelation, viewType, invoice }) => {
    const label = invoice && invoice.reference ? `INV #${invoice.reference}` : "INV"
    const href = generatePath(viewType === ViewTypeI.buyer ? INVOICE_BUYER_ROUTE : EDIT_INVOICE_SUPPLIER_ROUTE, {
        invoiceId: purchaseOrderRelation.invoiceId,
    })

    return <Link href={href}>{label}</Link>
}
