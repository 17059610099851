import { FC } from "react"

import { Documents } from "~/components/UploadDocument/Documents"
import { DocumentObjectType } from "~/domains/identity/features/documents/types"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"

interface InvoiceDocumentsProps {
    invoiceId: string
}

export const InvoiceDocuments: FC<InvoiceDocumentsProps> = ({ invoiceId }) => {
    const organizationId = useAppSelector(selectCurrentOrganizationId)

    return (
        <>
            <Documents
                organizationId={organizationId || ""}
                objectId={invoiceId}
                objectType={DocumentObjectType.INVOICE}
            />
        </>
    )
}
