import { useEffect } from "react"

import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { OrganizationId } from "~/types"

import { useDocumentRelationsApi } from "../documentRelationsApi"
import { documentRelationsActions, selectInvoiceRelationsDifferencesState } from "../store/documentRelationsSlice"

export const useInvoiceRelationsDifferences = (organizationId: OrganizationId, invoiceId: string) => {
    const invoiceRelationsDifferences = useAppSelector(selectInvoiceRelationsDifferencesState)

    const documentRelationsApi = useDocumentRelationsApi()
    const dispatch = useAppDispatch()

    useEffect(() => {
        ;(async () => {
            dispatch(documentRelationsActions.fetchInvoiceRelationsDifferences({ invoiceId }))

            try {
                const invoiceRelationsDifferences = await documentRelationsApi.getInvoiceRelationsDifferences(
                    organizationId,
                    invoiceId
                )

                if (invoiceRelationsDifferences) {
                    dispatch(
                        documentRelationsActions.fetchInvoiceRelationsDifferencesSuccess({
                            invoiceId,
                            invoiceRelationsDifferences,
                        })
                    )
                }
            } catch (error) {
                console.error(error)
            }
        })()
    }, [organizationId, invoiceId, documentRelationsApi])

    return { invoiceRelationsDifferences }
}
