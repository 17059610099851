import { XYPosition } from "@xyflow/react"

import { TRANSACTION_TYPES } from "~/domains/orchestration/flows/const"
import {
    ApiFlow,
    ApiFlowItem,
    ApiFlowNode,
    ApiFlows,
    ApiNodeType,
    ApiReviewer,
    ApiRun,
    ApiRuns,
    Flow,
    FlowId,
    FlowNode,
    Flows,
    Node,
    NodeType,
    ObjectEvent,
    ObjectType,
    Reviewer,
    Run,
    RunId,
    RunStatus,
    Runs,
    TeamReviewer,
    UserReviewer,
    UserType,
    isPosition,
} from "~/domains/orchestration/flows/types"

const adaptMetadata = (metadata: Record<string, unknown>) => {
    const defaultPosition: XYPosition = { x: 0, y: 0 }

    return {
        position: isPosition(metadata?.position) ? metadata.position : defaultPosition,
        additionalInformation: metadata?.additionalInformation as string | undefined,
    }
}

const adaptReviewerFromApi = (reviewer: ApiReviewer): Reviewer => {
    if ("userId" in reviewer) {
        return {
            userId: reviewer.userId,
            type: UserType.USER,
        } as UserReviewer
    }
    return {
        teamId: reviewer.teamId,
        type: UserType.TEAM,
    } as TeamReviewer
}

const adaptCommonNodeProperties = (node: ApiFlowNode): Node => {
    return {
        slug: node.slug,
        name: node.name || "",
        metadata: adaptMetadata(node.metadata),
    }
}

const adaptExpressionFromApi = (value: string) => value.replace(/'/g, "").replace(/\\/g, "")

const adaptTransactionTypeFromApi = (value: string) => {
    const adaptedValue = adaptExpressionFromApi(value)
    const transactionType = TRANSACTION_TYPES.find((type) => type === adaptedValue)
    return transactionType || null
}

const adaptNodeFromApi = (node: ApiFlowNode): FlowNode | null => {
    switch (node.type) {
        case ApiNodeType.EVENT_TRIGGER_NODE:
            return {
                ...adaptCommonNodeProperties(node),
                objectType: node.objectType as unknown as ObjectType,
                objectEvent: node.objectEvent as unknown as ObjectEvent,
                type: node.type as unknown as NodeType.EVENT_TRIGGER_NODE,
                filter: node.filter ? node.filter : { conditions: [] },
                nextNode: node.nextNode || null,
            }
        case ApiNodeType.IF_NODE:
            return {
                ...adaptCommonNodeProperties(node),
                type: node.type as unknown as NodeType.IF_NODE,
                condition: node.condition,
                nextIfSuccess: node.nextIfTrue || null,
                nextIfFailure: node.nextIfFalse || null,
            }
        case ApiNodeType.SET_PARTNERSHIP_FIELD_NODE:
            return {
                ...adaptCommonNodeProperties(node),
                type: node.type as unknown as NodeType.SET_PARTNERSHIP_FIELD_NODE,
                partnershipId: node.partnershipId,
                fieldToUpdate: node.fieldToUpdate,
                valueToSet: node.valueToSet,
                nextNode: node.nextNode || null,
            }
        case ApiNodeType.CHECK_NODE:
            return {
                ...adaptCommonNodeProperties(node),
                type: node.type as unknown as NodeType.CHECK_NODE,
                objectId: node.objectId,
                objectType: node.objectType as unknown as ObjectType,
                reviewers: (node.reviewers || []).map(adaptReviewerFromApi),
                passThreshold: node.passThreshold,
                refuseThreshold: node.refuseThreshold,
                nextIfSuccess: node.nextIfPassed || null,
                nextIfFailure: node.nextIfRefused || null,
            }
        case ApiNodeType.SEND_EMAIL_NODE:
            return {
                ...adaptCommonNodeProperties(node),
                type: node.type as unknown as NodeType.SEND_EMAIL_NODE,
                subject: node.subject,
                recipientAddresses: node.recipientAddresses,
                body: node.body,
                nextNode: node.nextNode || null,
            }
        case ApiNodeType.ADD_TO_BUDGET_NODE:
            return {
                ...adaptCommonNodeProperties(node),
                type: node.type as unknown as NodeType.ADD_TO_BUDGET_NODE,
                amount: node.amount,
                budgetId: adaptExpressionFromApi(node.budgetId),
                transactionId: node.transactionId,
                transactionType: adaptTransactionTypeFromApi(node.transactionType),
                failIfOverbudget: node.failIfOverbudget,
                currency: node.currency || "",
                nextNode: node.nextNode || null,
            }
        case ApiNodeType.INVOICE_TO_PURCHASE_ORDER_MATCH_NODE:
            return {
                ...adaptCommonNodeProperties(node),
                type: node.type as unknown as NodeType.INVOICE_TO_PURCHASE_ORDER_MATCH_NODE,
                invoiceId: node.invoiceId,
                nextIfSuccess: node.nextIfMatched || null,
                nextIfFailure: node.nextIfUnmatched || null,
                metadata: adaptMetadata(node.metadata),
            }
        case ApiNodeType.SET_INVOICE_LIFECYCLE_STATUS_NODE:
            return {
                ...adaptCommonNodeProperties(node),
                type: node.type as unknown as NodeType.SET_INVOICE_LIFECYCLE_STATUS_NODE,
                invoiceId: node.invoiceId,
                statusToSet: node.statusToSet,
                nextNode: node.nextNode || null,
            }
        case ApiNodeType.FITS_TO_BUDGET_NODE:
            return {
                ...adaptCommonNodeProperties(node),
                type: node.type as unknown as NodeType.FITS_TO_BUDGET_NODE,
                budgetId: node.budgetId,
                transactionId: node.transactionId,
                transactionType: node.transactionType,
                amount: node.amount,
                currency: node.currency,
                nextIfSuccess: node.nextIfFits || null,
                nextIfFailure: node.nextIfDoesNotFit || null,
            }
        default:
            return null
    }
}

// Adapt ApiFlow to Flow
export const adaptFlowFromApi = (flow: ApiFlow): Flow => {
    return {
        id: flow.id as FlowId,
        version: flow.version,
        name: flow.name,
        enabled: flow.enabled,
        archived: flow.archived,
        createdAt: flow.createdAt,
        nodes: flow.nodes.map(adaptNodeFromApi).filter(Boolean) as FlowNode[],
        author: flow.author,
    }
}

// Adapt ApiFlows to Flows
export const adaptFlowsFromApi = (flow: ApiFlows): Flows => {
    return {
        flows: flow.flows.map((f: ApiFlowItem) => ({
            id: f.id as FlowId,
            version: f.version,
            name: f.name,
            enabled: f.enabled,
            archived: f.archived,
            createdAt: f.createdAt,
        })),
    }
}

// Adapt ApiRuns to Runs
export const adaptRunsFromApi = (run: ApiRuns): Runs => {
    return {
        runs: run.runs.map((r) => ({
            id: r.id as RunId,
            status: r.status as unknown as RunStatus,
            startedAt: r.startedAt,
            finishedAt: r.finishedAt || null,
        })),
    }
}

// Adapt ApiRun to Run
export const adaptRunFromApi = (run: ApiRun): Run => {
    return {
        id: run.id as RunId,
        flowId: run.flowId as FlowId,
        flowVersion: run.flowVersion,
        status: run.status as unknown as RunStatus,
        state: {
            pathTaken: run.state.pathTaken,
        },
        error: run.error,
        startedAt: run.startedAt,
        finishedAt: run.finishedAt || null,
    }
}
