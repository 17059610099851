import { Skeleton, Stack } from "@mui/material"
import React from "react"
import { ReactElement } from "react"

import ArrowDown from "~/assets/images/arrow-down-bold-duotone.svg"
import ArrowUp from "~/assets/images/arrow-up-bold-duotone.svg"
import { Card } from "~/components"
import { ItemLabel } from "~/components/ItemLabel/ItemLabel"

interface DashboardMetricsCardProps {
    isLoading: boolean
    percentage: number
    metricValue: number
    heading: string
    percentageLabel: ReactElement | string
    valueLabel: ReactElement | string
}

export const DashboardMetricsCard = ({
    isLoading,
    percentage,
    metricValue,
    heading,
    percentageLabel,
    valueLabel,
}: DashboardMetricsCardProps) => {
    return (
        <Card title={heading} className="dashboard-metrics-card">
            {isLoading ? (
                <div data-testid="card-loader">
                    <Skeleton variant="text" sx={{ height: "40px" }} />
                    <Skeleton variant="text" sx={{ height: "40px" }} />
                </div>
            ) : (
                <Stack gap={1}>
                    <Stack direction="row" alignItems="center">
                        <img src={percentage > 0 ? ArrowUp : ArrowDown} />
                        <ItemLabel>
                            {percentage.toFixed(1)}% {percentageLabel}
                        </ItemLabel>
                    </Stack>
                    <p className="dashboard-metrics-card-current">
                        <span className="dashboard-metrics-card-currentValue">{metricValue}</span> {valueLabel}
                    </p>
                </Stack>
            )}
        </Card>
    )
}
