import * as t from "io-ts"
import { defineMessages } from "react-intl"

import {
    AggregatedCustomFieldValueI,
    CustomFieldValueI,
    CustomFieldValueIO,
} from "~/domains/transactions/custom-fields/types/CustomFieldValue"
import { optional } from "~/types/utils"

export const InvoiceCustomFieldsIO = t.type({
    shipToCompanyName: CustomFieldValueIO,
    shipToCompanyAddressLine1: CustomFieldValueIO,
    shipToCompanyAddressLine2: CustomFieldValueIO,
    shipToCompanyCity: CustomFieldValueIO,
    shipToCompanyState: CustomFieldValueIO,
    shipToCompanyZipCode: CustomFieldValueIO,
    shipToCompanyCountry: CustomFieldValueIO,
    shipToCompanyVatNumber: CustomFieldValueIO,
    shipToCompanyContact: CustomFieldValueIO,
    billToRef: CustomFieldValueIO,
    soldToRef: CustomFieldValueIO,
    salesConditions: CustomFieldValueIO,
    printDate: CustomFieldValueIO,
    supplierCommercialContact: CustomFieldValueIO,
    supplierAccountingContact: CustomFieldValueIO,
    modeOfTransport: CustomFieldValueIO,
    creditTerms: CustomFieldValueIO,
    orderNumber: CustomFieldValueIO,
    deliveryNote: CustomFieldValueIO,
    nbPack: CustomFieldValueIO,
    itemsByPack: CustomFieldValueIO,
    netWeight: CustomFieldValueIO,
    grossWeight: CustomFieldValueIO,
    weightUnitOfMeasure: CustomFieldValueIO,
    vatExonerationReason: CustomFieldValueIO, // Optional if there is no 0% vat rate on the invoice
    invoiceNotes: CustomFieldValueIO,
})

export const InvoiceLineCustomFieldsIO = t.type({
    linePurchaseOrder: optional(CustomFieldValueIO),
    lineCustomerItem: optional(CustomFieldValueIO),
    lineCustomsNotice: optional(CustomFieldValueIO),
})

export type InvoiceCustomFieldsI = t.TypeOf<typeof InvoiceCustomFieldsIO>
export type InvoiceLineCustomFieldsI = t.TypeOf<typeof InvoiceLineCustomFieldsIO> & {
    linePurchaseOrder?: CustomFieldValueI
    lineCustomsNotice?: CustomFieldValueI
}

export interface InputConfigI {
    name: keyof InvoiceCustomFieldsI
    type: "text" | "textarea"
    isRequired: boolean
}

export const getInputConfig = (): InputConfigI[] => {
    return [
        { name: "shipToCompanyName", type: "text", isRequired: true },
        { name: "shipToCompanyAddressLine1", type: "text", isRequired: false },
        { name: "shipToCompanyAddressLine2", type: "text", isRequired: false },
        { name: "shipToCompanyCity", type: "text", isRequired: false },
        { name: "shipToCompanyState", type: "text", isRequired: false },
        { name: "shipToCompanyZipCode", type: "text", isRequired: true },
        { name: "shipToCompanyCountry", type: "text", isRequired: true },
        { name: "shipToCompanyVatNumber", type: "text", isRequired: false },
        { name: "shipToCompanyContact", type: "text", isRequired: false },
        { name: "billToRef", type: "text", isRequired: true },
        { name: "soldToRef", type: "text", isRequired: true },
        { name: "salesConditions", type: "text", isRequired: true },
        { name: "printDate", type: "text", isRequired: true },
        { name: "supplierCommercialContact", type: "text", isRequired: true },
        { name: "supplierAccountingContact", type: "text", isRequired: true },
        { name: "modeOfTransport", type: "text", isRequired: true },
        { name: "creditTerms", type: "text", isRequired: true },
        { name: "orderNumber", type: "text", isRequired: true },
        { name: "deliveryNote", type: "text", isRequired: true },
        { name: "nbPack", type: "text", isRequired: true },
        { name: "itemsByPack", type: "text", isRequired: true },
        { name: "netWeight", type: "text", isRequired: true },
        { name: "grossWeight", type: "text", isRequired: true },
        { name: "weightUnitOfMeasure", type: "text", isRequired: true },
        { name: "vatExonerationReason", type: "text", isRequired: true }, // Optional if there is no 0% vat rate on the invoice
        { name: "invoiceNotes", type: "textarea", isRequired: false },
    ]
}

export interface InputLineConfigI {
    name: keyof InvoiceLineCustomFieldsI
    type: "text" | "textarea"
    required: boolean
}

export const inputLineConfig: InputLineConfigI[] = [
    { name: "linePurchaseOrder", type: "text", required: false },
    { name: "lineCustomerItem", type: "text", required: false },
    { name: "lineCustomsNotice", type: "text", required: false },
]

export const transformInvoiceCustomFieldsToArrayOfNotEmptyFields = (
    record: InvoiceCustomFieldsI | null,
    notEmpty: boolean = true
): Array<CustomFieldValueI> => {
    if (!record) return []

    return Object.entries(record).reduce((acc, [key, value]) => {
        if (!notEmpty || value) {
            acc.push({ ...value, name: key as keyof InvoiceCustomFieldsI })
        }
        return acc
    }, [] as Array<CustomFieldValueI>)
}

export const transformInvoiceLineCustomFieldsToArrayOfNotEmptyFields = (
    record: InvoiceLineCustomFieldsI
): Array<CustomFieldValueI> => {
    return Object.entries(record).reduce((acc, [key, value]) => {
        if (value) {
            acc.push({ ...value, name: key as keyof InvoiceLineCustomFieldsI })
        }
        return acc
    }, [] as Array<CustomFieldValueI>)
}

const invoiceCustomFieldKeys = Object.keys(InvoiceCustomFieldsIO.props) as Array<keyof InvoiceCustomFieldsI>
const invoiceLineCustomFieldKeys = Object.keys(InvoiceLineCustomFieldsIO.props) as Array<keyof InvoiceLineCustomFieldsI>

export const isInvoiceField = (name: string): name is keyof InvoiceCustomFieldsI =>
    invoiceCustomFieldKeys.includes(name as keyof InvoiceCustomFieldsI)
export const isInvoiceLineField = (name: string): name is keyof InvoiceLineCustomFieldsI =>
    invoiceLineCustomFieldKeys.includes(name as keyof InvoiceLineCustomFieldsI)

type InvoiceCustomFieldsReturn = {
    invoiceCustomFields: InvoiceCustomFieldsI
    invoiceLinesCustomFields: Record<string, InvoiceLineCustomFieldsI>
}
export const aggregatedCustomFieldsToInvoiceCustomFields = (
    aggregatedCustomFieldValues: AggregatedCustomFieldValueI,
    invoiceId: string
): InvoiceCustomFieldsReturn => {
    const invoiceCustomFields = {} as InvoiceCustomFieldsI

    const invoiceLinesCustomFields = Object.values(aggregatedCustomFieldValues).reduce((acc, customFieldGroup) => {
        customFieldGroup.forEach((customField) => {
            const { name, objectId } = customField
            if (isInvoiceField(name) && objectId === invoiceId) {
                invoiceCustomFields[name] = customField
            } else if (isInvoiceLineField(name) && objectId.startsWith(invoiceId)) {
                if (!acc[objectId]) {
                    acc[objectId] = {} as InvoiceLineCustomFieldsI
                }
                acc[objectId][name] = customField
            }
        })
        return acc
    }, {} as Record<string, InvoiceLineCustomFieldsI>)

    return {
        invoiceCustomFields,
        invoiceLinesCustomFields,
    }
}

export const customFieldsLabelMessages = defineMessages({
    title: { id: "invoice.customFields.title", defaultMessage: "Custom fields" },
    shipToCompanyName: { id: "invoice.customFields.shipToCompanyName", defaultMessage: "Ship to company name" },
    shipToCompanyAddressLine1: {
        id: "invoice.customFields.shipToCompanyAddressLine1",
        defaultMessage: "Ship to company address line 1",
    },
    shipToCompanyAddressLine2: {
        id: "invoice.customFields.shipToCompanyAddressLine2",
        defaultMessage: "Ship to company address line 2",
    },
    shipToCompanyCity: { id: "invoice.customFields.shipToCompanyCity", defaultMessage: "Ship to company city" },
    shipToCompanyState: { id: "invoice.customFields.shipToCompanyState", defaultMessage: "Ship to company state" },
    shipToCompanyZipCode: {
        id: "invoice.customFields.shipToCompanyZipCode",
        defaultMessage: "Ship to company zip code",
    },
    shipToCompanyCountry: {
        id: "invoice.customFields.shipToCompanyCountry",
        defaultMessage: "Ship to company country",
    },
    shipToCompanyVatNumber: {
        id: "invoice.customFields.shipToCompanyVatNumber",
        defaultMessage: "Ship to company VAT number",
    },
    shipToCompanyContact: {
        id: "invoice.customFields.shipToCompanyContact",
        defaultMessage: "Ship to company contact",
    },
    billToRef: { id: "invoice.customFields.billToRef", defaultMessage: "Bill to reference" },
    soldToRef: { id: "invoice.customFields.soldToRef", defaultMessage: "Sold to reference" },
    salesConditions: { id: "invoice.customFields.salesConditions", defaultMessage: "Sales conditions" },
    printDate: { id: "invoice.customFields.printDate", defaultMessage: "Print date" },
    supplierCommercialContact: {
        id: "invoice.customFields.supplierCommercialContact",
        defaultMessage: "Supplier commercial contact",
    },
    supplierAccountingContact: {
        id: "invoice.customFields.supplierAccountingContact",
        defaultMessage: "Supplier accounting contact",
    },
    modeOfTransport: { id: "invoice.customFields.modeOfTransport", defaultMessage: "Mode of transport" },
    creditTerms: { id: "invoice.customFields.creditTerms", defaultMessage: "Credit terms" },
    orderNumber: { id: "invoice.customFields.orderNumber", defaultMessage: "Order number" },
    deliveryNote: { id: "invoice.customFields.deliveryNote", defaultMessage: "Delivery note" },
    nbPack: { id: "invoice.customFields.nbPack", defaultMessage: "Number of packs" },
    itemsByPack: { id: "invoice.customFields.itemsByPack", defaultMessage: "Items per pack" },
    netWeight: { id: "invoice.customFields.netWeight", defaultMessage: "Net weight" },
    grossWeight: { id: "invoice.customFields.grossWeight", defaultMessage: "Gross weight" },
    weightUnitOfMeasure: { id: "invoice.customFields.weightUnitOfMeasure", defaultMessage: "Weight unit of measure" },
    vatExonerationReason: { id: "invoice.customFields.vatExonerationReason", defaultMessage: "VAT exoneration reason" },
    invoiceNotes: { id: "invoice.customFields.invoiceNotes", defaultMessage: "Invoice notes" },
})

export const lineCustomFieldsLabelMessages = defineMessages({
    linePurchaseOrder: {
        id: "invoice.line.customFields.linePurchaseOrder",
        defaultMessage: "Customer order number",
    },
    lineCustomerItem: {
        id: "invoice.line.customFields.lineCustomerItem",
        defaultMessage: "Customer item reference",
    },
    lineCustomsNotice: {
        id: "invoice.line.customFields.lineCustomsNotice",
        defaultMessage: "Customs notice",
    },
})

export const updateVatExonerationReasonInputConfig = (hasReason: boolean): InputConfigI[] => {
    return getInputConfig().map((input) => {
        if (input.name === "vatExonerationReason") {
            return { ...input, isRequired: hasReason }
        }
        return input
    })
}
