import { MenuItem, TextField, styled } from "@mui/material"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs, { Dayjs } from "dayjs"
import { useState } from "react"
import { FormattedMessage, defineMessages, useIntl } from "react-intl"

import { Button, CountrySelector, CurrencySelector, Loader } from "~/components"
import { useFormValidation } from "~/domains/payment/payment-method-details/hooks"
import { CardCategory, CardFormState } from "~/domains/payment/payment-method-details/types"
import { cardPaymentMethodFormValidationSchema } from "~/domains/payment/payment-method-details/utils/formsValidationSchemas"
import { CountryCode, CurrencyCodes, CurrencyI } from "~/types"

const messages = defineMessages({
    name: {
        id: "payment.cardPaymentMethodForm.name",
        defaultMessage: "Card alias",
    },
    nameOnCard: {
        id: "payment.cardPaymentMethodForm.nameOnCard",
        defaultMessage: "Name on card",
    },
    currency: {
        id: "payment.cardPaymentMethodForm.currency",
        defaultMessage: "Currency",
    },
    country: {
        id: "payment.cardPaymentMethodForm.country",
        defaultMessage: "Country",
    },
    cardNumber: {
        id: "payment.cardPaymentMethodForm.cardNumber",
        defaultMessage: "Card number",
    },
    cardExpirationDate: {
        id: "payment.cardPaymentMethodForm.cardExpirationDate",
        defaultMessage: "Card expiration date",
    },
    cardCategory: {
        id: "payment.cardPaymentMethodForm.cardCategory",
        defaultMessage: "Card type",
    },
    credit: {
        id: "payment.paymentMethodDetailsList.credit",
        defaultMessage: "Credit",
    },
    debit: {
        id: "payment.paymentMethodDetailsList.debit",
        defaultMessage: "Debit",
    },
    prepaid: {
        id: "payment.paymentMethodDetailsList.prepaid",
        defaultMessage: "Prepaid",
    },
    saveButton: {
        id: "payment.cardPaymentMethodForm.saveButton",
        defaultMessage: "Save",
    },
    cancelButton: {
        id: "payment.cardPaymentMethodForm.cancelButton",
        defaultMessage: "Cancel",
    },
})

const Form = styled("form")({
    display: "flex",
    flexDirection: "column",
    gap: "16px",
})

const FormActions = styled("div")({
    display: "flex",
    justifyContent: "flex-end",
    gap: "12px",
})

const initialState: CardFormState = {
    name: "",
    currency: CurrencyCodes.EUR,
    country: null,
    cardCategory: null,
    cardExpirationDate: null,
    cardNumber: "",
}

interface CardPaymentMethodFormProps {
    loading?: boolean
    selectedItem: CardFormState | null
    onCancel: (resetForm: () => void) => void
    onSubmit: (formData: CardFormState, resetForm: () => void) => void
}

export const CardPaymentMethodForm = ({
    selectedItem,
    onSubmit,
    onCancel,
    loading = false,
}: CardPaymentMethodFormProps) => {
    const [formData, setFormData] = useState<CardFormState>(selectedItem ?? initialState)
    const { errors, validate } = useFormValidation<CardFormState>(formData, cardPaymentMethodFormValidationSchema)
    const { formatMessage, locale } = useIntl()

    const resetForm = () => {
        setFormData(initialState)
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        e.stopPropagation()

        const isValid = validate()

        if (onSubmit && isValid) {
            onSubmit(formData, resetForm)
        }
    }

    const handleCurrencyChange = (currency: CurrencyI | null) => {
        setFormData({
            ...formData,
            currency: currency?.code ?? CurrencyCodes.EUR,
        })
    }

    const handleCountryChange = (countryCode: CountryCode | null) => {
        setFormData({
            ...formData,
            country: countryCode,
        })
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        })
    }

    const handleCardNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.replace(/\D/g, "")

        if (value.length <= 10) {
            setFormData({
                ...formData,
                cardNumber: value,
            })
        }
    }

    const handleChangeExpirationDate = (date: Dayjs | null) => {
        setFormData({
            ...formData,
            cardExpirationDate: date ?? null,
        })
    }

    const handleCancel = () => {
        onCancel(resetForm)
    }

    const formatCardNumber = () => {
        const { cardNumber } = formData
        const firstFour = cardNumber.slice(0, 4)
        const nextTwo = cardNumber.slice(4, 6)
        const lastFour = cardNumber.slice(6)

        return `${firstFour}${cardNumber.length > 4 ? " " : ""}${nextTwo}${
            cardNumber.length > 6 ? "xx xxxx " : ""
        }${lastFour}`
    }

    return (
        <Form noValidate onSubmit={handleSubmit}>
            <TextField
                label={formatMessage(messages.cardNumber)}
                placeholder="1234 56xx xxxx 1234"
                name="cardNumber"
                value={formatCardNumber()}
                disabled={loading}
                error={!!errors.cardNumber}
                helperText={errors.cardNumber}
                variant="filled"
                inputProps={{ maxLength: 19 }}
                required
                fullWidth
                autoComplete="off"
                onChange={handleCardNumberChange}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
                <DatePicker
                    views={["year", "month"]}
                    label={formatMessage(messages.cardExpirationDate)}
                    minDate={dayjs()}
                    format="MM/YY"
                    disabled={loading}
                    value={formData.cardExpirationDate}
                    slots={{
                        textField: TextField,
                    }}
                    slotProps={{
                        textField: {
                            variant: "filled",
                            fullWidth: true,
                            required: true,
                            error: !!errors.cardExpirationDate,
                            helperText: errors.cardExpirationDate,
                        },
                    }}
                    onChange={handleChangeExpirationDate}
                />
            </LocalizationProvider>
            <TextField
                label={formatMessage(messages.name)}
                name="name"
                value={formData.name}
                disabled={loading}
                variant="filled"
                fullWidth
                autoComplete="off"
                onChange={handleChange}
            />
            <TextField
                label={formatMessage(messages.cardCategory)}
                name="cardCategory"
                value={formData.cardCategory}
                disabled={loading}
                variant="filled"
                fullWidth
                autoComplete="off"
                select
                onChange={handleChange}
            >
                {Object.values(CardCategory).map((key) => (
                    <MenuItem key={key} value={key}>
                        <FormattedMessage {...messages[key]} />
                    </MenuItem>
                ))}
            </TextField>
            <CountrySelector
                label={formatMessage(messages.country)}
                value={formData.country}
                disabled={loading}
                onChange={handleCountryChange}
                variant="filled"
            />
            <CurrencySelector
                editMode
                label={formatMessage(messages.currency)}
                value={formData.currency}
                disabled={loading}
                variant="filled"
                onChange={handleCurrencyChange}
            />
            <FormActions>
                <Button type="neutral" onClick={handleCancel}>
                    <FormattedMessage {...messages.cancelButton} />
                </Button>
                <Button type="primary" buttonType="submit">
                    {loading ? <Loader small /> : <FormattedMessage {...messages.saveButton} />}
                </Button>
            </FormActions>
        </Form>
    )
}
