import { Grid, IconButton, Tooltip, styled } from "@mui/material"
import React, { ReactNode, useCallback, useEffect, useRef, useState } from "react"
import { defineMessage, useIntl } from "react-intl"
import { toast } from "react-toastify"

import { useWhitePagesApi } from "~/api/whitePagesApi"
import { CountrySelector } from "~/components"
import { BoltIcon, InfoIcon } from "~/components/Icons"
import { PartnerOrganizationI } from "~/domains/transactions/book-of-relations/types"
import { CompanyI, CountryCode, EstablishmentI, OrganizationI, WhitePagesIdTypes } from "~/types"

import {
    CompanyAutocompleteField,
    CompanyAutocompleteResult,
    CompanyAutocompleteType,
    CompanyCreation,
} from "./CompanyAutocompleteField"

const errorMessage = defineMessage({
    id: "company.autocomplete.errorFetchCompanyData",
    defaultMessage: "An error occured fetching company data, please fill the registration fields manually.",
})

export const suggestionsTooltipMessage = defineMessage({
    id: "company.autocomplete.textsSuggestionsTooltip",
    defaultMessage: "What our AI found from the invoice",
})

export type CompanyResult =
    | {
          type: CompanyAutocompleteType.Organization
          value: OrganizationI | PartnerOrganizationI
      }
    | {
          type: CompanyAutocompleteType.WhitePagesResult
          value: CompanyI
      }
export type CompanyResultWithCreation =
    | CompanyResult
    | {
          type: CompanyAutocompleteType.Creation
          value: CompanyCreation
      }

type Props = {
    organizations: (OrganizationI | PartnerOrganizationI)[]
    suggestedCompanies: CompanyI[] | undefined
    countryCode: CountryCode
    setCountryCode: (countryCode: CountryCode) => void
    setCompanyLoading: React.Dispatch<React.SetStateAction<boolean>>
    groupLabel?: Partial<Record<CompanyAutocompleteType, ReactNode>>
    suggestedStrings?: string[]
} & (
    | {
          company: CompanyResult | undefined
          enableCreation?: false
          setCompany: (company: CompanyResult | undefined, establishmentToSelect?: EstablishmentI | undefined) => void
      }
    | {
          company: CompanyResultWithCreation | undefined
          enableCreation: true
          setCompany: (
              company: CompanyResultWithCreation | undefined,
              establishmentToSelect?: EstablishmentI | undefined
          ) => void
      }
)

const SuggestedStringContainer = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    gap: "8px",
    marginTop: "12px",
})

const SuggestedString = styled("span")({
    cursor: "pointer",
    height: "22px",
    padding: "0px 4px",
    background: "var(--color-violet-lighter)",
    display: "inline-flex",
    gap: "4px",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    color: "var(--color-grey)",
    alignItems: "center",
})

const SuggestedStringContent = styled("span")({
    flex: "1 1 auto",
    overflow: "hidden",
    textOverflow: "ellipsis",
})

const StyledBoltIcon = styled(BoltIcon)({
    flex: "0 0 18px",
})

export const CompanyAutocomplete: React.FC<Props> = ({
    setCompany,
    company,
    organizations,
    suggestedCompanies,
    enableCreation,
    countryCode,
    setCountryCode,
    setCompanyLoading,
    groupLabel,
    suggestedStrings,
}) => {
    const { formatMessage } = useIntl()
    const [autocompleteCompany, setAutocompleteCompany] = useState<CompanyAutocompleteResult>()
    const [inputValue, setInputValue] = useState("")
    const inputValueRef = useRef<string>()
    const whitePagesApi = useWhitePagesApi()

    useEffect(() => {
        if (countryCode === CountryCode.UNKNOWN) {
            setCountryCode(CountryCode.FR)
        }
    }, [countryCode, setCountryCode])

    useEffect(() => {
        if (suggestedCompanies?.length === 1) {
            const company = suggestedCompanies[0]

            setAutocompleteCompany({
                type: CompanyAutocompleteType.WhitePagesResult,
                value: {
                    id: company.id,
                    idType:
                        company.countryCode === CountryCode.FR
                            ? WhitePagesIdTypes.FRENCH_SIRET
                            : WhitePagesIdTypes.DUNS,
                    name: company.name,
                    city: company.primaryAddress?.city ?? "",
                    primaryAddress: company.primaryAddress,
                    isOutOfBusiness: false,
                    countryCode: company.countryCode,
                },
            })
            setCompany({ type: CompanyAutocompleteType.WhitePagesResult, value: suggestedCompanies[0] })
        }
    }, [suggestedCompanies])

    useEffect(() => {
        const fetchCompany = async () => {
            if (autocompleteCompany?.type === CompanyAutocompleteType.WhitePagesResult) {
                setCompanyLoading(true)
                if (autocompleteCompany.value.idType === WhitePagesIdTypes.FRENCH_SIRET) {
                    try {
                        const company = await whitePagesApi.fetchCompanyBySiren(autocompleteCompany.value.id)
                        const establishmentToSelect = company.establishments?.find(
                            (establishment) => establishment.id === inputValueRef.current
                        )
                        setCompany(
                            { type: CompanyAutocompleteType.WhitePagesResult, value: company },
                            establishmentToSelect
                        )
                        setCountryCode(company.countryCode)
                    } catch (e) {
                        toast.error(formatMessage(errorMessage))
                        setCompany({
                            type: CompanyAutocompleteType.WhitePagesResult,
                            value: {
                                id: autocompleteCompany.value.id,
                                idType: autocompleteCompany.value.idType,
                                name: autocompleteCompany.value.name,
                                taxId: "",
                                countryCode: autocompleteCompany.value.countryCode,
                                registrationNumbers: [],
                            },
                        })
                        setCountryCode(CountryCode.FR)
                    }
                } else {
                    try {
                        const company = await whitePagesApi.fetchCompanyByDunsNumber(autocompleteCompany.value.id)
                        setCompany({ type: CompanyAutocompleteType.WhitePagesResult, value: company })
                        setCountryCode(company.countryCode)
                    } catch (e) {
                        toast.error(formatMessage(errorMessage))
                        setCompany({
                            type: CompanyAutocompleteType.WhitePagesResult,
                            value: {
                                id: autocompleteCompany.value.id,
                                idType: autocompleteCompany.value.idType,
                                name: autocompleteCompany.value.name,
                                taxId: "",
                                countryCode: autocompleteCompany.value.countryCode,
                                registrationNumbers: [],
                            },
                        })
                        setCountryCode(CountryCode.FR)
                    }
                }
                setCompanyLoading(false)
            } else if (autocompleteCompany?.type === CompanyAutocompleteType.Organization) {
                setCompany({ type: CompanyAutocompleteType.Organization, value: autocompleteCompany.value })
                setCountryCode(autocompleteCompany.value?.registration.countryCode ?? CountryCode.UNKNOWN)
            }
        }
        fetchCompany()
    }, [autocompleteCompany, formatMessage])

    const onAutocompleteChange = useCallback(
        (value: CompanyAutocompleteResult | undefined, inputValue: string) => {
            if (value?.type === CompanyAutocompleteType.WhitePagesResult) {
                inputValueRef.current = inputValue
            }
            setAutocompleteCompany(value)
            setCompany(undefined)
        },
        [setAutocompleteCompany, setCompany]
    )

    const onCountryCodeChange = useCallback(
        (code: CountryCode | null) => {
            if (code && countryCode !== code) {
                setCountryCode(code)
                setAutocompleteCompany(undefined)
                setInputValue("")
            }
        },
        [setCountryCode, setAutocompleteCompany]
    )

    const onSuggestedStringClick = useCallback(
        (suggestedString: string) => () => setInputValue(suggestedString),
        [setInputValue]
    )

    const renderSuggestedString = useCallback(
        (suggestedString: string, i: number) => (
            <SuggestedString key={i} onClick={onSuggestedStringClick(suggestedString)}>
                <SuggestedStringContent>{suggestedString}</SuggestedStringContent>
                <StyledBoltIcon />
            </SuggestedString>
        ),
        [onSuggestedStringClick]
    )

    return (
        <>
            <Grid container direction="column" gap={1}>
                <CountrySelector required value={countryCode} onChange={onCountryCodeChange} />
                <CompanyAutocompleteField
                    company={company}
                    countryCode={countryCode}
                    value={autocompleteCompany}
                    onChange={onAutocompleteChange}
                    organizations={organizations}
                    enableCreation={enableCreation}
                    groupLabel={groupLabel}
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                />
            </Grid>
            {suggestedStrings && suggestedStrings.length > 0 && (
                <SuggestedStringContainer>
                    {suggestedStrings.map(renderSuggestedString)}
                    <Tooltip title={formatMessage(suggestionsTooltipMessage)}>
                        <IconButton sx={{ margin: "0 -8px" }}>
                            <InfoIcon color="var(--color-silver)" />
                        </IconButton>
                    </Tooltip>
                </SuggestedStringContainer>
            )}
        </>
    )
}
