import { Grid } from "@mui/material"
import { FC, useState } from "react"
import { defineMessages, useIntl } from "react-intl"
import { generatePath, useNavigate } from "react-router-dom"

import { ConfirmModal } from "~/components/ConfirmModal"
import { eventsMock } from "~/domains/transactions/invoices-v1/api/mocks/eventsMock"
import { MY_DOCUMENTS_ROUTE } from "~/features/account/routes"
import { Actions, ModalRequest } from "~/features/buyer/components"
import { useSegment } from "~/hooks"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { useInvoiceValidationActions, useRejectInvoice } from "~/store/invoice/hooks"
import { useRemoveInvoiceApproval } from "~/store/invoice/hooks/useRemoveInvoiceApproval"
import { invoiceActions, selectInvoiceUserIds } from "~/store/invoice/invoiceSlice"
import { useGetAllUsersQuery } from "~/store/users/hooks"
import { useFetchInvoiceWorkflows } from "~/store/workflow/hooks/useFetchInvoiceWorkflows"
import { InvoiceI } from "~/types"
import { InvoiceRequestI, ViewTypeI } from "~/types"

const messages = defineMessages({
    confirmRemoveApprovalTitle: {
        id: "supplier.editInvoice.page.confirmRemoveApprovalTitle",
        defaultMessage: "Are you sure?",
    },
    confirmRemoveApprovalMessage: {
        id: "supplier.editInvoice.page.confirmRemoveApprovalMessage",
        defaultMessage: "This will reset all user approvals of this invoice. Do you wish to continue?",
    },
})

interface InvoiceActionsProps {
    invoice: InvoiceI
}

export const InvoiceActions: FC<InvoiceActionsProps> = ({ invoice }) => {
    const { formatMessage } = useIntl()
    const navigate = useNavigate()
    const { segmentTrack } = useSegment()

    const dispatch = useAppDispatch()
    const userIds = useAppSelector(selectInvoiceUserIds)

    const { users } = useGetAllUsersQuery(userIds)
    //const events = useAppSelector(selectInvoiceEvents)
    const { withinWorkflow, currentUserCanValidate, approve, refuse, currentUserApproval } =
        useInvoiceValidationActions(invoice.payerId, invoice.id)
    const rejectInvoiceAction = useRejectInvoice()
    const removeApprovalAction = useRemoveInvoiceApproval()
    const { fetchInvoiceChecks } = useFetchInvoiceWorkflows(invoice.payerId, invoice.id)

    const [displayModal, setDisplayModal] = useState(false)
    const [, setDisplayModalValidation] = useState(false)
    const [modalConfirmRemoveApprovalVisible, setModalConfirmRemoveApprovalVisible] = useState(false)
    const [, setModalConfirmUnmarkAsPaidVisible] = useState(false)

    const involvedUsers = users.filter((person) =>
        invoice?.involvedPeople.map((person) => person.userId).includes(person.id)
    )

    const isCurrentUserReviewer = withinWorkflow && currentUserCanValidate
    const hasApprovedOrRefused = currentUserApproval !== undefined

    const isReviewable = isCurrentUserReviewer && !hasApprovedOrRefused

    const handleDownloadPDF = () => {
        if (invoice?.fileLinks?.pdfLink) {
            window.location.href = invoice.fileLinks.pdfLink
        }
    }

    const handleApprove = () => approve && approve()

    const handleReject = () => refuse && refuse()

    const preValidation = () => setDisplayModalValidation(true)

    const handleMoveToTrash = () => {
        if (!invoice.payerId) return

        rejectInvoiceAction(ViewTypeI.buyer, invoice.id, invoice.payerId).then((result) => {
            result && navigate(generatePath(MY_DOCUMENTS_ROUTE, { view: ViewTypeI.buyer }))
        })
    }

    const handleRemoveApproval = () => setModalConfirmRemoveApprovalVisible(true)

    const hideConfirmRemoveApproval = () => setModalConfirmRemoveApprovalVisible(false)

    const handleUnmarkAsPaid = () => setModalConfirmUnmarkAsPaidVisible(true)

    const handleSendRequest = async (request: InvoiceRequestI) => {
        segmentTrack("Request Sent", {
            invoice_id: invoice.id,
        })
        dispatch(
            invoiceActions.sendRequest({
                invoiceId: invoice.id,
                subject: request.subject,
                body: request.body,
                userId: request.userId,
            })
        )
    }

    const handleConfirmRemoveApproval = async () => {
        if (!invoice?.payerId) return false

        await removeApprovalAction(invoice.id, invoice.payerId)
        fetchInvoiceChecks()
        return true
    }

    return (
        <Grid item className="actions">
            <Actions
                organizationId={invoice.payerId}
                invoice={invoice}
                status={invoice.status}
                handleDownloadPDF={handleDownloadPDF}
                handleValidate={preValidation}
                handleApprove={handleApprove}
                handleReject={handleReject}
                handleMoveToTrash={handleMoveToTrash}
                handleRemoveApproval={handleRemoveApproval}
                handleUnmarkAsPaid={handleUnmarkAsPaid}
                setDisplayModal={setDisplayModal}
                className="actions-header"
                events={eventsMock}
                isReviewable={isReviewable}
            />
            <ModalRequest
                displayModal={displayModal}
                setDisplayModal={setDisplayModal}
                onConfirm={handleSendRequest}
                invoiceId={invoice.id}
                involvedPeople={involvedUsers}
            />
            <ConfirmModal
                title={formatMessage(messages.confirmRemoveApprovalTitle)}
                open={modalConfirmRemoveApprovalVisible}
                close={hideConfirmRemoveApproval}
                onConfirm={handleConfirmRemoveApproval}
            >
                {formatMessage(messages.confirmRemoveApprovalMessage)}
            </ConfirmModal>
        </Grid>
    )
}
