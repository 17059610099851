import { useState } from "react"
import { defineMessages, useIntl } from "react-intl"

import { Loader } from "~/components"
import { useGetPaymentMethodDetailsQuery } from "~/domains/payment/payment-method-details/api/paymentMethodDetailsApi"
import { AddButon, BorderCard, Container, Title } from "~/domains/payment/payment-method-details/components/"
import { PaymentMethodDetailsManagement } from "~/domains/payment/payment-method-details/components/"
import { PaymentMethodDetailsSelector } from "~/domains/payment/payment-method-details/components/DocumentPaymentSection/PaymentMethodDetailsSelector"
import { PaymentMethodType } from "~/domains/payment/payment-methods/types"

const messages = defineMessages({
    payer: {
        id: "payment.payerPaymentMethodDetails.payer",
        defaultMessage: "Payer",
    },
    paymentMethod: {
        id: "payment.payerPaymentMethodDetails.paymentMethod",
        defaultMessage: "Payment method",
    },
    addPaymentMethod: {
        id: "payment.payerPaymentMethodDetails.addPaymentMethod",
        defaultMessage: "+ Add a payment method",
    },
})

interface PayerPaymentMethodDetailsProps {
    payerId: string
    loading?: boolean
    label?: string
    selectedPaymentMethodDetailsId: string | null
    onPaymentMethodChanged: (id: string, paymentMethodId: string) => void
}

export const PayerPaymentMethodDetails = ({
    payerId,
    label,
    selectedPaymentMethodDetailsId,
    onPaymentMethodChanged,
    loading = false,
}: PayerPaymentMethodDetailsProps) => {
    const { formatMessage } = useIntl()
    const [open, setOpen] = useState(false)
    const { data, isLoading, isFetching } = useGetPaymentMethodDetailsQuery({ owner: payerId })
    const items = data?.items ?? []

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handlePaymentMethdDetailsChanged = (id: string, paymentMethodId?: string) => {
        onPaymentMethodChanged(id, paymentMethodId ?? "")
    }

    if (isLoading || isFetching) {
        return <Loader small />
    }

    return (
        <BorderCard>
            <Container>
                <Title>{label ?? formatMessage(messages.payer)}</Title>
                <AddButon type="title" disabled={loading} onClick={handleOpen}>
                    {formatMessage(messages.addPaymentMethod)}
                </AddButon>
            </Container>

            {items.length > 0 && (
                <PaymentMethodDetailsSelector
                    label={formatMessage(messages.paymentMethod)}
                    value={selectedPaymentMethodDetailsId}
                    items={items}
                    loading={loading}
                    onItemSelect={handlePaymentMethdDetailsChanged}
                />
            )}
            {open && (
                <PaymentMethodDetailsManagement
                    activeTab={PaymentMethodType.CARD}
                    open
                    organizationId={payerId}
                    onClose={handleClose}
                    onOpen={handleOpen}
                />
            )}
        </BorderCard>
    )
}
