import { FC } from "react"
import { defineMessages, useIntl } from "react-intl"

import { Card } from "~/components"
import { TagsSelector } from "~/domains/tags/components/TagsSelector"
import { TagObjectType } from "~/domains/tags/types"
import { selectUser } from "~/store/account/accountSlice"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganization } from "~/store/organization/organizationSlice"
import { InvoiceI } from "~/types"
import { ViewTypeI } from "~/types"

const messages = defineMessages({
    tagsTitle: { id: "purchase.orders.order.tagsTitle", defaultMessage: "Tags" },
})

interface InvoiceTagsProps {
    invoice: InvoiceI
}

export const InvoiceTags: FC<InvoiceTagsProps> = ({ invoice }) => {
    const { formatMessage } = useIntl()
    const user = useAppSelector(selectUser)
    const organization = useAppSelector(selectCurrentOrganization)

    const tagSelectorContext = {
        page: "invoice",
        viewType: ViewTypeI.buyer,
        invoice,
        organization,
        user,
    }

    return (
        <Card title={formatMessage(messages.tagsTitle)} expandable>
            <TagsSelector
                organizationId={invoice.payerId}
                objectId={invoice.id}
                userId={user.id}
                objectType={TagObjectType.INVOICE}
                context={tagSelectorContext}
            />
        </Card>
    )
}
