import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { prepareHeadersWithAuthorization } from "~/api/prepareHeaders"
import { PaymentMethod } from "~/domains/payment/payment-methods/types"
import { PaginatedResponse } from "~/domains/payment/types"

const BASE_URL = import.meta.env.VITE_API_PAYMENT_URL_V2

export const paymentMethodsApi = createApi({
    reducerPath: "paymentMethodsApi",
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL, prepareHeaders: prepareHeadersWithAuthorization }),
    endpoints: (builder) => ({
        getPaymentMethods: builder.query<PaginatedResponse<PaymentMethod>, unknown>({
            query: () => ({
                url: "/payment_method",
            }),
        }),
    }),
})

export const { useGetPaymentMethodsQuery } = paymentMethodsApi
