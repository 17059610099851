import * as t from "io-ts"

import { DocumentRelationId } from "./InvoiceRelation"

export const PurchaseOrderRelationIO = t.type({
    relationId: t.string,
    invoiceId: t.string,
})

export type PurchaseOrderRelationI = t.TypeOf<typeof PurchaseOrderRelationIO> & {
    relationId: DocumentRelationId
}
