import { useMemo } from "react"
import { MessageDescriptor, defineMessages, useIntl } from "react-intl"

import { RoleWithEntityIds } from "~/domains/identity/features/roles-permissions/types/RolesPermissions"
import { useFetchUsers } from "~/domains/identity/store/hooks/useFetchUsers"
import {
    BorderCard,
    InfoItem,
    InfoItemLabel,
    InfoItemValue,
    PartyPaymentMethodDetails,
    PaymentMethodContainer,
    Title,
} from "~/domains/payment/payment-method-details/components"
import { Payment, PaymentStatus } from "~/domains/payment/payment/types"

const messages = defineMessages({
    payer: {
        id: "payment.document.paymentDetails.payer",
        defaultMessage: "Payer",
    },
    payee: {
        id: "payment.document.paymentDetails.payee",
        defaultMessage: "Payee",
    },
    executedBy: {
        id: "payment.document.paymentDetails.executedBy",
        defaultMessage: "Executed by",
    },
    amount: {
        id: "payment.document.paymentDetails.amount",
        defaultMessage: "Amount",
    },
    status: {
        id: "payment.document.paymentDetails.status",
        defaultMessage: "Status",
    },
})

const paymentStatuses: Partial<Record<PaymentStatus, MessageDescriptor>> = {
    [PaymentStatus.PENDING]: {
        id: "payment.document.paymentDetails.status.pending",
        defaultMessage: "Pending",
    },
}

interface ActivePaymentProps {
    payment: Payment
}

interface DataItem {
    label: string
    value: string
}

export const ActivePayment = ({ payment }: ActivePaymentProps) => {
    const payload = useMemo(
        () => ({ userIds: [payment.executed_by_id] } as RoleWithEntityIds),
        [payment.executed_by_id]
    )
    const users = useFetchUsers(payload)
    const executedBy = users[0]?.fullName ?? "-"
    const { formatMessage, formatNumber } = useIntl()

    const data: DataItem[] = [
        {
            label: formatMessage(messages.executedBy),
            value: executedBy,
        },
        {
            label: formatMessage(messages.status),
            value: paymentStatuses[payment.status] ? formatMessage(paymentStatuses[payment.status]!) : "-",
        },
        {
            label: formatMessage(messages.amount),
            value:
                formatNumber(payment.amount_sent_data?.amount, {
                    style: "currency",
                    currency: payment.amount_sent_data?.currency,
                }) ?? "-",
        },
    ]

    return (
        <>
            <BorderCard>
                <PaymentMethodContainer>
                    {data.map(({ label, value }) => (
                        <InfoItem key={label}>
                            <InfoItemLabel>{label}</InfoItemLabel>
                            <InfoItemValue>{value}</InfoItemValue>
                        </InfoItem>
                    ))}
                </PaymentMethodContainer>
            </BorderCard>
            {payment.origin_cash_id && (
                <BorderCard>
                    <Title>{formatMessage(messages.payer)}</Title>
                    <PartyPaymentMethodDetails paymentMethodDetailsId={payment.origin_cash_id} />
                </BorderCard>
            )}
            {payment.destination_cash_id && (
                <BorderCard>
                    <Title>{formatMessage(messages.payee)}</Title>
                    <PartyPaymentMethodDetails paymentMethodDetailsId={payment.destination_cash_id} />
                </BorderCard>
            )}
        </>
    )
}
