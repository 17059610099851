import { isResultSuccess, Result, ResultError, ResultSuccess } from "~/core/Result"
import {
    InvoicePennylaneSynchronizationStatusI,
    InvoicePennylaneSynchronizationStatusIO,
} from "~/features/ocr/types/pennylaneSynchronizationStatus"
import { genericParser, ParsingErrorType } from "~/utils"

export const parseInvoicePennylaneSync = (
    data: unknown
): Result<InvoicePennylaneSynchronizationStatusI, ParsingErrorType> => {
    const result = genericParser(data, InvoicePennylaneSynchronizationStatusIO)
    if (isResultSuccess(result)) {
        return ResultSuccess(result.result)
    }
    return ResultError(result.error)
}
