import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit"
import { PersistConfig, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"

import { RootState } from "~/store"
import {
    AxonautCredentialsI,
    OrganizationAccountingContactI,
    OrganizationAddressI,
    OrganizationBankDetailI,
    OrganizationI,
    OrganizationId,
    OrganizationInvitationI,
    OrganizationMemberI,
    OrganizationRegistrationI,
    OrganizationRole,
    OrganizationTeamI,
    PennylaneCredentialsI,
    TeamId,
    UserId,
} from "~/types"

import { OrganizationState } from "./organizationState"

const initialState: OrganizationState = {
    organizations: {},
    loadings: {},
    errors: {},
    creatingOrganization: false,
    filters: {},

    teams: {},
    loadingTeams: {},
    errorLoadingTeams: {},
}

const organizationPersistConfig: PersistConfig<OrganizationState> = {
    key: "organization",
    storage: storage,
    whitelist: ["currentOrganizationId"],
}

export interface SaveOrganizationRegistrationPayload {
    organizationId: OrganizationId
    registration: OrganizationRegistrationI
}

export interface SaveOrganizationAddressPayload {
    organizationId: OrganizationId
    address: OrganizationAddressI
}

export interface SaveOrganizationBankDetailsPayload {
    organizationId: OrganizationId
    bankDetails: OrganizationBankDetailI
}

export interface SaveOrganizationAccountingContactPayload {
    organizationId: OrganizationId
    accountingContact: OrganizationAccountingContactI
}

export interface ProvideAxonautCredentialsPayload {
    organizationId: OrganizationId
    credentials: AxonautCredentialsI
}

export interface ProvidePennylaneCredentialsPayload {
    organizationId: OrganizationId
    credentials: PennylaneCredentialsI
}

const orgarizationSlice = createSlice({
    name: "organization",
    initialState: initialState,
    reducers: {
        fetchOrganization(state, action: PayloadAction<OrganizationId>) {
            state.loadings[action.payload] = true
        },
        fetchOrganizationSuccess(state, action: PayloadAction<OrganizationI>) {
            state.loadings[action.payload.id] = false
            state.organizations[action.payload.id] = action.payload
        },
        fetchOrganizationFailed(state, action: PayloadAction<{ organizationId: OrganizationId; error: string }>) {
            state.loadings[action.payload.organizationId] = false
            state.errors[action.payload.organizationId] = action.payload.error
        },
        setFilter(state, action: PayloadAction<{ type: string; filter: string }>) {
            state.filters[action.payload.type] = action.payload.filter
        },
        setCurrentOrganizationIfNotSet(state, action: PayloadAction<OrganizationId | undefined>) {
            if (!state.currentOrganizationId) {
                state.currentOrganizationId = action.payload
            }
        },
        setCurrentOrganization(state, action: PayloadAction<OrganizationId | undefined>) {
            state.currentOrganizationId = action.payload
        },
        foundOrganization(state, action: PayloadAction<OrganizationI>) {
            state.organizations[action.payload.id] = action.payload
        },
        deleteOrganization(state, action: PayloadAction<OrganizationId>) {
            if (state.organizations[action.payload]) {
                delete state.organizations[action.payload]
            }
        },
        updateOrganization(
            state,
            action: PayloadAction<{ organizationId: OrganizationId; data: Partial<OrganizationI> }>
        ) {
            if (state.organizations[action.payload.organizationId]) {
                state.organizations[action.payload.organizationId] = {
                    ...state.organizations[action.payload.organizationId],
                    ...action.payload.data,
                }
            }
        },
        saveOrganizationRegistration(state, action: PayloadAction<SaveOrganizationRegistrationPayload>) {
            if (state.organizations[action.payload.organizationId]) {
                state.organizations[action.payload.organizationId].registration = action.payload.registration
            }
        },
        saveOrganizationAddress(state, action: PayloadAction<SaveOrganizationAddressPayload>) {
            if (state.organizations[action.payload.organizationId]) {
                state.organizations[action.payload.organizationId].address = action.payload.address
            }
        },
        saveOrganizationBankDetails(state, action: PayloadAction<SaveOrganizationBankDetailsPayload>) {
            if (state.organizations[action.payload.organizationId]) {
                state.organizations[action.payload.organizationId].bankDetails = action.payload.bankDetails
            }
        },
        saveOrganizationAccountingContact(state, action: PayloadAction<SaveOrganizationAccountingContactPayload>) {
            if (state.organizations[action.payload.organizationId]) {
                state.organizations[action.payload.organizationId].accountingContact = action.payload.accountingContact
            }
        },
        provideAxonautCredentials(state, action: PayloadAction<ProvideAxonautCredentialsPayload>) {
            if (state.organizations[action.payload.organizationId]) {
                state.organizations[action.payload.organizationId].axonautProvided = true
            }
        },
        providePennylaneCredentials(state, action: PayloadAction<ProvidePennylaneCredentialsPayload>) {
            if (state.organizations[action.payload.organizationId]) {
                state.organizations[action.payload.organizationId].pennylaneProvided = true
            }
        },
        removeMembersFromOrganization(
            state,
            action: PayloadAction<{ organizationId: OrganizationId; users: UserId[] }>
        ) {
            if (state.organizations[action.payload.organizationId]) {
                state.organizations[action.payload.organizationId].members = state.organizations[
                    action.payload.organizationId
                ].members.filter(
                    (member) => action.payload.users.indexOf(member.userId) === -1
                ) as OrganizationMemberI[]
                state.organizations[action.payload.organizationId].invitations = state.organizations[
                    action.payload.organizationId
                ].invitations.filter(
                    (member) => action.payload.users.indexOf(member.userId) === -1
                ) as OrganizationInvitationI[]
            }
        },
        fetchOrganizationTeams(state, action: PayloadAction<{ organizationId: OrganizationId }>) {
            state.loadingTeams[action.payload.organizationId] = true
            state.errorLoadingTeams[action.payload.organizationId] = undefined
        },
        fetchOrganizationTeamsSuccess(
            state,
            action: PayloadAction<{ organizationId: OrganizationId; teams: OrganizationTeamI[] }>
        ) {
            state.loadingTeams[action.payload.organizationId] = false
            state.teams[action.payload.organizationId] = action.payload.teams
        },
        fetchOrganizationTeamsError(state, action: PayloadAction<{ organizationId: OrganizationId; error: string }>) {
            state.loadingTeams[action.payload.organizationId] = false
            state.errorLoadingTeams[action.payload.organizationId] = action.payload.error
        },
        createOrganizationTeam(
            state,
            action: PayloadAction<{ organizationId: OrganizationId; team: OrganizationTeamI }>
        ) {
            state.teams[action.payload.organizationId].push(action.payload.team)
        },
        updateOrganizationTeam(
            state,
            action: PayloadAction<{ organizationId: OrganizationId; team: Partial<OrganizationTeamI> }>
        ) {
            if (state.teams[action.payload.organizationId]) {
                const index = state.teams[action.payload.organizationId].findIndex(
                    (t) => t.teamId === action.payload.team.teamId
                )
                if (index !== -1) {
                    state.teams[action.payload.organizationId][index] = {
                        ...state.teams[action.payload.organizationId][index],
                        ...action.payload.team,
                    }
                }
            }
        },
        removeTeamsFromOrganization(
            state,
            action: PayloadAction<{ organizationId: OrganizationId; teamIds: string[] }>
        ) {
            if (state.teams[action.payload.organizationId]) {
                state.teams[action.payload.organizationId] = state.teams[action.payload.organizationId].filter(
                    (team) => action.payload.teamIds.indexOf(team.teamId as string) === -1
                )
            }
        },
        addUserToOrganization(
            state,
            action: PayloadAction<{ organizationId: OrganizationId; teamId: TeamId; userId: UserId }>
        ) {
            if (state.teams[action.payload.organizationId]) {
                const teamIndex = state.teams[action.payload.organizationId].findIndex(
                    (team) => team.teamId === action.payload.teamId
                )
                if (teamIndex !== -1) {
                    if (!state.teams[action.payload.organizationId][teamIndex].members) {
                        state.teams[action.payload.organizationId][teamIndex].members = []
                    }
                    state.teams[action.payload.organizationId][teamIndex].members.push({
                        userId: action.payload.userId,
                    })
                }
            }
        },
        removeUserFromOrganization(
            state,
            action: PayloadAction<{ organizationId: OrganizationId; teamId: TeamId; userId: UserId }>
        ) {
            if (state.teams[action.payload.organizationId]) {
                const teamIndex = state.teams[action.payload.organizationId].findIndex(
                    (team) => team.teamId === action.payload.teamId
                )
                if (teamIndex >= 0) {
                    state.teams[action.payload.organizationId][teamIndex].members = state.teams[
                        action.payload.organizationId
                    ][teamIndex].members.filter((person) => person.userId !== action.payload.userId)
                }
            }
        },
        acceptMembership(state, action: PayloadAction<{ organizationId: OrganizationId; userId: UserId }>) {
            if (state.organizations[action.payload.organizationId]) {
                const index = state.organizations[action.payload.organizationId].membershipRequests.findIndex(
                    (user) => user.userId === action.payload.userId
                )
                if (index >= 0) {
                    state.organizations[action.payload.organizationId].membershipRequests = [
                        ...state.organizations[action.payload.organizationId].membershipRequests.slice(0, index),
                        ...state.organizations[action.payload.organizationId].membershipRequests.slice(index + 1),
                    ] as OrganizationInvitationI[]
                }
                state.organizations[action.payload.organizationId].members = [
                    ...state.organizations[action.payload.organizationId].members,
                    {
                        userId: action.payload.userId,
                        role: OrganizationRole.STAFF,
                    },
                ]
            }
        },
        rejectMembership(state, action: PayloadAction<{ organizationId: OrganizationId; userId: UserId }>) {
            if (state.organizations[action.payload.organizationId]) {
                const index = state.organizations[action.payload.organizationId].membershipRequests.findIndex(
                    (user) => user.userId === action.payload.userId
                )
                if (index >= 0) {
                    state.organizations[action.payload.organizationId].membershipRequests = [
                        ...state.organizations[action.payload.organizationId].membershipRequests.slice(0, index),
                        ...state.organizations[action.payload.organizationId].membershipRequests.slice(index + 1),
                    ] as OrganizationInvitationI[]
                }
            }
        },
    },
})

export const organizationActions = orgarizationSlice.actions

// // Selectors
export const selectOrganizationState = (state: RootState) => state.organization
export const selectCurrentOrganizationId = (state: RootState) => state.organization?.currentOrganizationId
export const selectOrganizationById = (state: RootState, organizationId: OrganizationId) =>
    state.organization.organizations[organizationId]
export const selectCurrentOrganization = (state: RootState) =>
    state.organization.currentOrganizationId &&
    state.organization.organizations[state.organization.currentOrganizationId]
export const selectOrganizationTeams = (state: RootState) => state.organization.teams
export const selectOrganizationLoadingTeams = (state: RootState) => state.organization.loadingTeams
export const selectOrganizationErrorLoadingTeams = (state: RootState) => state.organization.errorLoadingTeams
export const selectOrganizationsFilters = (state: RootState) => state.organization.filters

export const selectOrganizationTeamsState = createSelector(
    [selectOrganizationTeams, selectOrganizationLoadingTeams, selectOrganizationErrorLoadingTeams],
    (teams, loading, error) => ({
        teams,
        loading,
        error,
    })
)

// // Reducer
const organizationReducer = orgarizationSlice.reducer

export default persistReducer(organizationPersistConfig, organizationReducer)
