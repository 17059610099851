import { TextField } from "@mui/material"
import React, { ChangeEvent, useCallback } from "react"
import { useIntl } from "react-intl"

import { InputLineConfigI, lineCustomFieldsLabelMessages } from "~/features/invoice/core"
import { useInvoiceLineCustomFields } from "~/features/invoice/hooks/useInvoiceLineCustomFields"
import { useUpdateInvoiceLineCustomField } from "~/features/invoice/hooks/useUpdateInvoiceLineCustomField"

interface Props {
    lineIndex: number
    lineConfig: InputLineConfigI
    contextId?: string
}

export const CustomFieldCell: React.FC<Props> = ({ lineIndex, lineConfig, contextId }) => {
    const { formatMessage } = useIntl()

    const lineId = contextId ? `${contextId}-${lineIndex}` : `${lineIndex}`
    const [fields, setCustomFieldValue] = useInvoiceLineCustomFields(lineId)

    const { name: configName, required: isConfigRequired } = lineConfig
    const customField = fields?.[configName] || null
    const customFieldValue = fields[configName]?.value ?? ""

    const updateCustomFieldValue = useUpdateInvoiceLineCustomField(customField)

    const onChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            if (!customField) return
            const value = event.currentTarget.value
            updateCustomFieldValue(lineId, customField.id, value)
            setCustomFieldValue(configName, { ...customField, value })
        },
        [lineId, customField, configName, setCustomFieldValue, updateCustomFieldValue]
    )

    return (
        <TextField
            required={isConfigRequired}
            value={customFieldValue}
            placeholder={formatMessage(lineCustomFieldsLabelMessages[configName])}
            onChange={onChange}
            type="text"
        />
    )
}
