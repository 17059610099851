import { Grid, Tooltip } from "@mui/material"
import classnames from "classnames"
import React, { useRef } from "react"
import { Check, ChevronRight, Edit3, Send } from "react-feather"
import { defineMessages, useIntl } from "react-intl"

import "~/components/Steps/Steps_Deprecated.scss"
import {
    PurchaseRequestDetails,
    PurchaseRequestStatus,
} from "~/domains/transactions/purchase-requests/types/PurchaseRequests"
import { useResizeObserver } from "~/hooks"

export interface StepsI {
    key: string
    name: string
    value?: string
    done: boolean
    rejected?: boolean
    status?: string
    icon?: React.ReactNode
}

const listItemStyles = { padding: "8px 12px" }

const stepDataStyles = { gap: "0" }

const separatorStyles = { top: "auto" }

const labelStyles = { display: "none" }

const messages = defineMessages({
    draft: {
        id: "purchase.requests.request.steps.draft",
        defaultMessage: "Draft",
    },
    sent: {
        id: "purchase.requests.request.steps.sent",
        defaultMessage: "Sent",
    },
    create: {
        id: "purchase.requests.request.steps.create",
        defaultMessage: "Create",
    },
    done: {
        id: "purchase.requests.request.steps.done",
        defaultMessage: "Done",
    },
    approval: {
        id: "purchase.requests.request.steps.approval",
        defaultMessage: "Approval",
    },
    APPROVED: {
        id: "purchase.requests.request.steps.approved",
        defaultMessage: "Approved",
    },
    REJECTED: {
        id: "purchase.requests.request.steps.rejected",
        defaultMessage: "Rejected",
    },
    CONVERTED: {
        id: "purchase.requests.request.steps.converted",
        defaultMessage: "Done",
    },
    items: {
        id: "purchase.orders.order.steps.items",
        defaultMessage: "{current}/{total} items",
    },
})

const StepperSizes = {
    BuyerSmall: 600,
    SupplierSmall: 480,
    BuyerMedium: 1200,
    SupplierMedium: 920,
    DraftLarge: 1315,
    DraftMedium: 1062,
}

interface StepsProps {
    PR: PurchaseRequestDetails
    disabled?: boolean
}

export function StepsPurchaseRequest({ PR, disabled }: StepsProps) {
    const { formatMessage, formatDate } = useIntl()
    const listContainerRef = useRef(null)
    const { width } = useResizeObserver(listContainerRef)

    const isDraft = PR.status === PurchaseRequestStatus.DRAFT
    const isMediumSizedDraft = isDraft && width < StepperSizes.DraftMedium
    const isLargeSizedDraft = isDraft && width < StepperSizes.DraftLarge
    const isMediumContent = width < StepperSizes.BuyerMedium
    const isMediumDraftOrContent = isMediumSizedDraft || (!isDraft && isMediumContent)

    const classes = classnames("steps", {
        ["disabled"]: disabled,
        ["rejected"]: PR.status === PurchaseRequestStatus.REJECTED,
        ["done"]: !!PR.purchaseOrder,
    })

    const steps: StepsI[] = [
        {
            key: "draft",
            name: formatMessage(messages.draft),
            value: PR.status === PurchaseRequestStatus.DRAFT ? "Create" : "Done",
            status: "done",
            done: true,
            icon: <Edit3 size={18} />,
        },
        {
            key: "sent",
            name: formatMessage(messages.sent),
            value: PR.submittedDate ? formatDate(PR.submittedDate) : "-",
            status: PR.status !== PurchaseRequestStatus.DRAFT ? "done" : "next",
            done: PR.status !== PurchaseRequestStatus.DRAFT,
            icon: <Send size={18} />,
        },
        {
            key: "validation",
            name: formatMessage(messages.approval),
            value: PR.purchaseOrder
                ? formatMessage(messages.CONVERTED)
                : PR.status === PurchaseRequestStatus.APPROVED || PR.status === PurchaseRequestStatus.REJECTED
                ? formatMessage(messages[PR.status])
                : "-",
            done: PR.status === PurchaseRequestStatus.APPROVED || PR.status === PurchaseRequestStatus.REJECTED,
            status:
                PR.status === PurchaseRequestStatus.APPROVED || PR.status === PurchaseRequestStatus.REJECTED
                    ? "done"
                    : "next",
            rejected: PR.status === PurchaseRequestStatus.REJECTED,
            icon: <Check size={18} />,
        },
    ]

    const renderLine = ({ status, icon, name }: StepsI, key: number): React.ReactNode => (
        <li key={key} className={status} {...(isMediumDraftOrContent ? { style: listItemStyles } : {})}>
            <span
                className="steps_data"
                style={{
                    flexDirection: isLargeSizedDraft || (!isDraft && isMediumContent) ? "column" : "row",
                    ...(isMediumSizedDraft ? stepDataStyles : {}),
                }}
            >
                <Tooltip title={name} arrow>
                    <div className="steps_icon">{icon}</div>
                </Tooltip>

                <div className="steps_info">
                    <span className="steps_label" {...(isMediumDraftOrContent ? { style: labelStyles } : {})}>
                        {name}
                    </span>
                </div>
            </span>
            <span className="steps_separator" {...(isMediumSizedDraft ? { style: separatorStyles } : {})}>
                <ChevronRight color="var(--color-grey)" size={isMediumDraftOrContent ? 14 : 24} />
            </span>
        </li>
    )

    return (
        <Grid item className="stepper-container">
            <ul className={classes} ref={listContainerRef}>
                {steps.map((step, key) => renderLine(step, key))}
            </ul>
        </Grid>
    )
}
