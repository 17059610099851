import React, { useState } from "react"
import { styled } from "@mui/material"
import { Button, Size } from "~/components"
import { defineMessages, FormattedMessage, useIntl } from "react-intl"
import { ModalConfirm } from "~/components/ModalConfirm/ModalConfirm"

const messages = defineMessages({
    label: {
        id: "purchase.orders.order.markInPreparation.label",
        defaultMessage: "Mark all as in preparation",
    },
    title: {
        id: "purchase.orders.order.markInPreparation.title",
        defaultMessage: `Mark all items as "In preparation"?`,
    },
    cancel: { id: "purchase.orders.order.markInPreparation.cancel", defaultMessage: "Cancel" },
    confirm: { id: "purchase.orders.order.markInPreparation.confirm", defaultMessage: "Yes" },
    message: {
        id: "purchase.orders.order.markInPreparation.confirmMessage",
        defaultMessage: `All items are now accepted by the buyer, do you want to mark them as "In preparation"?`,
    },
})

interface Props {
    handleMarkInPreparation: () => void
}

const ModalTitle = styled("h2")({
    display: "flex",
    justifyContent: "flex-start",
    fontSize: "20px",
    lineHeight: "30px",
    textAlign: "left",
    color: "var(--color-grey)",
    fontWeight: "500",
})

export function MarkAllInPreparation({ handleMarkInPreparation }: Props) {
    const { formatMessage } = useIntl()
    const [displayModal, setDisplayModal] = useState<boolean>(false)

    const handleClick = (): void => {
        setDisplayModal(true)
    }

    const handleCancel = (): void => {
        setDisplayModal(false)
    }

    const handleConfirm = (): void => {
        handleMarkInPreparation()
        setDisplayModal(false)
    }

    return (
        <>
            <Button type="default" buttonType="button" onClick={handleClick}>
                <FormattedMessage {...messages.label} />
            </Button>
            <ModalConfirm
                position="start"
                size={Size.MD}
                message={formatMessage(messages.message)}
                title={<ModalTitle>{formatMessage(messages.title)}</ModalTitle>}
                button1={<Button type="neutral" onClick={handleCancel} text={formatMessage(messages.cancel)} />}
                button2={<Button type="error" onClick={handleConfirm} text={formatMessage(messages.confirm)} />}
                open={displayModal}
            />
        </>
    )
}
