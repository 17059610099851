import * as Sentry from "@sentry/browser"
import { isLeft } from "fp-ts/Either"
import { useCallback, useEffect, useRef } from "react"
import { defineMessages, useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import useWebSocket, { ReadyState } from "react-use-websocket"
import { WSMessageDataI, WSMessageDataIO } from "~/types"
import { useWebsocketCallbacks } from "./useWebsocketCallbacks"
import { HOME_ROUTE } from "~/core/routes"

const WS_URL = import.meta.env.VITE_WS_INVOICES_URL + "v1"

const messages = defineMessages({
    toastOcrError: {
        id: "supplier.ocrInvoice.page.toast.ocrNorAvailable",
        defaultMessage: "An error occurred please try again",
    },
})

export const useInvoiceWebsocket = (invoiceId: string | undefined | null) => {
    const didUnmount = useRef(false)
    const { formatMessage } = useIntl()
    const navigate = useNavigate()

    const onError = useCallback(
        (event: WebSocketEventMap["error"]) => {
            console.error("Error WS", event)
            if (!event.isTrusted) {
                toast.dismiss()
                toast.error(formatMessage(messages.toastOcrError), { position: "top-right" })
                navigate(HOME_ROUTE)
            }
        },
        [formatMessage, navigate]
    )

    const onClose = useCallback((event: WebSocketEventMap["close"]) => {
        if (!event.wasClean) {
            Sentry.captureMessage(`WS closed with error: ${event.reason}`, {
                extra: {
                    event,
                },
                level: "log",
            })
            console.warn("WS closed with error", event)
        }
    }, [])

    const { sendJsonMessage, lastMessage, readyState } = useWebSocket<WSMessageDataI>(WS_URL, {
        shouldReconnect: () => !didUnmount.current,
        reconnectAttempts: 100,
        reconnectInterval: 1000,
        onError,
        onClose,
    })

    useEffect(() => {
        return () => {
            didUnmount.current = true
        }
    }, [didUnmount])

    useEffect(() => {
        if (readyState === ReadyState.OPEN) {
            sendJsonMessage({ invoiceId: invoiceId })
        }
    }, [readyState])

    const callbacks = useWebsocketCallbacks(invoiceId)

    useEffect(() => {
        if (lastMessage) {
            if (typeof lastMessage.data === "string") {
                try {
                    const jsonData = JSON.parse(lastMessage.data)
                    const decodedData = WSMessageDataIO.decode(jsonData)
                    if (isLeft(decodedData)) {
                        Sentry.captureException(new Error("unable to parse data from websocket"), {
                            extra: {
                                data: jsonData,
                                errors: decodedData.left,
                            },
                        })
                        return
                    }
                    const data = decodedData.right
                    callbacks[data.payloadType](data)
                } catch (e) {
                    console.error("Error WS", e)
                    Sentry.captureException(e)
                }
            }
        }
    }, [lastMessage, callbacks])
}
