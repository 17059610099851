import { OrganizationId } from "~/types"
import { useCallback, useEffect, useRef, useState } from "react"
import { useAppSelector } from "~/store/hooks"
import {
    SubscriptionEventI,
    SubscriptionEventType,
    SubscriptionType,
} from "~/domains/transactions/common/subscriptions"
import { selectPurchaseRequest } from "../purchaseRequestsSlice"
import { useFetchPurchaseRequest } from "./useFetchPurchaseRequest"
import { useSubscribeToUpdate } from "~/domains/transactions/common/subscriptions/hooks"
import { useFetchPurchaseRequestWorkflows } from "~/store/workflow/hooks"
import { selectCurrentOrganization } from "~/store/organization/organizationSlice"

export const usePurchaseRequest = (
    organizationId: OrganizationId | undefined,
    purchaseRequestId: string | undefined
) => {
    const purchaseRequest = useAppSelector(selectPurchaseRequest)
    const currentOrganization = useAppSelector(selectCurrentOrganization)

    const [changedOrganization, setChangedOrganization] = useState<boolean>(false)

    const { fetchPurchaseRequest } = useFetchPurchaseRequest(organizationId)
    const { fetchPurchaseRequestChecks } = useFetchPurchaseRequestWorkflows(organizationId, purchaseRequestId)

    const lastOrganizationId = useRef<string | null>(null)

    const doFetch = useCallback(() => {
        if (purchaseRequestId && organizationId) {
            fetchPurchaseRequest(purchaseRequestId)
        }
    }, [purchaseRequestId, organizationId, fetchPurchaseRequest])

    useEffect(() => {
        if (
            currentOrganization &&
            purchaseRequestId &&
            lastOrganizationId &&
            organizationId &&
            organizationId !== lastOrganizationId.current
        ) {
            lastOrganizationId.current = organizationId
            setChangedOrganization(true)
            doFetch()
        }
    }, [currentOrganization, organizationId, purchaseRequestId])

    const onPurchaseRequestEvent = useCallback(
        (event: SubscriptionEventI) => {
            if (event.type === SubscriptionEventType.SELF || event.type === SubscriptionEventType.WORKFLOW) {
                doFetch()
            }
            if (event.type === SubscriptionEventType.WORKFLOW) {
                fetchPurchaseRequestChecks()
            }
        },
        [doFetch, fetchPurchaseRequestChecks]
    )

    useSubscribeToUpdate(SubscriptionType.PURCHASE_REQUEST, purchaseRequestId, onPurchaseRequestEvent)

    return { purchaseRequest, changedOrganization }
}
