/*
    This file contains the adapters for converting API responses to Editor nodes and edges.
*/
import { Edge, Node } from "@xyflow/react"

import { Flow, FlowNode, NodeType } from "~/domains/orchestration/flows/types"

const mapNodeTypeToComponent: Record<NodeType, string> = {
    [NodeType.EVENT_TRIGGER_NODE]: "EventTriggerNode",
    [NodeType.HTTP_NODE]: "HttpNode",
    [NodeType.SET_PARTNERSHIP_FIELD_NODE]: "SetPartnershipFieldNode",
    [NodeType.IF_NODE]: "IfNode",
    [NodeType.CHECK_NODE]: "CheckNode",
    [NodeType.SEND_EMAIL_NODE]: "SendEmailNode",
    [NodeType.ADD_TO_BUDGET_NODE]: "AddToBudgetNode",
    [NodeType.INVOICE_TO_PURCHASE_ORDER_MATCH_NODE]: "InvoiceToPurchaseOrderMatchNode",
    [NodeType.SET_INVOICE_LIFECYCLE_STATUS_NODE]: "SetInvoiceLifecycleStatusNode",
    [NodeType.FITS_TO_BUDGET_NODE]: "FitsToBudgetNode",
}

/**
 *
 * @param flow
 * @returns Node[]
 * @description Adapts the API response to Editor nodes
 */
const adaptFlowToNodes = (flow: Flow, currentNode?: FlowNode): Node[] => {
    return flow.nodes.map((node: FlowNode | null) => {
        if (!node)
            return {
                id: "",
                type: "",
                data: {},
                position: { x: 0, y: 0 },
                selected: false,
            }

        return {
            id: node.slug,
            type: mapNodeTypeToComponent[node.type],
            data: node,
            position: node.metadata.position,
            selected: currentNode?.slug == node.slug,
        }
    })
}

/**
 *
 * @param flow
 * @returns Edge[]
 * @description Adapts the API response to Editor edges
 */
const adaptFlowToEdges = (flow: Flow): Edge[] => {
    return flow.nodes.flatMap((node: FlowNode | null) => {
        if (!node) return []

        const commonEdgeProperties = {
            animated: true,
            type: "button",
        }

        const successEdgeProperties = {
            ...commonEdgeProperties,
            id: `${node.slug}-success`,
            sourceHandle: `${node.slug}-success`,
        }

        const failureEdgeProperties = {
            ...commonEdgeProperties,
            id: `${node.slug}-failure`,
            sourceHandle: `${node.slug}-failure`,
        }

        switch (node.type) {
            case NodeType.IF_NODE:
                return [
                    {
                        source: node.slug,
                        ...successEdgeProperties,
                        target: node.nextIfSuccess ? node.nextIfSuccess : "",
                    },
                    {
                        source: node.slug,
                        ...failureEdgeProperties,
                        target: node.nextIfFailure ? node.nextIfFailure : "",
                    },
                ]
            case NodeType.CHECK_NODE:
                return [
                    {
                        source: node.slug,
                        ...failureEdgeProperties,
                        target: node.nextIfFailure ? node.nextIfFailure : "",
                    },
                    {
                        source: node.slug,
                        ...successEdgeProperties,
                        target: node.nextIfSuccess ? node.nextIfSuccess : "",
                    },
                ]

            case NodeType.INVOICE_TO_PURCHASE_ORDER_MATCH_NODE:
                return [
                    {
                        source: node.slug,
                        ...failureEdgeProperties,
                        target: node.nextIfFailure ? node.nextIfFailure : "",
                    },
                    {
                        source: node.slug,
                        ...successEdgeProperties,
                        target: node.nextIfSuccess ? node.nextIfSuccess : "",
                    },
                ]
            case NodeType.FITS_TO_BUDGET_NODE:
                return [
                    {
                        source: node.slug,
                        ...successEdgeProperties,
                        target: node.nextIfSuccess ? node.nextIfSuccess : "",
                    },
                    {
                        source: node.slug,
                        ...failureEdgeProperties,
                        target: node.nextIfFailure ? node.nextIfFailure : "",
                    },
                ]
            default:
                return [
                    {
                        source: node.slug,
                        ...commonEdgeProperties,
                        id: node.slug,
                        target: node.nextNode ? node.nextNode : "",
                    },
                ]
        }
    })
}

// This a simple way to use expression cf : https://www.notion.so/get-flowie/Flows-expressions-1267fb2d6e9180709954d1e61460eeef
// For now, we just display the expression as a string
// It needs to be beetween single quotes (e.g. 'My message')

const adaptTextToExpression = (value: string) => `'${value}'`

const adaptExpressionToText = (value: string) => value.replace(/'/g, "").replace(/\\/g, "")

export { adaptFlowToNodes, adaptFlowToEdges, adaptTextToExpression, adaptExpressionToText }
