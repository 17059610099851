import { defineMessages, useIntl } from "react-intl"

import { Loader } from "~/components"
import { useGetPaymentMethodDetailsItemQuery } from "~/domains/payment/payment-method-details/api/paymentMethodDetailsApi"
import {
    InfoItem,
    InfoItemLabel,
    InfoItemValue,
    PaymentMethodContainer,
} from "~/domains/payment/payment-method-details/components"
import {
    buildExpirationDate,
    buildMaskedCardNumber,
} from "~/domains/payment/payment-method-details/utils/getGridColumnsByActiveTab"
import { PaymentMethodType } from "~/domains/payment/payment-methods/types"

const messages = defineMessages({
    iban: {
        id: "payment.partyPaymentMethodDetails.iban",
        defaultMessage: "Iban",
    },
    bicSwift: {
        id: "payment.partyPaymentMethodDetails.bicSwift",
        defaultMessage: "Bic / Swift",
    },
    bankAccountNumber: {
        id: "payment.partyPaymentMethodDetails.bankAccountNumber",
        defaultMessage: "Bank account number",
    },
    bankName: {
        id: "payment.partyPaymentMethodDetails.bankName",
        defaultMessage: "Bank name",
    },
    currency: {
        id: "payment.partyPaymentMethodDetails.currency",
        defaultMessage: "Currency",
    },
    cardNumber: {
        id: "payment.partyPaymentMethodDetails.cardNumber",
        defaultMessage: "Card number",
    },
    expirationDate: {
        id: "payment.partyPaymentMethodDetails.expirationDate",
        defaultMessage: "Expiration date",
    },
    cardBrand: {
        id: "payment.partyPaymentMethodDetails.cardBrand",
        defaultMessage: "Card brand",
    },
    accountNumber: {
        id: "payment.partyPaymentMethodDetails.accountNumber",
        defaultMessage: "Account number",
    },
    mandateReference: {
        id: "payment.partyPaymentMethodDetails.mandateReference",
        defaultMessage: "Mandate reference",
    },
    mandateDate: {
        id: "payment.partyPaymentMethodDetails.mandateDate",
        defaultMessage: "Mandate date",
    },
})

interface PartyPaymentMethodDetailsProps {
    paymentMethodDetailsId: string
}

interface DataItem {
    label: string
    value: string
}

export const PartyPaymentMethodDetails = ({ paymentMethodDetailsId }: PartyPaymentMethodDetailsProps) => {
    const { data, isLoading, isFetching } = useGetPaymentMethodDetailsItemQuery(paymentMethodDetailsId, {
        skip: !paymentMethodDetailsId,
    })
    const { formatMessage, formatDate } = useIntl()

    if (isLoading || isFetching) {
        return <Loader small />
    }

    if (!data) {
        return null
    }

    const dataValues: DataItem[] = []

    if (data.payment_method_type === PaymentMethodType.BANK_TRANSFER) {
        const bankTransferItems = [
            ...(data?.iban
                ? [
                      {
                          label: formatMessage(messages.iban),
                          value: data.iban || "-",
                      },
                  ]
                : []),
            ...(data?.bic_swift
                ? [
                      {
                          label: formatMessage(messages.bicSwift),
                          value: data.bic_swift || "-",
                      },
                  ]
                : []),
            ...(data?.bank_account_number
                ? [
                      {
                          label: formatMessage(messages.bankAccountNumber),
                          value: data.bank_account_number ?? "-",
                      },
                  ]
                : []),
            {
                label: formatMessage(messages.bankName),
                value: data?.bank_name || "-",
            },
            {
                label: formatMessage(messages.currency),
                value: data?.currency || "-",
            },
        ]
        dataValues.push(...bankTransferItems)
    }

    if (data.payment_method_type === PaymentMethodType.CARD) {
        dataValues.push(
            {
                label: formatMessage(messages.cardNumber),
                value: buildMaskedCardNumber([data?.card_first_6_digits, data?.card_last_4_digits]) || "-",
            },
            {
                label: formatMessage(messages.expirationDate),
                value: buildExpirationDate([data?.card_expiration_month, data?.card_expiration_year]) || "-",
            },
            {
                label: formatMessage(messages.cardBrand),
                value: data?.card_brand || "-",
            },
            {
                label: formatMessage(messages.currency),
                value: data?.currency || "-",
            }
        )
    }

    if (data.payment_method_type === PaymentMethodType.DIRECT_DEBIT) {
        dataValues.push(
            {
                label: formatMessage(messages.accountNumber),
                value: data?.bank_account_number || "-",
            },
            {
                label: formatMessage(messages.mandateReference),
                value: data?.mandate_reference || "-",
            },
            {
                label: formatMessage(messages.mandateDate),
                value: formatDate(data?.mandate_date) || "-",
            },
            {
                label: formatMessage(messages.currency),
                value: data?.currency || "-",
            }
        )
    }

    return (
        <PaymentMethodContainer>
            {dataValues.map(({ label, value }: DataItem) => (
                <InfoItem key={label}>
                    <InfoItemLabel>{label}</InfoItemLabel>
                    <InfoItemValue>{value}</InfoItemValue>
                </InfoItem>
            ))}
        </PaymentMethodContainer>
    )
}
