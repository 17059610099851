import { EventI } from "~/types"

export const eventsMock = [
    {
        userId: "6537880532b88901a70f8132",
        timestamp: "2024-10-17T12:32:31.381Z",
        type: "invoice_opened",
        count: 37765,
        user: {
            id: "6537880532b88901a70f8132",
            email: "nicolas@flowie.fr",
            emailVerified: false,
            organizations: [
                {
                    id: "64ec6c29dd74306a85f11c19",
                },
                {
                    id: "6703e91850789d0f5f3e5b9f",
                },
                {
                    id: "67065bd350789d0f5f3e5bab",
                },
                {
                    id: "670ff49144455e7c26f25aa1",
                },
            ],
            invitations: [],
            membershipRequests: [],
            fullName: "Nicolas SANDRON",
            phoneNumber: "",
            phoneVerified: false,
        },
    },
    {
        userId: "6537880532b88901a70f8132",
        timestamp: "2024-10-09T15:14:18.256Z",
        type: "invoice_invalidated",
        user: {
            id: "6537880532b88901a70f8132",
            email: "nicolas@flowie.fr",
            emailVerified: false,
            organizations: [
                {
                    id: "64ec6c29dd74306a85f11c19",
                },
                {
                    id: "6703e91850789d0f5f3e5b9f",
                },
                {
                    id: "67065bd350789d0f5f3e5bab",
                },
                {
                    id: "670ff49144455e7c26f25aa1",
                },
            ],
            invitations: [],
            membershipRequests: [],
            fullName: "Nicolas SANDRON",
            phoneNumber: "",
            phoneVerified: false,
        },
    },
    {
        userId: "6537880532b88901a70f8132",
        timestamp: "2024-10-09T15:14:07.880Z",
        type: "invoice_validated",
        user: {
            id: "6537880532b88901a70f8132",
            email: "nicolas@flowie.fr",
            emailVerified: false,
            organizations: [
                {
                    id: "64ec6c29dd74306a85f11c19",
                },
                {
                    id: "6703e91850789d0f5f3e5b9f",
                },
                {
                    id: "67065bd350789d0f5f3e5bab",
                },
                {
                    id: "670ff49144455e7c26f25aa1",
                },
            ],
            invitations: [],
            membershipRequests: [],
            fullName: "Nicolas SANDRON",
            phoneNumber: "",
            phoneVerified: false,
        },
    },
    {
        userId: "6537880532b88901a70f8132",
        timestamp: "2024-10-09T15:12:17.934Z",
        type: "invoice_invalidated",
        user: {
            id: "6537880532b88901a70f8132",
            email: "nicolas@flowie.fr",
            emailVerified: false,
            organizations: [
                {
                    id: "64ec6c29dd74306a85f11c19",
                },
                {
                    id: "6703e91850789d0f5f3e5b9f",
                },
                {
                    id: "67065bd350789d0f5f3e5bab",
                },
                {
                    id: "670ff49144455e7c26f25aa1",
                },
            ],
            invitations: [],
            membershipRequests: [],
            fullName: "Nicolas SANDRON",
            phoneNumber: "",
            phoneVerified: false,
        },
    },
    {
        userId: "6537880532b88901a70f8132",
        timestamp: "2024-10-09T15:12:10.330Z",
        type: "invoice_validated",
        user: {
            id: "6537880532b88901a70f8132",
            email: "nicolas@flowie.fr",
            emailVerified: false,
            organizations: [
                {
                    id: "64ec6c29dd74306a85f11c19",
                },
                {
                    id: "6703e91850789d0f5f3e5b9f",
                },
                {
                    id: "67065bd350789d0f5f3e5bab",
                },
                {
                    id: "670ff49144455e7c26f25aa1",
                },
            ],
            invitations: [],
            membershipRequests: [],
            fullName: "Nicolas SANDRON",
            phoneNumber: "",
            phoneVerified: false,
        },
    },
    {
        userId: "6537880532b88901a70f8132",
        timestamp: "2024-10-09T15:09:23.046Z",
        type: "invoice_invalidated",
        user: {
            id: "6537880532b88901a70f8132",
            email: "nicolas@flowie.fr",
            emailVerified: false,
            organizations: [
                {
                    id: "64ec6c29dd74306a85f11c19",
                },
                {
                    id: "6703e91850789d0f5f3e5b9f",
                },
                {
                    id: "67065bd350789d0f5f3e5bab",
                },
                {
                    id: "670ff49144455e7c26f25aa1",
                },
            ],
            invitations: [],
            membershipRequests: [],
            fullName: "Nicolas SANDRON",
            phoneNumber: "",
            phoneVerified: false,
        },
    },
    {
        userId: "6537880532b88901a70f8132",
        timestamp: "2024-10-09T15:03:39.810Z",
        type: "invoice_validated",
        user: {
            id: "6537880532b88901a70f8132",
            email: "nicolas@flowie.fr",
            emailVerified: false,
            organizations: [
                {
                    id: "64ec6c29dd74306a85f11c19",
                },
                {
                    id: "6703e91850789d0f5f3e5b9f",
                },
                {
                    id: "67065bd350789d0f5f3e5bab",
                },
                {
                    id: "670ff49144455e7c26f25aa1",
                },
            ],
            invitations: [],
            membershipRequests: [],
            fullName: "Nicolas SANDRON",
            phoneNumber: "",
            phoneVerified: false,
        },
    },
    {
        userId: "6537880532b88901a70f8132",
        timestamp: "2024-10-09T15:03:19.782Z",
        type: "invoice_invalidated",
        user: {
            id: "6537880532b88901a70f8132",
            email: "nicolas@flowie.fr",
            emailVerified: false,
            organizations: [
                {
                    id: "64ec6c29dd74306a85f11c19",
                },
                {
                    id: "6703e91850789d0f5f3e5b9f",
                },
                {
                    id: "67065bd350789d0f5f3e5bab",
                },
                {
                    id: "670ff49144455e7c26f25aa1",
                },
            ],
            invitations: [],
            membershipRequests: [],
            fullName: "Nicolas SANDRON",
            phoneNumber: "",
            phoneVerified: false,
        },
    },
    {
        userId: "6537880532b88901a70f8132",
        timestamp: "2024-10-09T15:03:13.405Z",
        type: "invoice_validated",
        user: {
            id: "6537880532b88901a70f8132",
            email: "nicolas@flowie.fr",
            emailVerified: false,
            organizations: [
                {
                    id: "64ec6c29dd74306a85f11c19",
                },
                {
                    id: "6703e91850789d0f5f3e5b9f",
                },
                {
                    id: "67065bd350789d0f5f3e5bab",
                },
                {
                    id: "670ff49144455e7c26f25aa1",
                },
            ],
            invitations: [],
            membershipRequests: [],
            fullName: "Nicolas SANDRON",
            phoneNumber: "",
            phoneVerified: false,
        },
    },
    {
        userId: "6537880532b88901a70f8132",
        timestamp: "2024-10-09T15:03:08.439Z",
        type: "invoice_invalidated",
        user: {
            id: "6537880532b88901a70f8132",
            email: "nicolas@flowie.fr",
            emailVerified: false,
            organizations: [
                {
                    id: "64ec6c29dd74306a85f11c19",
                },
                {
                    id: "6703e91850789d0f5f3e5b9f",
                },
                {
                    id: "67065bd350789d0f5f3e5bab",
                },
                {
                    id: "670ff49144455e7c26f25aa1",
                },
            ],
            invitations: [],
            membershipRequests: [],
            fullName: "Nicolas SANDRON",
            phoneNumber: "",
            phoneVerified: false,
        },
    },
    {
        userId: "6537880532b88901a70f8132",
        timestamp: "2024-10-09T15:02:27.657Z",
        type: "invoice_validated",
        user: {
            id: "6537880532b88901a70f8132",
            email: "nicolas@flowie.fr",
            emailVerified: false,
            organizations: [
                {
                    id: "64ec6c29dd74306a85f11c19",
                },
                {
                    id: "6703e91850789d0f5f3e5b9f",
                },
                {
                    id: "67065bd350789d0f5f3e5bab",
                },
                {
                    id: "670ff49144455e7c26f25aa1",
                },
            ],
            invitations: [],
            membershipRequests: [],
            fullName: "Nicolas SANDRON",
            phoneNumber: "",
            phoneVerified: false,
        },
    },
    {
        userId: "6537880532b88901a70f8132",
        timestamp: "2024-10-09T15:02:22.557Z",
        type: "invoice_invalidated",
        user: {
            id: "6537880532b88901a70f8132",
            email: "nicolas@flowie.fr",
            emailVerified: false,
            organizations: [
                {
                    id: "64ec6c29dd74306a85f11c19",
                },
                {
                    id: "6703e91850789d0f5f3e5b9f",
                },
                {
                    id: "67065bd350789d0f5f3e5bab",
                },
                {
                    id: "670ff49144455e7c26f25aa1",
                },
            ],
            invitations: [],
            membershipRequests: [],
            fullName: "Nicolas SANDRON",
            phoneNumber: "",
            phoneVerified: false,
        },
    },
    {
        userId: "6537880532b88901a70f8132",
        timestamp: "2024-10-09T15:01:55.994Z",
        type: "invoice_validated",
        user: {
            id: "6537880532b88901a70f8132",
            email: "nicolas@flowie.fr",
            emailVerified: false,
            organizations: [
                {
                    id: "64ec6c29dd74306a85f11c19",
                },
                {
                    id: "6703e91850789d0f5f3e5b9f",
                },
                {
                    id: "67065bd350789d0f5f3e5bab",
                },
                {
                    id: "670ff49144455e7c26f25aa1",
                },
            ],
            invitations: [],
            membershipRequests: [],
            fullName: "Nicolas SANDRON",
            phoneNumber: "",
            phoneVerified: false,
        },
    },
    {
        userId: "6537880532b88901a70f8132",
        timestamp: "2024-10-09T15:01:48.286Z",
        type: "invoice_invalidated",
        user: {
            id: "6537880532b88901a70f8132",
            email: "nicolas@flowie.fr",
            emailVerified: false,
            organizations: [
                {
                    id: "64ec6c29dd74306a85f11c19",
                },
                {
                    id: "6703e91850789d0f5f3e5b9f",
                },
                {
                    id: "67065bd350789d0f5f3e5bab",
                },
                {
                    id: "670ff49144455e7c26f25aa1",
                },
            ],
            invitations: [],
            membershipRequests: [],
            fullName: "Nicolas SANDRON",
            phoneNumber: "",
            phoneVerified: false,
        },
    },
    {
        userId: "6537880532b88901a70f8132",
        timestamp: "2024-10-09T15:01:15.211Z",
        type: "invoice_validated",
        user: {
            id: "6537880532b88901a70f8132",
            email: "nicolas@flowie.fr",
            emailVerified: false,
            organizations: [
                {
                    id: "64ec6c29dd74306a85f11c19",
                },
                {
                    id: "6703e91850789d0f5f3e5b9f",
                },
                {
                    id: "67065bd350789d0f5f3e5bab",
                },
                {
                    id: "670ff49144455e7c26f25aa1",
                },
            ],
            invitations: [],
            membershipRequests: [],
            fullName: "Nicolas SANDRON",
            phoneNumber: "",
            phoneVerified: false,
        },
    },
    {
        userId: "6537880532b88901a70f8132",
        timestamp: "2024-10-09T15:01:07.576Z",
        type: "invoice_invalidated",
        user: {
            id: "6537880532b88901a70f8132",
            email: "nicolas@flowie.fr",
            emailVerified: false,
            organizations: [
                {
                    id: "64ec6c29dd74306a85f11c19",
                },
                {
                    id: "6703e91850789d0f5f3e5b9f",
                },
                {
                    id: "67065bd350789d0f5f3e5bab",
                },
                {
                    id: "670ff49144455e7c26f25aa1",
                },
            ],
            invitations: [],
            membershipRequests: [],
            fullName: "Nicolas SANDRON",
            phoneNumber: "",
            phoneVerified: false,
        },
    },
    {
        userId: "6537880532b88901a70f8132",
        timestamp: "2024-10-09T14:57:31.721Z",
        type: "invoice_validated",
        user: {
            id: "6537880532b88901a70f8132",
            email: "nicolas@flowie.fr",
            emailVerified: false,
            organizations: [
                {
                    id: "64ec6c29dd74306a85f11c19",
                },
                {
                    id: "6703e91850789d0f5f3e5b9f",
                },
                {
                    id: "67065bd350789d0f5f3e5bab",
                },
                {
                    id: "670ff49144455e7c26f25aa1",
                },
            ],
            invitations: [],
            membershipRequests: [],
            fullName: "Nicolas SANDRON",
            phoneNumber: "",
            phoneVerified: false,
        },
    },
    {
        userId: "6537880532b88901a70f8132",
        timestamp: "2024-10-09T14:56:46.657Z",
        type: "invoice_invalidated",
        user: {
            id: "6537880532b88901a70f8132",
            email: "nicolas@flowie.fr",
            emailVerified: false,
            organizations: [
                {
                    id: "64ec6c29dd74306a85f11c19",
                },
                {
                    id: "6703e91850789d0f5f3e5b9f",
                },
                {
                    id: "67065bd350789d0f5f3e5bab",
                },
                {
                    id: "670ff49144455e7c26f25aa1",
                },
            ],
            invitations: [],
            membershipRequests: [],
            fullName: "Nicolas SANDRON",
            phoneNumber: "",
            phoneVerified: false,
        },
    },
    {
        userId: "6537880532b88901a70f8132",
        timestamp: "2024-10-09T14:56:37.730Z",
        type: "invoice_validated",
        user: {
            id: "6537880532b88901a70f8132",
            email: "nicolas@flowie.fr",
            emailVerified: false,
            organizations: [
                {
                    id: "64ec6c29dd74306a85f11c19",
                },
                {
                    id: "6703e91850789d0f5f3e5b9f",
                },
                {
                    id: "67065bd350789d0f5f3e5bab",
                },
                {
                    id: "670ff49144455e7c26f25aa1",
                },
            ],
            invitations: [],
            membershipRequests: [],
            fullName: "Nicolas SANDRON",
            phoneNumber: "",
            phoneVerified: false,
        },
    },
    {
        userId: "6537880532b88901a70f8132",
        timestamp: "2024-10-09T14:56:12.028Z",
        type: "invoice_invalidated",
        user: {
            id: "6537880532b88901a70f8132",
            email: "nicolas@flowie.fr",
            emailVerified: false,
            organizations: [
                {
                    id: "64ec6c29dd74306a85f11c19",
                },
                {
                    id: "6703e91850789d0f5f3e5b9f",
                },
                {
                    id: "67065bd350789d0f5f3e5bab",
                },
                {
                    id: "670ff49144455e7c26f25aa1",
                },
            ],
            invitations: [],
            membershipRequests: [],
            fullName: "Nicolas SANDRON",
            phoneNumber: "",
            phoneVerified: false,
        },
    },
    {
        userId: "6537880532b88901a70f8132",
        timestamp: "2024-10-09T14:46:17.190Z",
        type: "invoice_validated",
        user: {
            id: "6537880532b88901a70f8132",
            email: "nicolas@flowie.fr",
            emailVerified: false,
            organizations: [
                {
                    id: "64ec6c29dd74306a85f11c19",
                },
                {
                    id: "6703e91850789d0f5f3e5b9f",
                },
                {
                    id: "67065bd350789d0f5f3e5bab",
                },
                {
                    id: "670ff49144455e7c26f25aa1",
                },
            ],
            invitations: [],
            membershipRequests: [],
            fullName: "Nicolas SANDRON",
            phoneNumber: "",
            phoneVerified: false,
        },
    },
    {
        userId: "6537880532b88901a70f8132",
        timestamp: "2024-10-09T14:46:06.763Z",
        type: "invoice_invalidated",
        user: {
            id: "6537880532b88901a70f8132",
            email: "nicolas@flowie.fr",
            emailVerified: false,
            organizations: [
                {
                    id: "64ec6c29dd74306a85f11c19",
                },
                {
                    id: "6703e91850789d0f5f3e5b9f",
                },
                {
                    id: "67065bd350789d0f5f3e5bab",
                },
                {
                    id: "670ff49144455e7c26f25aa1",
                },
            ],
            invitations: [],
            membershipRequests: [],
            fullName: "Nicolas SANDRON",
            phoneNumber: "",
            phoneVerified: false,
        },
    },
    {
        userId: "6537880532b88901a70f8132",
        timestamp: "2024-10-09T14:43:28.600Z",
        type: "invoice_validated",
        user: {
            id: "6537880532b88901a70f8132",
            email: "nicolas@flowie.fr",
            emailVerified: false,
            organizations: [
                {
                    id: "64ec6c29dd74306a85f11c19",
                },
                {
                    id: "6703e91850789d0f5f3e5b9f",
                },
                {
                    id: "67065bd350789d0f5f3e5bab",
                },
                {
                    id: "670ff49144455e7c26f25aa1",
                },
            ],
            invitations: [],
            membershipRequests: [],
            fullName: "Nicolas SANDRON",
            phoneNumber: "",
            phoneVerified: false,
        },
    },
    {
        userId: "6537880532b88901a70f8132",
        timestamp: "2024-10-09T14:42:28.583Z",
        type: "invoice_confirmed",
        user: {
            id: "6537880532b88901a70f8132",
            email: "nicolas@flowie.fr",
            emailVerified: false,
            organizations: [
                {
                    id: "64ec6c29dd74306a85f11c19",
                },
                {
                    id: "6703e91850789d0f5f3e5b9f",
                },
                {
                    id: "67065bd350789d0f5f3e5bab",
                },
                {
                    id: "670ff49144455e7c26f25aa1",
                },
            ],
            invitations: [],
            membershipRequests: [],
            fullName: "Nicolas SANDRON",
            phoneNumber: "",
            phoneVerified: false,
        },
    },
] as EventI[]
