import dayjs from "dayjs"
import { useCallback, useMemo } from "react"
import { toast } from "react-toastify"
import { useSegment } from "~/hooks/useSegment"
import { useAppDispatch } from "~/store/hooks"
import { invoiceActions } from "~/store/invoice/invoiceSlice"
import { ocrActions } from "~/store/ocr/ocrSlice"
import { paymentActions } from "~/store/payment/paymentSlice"
import { CountryCode, OcrInvoiceI, WebSocketMessageType, WSMessageDataI, WSOCRResultI } from "~/types"

const convertWSOcrResultToOcrInvoice = (data: WSOCRResultI): OcrInvoiceI => ({
    description: "",
    reference: data.reference ?? "",
    purchaseOrderNumber: data.purchaseOrderNumber ?? "",
    dueDate: data.dueDate ?? "",
    issueDate: data.issueDate ?? dayjs().format("YYYY-MM-DD"),
    total: data.total ?? null,
    totalExcludedTaxes: data.totalExcludedTaxes ?? null,
    totalDiscount: data.totalExcludedTaxes ?? null,
    buyer: {
        name: data.buyer.name ?? "",
        email: "",
        contactName: "",
        registrations: data.buyer.registrations
            ? {
                  countryCode: CountryCode.UNKNOWN,
                  registrationNumber: {
                      registrationNumber:
                          data.buyer.registrations.siret ??
                          data.buyer.registrations.SIRET ??
                          data.buyer.registrations.siren ??
                          data.buyer.registrations.SIREN ??
                          "",
                      registrationType: "UNKNOWN",
                  },
                  vatNumber: data.buyer.registrations["VAT NUMBER"] ?? "",
              }
            : {
                  legalName: "",
                  countryCode: CountryCode.UNKNOWN,
              },
    },
    supplier: {
        name: data.supplier.name ?? "",
        email: "",
        contactName: "",
        registrations: data.supplier.registrations
            ? {
                  legalName: "",
                  countryCode: CountryCode.UNKNOWN,
                  registrationNumber: {
                      registrationNumber:
                          data.supplier.registrations.siret ??
                          data.supplier.registrations.SIRET ??
                          data.supplier.registrations.siren ??
                          data.supplier.registrations.SIREN ??
                          "",
                      registrationType: "UNKNOWN",
                  },
                  vatNumber: data.supplier.registrations.vatNumber ?? data.supplier.registrations["VAT NUMBER"] ?? "",
              }
            : {
                  legalName: "",
                  countryCode: CountryCode.UNKNOWN,
              },
    },
    paymentDetails: data.paymentDetails,
    notification: {
        subject: "",
        body: "",
    },
    supplierTaxes: [],
    buyerTaxes: [],
})

export const useWebsocketCallbacks = (invoiceId: string | undefined | null) => {
    const { segmentTrack } = useSegment()
    const dispatch = useAppDispatch()

    const onSetImageLinks = useCallback(
        (data: WSMessageDataI) => {
            if (data.payloadType === WebSocketMessageType.OCR_PREVIEW_IMAGES) {
                dispatch(ocrActions.setImageLinks(data.payload))
                dispatch(
                    invoiceActions.updatePartialImportingInvoice({
                        fileLinks: data.payload,
                    })
                )
            }
        },
        [dispatch]
    )

    const onOCRResult = useCallback(
        (data: WSMessageDataI) => {
            if (data.payloadType !== WebSocketMessageType.OCR_RESULT) return
            segmentTrack("OCR finished", {
                invoice_id: invoiceId,
            })
            dispatch(ocrActions.setOcrData(convertWSOcrResultToOcrInvoice(data.payload)))
            toast.dismiss()
        },
        [dispatch]
    )

    const onOCRDetails = useCallback(
        (data: WSMessageDataI) => {
            if (data.payloadType !== WebSocketMessageType.OCR_DETAILS) return
            segmentTrack("OCR details", {
                invoice_id: invoiceId,
            })
            dispatch(ocrActions.setOcrDetails(data.payload))
            toast.dismiss()
        },
        [dispatch]
    )

    const onAnyError = useCallback((data: WSMessageDataI) => {
        if (
            data.payloadType in
            [
                WebSocketMessageType.ERROR_OCR_FAIL,
                WebSocketMessageType.ERROR_OCR_IMAGES_FAIL,
                WebSocketMessageType.ERROR_PAYMENT_FAIL,
            ]
        ) {
            console.error("WS Error", data.payload)
            toast.error("Error " + data.payload, { position: "top-right" })
        }
    }, [])

    const onPaymentWebView = useCallback(
        (data: WSMessageDataI) => {
            if (data.payloadType === WebSocketMessageType.PAYMENT_WEBVIEW) {
                const payload = data.payload
                segmentTrack("Payment Powens Initiated", {
                    invoice_id: invoiceId,
                })
                window.open(payload.webViewUrl, "Payment", "width=520,height=800")
                toast.dismiss()
            }
        },
        [segmentTrack, invoiceId]
    )

    const onPaymentSuccess = useCallback(
        (data: WSMessageDataI) => {
            if (data.payloadType === WebSocketMessageType.PAYMENT_SUCCESS) {
                dispatch(paymentActions.setPaymentSuccess())
            }
        },
        [dispatch]
    )

    const onPaymentCancel = useCallback(
        (data: WSMessageDataI) => {
            if (data.payloadType === WebSocketMessageType.PAYMENT_CANCEL) {
                dispatch(paymentActions.setPaymentSuccess())
            }
        },
        [dispatch]
    )

    const onPaymentFailed = useCallback(
        (data: WSMessageDataI) => {
            if (data.payloadType === WebSocketMessageType.PAYMENT_FAILED) {
                dispatch(paymentActions.setPaymentFailed())
                toast.error(data.payload.errorMessage)
            }
        },
        [dispatch]
    )

    const onPaymentValidated = useCallback(
        (data: WSMessageDataI) => {
            if (data.payloadType === WebSocketMessageType.PAYMENT_VALIDATED) {
                dispatch(paymentActions.setPaymentValidated())
            }
        },
        [dispatch]
    )

    const onPaymentScheduled = useCallback(
        (data: WSMessageDataI) => {
            if (data.payloadType === WebSocketMessageType.PAYMENT_SCHEDULED) {
                dispatch(paymentActions.setPaymentScheduled())
            }
        },
        [dispatch]
    )

    const onInvoiceMarkedAsPaid = useCallback(
        (data: WSMessageDataI) => {
            if (data.payloadType === WebSocketMessageType.INVOICE_MARKED_AS_PAID) {
                dispatch(invoiceActions.markedAsPaid(data.payload))
            }
        },
        [dispatch]
    )

    const onInvoiceBrandedImagesGenerated = useCallback(
        (data: WSMessageDataI) => {
            if (data.payloadType === WebSocketMessageType.INVOICE_BRANDED_IMAGES_GENERATED) {
                dispatch(invoiceActions.setImageLinks(data.payload))
            }
        },
        [dispatch]
    )

    const onInvoicePersonInvolved = useCallback(
        (data: WSMessageDataI) => {
            if (data.payloadType === WebSocketMessageType.INVOICE_PERSON_INVOLVED) {
                dispatch(invoiceActions.addInvolvedPersonData({ userId: data.payload }))
            }
        },
        [dispatch]
    )

    return useMemo<Record<WebSocketMessageType, (data: WSMessageDataI) => void>>(
        () => ({
            [WebSocketMessageType.OCR_PREVIEW_IMAGES]: onSetImageLinks,
            [WebSocketMessageType.OCR_RESULT]: onOCRResult,
            [WebSocketMessageType.OCR_DETAILS]: onOCRDetails,
            [WebSocketMessageType.ERROR_OCR_FAIL]: onAnyError,
            [WebSocketMessageType.ERROR_OCR_IMAGES_FAIL]: onAnyError,
            [WebSocketMessageType.ERROR_PAYMENT_FAIL]: onAnyError,
            [WebSocketMessageType.PAYMENT_WEBVIEW]: onPaymentWebView,
            [WebSocketMessageType.PAYMENT_SUCCESS]: onPaymentSuccess,
            [WebSocketMessageType.PAYMENT_CANCEL]: onPaymentCancel,
            [WebSocketMessageType.PAYMENT_FAILED]: onPaymentFailed,
            [WebSocketMessageType.PAYMENT_VALIDATED]: onPaymentValidated,
            [WebSocketMessageType.PAYMENT_SCHEDULED]: onPaymentScheduled,
            [WebSocketMessageType.INVOICE_MARKED_AS_PAID]: onInvoiceMarkedAsPaid,
            [WebSocketMessageType.INVOICE_BRANDED_IMAGES_GENERATED]: onInvoiceBrandedImagesGenerated,
            [WebSocketMessageType.INVOICE_PERSON_INVOLVED]: onInvoicePersonInvolved,
        }),
        [
            onSetImageLinks,
            onOCRResult,
            onOCRDetails,
            onAnyError,
            onPaymentWebView,
            onPaymentSuccess,
            onPaymentCancel,
            onPaymentFailed,
            onPaymentValidated,
            onPaymentScheduled,
            onInvoiceMarkedAsPaid,
            onInvoiceBrandedImagesGenerated,
            onInvoicePersonInvolved,
        ]
    )
}
