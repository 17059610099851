import { Stack } from "@mui/material"
import classNames from "classnames"
import React, { useCallback, useEffect, useState } from "react"
import {
    BarChart,
    ChevronsLeft,
    ChevronsRight,
    X as CloseMenu,
    Command,
    DollarSign,
    Feather,
    FileText,
    Folder,
    Home,
    Icon,
    LogIn,
    Mail,
    Play,
    Power,
    Send,
    ShoppingBag,
    User,
    Users,
} from "react-feather"
import { FormattedMessage, defineMessages, useIntl } from "react-intl"
import { Link, generatePath, useLocation } from "react-router-dom"

import { LangSelector, TooltipConditional } from "~/components"
import { Features, isFeatureEnabled } from "~/core/featureFlag"
import { DASHBOARD_ROUTE, HOME_ROUTE } from "~/core/routes"
import { COPILOT_PROMPT_ROUTE } from "~/domains/copilot/prompt/routes"
import { DOCUMENTS_ROUTE } from "~/domains/identity/features/documents/routes"
import { DomainName, ScopeName } from "~/domains/identity/features/roles-permissions/types/RolesPermissions"
import { permissionMessages } from "~/domains/identity/features/roles-permissions/utils/permissions"
import { useHasPermissions } from "~/domains/identity/store/hooks/useHasPermissions"
import { FLOWS_LIST } from "~/domains/orchestration/flows/routes"
import { PARTNERS_ROUTE, PARTNERS_ROUTE_DEFAULT } from "~/domains/transactions/book-of-relations/routes"
import { EMAILS_INBOX } from "~/domains/transactions/emails/routes"
import { PURCHASE_ORDERS_ROUTE, PURCHASE_ORDERS_ROUTE_DEFAULT } from "~/domains/transactions/purchase-orders/routes"
import { PURCHASE_REQUESTS_ROUTE } from "~/domains/transactions/purchase-requests/routes"
import { SelectOrganization } from "~/features/account/components/Organization/SelectOrganization/SelectOrganization"
import {
    MY_DOCUMENTS_ROUTE,
    MY_DOCUMENTS_ROUTE_DEFAULT,
    PROFILE_ROUTE,
    PROFILE_ROUTE_DEFAULT,
} from "~/features/account/routes"
import { BUDGETS_LIST } from "~/features/budget/routes"
import { METRICS_ROUTE } from "~/features/metrics/routes"
import { WORKFLOWS_LIST } from "~/features/workflow/routes"
import { useSegment } from "~/hooks/useSegment"
import { authActions, selectIsConnected } from "~/store/auth/authSlice"
import { globalActions, selectDisplayMenu, selectKeepSidebarOpened, selectLang } from "~/store/global/globalSlice"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { AuthorizationName, ViewTypeI } from "~/types"

import { SideBarCompanyLogo } from "../CompanyLogo"
import { PlusIcon } from "../Icons"
import "./Sidebar.scss"

const ICON_SIZE = 18

const messages = defineMessages({
    newUpload: { id: "navbar.newUpload", defaultMessage: "New upload" },
    invoices: { id: "navbar.invoices", defaultMessage: "Invoices" },
    documents: { id: "navbar.documents", defaultMessage: "Documents" },
    customForms: { id: "navbar.customForms", defaultMessage: "Custom forms" },
    partners: { id: "navbar.partners", defaultMessage: "Partners network" },
    budgets: { id: "navbar.budgets", defaultMessage: "Budgets" },
    metrics: { id: "navbar.metrics", defaultMessage: "Metrics" },
    purchaseOrders: { id: "navbar.purchases.orders", defaultMessage: "Payments" },
    purchaseRequests: { id: "navbar.purchases.requests", defaultMessage: "Payments" },
    inbox: { id: "navbar.inbox", defaultMessage: "Inbox" },
    home: { id: "navbar.home", defaultMessage: "Home" },
    account: { id: "navbar.account", defaultMessage: "My account" },
    logout: { id: "navbar.logout", defaultMessage: "Logout" },
    login: { id: "navbar.login", defaultMessage: "Login" },
    workflow: { id: "navbar.workflow", defaultMessage: "Workflows" },
    flows: { id: "navbar.flows", defaultMessage: "Flows (beta)" },
    copilot: { id: "navbar.copilot", defaultMessage: "Copilot" },
})

export const Sidebar = () => {
    const { formatMessage } = useIntl()
    const [open] = useState<boolean>(false)
    const [displayLang, setDisplayLang] = useState<boolean>(false)
    const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth)

    const lang = useAppSelector(selectLang)
    const currentOrganizationId = useAppSelector(selectCurrentOrganizationId)
    const isConnected = useAppSelector(selectIsConnected)
    const keepOpen = useAppSelector(selectKeepSidebarOpened)
    const displayMenu = useAppSelector(selectDisplayMenu)
    const sidebarClasses = classNames("sidebar", {
        ["open"]: displayMenu,
        ["keep-open"]: keepOpen || displayMenu,
    })
    const { segmentLogout } = useSegment()
    const dispatch = useAppDispatch()
    const location = useLocation()

    const areFlowsEnabled = isFeatureEnabled(Features.Flows, currentOrganizationId)
    const isMetricsEnabled = isFeatureEnabled(Features.Metrics, currentOrganizationId)

    const { hasPermission: hasBudgetsPermission } = useHasPermissions(
        currentOrganizationId ?? "",
        AuthorizationName.READ,
        DomainName.TRANSACTION,
        ScopeName.BUDGETS
    )
    const { hasPermission: hasWorkflowsPermission } = useHasPermissions(
        currentOrganizationId ?? "",
        AuthorizationName.READ,
        DomainName.ORCHESTRATION,
        ScopeName.WORKFLOWS
    )

    const { hasPermission: hasPOsPermission } = useHasPermissions(
        currentOrganizationId ?? "",
        AuthorizationName.READ,
        DomainName.TRANSACTION,
        ScopeName.PURCHASE_ORDERS
    )
    const { hasPermission: hasInvoicesPermission } = useHasPermissions(
        currentOrganizationId ?? "",
        AuthorizationName.READ,
        DomainName.TRANSACTION,
        ScopeName.INVOICES
    )
    const isDocumentsEnabled = isFeatureEnabled(Features.Documents, currentOrganizationId)

    const isCopilotEnabled = isFeatureEnabled(Features.ChatBot, currentOrganizationId)
    const isCustomFormsEnabled = isFeatureEnabled(Features.CustomForms, currentOrganizationId)

    const { hasPermission: hasBookOfRelationsPermission } = useHasPermissions(
        currentOrganizationId ?? "",
        AuthorizationName.READ,
        DomainName.TRANSACTION,
        ScopeName.BOOK_OF_RELATIONS
    )

    const isMobile = screenWidth <= 900
    const isOpen = open || isMobile || keepOpen

    const Chevrons = keepOpen ? ChevronsLeft : ChevronsRight

    const handleLogout = () => {
        segmentLogout()
        dispatch(authActions.logout())
    }

    const toggleKeepSidebarOpened = useCallback(() => {
        dispatch(globalActions.toggleKeepSidebarOpened())
    }, [dispatch])

    useEffect(() => {
        const updateDimension = () => {
            setScreenWidth(window.innerWidth)
        }
        window.addEventListener("resize", updateDimension)

        return () => {
            window.removeEventListener("resize", updateDimension)
        }
    }, [setScreenWidth])

    const handleDisplayHamburgerMenu = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault()
        event.stopPropagation()
        dispatch(globalActions.setDisplayMenu())
    }

    const handleLogin = useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            event.preventDefault()
            dispatch(authActions.openPopupSignIn())
        },
        [dispatch]
    )

    const renderMenuItems = useCallback(
        (route: string, Icon: Icon, message: string, classes?: string, iconSize = ICON_SIZE) => {
            const active = location.pathname.startsWith(route)

            return (
                <li className={classNames(classes, { active })}>
                    <TooltipConditional
                        condition={!isOpen}
                        type="primary"
                        title={formatMessage(messages[message])}
                        placement="right"
                        arrow
                    >
                        <Link to={generatePath(route)}>
                            <Stack direction="row" alignItems="center" gap={1}>
                                <Icon size={iconSize} />
                                {isOpen && <FormattedMessage {...messages[message]} />}
                            </Stack>
                        </Link>
                    </TooltipConditional>
                </li>
            )
        },
        [isOpen, location, lang, currentOrganizationId]
    )

    const renderHomeLink = () => {
        return renderMenuItems(DASHBOARD_ROUTE, Home, "home")
    }

    const renderInboxLink = () => {
        const classes = classNames({ disabled: !hasInvoicesPermission })
        const route = hasInvoicesPermission ? generatePath(EMAILS_INBOX) : "#"
        return renderMenuItems(route, Mail, "inbox", classes)
    }

    const renderBudgetsLink = () => {
        const classes = classNames({ disabled: !hasBudgetsPermission })
        const route = hasBudgetsPermission ? BUDGETS_LIST : "#"
        return renderMenuItems(route, DollarSign, "budgets", classes)
    }

    // TEMP: note that metrics piggybacks on budgets permissions,
    // whoever has budgets permissions has metrics permissions
    const renderMetricsLink = () => {
        const classes = classNames({ disabled: !hasBudgetsPermission })
        const route = hasBudgetsPermission ? METRICS_ROUTE : "#"
        return renderMenuItems(route, BarChart, "metrics", classes)
    }

    const renderWorkflowsLink = () => {
        const classes = classNames({ disabled: !hasWorkflowsPermission })
        const route = hasWorkflowsPermission ? WORKFLOWS_LIST : "#"
        return renderMenuItems(route, Play, "workflow", classes)
    }

    const renderFlowsLink = () => {
        const classes = classNames({ disabled: !hasWorkflowsPermission })
        const route = hasWorkflowsPermission ? FLOWS_LIST : "#"
        return renderMenuItems(route, Play, "flows", classes)
    }

    const renderPOsLink = () => {
        const classes = classNames({
            active: location.pathname.startsWith(PURCHASE_ORDERS_ROUTE_DEFAULT),
            disabled: !hasPOsPermission,
        })
        const route = hasPOsPermission ? generatePath(PURCHASE_ORDERS_ROUTE, { viewType: ViewTypeI.buyer }) : "#"
        return renderMenuItems(route, ShoppingBag, "purchaseOrders", classes)
    }

    const renderPRsLink = () => {
        const classes = classNames({ disabled: !isConnected || !currentOrganizationId })
        const route = isConnected ? PURCHASE_REQUESTS_ROUTE : "#"
        return renderMenuItems(route, Send, "purchaseRequests", classes)
    }

    const renderInvoicesLink = () => {
        const classes = classNames({
            active: location.pathname.startsWith(MY_DOCUMENTS_ROUTE_DEFAULT),
            disabled: !hasInvoicesPermission,
        })
        const route = hasInvoicesPermission ? generatePath(MY_DOCUMENTS_ROUTE, { view: ViewTypeI.buyer }) : "#"
        return renderMenuItems(route, FileText, "invoices", classes)
    }

    const renderDocumentsLink = () => {
        const route = generatePath(DOCUMENTS_ROUTE)
        return renderMenuItems(route, Folder, "documents")
    }

    const renderCustomFormsLink = () => {
        const route = "_not_implemented_"
        return renderMenuItems(route, Feather, "customForms")
    }

    const renderBookOfRelationsLink = () => {
        const classes = classNames({
            active: location.pathname.startsWith(PARTNERS_ROUTE_DEFAULT),
            disabled: !hasBookOfRelationsPermission,
        })
        const route = hasBookOfRelationsPermission ? generatePath(PARTNERS_ROUTE, { view: ViewTypeI.supplier }) : "#"
        return renderMenuItems(route, Users, "partners", classes)
    }

    const renderCopilotLink = () => {
        const route = generatePath(COPILOT_PROMPT_ROUTE)
        return renderMenuItems(route, Command, "copilot")
    }

    const renderAccountLink = () => {
        const classes = classNames({ active: location.pathname.startsWith(PROFILE_ROUTE_DEFAULT) })
        const route = generatePath(PROFILE_ROUTE)
        return renderMenuItems(route, User, "account", classes)
    }

    return (
        <nav className={sidebarClasses}>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                onClick={toggleKeepSidebarOpened}
                className="sidebar-logo-container"
            >
                <SideBarCompanyLogo iconOnly={!isOpen} />
                <a href="#" title="" className="hamburger-menu" onClick={handleDisplayHamburgerMenu}>
                    <CloseMenu size={30} />
                </a>
                {isOpen && <Chevrons size={16} color="var(--color-light-grey)" />}
            </Stack>

            <ul className="sidebar-top-actions">
                <li className="sidebar-upload">
                    <Link to={HOME_ROUTE} title="">
                        <PlusIcon size={ICON_SIZE} color="white" />
                        <div className="sidebar-upload-label">
                            <FormattedMessage {...messages.newUpload} />
                        </div>
                    </Link>
                </li>
            </ul>

            <hr />

            <ul>
                {renderHomeLink()}

                <TooltipConditional
                    condition={!hasInvoicesPermission}
                    title={formatMessage(permissionMessages.errorNoAccessAdministrator)}
                >
                    {renderInboxLink()}
                </TooltipConditional>

                <hr />

                <TooltipConditional
                    condition={!isConnected}
                    title={formatMessage(permissionMessages.errorNoAccessAdministrator)}
                >
                    {renderPRsLink()}
                </TooltipConditional>

                <TooltipConditional
                    condition={!hasPOsPermission}
                    title={formatMessage(permissionMessages.errorNoAccessAdministrator)}
                >
                    {renderPOsLink()}
                </TooltipConditional>

                <TooltipConditional
                    condition={!hasInvoicesPermission}
                    title={formatMessage(permissionMessages.errorNoAccessAdministrator)}
                >
                    {renderInvoicesLink()}
                </TooltipConditional>

                {isDocumentsEnabled && renderDocumentsLink()}

                {isCustomFormsEnabled && renderCustomFormsLink()}

                <hr />

                <TooltipConditional
                    condition={!hasWorkflowsPermission}
                    title={formatMessage(permissionMessages.errorNoAccessAdministrator)}
                >
                    {renderWorkflowsLink()}
                </TooltipConditional>

                {/* TEMP: New version of Flows are behind a feature flag */}

                {areFlowsEnabled && (
                    <TooltipConditional
                        condition={!hasWorkflowsPermission}
                        title={formatMessage(permissionMessages.errorNoAccessAdministrator)}
                    >
                        {renderFlowsLink()}
                    </TooltipConditional>
                )}

                <TooltipConditional
                    condition={!hasBookOfRelationsPermission}
                    title={formatMessage(permissionMessages.errorNoAccessAdministrator)}
                >
                    {renderBookOfRelationsLink()}
                </TooltipConditional>

                <TooltipConditional
                    condition={!hasBudgetsPermission}
                    title={formatMessage(permissionMessages.errorNoAccessAdministrator)}
                >
                    {renderBudgetsLink()}
                </TooltipConditional>

                {isMetricsEnabled && hasBudgetsPermission && renderMetricsLink()}

                {isCopilotEnabled && (
                    <>
                        <hr />
                        {renderCopilotLink()}
                    </>
                )}
            </ul>

            <hr />

            <ul className="nav-profile">
                <SelectOrganization as="li" withEditButton />
                <hr />
                <LangSelector displayLang={displayLang} setDisplayLang={setDisplayLang} showSelectedLabel={isOpen} />
                {renderAccountLink()}
            </ul>

            <hr />

            {isConnected ? (
                <ul className="nav-logout">
                    <li>
                        <TooltipConditional
                            condition={!isOpen}
                            type="danger"
                            title={formatMessage(messages.logout)}
                            placement="right"
                            arrow
                        >
                            <a href="#" onClick={handleLogout}>
                                <Stack direction="row" alignItems="center" gap={1}>
                                    <Power size={ICON_SIZE} />
                                    {isOpen && <FormattedMessage {...messages.logout} />}
                                </Stack>
                            </a>
                        </TooltipConditional>
                    </li>
                </ul>
            ) : (
                <ul>
                    <li>
                        <TooltipConditional
                            condition={!isOpen}
                            type="primary"
                            title={formatMessage(messages.login)}
                            placement="right"
                            arrow
                        >
                            <a href="#" onClick={handleLogin}>
                                <Stack direction="row" alignItems="center" gap={1}>
                                    <LogIn size={ICON_SIZE} />
                                    {isOpen && <FormattedMessage {...messages.login} />}
                                </Stack>
                            </a>
                        </TooltipConditional>
                    </li>
                </ul>
            )}
        </nav>
    )
}
